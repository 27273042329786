import React from 'react'
import WrongAnswerCross from './WrongAnswerCross'

const WrongAnswersCrosses = ({ wrongAnswersList = [] }) => {
  const isLongWrongAnswersList = wrongAnswersList.length > 80
  return (
    <div
      className={`wrong-answer-crosses ${isLongWrongAnswersList ? 'long-wrong-answers-list' : ''}`}>
      {isLongWrongAnswersList ? (
        <div className="number-wrong-answer-crosses">
          {wrongAnswersList.length} <WrongAnswerCross />
        </div>
      ) : (
        wrongAnswersList.map((x, i) => (
          <WrongAnswerCross key={i} wrongAnswersLength={wrongAnswersList.length} />
        ))
      )}
    </div>
  )
}

export default WrongAnswersCrosses
