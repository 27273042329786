import React from 'react'
import EasyMediumHardNavigationPage from '../../../src/components/EasyMediumHardNavigationPage'

const ColorsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'learn', 'play']}
    pageName="colors_page"
    customNavigationPath="/words/colors"
  />
)

export default ColorsPage
