import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnMultiplicationsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'beginner', 'easy', 'medium', 'multiplications', 'mathematics']}
    pageName="learn_multiplications_page"
    topics={[
      'topic_beginner',
      'topic_easy',
      'topic_medium',
      'topic_multiplications',
      'topic_mathematics'
    ]}
    topicNavigationPath="mathematics/multiplications/learn"
    subTopics={['beginner', 'easy', 'medium']}
    borderRadius={0.53}
    canonicalPath="/mathematics"
  />
)

export default LearnMultiplicationsPage
