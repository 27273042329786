import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const EasyDivisionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'quotient', 'remainder']}
    pageName="easy_level_divisions_page"
    topics={['topic_quotient', 'topic_remainder']}
    topicNavigationPath="mathematics/divisions/easy"
    subTopics={['quotient', 'remainder']}
    borderRadius={0.1}
    canonicalPath="/mathematics"
  />
)

export default EasyDivisionsPage
