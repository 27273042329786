import React from 'react'
import LearnPage from '../../../../../src/components/LearnPage'
import { getSmallCountryImageUrl } from '../../../../../src/pages/geography/locations/util'

const LearnOtherLocationsPage = () => (
  <LearnPage
    parentPage="locations_geography"
    grandParentPage="geography"
    pageName="learn_other_locations"
    parentPagePath="/geography/locations"
    getImageUrl={getSmallCountryImageUrl}
  />
)

export default LearnOtherLocationsPage
