import React from 'react'
import LearnPage from '../../../../../src/components/LearnPage'
import { getCountryFlagImageUrl } from '../../../../../src/pages/geography/flags/util'

const LearnFamousFlagsPage = () => (
  <LearnPage
    parentPage="flags_geography"
    grandParentPage="geography"
    pageName="learn_famous_flags"
    parentPagePath="/geography/flags"
    getImageUrl={getCountryFlagImageUrl}
  />
)

export default LearnFamousFlagsPage
