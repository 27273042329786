import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 785
const height = 610

const cities = {
  Skopje: { x: 297, y: 140 }
}

const Macedonia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map macedonia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="MK-01"
          title="Aerodrom"
          className="land"
          d="M333.53,167.52L328.02,171.25L328.02,171.25L320.88,163.91L310.37,160.39L303.61,153.35L299.86,152.85L297.17,149.64L297.17,149.64L296.48,148.82L298.34,145.18L298.34,145.18L300.16,145.18L300.16,145.18L306.62,147.81L317.5,155.36L325.77,159.39L331.02,163.41z"
        />
        <path
          id="MK-02"
          title="Aračinovo"
          className="land"
          d="M329.48,109.56L339.74,122.38L346.39,122.84L348.55,113.97L348.55,113.97L351.2,113.97L357.26,117.25L357.6,121.87L357.6,121.87L343.77,138.05L338.07,139.11L331.37,143.86L329.2,143.31L329.2,143.31L329.21,135.94L332.95,129.74L331.86,120.1L329.01,115.87z"
        />
        <path
          id="MK-03"
          title="Berovo"
          className="land"
          d="M673.63,216.54L674.42,218.65L688.85,219.81L698.88,224.12L698.88,224.12L703.57,238.5L705.73,239.66L707.61,248.52L710.06,249.1L712.95,245.06L730.41,247.37L733.87,248.91L735.46,254.69L744.41,254.88L754.8,262.58L763.02,265.28L766.92,268.18L766.92,268.18L758.96,280.47L759.58,284.2L752.37,284.67L750.01,287.26L749,291.07L750.82,296.46L749.69,308.51L754.28,313L754.42,316.8L756.42,320.11L755.16,322.51L755.38,327.03L752.27,327.72L752.17,332.27L752.17,332.27L729.38,334.01L729.38,334.01L727.94,334.01L722.45,328.25L703.19,326.14L696.12,319.99L693.09,313.27L689.9,312.84L689.9,312.84L680.82,310.77L674.18,310.96L674.04,307.5L677.79,305.96L678.51,304.04L677.65,295.39L666.17,287.94L663.11,292.99L660.76,293.71L659.91,286.69L659.91,286.69L658.42,277.84L664.37,268.46L664.55,266.29L658.6,261.48L657.38,249.01L657.38,249.01L659.84,246.79L667.35,246.21L670.23,242.75L673.41,233.11L673.84,226.36L672.4,220z"
        />
        <path
          id="MK-04"
          title="Bitola"
          className="land"
          d="M260.57,457.66L263.26,461.49L263.26,464.85L260.58,469.99L261.62,477.49L265.95,484.2L274.76,489.34L275.8,507.49L282.07,511.04L290.57,507.1L295.81,508.35L295.81,508.35L293.18,521.22L293.73,525.33L298.31,530.89L299.22,543.2L305.81,544.9L318.25,561.55L320.35,577.48L320.35,577.48L306.86,577.35L297.71,573.36L270.18,591.47L254.83,596.59L244.42,596.62L240.82,593.99L240.82,593.99L244.52,579.35L243.78,577.4L240.64,575.46L241.01,570.35L239.72,565.24L238.06,564.03L227.55,562.81L226.26,556.97L222.94,552.35L218.88,550.16L206.15,550.4L203.94,548.46L203.2,542.86L197.11,534.82L196.38,527.76L189.74,525.75L187.66,523.31L188.81,514.79L194.35,510.22L197.35,505.04L197.35,496.81L194.58,493.76L194.7,491.57L194.7,491.57L204.95,486.74L212.1,485.83L219.25,482.47L222.71,477.9L227.32,475.15L231.24,474.85L235.16,468.14L241.38,466L246.91,461.73L249.91,456.54L253.27,454.22L253.27,454.22L254.75,453.79L258.67,457.45z"
        />
        <path
          id="MK-05"
          title="Bogdanci"
          className="land"
          d="M640.82,445.1L641.39,447.3L648.18,454.38L660.4,453.78L669.28,458.33L670.34,467.87L670.34,467.87L664.85,471.18L661.18,480.36L655.32,488.89L648.82,494.05L647.2,493.48L647.2,493.48L648.65,492.44L641.39,486.97L633.6,482.9L626,481.58L628.44,467.32L622.07,456.56L622.07,456.56L627.65,452.61L628.53,442.94L629.86,440.6L635.16,440.45L636.49,443.09z"
        />
        <path
          id="MK-06"
          title="Bogovinje"
          className="land"
          d="M152.46,157.5L152.74,165.24L153.95,167.15L161.46,167.42L161.26,170.41L157,179.38L151.52,181.55L150.1,183.72L151.72,189.7L149.56,189.7L149.56,189.7L142.18,186.98L125.74,177.74L115.79,174.48L96.91,171.22L93.43,172.25L93.43,172.25L88.79,168.67L90.74,161.18L90.74,161.18L95.39,158.99L98.84,155.46L108.79,151.1L118.94,143.21L123.61,142.94L141.06,147.02z"
        />
        <path
          id="MK-07"
          title="Bosilovo"
          className="land"
          d="M689.9,312.84L693.09,313.27L696.12,319.99L703.19,326.14L722.45,328.25L727.94,334.01L729.38,334.01L729.38,334.01L728.95,343.99L721.15,353.78L712.21,361.65L712.21,376.03L707.59,382.35L707.59,382.35L699.22,384.65L696.62,384.27L692.29,381.2L689.26,373.15L681.33,375.26L677.14,372.96L679.59,363.56L677.28,355.7L673.6,351.85L673.6,351.85L673.68,350.52L676.13,348.98L681.47,347.45L686.23,342.84L689.41,336.7L691.14,325.18z"
        />
        <path
          id="MK-08"
          title="Brvenica"
          className="land"
          d="M171.34,157.42L177.8,166.33L182.07,167.42L182.68,176.93L185.52,178.56L189.58,178.56L191,182.09L190.19,185.35L187.55,185.9L186.74,196.49L198.1,197.3L203.99,202.46L213.73,202.19L214.95,198.12L219.42,197.3L221.04,199.74L221.04,203.6L221.04,203.6L215.76,203.27L209.88,207.62L202.77,215.49L197.7,223.62L193.44,227.69L189.24,228.66L189.24,228.66L181.97,218.2L174.05,210.87L173.64,204.63L160.65,204.09L151.85,199.36L151.85,199.36L150.1,198.66L149.56,189.7L149.56,189.7L151.72,189.7L150.1,183.72L151.52,181.55L157,179.38L161.26,170.41L161.46,167.42L153.95,167.15L152.74,165.24L152.46,157.5L152.46,157.5L153.65,157.63L155.88,152.73L157.71,151.65L165.83,156.54z"
        />
        <path
          id="MK-09"
          title="Butel"
          className="land"
          d="M320.38,96.76L316.75,98.95L307.74,112.56L306.99,134.73L305.11,138.75L300.61,139.36L300.61,139.36L295.23,135.85L295.23,135.85L294.56,134.07L294.56,134.07L293.7,125.31L293.7,125.31L294.86,126.54L297.98,124.66L297.23,110.55L299.48,95.93L306.62,81.3L311.12,79.79L312.88,80.38L312.88,80.38L311.82,83.24z"
        />
        <path
          id="MK-10"
          title="Valandovo"
          className="land"
          d="M604.21,362.69L608.74,369.7L614.8,375.84L618.55,377.56L627.35,377.75L630.39,381.39L625.77,393.66L631.25,403.62L631.68,409.55L646.26,408.4L651.74,413.57L651.74,416.25L660.26,417.02L665.02,413.38L669.64,415.1L672.52,411.85L675.84,404L680.03,402.08L700.71,412.8L700.71,412.8L699.42,418.98L699.91,429.32L699.91,429.32L690.03,425.37L676.66,425.22L669.92,423.9L666.16,427.86L665.39,432.55L652.63,433.28L646.44,442.5L640.82,445.1L640.82,445.1L640.82,445.1L640.82,445.1L636.49,443.09L635.16,440.45L629.86,440.6L628.53,442.94L627.65,452.61L622.07,456.56L622.07,456.56L617.43,448.07L615,447.19L615,439.87L611.57,430.49L611.02,416.14L604.5,414.23L590.35,396.78L583.28,394.44L580.3,391.21L571.05,390.12L571.05,390.12L567.37,380.35L569.06,371.31L569.06,371.31L570.79,372L572.37,367.97L579.73,365.1L593.15,361.84z"
        />
        <path
          id="MK-11"
          title="Vasilevo"
          className="land"
          d="M659.91,286.69L660.76,293.71L663.11,292.99L666.17,287.94L677.65,295.39L678.51,304.04L677.79,305.96L674.04,307.5L674.18,310.96L680.82,310.77L689.9,312.84L689.9,312.84L691.14,325.18L689.41,336.7L686.23,342.84L681.47,347.45L676.13,348.98L673.68,350.52L673.6,351.85L673.6,351.85L667.98,355.89L660.18,358.39L651.38,358.19L645.18,350.14L633.63,344.57L630.6,344.95L626.99,351.1L623.4,354.37L623.4,354.37L621.51,347.06L621.77,337.94L621.77,337.94L622.74,334.97L620.57,329.02L628.51,325.37L631.4,320.76L637.89,323.64L646.84,332.48L648.57,332.67L651.02,329.98L652.75,327.29L652.61,317.69L650.73,309.81L646.11,304.62L644.24,299.43L650.01,288.66L659.1,287.7z"
        />
        <path
          id="MK-12"
          title="Vevčani"
          className="land"
          d="M19.63,440.66L23.94,439.1L33.38,438.81L44.58,440.85L49.19,436.78L52.05,436.19L54.46,436.19L57.1,440.27L57.1,443.18L54.9,445.51L51.39,447.54L44.14,447.54L38.65,451.32L31.63,451.9L20.68,449.58L20.68,449.58z"
        />
        <path
          id="MK-13"
          title="Veles"
          className="land"
          d="M409.14,174.14L409.11,179.33L412.38,192L422.99,205.28L422.99,205.28L422.03,209L412.99,219.12L419.64,228.71L419.64,231.55L414.98,243.79L415.91,245.21L421.76,246.09L426.81,255.15L426.81,255.15L425.61,266.31L417.91,274.81L409.94,291.28L401.97,291.99L400.06,299.71L400.06,299.71L390.18,297.62L387.52,294.61L385.65,282.73L380.47,286.63L377.27,287.16L369.03,261.61L352.26,256.46L346.54,258.42L338.59,243.65L338.59,243.65L344.15,242.79L350.8,239.77L356.92,229.64L356.92,222.36L354.26,219.69L354.26,211.87L355.7,211.36L355.7,211.36L358.83,213.77L362.15,214.3L364.41,214.48L367.07,212.52L367.6,218.56L369.33,218.56L373.58,211.81L375.7,211.81L379.02,216.61L384.21,217.32L389.12,199.38L388.99,184.27L398.69,181.96z"
        />
        <path
          id="MK-14"
          title="Vinica"
          className="land"
          d="M652.81,145.31L653.64,167.68L664.31,171.55L676,177.73L685.38,179.47L685.38,181.59L680.33,190.09L678.17,202.44L674.13,209L673.63,216.54L673.63,216.54L672.4,220L673.84,226.36L673.41,233.11L670.23,242.75L667.35,246.21L659.84,246.79L657.38,249.01L657.38,249.01L648.57,247.08L641.21,240.34L634.42,236.87L631.68,231.86L629.52,232.05L626.34,237.64L615.38,238.79L607.29,232.24L604.33,231.55L604.33,231.55L604.48,223.79L604.48,223.79L604.91,202.93L603.62,195.4L601.07,191.25L601.07,191.25L603.33,187.87L603.04,174.54L606.79,157.92L611.7,157.73L617.9,163.33L621.51,163.53L626.05,160.48L626.05,160.48L632.48,162.56L635.94,160.24L639.98,151.74L641.28,145.55L643.16,143.81L650.08,144z"
        />
        <path
          id="MK-15"
          title="Vraneštica"
          className="land"
          d="M196.06,308.67L199.73,319.73L210.08,327.03L210.28,329.46L194.04,345.66L188.97,347.82L188.36,354.32L188.36,354.32L182.88,352.68L178.21,353.22L177.4,354.84L179.23,367.52L181.26,370.76L186.85,369.68L186.85,369.68L186.74,373.45L182.47,375.61L178.21,376.69L166.64,376.42L164,369.14L156.08,363.74L154.87,361.31L154.87,354.84L157.79,353.3L157.79,353.3L163.6,341.34L166.84,339.18L166.84,339.18L172.12,338.64L175.98,335.94L178.62,332.16L181.46,322.71L191.61,315.68z"
        />
        <path
          id="MK-16"
          title="Vrapčište"
          className="land"
          d="M93.43,172.25L96.91,171.22L115.79,174.48L125.74,177.74L142.18,186.98L149.56,189.7L149.56,189.7L150.1,198.66L151.85,199.36L151.85,199.36L147.05,203.82L148.47,210.33L145.43,213.86L139.74,215.76L130,213.32L122.29,214.4L118.63,217.66L113.15,216.84L107.88,213.86L102.19,203.55L88.74,202.09L88.74,202.09L100.57,193.39L87.23,180.97z"
        />
        <path
          id="MK-17"
          title="Gazi Baba"
          className="land"
          d="M329.2,143.31L326.35,149.53L338.95,158.37L341.81,163.78L342.21,170.86L342.21,170.86L338.93,174.72L338.93,174.72L335.31,167.99L333.53,167.52L333.53,167.52L331.02,163.41L325.77,159.39L317.5,155.36L306.62,147.81L300.16,145.18L300.16,145.18L300.61,139.36L300.61,139.36L305.11,138.75L306.99,134.73L307.74,112.56L316.75,98.95L320.38,96.76L320.38,96.76L329.48,109.56L329.48,109.56L329.01,115.87L331.86,120.1L332.95,129.74L329.21,135.94z"
        />
        <path
          id="MK-18"
          title="Gevgelija"
          className="land"
          d="M571.05,390.12L580.3,391.21L583.28,394.44L590.35,396.78L604.5,414.23L611.02,416.14L611.57,430.49L615,439.87L615,447.19L617.43,448.07L622.07,456.56L622.07,456.56L628.44,467.32L626,481.58L633.6,482.9L641.39,486.97L648.65,492.44L647.2,493.48L647.2,493.48L637.73,488.6L633.95,488.59L611.67,493.95L600.36,493.46L596.18,492.16L591.79,486.25L579.6,486.14L571.57,487.79L568.93,487.03L564.87,481.84L561.36,482.89L549.25,474.83L538.38,474.62L538.38,474.62L532.08,472.96L534.08,466.4L548.31,464.19L544.85,447.21L552.16,445.17L560.62,439.23L559.98,435.83L556.77,434.98L556.13,422.91L554.49,419.18L554.49,419.18L560.11,411.35L574.34,410.84L574.6,402.67L570.88,395.35z"
        />
        <path
          id="MK-19"
          title="Gostivar"
          className="land"
          d="M151.85,199.36L160.65,204.09L173.64,204.63L174.05,210.87L181.97,218.2L189.24,228.66L189.24,228.66L181.26,229.59L179.63,233.39L179.63,243.42L178.82,245.04L174.76,246.4L174.35,248.57L175.77,252.63L178.62,254.8L179.02,261.57L182.27,268.34L182.88,275.38L181.3,281.23L181.3,281.23L165.42,285.66L160.96,288.91L160.69,290.57L160.69,290.57L152.84,281.6L148.37,278.35L137.41,274.02L134.09,274.36L134.09,274.36L133.45,270.77L128.99,265.63L122.9,255.07L127.16,250.73L127.16,248.57L115.59,248.29L111.94,250.46L109.09,247.21L108.48,239.35L102.8,230.4L80.27,228.23L71.13,238.27L58.55,236.64L53.88,232.57L46.98,229.32L43.86,225.75L43.86,225.75L47.05,220.65L50.73,219.38L54.06,211.82L56.75,211.85L63.84,204.31L73.79,206.81L80.73,206.4L88.74,202.09L88.74,202.09L102.19,203.55L107.88,213.86L113.15,216.84L118.63,217.66L122.29,214.4L130,213.32L139.74,215.76L145.43,213.86L148.47,210.33L147.05,203.82z"
        />
        <path
          id="MK-20"
          title="Gradsko"
          className="land"
          d="M426.81,255.15L428.01,251.77L433.32,252.3L434.12,253.72L438.5,279.42L444.21,281.54L449.66,281.9L455.9,276.94L457.08,274.59L457.08,274.59L466.36,275.18L472.15,278.84L472.15,278.84L471.46,283.81L462.75,296.26L462.38,300.73L465.36,303.72L474.7,300.4L478.19,301.9L478.56,305.88L473.71,315.49L476.57,322.12L473.46,329.58L469.6,329.74L467.96,320.58L466.56,318.92L463.82,319.69L463.82,319.69L458.93,318.3L456.29,319.96L455.51,325.75L451.15,325.75L445.7,323.27L439.17,332.18L434.5,332.18L428.43,328.45L423.76,327.83L422.83,332.38L423.92,339.22L421.58,341.9L416.6,342.32L413.02,345.8L413.02,345.8L404.76,340.81L403.83,337.28L404.49,329.32L408.61,324.73L409.94,317.83L408.74,315.35L401.43,310.22L399.57,307.57L400.06,299.71L400.06,299.71L401.97,291.99L409.94,291.28L417.91,274.81L425.61,266.31z"
        />
        <path
          id="MK-21"
          title="Debar"
          className="land"
          d="M29.79,308.29L35.63,314.85L43.24,320.34L56.88,322.45L63.23,327.94L70.84,338.07L81.62,342.71L82.94,349.46L82.94,349.46L80.07,348.09L77.63,348.63L75.6,351.33L71.95,366.71L69.55,371.21L69.55,371.21L68.5,369.95L67.59,370.98L67.59,370.98L65.76,367.17L61,366.32L61,354.52L58.78,347.35L55.3,347.35L52.12,344.4L50.22,336.8L46.73,331.74L34.04,330.89L30.56,336.8L15.58,345.64L15.58,345.64L13.99,342.17L7.76,341.69L5.25,338.96L5,336.05L1.6,334.36L0,330.9L2.34,327.78L6.06,317.71L10.32,318.02L23.6,313.06z"
        />
        <path
          id="MK-22"
          title="Debarca"
          className="land"
          d="M70.32,372.13L72.96,369.68L104.02,369.68L119.85,372.1L131.42,372.37L136.7,384.78L137.1,394.48L139.34,399.06L145.02,400.94L151.75,411.79L151.75,411.79L152.11,419.87L157.28,424.76L163.18,427.94L166.13,436.01L171.66,440.66L171.66,445.3L174.82,451.74L174.82,451.74L170.4,452.88L155.98,450.73L148.07,445.35L137.1,441.59L128.78,441.59L123.71,443.74L112.95,444.01L110.92,445.89L110.31,449.12L106.45,451.54L102.8,458.8L103.21,466.86L97.93,469.01L96.1,473.04L86.67,473.46L86.67,473.46L86.76,465.52L84.13,462.56L83.52,456.92L89.2,448.04L91.43,440.78L92.04,428.41L84.13,416.3L82.91,396.9z"
        />
        <path
          id="MK-23"
          title="Delčevo"
          className="land"
          d="M665.44,115.13L667.87,117.29L671.38,118.05L675.16,118.12L681.57,115.85L681.23,118.26L687.69,125.89L690.76,125.34L699.08,129.39L702.54,128.86L704.36,125.38L711.85,135.59L716.5,136.15L719.58,134.37L721.02,135.66L723.12,135.3L723.42,139.46L721.47,142.55L726.09,145.3L725.85,152.78L727.02,153.8L725.89,162.13L727.21,167L726.03,169.65L728.8,175.48L731.18,176.4L732.01,178.18L733.58,182.82L732.88,184.88L734.28,187.6L734.32,192.89L734.32,192.89L721.32,203.79L715.4,215.56L709.34,217.3L704.14,224.05L698.88,224.12L698.88,224.12L688.85,219.81L674.42,218.65L673.63,216.54L673.63,216.54L674.13,209L678.17,202.44L680.33,190.09L685.38,181.59L685.38,179.47L676,177.73L664.31,171.55L653.64,167.68L652.81,145.31L652.81,145.31L652.91,136.55L658.25,134.23L659.08,110.07L659.08,110.07L660.73,112.46z"
        />
        <path
          id="MK-24"
          title="Demir Kapija"
          className="land"
          d="M559.97,345.22L560.83,347.26L569.63,350.9L571.07,353.97L567.32,361.84L569.06,371.31L569.06,371.31L567.37,380.35L571.05,390.12L571.05,390.12L570.88,395.35L574.6,402.67L574.34,410.84L560.11,411.35L554.49,419.18L554.49,419.18L536.41,430.19L525.91,430.19L516.62,424.02L513.69,417.4L510.08,416.26L494.59,401.68L494.59,401.68L509.62,400.98L512.16,390.75L507.42,383.43L506.98,380.36L512.93,376.12L518,360.45L525.71,351.81L527.8,350.35L532.32,350.93L538.6,355.77L547.64,359.14L551.49,352.4z"
        />
        <path
          id="MK-25"
          title="Demir Hisar"
          className="land"
          d="M207.47,387.34L216.84,392.75L216.84,398.49L229.52,398.49L231.54,401.55L231.83,419.14L237.31,418.76L240.19,417.23L243.07,418.37L246.82,422.96L249.12,432.89L254.31,439.01L253.27,454.22L253.27,454.22L249.91,456.54L246.91,461.73L241.38,466L235.16,468.14L231.24,474.85L227.32,475.15L222.71,477.9L219.25,482.47L212.1,485.83L204.95,486.74L194.7,491.57L194.7,491.57L190.66,485.34L186.23,462.4L180.51,462.15L177.19,456.99L177.19,456.99L174.82,451.74L174.82,451.74L171.66,445.3L171.66,440.66L166.13,436.01L163.18,427.94L157.28,424.76L152.11,419.87L151.75,411.79L151.75,411.79L153.59,408.13L160.41,402.99L167.6,399.81L191.2,399.64L200.71,391.99L201.57,388.93L206.18,386.63z"
        />
        <path
          id="MK-26"
          title="Dojran"
          className="land"
          d="M640.82,445.1L646.44,442.5L652.63,433.28L665.39,432.55L666.16,427.86L669.92,423.9L676.66,425.22L690.03,425.37L699.91,429.32L699.91,429.32L698.25,445.46L698.25,445.46L694.73,443.38L688.21,443.82L684.45,446.46L681.03,452.46L682.13,461.53L686.99,470.75L692.8,476.3L692.8,476.3L686.61,483.27L684.82,482.99L679.66,478.81L674.9,471.43L670.34,467.87L670.34,467.87L669.28,458.33L660.4,453.78L648.18,454.38L641.39,447.3z"
        />
        <path
          id="MK-27"
          title="Dolneni"
          className="land"
          d="M278.91,303.34L293.71,307.71L296.25,310.5L298.63,316.65L303.56,319.24L305.8,327.78L306.24,337.31L310.27,343.27L313.41,344.85L326.99,343.66L330.27,352.99L343.1,356.76L345.94,359.14L348.23,363.7L348.23,363.7L342.36,367.87L339.97,377.58L333.25,379.96L329.67,384.52L326.54,386.1L303.18,388.68L296.17,397L289.79,401.09L289.79,401.09L285.8,397.99L271.47,398.78L268.79,387.1L266.85,386.3L259.48,391.2L259.48,391.2L257.07,389.87L248.42,379.17L244.98,377.38L246.33,373.02L256.48,373.22L256.77,368.86L244.11,352.16L244.11,352.16L244.24,348.23L250.21,345.85L252.74,341.48L256.25,340.89L264.91,344.85L265.5,331.55L273.86,320.43L275.8,315.66L276.55,303.34z"
        />
        <path
          id="MK-28"
          title="Drugovo"
          className="land"
          d="M133.96,332.3L134.77,337.02L138.63,341.61L152.03,347.82L157.79,353.3L157.79,353.3L154.87,354.84L154.87,361.31L156.08,363.74L164,369.14L166.64,376.42L178.21,376.69L182.47,375.61L186.74,373.45L186.85,369.68L186.85,369.68L192.22,368.06L195.46,374.26L205.8,379.22L205.8,379.22L205.02,380.9L207.47,387.34L207.47,387.34L206.18,386.63L201.57,388.93L200.71,391.99L191.2,399.64L167.6,399.81L160.41,402.99L153.59,408.13L151.75,411.79L151.75,411.79L145.02,400.94L139.34,399.06L137.1,394.48L136.7,384.78L131.42,372.37L119.85,372.1L104.02,369.68L72.96,369.68L70.32,372.13L70.32,372.13L70.32,372.13L70.32,372.13L69.55,371.21L69.55,371.21L71.95,366.71L75.6,351.33L77.63,348.63L80.07,348.09L82.94,349.46L82.94,349.46L91.23,351.6L95.09,350.25L98.33,347.01L101.92,340.18L105.73,338.07L106.04,329.21L109.85,324.98L109.85,308.09L107.42,294.54L107.42,294.54L110.82,295.13L116.5,309.46L121.58,310.27L125.84,320.27L130.51,325.41L132.74,331.89z"
        />
        <path
          id="MK-29"
          title="Gjorče Petrov"
          className="land"
          d="M253.43,98.02L257.44,99L256.97,98.17L259.82,99.97L259.82,99.97L269.06,109.04L274.32,120.63L274.32,126.33L274.32,126.33L274.13,144.35L274.13,144.35L266.46,143.64L261.8,137.71L255.05,123.67L254.12,118.36L251.56,115.54L244.11,118.67L241.31,117.11L239.22,111.79L238.48,104.23L238.48,104.23L243.13,104.1z"
        />
        <path
          id="MK-30"
          title="Želino"
          className="land"
          d="M174.78,135.15L181.18,137.92L186.82,136.71L188.4,137.92L188.4,140.64L186.6,142.46L187.27,143.97L190.89,145.18L199.24,145.78L202.4,142.46L207.27,142.31L207.27,142.31L214.1,145.09L223.12,151.92L219.98,178.72L231.19,187.92L231.19,187.92L231.01,190.2L233.22,193.15L238.37,196.76L236.22,212.48L236.22,212.48L221.04,203.6L221.04,203.6L221.04,199.74L219.42,197.3L214.95,198.12L213.73,202.19L203.99,202.46L198.1,197.3L186.74,196.49L187.55,185.9L190.19,185.35L191,182.09L189.58,178.56L185.52,178.56L182.68,176.93L182.07,167.42L177.8,166.33L171.34,157.42L171.34,157.42L173.54,154.37L179.63,152.46L179.83,149.47L175.57,147.57L173.54,143.49L169.69,141.31L172.93,135.59z"
        />
        <path
          id="MK-31"
          title="Zajas"
          className="land"
          d="M160.69,290.57L160.96,309.19L155.34,320.73L155.34,320.73L148.98,321.08L140.46,325.95L135.38,327.03L133.96,332.3L133.96,332.3L132.74,331.89L130.51,325.41L125.84,320.27L121.58,310.27L116.5,309.46L110.82,295.13L107.42,294.54L107.42,294.54L102.09,295.4L99.65,293.51L99.45,290.8L102.7,286.74L109.6,286.47L120.76,276.19L127.67,276.19L129.29,274.84L134.09,274.36L134.09,274.36L137.41,274.02L148.37,278.35L152.84,281.6z"
        />
        <path
          id="MK-32"
          title="Zelenikovo"
          className="land"
          d="M343.29,180.93L357.24,183.74L358.7,185.87L358.17,206.49L355.7,211.36L355.7,211.36L354.26,211.87L354.26,219.69L356.92,222.36L356.92,229.64L350.8,239.77L344.15,242.79L338.59,243.65L338.59,243.65L313.68,243.68L311.9,241.94L311.9,241.94L313.28,233.02L307.3,222.54L306.9,214L308.89,209.55L311.42,206.71L313.68,206.89L315.15,212.4L319.67,212.58L321,210.98L321.67,204.75L327.39,196.75L328.18,191.05L342.02,183.22z"
        />
        <path
          id="MK-33"
          title="Zrnovci"
          className="land"
          d="M601.07,191.25L603.62,195.4L604.91,202.93L604.48,223.79L604.48,223.79L587.59,222.63L587.59,222.63L584.33,216.42L577.93,209.2L577.09,200.48L577.09,200.48L582.19,200.31L587.59,195.11L589.22,195.11L592.48,198.13L597.38,192.08z"
        />
        <path
          id="MK-34"
          title="Ilinden"
          className="land"
          d="M357.6,121.87L358.54,124.38L369.27,132.65L373.42,132.65L373.74,144.78L373.74,144.78L368.37,156.01L370.89,162.88L369.89,164.88L362.09,165.55L356.94,161.99L354.28,161.99L352.29,164.88L342.21,170.86L342.21,170.86L341.81,163.78L338.95,158.37L326.35,149.53L329.2,143.31L329.2,143.31L331.37,143.86L338.07,139.11L343.77,138.05z"
        />
        <path
          id="MK-35"
          title="Jegunovce"
          className="land"
          d="M234.33,97.25L230.77,101.48L215.32,103.12L210.67,106.41L215.57,111.68L221.45,110.04L222.68,117.27L216.55,122.54L219.59,135.89L208.21,139.83L207.27,142.31L207.27,142.31L202.4,142.46L199.24,145.78L190.89,145.18L187.27,143.97L186.6,142.46L188.4,140.64L188.4,137.92L186.82,136.71L181.18,137.92L174.78,135.15L174.78,135.15L174.18,132.55L174.18,132.55L175.08,130.35L188.63,127.63L193.37,125.2L198.79,114.3L204.66,108.24L205.11,103.7L203.98,100.06L196.3,96.42L189.53,85.21L176.78,72.17L176.78,72.17L193.8,57.87L201.46,58.26L205.7,61.74L209.83,62.65L212.69,67.03L216.1,68.83L215.56,75.42L224.08,80.34z"
        />
        <path
          id="MK-36"
          title="Kavadarci"
          className="land"
          d="M463.95,339.66L466.09,341.49L469.36,341.49L477.61,339.01L480.72,340.25L482.28,342.53L482.12,350.81L485.23,366.11L485.23,380.38L486.63,386.58L489.28,391.12L489.9,398.77L494.59,401.68L494.59,401.68L510.08,416.26L513.69,417.4L516.62,424.02L525.91,430.19L536.41,430.19L554.49,419.18L554.49,419.18L556.13,422.91L556.77,434.98L559.98,435.83L560.62,439.23L552.16,445.17L544.85,447.21L548.31,464.19L534.08,466.4L532.08,472.96L538.38,474.62L538.38,474.62L523.44,477.64L510.9,490.54L503.36,489.27L492.46,481.95L491.61,478.55L485.99,477.77L482.01,485.9L478.35,488.35L457.29,492.03L446.88,500.93L446.88,500.93L444.77,488.87L438.86,488.66L434.81,486.81L434.11,465.08L428.35,456.84L428.98,448.39L434.11,437.46L433.49,435.81L417.93,434.99L414.97,426.74L414.5,410.64L416.84,404.45L416.99,398.66L412.4,386.58L413.8,376.45L413.18,369.42L411.62,370.25L409.29,375.21L406.33,375.21L399.64,367.77L399.64,364.57L399.64,364.57L405.87,355.77L409.45,356.39L411.1,358.44L411.1,358.44L418.63,368.39L437.77,368.18L439.95,366.53L440.57,361.36L441.97,359.91L446.64,360.73L453.33,364.66L454.89,364.04L456.29,359.29L461.58,355.98L462.04,343.98z"
        />
        <path
          id="MK-37"
          title="Karbinci"
          className="land"
          d="M513.57,195.23L517.8,192.78L519.72,193.29L521.64,200.13L524.84,201.5L535.71,200.99L537.03,199.48L537.03,199.48L546.7,209.37L554.63,215.18L566.52,217.23L573.56,216.89L580.21,223.55L586.6,224.07L587.59,222.63L587.59,222.63L604.48,223.79L604.48,223.79L604.33,231.55L604.33,231.55L595.9,237.25L595.32,246.69L590.63,254.85L590.63,254.85L589.1,252.93L584.75,252.76L565.76,256.51L559.36,256.51L545.3,242L539.29,240.98L529.95,231.58L525.6,228.85L511.66,227.31L507.06,217.4L506.17,204.75L508.72,199.45z"
        />
        <path
          id="MK-38"
          title="Karpoš"
          className="land"
          d="M274.32,126.33L277.86,126.79L281.12,129.29L286.48,137.09L293.7,138.34L295.2,137L295.2,137L295.05,140.51L295.05,140.51L290.1,143.07L288.24,150.71L292.23,156.24L293.8,156.24L294.51,151.85L295.71,151.47L295.71,151.47L295.35,160.39L295.35,160.39L275.07,160.39L274.52,159.16L274.52,159.16L274.13,144.35L274.13,144.35z"
        />
        <path
          id="MK-39"
          title="Kisela Voda"
          className="land"
          d="M297.17,149.64L299.86,152.85L303.61,153.35L310.37,160.39L320.88,163.91L328.02,171.25L328.02,171.25L323.99,173.14L319.85,181.7L317.59,182.75L313.85,181.3L313.16,170.63L310.37,167.43L304.74,168.94L301.08,168.49L301.08,168.49L299.86,166.43L299.86,161.4L295.35,160.39L295.35,160.39L295.71,151.47L295.71,151.47z"
        />
        <path
          id="MK-40"
          title="Kičevo"
          className="land"
          d="M155.34,320.73L157.51,329.19L162.78,327.3L165.22,329.19L166.84,332.16L166.84,339.18L166.84,339.18L163.6,341.34L157.79,353.3L157.79,353.3L152.03,347.82L138.63,341.61L134.77,337.02L133.96,332.3L133.96,332.3L135.38,327.03L140.46,325.95L148.98,321.08z"
        />
        <path
          id="MK-41"
          title="Konče"
          className="land"
          d="M567.05,300.99L574.9,303.08L579.95,307.5L596.11,308.85L602.89,315.57L609.75,325.18L609.03,329.4L606,331.32L605.85,333.44L607.44,334.78L621.77,337.94L621.77,337.94L621.51,347.06L623.4,354.37L623.4,354.37L621.58,358.39L614.22,356.66L606.29,359.92L604.21,362.69L604.21,362.69L593.15,361.84L579.73,365.1L572.37,367.97L570.79,372L569.06,371.31L569.06,371.31L567.32,361.84L571.07,353.97L569.63,350.9L560.83,347.26L559.97,345.22L559.97,345.22L557.94,336.89L547.89,323.29L547.89,323.29L547.84,320.95L555.05,314.8z"
        />
        <path
          id="MK-42"
          title="Kočani"
          className="land"
          d="M595.46,97.61L596.76,102.77L598.78,103.93L603.25,99.28L607.01,103.35L607.29,116.13L608.59,119.62L617.25,125.04L617.97,134.33L624.03,143.81L624.03,151.74L626.05,160.48L626.05,160.48L621.51,163.53L617.9,163.33L611.7,157.73L606.79,157.92L603.04,174.54L603.33,187.87L601.07,191.25L601.07,191.25L597.38,192.08L592.48,198.13L589.22,195.11L587.59,195.11L582.19,200.31L577.09,200.48L577.09,200.48L574.1,197.62L570.96,196.78L567.58,186.88L567.32,177.31L569.08,174.79L569.08,171.76L568.33,170.75L556.53,171.09L551.89,167.9L548,163.02L546.7,159.24L546.7,159.24L559.29,147.39L562.56,141L568.52,137.8L571.12,127.54L571.12,127.54L576.42,109.52L574.1,99.69L574.1,99.69L574.9,97.36L590.66,96.69z"
        />
        <path
          id="MK-43"
          title="Kratovo"
          className="land"
          d="M476.05,71.53L480.79,76.46L484.64,77.38L486.16,85.71L493.04,86.08L498,88.67L501.44,93.11L511.91,101.8L517.01,101.62L526.65,97.92L527.47,90.34L530.64,89.18L530.64,89.18L531.94,92.54L539.68,92.77L550.19,91.38L554.66,88.61L574.1,99.69L574.1,99.69L576.42,109.52L571.12,127.54L571.12,127.54L568.61,123.5L560.51,117.5L544.5,117.27L543.3,118.19L543.3,130.43L541.23,133.43L537.45,132.51L534.18,127.89L527.81,130.43L524.19,130.43L518.34,124.2L504.82,129.97L492.77,131.35L488.13,141.97L485.37,141.74L478.49,137.59L476.4,138.11L476.4,138.11L464.56,138.34L464.56,138.34L463.71,135.25L456.55,128.97L454.34,119.36L446.63,118.81L445.39,116.96L445.12,114.19L450.49,105.87L452.45,98.5L452.45,98.5L460.2,96.62L463.23,92L466.12,86.27L466.12,72.2L467.63,71.09z"
        />
        <path
          id="MK-44"
          title="Kriva Palanka"
          className="land"
          d="M557.75,4.61L565.89,13.03L571.69,16.06L573.83,14.11L576.28,15.63L579.45,15.5L582.27,21.29L586.1,23.33L585.4,25.81L589.86,32.88L592.55,34.19L592.72,37.48L595.58,36.8L596.89,41.04L600.08,43.52L599.59,45.74L605.3,56.49L605.85,60.1L610.39,60.35L615.12,63.03L616.76,73.38L620.05,77.96L618.55,79.55L618.55,79.55L603.98,89.2L600.8,93.85L595.46,97.61L595.46,97.61L590.66,96.69L574.9,97.36L574.1,99.69L574.1,99.69L554.66,88.61L550.19,91.38L539.68,92.77L531.94,92.54L530.64,89.18L530.64,89.18L533.66,85.83L533.83,80.75L531.42,75.19L530.73,66.17L527.29,58.53L527.64,50.43L530.39,45.56L530.39,42.79L527.64,41.4L518.68,41.4L513.17,38.61L502.16,22.85L499.46,15.76L499.46,15.76L516.5,11.56L522.91,4.53L528.06,4.61L545.73,0L552.29,0.3z"
        />
        <path
          id="MK-45"
          title="Krivogaštani"
          className="land"
          d="M259.48,391.2L266.85,386.3L268.79,387.1L271.47,398.78L285.8,397.99L289.79,401.09L289.79,401.09L284.83,406.5L287.66,413.83L295.13,415.41L290.95,433.42L282.14,442.52L275.94,443.21L275.94,443.21L273.11,441.53L270.73,437.97L266.84,436.46L266.84,436.46L266.7,434.41L262.97,433.42L266.55,420.76L266.1,416.6L258.94,406.11z"
        />
        <path
          id="MK-46"
          title=" Kruševo"
          className="land"
          d="M244.11,352.16L256.77,368.86L256.48,373.22L246.33,373.02L244.98,377.38L248.42,379.17L257.07,389.87L259.48,391.2L259.48,391.2L258.94,406.11L266.1,416.6L266.55,420.76L262.97,433.42L266.7,434.41L266.84,436.46L266.84,436.46L263.12,437.38L257.59,450.23L257.29,453.98L260.57,457.66L260.57,457.66L258.67,457.45L254.75,453.79L253.27,454.22L253.27,454.22L254.31,439.01L249.12,432.89L246.82,422.96L243.07,418.37L240.19,417.23L237.31,418.76L231.83,419.14L231.54,401.55L229.52,398.49L216.84,398.49L216.84,392.75L207.47,387.34L207.47,387.34L205.02,380.9L205.8,379.22L205.8,379.22L208.6,376.58L224.51,369.52L224.51,369.52L238.31,364.07L240.1,352.19z"
        />
        <path
          id="MK-47"
          title="Kumanovo"
          className="land"
          d="M388.52,36.83L392.81,37.32L392.81,37.32L400.71,41.14L402.71,43.54L402.18,48.91L395.88,51.17L395.25,53.29L395.14,56.54L396.93,59.51L397.03,67.55L394.51,75.6L395.88,85.48L403.65,95.64L408.48,96.62L413,92.82L423.92,89.01L425.92,89.15L428.65,91.97L439.47,94.79L441.78,92.82L442.61,88.72L446.92,87.31L449.02,88.3L452.45,98.5L452.45,98.5L450.49,105.87L445.12,114.19L445.39,116.96L446.63,118.81L454.34,119.36L456.55,128.97L463.71,135.25L464.56,138.34L464.56,138.34L463.98,141.89L461.09,144.11L451.18,148.54L447.87,148.72L440.98,139.49L436.85,138.38L428.59,139.31L420.47,147.25L419.78,152.97L412.05,161.79L412.05,161.79L402.7,153.59L394.83,153.73L388.77,156.16L382.82,149.75L375.38,146.76L373.74,144.78L373.74,144.78L373.42,132.65L369.27,132.65L358.54,124.38L357.6,121.87L357.6,121.87L357.26,117.25L351.2,113.97L348.55,113.97L348.55,113.97L347.06,113.12L347.8,107.56L356.73,100.85L359.33,83.58L360.82,83.01L366.03,85.29L366.98,84.58L366.98,74.15L373.04,71.58L373.57,70.01L371.56,58.43L371.35,44.35L371.35,44.35L373.01,45.38L377.99,44.6z"
        />
        <path
          id="MK-48"
          title="Lipkovo"
          className="land"
          d="M324.42,38.96L327.65,40.73L344.67,36.68L353.13,40.38L362.31,39.88L371.35,44.35L371.35,44.35L371.56,58.43L373.57,70.01L373.04,71.58L366.98,74.15L366.98,84.58L366.03,85.29L360.82,83.01L359.33,83.58L356.73,100.85L347.8,107.56L347.06,113.12L348.55,113.97L348.55,113.97L346.39,122.84L339.74,122.38L329.48,109.56L329.48,109.56L320.38,96.76L320.38,96.76L311.82,83.24L312.88,80.38L312.88,80.38L320.98,75.22L321.83,64.33L320.77,62.04L318.21,61.18L314.8,57.17L311.01,48.79L311.01,48.79L310.06,32.06L314.79,31.92L320.67,35.1z"
        />
        <path
          id="MK-49"
          title="Lozovo"
          className="land"
          d="M422.99,205.28L430.37,206.73L437.26,215.71L445,218.24L447.59,220.31L457.4,232.96L473.1,246.66L473.1,246.66L471.09,247.27L471.71,252.42L467.36,252.92L466.24,256.41L465.11,256.75L460.14,251.6L456.9,250.6L450.8,257.91L449.68,263.06L456.65,269.87L457.08,274.59L457.08,274.59L455.9,276.94L449.66,281.9L444.21,281.54L438.5,279.42L434.12,253.72L433.32,252.3L428.01,251.77L426.81,255.15L426.81,255.15L421.76,246.09L415.91,245.21L414.98,243.79L419.64,231.55L419.64,228.71L412.99,219.12L422.03,209z"
        />
        <path
          id="MK-50"
          title="Mavrovo i Rostuša"
          className="land"
          d="M39.91,224.59L43.2,226.8L43.86,225.75L43.86,225.75L46.98,229.32L53.88,232.57L58.55,236.64L71.13,238.27L80.27,228.23L102.8,230.4L108.48,239.35L109.09,247.21L111.94,250.46L115.59,248.29L127.16,248.57L127.16,250.73L122.9,255.07L128.99,265.63L133.45,270.77L134.09,274.36L134.09,274.36L129.29,274.84L127.67,276.19L120.76,276.19L109.6,286.47L102.7,286.74L99.45,290.8L99.65,293.51L102.09,295.4L107.42,294.54L107.42,294.54L109.85,308.09L109.85,324.98L106.04,329.21L105.73,338.07L101.92,340.18L98.33,347.01L95.09,350.25L91.23,351.6L82.94,349.46L82.94,349.46L81.62,342.71L70.84,338.07L63.23,327.94L56.88,322.45L43.24,320.34L35.63,314.85L29.79,308.29L29.79,308.29L31.56,306.88L29.34,295.97L22.57,285.14L22.18,278.63L20.63,275.02L22.46,261.63L19.82,252.48L19.32,242.85L24.3,229.67L26.3,227.48L29.3,226z"
        />
        <path
          id="MK-51"
          title="Makedonska Kamenica"
          className="land"
          d="M639.99,98.48L642.14,98.22L645.15,103.12L654.36,105.28L659.08,110.07L659.08,110.07L658.25,134.23L652.91,136.55L652.81,145.31L652.81,145.31L650.08,144L643.16,143.81L641.28,145.55L639.98,151.74L635.94,160.24L632.48,162.56L626.05,160.48L626.05,160.48L624.03,151.74L624.03,143.81L617.97,134.33L617.25,125.04L608.59,119.62L607.29,116.13L607.01,103.35L603.25,99.28L598.78,103.93L596.76,102.77L595.46,97.61L595.46,97.61L600.8,93.85L603.98,89.2L618.55,79.55L618.55,79.55L622.35,82.52L623.63,85.3L629.51,87.85L634.5,94.36z"
        />
        <path
          id="MK-52"
          title="Makedonski Brod"
          className="land"
          d="M236.22,212.48L243.03,220.03L257.74,244.27L263.62,249.51L268.53,250.16L270.44,248.66L270.44,248.66L276.11,249.4L278.79,252.47L278.79,266.27L282.24,273.43L284.92,275.98L285.26,274.78L285.26,274.78L285.69,277.64L283.73,281.23L278.33,287.44L278.91,303.34L278.91,303.34L276.55,303.34L275.8,315.66L273.86,320.43L265.5,331.55L264.91,344.85L256.25,340.89L252.74,341.48L250.21,345.85L244.24,348.23L244.11,352.16L244.11,352.16L240.1,352.19L238.31,364.07L224.51,369.52L224.51,369.52L223.54,365.15L213.96,361.48L212.17,359.53L210.71,356.08L210.55,349.82L209.09,348.74L205.35,348.74L195.93,353.27L188.36,354.32L188.36,354.32L188.97,347.82L194.04,345.66L210.28,329.46L210.08,327.03L199.73,319.73L196.06,308.67L196.06,308.67L196.48,291.88L194.86,288.64L190.59,286.74L184.1,286.47L182.27,284.85L181.3,281.23L181.3,281.23L182.88,275.38L182.27,268.34L179.02,261.57L178.62,254.8L175.77,252.63L174.35,248.57L174.76,246.4L178.82,245.04L179.63,243.42L179.63,233.39L181.26,229.59L189.24,228.66L189.24,228.66L193.44,227.69L197.7,223.62L202.77,215.49L209.88,207.62L215.76,203.27L221.04,203.6L221.04,203.6z"
        />
        <path
          id="MK-53"
          title="Mogila"
          className="land"
          d="M275.94,443.21L277.37,448.05L284.83,449.24L288.71,451.61L298.93,465.25L307.44,466.43L309.53,470.19L315.35,473.34L322.06,473.54L325.94,470.78L328.18,470.78L333.7,475.12L346.09,478.68L347.22,479.97L347.22,479.97L345.79,486.15L332.98,502.6L329.5,504.78L315.78,502.84L307.09,499.45L303.98,499.45L299.22,502.84L295.81,508.35L295.81,508.35L290.57,507.1L282.07,511.04L275.8,507.49L274.76,489.34L265.95,484.2L261.62,477.49L260.58,469.99L263.26,464.85L263.26,461.49L260.57,457.66L260.57,457.66L257.29,453.98L257.59,450.23L263.12,437.38L266.84,436.46L266.84,436.46L270.73,437.97L273.11,441.53z"
        />
        <path
          id="MK-54"
          title="Negotino"
          className="land"
          d="M472.15,278.84L475.7,281.82L481.55,283.15L487.4,291.44L490.39,290.78L493.87,283.98L495.99,283.65L499.73,289.79L502.59,302.72L504.46,304.22L515.16,304.05L520.02,305.71L521.01,314.17L523.38,315.66L534.46,322.45L539.81,323.94L547.89,323.29L547.89,323.29L557.94,336.89L559.97,345.22L559.97,345.22L551.49,352.4L547.64,359.14L538.6,355.77L532.32,350.93L527.8,350.35L525.71,351.81L518,360.45L512.93,376.12L506.98,380.36L507.42,383.43L512.16,390.75L509.62,400.98L494.59,401.68L494.59,401.68L489.9,398.77L489.28,391.12L486.63,386.58L485.23,380.38L485.23,366.11L482.12,350.81L482.28,342.53L480.72,340.25L477.61,339.01L469.36,341.49L466.09,341.49L463.95,339.66L463.95,339.66L463.82,319.69L463.82,319.69L466.56,318.92L467.96,320.58L469.6,329.74L473.46,329.58L476.57,322.12L473.71,315.49L478.56,305.88L478.19,301.9L474.7,300.4L465.36,303.72L462.38,300.73L462.75,296.26L471.46,283.81z"
        />
        <path
          id="MK-55"
          title="Novaci"
          className="land"
          d="M347.22,479.97L352.01,477.43L356.22,484.45L360.97,487.84L380.37,491.47L390.43,496.31L397.75,497.76L406.17,505.5L410.38,506.71L417.33,506.47L419.53,503.81L427.4,512.27L439.42,517.77L439.42,517.77L435.82,525.02L431.92,525.44L429.62,531.89L424.08,534.19L417.97,545.89L409.46,552.69L407.18,564.79L403.68,568.81L394.67,570.03L388.03,569.09L381.81,565.62L378.81,566.18L369.1,572.73L368.46,580.43L365.94,580.9L366.11,583.12L357.64,584.02L345.84,593.47L341.39,594.17L338.44,592.25L327.53,577.55L320.35,577.48L320.35,577.48L318.25,561.55L305.81,544.9L299.22,543.2L298.31,530.89L293.73,525.33L293.18,521.22L295.81,508.35L295.81,508.35L299.22,502.84L303.98,499.45L307.09,499.45L315.78,502.84L329.5,504.78L332.98,502.6L345.79,486.15z"
        />
        <path
          id="MK-56"
          title="Novo Selo"
          className="land"
          d="M754.56,372.48L752.48,373.12L749.71,377.77L750.02,380.49L752.21,381.8L754.63,386.62L754.07,391.74L756.34,400L755.85,401.02L752.2,401.22L752.35,404.18L731.95,404.08L723.74,406.04L714.72,403.96L710.45,405.94L710.45,405.94L709.61,385.03L707.59,382.35L707.59,382.35L712.21,376.03L712.21,361.65L721.15,353.78L728.95,343.99L729.38,334.01L729.38,334.01L752.17,332.27L752.17,332.27L755.85,338.38L755.1,343.14L751.17,347.37L752.23,354.1L754.22,355.27L753.73,360.71L758.07,365.94z"
        />
        <path
          id="MK-57"
          title="Oslomej"
          className="land"
          d="M181.3,281.23L182.27,284.85L184.1,286.47L190.59,286.74L194.86,288.64L196.48,291.88L196.06,308.67L196.06,308.67L191.61,315.68L181.46,322.71L178.62,332.16L175.98,335.94L172.12,338.64L166.84,339.18L166.84,339.18L166.84,332.16L165.22,329.19L162.78,327.3L157.51,329.19L155.34,320.73L155.34,320.73L160.96,309.19L160.69,290.57L160.69,290.57L160.96,288.91L165.42,285.66z"
        />
        <path
          id="MK-58"
          title="Ohrid"
          className="land"
          d="M86.67,473.46L96.1,473.04L97.93,469.01L103.21,466.86L102.8,458.8L106.45,451.54L110.31,449.12L110.92,445.89L112.95,444.01L123.71,443.74L128.78,441.59L137.1,441.59L148.07,445.35L155.98,450.73L170.4,452.88L174.82,451.74L174.82,451.74L177.19,456.99L177.19,456.99L170.74,466.3L145.84,481.93L136.25,493.15L132.56,508.02L132.01,520.69L127.95,523.37L128.13,529.22L131.45,535.07L130.9,543.1L128.5,545.78L120.76,546.51L117.25,549.19L114.67,559.16L115.14,569.9L108.89,580.72L108.89,580.72L95.6,582.15L89.85,580.81L86.18,577.96L86.18,577.96L95.09,571.98L98.54,560.47L103.41,551.63L106.86,540.92L107.06,532.88L105.85,510.62L106.66,499.62L96.91,493.72L93.87,489.15L90.82,479.49L87.17,477.61L85.62,475.29L85.62,475.29z"
        />
        <path
          id="MK-59"
          title="Petrovec"
          className="land"
          d="M373.74,144.78L375.38,146.76L382.82,149.75L388.77,156.16L394.83,153.73L402.7,153.59L412.05,161.79L412.05,161.79L409.28,169.65L409.14,174.14L409.14,174.14L398.69,181.96L388.99,184.27L389.12,199.38L384.21,217.32L379.02,216.61L375.7,211.81L373.58,211.81L369.33,218.56L367.6,218.56L367.07,212.52L364.41,214.48L362.15,214.3L358.83,213.77L355.7,211.36L355.7,211.36L358.17,206.49L358.7,185.87L357.24,183.74L343.29,180.93L343.29,180.93L338.01,180.45L337.34,178.23L338.93,174.72L338.93,174.72L342.21,170.86L342.21,170.86L352.29,164.88L354.28,161.99L356.94,161.99L362.09,165.55L369.89,164.88L370.89,162.88L368.37,156.01z"
        />
        <path
          id="MK-60"
          title="Pehčevo"
          className="land"
          d="M735.95,194.11L737.43,193.24L739.74,194.83L742.67,199.15L744.18,204.4L745.82,205.47L748.14,213.17L748.39,218.89L753.66,228.2L756.02,229.87L757.37,237.47L759.08,238.48L762.06,235.15L763.04,236.76L764.41,234.99L766.87,234.91L769.1,239.4L768.98,242.18L770.93,243.44L774.22,252.64L773.44,254.86L774.34,257.95L770.91,260.92L766.92,268.18L766.92,268.18L763.02,265.28L754.8,262.58L744.41,254.88L735.46,254.69L733.87,248.91L730.41,247.37L712.95,245.06L710.06,249.1L707.61,248.52L705.73,239.66L703.57,238.5L698.88,224.12L698.88,224.12L704.14,224.05L709.34,217.3L715.4,215.56L721.32,203.79L734.32,192.89L734.32,192.89z"
        />
        <path
          id="MK-61"
          title="Plasnica"
          className="land"
          d="M188.36,354.32L195.93,353.27L205.35,348.74L209.09,348.74L210.55,349.82L210.71,356.08L212.17,359.53L213.96,361.48L223.54,365.15L224.51,369.52L224.51,369.52L208.6,376.58L205.8,379.22L205.8,379.22L195.46,374.26L192.22,368.06L186.85,369.68L186.85,369.68L181.26,370.76L179.23,367.52L177.4,354.84L178.21,353.22L182.88,352.68z"
        />
        <path
          id="MK-62"
          title="Prilep"
          className="land"
          d="M399.64,364.57L399.64,367.77L406.33,375.21L409.29,375.21L411.62,370.25L413.18,369.42L413.8,376.45L412.4,386.58L416.99,398.66L416.84,404.45L414.5,410.64L414.97,426.74L417.93,434.99L433.49,435.81L434.11,437.46L428.98,448.39L428.35,456.84L434.11,465.08L434.81,486.81L438.86,488.66L444.77,488.87L446.88,500.93L446.88,500.93L440.86,504.72L439.01,513.25L439.42,517.77L439.42,517.77L427.4,512.27L419.53,503.81L417.33,506.47L410.38,506.71L406.17,505.5L397.75,497.76L390.43,496.31L380.37,491.47L360.97,487.84L356.22,484.45L352.01,477.43L347.22,479.97L347.22,479.97L346.09,478.68L333.7,475.12L328.18,470.78L325.94,470.78L322.06,473.54L315.35,473.34L309.53,470.19L307.44,466.43L298.93,465.25L288.71,451.61L284.83,449.24L277.37,448.05L275.94,443.21L275.94,443.21L282.14,442.52L290.95,433.42L295.13,415.41L287.66,413.83L284.83,406.5L289.79,401.09L289.79,401.09L296.17,397L303.18,388.68L326.54,386.1L329.67,384.52L333.25,379.96L339.97,377.58L342.36,367.87L348.23,363.7L348.23,363.7L354.44,364.3L356.68,365.88L358.92,370.64L364.59,370.05L366.23,369.25L368.47,364.5L369.82,354.98L378.62,353.78L384.59,351.4L395.48,357.95z"
        />
        <path
          id="MK-63"
          title="Probištip"
          className="land"
          d="M571.12,127.54L568.52,137.8L562.56,141L559.29,147.39L546.7,159.24L546.7,159.24L544.15,158.54L542.1,160.08L536.86,174.12L529.82,179.77L526.88,180.46L525.99,182.51L526.37,185.42L537.03,199.48L537.03,199.48L535.71,200.99L524.84,201.5L521.64,200.13L519.72,193.29L517.8,192.78L513.57,195.23L513.57,195.23L511.41,192.59L508.65,193.38L504.92,191.8L498.8,192.03L498.8,192.03L494.84,186.47L497.42,181.86L497.42,177.48L491.57,174.03L485.54,162.27L473.15,152.58L472.98,145.2L476.4,138.11L476.4,138.11L478.49,137.59L485.37,141.74L488.13,141.97L492.77,131.35L504.82,129.97L518.34,124.2L524.19,130.43L527.81,130.43L534.18,127.89L537.45,132.51L541.23,133.43L543.3,130.43L543.3,118.19L544.5,117.27L560.51,117.5L568.61,123.5z"
        />
        <path
          id="MK-64"
          title="Radoviš"
          className="land"
          d="M604.33,231.55L607.29,232.24L615.38,238.79L626.34,237.64L629.52,232.05L631.68,231.86L634.42,236.87L641.21,240.34L648.57,247.08L657.38,249.01L657.38,249.01L658.6,261.48L664.55,266.29L664.37,268.46L658.42,277.84L659.91,286.69L659.91,286.69L659.1,287.7L650.01,288.66L644.24,299.43L646.11,304.62L650.73,309.81L652.61,317.69L652.75,327.29L651.02,329.98L648.57,332.67L646.84,332.48L637.89,323.64L631.4,320.76L628.51,325.37L620.57,329.02L622.74,334.97L621.77,337.94L621.77,337.94L607.44,334.78L605.85,333.44L606,331.32L609.03,329.4L609.75,325.18L602.89,315.57L596.11,308.85L579.95,307.5L574.9,303.08L567.05,300.99L567.05,300.99L563.5,298.85L562.05,295.97L561.48,285.58L557.29,275.96L558.3,273.65L563.35,268.46L565.23,268.07L575.33,273.07L580.96,272.88L590.63,254.85L590.63,254.85L595.32,246.69L595.9,237.25z"
        />
        <path
          id="MK-65"
          title="Rankovce"
          className="land"
          d="M499.46,15.76L502.16,22.85L513.17,38.61L518.68,41.4L527.64,41.4L530.39,42.79L530.39,45.56L527.64,50.43L527.29,58.53L530.73,66.17L531.42,75.19L533.83,80.75L533.66,85.83L530.64,89.18L530.64,89.18L527.47,90.34L526.65,97.92L517.01,101.62L511.91,101.8L501.44,93.11L498,88.67L493.04,86.08L486.16,85.71L484.64,77.38L480.79,76.46L476.05,71.53L476.05,71.53L481.82,71.28L483.2,69.43L482.09,63.87L478.38,57.76L478.24,46.83L475.41,39.79L476.52,37.38L482.44,36.45L484.5,34.04L482.74,20.77L482.74,20.77z"
        />
        <path
          id="MK-66"
          title="Resen"
          className="land"
          d="M177.19,456.99L180.51,462.15L186.23,462.4L190.66,485.34L194.7,491.57L194.7,491.57L194.58,493.76L197.35,496.81L197.35,505.04L194.35,510.22L188.81,514.79L187.66,523.31L189.74,525.75L196.38,527.76L197.11,534.82L203.2,542.86L203.94,548.46L206.15,550.4L218.88,550.16L222.94,552.35L226.26,556.97L227.55,562.81L238.06,564.03L239.72,565.24L241.01,570.35L240.64,575.46L243.78,577.4L244.52,579.35L240.82,593.99L240.82,593.99L232.68,588.03L215,596.36L201.98,598.89L165.14,600L162.61,598.89L161,583.8L151.67,575.17L145.04,573.81L138.34,576.56L124.83,572.26L119,574.29L113.31,579.64L108.89,580.72L108.89,580.72L115.14,569.9L114.67,559.16L117.25,549.19L120.76,546.51L128.5,545.78L130.9,543.1L131.45,535.07L128.13,529.22L127.95,523.37L132.01,520.69L132.56,508.02L136.25,493.15L145.84,481.93L170.74,466.3z"
        />
        <path
          id="MK-67"
          title="Rosoman"
          className="land"
          d="M463.82,319.69L463.95,339.66L463.95,339.66L462.04,343.98L461.58,355.98L456.29,359.29L454.89,364.04L453.33,364.66L446.64,360.73L441.97,359.91L440.57,361.36L439.95,366.53L437.77,368.18L418.63,368.39L411.1,358.44L411.1,358.44L413.02,345.8L413.02,345.8L416.6,342.32L421.58,341.9L423.92,339.22L422.83,332.38L423.76,327.83L428.43,328.45L434.5,332.18L439.17,332.18L445.7,323.27L451.15,325.75L455.51,325.75L456.29,319.96L458.93,318.3z"
        />
        <path
          id="MK-68"
          title="Saraj"
          className="land"
          d="M234.33,97.25L238.48,104.23L238.48,104.23L239.22,111.79L241.31,117.11L244.11,118.67L251.56,115.54L254.12,118.36L255.05,123.67L261.8,137.71L266.46,143.64L274.13,144.35L274.13,144.35L274.52,159.16L274.52,159.16L273.43,160.99L266.57,161.32L261.42,164.27L244.5,179.37L232.73,183.97L231.19,187.92L231.19,187.92L219.98,178.72L223.12,151.92L214.1,145.09L207.27,142.31L207.27,142.31L208.21,139.83L219.59,135.89L216.55,122.54L222.68,117.27L221.45,110.04L215.57,111.68L210.67,106.41L215.32,103.12L230.77,101.48z"
        />
        <path
          id="MK-69"
          title="Sveti Nikole"
          className="land"
          d="M476.4,138.11L472.98,145.2L473.15,152.58L485.54,162.27L491.57,174.03L497.42,177.48L497.42,181.86L494.84,186.47L498.8,192.03L498.8,192.03L497.94,195.22L494.5,197.06L493.98,198.67L494.32,201.67L497.77,207.65L497.94,212.48L494.24,214.78L485.29,228.36L478.74,231.12L482.36,240.54L482.19,243.3L480.64,243.76L479.6,246.52L482.19,248.36L482.88,251.35L481.84,252.5L478.06,251.58L473.1,246.66L473.1,246.66L457.4,232.96L447.59,220.31L445,218.24L437.26,215.71L430.37,206.73L422.99,205.28L422.99,205.28L412.38,192L409.11,179.33L409.14,174.14L409.14,174.14L409.28,169.65L412.05,161.79L412.05,161.79L419.78,152.97L420.47,147.25L428.59,139.31L436.85,138.38L440.98,139.49L447.87,148.72L451.18,148.54L461.09,144.11L463.98,141.89L464.56,138.34L464.56,138.34z"
        />
        <path
          id="MK-70"
          title="Sopište"
          className="land"
          d="M274.52,159.16L275.07,160.39L295.35,160.39L295.35,160.39L299.86,161.4L299.86,166.43L301.08,168.49L301.08,168.49L298.82,183.59L286.26,184.38L281.56,182.02L274.3,181.23L269.59,182.54L267.43,184.9L268.81,193.83L279.01,195.4L280.58,196.98L280.18,199.08L277.44,199.08L274.3,201.44L270.77,217.17L266.45,219.27L266.06,222.41L270.18,225.03L271.52,228.94L269.22,233.03L268.07,239.17L268.07,243.78L270.44,248.66L270.44,248.66L268.53,250.16L263.62,249.51L257.74,244.27L243.03,220.03L236.22,212.48L236.22,212.48L238.37,196.76L233.22,193.15L231.01,190.2L231.19,187.92L231.19,187.92L232.73,183.97L244.5,179.37L261.42,164.27L266.57,161.32L273.43,160.99z"
        />
        <path
          id="MK-71"
          title="Staro Nagoričane"
          className="land"
          d="M467.23,14.06L474.96,21.54L482.74,20.77L482.74,20.77L484.5,34.04L482.44,36.45L476.52,37.38L475.41,39.79L478.24,46.83L478.38,57.76L482.09,63.87L483.2,69.43L481.82,71.28L476.05,71.53L476.05,71.53L467.63,71.09L466.12,72.2L466.12,86.27L463.23,92L460.2,96.62L452.45,98.5L452.45,98.5L449.02,88.3L446.92,87.31L442.61,88.72L441.78,92.82L439.47,94.79L428.65,91.97L425.92,89.15L423.92,89.01L413,92.82L408.48,96.62L403.65,95.64L395.88,85.48L394.51,75.6L397.03,67.55L396.93,59.51L395.14,56.54L395.25,53.29L395.88,51.17L402.18,48.91L402.71,43.54L400.71,41.14L392.81,37.32L392.81,37.32L405.84,35.76L407.28,20.57L415.44,12.64L420.9,12.29L430.06,24.27L451.36,7.45L460.94,13.87z"
        />
        <path
          id="MK-72"
          title="Struga"
          className="land"
          d="M70.32,372.13L82.91,396.9L84.13,416.3L92.04,428.41L91.43,440.78L89.2,448.04L83.52,456.92L84.13,462.56L86.76,465.52L86.67,473.46L86.67,473.46L85.62,475.29L85.62,475.29L65.05,474.38L62.2,477.07L56.11,493.72L54.06,507.85L54.06,507.85L43.88,505.22L42.79,503.68L40.49,499.5L40.17,495.64L43.36,491.18L40.36,482.35L31.13,464.93L28.55,465.13L25.38,462.14L20.68,449.58L20.68,449.58L31.63,451.9L38.65,451.32L44.14,447.54L51.39,447.54L54.9,445.51L57.1,443.18L57.1,440.27L54.46,436.19L52.05,436.19L49.19,436.78L44.58,440.85L33.38,438.81L23.94,439.1L19.63,440.66L19.63,440.66L17.31,435.35L18.17,430.28L11.93,410.31L11.88,405.77L13.27,403.06L17.39,405.66L21.21,404.71L27.71,393.15L31.18,379.49L31.18,379.49L57.94,380.19L62.2,377.77L67.59,370.98L67.59,370.98L68.5,369.95L69.55,371.21L69.55,371.21z"
        />
        <path
          id="MK-73"
          title="Strumica"
          className="land"
          d="M623.4,354.37L626.99,351.1L630.6,344.95L633.63,344.57L645.18,350.14L651.38,358.19L660.18,358.39L667.98,355.89L673.6,351.85L673.6,351.85L677.28,355.7L679.59,363.56L677.14,372.96L681.33,375.26L689.26,373.15L692.29,381.2L696.62,384.27L699.22,384.65L707.59,382.35L707.59,382.35L709.61,385.03L710.45,405.94L710.45,405.94L708.42,407.98L702.15,409.3L700.71,412.8L700.71,412.8L680.03,402.08L675.84,404L672.52,411.85L669.64,415.1L665.02,413.38L660.26,417.02L651.74,416.25L651.74,413.57L646.26,408.4L631.68,409.55L631.25,403.62L625.77,393.66L630.39,381.39L627.35,377.75L618.55,377.56L614.8,375.84L608.74,369.7L604.21,362.69L604.21,362.69L606.29,359.92L614.22,356.66L621.58,358.39z"
        />
        <path
          id="MK-74"
          title="Studeničani"
          className="land"
          d="M338.93,174.72L337.34,178.23L338.01,180.45L343.29,180.93L343.29,180.93L342.02,183.22L328.18,191.05L327.39,196.75L321.67,204.75L321,210.98L319.67,212.58L315.15,212.4L313.68,206.89L311.42,206.71L308.89,209.55L306.9,214L307.3,222.54L313.28,233.02L311.9,241.94L311.9,241.94L303.34,244.6L300.15,247.22L294.76,258.34L289.12,261.94L287.65,264.23L285.26,274.78L285.26,274.78L284.92,275.98L282.24,273.43L278.79,266.27L278.79,252.47L276.11,249.4L270.44,248.66L270.44,248.66L268.07,243.78L268.07,239.17L269.22,233.03L271.52,228.94L270.18,225.03L266.06,222.41L266.45,219.27L270.77,217.17L274.3,201.44L277.44,199.08L280.18,199.08L280.58,196.98L279.01,195.4L268.81,193.83L267.43,184.9L269.59,182.54L274.3,181.23L281.56,182.02L286.26,184.38L298.82,183.59L301.08,168.49L301.08,168.49L304.74,168.94L310.37,167.43L313.16,170.63L313.85,181.3L317.59,182.75L319.85,181.7L323.99,173.14L328.02,171.25L328.02,171.25L333.53,167.52L333.53,167.52L335.31,167.99z"
        />
        <path
          id="MK-75"
          title="Tearce"
          className="land"
          d="M176.78,72.17L189.53,85.21L196.3,96.42L203.98,100.06L205.11,103.7L204.66,108.24L198.79,114.3L193.37,125.2L188.63,127.63L175.08,130.35L174.18,132.55L174.18,132.55L168.47,130.15L167.66,128.52L167.66,128.52L168.47,120.08L168.47,120.08L165.02,114.63L157.91,112.72L152.84,100.46L150.2,97.46L143.5,93.38L135.12,90.32L135.12,90.32L139.32,87.07L158.67,84.11L161.63,79.34L169.98,77.37z"
        />
        <path
          id="MK-76"
          title="Tetovo"
          className="land"
          d="M135.12,90.32L143.5,93.38L150.2,97.46L152.84,100.46L157.91,112.72L165.02,114.63L168.47,120.08L168.47,120.08L167.66,128.52L167.66,128.52L168.47,130.15L174.18,132.55L174.18,132.55L174.78,135.15L174.78,135.15L172.93,135.59L169.69,141.31L173.54,143.49L175.57,147.57L179.83,149.47L179.63,152.46L173.54,154.37L171.34,157.42L171.34,157.42L165.83,156.54L157.71,151.65L155.88,152.73L153.65,157.63L152.46,157.5L152.46,157.5L141.06,147.02L123.61,142.94L118.94,143.21L108.79,151.1L98.84,155.46L95.39,158.99L90.74,161.18L90.74,161.18L90.89,157.67L85.08,148.18L84.24,142.32L86.18,136.91L85.98,128.5L101.61,108.67L111.57,100.68L127.77,100.87z"
        />
        <path
          id="MK-77"
          title="Centar"
          className="land"
          d="M295.05,140.51L294.97,143.57L297.42,143.33L298.34,145.18L298.34,145.18L296.48,148.82L297.17,149.64L297.17,149.64L295.71,151.47L295.71,151.47L294.51,151.85L293.8,156.24L292.23,156.24L288.24,150.71L290.1,143.07z"
        />
        <path
          id="MK-78"
          title=" Centar Župa"
          className="land"
          d="M15.58,345.64L30.56,336.8L34.04,330.89L46.73,331.74L50.22,336.8L52.12,344.4L55.3,347.35L58.78,347.35L61,354.52L61,366.32L65.76,367.17L67.59,370.98L67.59,370.98L62.2,377.77L57.94,380.19L31.18,379.49L31.18,379.49L32.61,377.81L26.36,369.19L18.13,364.31L13.43,347.1z"
        />
        <path
          id="MK-79"
          title=" Čair"
          className="land"
          d="M300.61,139.36L300.16,145.18L300.16,145.18L298.34,145.18L298.34,145.18L297.42,143.33L294.97,143.57L295.05,140.51L295.05,140.51L295.2,137L295.2,137L295.23,135.85L295.23,135.85z"
        />
        <path
          id="MK-80"
          title="Čaška"
          className="land"
          d="M311.9,241.94L313.68,243.68L338.59,243.65L338.59,243.65L346.54,258.42L352.26,256.46L369.03,261.61L377.27,287.16L380.47,286.63L385.65,282.73L387.52,294.61L390.18,297.62L400.06,299.71L400.06,299.71L399.57,307.57L401.43,310.22L408.74,315.35L409.94,317.83L408.61,324.73L404.49,329.32L403.83,337.28L404.76,340.81L413.02,345.8L413.02,345.8L411.1,358.44L411.1,358.44L409.45,356.39L405.87,355.77L399.64,364.57L399.64,364.57L395.48,357.95L384.59,351.4L378.62,353.78L369.82,354.98L368.47,364.5L366.23,369.25L364.59,370.05L358.92,370.64L356.68,365.88L354.44,364.3L348.23,363.7L348.23,363.7L345.94,359.14L343.1,356.76L330.27,352.99L326.99,343.66L313.41,344.85L310.27,343.27L306.24,337.31L305.8,327.78L303.56,319.24L298.63,316.65L296.25,310.5L293.71,307.71L278.91,303.34L278.91,303.34L278.33,287.44L283.73,281.23L285.69,277.64L285.26,274.78L285.26,274.78L287.65,264.23L289.12,261.94L294.76,258.34L300.15,247.22L303.34,244.6z"
        />
        <path
          id="MK-81"
          title="Češinovo-Obleševo"
          className="land"
          d="M546.7,159.24L548,163.02L551.89,167.9L556.53,171.09L568.33,170.75L569.08,171.76L569.08,174.79L567.32,177.31L567.58,186.88L570.96,196.78L574.1,197.62L577.09,200.48L577.09,200.48L577.93,209.2L584.33,216.42L587.59,222.63L587.59,222.63L586.6,224.07L580.21,223.55L573.56,216.89L566.52,217.23L554.63,215.18L546.7,209.37L537.03,199.48L537.03,199.48L526.37,185.42L525.99,182.51L526.88,180.46L529.82,179.77L536.86,174.12L542.1,160.08L544.15,158.54z"
        />
        <path
          id="MK-82"
          title="Čučer Sandevo"
          className="land"
          d="M311.01,48.79L314.8,57.17L318.21,61.18L320.77,62.04L321.83,64.33L320.98,75.22L312.88,80.38L312.88,80.38L311.12,79.79L306.62,81.3L299.48,95.93L297.23,110.55L297.98,124.66L294.86,126.54L293.7,125.31L293.7,125.31L288.11,116.79L283.45,119.61L283.22,126.48L286.71,127.73L291.13,134.91L294.56,134.07L294.56,134.07L295.23,135.85L295.23,135.85L295.2,137L295.2,137L293.7,138.34L286.48,137.09L281.12,129.29L277.86,126.79L274.32,126.33L274.32,126.33L274.32,120.63L269.06,109.04L259.82,99.97L259.82,99.97L260.4,98.67L256.21,88.67L262.5,75.21L262.81,71.58L279.07,59.17L281.77,54.82L283.21,47.67L288.81,47.67L293.93,49.86L304.17,46.73z"
        />
        <path
          id="MK-83"
          title=" Štip"
          className="land"
          d="M498.8,192.03L504.92,191.8L508.65,193.38L511.41,192.59L513.57,195.23L513.57,195.23L508.72,199.45L506.17,204.75L507.06,217.4L511.66,227.31L525.6,228.85L529.95,231.58L539.29,240.98L545.3,242L559.36,256.51L565.76,256.51L584.75,252.76L589.1,252.93L590.63,254.85L590.63,254.85L580.96,272.88L575.33,273.07L565.23,268.07L563.35,268.46L558.3,273.65L557.29,275.96L561.48,285.58L562.05,295.97L563.5,298.85L567.05,300.99L567.05,300.99L555.05,314.8L547.84,320.95L547.89,323.29L547.89,323.29L539.81,323.94L534.46,322.45L523.38,315.66L521.01,314.17L520.02,305.71L515.16,304.05L504.46,304.22L502.59,302.72L499.73,289.79L495.99,283.65L493.87,283.98L490.39,290.78L487.4,291.44L481.55,283.15L475.7,281.82L472.15,278.84L472.15,278.84L466.36,275.18L457.08,274.59L457.08,274.59L456.65,269.87L449.68,263.06L450.8,257.91L456.9,250.6L460.14,251.6L465.11,256.75L466.24,256.41L467.36,252.92L471.71,252.42L471.09,247.27L473.1,246.66L473.1,246.66L478.06,251.58L481.84,252.5L482.88,251.35L482.19,248.36L479.6,246.52L480.64,243.76L482.19,243.3L482.36,240.54L478.74,231.12L485.29,228.36L494.24,214.78L497.94,212.48L497.77,207.65L494.32,201.67L493.98,198.67L494.5,197.06L497.94,195.22z"
        />
        <path
          id="MK-84"
          title="Šuto Orizari"
          className="land"
          d="M294.56,134.07L291.13,134.91L286.71,127.73L283.22,126.48L283.45,119.61L288.11,116.79L293.7,125.31L293.7,125.31z"
        />
        <path
          id="MK-LKD"
          title="Lake Dojran"
          className="land"
          d="M693.17,474.51L692.8,476.3L692.8,476.3L686.99,470.75L682.13,461.53L681.03,452.46L684.45,446.46L688.21,443.82L694.73,443.38L698.25,445.46L698.25,445.46L696.41,468.79z"
        />
        <path
          id="MK-LKO"
          title="Lake Ohrid"
          className="land"
          d="M85.62,475.29L87.17,477.61L90.82,479.49L93.87,489.15L96.91,493.72L106.66,499.62L105.85,510.62L107.06,532.88L106.86,540.92L103.41,551.63L98.54,560.47L95.09,571.98L86.18,577.96L86.18,577.96L66.16,512.38L65.01,510.2L54.06,507.85L54.06,507.85L56.11,493.72L62.2,477.07L65.05,474.38z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Macedonia))
