import React from 'react'
import { convertKebabCaseWordToTitleCase } from '../lib/methodsUtil'

const StyledAnswer = ({ RandomAnimation, answer, color, isAnnyangSupported }) => {
  let preAnswer = ''
  let coloredAnswer = ''

  if (answer) {
    const splitsAnswer = isAnnyangSupported ? answer.split(':') : [null, answer]
    preAnswer = splitsAnswer[0]
    coloredAnswer = convertKebabCaseWordToTitleCase(splitsAnswer[1])
  }

  return (
    <>
      <div className="show-answer-introduction">{preAnswer}</div>
      {RandomAnimation ? (
        <RandomAnimation className="show-answer-answer-shadow">
          {coloredAnswer}
          <RandomAnimation className={`show-answer-answer-${color}`}>
            {coloredAnswer}
          </RandomAnimation>
        </RandomAnimation>
      ) : (
        <div className="show-answer-answer-shadow">
          {coloredAnswer}
          <div className={`show-answer-answer-${color}`}>{coloredAnswer}</div>
        </div>
      )}
    </>
  )
}
export default StyledAnswer
