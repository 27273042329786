import { lightSvgFillColors } from '../../../src/lib/dataUtil'

export const getSvgScale = (width, height, windowWidth, windowHeight) => {
  const widthRadio = width / (windowWidth * 0.9)
  const heightRatio = height / (windowHeight * 0.6)
  const biggestRatio = widthRadio > heightRatio ? widthRadio : heightRatio
  if (windowWidth <= 480) {
    return biggestRatio > 1 ? biggestRatio * 0.65 : 0.65
  } else {
    return biggestRatio > 1 ? biggestRatio : 1
  }
}

export const getCircleFill = color => (lightSvgFillColors.includes(color) ? 'red' : 'yellow')
