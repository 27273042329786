import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import '../../src/styles/scss/App.scss'
import annyang from '../lib/annyangSetUp'
import {
  translateFrom,
  common,
  addAcceptedOrtographCommands,
  commonTranslateArray
} from '../lib/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import {
  isLearnPage,
  isCapitalsPage,
  isFlagsPage,
  isLearnGeographyPage,
  isAdditionsPage,
  isSubtractionsPage,
  isMultiplicationsPage,
  isLearnMathematicsPage,
  isRedirectButtonClick,
  isSpeakRequest,
  isSpeakSoundDisabled,
  createBorderRadius,
  isDivisionPath,
  getDivisionPageTitle,
  isTranscriptAMatch,
  capitalizePagename
} from '../lib/methodsUtil'
import { needDonationsTopics, temporarilyUnlockTopics } from '../lib/dataUtil'
import PageHeader from '../../src/components/PageHeader'
import SEO from '../../src/components/SEO'
import SpeechButton from '../../src/components/SpeechButton'
import AnnyangNotSupportedMenuButton from '../../src/components/AnnyangNotSupportedMenuButton'
import AppListeningSpeakingVisual from '../../src/components/AppListeningSpeakingVisual'
import NavigationButtons from '../../src/components/NavigationButtons'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction, updateIsListeningAction } from '../redux/actions/actions'
import { saveTranscriptAction } from '../redux/actions/actions'
import { isTopicPathMatch, wasPathChanged } from '../../src/router/routerUtil'

const getUpdatedPageName = pageName =>
  isLearnGeographyPage(pageName)
    ? 'learn_geography_page'
    : pageName.indexOf('level_divisions_page') > -1
      ? 'level_divisions_page'
      : isLearnMathematicsPage(pageName)
        ? 'learn_mathematics_page'
        : pageName

const updateWithExistingDataNavigation = (navigationObject, pageName) => {
  if (isLearnGeographyPage(pageName)) {
    const geographyTopic = isCapitalsPage(pageName)
      ? 'capitals'
      : isFlagsPage(pageName)
        ? 'flags'
        : 'locations'
    navigationObject[common(geographyTopic)] = `/geography/${geographyTopic}`
    navigationObject[common('geography').toLowerCase()] = '/geography'
  }
  if (isLearnMathematicsPage(pageName)) {
    const mathematicsTopic = isAdditionsPage(pageName)
      ? 'additions'
      : isSubtractionsPage(pageName)
        ? 'subtractions'
        : isMultiplicationsPage(pageName)
          ? 'multiplications'
          : 'divisions'
    navigationObject[common(mathematicsTopic).toLowerCase()] = `/mathematics/${mathematicsTopic}`
    navigationObject[common('mathematics').toLowerCase()] = '/mathematics'
  }
  return navigationObject
}

const createCustomNavigationCommands = (
  pageName,
  translate,
  subTopics,
  topicNavigationPath,
  lngCode,
  comingSoonTopics = []
) => {
  let navigationObject = {}
  subTopics
    .filter(topic => !comingSoonTopics.includes(topic))
    .forEach(topic => {
      const newRoute = topicNavigationPath
        ? `/${topicNavigationPath}/${topic.replace(/_/g, '-')}`
        : `/${topic.replace(/_/g, '-')}`
      navigationObject[
        topicNavigationPath ? translate(topic).toLowerCase() : common(topic).toLowerCase()
      ] = newRoute
    })
  if (isLearnPage(pageName)) {
    navigationObject = updateWithExistingDataNavigation(navigationObject, pageName)
  }
  return addAcceptedOrtographCommands(lngCode, navigationObject)
}

class TopicNavigationPage extends Component {
  componentDidMount() {
    const { dispatch, lngCode, navigationCommands, comingSoonTopics = [] } = this.props
    const filteredNavigationCommands = navigationCommands.filter(
      topic => !comingSoonTopics.includes(topic)
    )
    annyang.bindNavigationCommands(
      dispatch,
      commonTranslateArray(filteredNavigationCommands),
      lngCode
    )
    annyang.start()
    dispatch(updateIsListeningAction(true))
    annyang.addTranscriptListener(dispatch)
  }

  componentDidUpdate(oldProps) {
    const {
      dispatch,
      lngCode,
      pageName,
      topicNavigationPath,
      subTopics,
      transcript,
      comingSoonTopics
    } = this.props

    // The below is to prevent a weird bug on the questons page only. SpeechButton needs 'speak' to NOT be reset
    // so it resets the count itself internally. But the question page needs it reset at the right place
    // to avoid speaking on questions links clicks
    if (isSpeakRequest(transcript) && pageName === 'questions_page') {
      dispatch(saveTranscriptAction([]))
    }

    const translate = translateFrom(getUpdatedPageName(pageName))
    const customNavigationCommands = createCustomNavigationCommands(
      pageName,
      translate,
      subTopics,
      topicNavigationPath,
      lngCode,
      comingSoonTopics
    )

    if (oldProps.transcript !== transcript) {
      const foundCommand = Object.keys(customNavigationCommands).find(navCommand =>
        transcript.map(ts => ts.toLowerCase()).includes(navCommand)
      )
      if (foundCommand) {
        dispatch(changePathAction(customNavigationCommands[foundCommand]))
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const { language, gender, soundLevel } = this.props
    const {
      transcript: nextTranscript,
      path: nextPath,
      navigationCommands,
      topicNavigationPath,
      allowedContentKeyWords = [],
      subTopics,
      lngCode,
      pageName,
      language: nextLanguage,
      gender: nextGender,
      soundLevel: nextSoundLevel,
      comingSoonTopics = []
    } = nextProps

    const translatedNavigationCommands = Object.keys(
      createCustomNavigationCommands(
        pageName,
        translateFrom(getUpdatedPageName(pageName)),
        subTopics,
        topicNavigationPath,
        lngCode,
        comingSoonTopics
      )
    )

    const allAllowedWords = [...translatedNavigationCommands, ...allowedContentKeyWords]
    const isLanguageButtonClick = language !== nextLanguage // LanguagePage
    const isSettingsButtonClick = gender !== nextGender || soundLevel !== nextSoundLevel // SettingsPage

    const filteredNavigationCommands = navigationCommands.filter(
      topic => !comingSoonTopics.includes(topic)
    )

    return (
      isTranscriptAMatch(nextTranscript, allAllowedWords) ||
      isRedirectButtonClick(nextPath, filteredNavigationCommands) ||
      isLanguageButtonClick ||
      isSettingsButtonClick ||
      isSpeakRequest(nextTranscript)
    )
  }

  componentWillUnmount() {
    annyang.abort()
  }

  render() {
    const {
      user,
      path,
      dispatch,
      pageName,
      topics,
      topicNavigationPath,
      subTopics,
      comingSoonTopics = [],
      newTopics = [],
      children,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel,
      temporarilyUnlocked,
      defaultStyle,
      borderRadius = 0.5,
      disableSpeechButton = false,
      allowedContentKeyWords,
      extraIntro,
      SEODescription,
      canonicalPath,
      pageNeedsKeyboardTyping = false,
      titleOnly = false
    } = this.props

    const useExistingData = isLearnGeographyPage(pageName) || isLearnMathematicsPage(pageName)
    const updatedPageName = getUpdatedPageName(pageName)
    const translate = translateFrom(updatedPageName)
    let intro = extraIntro ? `${translate('intro')} ${extraIntro}` : translate('intro')
    // TODO - try common(topic) || translate(topic)
    const topicsMap = topics.map(topic => translate(topic))

    const readOutLoudNavigationText = `${intro} ${topicsMap.join('. ')}`
    const isSpeakDisabled = isSpeakSoundDisabled(soundLevel)
    if (isSpeakRequest(transcript) && !isSpeakDisabled) {
      readOutLoud(readOutLoudNavigationText, dispatch, language, gender)
    }

    const pageClassName = useExistingData ? pageName : updatedPageName

    const isOverOneDayShare = temporarilyUnlocked
      ? Date.now() - parseInt(temporarilyUnlocked, 10) > 86400000
      : true

    return (
      <div className={`${pageClassName.replace(/_/g, '-')} top-navigation`}>
        <SEO
          title={`Mini Einstein - ${capitalizePagename(pageName)}`}
          description={SEODescription}
          path={canonicalPath || path}
        />

        <div
          className="navigation-box"
          style={defaultStyle || createBorderRadius(borderRadius, true)}>
          <PageHeader
            title={
              useExistingData
                ? common(pageName.replace(/_page/g, ''))
                : topicNavigationPath && isDivisionPath(topicNavigationPath)
                  ? translateFrom(getDivisionPageTitle(topicNavigationPath))('title')
                  : translate('title')
            }
            intro={intro}
            topics={topicsMap}
            titleOnly={titleOnly}
          />

          {children && children(translate)}

          {!pageNeedsKeyboardTyping && <AppListeningSpeakingVisual />}

          <AnnyangNotSupportedMenuButton />

          <NavigationButtons
            user={user}
            pageName={pageName}
            lngCode={lngCode}
            customNavigationCommands={createCustomNavigationCommands(
              pageName,
              translate,
              subTopics,
              topicNavigationPath
            )}
            needDonationsTopics={needDonationsTopics}
            comingSoonTopics={comingSoonTopics}
            newTopics={newTopics}
            isOverOneDayShare={isOverOneDayShare}
            dispatch={dispatch}
          />
        </div>

        {!isSpeakDisabled && !pageNeedsKeyboardTyping && (
          <SpeechButton
            className={`${updatedPageName.replace(/_/g, '-')}-speech-button`}
            text={readOutLoudNavigationText}
            disableSpeechButton={disableSpeechButton}
            forceSpeechButtonReset={isTranscriptAMatch(transcript, allowedContentKeyWords)}
          />
        )}

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}

        {isTopicPathMatch(path, needDonationsTopics) && <Navigate to="/support" replace />}

        {isTopicPathMatch(path, temporarilyUnlockTopics) && isOverOneDayShare && (
          <Navigate to="/temporarily-unlock" replace />
        )}
      </div>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(TopicNavigationPage)
