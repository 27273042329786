import actions from '../actions/action-types'
import { createInitialWinningImagesList } from '../../lib/themeUtil'
import { getLngCode } from '../../lib/languageUtil'
import { getRandomItemFromList } from '../../lib/methodsUtil'

const initialState = {
  // this is the database default user I'm using.
  // user - NOT USED AT THE MOMENT
  user: {
    name: 'Boubou',
    dob: '09/04/1980',
    nationality: 'FR',
    hofConsent: false,
    hof: [{ name: 'capitals', date: '01/01/2023' }],
    premium: true, // true when all paid
    //locked topics -  NOT USED AT THE MOMENT
    // for users who have premium false and only buy individual topics. Default value empty array
    unlockedPremiumTopics: ['letters-and-numbers', 'multiplications']
  },
  isAnnyangSupported: false,
  isListening: false,
  path: '/',
  transcript: [],
  language: 'en-GB',
  lngCode: 'en',
  gender: 'man',
  soundLevel: 'on',
  temporarilyUnlocked: false,
  isCorrectAnswer: false,
  isPlaying: false,
  winningImagesList: createInitialWinningImagesList(),
  winningImage: null
}

let winningImagesList
let winningImage

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.updateIsAnnyangSupported:
      return {
        ...state,
        isAnnyangSupported: action.payload
      }

    case actions.updateIsListening:
      return {
        ...state,
        isListening: action.payload
      }

    case actions.changePath:
      return {
        ...state,
        path: action.payload,
        transcript: []
      }

    case actions.saveTranscript:
      return {
        ...state,
        transcript: action.payload
      }

    case actions.updateLanguage:
      return {
        ...state,
        language: action.payload,
        lngCode: getLngCode(action.payload)
      }

    case actions.updateGender:
      return {
        ...state,
        transcript: [],
        gender: action.payload
      }

    case actions.updateSoundLevel:
      return {
        ...state,
        transcript: [],
        soundLevel: action.payload
      }

    case actions.updateTemporarilyUnlocked:
      return {
        ...state,
        temporarilyUnlocked: action.payload
      }

    case actions.isCorrectAnswer:
      return {
        ...state,
        isCorrectAnswer: true
      }

    case actions.isPlaying:
      return {
        ...state,
        isPlaying: action.payload
      }

    case actions.resetForNextQuestion:
      return {
        ...state,
        transcript: [],
        isCorrectAnswer: false
      }

    case actions.getAWinningImage:
      winningImagesList = state.winningImagesList
      if (winningImagesList.length <= 0) {
        winningImagesList = createInitialWinningImagesList()
      }
      winningImage = getRandomItemFromList(winningImagesList)

      return {
        ...state,
        winningImagesList: winningImagesList.filter(e => e !== winningImage),
        winningImage
      }

    default:
      return state
  }
}

export default appReducer
