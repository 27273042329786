import React from 'react'
import {
  FadeIn,
  FadeOut,
  Swing,
  Jello,
  FlipInX,
  FlipOutX,
  FlipOutY,
  BounceOut,
  Bounce,
  BounceIn,
  Pulse,
  RollIn,
  RollOut,
  RotateIn,
  RotateOut,
  RubberBand,
  FadeInUp,
  FadeOutDown,
  SlideInLeft,
  SlideOutRight,
  DrawBarAnim
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnBeginnerMultiplicationsPage = () => (
  <LearnMathematicsPage
    pageName="learn_beginner_multiplications"
    frReadSpeed="1"
    sentencesNumberPlusRedirect={13}
    startTimesOf={{
      2: 6000,
      3: 16000,
      4: 22000,
      5: 25500,
      6: 29000,
      7: 32500,
      8: 38500,
      9: 42000,
      10: 48000,
      11: 56000,
      12: 63000,
      13: 68000
    }}
    animations={{
      1: (
        <RotateIn className="learn-flex-center-center" duration={2.5}>
          <RotateOut delay={2.5} duration={2.5}>
            <img
              className="width-100"
              src={`${baseUrl}/images/learn/multiplications-frame-1.webp`}
            />
          </RotateOut>
        </RotateIn>
      ),
      2: (
        <div className="learn-flex-column-space-between learn-horizontal-numbers">
          <FadeIn className="learn-flex-center-center position-absolute">
            <FadeOut
              className="learn-flex-center-center position-absolute"
              delay={2}
              duration={0.5}>
              <img
                className="learn-beginner-multiplications-frame-2"
                src={`${baseUrl}/images/learn/multiplications-frame-2.webp`}
              />
            </FadeOut>
          </FadeIn>
          <FlipOutX delay={9.5} duration={0.5}>
            <FlipInX delay={2.75}>
              <Pulse delay={5.2} duration={0.4}>
                <RubberBand delay={6.9} duration={0.5}>
                  <RubberBand delay={7.4} duration={0.5}>
                    <RubberBand delay={7.9} duration={0.5}>
                      <img className="width-100" src={`${mathsNumbersUrls}/9/2.webp`} />
                    </RubberBand>
                  </RubberBand>
                </RubberBand>
              </Pulse>
            </FlipInX>
          </FlipOutX>
          <FlipOutX delay={9.5} duration={0.5}>
            <FlipInX delay={3}>
              <img
                className="width-100 plus-sign minus-sign"
                src={`${mathsNumbersUrls}/9/times.webp`}
              />
            </FlipInX>
          </FlipOutX>
          <FlipOutX delay={9.5} duration={0.5}>
            <FlipInX delay={3.25}>
              <Pulse delay={5.6} duration={0.4}>
                <img className="width-100" src={`${mathsNumbersUrls}/9/3.webp`} />
              </Pulse>
            </FlipInX>
          </FlipOutX>
        </div>
      ),
      3: (
        <FadeIn className="learn-flex-center-center" delay={0.5}>
          <SlideInLeft delay={0.5}>
            <SlideOutRight delay={4.5}>
              <FadeOut delay={4.5}>
                <img
                  className="width-100"
                  src={`${baseUrl}/images/learn/multiplications-frame-3.webp`}
                />
              </FadeOut>
            </SlideOutRight>
          </SlideInLeft>
        </FadeIn>
      ),
      4: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container position-relative">
            <DrawBarAnim number="one-three" />
            <DrawBarAnim number="two-three" />
          </div>
          <div className="math-bars-container position-relative visibility-hidden" />
          <div className="math-bars-container position-relative visibility-hidden" />
        </div>
      ),
      5: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container position-relative">
            <div className="math-bar math-bar-one" />
            <div className="math-bar math-bar-two" />
          </div>
          <div className="math-bars-container position-relative">
            <DrawBarAnim number="three-three" />
            <DrawBarAnim number="four-three" />
          </div>
          <div className="math-bars-container position-relative visibility-hidden" />
        </div>
      ),
      6: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container position-relative">
            <div className="math-bar math-bar-one" />
            <div className="math-bar math-bar-two" />
          </div>
          <div className="math-bars-container position-relative">
            <div className="math-bar math-bar-three" />
            <div className="math-bar math-bar-four" />
          </div>
          <div className="math-bars-container position-relative">
            <DrawBarAnim number="five-three" />
            <DrawBarAnim number="six-three" />
          </div>
        </div>
      ),
      7: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container position-relative">
            <div className="math-bar math-bar-one" />
            <DrawBarAnim number="one-cross-seven" />
            <div className="math-bar math-bar-two" />
            <DrawBarAnim number="two-cross-seven" />
          </div>
          <div className="math-bars-container position-relative">
            <div className="math-bar math-bar-three" />
            <DrawBarAnim number="three-cross-seven" />
            <div className="math-bar math-bar-four" />
            <DrawBarAnim number="four-cross-seven" />
          </div>
          <div className="math-bars-container position-relative">
            <div className="math-bar math-bar-five" />
            <DrawBarAnim number="five-cross-seven" />
            <div className="math-bar math-bar-six" />
            <DrawBarAnim number="six-cross-seven" />
          </div>
        </div>
      ),
      8: (
        <FadeOut
          className="learn-flex-column-space-between learn-horizontal-numbers justify-content-center"
          delay={3}>
          <FadeIn className="width-100 flex" delay={0.25} duration={0.5}>
            <img className="width-100 flex" src={`${mathsNumbersUrls}/9/2.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.5} duration={0.5}>
            <img className="plus-sign width-100 flex" src={`${mathsNumbersUrls}/9/times.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.75} duration={0.5}>
            <img className="width-100 flex" src={`${mathsNumbersUrls}/9/3.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={1} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/9/equal.webp`} />
          </FadeIn>
          <FadeIn className="learn-easy-six-one flex" delay={1.25} duration={0.5}>
            <img className="width-150 flex" src={`${mathsNumbersUrls}/9/6.webp`} />
          </FadeIn>
        </FadeOut>
      ),
      9: (
        <FlipInX className="learn-flex-center-center" duration={2}>
          <FlipOutY className="learn-flex-center-center flex-direction-column" delay={4}>
            <div className="learn-flex-center-center height-33">
              <img className="height-100" src={`${mathsNumbersUrls}/6/2.webp`} />
              <img className="height-50" src={`${mathsNumbersUrls}/6/times.webp`} />
              <img className="height-100" src={`${mathsNumbersUrls}/6/3.webp`} />
            </div>
            <div className="learn-flex-center-center height-33">
              <img className="height-50" src={`${mathsNumbersUrls}/6/equal.webp`} />
            </div>
            <div className="learn-flex-center-center height-33">
              <img className="height-100" src={`${mathsNumbersUrls}/6/3.webp`} />
              <img className="height-50" src={`${mathsNumbersUrls}/6/times.webp`} />
              <img className="height-100" src={`${mathsNumbersUrls}/6/2.webp`} />
            </div>
          </FlipOutY>
        </FlipInX>
      ),
      10: (
        <div className="learn-flex-column-space-between">
          <FadeInUp className="position-absolute height-100 width-100" duration={0.5}>
            <FadeOutDown
              className="position-absolute height-100 width-100"
              delay={2.5}
              duration={0.5}>
              <img src={`${baseUrl}/images/learn/boy-pointing-index-finger.webp`} />
            </FadeOutDown>
          </FadeInUp>
          <div className="math-bars-container position-relative">
            <DrawBarAnim number="one-ten" />
            <DrawBarAnim number="two-ten" />
            <DrawBarAnim number="three-ten" />
          </div>
          <div className="math-bars-container position-relative">
            <DrawBarAnim number="four-ten" />
            <DrawBarAnim number="five-ten" />
            <DrawBarAnim number="six-ten" />
          </div>
        </div>
      ),
      11: (
        <div className="learn-flex-center-center">
          <RollIn duration={0.5}>
            <Swing delay={2.75}>
              <Jello delay={4.25}>
                <RollOut delay={6} duration={0.5}>
                  <img src={`${baseUrl}/images/learn/learn-beginner-multiplications-11.webp`} />
                </RollOut>
              </Jello>
            </Swing>
          </RollIn>
        </div>
      ),
      12: (
        <div className="learn-flex-center-center">
          <BounceIn duration={0.5}>
            <Bounce delay={1} duration={1}>
              <BounceOut delay={2} duration={0.5}>
                <img src={`${baseUrl}/images/learn/learn-beginner-multiplications-end.webp`} />
              </BounceOut>
            </Bounce>
          </BounceIn>
        </div>
      )
    }}
  />
)

export default LearnBeginnerMultiplicationsPage
