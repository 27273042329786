import { isLetter } from './methodsUtil'

// of(country) adds the abbreviation such as de la Russie, du Bresil, de l'Espagne
const of = country => {
  if (
    [
      'Afghanistan',
      'Albanie',
      'Algérie',
      'Andorre',
      'Angleterre',
      'Angola',
      'Argentine',
      'Arménie',
      'Australie',
      'Autriche',
      'Azerbaïdjan',
      'Équateur',
      'Égypte',
      'Érythrée',
      'Estonie',
      'Éthiopie',
      'Allemagne',
      'Islande',
      'Inde',
      'Indonésie',
      'Iran',
      'Irak',
      'Irlande',
      'Israël',
      'Italie',
      'Irlande Du Nord',
      'Oman',
      'Arabie Saoudite',
      'Afrique du Sud',
      'Espagne',
      'Ecosse',
      'Eswatini',
      'Ouganda',
      'Ukraine',
      'Uruguay',
      'Ouzbékistan'
    ].includes(country)
  ) {
    return `de l'${country}`
  }

  if (
    [
      'Antigua-et-Barbuda',
      'Brunei',
      'Cuba',
      'Chypre',
      'Djibouti',
      'Grenade',
      'Haïti',
      'Kiribati',
      'Madagascar',
      'Malte',
      'Maurice',
      'Monaco',
      'Nauru',
      'Panama',
      'Saint-Marin',
      'Sao Tomé-et-Principe',
      'Singapour',
      'Salomon',
      'Saint-Christophe-et-Niévès',
      'Sainte-Lucie',
      'Saint-Vincent-et-les-Grenadines',
      'Taïwan',
      'Trinité-et-Tobago',
      'Tuvalu',
      'Vanuatu'
    ].includes(country)
  ) {
    return `de ${country}`
  }

  if (
    [
      'Bahamas',
      'Comores',
      'Fidji',
      'Maldives',
      'Îles Marshall',
      'Pays-Bas',
      'Palaos',
      'Philippines',
      'Seychelles',
      'Émirats Arabes Unis',
      'États-Unis'
    ].includes(country)
  ) {
    return `des ${country}`
  }

  if (
    [
      'Bahreïn',
      'Bangladesh',
      'Bélize',
      'Bénin',
      'Bhoutan',
      'Botswana',
      'Brésil',
      'Burkina',
      'Burundi',
      'Cambodge',
      'Cameroun',
      'Canada',
      'Cap-Vert',
      'Centrafrique',
      'Tchad',
      'Chili',
      'Congo',
      'Costa Rica',
      'Danemark',
      'Timor oriental',
      'Salvador',
      'Royaume-Uni',
      'Gabon',
      'Ghana',
      'Guatémala',
      'Guyana',
      'Honduras',
      'Japon',
      'Kazakhstan',
      'Kénya',
      'Kosovo',
      'Koweït',
      'Kirghizstan',
      'Laos',
      'Liban',
      'Lésotho',
      'Libéria',
      'Liechtenstein',
      'Luxembourg',
      'Malawi',
      'Mexique',
      'Monténégro',
      'Mali',
      'Maroc',
      'Mozambique',
      'Népal',
      'Nicaragua',
      'Niger',
      'Nigéria',
      'Pakistan',
      'Paraguay',
      'Pérou',
      'Portugal',
      'Qatar',
      'Rwanda',
      'Samoa',
      'Sénégal',
      'Sierra Leone',
      'Soudan du Sud',
      'Sri Lanka',
      'Soudan',
      'Suriname',
      'Tadjikistan',
      'Togo',
      'Tonga',
      'Turkménistan',
      'Vatican',
      'Vénézuéla',
      'Vietnam',
      'Pays De Galles',
      'Yémen',
      'Zimbabwé'
    ].includes(country)
  ) {
    return `du ${country}`
  }

  if (
    [
      'Barbade',
      'Biélorussie',
      'Belgique',
      'Bolivie',
      'Bosnie-Herzégovine',
      'Bulgarie',
      'Chine',
      'Colombie',
      "Côte d'Ivoire",
      'République démocratique du Congo',
      'Croatie',
      'République Tchèque',
      'Dominique',
      'République Dominicaine',
      'Guinée équatoriale',
      'Finlande',
      'France',
      'Gambie',
      'Géorgie',
      'Grèce',
      'Guinée',
      'Guinée-Bissao',
      'Hongrie',
      'Jamaïque',
      'Jordanie',
      'Lettonie',
      'Libye',
      'Lituanie',
      'Macédoine',
      'Malaisie',
      'Mauritanie',
      'Micronésie',
      'Moldavie',
      'Mongolie',
      'Birmanie',
      'Namibie',
      'Nouvelle-Zélande',
      'Corée du Nord',
      'Norvège',
      'Papouasie-Nouvelle-Guinée',
      'Pologne',
      'Roumanie',
      'Russie',
      'Serbie',
      'Slovaquie',
      'Slovénie',
      'Somalie',
      'Corée du Sud',
      'Suède',
      'Suisse',
      'Syrie',
      'Tanzanie',
      'Thaïlande',
      'Tunisie',
      'Turquie',
      'Zambie'
    ].includes(country)
  ) {
    return `de la ${country}`
  }
}

const ofLocation = country => {
  if (
    [
      'Afghanistan',
      'Albanie',
      'Algérie',
      'Andorre',
      'Angleterre',
      'Angola',
      'Argentine',
      'Arménie',
      'Australie',
      'Autriche',
      'Azerbaïdjan',
      'Équateur',
      'Égypte',
      'Érythrée',
      'Estonie',
      'Éthiopie',
      'Allemagne',
      'Islande',
      'Inde',
      'Indonésie',
      'Iran',
      'Irak',
      'Irlande',
      'Israël',
      'Italie',
      'Irlande Du Nord',
      'Oman',
      'Arabie Saoudite',
      'Afrique du Sud',
      'Espagne',
      'Ecosse',
      'Eswatini',
      'Ouganda',
      'Ukraine',
      'Uruguay',
      'Ouzbékistan'
    ].includes(country)
  ) {
    return `l'${country}`
  }

  if (
    [
      'Bahamas',
      'Comores',
      'Fidji',
      'Maldives',
      'Îles Marshall',
      'Pays-Bas',
      'Palaos',
      'Philippines',
      'Seychelles',
      'Émirats Arabes Unis',
      'États-Unis'
    ].includes(country)
  ) {
    return `les ${country}`
  }

  if (
    [
      'Bahreïn',
      'Bangladesh',
      'Bélize',
      'Bénin',
      'Bhoutan',
      'Botswana',
      'Brésil',
      'Burkina',
      'Burundi',
      'Cambodge',
      'Cameroun',
      'Canada',
      'Cap-Vert',
      'Centrafrique',
      'Tchad',
      'Chili',
      'Congo',
      'Costa Rica',
      'Danemark',
      'Timor oriental',
      'Salvador',
      'Royaume-Uni',
      'Gabon',
      'Ghana',
      'Guatémala',
      'Guyana',
      'Honduras',
      'Japon',
      'Kazakhstan',
      'Kénya',
      'Kosovo',
      'Koweït',
      'Kirghizstan',
      'Laos',
      'Liban',
      'Lésotho',
      'Libéria',
      'Liechtenstein',
      'Luxembourg',
      'Malawi',
      'Mexique',
      'Monténégro',
      'Mali',
      'Maroc',
      'Mozambique',
      'Népal',
      'Nicaragua',
      'Niger',
      'Nigéria',
      'Pakistan',
      'Paraguay',
      'Pérou',
      'Portugal',
      'Qatar',
      'Rwanda',
      'Samoa',
      'Sénégal',
      'Sierra Leone',
      'Soudan du Sud',
      'Sri Lanka',
      'Soudan',
      'Suriname',
      'Tadjikistan',
      'Togo',
      'Tonga',
      'Turkménistan',
      'Vatican',
      'Vénézuéla',
      'Vietnam',
      'Pays De Galles',
      'Yémen',
      'Zimbabwé'
    ].includes(country)
  ) {
    return `le ${country}`
  }

  if (
    [
      'Barbade',
      'Biélorussie',
      'Belgique',
      'Bolivie',
      'Bosnie-Herzégovine',
      'Bulgarie',
      'Chine',
      'Colombie',
      "Côte d'Ivoire",
      'République démocratique du Congo',
      'Croatie',
      'République Tchèque',
      'Dominique',
      'République Dominicaine',
      'Guinée équatoriale',
      'Finlande',
      'France',
      'Gambie',
      'Géorgie',
      'Grèce',
      'Guinée',
      'Guinée-Bissao',
      'Hongrie',
      'Jamaïque',
      'Jordanie',
      'Lettonie',
      'Libye',
      'Lituanie',
      'Macédoine',
      'Malaisie',
      'Mauritanie',
      'Micronésie',
      'Moldavie',
      'Mongolie',
      'Birmanie',
      'Namibie',
      'Nouvelle-Zélande',
      'Corée du Nord',
      'Norvège',
      'Papouasie-Nouvelle-Guinée',
      'Pologne',
      'Roumanie',
      'Russie',
      'Serbie',
      'Slovaquie',
      'Slovénie',
      'Somalie',
      'Corée du Sud',
      'Suède',
      'Suisse',
      'Syrie',
      'Tanzanie',
      'Thaïlande',
      'Tunisie',
      'Turquie',
      'Zambie'
    ].includes(country)
  ) {
    return `la ${country}`
  }
  return country
}

const frUnUneDuOrDes = word => {
  if (
    [
      'bacon',
      'pain',
      'beurre',
      'fromage',
      'poulet',
      'chocolat',
      'café',
      'maïs',
      'poisson',
      'raisin',
      'jambon',
      'miel',
      'ketchup',
      'lait',
      `jus d'orange`,
      'persil',
      'popcorn',
      'saumon',
      'sel',
      'thé'
    ].includes(word)
  ) {
    return 'du'
  }

  if (['haricots', 'céréales', 'frites', 'chips', 'nachos', 'pâtes', 'spaghetti'].includes(word)) {
    return 'des'
  }

  if (['ail', 'huile', 'eau'].includes(word)) {
    return `de l'`
  }

  if (['glasse', 'sauce piquante', 'soupe', 'laitue', 'moutarde'].includes(word)) {
    return 'de la'
  }

  return [
    'abeille',
    'anguille',
    'amande',
    'antilope',
    'araignée',
    'asperge',
    'aubergine',
    'autruche',
    'baleine',
    'banane',
    'betterave',
    'biche',
    'cacahuète',
    'carotte',
    'cerise',
    'chauve-souris',
    'chenille',
    'chèvre',
    'chouette',
    'ciboule',
    'citrouille',
    'coccinelle',
    'coquille saint-jacques',
    'crêpe',
    'crevette',
    'cygogne',
    'dinde',
    'étoile de mer',
    'fourmi',
    'fraise',
    'girafe',
    'glace',
    `glace á l'eau`,
    'grenouille',
    'guèpe',
    'hyène',
    'huitre',
    'libellule',
    'licorne',
    'loutre',
    'mante religieuse',
    'méduse',
    'mouche',
    'moufette',
    'moule',
    'noix',
    'noix de coco',
    'oie',
    'omelette',
    'orange',
    'palourde',
    'panthère',
    'pastèque',
    'patate',
    'pêche',
    'pieuvre',
    'pistache',
    'pizza',
    'poire',
    'pomme',
    'poule',
    'raie',
    'salamandre',
    'saucisse',
    'sauterelle',
    'souris',
    'sucette',
    'tarte',
    'tique',
    'tomate',
    'tortue',
    'vache'
  ].includes(word)
    ? 'une'
    : 'un'
}

const frFoodQuestionSentence = food => {
  if (
    [
      'ananas',
      'avocat',
      'banane',
      'cerise',
      'citron',
      'citrouille',
      'fraise',
      'melon',
      'noix',
      'noix de coco',
      'orange',
      'pastèque',
      'pêche',
      'piment',
      'poire',
      'pomme',
      'raisin',
      'tomate'
    ].includes(food)
  ) {
    return 'Quel est ce fruit?'
  }
  if (
    [
      'ail',
      'asperge',
      'aubergine',
      'betterave',
      'brocoli',
      'carotte',
      'champignon',
      'chou-fleur',
      'ciboule',
      'concombre',
      'laitue',
      'maïs',
      'oignon',
      'patate',
      'persil',
      'poireau',
      'poivron',
      'radis'
    ].includes(food)
  ) {
    return 'Quel est ce légume?'
  }
  if (['café', 'eau', `jus d'orange`, 'lait', 'soda', 'thé'].includes(food)) {
    return 'Quel est cette boisson?'
  }
  return `C'est quoi ça?`
}

const frLettersAndNumbersQuestionSentence = letterAndNumber =>
  isLetter(letterAndNumber) ? 'Quel est cette lettre?' : 'Quel est ce chiffre?'

const frLettersAndNumbersAnswerSentence = letterAndNumber =>
  isLetter(letterAndNumber)
    ? `C'est la lettre :${letterAndNumber.toUpperCase()}`
    : `C'est le chiffre :${letterAndNumber}`

const addEstOrSont = theName =>
  theName.indexOf('les ') === 0 ? `${theName} sont` : `${theName} est`

export {
  of,
  ofLocation,
  frUnUneDuOrDes,
  frFoodQuestionSentence,
  frLettersAndNumbersQuestionSentence,
  frLettersAndNumbersAnswerSentence,
  addEstOrSont
}
