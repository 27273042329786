import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// import '../styles/scss/App.scss'
import { isDevEnvironment, isDevDisableReadOutLoud, minusIndexNumber } from '../lib/_processUtil'
import annyang from '../lib/annyangSetUp'
import { common } from '../lib/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import {
  isMathematicsQuestion,
  getTranslatedAnswer,
  getQuestionAnswersBooleanValues,
  getSavedAnswerBeforeSeenListReset,
  isLettersAndNumbersPage,
  isSpeakSoundDisabled,
  getPageTitleColor,
  getTopic
} from '../lib/methodsUtil'
import {
  resetForNextQuestionAction,
  isCorrectAnswerAction,
  isPlayingAction,
  getAWinningImageAction
} from '../redux/actions/actions'
import ShowAnswer from './ShowAnswer'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import AnnyangNotSupportedNextQuestionButton from './AnnyangNotSupportedNextQuestionButton'
import AnnyangNotSupportedShowAnswerButton from './AnnyangNotSupportedShowAnswerButton'
import CorrectAnswerImage from './CorrectAnswerImage'
import AnswerSound from './AnswerSound'
import WinningAnimation from './WinningAnimation'
// import CustomShapeAnimation from './CustomShapeAnimation'
import WrongAnswersCrosses from './WrongAnswersCrosses'
import PassComponent from './PassComponent'
import ShowClue from './ShowClue'
import QuestionDisplay from './QuestionDisplay'
import SpeechButton from './SpeechButton'
import ImageWithDimensions from './ImageWithDimensions'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import { wasPathChanged } from './../../src/router/routerUtil'
import { getCorrectAnswerImageUrl } from './../../src/lib/themeUtil'

const disableReadOutLoud = isDevDisableReadOutLoud

class QuestionsAnswersGame extends Component {
  constructor(props) {
    super(props)
    this.resetWrongAnswerList = this.resetWrongAnswerList.bind(this)
    this.handleAnnyangNotSupportedButtonNext = this.handleAnnyangNotSupportedButtonNext.bind(this)
    this.handleAnnyangNotSupportedShowAnswer = this.handleAnnyangNotSupportedShowAnswer.bind(this)
    this.toggleIsPassedValue = this.toggleIsPassedValue.bind(this)

    // The 3 below are only used in Dev mode with isDevEnvironment  ------>
    this.handleCorrect = this.handleCorrect.bind(this)
    this.handleWrong = this.handleWrong.bind(this)
    this.handleRepeat = this.handleRepeat.bind(this)
    // <------

    this.state = {
      savedAnswerBeforeSeenListReset: '',
      wrongAnswersList: [],
      forceShowAnswer: false,
      isPassed: false
    }
  }

  handleAnnyangNotSupportedButtonNext() {
    const { questionText, dispatch, language, gender, soundLevel } = this.props
    this.resetWrongAnswerList()
    dispatch(resetForNextQuestionAction())
    dispatch(isPlayingAction(true))
    // On new question, for whatever reasons, the winningImage action is called twice.
    dispatch(getAWinningImageAction())
    disableReadOutLoud || isSpeakSoundDisabled(soundLevel)
      ? null
      : readOutLoud(questionText, dispatch, language, gender)
  }

  handleAnnyangNotSupportedShowAnswer() {
    const { dispatch, pageName, randomizeQuestionFunction } = this.props
    dispatch(isPlayingAction(false))
    dispatch(randomizeQuestionFunction(pageName))
    this.setState({ forceShowAnswer: true })
  }

  // The 3 below are only used in Dev mode with isDevEnvironment  ------>
  handleCorrect() {
    const { dispatch, pageName, randomizeQuestionFunction } = this.props
    dispatch(isCorrectAnswerAction())
    annyang.abort()
    dispatch(isPlayingAction(false))
    dispatch(randomizeQuestionFunction(pageName))
    annyang.start()
  }

  handleWrong() {
    this.setState({ wrongAnswersList: ['X', 'Y', 'Z'] })
  }

  handleRepeat() {
    const { questionText, dispatch, language, gender, soundLevel } = this.props
    disableReadOutLoud || isSpeakSoundDisabled(soundLevel)
      ? null
      : readOutLoud(questionText, dispatch, language, gender)
  }
  // <------

  componentDidMount() {
    const { dispatch, navigationCommands, randomizeQuestionFunction, pageName } = this.props
    annyang.bindNavigationCommands(dispatch, navigationCommands)
    annyang.start()
    annyang.addTranscriptListener(dispatch)

    // choose a question on page mount
    dispatch(randomizeQuestionFunction(pageName))
  }

  componentDidUpdate(oldProps) {
    // The logic showing the answer to "I don't know" use a -3 index from the seenList compared to its actual status. if the list has reset it cannot be accessed anymore.
    // As a workaround, on list reset, the last answer is saved in the state and this is how "I dont know" can access for that specific case onky.
    if (oldProps.seenList !== this.props.seenList) {
      this.setState({
        savedAnswerBeforeSeenListReset: getSavedAnswerBeforeSeenListReset(
          oldProps.seenList,
          this.props.seenList
        )
      })
    }

    // if we say the exact same answer (even if wrong) & annyang understands the exact same possibilities, the below will not run.
    const wasSomethingDifferentSaid =
      oldProps.transcript !== this.props.transcript && this.props.transcript.length > 0

    if (wasSomethingDifferentSaid) {
      const {
        transcript,
        isPlaying,
        actual,
        answerQuestionMap,
        alsoAcceptedAsAnswers,
        lngCode,
        displayType
      } = this.props
      const isMathematics = isMathematicsQuestion(displayType)
      const { isRepeat, isCorrect, isIDontKnow, nextQuestion, isPass, isWrong } =
        getQuestionAnswersBooleanValues(
          isMathematics,
          transcript,
          isPlaying,
          actual,
          answerQuestionMap,
          lngCode,
          alsoAcceptedAsAnswers
        )

      // React re-renders 5 or 6 times and as a result, if we have a wrong answer, we add what Annyang understood to the array 5 or 6 times.
      // Further down in the component, we use a unique filter to have it showing 1 cross per wrong answer
      const isAWrongAnswer =
        !nextQuestion && isPlaying && !isIDontKnow && !isCorrect && !isRepeat && !isPass && isWrong
      if (isAWrongAnswer) {
        const { wrongAnswersList } = this.state
        wrongAnswersList.push(oldProps.transcript.join())
        this.setState({ wrongAnswersList })
      }
    }
  }

  componentWillUnmount() {
    annyang.abort()
    const { dispatch, resetCategoryAction } = this.props
    dispatch(resetCategoryAction())
    dispatch(resetForNextQuestionAction())
    dispatch(isPlayingAction(false))
  }

  resetWrongAnswerList() {
    this.setState({ wrongAnswersList: [], forceShowAnswer: false })
  }

  toggleIsPassedValue() {
    const { isPassed } = this.state
    this.setState({ isPassed: !isPassed })
  }

  render() {
    const {
      path,
      pageName,
      isAnnyangSupported,
      language = 'en',
      lngCode = 'en',
      gender = 'man ',
      soundLevel = 'on',
      questionClue,
      questionText,
      questionAnswer,
      answerQuestionMap,
      displayType,
      getImageUrl,
      imageAlt,
      transcript,
      actual,
      seenList,
      isPlaying,
      isCorrectAnswer,
      dispatch,
      alsoAcceptedAsAnswers = {},
      randomizeQuestionFunction,
      getPreviousQuestionAnswer,
      winningImage,
      resultsList,
      randomList,
      randomNumber,
      mapFillColorOne,
      mapFillColorTwo,
      textDisplayColor
    } = this.props

    const titleLettersColor = getPageTitleColor(path) || 'ghostwhite'

    const isSpeakDisabled = isSpeakSoundDisabled(soundLevel)
    const isSoundOff = soundLevel === 'off'
    const isGeneralReadDisabled = disableReadOutLoud || isSpeakDisabled

    const { savedAnswerBeforeSeenListReset, wrongAnswersList, forceShowAnswer, isPassed } =
      this.state
    const isMathematics = isMathematicsQuestion(displayType)

    // Regarding minusIndexNumber, seenList.length < 3 seems to work for impair, but what about pair, might need -2?
    const previousExpected = isMathematics
      ? resultsList[resultsList.length - minusIndexNumber]
      : seenList.length < minusIndexNumber
        ? savedAnswerBeforeSeenListReset
        : seenList[seenList.length - minusIndexNumber]

    const previousAnswer =
      lngCode === 'en' || isMathematics || isLettersAndNumbersPage(pageName)
        ? previousExpected
        : getTranslatedAnswer(lngCode, answerQuestionMap, previousExpected)

    const { isRepeat, isCorrect, isIDontKnow, nextQuestion, isPass } =
      getQuestionAnswersBooleanValues(
        isMathematics,
        transcript,
        isPlaying,
        actual,
        answerQuestionMap,
        lngCode,
        alsoAcceptedAsAnswers,
        questionAnswer
      )

    const getNewQuestionAndAnswer = () => {
      dispatch(isPlayingAction(false))
      dispatch(randomizeQuestionFunction(pageName))
    }

    const resetReduxStateForNextQuestion = () => {
      dispatch(resetForNextQuestionAction())
      dispatch(isPlayingAction(true))
      // On new question, for whatever reasons, the winningImage action is called twice.
      dispatch(getAWinningImageAction())
      sendAmplitudeEvent('Game Page Plays', { topic: getTopic(pageName), action: 'vocal' })
    }

    if (isPassed) {
      isGeneralReadDisabled ? null : readOutLoud(questionText, dispatch, language, gender)
      this.toggleIsPassedValue()
    }

    if (nextQuestion && !isPlaying) {
      this.resetWrongAnswerList()
      resetReduxStateForNextQuestion()
      isGeneralReadDisabled ? null : readOutLoud(questionText, dispatch, language, gender)
    }

    if (isPlaying && !nextQuestion) {
      if (isRepeat) {
        isGeneralReadDisabled ? null : readOutLoud(questionText, dispatch, language, gender)
      } else if (isCorrect) {
        dispatch(isCorrectAnswerAction())
        annyang.abort()
        getNewQuestionAndAnswer()
        annyang.start()
      } else if (isIDontKnow) {
        disableReadOutLoud || isSoundOff
          ? null
          : readOutLoud(questionAnswer, dispatch, language, gender)
        getNewQuestionAndAnswer()
      }
    }

    // eslint-disable-next-line
    const uniqueWrongAnswersList = [...new Set(wrongAnswersList)].map(x => 'X')

    // Tested on Firefox - Only used for non-Chrome / Annyang not supported. nb: resultsList means it's mathematics.
    const annyangNotSupportedShownAnswer = resultsList
      ? resultsList[resultsList.length - 2]
      : seenList
        ? getTranslatedAnswer(lngCode, answerQuestionMap, seenList[seenList.length - 2])
        : ''

    return isPlaying && isPass ? (
      <PassComponent
        getNewQuestionAndAnswer={getNewQuestionAndAnswer}
        resetWrongAnswerList={this.resetWrongAnswerList}
        resetReduxStateForNextQuestion={resetReduxStateForNextQuestion}
        toggleIsPassedValue={this.toggleIsPassedValue}
      />
    ) : (
      <div
        className={`${pageName.replace(/_/g, '-')}-page${isPlaying ? ' is-playing' : ''} ${
          isMathematics ? `question-answer-game-${pageName.split('_')[1]}` : ''
        }
        question-answer-game`}>
        {!isPlaying && (
          <div className="question-answer-game-page-title" style={{ color: titleLettersColor }}>
            {common(pageName)}
          </div>
        )}

        {(isIDontKnow || forceShowAnswer) && (
          <ShowAnswer
            answer={
              forceShowAnswer
                ? annyangNotSupportedShownAnswer
                : getPreviousQuestionAnswer(previousAnswer, lngCode)
            }
            isMathematics={isMathematics}
            getImageUrl={getImageUrl}
            randomList={randomList}
            pageName={pageName}
            titleLettersColor={titleLettersColor}
            isAnnyangSupported={isAnnyangSupported}
          />
        )}

        {isCorrectAnswer && (
          <>
            <CorrectAnswerImage winningImage={winningImage} />
            {!isSoundOff && <AnswerSound isCorrect />}
            <WinningAnimation />
          </>
        )}

        {/* <CustomShapeAnimation /> */}

        {isPlaying && questionClue && (
          <ShowClue
            clue={questionClue}
            color={titleLettersColor}
            isRepeat={isRepeat}
            hasAnswers={uniqueWrongAnswersList.length > 0}
          />
        )}

        {isPlaying && !isCorrectAnswer && (
          <ImageWithDimensions
            className="none"
            alt="hidden preloaded answer image"
            src={getCorrectAnswerImageUrl(winningImage)}
          />
        )}

        {isPlaying && (
          <QuestionDisplay
            displayType={displayType}
            actual={actual}
            getImageUrl={getImageUrl}
            imageAlt={imageAlt}
            pageName={pageName}
            randomList={randomList}
            randomNumber={randomNumber}
            mapFillColorOne={mapFillColorOne}
            mapFillColorTwo={mapFillColorTwo}
            textDisplayColor={textDisplayColor}
            translatedActual={getTranslatedAnswer(lngCode, answerQuestionMap, actual)}
          />
        )}

        {isPlaying && !isCorrectAnswer && (
          <WrongAnswersCrosses wrongAnswersList={uniqueWrongAnswersList} />
        )}

        {isPlaying ? (
          <AnnyangNotSupportedShowAnswerButton onClick={this.handleAnnyangNotSupportedShowAnswer} />
        ) : (
          <AnnyangNotSupportedNextQuestionButton
            onClick={this.handleAnnyangNotSupportedButtonNext}
          />
        )}

        {/* ONLY VISIBLE FOR DEVS */}
        {isDevEnvironment && (
          <div className="question-answer-navigation-buttons">
            <button onClick={this.handleCorrect}>CORRECT ANSWER</button>
            <button onClick={this.handleAnnyangNotSupportedShowAnswer}>I DONT KNOW</button>
            <button onClick={this.handleAnnyangNotSupportedButtonNext}>NEXT</button>
            <button onClick={this.handleWrong}>WRONG</button>
            <button onClick={this.handleRepeat}>REPEAT</button>
          </div>
        )}

        {!isSpeakDisabled && (
          <SpeechButton
            text={common(isPlaying ? 'playingSpeech' : 'notPlayingSpeech')}
            language={language}
            transcript={transcript}
            count={isPlaying ? 120 : 10}
            disableSpeakRequestWhilePlaying={isPlaying || (!isPlaying && !nextQuestion)}
          />
        )}

        <AppListeningSpeakingVisual />

        <AnnyangNotSupportedMenuButton />

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(QuestionsAnswersGame)
