import React from 'react'
import LearnPage from '../../../../../src/components/LearnPage'
import { getSmallCountryImageUrl } from '../../../../../src/pages/geography/capitals/util'

const LearnOtherCapitalsPage = () => (
  <LearnPage
    parentPage="capitals_geography"
    grandParentPage="geography"
    pageName="learn_other_capitals"
    parentPagePath="/geography/capitals"
    getImageUrl={getSmallCountryImageUrl}
  />
)

export default LearnOtherCapitalsPage
