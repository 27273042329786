import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 610
const height = 792

const cities = {
  Rome: { x: 304, y: 371 }
}

const Italy = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map italy">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="VA"
          title="Vatican City"
          className="land"
          d="M298.99,371.87L298.88,371.76L298.78,371.79L298.61,371.83L298.45,372.09L298.42,372.15L298.48,372.23L298.54,372.21L298.8,372.27L299,372.19L299.02,372.13L298.98,372.05z"
        />
        <path
          id="SM"
          title="San Marino"
          className="land"
          d="M300.77,231.65L300.77,231.65L301.59,226.34L296.23,228.85L296.51,232.46L296.51,232.46L296.55,232.56z"
        />
        <path
          id="FR-H"
          title="Corsica,  France"
          className="land"
          d="M143.42,295.38l2.08,1.75l1.51,12.62l-2.38,9.33l4.52,9.28l1.01,28.6l-7.31,11.7l-1.17,20.28l-5.52,3.51l0.34,2.1l1.57,-2.06l2.55,0.92l-4.82,4.16l0.24,4.51l-3.18,3.74l2.21,-0.29l-4.09,3.38l-4.43,-2.03l1.3,-3.3l-17.01,-7.72l0.17,-4.98l6.29,-4.26l-6.75,-0.58l-0.35,-2.83l-5.95,0.06l5.79,-4.88l1.6,-5.75l-1.06,-1.88l-8.95,1.99l-0.77,-4.91l2.86,-0.24l0.6,-3.01l4.47,-2.56l-2.43,-4.32l-2.09,0.81l-5.22,-3.1l1.19,-4.13l-2.1,-2.19l7.94,-2.02l-4.64,-2.69l0.61,-3l-2.96,1.18l-0.79,-2.48l5.75,-3.28l1.27,-3.71l-1.6,-0.67l3.96,-7.35l3.23,2.16l0.68,-3.38l10.92,-2.69l5.62,-6.27l4.98,-0.26l3.94,4.07l2.36,-4.12l-1.81,-6.76l2.66,-6.34l-1.08,-4.88L143.42,295.38z"
        />
        <path
          id="MT"
          title="Malta"
          className="land"
          d="M397.03,760.26l0.74,3.2l2.3,-0.96l4.71,3.57l-1.02,1.49l3.57,0.4l-0.43,3.34l-7.19,-0.37l-4.42,-3.34l-0.9,-5.78L397.03,760.26zM390.08,754.81l5.23,3.1l-7.56,-0.12l-0.18,-2.54L390.08,754.81z"
        />
        <path
          id="IT_65"
          title="Abruzzo"
          className="land"
          d="M373.96,303.76L383.83,326.79L399.08,340.65L403.95,347.86L414.67,353.39L414.88,358.24L418.31,360.67L418.31,360.67L403.25,381.78L401.21,381.41L399.83,375.41L395.77,374.89L392.61,371.57L385.63,377.22L387.98,382.69L384.22,382.84L383.59,385.48L381.64,383.5L379.16,387.41L375.19,386.95L375.19,386.95L372.7,383.68L365.98,382.71L364.23,379.4L360.06,378.46L354.95,381.9L349.42,377.3L346.29,377.96L345.57,370.63L334.03,364.41L329.6,364.29L328.19,362.04L328.34,357.12L331.2,355.7L331.25,353.31L339.54,356.71L346.34,353L334.71,341.01L336.79,338.68L332.8,334.94L335.98,332.52L334.88,328.28L336.67,324.94L341.96,326.23L347.07,324.6L347.83,321.17L344.69,319.17L345.23,317.49L345.23,317.49L345.23,317.49L345.23,317.49L345.23,317.49L345.23,317.49L352.31,314.34L354.19,308.92L360.66,309.71L363.81,306.18L373.69,303.52L373.69,303.52z"
        />
        <path
          id="IT-77"
          title="Basilicata"
          className="land"
          d="M457.12,429.87L458.13,427.41L468.69,427.96L473.95,424.35L482.56,431.06L479.53,436.56L486.71,440.45L490.85,439.14L493.79,445.6L501.13,453.39L506.14,449.77L508.18,452.24L510.05,449.68L514.63,450.72L517.55,452.94L517.42,468.66L521.75,470.24L524.64,475.19L524.64,475.19L513.6,492.86L513.6,492.86L502.11,491.12L498.55,507.55L494.41,504.98L491.46,507.39L482.84,507.46L480.81,503.5L482.05,501.27L476.86,500.54L471.3,500.63L468,505.76L468,505.76L462.29,497.83L462.29,497.83L470.48,482.46L456.7,467.7L456.77,462.59L452.42,459.96L454.86,456.12L448.93,452.27L449.1,447.42L446.44,444.9L448.54,444.75L448.07,441.43L452.86,442.51L456.23,439.71L458.49,433.84z"
        />
        <path
          id="IT-78"
          title="Calabria"
          className="land"
          d="M468,505.76l3.31,-5.13l5.55,-0.09l5.19,0.73l-1.24,2.23l2.03,3.96l8.62,-0.07l2.95,-2.4l4.14,2.56l3.56,-16.42l11.49,1.74l0,0l-2.25,5.07l1.36,5.79l-7.1,10.46l1.49,8.71l5.08,3.01l8.04,0.12l4.72,5.33l15,9.22l-2.36,5.88l1.93,6.86l-1.96,6.55l4.91,5.42l-5.74,8.77l-5.93,-3.02l-4.69,0.74l-14.47,7.55l-3.65,6.55l2,18.52l-5.65,5.88l-7.24,3.06l-8,10.12l-3.55,11.61l-5.03,2.64l-12.22,-0.17l-6.42,-6.48l-0.02,-13.9l8.56,-3.2l5.86,-12.41l0.75,-4.87l-5.32,-4.76l0.48,-1.82l7.77,-4.94l7.61,0.22l4.39,-8.38l-0.29,-5.12l-6.11,-7.07l-5.16,-26.8l-6.3,-7.6l-3.03,-8.1L468,505.76z"
        />
        <path
          id="IT-72"
          title="Campania"
          className="land"
          d="M389.63,463.3l2.01,0.24l-3.62,1.33L389.63,463.3zM371.32,449.83l4.41,1.2l-0.16,2.84l-5.11,-0.42L371.32,449.83zM376.92,402.16l3.3,4.74l3.45,0.38l-1.65,-4.09l2.2,-4.03l2.29,1.91l3.84,-1.31l18.41,9.03l3.2,-2.95l4.79,-0.07l1.72,-2.89l4.01,1.7l7.2,-3.91l0,0l4.67,3.63l-1.38,6.75l10.53,5.68l-3.6,6.59l2.3,2.66l5.94,1.73l1.7,-1.29l7.27,3.46l0,0l1.37,3.97l-2.26,5.87l-3.37,2.8l-4.79,-1.09l0.47,3.33l-2.1,0.15l2.67,2.52l-0.17,4.85l5.93,3.85l-2.44,3.84l4.35,2.63l-0.06,5.11l13.77,14.76l-8.19,15.37l0,0l-3.64,-2.34l-7.82,5.81l-7.63,-2.01l-0.02,-3.51l-7.3,-6.43l-4.68,0.06l-6.33,-4.44l4.08,-11l-6.05,-12.56l-5.33,-5.6l-10.14,3.98l-4.78,-0.92l-8.06,3.87l0.78,-4.1l7.17,-4.56l-1.08,-3.17l-9.4,-6.72l-4.49,3.49l-4.75,-2.58l-0.25,3.47l-2.25,-0.76l-1.12,-8.45l-13.29,-20.86l0,0l6.62,-5.05l-1.54,-8.19L376.92,402.16z"
        />
        <path
          id="IT-45"
          title="Emilia-Romagna"
          className="land"
          d="M314,227.91l-0.94,7.21l-3.06,3.09l-2.92,-0.36l-1.31,-3.87l-2.72,1.13l-2.28,-3.46l0,0l0.82,-5.31l-5.36,2.51l0.28,3.6l0,0l0,0l0,0l1.21,2.22l-3.95,-0.04l-3.81,7.73l0,0l-0.04,-0.03l0,0l-4.51,2.49l-1.55,-2.31l-4.58,1.54l-8.27,-4.98l-4.66,-0.21l-5.58,-4.41l0.27,-3.06l-3.66,-4.93l5.2,-9.66l-8.12,0.74l1.26,-3.14l-4.83,0.21l-4.79,-5.7l-7.43,5.79l-3.99,0.14l3.07,3.72l-11.72,0.4l-1.33,-3.1l-4.86,5.46l-8.26,-6.69l-5.57,-0.13l-2.6,3l-5.35,-7.58l-9.63,-4.68l-2.38,1.19l-5.63,-5.95l-6.72,-2.43l-0.59,-3.89l-3.99,-2.32l-5.35,0.59l-7.01,7.45l0,0l-4.12,-5.31l-8.45,1.35l2.86,-4.72l-0.37,-5.51l-4.02,-2.84l-2.44,1.79l-8.2,-2.89l0,0l0.47,-4.89l0,0l1.75,-0.11l-0.32,-2.02l1.31,2.51l3.23,-0.85l-2.01,-5.68l3.85,-3.88l-4.52,-5.28l0.65,-2.65l6.49,-10.91l4.39,-1.35l1.57,1.86l0.5,-3.76l2.33,2.03l1.93,-2.45l0.76,3.75l4.18,1.79l2.24,-3.78l3.05,4.35l0.93,-3.21l2.9,1.12l-0.94,-2.75l1.8,-1.46l0.35,2.8l3.77,-2.12l5.31,7.77l3.33,-1.56l10.19,5.54l2.45,-1.34l6.04,5.15l3.94,-0.17l6.55,-5.42l0.97,3.12l7.26,2.5l9.41,-3.55l3.87,2.19l7.94,-2.16l6.23,0.92l0,0l0.16,1.49l5.7,-0.37l3.25,3.28l10.09,-6.49l15.17,0.37l2.29,3.08l6.98,-1.05l0.53,5.22l3.23,3.64l0,0l-3.03,-2.03l-1.73,2.28l-0.98,8.85l6.36,31.27l8.46,11.35L314,227.91zM287.35,239.24L287.35,239.24l-3.04,1.1l1.37,1.59l1.88,-2.23L287.35,239.24z"
        />
        <path
          id="IT-36"
          title="Friuli-Venezia Giulia"
          className="land"
          d="M316.33,34.01l2.64,2.44l16.36,1.37l3.66,2.76l6.87,-2.04l17.56,4.34l-1.5,6.1l-4.82,0.09l-11.16,10.42l1.89,6.28l3.58,-1.04l9.26,3.31l-9.64,12.95l3.05,2.85l5.55,-1.38l-3.41,9.54l1.04,3.26l10.22,4.55l6.39,8.32l-4.12,3.68l-6.12,-0.79l4.6,-1.45l-3.49,-1.95l0.51,-3l-6.15,-6.59l-4.19,-1.16l-1.56,3.14l1.76,1.42l-6.28,3.41l-9.79,-2.9l-4.23,1.12l-2.88,4.42l0,0l-4.73,-6.17l-2.33,-8.73l-1.69,2.16l-3.49,-2.47l-2.73,2.13l-1.33,-2.56l-6.53,5.01l-1.29,-3.03l-3.92,-0.1l-2.43,-6.99l-3.96,-1.56l-1.66,-6.87l4.67,-4.77l0.45,-3.58l-9.01,-8.4l10.73,-14.67l6.69,-0.03l-1.5,-3.48l6.01,-2.24l-0.96,-6.89l0,0L316.33,34.01z"
        />
        <path
          id="IT-62"
          title="Lazio"
          className="land"
          d="M324.19,439.19l2.24,-1.01l-2.05,3.84L324.19,439.19zM376.92,402.16l-5.95,3.1l1.54,8.19l-6.62,5.06l-6.84,-2.57l-3.98,3.78l-13.28,-6.25l-12.61,4.76l-6.59,-10.63L307.27,403.3l-10.4,-14.3l-9.89,-5.95l-4.06,-11.66l-11.61,-8.44l-4.27,0.63l-9.25,-17.22l-10.57,-6.87l1.86,-4.15l6.8,-0.08l-0.09,-3.43l-2.79,-1.91l0.92,-3.7l3.24,0.07l8.42,-5.68l-1.54,-4.18l2.27,-2.35l-3.78,-2.96l1.16,-2.44l1.9,1.79l2.13,-3.08l2.48,0.14l4.4,5.68l-2.85,3.03l1.29,2.11l4.14,2.56l8.49,-1.28l4.8,12.05l6.56,-0.42l1.12,7.05l3.64,-0.52l0.82,2.68l4.33,-3.36l0.22,-3.88l3.29,1.73l2.99,-1.97l-1.37,-2.04l9.22,-4.4l-0.04,-3.72l13.29,-2.25l2.08,-5.73l4.92,-0.49l3.68,3.16l-0.54,1.69l3.14,1.99l-0.76,3.44l-5.11,1.62l-5.29,-1.28l-1.79,3.34l1.1,4.24l-3.18,2.42l3.99,3.74l-2.09,2.34l11.64,11.99l-6.8,3.71l-8.29,-3.4l-0.05,2.4l-2.85,1.42l-0.15,4.92l1.4,2.25l4.43,0.12l11.55,6.22l0.72,7.34l3.13,-0.66l5.53,4.6l5.11,-3.44l4.17,0.95l1.75,3.31l6.72,0.97l2.5,3.27l4.18,8.82L376.92,402.16zM299.02,372.13l-0.04,-0.08l0.01,-0.18l-0.11,-0.11l-0.09,0.03l-0.17,0.04l-0.16,0.26l-0.03,0.06l0.07,0.08l0.06,-0.02l0.26,0.06l0.2,-0.08L299.02,372.13z"
        />
        <path
          id="IT-42"
          title="Liguria"
          className="land"
          d="M55.67,221.16l2.35,-1.4l-2.22,-1.42l2.41,-2.62l9,2.88l3.6,-0.18l-1.44,-2.22l1.84,-1.52l2.95,0.53l-0.47,-11.11l1.88,0.11l2.34,-5.62l3.19,-1.76l-0.62,-5.21l2.89,-3.59l5.91,4.32l5.28,-3.95l4.55,1.48l3.18,-5.87l3.56,0.2l4.35,6.24l2.63,-4.88l4.59,0.11l-1.74,-5.28l3.81,-3.16l9.07,7.44l3.46,-2.92l0,0l8.2,2.89l2.44,-1.79l4.02,2.84l0.37,5.51l-2.85,4.72l8.45,-1.35l4.12,5.31l0,0l8.95,6.57l0.47,6l2.08,-1.75l1.47,1.02l-1.99,2.04l3.93,-0.13l0.65,4.98l1.78,-1.5l2.37,1.47l-2.5,3.93l0,0l-2.77,0.6l-6.24,-5.13l-0.52,4.27l-30.67,-21.22l-0.97,3.44l-3.64,-1.69l-0.17,-2.74l-18.16,-4.58l-14.62,7.21l-3.08,3.62l-0.77,5.6l-8,4l-5.4,10.33l0.52,2.77l-5.2,4.55l-20.61,8.15l-6.95,-0.28l-2.07,-6.71l7.95,-7.16L55.67,221.16z"
        />
        <path
          id="IT-25"
          title="Lombardia"
          className="land"
          d="M185.79,35.38l-0.13,3.14l2.55,1.93l8.14,1.45l0,0l1.15,2.7l6.4,2.07l1.64,4.94l-6.04,4.22l2.64,1.27l1.04,5.89l-2.15,10.17l-4.73,8.75l2.93,5.34l-0.39,6.41l3.29,3.34l4.12,-1.4l0.47,-2.15l9.64,0.08l0,0l-11.09,16.5l0.73,13.73l4,0.5l-0.98,7.69l2,1.37l2.41,-2l10.43,8.62l4.25,6.98l3.73,-1.99l3,1.12l-1.45,3.65l4.55,0.31l0.01,2.46l8.2,5.32l0,0l-6.23,-0.92l-7.94,2.16l-3.87,-2.19l-9.41,3.55l-7.26,-2.5l-0.97,-3.12l-6.55,5.42l-3.94,0.17l-6.04,-5.15l-2.45,1.34l-10.19,-5.54l-3.33,1.56l-5.31,-7.77l-3.77,2.12l-0.35,-2.8l-1.8,1.46l0.94,2.75l-2.9,-1.12l-0.93,3.21l-3.04,-4.35l-2.24,3.78l-4.18,-1.79l-0.76,-3.75l-1.93,2.45l-2.33,-2.03l-0.5,3.76l-1.57,-1.86l-4.39,1.35l-6.48,10.91l-0.65,2.66l4.52,5.28l-3.85,3.88l2.02,5.68l-3.23,0.86l-1.31,-2.51l0.32,2.02l-1.75,0.11l0,0l0.26,-4.85l-2.74,-4.61l-4.62,-0.16l-0.82,-5.11l-7.81,-8.52l-0.29,-3.2l-6.42,3.05l-2.97,-2.17l-0.46,1.76l-3.32,-1.92l-1.36,-6.11l-3.69,-3.22l1.79,-2.57l-2.67,0.57l1.58,-3.02l-2.98,-3.69l2.98,-5.12l3.96,0.89l3.09,4.09l3.54,-3.89l-2.17,-2.32l6.39,-1.16l-2.97,-6.63l-3.51,-1.81l-3.49,-15.11l-3.05,-0.91l-2.02,-3.82l2.14,-3.85l-1.28,-4.95l8.05,-8.76l-0.38,-6.16l0,0l1.21,-1.33l5.68,3.46l-3.51,6.18l5.77,2.5l2.51,6.55l-1.75,2.63l6.41,0.99l2.78,-5.83l-5.1,-5.19l1.89,-1.73l-1.13,-3.27l3.57,-2.13l-0.19,-4.04l9.09,-8.55l2.51,-6.76l-2.59,-8.57l1.52,-4.03l4.4,-0.99l2.5,3.1l2.7,-3.12l-0.07,9.95l4.41,5.46l8.27,0.73l1.41,-4.28l2.11,1.1l8.98,-3.27l2.26,2.05l0.15,5l2.92,1.49l-0.53,2.56l3.94,0.37l2.76,-2.36l-3.65,-5.61l3.14,-5.72l-6.48,-2.62l0.23,-7.05l2.97,-5.29L185.79,35.38z"
        />
        <path
          id="IT-57"
          title="Marche"
          className="land"
          d="M352.87,251.97l-2.9,1.26l-7.56,-4.21l-20.49,-17.97l-7.92,-3.14l0,0l-0.94,7.21l-3.06,3.09l-2.92,-0.36l-1.31,-3.87l-2.72,1.13l-2.28,-3.46l0,0l-4.22,0.92l0,0l-0.04,-0.1l0,0l1.21,2.22l-3.95,-0.04l-3.81,7.73l0,0l0.13,0.1l0,0l4.44,3.93l-2.72,2.14l-0.61,-3.72l-0.44,3.12l-5.74,3.06l1.45,2.23l0,0l4.16,1.69l3.95,-1.8l-2.95,4.92l4.06,2.42l1.42,-2.09l3.95,1.16l6.36,7.29l7.76,-2.61l-1.23,5.37l5.96,12.03l-1.76,4.45l3.31,3.67l0.02,8.98l4,2.6l0.12,4.04l1.22,-3.07l2.69,-0.61l5.51,6.24l3.92,-2.43l1.16,5.43l-3.47,3.91l0,0l4.92,-0.49l3.68,3.16l0,0l7.08,-3.15l1.88,-5.42l6.46,0.79l3.15,-3.52l9.88,-2.67l0,0l-14.78,-45.89L352.87,251.97zM297.73,254.39l-0.74,-0.26l0,0l-0.19,-0.06l0,0l0.89,-0.37l0,0l0.1,0.53l0,0L297.73,254.39z"
        />
        <path
          id="IT-67"
          title="Molise"
          className="land"
          d="M418.76,361.06L429.19,364.88L436.32,370.45L436.32,370.45L434.26,381.31L437.37,385.39L430.43,391.29L427.19,389.79L425.94,391.31L426.14,397.69L429.69,400.66L429.69,400.66L422.48,404.57L418.47,402.86L416.75,405.76L411.96,405.83L408.76,408.79L390.35,399.76L386.51,401.07L384.22,399.16L382.02,403.19L383.67,407.28L380.22,406.9L376.92,402.16L376.92,402.16L379.37,395.77L375.19,386.95L375.19,386.95L379.16,387.41L381.64,383.5L383.59,385.48L384.22,382.84L387.98,382.69L385.63,377.22L392.61,371.57L395.77,374.89L399.83,375.41L401.21,381.41L403.25,381.78L418.31,360.67L418.31,360.67z"
        />
        <path
          id="IT-21"
          title="Piemonte"
          className="land"
          d="M92.94,46.87L94.61,51.93L93.14,62.89L97.61,65.16L101.74,72.22L107.21,73.58L107.21,73.58L107.59,79.74L99.54,88.5L100.82,93.46L98.68,97.31L100.7,101.13L103.75,102.04L107.24,117.15L110.75,118.96L113.72,125.59L107.33,126.75L109.5,129.07L105.96,132.96L102.88,128.87L98.91,127.98L95.94,133.1L98.92,136.79L97.34,139.82L100.01,139.25L98.22,141.82L101.91,145.04L103.27,151.16L106.58,153.07L107.04,151.32L110.02,153.48L116.44,150.43L116.73,153.64L124.54,162.15L125.36,167.26L129.98,167.42L132.72,172.03L132.47,176.88L132.47,176.88L132,181.77L132,181.77L128.54,184.69L119.47,177.24L115.66,180.4L117.4,185.68L112.81,185.57L110.19,190.45L105.83,184.21L102.28,184L99.1,189.88L94.55,188.39L89.27,192.35L83.36,188.03L80.47,191.62L81.09,196.82L77.91,198.58L75.57,204.21L73.69,204.1L74.16,215.21L71.21,214.68L69.37,216.19L70.81,218.41L67.21,218.6L58.21,215.72L55.8,218.34L58.02,219.76L55.67,221.16L55.67,221.16L53.29,216.38L54.19,213.27L37.41,217.5L28.73,211.39L19.48,208.94L13.36,199.9L13.64,195.67L16.5,195.11L11.77,187.85L17.49,181.56L17.22,177.43L23.09,176.74L19.15,169.39L19.45,165.78L12.17,164.97L6.4,161L6.05,153.14L2.42,152.73L0,146.53L7.26,142.59L13.69,144.25L13.7,141.95L16.39,141.85L19.22,138.22L22.71,138.82L25.58,136.3L24.84,130.39L28.8,124.71L24.44,120.25L24.44,120.25L26.21,116.69L26.57,119.54L30.54,119.93L44.27,111.83L56.72,114.16L61.61,110.69L65.26,111.14L67.03,101.3L63.34,96.4L63.34,87.29L63.34,87.29L65.63,81.4L70.89,80.35L72.04,73.9L78.23,70.6L77.53,64.69L74.35,62.08L86.25,53.36L86.05,49.92z"
        />
        <path
          id="IT-75"
          title="Puglia"
          className="land"
          d="M481.43,368.91l8.76,4.49l0.5,5.53l-1.75,3.33l-13.41,9.49l2.65,10.42l32.34,17.33l26.06,9.75l19.83,15.95l27.84,12.53l2.94,7.83l17.63,15.51l4.74,10.71l-5.56,10.63l-0.85,10.56l-2.32,1.88l-15.36,-9.26l-2.72,-4.74l1.02,-2.45l-2.08,-1.12l1.92,-3.62l-7.82,-11.93l-17.84,-0.64l-15.65,-7.56l2.44,-3.24l-7.07,-4.23l-7.32,2.47l-5.69,6.67l0,0l-2.89,-4.94l-4.33,-1.58l0.13,-15.72l-2.92,-2.22l-4.58,-1.04l-1.87,2.56l-2.04,-2.47l-5.01,3.62l-7.34,-7.79l-2.94,-6.46l-4.13,1.31l-7.19,-3.89l3.03,-5.5l-8.61,-6.71l-5.25,3.61l-10.56,-0.56l-1.02,2.47l0,0l-7.27,-3.46l-1.7,1.29l-5.94,-1.73l-2.3,-2.66l3.6,-6.59l-10.53,-5.68l1.38,-6.75l-4.67,-3.63l0,0l-3.55,-2.97l-0.2,-6.38l1.25,-1.52l3.24,1.5l6.95,-5.9l-3.11,-4.08l2.06,-10.86l0,0l14.87,1.78L481.43,368.91z"
        />
        <path
          id="IT-88"
          title="Sardegna"
          className="land"
          d="M89.46,559.47l2.81,0.31l2.12,3.57l-2.7,6.51l-2.8,-5.35L89.46,559.47zM86.27,554.94l-0.13,5.46l-1.62,0.38l-2.84,-3.56L86.27,554.94zM86.96,425.57l0.61,4.65l-2.73,-1.27l-2.18,3.03l1.01,2.8l-2.3,-0.29l0.52,-3.67L86.96,425.57zM146,417.24l0.48,3.84l-2.3,0.35L146,417.24zM133.6,416.13l2.83,5.07l3.97,-1.59l3.1,1.99l0.99,5.79l1.21,-3.42l2.79,-1l2.4,2.81l-2.99,7.27l2.71,-1.92l-0.53,2.25l2.24,-1.26l3.55,1.83l-4.25,0.82l-0.37,3.71h-3.63l4.47,2.41l2.74,-1.81l-1.48,1.88l1.71,2.47l3.77,1.08l-2.6,3.65l4.2,7.56l0.03,5.11l3.78,4.57l-3.14,9.17l-7.18,8.9l1.37,7.48l4.08,3.91l-2.4,7.48l1.6,3.18l-1.83,1.85l-1.81,29.15l-2.47,7.5l1.78,3.31l-2.63,2.16l-0.16,5.91l-2.9,5.41l-11.62,-7.8l-4.44,-0.93l-2.6,3l-2.56,-2.06l-4.67,4.09l0.17,10.93l-8.37,6.96l-6.97,-4.13l-4.1,4.93l-3.99,-11.77l-4.57,-1.02l-0.25,-3.41l-5.26,-6.65l3.42,-5.16l-3.18,-5.59l4.97,-15l-1.27,-10.25l3.24,2.18l2.36,-9.11l-1.52,-2.77l-4.32,1.82l-2.19,-2.07l0.78,-7.45l-1.82,-1.06l3.93,-1.04l1.92,-3.47l-1.77,-4.92l1.33,-7.34l-5.12,-3.51l-0.47,-10.18l-3.76,-6.69l-4.9,1.51l-0.36,-3.42l-2.38,2.66l2.84,-7.1l-3.72,-3.13l4.67,-10.53l-2.4,-3.34l1.4,-2.4l1.72,1.24l-0.25,2.86l4.13,4.43l12.71,0.86l6.83,-5.52l5.01,-0.74l11.22,-13.46l7.99,-3.05l-1.23,-5.25L133.6,416.13zM143.28,415.47l1.03,3.51l-2.96,0.46L143.28,415.47z"
        />
        <path
          id="IT-82"
          title="Sicilia"
          className="land"
          d="M302.68,789.79l4.91,0.51l0.32,1.67L302.68,789.79zM273.26,706.81l4.95,2.53l-1.3,4.02l-5.19,-4.21L273.26,706.81zM291.36,635.17l2.99,3l-4.13,-0.56L291.36,635.17zM278.85,632.73l1.33,2.86l-2.49,-0.75l-0.7,-2.16L278.85,632.73zM457.95,612.73l5.16,2.28l-4.27,2.01l-4.05,10.31l-14.54,20.68l-1.84,11.51l-4.66,5.82l0.59,10.6l8.16,5l-1.69,1.76l-1.61,-1.47l-0.33,3.21l2.44,4.56l3.17,0.73l-1.07,4.24l3.11,2.37l-3.09,-0.28l-6.3,5.99l-2.96,7.3l2.31,7.29l-3.04,2.34l-3.2,-3.39l-7.2,-1.85l-4.95,1.71l-14.74,-5.24l-5.81,-11.15l-6.02,-6.1l-9.89,-3.44l-8.46,0.97l-7.9,-3.51l-10.05,-8.65l-5.38,-0.55l-9.08,-6.2l-5.49,-6.69l-7.47,-0.07l-5.26,-5.24l-12.44,1.08l-3.42,-5.2l-6.71,-3.8l-2.63,-6.56l3.09,-4.74l-1.42,-2.15l3.1,-5.46l-1.48,-1.6l11.32,-6.02l1.05,-5.19l8.7,10.68l9.07,-4.08l-1.28,-3.3l2.86,-3.53l4.26,1.51l6.48,-3.56l3.35,7.56l7.92,-0.32l1.58,4.78l10.22,4.46l13.11,-4.65l13.51,2.2l9.85,-1.71l8.22,-2.63l5.6,-5.98l8.5,-1.71l9.48,4.55l6.14,-4.69l0.27,-5.01l1,3.57l2.25,0.63L457.95,612.73zM427.27,604.98l2.19,3.16l-2.74,-0.91L427.27,604.98zM427.38,598.26l-0.46,5.36l-2.89,-3.04L427.38,598.26zM420.15,594.25l2.57,0.12l-0.03,2.87l-3.86,-1.59L420.15,594.25zM440.89,579.4l-0.64,2.56l-1.31,-1.3L440.89,579.4z"
        />
        <path
          id="IT-52"
          title="Toscana"
          className="land"
          d="M189.13,341.23l0.86,1.8l-1.77,0.56L189.13,341.23zM218.46,338.48l1.75,5.05l-2.8,-2.57L218.46,338.48zM177.15,322.6l0.43,3.59l-2.22,-0.98L177.15,322.6zM194.48,305.33l0.88,6.2l-2.52,1.36l1.9,3.57l-4.16,-3.89l-1.14,1.91l-0.9,-1.6l-7.46,2.37l-2.84,-2.86l1.93,-2.99l5.39,1.48l0.92,-2.94l4.77,2.18L194.48,305.33zM287.49,239.55l0.08,0.16l0,0l-1.88,2.23l-1.37,-1.59l3.04,-1.1l0,0L287.49,239.55zM156.74,199.9l7.01,-7.45l5.35,-0.59l3.99,2.32l0.59,3.89l6.72,2.43l5.63,5.96l2.38,-1.19l9.63,4.68l5.35,7.58l2.6,-3l5.57,0.13l8.26,6.69l4.86,-5.46l1.33,3.1l11.72,-0.4l-3.07,-3.72l3.99,-0.14l7.43,-5.79l4.79,5.7l4.83,-0.21l-1.26,3.14l8.13,-0.74l-5.2,9.66l3.67,4.93l-0.27,3.06l5.58,4.41l4.67,0.21l8.27,4.98l4.58,-1.54l1.55,2.31l4.51,-2.49l0,0l0.17,0.13l0,0l4.44,3.93l-2.72,2.14l-0.61,-3.72l-0.44,3.12l-5.74,3.06l1.45,2.23l0,0l-3.25,5.72l-2.75,0.38l2.34,3.8l-5.94,4l4.66,3.51l0.81,4.9l4.67,-0.13l-6.67,4.18l-3.02,-0.86l-0.49,4.06l-5.18,3.05l0.06,4.98l2.89,1.88l-2.42,9.85l1.25,2.56l-3.22,2.42l0,0l-2.48,-0.14l-2.13,3.08l-1.9,-1.79l-1.16,2.44l3.78,2.96l-2.27,2.35l1.54,4.18l-8.42,5.68l-3.24,-0.07l-0.92,3.7l2.79,1.91l0.09,3.43l-6.8,0.08l-1.85,4.15l0,0l-9.54,-2.9l-2.73,0.72l-1.1,3.18l-4.8,-2.07l-0.44,-2.58l4.07,-0.56l1.32,-5.18l-5.37,-8.14l-4.14,-1.77l-3.14,-6.01l-10.9,-4.2l2.87,-6.07l-2.46,-2.81l-6.06,-1.8l-6.43,1.56l2.22,-10.95l-0.66,-11.12l-11.64,-20.19l-3.03,-23.7l-5.04,-7.69l-6.28,-4.69l0,0l2.5,-3.92l-2.37,-1.47l-1.78,1.5l-0.65,-4.98l-3.93,0.14l1.99,-2.04l-1.47,-1.02l-2.08,1.75l-0.47,-6L156.74,199.9z"
        />
        <path
          id="IT-32"
          title="Trentino-Alto Adige"
          className="land"
          d="M285.92,0.44L287.65,1.8L285.95,4.79L281.66,6.37L284.11,11.69L282.81,13.61L286.52,16.36L289.65,15.7L291.23,18.92L290.04,23.09L293.66,23.74L295.03,28L300.07,30.91L300.07,30.91L294.93,35.29L288.73,34.64L285.39,37.29L285.28,35.03L278.97,31.27L275.47,41.69L266.11,44.3L269.66,47.75L267.09,54.13L263.92,54.82L273.62,67.07L270.2,72.18L259.63,74.28L258.73,83.82L254.52,83.52L252.09,80.11L243.58,82.44L242.34,87.98L239.79,85.86L237.39,87.04L230.86,103.96L225.25,101.62L221.42,104.92L216.34,101.83L219.03,95.19L216.35,93.53L216.35,93.53L206.72,93.45L206.24,95.6L202.12,97L198.83,93.65L199.22,87.24L196.29,81.9L201.03,73.15L203.17,62.98L202.13,57.09L199.49,55.82L205.54,51.6L203.9,46.66L197.49,44.59L196.35,41.9L196.35,41.9L198.24,35.94L193.67,34.17L192.83,30.52L197.49,17.61L201.04,19.19L207.13,16.35L212.1,20.23L210.35,22.87L225.29,24.45L228.52,20.39L229.19,13.53L232.72,9.58L240.66,7.59L244.97,9.65L248.84,6.14L251.77,8.26L255.97,6.02L262.55,9.31L274.18,3.2z"
        />
        <path
          id="IT-55"
          title="Umbria"
          className="land"
          d="M297.73,254.39l-0.74,-0.26l0,0l-0.19,-0.06l0,0l0.89,-0.37l0,0l0.1,0.53l0,0L297.73,254.39zM286.48,253.23l4.16,1.69l3.95,-1.8l-2.95,4.92l4.06,2.42l1.42,-2.09l3.95,1.16l6.36,7.29l7.76,-2.61l-1.23,5.37l5.96,12.03l-1.76,4.45l3.31,3.67l0.02,8.98l4,2.6l0.12,4.04l1.22,-3.07l2.69,-0.61l5.51,6.24l3.92,-2.43l1.16,5.43l-3.47,3.91l0,0l-2.08,5.73l-13.29,2.25l0.04,3.72l-9.22,4.4l1.37,2.04l-2.99,1.97l-3.29,-1.73l-0.22,3.88l-4.33,3.36l-0.82,-2.68l-3.64,0.53l-1.12,-7.05l-6.56,0.42l-4.8,-12.05l-8.49,1.29l-4.14,-2.56l-1.29,-2.11l2.86,-3.02l-4.4,-5.68l0,0l3.23,-2.42l-1.25,-2.56l2.42,-9.85l-2.89,-1.88l-0.06,-4.98l5.18,-3.05l0.49,-4.06l3.02,0.86l6.67,-4.18l-4.67,0.13l-0.81,-4.9l-4.66,-3.51l5.94,-4l-2.34,-3.8l2.75,-0.38L286.48,253.23z"
        />
        <path
          id="IT-23"
          title="Valle d'Aosta"
          className="land"
          d="M48.76,82.18L53.16,82.81L56.03,86.76L63.34,87.29L63.34,87.29L63.34,96.4L67.03,101.3L65.26,111.14L61.61,110.69L56.72,114.16L44.27,111.83L30.54,119.93L26.57,119.54L26.21,116.69L24.44,120.25L24.44,120.25L19.2,117.47L19.19,107.62L14.85,106.75L9.36,101.47L9.98,93.18L16.17,92.44L21.39,86.8L24.55,91.6L27.12,90.05L28.98,91.55L34.18,86.96L38.9,88.8z"
        />
        <path
          id="IT-34"
          title="Veneto"
          className="land"
          d="M301.95,155.08l1.19,0.9l-2.37,0.53L301.95,155.08zM301.58,31l11.33,3.22l0,0l0.96,6.89l-6.01,2.24l1.5,3.48l-6.69,0.03l-10.73,14.67l9.01,8.4l-0.45,3.58l-4.67,4.77l1.66,6.87l3.96,1.56l2.44,6.99l3.92,0.1l1.29,3.04l6.53,-5.01l1.33,2.56l2.73,-2.13l3.49,2.47l1.69,-2.16l2.33,8.73l4.73,6.17l0,0h-6.29l-31.73,18.06l-3.36,11.17l1.85,10.77l3.02,4.33l-1.31,0.61l2.04,1.18l0.61,-2.05l4.3,5.37l-1.69,8.3l-2.28,-3.85l-1.67,2.11l1.98,4.09l-1.43,1.31l-2.41,-1.92l0,0l-3.23,-3.64l-0.53,-5.22l-6.98,1.05l-2.29,-3.08l-15.17,-0.37l-10.09,6.5l-3.24,-3.27l-5.7,0.38l-0.16,-1.49l0,0l-8.2,-5.32l0,-2.46l-4.55,-0.31l1.45,-3.65l-3,-1.12l-3.73,1.99l-4.25,-6.98l-10.43,-8.62l-2.41,2.01l-2,-1.37l0.98,-7.69l-4,-0.5l-0.73,-13.73l11.09,-16.5l0,0l2.68,1.66l-2.69,6.64l5.08,3.09l3.83,-3.3l5.61,2.34l6.53,-16.92l2.4,-1.18l2.55,2.12l1.25,-5.53l8.51,-2.33l2.42,3.41l4.21,0.3l0.9,-9.54l10.57,-2.1l3.42,-5.11l-9.7,-12.25l3.17,-0.7l2.57,-6.37l-3.55,-3.46l9.36,-2.61l3.5,-10.42l6.32,3.76l0.11,2.26l3.34,-2.65l6.2,0.65l5.14,-4.38l0,0L301.58,31z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Italy))
