import React from 'react'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { baseUrl } from '../lib/_processUtil'

const HallOfFamePage = () => (
  <div className="hall-of-fame-page">
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '48px',
        height: '100%'
      }}>
      Hall of fame Page
      <div>
        {['gold', 'silver', 'bronze'].map(medalColor => (
          <ImageWithDimensions
            key={medalColor}
            alt="start icon"
            className="menu-button-icon"
            src={`${baseUrl}/images/hall-of-fame/${medalColor}-einstein.webp`}
          />
        ))}
      </div>
    </div>
  </div>
)

export default HallOfFamePage
