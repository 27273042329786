import React from 'react'
import {
  FadeIn,
  FadeOut,
  Flip,
  FlipInX,
  FlipInY,
  FlipOutX,
  FlipOutY,
  BounceOut,
  Bounce,
  BounceIn,
  RollIn,
  RollOut,
  RotateIn,
  RotateOut,
  RubberBand,
  DrawBarAnim,
  SlideInLeft,
  SlideOutRight,
  SlideOutLeft,
  SlideInRight,
  drawCircleAnimOne,
  drawCircleAnimTwo,
  drawCircleAnimThree,
  drawCircleAnimFour,
  drawCircleAnimFive
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import StopWatch from '../../../../components/StopWatch'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnEasySubtractionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_easy_subtractions"
    frReadSpeed="1"
    sentencesNumberPlusRedirect={11}
    startTimesOf={{
      2: 3000,
      3: 9000,
      4: 16000,
      5: 19500,
      6: 27500,
      7: 35000,
      8: 38000,
      9: 44500,
      10: 47500,
      11: 57000
    }}
    animations={{
      1: (
        <RotateIn className="learn-flex-center-center" duration={0.4}>
          <RotateOut className="position-relative" delay={2.6} duration={0.4}>
            <img className="width-100 height-100" src={`${baseUrl}/images/learn/stopwatch.webp`} />
            <StopWatch />
          </RotateOut>
        </RotateIn>
      ),
      2: (
        <div className="learn-flex-center-center">
          <FadeIn className="max-100" duration={0.5}>
            <FadeOut className="max-100" delay={3.5} duration={0.5}>
              <img className="left-hand max-100 height-100" src={`${mathsNumbersUrls}/0/5.webp`} />
            </FadeOut>
          </FadeIn>
          <FadeIn delay={2.5} className="position-absolute max-100">
            <FadeOut className="max-100" delay={3.5} duration={0.5}>
              <img className="max-100 height-100" src={`${baseUrl}/images/learn/red-cross.webp`} />
            </FadeOut>
          </FadeIn>
          <FlipInY delay={3.5} duration={0.5} className="position-absolute">
            <FlipOutY delay={5.5} duration={0.5}>
              <img className="max-100" src={`${baseUrl}/images/learn/mentally.webp`} />
            </FlipOutY>
          </FlipInY>
        </div>
      ),
      3: (
        <div className="learn-flex-center-center">
          <RollIn delay={1} className="position-absolute">
            <RollOut delay={5.5}>
              <img
                className="max-100 height-100"
                src={`${baseUrl}/images/learn/pen-and-paper.webp`}
              />
            </RollOut>
          </RollIn>
        </div>
      ),
      4: (
        <div className="learn-flex-column-space-between learn-horizontal-numbers">
          <FlipOutX delay={3.5} duration={0.5}>
            <FlipInX>
              <RubberBand delay={1.25} duration={0.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/17/8.webp`} />
              </RubberBand>
            </FlipInX>
          </FlipOutX>
          <FlipOutX delay={3.5} duration={0.5}>
            <FlipInX delay={0.25}>
              <img
                className="width-100 plus-sign minus-sign"
                src={`${mathsNumbersUrls}/17/minus.webp`}
              />
            </FlipInX>
          </FlipOutX>
          <FlipOutX delay={3.5} duration={0.5}>
            <FlipInX delay={0.5}>
              <RubberBand delay={1.5} duration={0.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/17/3.webp`} />
              </RubberBand>
            </FlipInX>
          </FlipOutX>
        </div>
      ),
      5: (
        <div className="learn-flex-center-center">
          <SlideInLeft className="max-100 flex" duration={0.5}>
            <FadeIn className="width-100" duration={0.5}>
              <SlideOutRight className="width-100" delay={2} duration={0.5}>
                <FadeOut className="width-100" delay={2} duration={0.5}>
                  <img className="width-100" src={`${baseUrl}/images/arrows/11.webp`} />
                </FadeOut>
              </SlideOutRight>
            </FadeIn>
          </SlideInLeft>
          <SlideInRight delay={2.5} className="position-absolute max-100 flex">
            <FadeIn className="width-100" delay={2.5} duration={0.5}>
              <SlideOutLeft className="width-100" delay={4.5} duration={0.5}>
                <FadeOut className="width-100" delay={4.5} duration={0.5}>
                  <img className="width-100" src={`${baseUrl}/images/arrows/12.webp`} />
                </FadeOut>
              </SlideOutLeft>
            </FadeIn>
          </SlideInRight>
          <FlipInY delay={5} duration={0.5} className="position-absolute max-100 flex">
            <FlipOutY className="width-100" delay={7} duration={0.5}>
              <img className="width-100" src={`${baseUrl}/images/learn/question-mark.webp`} />
            </FlipOutY>
          </FlipInY>
        </div>
      ),
      6: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container">
            <DrawBarAnim number="one" />
            <DrawBarAnim number="two" />
            <DrawBarAnim number="three" />
            <DrawBarAnim number="four" />
            <DrawBarAnim number="five" />
            <DrawBarAnim number="six" />
            <DrawBarAnim number="seven-medium-subtraction" />
            <DrawBarAnim number="eight-medium-subtraction" />
          </div>
        </div>
      ),
      7: (
        <div className="learn-flex-center-center">
          <div className="learn-flex-column-space-between position-relative">
            <div className="math-bars-container">
              <div className="math-bar math-bar-one" />
              <div className="math-bar math-bar-two" />
              <div className="math-bar math-bar-three" />
              <div className="math-bar math-bar-four" />
              <div className="math-bar math-bar-five" />
              <div className="math-bar math-bar-six" />
              <DrawBarAnim number="six-cross-medium-subtraction" />
              <div className="math-bar math-bar-seven-medium-subtraction" />
              <DrawBarAnim number="seven-cross-medium-subtraction" />
              <div className="math-bar math-bar-eight-medium-subtraction" />
              <DrawBarAnim number="eight-cross-medium-subtraction" />
            </div>
          </div>
        </div>
      ),
      8: (
        <FadeOut className="learn-flex-column-space-between" delay={6} duration={0.5}>
          <div className="math-bars-container">
            <div className="math-bar math-bar-one" />
            {drawCircleAnimOne}
            <div className="math-bar math-bar-two" />
            {drawCircleAnimTwo}
            <div className="math-bar math-bar-three" />
            {drawCircleAnimThree}
            <div className="math-bar math-bar-four" />
            {drawCircleAnimFour}
            <div className="math-bar math-bar-five" />
            {drawCircleAnimFive}
            <div className="math-bar math-bar-six" />
            <div className="math-bar math-bar-six-cross-medium-subtraction" />
            <div className="math-bar math-bar-seven-medium-subtraction" />
            <div className="math-bar math-bar-seven-cross-medium-subtraction" />
            <div className="math-bar math-bar-eight-medium-subtraction" />
            <div className="math-bar math-bar-eight-cross-medium-subtraction" />
          </div>
        </FadeOut>
      ),
      9: (
        <div className="learn-flex-column-space-between learn-horizontal-numbers justify-content-center">
          <FadeIn className="width-100 flex" delay={0.25} duration={0.5}>
            <img className="width-100 flex" src={`${mathsNumbersUrls}/1/8.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.5} duration={0.5}>
            <img className="plus-sign width-100 flex" src={`${mathsNumbersUrls}/1/minus.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.75} duration={0.5}>
            <img className="width-100 flex" src={`${mathsNumbersUrls}/1/3.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={1} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/1/equal.webp`} />
          </FadeIn>
          <FadeIn className="learn-easy-six-one flex" delay={1.25} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/1/5.webp`} />
          </FadeIn>
        </div>
      ),
      10: (
        <div className="learn-flex-center-center">
          <FlipInY duration={0.5}>
            <Flip delay={0.5} duration={2}>
              <FlipOutY delay={2.5} duration={0.5}>
                <img className="max-100" src={`${baseUrl}/images/learn/thinking-girl.webp`} />
              </FlipOutY>
            </Flip>
          </FlipInY>

          <FlipInY delay={2.5} duration={0.5} className="position-absolute">
            <Flip delay={3} duration={2}>
              <FlipOutY delay={5} duration={0.5}>
                <img className="max-100" src={`${baseUrl}/images/learn/thinking-boy.webp`} />
              </FlipOutY>
            </Flip>
          </FlipInY>

          <BounceIn delay={6.5} duration={0.5} className="position-absolute">
            <Bounce delay={7} duration={1}>
              <BounceOut delay={8} duration={0.5}>
                <img src={`${baseUrl}/images/learn/learn-easy-subtractions-end.webp`} />
              </BounceOut>
            </Bounce>
          </BounceIn>
        </div>
      )
    }}
  />
)

export default LearnEasySubtractionsPage
