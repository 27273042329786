import React from 'react'
import {
  FadeIn,
  FadeOut,
  Swing,
  Pulse,
  Bounce,
  BounceIn,
  BounceOut,
  Wobble
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnBeginnerSubtractionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_beginner_subtractions"
    sentencesNumberPlusRedirect={10}
    startTimesOf={{
      2: 5000,
      3: 12000,
      4: 15000,
      5: 16500,
      6: 19000,
      7: 21000,
      8: 25000,
      9: 28000,
      10: 30000
    }}
    animations={{
      1: (
        <div className="learn-flex-center-center">
          <FadeIn duration={0.5}>
            <Swing delay={1.5}>
              <FadeOut delay={4.25} duration={0.5}>
                <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
              </FadeOut>
            </Swing>
          </FadeIn>
          <FadeIn duration={0.5}>
            <Swing delay={3}>
              <FadeOut delay={4.25} duration={0.5}>
                <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
              </FadeOut>
            </Swing>
          </FadeIn>
        </div>
      ),
      2: (
        <div className="flex position-relative">
          <FadeIn delay={1}>
            <Wobble delay={2} duration={1.5}>
              <FadeOut delay={6}>
                <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/4.webp`} />
              </FadeOut>
            </Wobble>
          </FadeIn>
          <BounceIn className="position-absolute width-100 height-100" delay={3.5}>
            <BounceOut className="position-absolute width-100 height-100" delay={5.5}>
              <img
                className="beginner-subtraction-cross-one"
                src={`${baseUrl}/images/learn/red-cross-finger-1.webp`}
              />
              <img
                className="beginner-subtraction-cross-two"
                src={`${baseUrl}/images/learn/red-cross-finger-2.webp`}
              />
            </BounceOut>
          </BounceIn>
        </div>
      ),
      3: (
        <div className="learn-flex-center-center">
          <BounceIn duration={0.75}>
            <Pulse delay={0.75} duration={1.25}>
              <BounceOut delay={2} duration={0.5}>
                <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
              </BounceOut>
            </Pulse>
          </BounceIn>
          <BounceIn duration={0.5}>
            <Pulse delay={0.5} duration={1.5}>
              <BounceOut delay={2} duration={0.5}>
                <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
              </BounceOut>
            </Pulse>
          </BounceIn>
        </div>
      ),
      4: (
        <div className="flex position-relative">
          <FadeIn>
            <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/4.webp`} />
          </FadeIn>
        </div>
      ),
      5: (
        <div className="flex position-relative">
          <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/4.webp`} />
          <BounceIn className="position-absolute width-100 height-100" duration={0.5}>
            <BounceOut className="position-absolute width-100 height-100" delay={1} duration={0.5}>
              <img
                className="beginner-subtraction-cross-one"
                src={`${baseUrl}/images/learn/red-cross-finger-1.webp`}
              />
              <img
                className="beginner-subtraction-cross-two"
                src={`${baseUrl}/images/learn/red-cross-finger-2.webp`}
              />
            </BounceOut>
          </BounceIn>
        </div>
      ),
      6: (
        <div className="flex position-relative">
          <FadeOut>
            <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/4.webp`} />
          </FadeOut>
          <FadeIn className="position-absolute">
            <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/3.webp`} />
          </FadeIn>
        </div>
      ),
      7: (
        <div className="flex position-relative">
          <FadeOut delay={0.25} duration={3}>
            <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/3.webp`} />
          </FadeOut>
          <FadeIn className="position-absolute" delay={0.25} duration={3}>
            <FadeOut delay={3.25} duration={0.75}>
              <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/2.webp`} />
            </FadeOut>
          </FadeIn>
        </div>
      ),
      8: (
        <div className="learn-flex-column-space-between learn-horizontal-numbers">
          <FadeIn delay={0.25} duration={0.5}>
            <img className="width-100" src={`${mathsNumbersUrls}/15/4.webp`} />
          </FadeIn>
          <FadeIn delay={0.5} duration={0.5}>
            <img
              className="width-100 plus-sign minus-sign"
              src={`${mathsNumbersUrls}/15/minus.webp`}
            />
          </FadeIn>
          <FadeIn delay={0.75} duration={0.5}>
            <img className="width-100" src={`${mathsNumbersUrls}/15/2.webp`} />
          </FadeIn>
          <FadeIn delay={1} duration={0.5}>
            <img className="width-100 equal-sign" src={`${mathsNumbersUrls}/15/equal.webp`} />
          </FadeIn>
          <FadeIn delay={1.25} duration={0.5}>
            <img className="width-100" src={`${mathsNumbersUrls}/15/2.webp`} />
          </FadeIn>
        </div>
      ),
      9: (
        <div className="learn-flex-center-center">
          <BounceIn duration={0.5}>
            <Bounce delay={1} duration={1}>
              <BounceOut delay={2} duration={0.5}>
                <img
                  className="width-100"
                  src={`${baseUrl}/images/learn/learn-beginner-subtractions-end.webp`}
                />
              </BounceOut>
            </Bounce>
          </BounceIn>
        </div>
      )
    }}
  />
)

export default LearnBeginnerSubtractionsPage
