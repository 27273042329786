import React, { Component } from 'react'

class StopWatch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: '00:00:00',
      milliseconds: 0
    }
  }

  format(milliseconds) {
    const mm = Math.floor(milliseconds / 6000) % 60
    const ss = Math.floor(milliseconds / 100) % 60
    const ms = milliseconds % 100
    return `${mm < 10 ? '0' + mm : mm}:${ss < 10 ? '0' + ss : ss}:${ms < 10 ? '0' + ms : ms}`
  }

  tick() {
    this.setState({
      milliseconds: this.state.milliseconds + 1,
      display: this.format(this.state.milliseconds)
    })
  }

  componentDidMount() {
    this.timer = setInterval(() => this.tick(), 10)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  reset() {
    this.setState({
      display: '00:00:00',
      milliseconds: 0,
      laps: [],
      running: false
    })
    clearInterval(this.timer)
  }

  render() {
    return (
      <div className="stopwatch">
        <div className="timer">{this.state.display}</div>
      </div>
    )
  }
}

export default StopWatch
