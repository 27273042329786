import React from 'react'
import { connect } from 'react-redux'
import TopicNavigationPage from '../components/TopicNavigationPage'
import { mapMinimalStateToProps } from '../redux/mapToPropsUtil'

const navigationCommands = ['menu', 'questions', 'settings', 'contact']
const annyangNotSupportedNavigationCommands = ['menu', 'questions', 'contact']

const content = translate => (
  <div className="content">
    <iframe
      src="https://www.youtube.com/embed/uNNlTvviUZc"
      title="About Mini Einstein"
      className="promotional-video"
    />

    <div className="content-attribution">
      <div className="content-attribution-title">{translate('external_credits')}:</div>
      {translate('design')}
      <a href="http://www.freepik.com" target="blank">
        {' '}
        brgfx / Freepik
      </a>
      . {translate('geography_design')}{' '}
      <a href="https://www.amcharts.com/svg-maps" target="blank">
        AmCharts
      </a>
      (CC BY-NC 4.0 License) &{' '}
      <a href="https://www.wikipedia.org/" target="blank">
        Wikipedia
      </a>
      .
    </div>
  </div>
)

const AboutPage = ({ isAnnyangSupported }) => (
  <TopicNavigationPage
    navigationCommands={
      isAnnyangSupported ? navigationCommands : annyangNotSupportedNavigationCommands
    }
    pageName="about_page"
    topics={['topic_questions', 'topic_parameters', 'topic_contact']}
    subTopics={isAnnyangSupported ? navigationCommands : annyangNotSupportedNavigationCommands}
    borderRadius={0.45}
    SEODescription="E=MC² - Entertainers, Makers & Creators² - Discover the Story, how the application came together/was created & designed via Videos, introductions and History">
    {content}
  </TopicNavigationPage>
)

export default connect(mapMinimalStateToProps)(AboutPage)
