import React from 'react'
import LearnPage from '../../../../src/components/LearnPage'
import { getFoodImageUrl } from '../../../../src/pages/words/food/util'

const LearnFoodPage = () => (
  <LearnPage
    parentPage="food_words"
    grandParentPage="words"
    pageName="learn_food"
    parentPagePath="/words/food"
    getImageUrl={getFoodImageUrl}
  />
)

export default LearnFoodPage
