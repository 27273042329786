import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 803
const height = 566

const cities = {
  'Santo Domingo': { x: 445, y: 330 }
}

const DominicanRepublic = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map dominican-republic">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="DO-01"
          title="Distrito Nacional"
          className="land"
          d="M435.2,341.16l0.17,-2.24l2.37,-2.49l-0.13,-5.53l-7.65,-7.74l2.77,-1.52l1.19,-5.25l1.72,-1.24l2.37,0.97l1.85,4.7l4.61,4.28l10.94,-2.9l3.17,2.07l-1.55,7.62l0,0l-1.79,-0.25l-6.45,3.19l-4.66,4.26L435.2,341.16z"
        />
        <path
          id="DO-02"
          title="Azua"
          className="land"
          d="M239.39,214.1l6.16,-3.42l5,5.02l-1.54,3.06l0.38,3.46l3.85,3.86l0,1.92l-2.69,1.14l0.38,9.59l3.46,4.24l-0.39,3.13l1.27,2.1l6.4,4.39l5.79,8.47l4.23,-0.36l5.78,1.62l4.83,4.11l1.31,3.98v6.58l-0.78,4.93l-2.29,2.6l0,0l5.38,3.86l2.31,3.84l0.38,6.51l-2.69,4.2l0.77,1.54l8.85,6.17l3.46,7.68l5,-0.74l1.15,2.69l7.31,1.96l3.08,4.23l5.38,0.8l1.15,1.92l-1.15,0.76l0,2.3l1.92,1.16v2.3l1.92,3.07l-0.39,2.68l3.7,2.55l0,0l1.68,2.83v3.06l-4.24,5.71l-0.39,5.73l1.15,5.36l-2.31,5.34l-8.85,2.63l-8.46,0.34l-1.11,1.05l0,0l-1.85,-1.44v-1.75l2.51,-5.51l1.79,-0.44l-0.72,-6.76l1.37,-3.82l-2.63,-10.89l-4.3,-2.88l-14.94,-2.82l-3.76,0.56l-1.38,3.07l-3.35,1.44l1.61,5.95l-5.08,6.38l-3.76,1.94l-10.82,0.25l-0.18,-1.69l-1.97,-0.44l-1.79,1.06l0.36,1.88l-3.94,2.63l0.24,-1.13l-1.49,-0.12l1.43,-1.75l-2.99,-1.13l-0.54,1.44l-2.93,1.19l-1.79,2.69l-0.18,3.13l-2.81,3.75l-7.35,7.01l-9.08,3.44l-2.45,-2.22l0,0l2.33,-1.87l1.93,-6.87l-0.77,-3.44l-4.23,-6.14l2.7,-2.28v-1.91l-3.08,-1.93l-4.23,3.42l-4.62,-1.56l-1.92,-3.83l-3.46,0.36l-2.69,-6.14l6.54,-2.64l1.93,-8.79l3.85,-0.74l1.54,-1.9l-1.15,-3.83l-5.77,-3.48l-3.07,-6.91l0,0l2.31,0.78l1.92,4.6l6.93,-3.4l-4.23,-4.23l-26.54,-9.34l-6.54,-6.16l-1.15,-2.56l0,0l5.49,-0.18l5.22,-5.41h2.26l2.55,-6.33l3.84,-0.1l4.11,-2.54l-0.38,-2.68l-2.31,-2.31v-1.92l3.46,-1.9l-2.31,-4.61l-0.38,-6.13l3.46,-3.43l2.69,0.02l1.54,1.16l2.69,6.92l1.54,0.01l6.54,-11.85v-2.68l-4.23,-11.14l2.31,-1.52l2.31,-4.59l-0.38,-4.22l4.23,-1.51l1.54,-2.68l5.39,-1.12l1.54,-2.29l-1.92,-4.61l6.54,-1.11l1.16,-1.53L239.39,214.1z"
        />
        <path
          id="DO-03"
          title="Baoruco"
          className="land"
          d="M100.88,290.4L106.65,294.26L110.88,295.43L118.57,294.71L124.34,297.81L125.5,300.11L128.57,298.6L131.26,299.38L131.65,301.68L139.34,303.25L143.57,300.21L145.5,300.22L148.19,297.18L161.27,298.4L175.12,293.5L180.12,295.06L181.27,298.26L181.27,298.26L182.42,300.81L188.96,306.98L215.5,316.32L219.73,320.55L212.8,323.96L210.88,319.35L208.58,318.57L208.58,318.57L207.04,320.86L208.96,324.32L203.57,323.9L205.11,325.83L204.34,330.03L198.95,331.15L198.18,334.97L195.49,335.72L193.57,334.18L188.56,338.74L184.33,338.34L183.95,339.86L187.8,341.42L187.41,342.94L175.1,347.85L172.02,351.66L171.63,357.77L173.55,358.93L172.78,360.84L172.78,360.84L163.55,361.55L160.48,358.09L157.79,348.9L154.33,346.58L151.25,342.36L147.02,342.33L134.32,350.68L132.4,350.67L122.02,342.19L115.86,339.1L107.02,337.52L82.79,325.52L80.86,322.45L80.1,314.4L85.87,301.04L85.87,294.53L94.34,293.04z"
        />
        <path
          id="DO-04"
          title="Barahona"
          className="land"
          d="M208.58,318.57l3.08,6.91l5.77,3.48l1.15,3.83l-1.54,1.9l-3.85,0.74l-1.93,8.79l-6.54,2.64l2.69,6.14l3.46,-0.36l1.92,3.84l4.62,1.56l4.23,-3.42l3.08,1.93v1.91l-2.69,2.28l4.23,6.14l0.77,3.45l-1.92,6.87l-2.33,1.87l0,0l-1.79,-1.47l-1.19,-4.88l-3.41,-3.13l-5.38,-2.5l-2.39,0.69l-0.3,-1.19l-5.62,-2.56l-3.11,0.88l-0.12,1.56l2.39,-1.38l0.96,0.94l-8.78,7.82l-1.49,5.94l5.56,8.19l-1.91,2.06l4.13,4.38l1.32,4.75l2.51,2.75l-1.85,2.63l-1.79,7.94l-4.96,9.12l-2.27,0.88l-2.45,3.12l0.12,1.94l-4.6,3.19l0.06,4.37l-1.49,0.19l-4.24,4.43l-8.9,14.67l-3.82,4.62l-7.89,6.06l-3.41,6.18l-1.24,-1.82l0,0l-0.31,-2.53l1.15,-1.52l-0.77,-4.96l-1.45,-1.06l-0.25,-3.25l-5.09,-6.38h-2.8l-1.27,4.25l-3.56,1.06l-4.04,-5.03l-2.31,-1.54l-1.79,0.3l-1.02,-3.3l-2.54,-1.86h-4.58l-1.53,-1.86l0.76,-9.83l2.54,1.33l2.8,-0.8l4.07,-5.32l0,0v-6.91l0,0l-3.56,-5.85l4.58,-0.27l1.27,-2.92l-5.85,-4.26l-4.63,-0.51l0,0l0.64,-6.86l1.92,-2.66l3.08,-0.75l3.08,1.55l6.54,-0.35l-3.07,-10.33l-5.77,-11.11l0,-1.53l5.77,-7.61l6.15,4.62l13.85,1.99l9.23,-2.62l5.39,-6.85l0.77,-3.05l0,0l0.77,-1.91l-1.92,-1.16l0.39,-6.12l3.08,-3.81l12.31,-4.9l0.39,-1.53l-3.85,-1.55l0.39,-1.53l4.23,0.41l5,-4.56l1.92,1.54l2.69,-0.75l0.77,-3.82l5.39,-1.12l0.77,-4.2l-1.54,-1.92l5.39,0.41l-1.92,-3.45L208.58,318.57z"
        />
        <path
          id="DO-05"
          title="Dajabón"
          className="land"
          d="M57.9,65.02L62.88,68.88L66.73,68.52L79.04,73.59L97.5,72.93L101.35,70.64L106.73,71.05L108.65,77.23L117.88,81.9L120.57,85.39L127.11,88.12L127.11,88.12L127.11,89.66L121.34,95.79L119.03,100.4L120.18,114.65L115.95,118.47L116.32,135.02L110.55,136.14L105.17,134.96L105.94,138.81L110.17,141.91L110.94,143.83L110.94,143.83L105.16,150.72L98.63,148L95.55,148.75L91.32,146.42L89.39,148.33L88.62,154.09L85.16,154.07L80.16,159.04L80.16,159.04L73.7,158.58L73.27,157.53L68.21,156.48L64.32,153.32L64.32,151.56L60.85,149.89L60.68,147.6L59.25,151.22L57.47,147.79L55.62,148.41L54.18,147.18L54.6,145.06L53,144.62L53.42,141.89L51.13,137.84L47.5,135.56L50.53,133.88L54.75,133.61L55.76,130.26L60.65,127.52L62.08,128.31L62.84,126.19L65.71,129L67.15,129L66.29,120.72L70.33,112.25L70.32,108.19L67.12,102.31L68.27,94.09L65.83,94.27L65.22,89.6L63.03,89.6L63.02,85.63L56.35,78.23L55.91,66.94z"
        />
        <path
          id="DO-06"
          title="Duarte"
          className="land"
          d="M384.84,98.82L386.76,101.14L394.07,103.88L394.84,105.81L397.53,105.44L399.45,106.6L402.53,116.63L401.37,124.32L402.91,125.48L408.29,138.21L422.52,139.82L424.06,140.99L423.67,147.91L425.98,150.23L435.98,152.21L444.06,155.71L457.13,159.25L465.21,165.06L477.13,166.66L477.13,166.66L480.98,161.69L491.75,163.67L495.21,162.92L497.14,165.24L503.68,165.28L503.29,166.81L501.75,166.8L499.83,169.1L498.67,173.7L497.13,173.69L496.75,175.23L494.44,174.45L493.67,175.98L491.75,176.35L491.75,178.66L488.28,180.94L485.98,180.93L485.98,183.24L488.28,185.94L489.82,190.56L484.82,190.53L483.66,191.67L483.66,191.67L477.12,190.1L472.51,192L465.97,191.19L461.74,193.09L457.12,191.52L457.12,201.89L455.58,203.04L448.27,202.61L448.27,202.61L450.97,190.34L444.43,188.76L437.12,184.11L435.2,184.1L431.35,186.77L428.66,184.83L419.81,182.48L415.97,179L413.27,178.98L412.12,176.67L408.27,178.57L407.12,177.03L404.43,176.63L402.12,179.31L400.96,178.91L400.96,178.91L400.96,175.84L400.96,175.84L398.66,176.6L396.73,180.04L396.73,177.35L392.5,178.1L393.27,175.41L391.73,175.02L387.5,178.84L386.35,177.3L385.19,179.98L381.35,178.8L380.19,176.88L377.89,177.63L377.12,175.71L370.96,176.06L369.81,176.82L370.57,178.36L370.57,178.36L369.04,178.35L367.5,175.27L361.73,172.16L355.96,166.37L352.89,160.58L352.89,160.58L350.97,152.88L355.97,146.37L355.97,142.53L357.9,138.31L357.51,135.61L360.98,122.17L367.14,117.58L377.53,113.02L377.53,111.87L374.45,110.7L373.68,108.77L374.83,106.46L381.76,103.42L382.15,100.35L380.22,98.41L380.22,98.41z"
        />
        <path
          id="DO-08"
          title="El Seibo"
          className="land"
          d="M615.64,201.59l1.79,0.57l4,6.72l3.47,0.19l0.78,2.64l6.99,3.33l11.12,-0.88l1.91,-2.7l-2.45,-3.33l2.27,-1l3.82,1.26l3.53,-3.96l3.29,1.19l2.63,-1.26l5.56,1.19l11.24,7.85l6.09,-0.82l4.78,3.27l4.53,0.38l0,0v3.87l-1.92,-0.78l-0.39,3.45l-1.92,-0.78l-1.15,2.3l-3.08,0.37l-0.39,6.14l-4.23,2.66l7.3,16.54l-0.39,9.97l-9.62,8l-3.46,6.5l-5.39,-0.8l-6.15,1.12l-0.39,6.13l-1.54,1.52l1.92,4.61l0,0l-7.31,-1.19l-6.93,14.52l-3.46,4.58l-6.54,4.94l-0.77,2.68l-4.23,4.19l-4.62,1.51l0,0l1.16,-9.18l1.92,-0.75l-0.77,-1.53l-6.15,-0.04l-4.62,1.89l-5.77,-3.09l-1.92,-4.61l-6.15,-4.25l-3.46,-1.17l-4.62,1.12l-1.15,-2.69l-3.07,-1.17l0,0l0.39,-3.83l3.85,-2.28l3.86,-23.35l-1.92,-4.23l-6.53,-8.09l-7.31,-3.11l-1.15,-3.84l-3.46,-1.55l-2.69,-6.54l-2.31,-2.31l0,-2.3l1.93,-9.58l1.92,-0.37l15.38,8.91l12.31,1.6l1.16,-1.91l-0.38,-2.69l-8.08,-6.95l-3.08,-5.39l-0.38,-4.22l3.08,-0.75l2.3,-2.35l0,0l2.02,2.19l2.75,-0.44l1.38,-0.44l6.33,-0.38l1.14,1.32l-0.54,1.63l1.79,0.63l1.61,-2.45l-0.24,-5.47l-1.38,-1.82l-2.57,0.63L615.64,201.59z"
        />
        <path
          id="DO-09"
          title="Espaillat"
          className="land"
          d="M351.07,49.38L354.3,54.24L363.98,62.13L373.19,66.1L382.09,66.36L394.22,70.77L399.6,70.9L411.02,68.55L411.02,68.55L410.62,76.24L408.69,80.47L394.46,87.71L387.15,86.51L386,87.27L386.76,93.06L384.84,98.82L384.84,98.82L380.22,98.41L380.22,98.41L374.84,92.22L374.84,86.82L370.99,84.88L364.07,86.38L362.15,89.06L359.84,89.82L356.37,98.66L354.45,99.42L350.99,97.09L349.83,91.69L345.6,90.9L340.99,87.02L335.99,98.54L336.37,103.94L334.44,107.01L334.44,110.09L337.52,114.72L337.52,117.42L334.82,123.17L330.58,139.31L330.58,139.31L328.28,142.75L323.27,142.73L322.51,134.65L319.05,128.09L315.2,132.3L311.74,132.66L301.35,131.45L299.04,127.97L299.04,127.97L301.36,124.52L305.59,125.7L302.9,116.07L305.59,109.92L305.21,104.15L313.29,98.8L313.29,94.18L313.29,94.18L315.6,92.27L316.37,83.41L319.84,79.19L318.68,74.18L320.22,71.49L319.46,68.02L321.76,67.26L321.38,65.33L323.3,63.42L326.38,64.97L327.54,63.82L330.23,63.84L331,65L331.38,62.3L334.85,62.71L336.39,61.56L338.69,64.66L340.23,64.67L341.39,62.75L342.92,64.3L343.31,62.37L344.85,64.31L346.38,63.93L344.85,61.61L350.23,60.1L348.7,54.69L351.01,49.38L351.01,49.38z"
        />
        <path
          id="DO-30"
          title="Hato Mayor"
          className="land"
          d="M600.59,207.63l-2.3,2.35l-3.08,0.75l0.38,4.22l3.08,5.39l8.08,6.95l0.38,2.69l-1.15,1.91l-12.31,-1.6l-15.38,-8.91l-1.92,0.37l-1.93,9.58l0,2.3l2.31,2.31l2.69,6.54l3.46,1.55l1.15,3.84l7.31,3.11l6.54,8.09l1.92,4.23l-3.86,23.36l-3.85,2.28l-0.39,3.83l0,0l-4.23,3.81l-5,0.36l-1.92,6.12l0.77,5.36l-2.31,-0.01l-0.39,2.3l-6.92,5.32l-1.54,3.44l-4.23,-6.91l-3.07,-9.97l-3.46,-2.32l-1.92,-6.52l-3.46,-5.77l-2.31,-1.16l-0.38,-1.53l2.7,-3.43l-1.54,-2.31l0,-6.13l-2.31,-6.53l-3.46,-1.55l-0.77,-1.92l1.54,-7.28l0,0l1.54,-1.14l0.39,-2.68l1.54,-0.37l3.47,-4.58l4.23,-1.13l1.54,-3.83l3.08,-0.37l1.54,-4.98l-3.85,-1.17l-6.15,1.12l-9.62,-4.66l-16.15,-8.92l-2.69,-3.08l-5.38,-2.33l-3.84,-3.48l-6.15,-1.95l-1.92,-3.08l0,0l5.06,-16.43l0,0l2.45,0.5l8.19,-2.89l3.53,2.2l10.16,-1.76l1.79,2.83l5.62,-0.88l2.75,2.51l2.51,-1.26l0.72,-4.71l-8.43,0.63l6.1,-1.7l7.77,-0.38l6.57,4.21l-0.42,3.83l1.31,2.14l5.44,1.32l2.45,2.01l6.87,-1.19l2.99,3.14l3.53,0.88l3.94,3.39l6.69,-2.64h1.61L600.59,207.63z"
        />
        <path
          id="DO-10"
          title="Independencia"
          className="land"
          d="M44.47,282.42L53.18,282.47L72.03,286.4L84.34,286.09L88.57,288.03L100.88,290.4L100.88,290.4L100.88,290.4L100.88,290.4L100.88,290.4L100.88,290.4L94.34,293.04L85.87,294.53L85.87,301.04L80.1,314.4L80.86,322.45L82.79,325.52L107.02,337.52L115.86,339.1L122.02,342.19L132.4,350.67L134.32,350.68L147.02,342.33L151.25,342.36L154.33,346.58L157.79,348.9L160.48,358.09L163.55,361.55L172.78,360.84L172.78,360.84L172.01,363.89L166.63,370.74L157.39,373.37L143.54,371.38L137.39,366.76L131.62,374.37L131.62,375.9L137.39,387.01L140.46,397.35L133.92,397.69L130.84,396.15L127.77,396.89L125.84,399.56L125.2,406.41L125.2,406.41L109.49,392.29L101.1,391.5L91.61,387.14L82.53,385.64L71.99,377.48L69.3,374.02L61.18,371.26L61.18,371.26L67.96,359.86L46.26,351.23L44.4,348.08L38.74,346.86L35.69,339.95L22.51,333.66L20.48,327.36L27.73,326.03L27.56,323.14L11.5,306.07L8.87,300.2L7.1,300.2L4.81,297.05L1.77,297.49L0,295.83L5.65,293.27L9.1,288.62L17.38,293.43L20.58,290.26L22.44,290.26L26.92,292.97L27.68,295.07L29.12,294.9L30.12,292.79L33.75,293.22L35.44,294.71L39.07,293.65L41.69,294.26L43.54,293.2L42.86,287.32z"
        />
        <path
          id="DO-11"
          title="La Altagracia"
          className="land"
          d="M696.2,392.28l13.03,6.06l4.18,0.13l-0.36,-1.19l3.35,0.56l1.14,2l3.88,1.44l10.82,-0.56l5.44,5.19l1.02,5.25l-1.79,1.13l-2.27,-1.94l-11.95,-2.62l-9.56,3.63l-14.52,-5.06l0.12,-2l-6.15,-6.25l0.36,-3.63L696.2,392.28zM691.06,216.23l9.74,4.27l10.16,6.97l10.46,10.3l6.28,8.66l2.87,1.57l1.02,2.13l3.41,2.01l8.37,8.79l2.81,1.95l1.73,-1.57l1.5,0.88l0.54,1.76l6.99,6.21l4.36,1.38l5.98,7.65l4.12,2.19l4.6,5.58l2.03,1.32l1.79,-1.13l7.65,5.14l4,5.45l-0.78,1.32l1.32,0.82l-0.36,2.76l-1.37,0.13l1.2,1.69l-11.77,20.36l-2.63,0.69l-1.97,7.14l-4.6,3.01l-4.6,5.39l-1.37,14.59l-3.11,3.82l-5.32,1.69l-14.7,-4.69l-7.89,-5.2l-3.64,1.75l-0.9,3.44l2.75,3.44l0.3,2.32l-3.05,6.76l-2.51,2.57l-3.11,7.94l-0.18,10.38l-3.88,-1.75l-6.21,1.75l-6.39,-0.87l-4.06,2.31l1.73,-3.06l-3.11,-1.31l-0.9,1.13l1.41,2.25l-2.66,1.19l0.78,-1.88l-0.84,-4.94L686.28,361.38l-4.84,-3.57l-1.55,-5.01l-12.66,-5.94l0,0l2.17,-3.89l0.39,-8.03l2.31,-1.14l-0.38,-1.53l6.93,-12.21l-0.76,-8.81l-5.77,-3.86l-4.23,-6.92l-1.92,-0.78l-0.38,-5.36l-2.69,-1.55l0,-2.3l-2.31,-1.54l-0.38,-1.92l0,0l-1.92,-4.61l1.54,-1.52l0.39,-6.13l6.15,-1.11l5.39,0.8l3.47,-6.5l9.62,-8l0.39,-9.97l-7.3,-16.53l4.23,-2.66l0.39,-6.14l3.08,-0.37l1.15,-2.3l1.92,0.78l0.39,-3.45l1.92,0.78v-3.87l0,0H691.06z"
        />
        <path
          id="DO-07"
          title="La Estrelleta"
          className="land"
          d="M110.94,143.83L119.78,146.58L122.47,150.44L122.86,155.05L130.93,161.25L133.24,161.26L145.55,168.25L145.55,168.25L144.01,169.78L142.85,180.15L143.62,182.84L142.46,184.37L139.77,185.89L135.15,185.87L132.07,188.54L130.54,188.53L130.15,186.99L127.46,186.98L123.62,183.11L116.31,181.92L114,182.67L110.15,188.42L110.53,193.03L104.76,204.9L104.37,209.89L101.29,211.02L92.06,209.43L81.67,221.27L82.44,223.2L83.98,223.59L83.59,227.42L80.13,233.16L79.74,237.76L83.59,235.86L83.2,243.92L84.35,248.14L80.89,253.49L83.97,255.43L85.5,258.89L90.12,260.44L93.2,263.15L93.58,265.06L90.89,269.65L94.34,275.42L102.42,277.38L103.96,282.75L100.88,290.4L100.88,290.4L88.57,288.03L84.34,286.09L72.03,286.4L53.18,282.47L44.47,282.42L44.47,282.42L45.55,280.3L48.16,280.29L48.58,277.13L58.45,273.34L61.71,254.81L61.01,238.82L58.39,238.12L57.96,235.23L51.71,231.64L48.82,220.92L46.8,220.04L46.04,221.28L38.94,217.77L37.68,219.01L36.92,217.34L33.04,220.95L31.6,219.02L31.61,222.62L28.57,222.19L27.56,223.86L26.37,219.91L28.57,220.26L28.39,217.09L30.42,216.3L30.25,214.8L32.86,215.68L33.45,212.25L35.05,212.68L37.49,209.34L38.68,212.06L43.4,211L45.01,209.5L45.18,211.43L47.46,212.04L46.78,208.88L50.07,207.55L48.46,206.5L51.07,203.33L53.1,204.38L52.5,202.71L55.54,199.8L57.56,200.86L58.15,199.09L62.79,196.8L61.78,195.92L62.79,194.25L64.22,195.3L65.99,193.63L63.63,192.75L63.79,191.34L66.83,190.63L64.8,187.91L65.22,186.85L66.82,185.8L69.69,187.29L70.7,185.79L70.03,183.94L74.08,184.72L76.1,183.49L77.95,179.7L76.09,178.39L79.13,177.15L77.52,175.65L78.54,174.77L77.77,172.31L80.56,172.04L77.52,170.37L81.56,169.31L81.99,168.25L79.37,166.76L79.53,164.65L82.99,163.59L80.16,159.04L80.16,159.04L85.16,154.07L88.62,154.09L89.39,148.33L91.32,146.42L95.55,148.75L98.63,148L105.16,150.72z"
        />
        <path
          id="DO-12"
          title="La Romana"
          className="land"
          d="M643.79,351.93l3.17,3.69l0.66,5.01l-6.87,-3.19l1.73,-5.32L643.79,351.93zM626.33,318.24l4.62,-1.5l4.23,-4.19l0.77,-2.68l6.54,-4.94l3.46,-4.58l6.93,-14.52l7.31,1.19l0,0l0.38,1.92l2.31,1.55l0,2.3l2.69,1.55l0.38,5.36l1.93,0.78l4.23,6.92l5.77,3.86l0.77,8.81l-6.93,12.21l0.38,1.53l-2.31,1.14l-0.39,8.03l-2.17,3.89l0,0l-8.38,-2.07l-1.31,0.81l-2.39,-1.94l-3.11,1.31l-1.25,1.88l-22.54,1.75l0,0l-1.92,-9.71l-6.15,-8.83l1.54,-4.97l3.46,-3.43L626.33,318.24z"
        />
        <path
          id="DO-13"
          title="La Vega"
          className="land"
          d="M299.04,127.97L301.35,131.45L311.74,132.66L315.2,132.3L319.05,128.09L322.51,134.65L323.27,142.73L328.28,142.75L330.58,139.31L330.58,139.31L332.5,146.62L335.58,149.33L339.04,157.81L341.73,161.29L352.89,160.58L352.89,160.58L355.96,166.37L361.73,172.16L367.5,175.27L369.04,178.35L370.57,178.36L370.57,178.36L371.73,178.75L371.34,179.9L368.65,179.12L367.5,182.57L365.19,183.71L357.88,183.28L354.42,187.87L349.8,189.38L346.72,195.13L346.72,195.13L346.72,190.13L337.88,186.24L334.8,188.15L334.41,191.6L330.56,194.65L327.49,193.1L321.71,193.83L317.1,198.03L320.56,211.87L320.55,217.63L314.78,220.29L314.01,222.97L308.62,228.69L302.47,232.11L299.77,237.85L300.16,240.15L307.85,246.72L310.15,252.48L316.31,259.42L316.31,259.42L316.31,259.42L316.31,259.42L315.15,262.1L316.3,267.47L312.07,269.75L310.53,272.42L310.91,275.87L306.68,275.85L302.45,278.89L302.45,283.49L296.68,284.22L291.68,282.66L280.52,284.51L280.52,284.51L282.81,281.92L283.6,276.98L283.6,270.41L282.29,266.43L277.46,262.32L271.68,260.7L267.45,261.06L261.66,252.59L255.27,248.2L254,246.1L254.38,242.96L250.92,238.73L250.54,229.13L253.23,228L253.23,226.08L249.39,222.22L249,218.76L250.55,215.7L245.54,210.68L239.39,214.1L239.39,214.1L236.31,207.18L234.01,205.63L234.01,205.63L235.55,202.18L233.63,196.79L234.01,194.49L239.4,194.9L243.63,189.94L247.09,190.34L250.17,188.05L253.63,187.3L256.33,181.56L264.02,180.45L272.48,175.5L273.26,170.51L276.34,165.53L278.64,169L282.87,170.56L284.8,169.81L286.34,167.89L284.8,166.73L285.18,164.04L283.65,162.88L285.19,160.2L284.42,157.89L285.57,156.36L284.42,154.04L285.57,152.13L270.57,145.51L269.8,144.35L272.88,144.37L272.88,143.21L270.57,142.04L269.81,138.96L272.88,138.98L273.65,137.45L277.12,135.93L282.5,136.34L284.04,132.51L290.97,130.62L295.2,127.95z"
        />
        <path
          id="DO-14"
          title="María Trinidad Sánchez"
          className="land"
          d="M433.49,56.51L442.63,58.41L446.21,63.45L448.13,62.89L449.56,64.59L452.67,65.22L456.2,74.3L451.65,87.61L455.06,93.6L459.42,96.18L457.75,108.4L458.53,111.62L464.14,122.26L464.14,125.47L470.84,132.15L474.66,139.07L479.82,143.98L479.82,143.98L480.22,147.07L483.68,150.17L478.68,148.99L480.6,151.31L480.22,152.46L477.14,150.52L475.6,150.89L478.29,157.83L476.75,160.51L474.06,159.73L473.67,162.8L476.75,164.74L477.13,166.66L477.13,166.66L465.21,165.06L457.13,159.25L444.06,155.71L435.98,152.21L425.98,150.23L423.67,147.91L424.06,140.99L422.52,139.82L408.29,138.21L402.91,125.48L401.37,124.32L402.53,116.63L399.45,106.6L397.53,105.44L394.84,105.81L394.07,103.88L386.76,101.14L384.84,98.82L384.84,98.82L386.76,93.06L386,87.27L387.15,86.51L394.46,87.71L408.69,80.47L410.62,76.24L411.02,68.55L411.02,68.55L418.78,58.91L426.43,57.08L432.11,57.71z"
        />
        <path
          id="DO-28"
          title="Monseñor Nouel"
          className="land"
          d="M316.31,259.42L310.15,252.48L307.85,246.72L300.16,240.15L299.77,237.85L302.47,232.11L308.62,228.69L314.01,222.97L314.78,220.29L320.55,217.63L320.56,211.87L317.1,198.03L321.71,193.83L327.49,193.1L330.56,194.65L334.41,191.6L334.8,188.15L337.88,186.24L346.72,190.13L346.72,195.13L346.72,195.13L347.49,198.59L351.72,203.22L351.33,212.43L359.02,215.16L359.79,219.77L378.96,235.77L381.98,240.28L382.96,244.32L382.96,244.32L377.86,247.88L377.09,256.31L377.09,256.31L370.54,259.34L365.93,263.53L367.08,269.67L362.85,275.4L359.38,277.29L355.82,274.73L355.82,274.73L345.15,268.4L332.84,271.01L329,267.16L324,265.21L320.92,260.98z"
        />
        <path
          id="DO-15"
          title="Monte Cristi"
          className="land"
          d="M100.31,4.57l7.95,1.75l2.57,-1.01l23.73,11.69l12.91,2.4l3.94,-0.44l5.86,3.66l4.9,0.89l6.02,-1.7l0,0l1.66,1.79l-0.77,3.48l1.54,6.95l16.56,7.05l0,0l-5.02,16.14l1.92,6.18v6.18l-1.55,-1.17l-1.14,1.17l-3.47,-0.81l-0.77,2.31l2.69,6.16l0.38,5.03l-0.77,3.46l-3.08,4.22l0,0l-6.15,-2.35l-1.92,3.84l-2.31,0.37l-3.08,-2.71l-4.61,-0.01l-4.62,-4.67l-2.69,2.68l-2.31,-0.4l-6.92,4.58l-5.38,-1.19l-1.92,1.92v-1.93l-5,-3.88l0.39,-1.92l-1.92,0.37l0.4,3.06l-1.17,0.4l0,0l-6.54,-2.73l-2.69,-3.48l-9.23,-4.67l-1.92,-6.18l-5.38,-0.42l-3.85,2.29l-18.46,0.67l-12.31,-5.08l-3.85,0.36l-4.98,-3.86l0,0l0.15,-4.44l-4.61,-3.35l0.59,-2.12l-1.61,-1.5l0.18,-1.26l5.46,-1.2l1.68,-5.05l-1.21,-2.84l-1.19,0.76l-3.47,-6.76l1.26,-0.63l-0.54,-3.28l-1.43,-0.06l-1.61,2.97l-2.28,0.94l0.25,2.28l-1.27,-0.64l0.42,-4.29l6.64,-5.87l0.48,-1.7l5.74,-1.07l5.44,-2.78l0.3,-2.08l3.11,-0.76l3.47,-2.9l1.55,-3.62l-1.55,-5.48l3.17,-0.51l1.67,3.23l1.79,0.88l2.56,-1.77l0.55,-2.91l3.23,0.06l4.17,-2.22l3.96,0.89L100.31,4.57z"
        />
        <path
          id="DO-29"
          title="Monte Plata"
          className="land"
          d="M483.66,191.67L490.97,195.94L497.89,203.66L507.12,207.17L509.05,209.48L509.05,209.48L510.97,212.56L517.12,214.52L520.97,217.99L526.35,220.33L529.04,223.41L545.19,232.33L554.81,236.98L560.97,235.87L564.81,237.04L563.27,242.02L560.19,242.39L558.65,246.21L554.42,247.34L550.96,251.92L549.42,252.3L549.03,254.98L547.49,256.12L547.49,256.12L547.11,254.58L544.03,253.42L539.03,259.14L531.72,270.21L527.86,282.84L522.49,290.97L522.49,290.97L513.43,287.71L509.57,287.71L508.02,290.96L500.81,293.39L496.65,293.06L492.09,294.51L489.78,293.73L485.16,297.15L483.24,303.27L476.31,308.21L475.16,305.14L475.54,301.7L473.62,297.47L469.77,297.45L469.01,296.3L469.39,289.79L467.09,289.01L465.16,290.15L459.4,278.62L454.01,278.98L448.24,281.62L439.39,278.13L439.77,282.34L438.24,284.25L435.54,284.62L433.24,287.67L426.31,286.86L422.46,289.14L417.46,284.52L407.46,284.46L399.39,271.39L397.08,270.99L395.16,272.13L395.16,272.13L389.78,266.35L384.77,265.55L377.09,256.31L377.09,256.31L377.86,247.88L382.96,244.32L382.96,244.32L387.86,241.03L402.48,241.88L405.56,236.53L409.79,236.55L414.02,233.89L415.56,233.9L417.1,236.21L421.33,236.62L424.79,231.27L433.25,236.68L437.1,237.09L442.87,229.06L446.34,220.64L446.73,214.89L445.19,213.73L444.42,210.27L448.27,202.61L448.27,202.61L455.58,203.04L457.12,201.89L457.12,191.52L461.74,193.09L465.97,191.19L472.51,192L477.12,190.1z"
        />
        <path
          id="DO-16"
          title="Pedernales"
          className="land"
          d="M77.84,553.59l1.37,1.99l-2.39,-0.5l-0.24,-1.12L77.84,553.59zM103.96,522.63l5.14,2.56l2.93,5.23h2.99l-1.55,2.87l-4.66,3.99l-10.16,2.74l-1.79,-2.86l3.41,-9.28l2.15,-1.31L103.96,522.63zM61.18,371.26l8.12,2.76l2.69,3.46l10.54,8.17l9.08,1.5l9.49,4.36l8.39,0.8l15.71,14.12l0,0l4.63,0.51l5.85,4.26l-1.27,2.93l-4.58,0.27l3.56,5.85l0,0v6.91l0,0l-4.07,5.32l-2.8,0.8l-2.54,-1.33l-0.76,9.83l1.53,1.86h4.58l2.54,1.86l1.02,3.3l1.79,-0.3l2.31,1.54l4.04,5.03l3.56,-1.06l1.27,-4.25h2.8l5.09,6.38l0.26,3.25l1.45,1.06l0.77,4.96l-1.15,1.52l0.31,2.53l0,0l-1.27,1.44l0.06,4.81l-2.09,1.69l-1.08,-1.06l-1.91,0.94l-3.88,5.24l-4.24,8.23l-5.26,19.39l-3.23,3.18l-3.83,9.6h-1.55l-2.03,3.12l-4.18,-4.49l-0.6,-3.24l-5.98,-5.55l-5.38,-14.34l-7.71,-6.92l-5.02,-1.93l-9.92,-0.44l-12.85,2.62l-5.74,-2.81l6.16,-3.56l1.55,-5.93l3.82,0.44l1.68,-5.93l-3.82,-5.93l-1.85,-5.68l-4.36,-3.74l3.05,-1.44l1.37,-3.99l-3.76,-9.99l-5.86,-5.25l-4.48,-2.31l-3.71,-4.25l-3.51,-1.12l0.96,-2.78l2.36,-1.67l0.42,-3.76l1.85,-2.98l-1.27,-2.27l1.01,-1.49l-2.03,-0.78l-1.18,-2.8l1.6,-4.38l-1.6,-1.48l-3.89,-5.42l0.67,-1.49l-2.36,0.62l-2.37,-2.71l1.26,-3.59l2.02,-1.05l0.58,-4.99l2.44,-1.49l0,-2.71L61.18,371.26z"
        />
        <path
          id="DO-17"
          title="Peravia"
          className="land"
          d="M354.42,323.6l4.92,0.19l2.95,1.55l0.54,1.76l3.08,1.55v1.53l2.69,0.78l0.77,4.6l5,-0.35l0,4.21l1.54,0.39l-0.77,1.53l1.54,0.01l1.54,-2.67l0,2.68l2.31,-0.75l3.08,1.55l-1.92,1.9l-1.15,-1.92l0,4.21l-4.23,3.04l-5,-0.41l-1.92,3.43l2.69,-0.75l1.15,5.74l1.92,-1.52l3.08,0.78l1.15,6.89l5.77,5l1.92,5.36l4.62,1.94l-1.16,7.26l1.92,1.57l0,0l-2.08,1l-4.24,-1.69l-6.04,3.06l-8.73,-3.56l-11.77,2.69l-12.97,-2.56l-24.5,9.76l-4.54,-3l-8.01,-0.19l1.43,-3.13l1.73,-0.69v1.31l-0.24,-0.94l-1.31,0.5l-0.72,2.06l1.2,0.56l0.96,-1.94l2.45,1.69l0.9,-1.19l2.45,0.81l4.6,-1.94l-3.47,-2.06h-3.11l-3.7,-4.69l-5.38,-1.94l-1.79,-2.56l0,0l1.11,-1.05l8.46,-0.33l8.85,-2.63l2.31,-5.34l-1.15,-5.36l0.39,-5.73l4.24,-5.71v-3.06l-1.68,-2.83l0,0l0.56,-4.47l1.47,-2.06l4.18,-1.03l7.13,-4.12l6.88,-1.29L354.42,323.6z"
        />
        <path
          id="DO-18"
          title="Puerto Plata"
          className="land"
          d="M213.2,0L218.16,0.38L227.84,5.94L230.59,3.6L236.51,6.51L240.51,6.19L242.96,6.13L245.53,6.95L248.52,5.5L250.61,6.07L251.39,9.73L253.78,11.37L256.53,11.31L263.7,20.53L262.33,22.55L262.92,23.5L265.08,21.86L270.57,21.48L274.16,24.13L274.22,25.4L278.16,26.72L279.96,28.62L279.18,29.31L279.96,30.38L282.17,29.18L289.1,33.6L291.13,36.26L293.58,36.26L295.25,38.53L297.77,36.95L304.7,40.36L311.27,38.15L313.42,40.42L318.74,40.42L319.82,40.11L319.28,38.4L321.19,34.68L326.99,33.48L334.7,34.05L342.35,40.99L345.04,41.05L351.01,49.38L351.01,49.38L348.7,54.69L350.23,60.1L344.85,61.61L346.38,63.93L344.85,64.31L343.31,62.37L342.92,64.3L341.39,62.75L340.23,64.67L338.69,64.66L336.39,61.56L334.85,62.71L331.38,62.3L331,65L330.23,63.84L327.54,63.82L326.38,64.97L323.3,63.42L321.38,65.33L321.76,67.26L319.46,68.02L320.22,71.49L318.68,74.18L319.84,79.19L316.37,83.41L315.6,92.27L313.29,94.18L313.29,94.18L302.91,89.5L294.45,87.91L292.91,83.67L295.22,75.59L294.45,72.12L278.68,63.93L277.91,65.86L279.83,73.96L279.06,76.27L277.52,78.19L271.75,80.47L269.83,79.68L268.29,76.21L264.83,75.8L256.37,68.05L254.83,69.58L245.22,66.06L245.22,66.06L236.75,65.62L233.29,63.29L231.75,64.05L228.29,60.56L227.91,58.64L221.75,55.9L217.14,51.25L209.06,52.36L204.45,50.41L202.91,45.77L192.91,43.79L187.16,41.07L187.16,41.07L170.6,34.02L169.06,27.06L169.84,23.58L168.18,21.8L168.18,21.8L170.11,20.66L171.13,16.62L170.41,14.15L175.13,17.63L177.88,18.25L179.68,15.92L187.68,17.25L189.42,15.61L196.59,13.46L198.8,11.31L198.44,7.46L202.14,4.99L202.74,3.22z"
        />
        <path
          id="DO-19"
          title="Hermanas Mirabal"
          className="land"
          d="M330.58,139.31L334.82,123.17L337.52,117.42L337.52,114.72L334.44,110.09L334.44,107.01L336.37,103.94L335.99,98.54L340.99,87.02L345.6,90.9L349.83,91.69L350.99,97.09L354.45,99.42L356.37,98.66L359.84,89.82L362.15,89.06L364.07,86.38L370.99,84.88L374.84,86.82L374.84,92.22L380.22,98.41L380.22,98.41L382.15,100.35L381.76,103.42L374.83,106.46L373.68,108.77L374.45,110.7L377.53,111.87L377.53,113.02L367.14,117.58L360.98,122.17L357.51,135.61L357.9,138.31L355.97,142.53L355.97,146.37L350.97,152.88L352.89,160.58L352.89,160.58L341.73,161.29L339.04,157.81L335.58,149.33L332.5,146.62z"
        />
        <path
          id="DO-20"
          title="Samaná"
          className="land"
          d="M595.92,129.13l1.73,1.39l2.09,-0.69l0.48,1.39l-7.77,11.33l-0.36,3.08l4.84,1.2l2.57,-0.31l2.45,-2.45l3.94,1.01l3.88,-2.58l4.18,1.13l-0.18,3.34l-2.45,4.59l-9.92,7.24l-2.63,11.26l-2.39,0.88l-9.98,-1.07l-3.34,-2.45l-1.02,1.07l-2.21,-1.57l-1.14,0.88l-3.88,-2.14l-1.91,2.26l-13.21,-0.5l-1.02,2.52l-1.79,-1.95l-3.59,0.57l-2.15,-2.26l-6.39,-1.7l-5.32,0.19l-1.61,-1.45l-1.85,1.2l-2.75,-1.32l-6.27,0.44l-14.04,-3.08l-1.55,1.07l0.18,9.25l-2.99,10.06l1.85,7.17l-1.13,1.26l1.73,3.14l3.11,0.57l0,0l-5.06,16.43l0,0l-1.92,-2.31l-9.23,-3.51l-6.92,-7.72l-7.31,-4.27l0,0l1.15,-1.15l5,0.03l-1.54,-4.62l-2.31,-2.7v-2.31l2.31,0.01l3.46,-2.29l0,-2.31l1.92,-0.37l0.77,-1.53l2.31,0.78l0.39,-1.53l1.54,0.01l1.16,-4.61l1.93,-2.29l1.54,0.01l0.39,-1.54l-6.54,-0.04l-1.92,-2.32l-3.46,0.75l-10.77,-1.98l-3.85,4.97l0,0l-0.38,-1.92l-3.08,-1.94l0.39,-3.07l2.69,0.78l1.54,-2.68l-2.69,-6.94l1.54,-0.38l3.08,1.94l0.39,-1.15l-1.92,-2.32l5,1.18l-3.46,-3.1l-0.4,-3.09l0,0l6.79,3.78l6.87,1.32l8.37,-1.95l3.59,-2.2l13.98,-0.94l2.93,-1.64l0.12,-2.33l2.45,0.31v-1.51l1.67,0.44l2.03,-1.89l1.73,1.2l2.21,-1.13l8.78,1.01l5.38,-1.13l2.69,1.57l2.33,-1.2l2.81,2.77l3.05,-2.01l4.48,3.21l2.51,-0.69l1.79,1.83l3.71,0.06l1.25,2.64l4.01,1.83l1.79,-5.48l7.05,-5.22l4.78,-0.82l-0.36,-1.32l1.61,-1.38l2.03,1.01l2.57,-4.91L595.92,129.13z"
        />
        <path
          id="DO-21"
          title="San Cristóbal"
          className="land"
          d="M377.09,256.31L384.77,265.55L389.78,266.35L395.16,272.13L395.16,272.13L393.23,274.42L393.23,276.72L396.69,281.72L397.46,289.38L401.3,295.15L400.92,296.68L399,295.14L397.46,295.51L396.68,301.64L398.61,303.18L401.3,310.47L402.84,310.86L404.37,318.91L405.14,320.44L407.45,320.84L410.53,320.47L410.53,318.94L411.68,318.57L415.53,322.8L417.45,327.79L413.6,329.29L413.99,330.44L417.06,330.46L416.68,334.67L419.37,331.62L421.29,331.63L422.83,333.94L424.75,334.33L427.83,330.91L427.45,335.11L428.6,334.74L428.98,337.03L430.52,338.19L429.47,341.6L429.47,341.6L427.75,343.23L426.79,342.66L427.09,344.1L422.73,351.05L419.38,353.93L412.63,367.14L407.49,370.2L407.07,372.64L402.11,377.84L398.7,384.53L397.03,385.47L394.76,383.72L392.42,384.65L392.42,384.65L390.5,383.08L391.66,375.83L387.04,373.89L385.12,368.53L379.35,363.53L378.2,356.64L375.12,355.86L373.2,357.37L372.05,351.63L369.35,352.38L371.28,348.95L376.28,349.36L380.51,346.32L380.52,342.12L381.67,344.04L383.59,342.13L380.52,340.59L378.21,341.34L378.21,338.66L376.67,341.33L375.13,341.32L375.9,339.8L374.36,339.4L374.36,335.2L369.36,335.55L368.59,330.96L365.9,330.17L365.9,328.64L362.83,327.1L362.28,325.34L359.33,323.79L354.42,323.6L354.42,323.6L353.19,319.67L349.26,315.29L352.45,302.92L352.45,297.25L349.75,292.87L349.99,289.52L352.94,284.36L353.19,279.2L355.82,274.73L355.82,274.73L359.38,277.29L362.85,275.4L367.08,269.67L365.93,263.53L370.54,259.34z"
        />
        <path
          id="DO-31"
          title="San José de Ocoa"
          className="land"
          d="M316.31,259.42L320.92,260.98L324,265.21L329,267.16L332.84,271.01L345.15,268.4L355.82,274.73L355.82,274.73L353.19,279.2L352.94,284.36L349.99,289.52L349.75,292.87L352.45,297.25L352.45,302.92L349.26,315.29L353.19,319.67L354.42,323.6L354.42,323.6L348.27,331L341.39,332.29L334.27,336.41L330.09,337.44L328.61,339.5L328.05,343.97L328.05,343.97L324.35,341.42L324.74,338.74L322.82,335.67L322.82,333.38L320.89,332.22L320.9,329.92L322.05,329.16L320.9,327.24L315.51,326.45L312.44,322.22L305.13,320.26L303.98,317.58L298.97,318.32L295.51,310.64L286.67,304.46L285.9,302.93L288.6,298.73L288.21,292.22L285.91,288.37L280.52,284.51L280.52,284.51L291.68,282.66L296.68,284.22L302.45,283.49L302.45,278.89L306.68,275.85L310.91,275.87L310.53,272.42L312.07,269.75L316.3,267.47L315.15,262.1z"
        />
        <path
          id="DO-22"
          title="San Juan"
          className="land"
          d="M145.55,168.25L148.62,172.5L154.78,171.38L160.16,173.33L163.24,172.19L166.32,172.98L166.32,172.98L170.16,176.08L175.93,176.88L182.47,179.6L184.78,183.07L188.62,185.02L189.39,193.47L195.16,198.11L204.77,201.24L207.08,206.63L210.54,207.41L217.47,202.08L220.54,203.25L224.01,202.11L234.01,205.63L234.01,205.63L236.31,207.18L239.39,214.1L239.39,214.1L239.77,217.94L238.62,219.47L232.08,220.59L234,225.2L232.46,227.5L227.07,228.62L225.54,231.29L221.3,232.8L221.69,237.03L219.38,241.62L217.07,243.14L221.29,254.28L221.29,256.97L214.75,268.81L213.21,268.81L210.52,261.89L208.98,260.73L206.29,260.71L202.83,264.15L203.21,270.28L205.52,274.89L202.05,276.79L202.05,278.71L204.36,281.02L204.74,283.7L200.63,286.24L196.79,286.34L194.24,292.66L191.98,292.66L186.76,298.08L181.27,298.26L181.27,298.26L180.12,295.06L175.12,293.5L161.27,298.4L148.19,297.18L145.5,300.22L143.57,300.21L139.34,303.25L131.65,301.68L131.26,299.38L128.57,298.6L125.5,300.11L124.34,297.81L118.57,294.71L110.88,295.43L106.65,294.26L100.88,290.4L100.88,290.4L100.88,290.4L100.88,290.4L103.96,282.75L102.42,277.38L94.34,275.42L90.89,269.65L93.58,265.06L93.2,263.15L90.12,260.44L85.5,258.89L83.97,255.43L80.89,253.49L84.35,248.14L83.2,243.92L83.59,235.86L79.74,237.76L80.13,233.16L83.59,227.42L83.98,223.59L82.44,223.2L81.67,221.27L92.06,209.43L101.29,211.02L104.37,209.89L104.76,204.9L110.53,193.03L110.15,188.42L114,182.67L116.31,181.92L123.62,183.11L127.46,186.98L130.15,186.99L130.54,188.53L132.07,188.54L135.15,185.87L139.77,185.89L142.46,184.37L143.62,182.84L142.85,180.15L144.01,169.78z"
        />
        <path
          id="DO-23"
          title="San Pedro de Macorís"
          className="land"
          d="M547.49,256.12L545.95,263.4L546.72,265.32L550.18,266.87L552.49,273.4L552.48,279.53L554.02,281.83L551.33,285.27L551.71,286.8L554.02,287.96L557.48,293.73L559.4,300.25L562.86,302.57L565.94,312.54L570.16,319.46L571.7,316.02L578.63,310.7L579.02,308.4L581.32,308.42L580.56,303.05L582.48,296.94L587.48,296.58L591.72,292.77L591.72,292.77L594.79,293.94L595.95,296.63L600.56,295.51L604.02,296.67L610.18,300.92L612.1,305.53L617.87,308.62L622.48,306.74L628.64,306.77L629.41,308.31L627.48,309.06L626.33,318.24L626.33,318.24L625.17,321.68L621.71,325.11L620.17,330.07L626.32,338.91L628.24,348.61L628.24,348.61L606.74,342.41L603.63,340.03L604.71,339.53L603.81,336.34L601.48,335.09L595.8,337.34L588.69,337.47L582.59,339.41L579.13,337.97L579.43,336.59L578.41,336.34L577.15,337.22L578.11,338.97L575.06,341.35L556.06,341.16L536.88,346.05L532.48,345.98L532.48,345.98L532.46,336.09L534.39,335.33L534.77,331.51L524.77,330.68L518.23,326.82L516.31,321.83L513.62,320.29L516.7,317.63L522.09,306.94L520.16,303.48L521.71,297.36L519.01,298.5L518.24,296.19L522.49,290.97L522.49,290.97L527.86,282.84L531.72,270.21L539.03,259.14L544.03,253.42L547.11,254.58z"
        />
        <path
          id="DO-24"
          title="Sánchez Ramírez"
          className="land"
          d="M370.57,178.36L369.81,176.82L370.96,176.06L377.12,175.71L377.89,177.63L380.19,176.88L381.35,178.8L385.19,179.98L386.35,177.3L387.5,178.84L391.73,175.02L393.27,175.41L392.5,178.1L396.73,177.35L396.73,180.04L398.66,176.6L400.96,175.84L400.96,175.84L400.96,178.91L400.96,178.91L402.12,179.31L404.43,176.63L407.12,177.03L408.27,178.57L412.12,176.67L413.27,178.98L415.97,179L419.81,182.48L428.66,184.83L431.35,186.77L435.2,184.1L437.12,184.11L444.43,188.76L450.97,190.34L448.27,202.61L448.27,202.61L444.42,210.27L445.19,213.73L446.73,214.89L446.34,220.64L442.87,229.06L437.1,237.09L433.25,236.68L424.79,231.27L421.33,236.62L417.1,236.21L415.56,233.9L414.02,233.89L409.79,236.55L405.56,236.53L402.48,241.88L387.86,241.03L382.96,244.32L382.96,244.32L381.98,240.28L378.96,235.77L359.79,219.77L359.02,215.16L351.33,212.43L351.72,203.22L347.49,198.59L346.72,195.13L346.72,195.13L349.8,189.38L354.42,187.87L357.88,183.28L365.19,183.71L367.5,182.57L368.65,179.12L371.34,179.9L371.73,178.75z"
        />
        <path
          id="DO-25"
          title="Santiago"
          className="land"
          d="M245.22,66.06L254.83,69.58L256.37,68.05L264.83,75.8L268.29,76.21L269.83,79.68L271.75,80.47L277.52,78.19L279.06,76.27L279.83,73.96L277.91,65.86L278.68,63.93L294.45,72.12L295.22,75.59L292.91,83.67L294.45,87.91L302.91,89.5L313.29,94.18L313.29,94.18L313.29,98.8L305.21,104.15L305.59,109.92L302.9,116.07L305.59,125.7L301.36,124.52L299.04,127.97L299.04,127.97L295.2,127.95L290.97,130.62L284.04,132.51L282.5,136.34L277.12,135.93L273.65,137.45L272.88,138.98L269.81,138.96L270.57,142.04L272.88,143.21L272.88,144.37L269.8,144.35L270.57,145.51L285.57,152.13L284.42,154.04L285.57,156.36L284.42,157.89L285.19,160.2L283.65,162.88L285.18,164.04L284.8,166.73L286.34,167.89L284.8,169.81L282.87,170.56L278.64,169L276.34,165.53L273.26,170.51L272.48,175.5L264.02,180.45L256.33,181.56L253.63,187.3L250.17,188.05L247.09,190.34L243.63,189.94L239.4,194.9L234.01,194.49L233.63,196.79L235.55,202.18L234.01,205.63L234.01,205.63L224.01,202.11L220.54,203.25L217.47,202.08L210.54,207.41L207.08,206.63L204.77,201.24L195.16,198.11L189.39,193.47L188.62,185.02L184.78,183.07L182.47,179.6L175.93,176.88L170.16,176.08L166.32,172.98L166.32,172.98L167.86,161.07L165.94,156.84L163.25,156.05L161.71,152.58L162.48,151.05L164.79,150.68L164.02,145.67L165.18,141.06L162.87,137.21L164.79,136.83L165.95,133.76L168.64,134.93L168.26,129.93L173.64,125.73L179.8,124.22L181.34,127.31L184.03,127.32L184.42,124.63L187.49,124.26L187.49,123.11L190.57,123.51L192.11,119.29L195.19,117.77L199.03,118.17L200.96,115.87L197.5,114.7L198.27,112.01L195.96,109.3L198.27,108.55L199.81,105.48L199.81,105.48L211.73,111.7L219.04,111.36L222.5,107.14L224.81,110.23L230.2,111.8L233.27,110.28L241.35,110.71L243.66,107.65L238.66,105.31L236.36,101.83L239.44,89.91L235.21,88.73L234.44,86.8L233.28,87.56L230.59,85.24L231.36,82.55L229.44,80.61L229.44,77.91L239.05,83.36L244.06,74.53z"
        />
        <path
          id="DO-26"
          title="Santiago Rodríguez"
          className="land"
          d="M127.11,88.12L128.29,87.72L127.89,84.66L129.81,84.28L129.42,86.21L134.42,90.09L134.42,92.01L136.35,90.1L141.73,91.28L148.66,86.7L150.96,87.1L153.66,84.42L158.28,89.09L162.89,89.09L165.96,91.81L168.27,91.43L170.2,87.59L176.35,89.94L176.35,89.94L176.35,94.56L177.89,96.11L179.04,101.51L185.96,102.7L190.96,105.81L199.81,105.48L199.81,105.48L198.27,108.55L195.96,109.3L198.27,112.01L197.5,114.7L200.96,115.87L199.03,118.17L195.19,117.77L192.11,119.29L190.57,123.51L187.49,123.11L187.49,124.26L184.42,124.63L184.03,127.32L181.34,127.31L179.8,124.22L173.64,125.73L168.26,129.93L168.64,134.93L165.95,133.76L164.79,136.83L162.87,137.21L165.18,141.06L164.02,145.67L164.79,150.68L162.48,151.05L161.71,152.58L163.25,156.05L165.94,156.84L167.86,161.07L166.32,172.98L166.32,172.98L163.24,172.19L160.16,173.33L154.78,171.38L148.62,172.5L145.55,168.25L145.55,168.25L133.24,161.26L130.93,161.25L122.86,155.05L122.47,150.44L119.78,146.58L110.94,143.83L110.94,143.83L110.17,141.91L105.94,138.81L105.17,134.96L110.55,136.14L116.32,135.02L115.95,118.47L120.18,114.65L119.03,100.4L121.34,95.79L127.11,89.66z"
        />
        <path
          id="DO-32"
          title="Santo Domingo"
          className="land"
          d="M395.16,272.13L397.08,270.99L399.39,271.39L407.46,284.46L417.46,284.52L422.46,289.14L426.31,286.86L433.24,287.67L435.54,284.62L438.24,284.25L439.77,282.34L439.39,278.13L448.24,281.62L454.01,278.98L459.4,278.62L465.16,290.15L467.09,289.01L469.39,289.79L469.01,296.3L469.77,297.45L473.62,297.47L475.54,301.7L475.16,305.14L476.31,308.21L483.24,303.27L485.16,297.15L489.78,293.73L492.09,294.51L496.65,293.06L500.81,293.39L508.02,290.96L509.57,287.71L513.43,287.71L522.49,290.97L522.49,290.97L518.24,296.19L519.01,298.5L521.71,297.36L520.16,303.48L522.09,306.94L516.7,317.63L513.62,320.29L516.31,321.83L518.23,326.82L524.77,330.68L534.77,331.51L534.39,335.33L532.46,336.09L532.48,345.98L532.48,345.98L528.27,344.79L518.59,336.4L512.67,336.15L510.1,338.22L511.48,345.48L509.32,346.92L502.27,344.67L499.88,340.79L499.46,336.53L495.82,334.71L477.41,332.15L457.03,331.89L457.03,331.89L458.58,324.28L455.41,322.2L444.47,325.1L439.85,320.82L438.01,316.12L435.64,315.15L433.92,316.4L432.73,321.65L429.97,323.17L437.61,330.91L437.74,336.44L435.37,338.92L435.2,341.16L435.2,341.16L432.47,342.29L429.47,341.6L429.47,341.6L430.52,338.19L428.98,337.03L428.6,334.74L427.45,335.11L427.83,330.91L424.75,334.33L422.83,333.94L421.29,331.63L419.37,331.62L416.68,334.67L417.06,330.46L413.99,330.44L413.6,329.29L417.45,327.79L415.53,322.8L411.68,318.57L410.53,318.94L410.53,320.47L407.45,320.84L405.14,320.44L404.37,318.91L402.84,310.86L401.3,310.47L398.61,303.18L396.68,301.64L397.46,295.51L399,295.14L400.92,296.68L401.3,295.15L397.46,289.38L396.69,281.72L393.23,276.72L393.23,274.42z"
        />
        <path
          id="DO-27"
          title="Valverde"
          className="land"
          d="M187.16,41.07L192.91,43.79L202.91,45.77L204.45,50.41L209.06,52.36L217.14,51.25L221.75,55.9L227.91,58.64L228.29,60.56L231.75,64.05L233.29,63.29L236.75,65.62L245.22,66.06L245.22,66.06L244.06,74.53L239.05,83.36L229.44,77.91L229.44,80.61L231.36,82.55L230.59,85.24L233.28,87.56L234.44,86.8L235.21,88.73L239.44,89.91L236.36,101.83L238.66,105.31L243.66,107.65L241.35,110.71L233.27,110.28L230.2,111.8L224.81,110.23L222.5,107.14L219.04,111.36L211.73,111.7L199.81,105.48L199.81,105.48L190.96,105.81L185.96,102.7L179.04,101.51L177.89,96.11L176.35,94.56L176.35,89.94L176.35,89.94L179.43,85.72L180.2,82.26L179.82,77.23L177.13,71.07L177.9,68.76L181.37,69.57L182.51,68.4L184.06,69.57L184.06,63.38L182.14,57.21z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(DominicanRepublic))
