import amplitude from 'amplitude-js'
import { isDevEnvironment } from './_processUtil'

// Prevent sending events until live for Prod
const disableAmplitude = true

const initializeAmplitude = () => {
  // eslint-disable-next-line
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
    includeUtm: true,
    includeGclid: true,
    includeReferrer: true
  })
}

const sendAmplitudeEvent = (eventName, options = {}) => {
  if (!disableAmplitude) {
    // This logic is to prevent sending the same event twice, which already happened. This only allow an event every 2 seconds
    const isLikelyToBeSameEvent = Date.now() <= amplitude.getInstance()._lastEventTime + 2000

    if (!isDevEnvironment && !isLikelyToBeSameEvent) {
      amplitude.getInstance().logEvent(eventName, options)
    }
  }
}

export { initializeAmplitude, sendAmplitudeEvent }
