import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 807
const height = 216

const cities = {
  Banjul: { x: 62, y: 99 }
}

const Gambia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map gambia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="GM-N"
          title="North Bank"
          className="land"
          d="M406.41,87.94L390.82,76.56L388.95,52.49L354.49,48.35L349.15,64.27L72.97,63.23L74.26,90.82L88.29,109.51L83.44,125.27L118.21,131.3L184.11,99.26L282.52,96.41L339.11,79.19L393.24,98.68L406.41,87.94z"
        />
        <path
          id="GM-W"
          title="West Coast"
          className="land"
          d="M22.07,205.22L43.29,178.23L265.73,180.26L268.1,155.33L181.55,145.71L172.5,152.68L161.34,153.3L153.3,134.56L105.13,147.74L121.58,164.44L110.56,166.3L107.93,151.61L74.97,142.47L63.94,118.18L35.12,131.05L19.62,112.77L1.94,130.85L22.07,205.22z"
        />
        <path
          id="GM-L"
          title="Lower River"
          className="land"
          d="M438.33,70.6L406.94,87.94L396.18,103.01L342.47,84.27L321.98,99.2L178.92,106.31L161.47,132.16L168.84,152.45L181.55,145.71L268.1,155.33L272.66,130.51L412.99,119.48L438.33,70.6z"
        />
        <path
          id="GM-B"
          title="Banjul"
          className="land"
          d="M63.94,118.18L65.21,97.41L41.88,87.94L19.62,112.77L35.12,131.05L63.94,118.18z"
        />
        <path
          id="GM-U"
          title="Upper River"
          className="land"
          d="M604.92,135.05L653.44,158.82L697.69,160.49L777.41,137.37L798.06,120.05L792.73,93.26L759.69,66.32L659.14,99.87L628.74,82.36L604.92,135.05z"
        />
        <path
          id="GM-M"
          title="Central River"
          className="land"
          d="M466.14,0L415.59,21.03L382.01,12.93L354.49,48.35L388.95,52.49L390.82,76.56L406.41,87.94L406.94,87.94L438.33,70.6L449.9,62.4L557.4,125.28L604.92,135.05L628.74,82.36L607.44,48.55L585.18,42.37L554.93,55.46L518.3,10.46L466.14,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Gambia))
