import { createInvertedAnswerQuestionMap, makeLanguageAnswers } from '../../../lib/methodsUtil'

// IMPORTANT: Capitals MUST be ordered the same way as answerQuestionMap['en'] as the index is used to match with foreign names.

// Note: 'Antigua and Barbuda' & 'Marshall islands' not cameCase because pictures are being used for display.

const answerQuestionMap = {
  en: {
    Kabul: 'Afghanistan',
    Tirana: 'Albania',
    Algiers: 'Algeria',
    'Andorra la Vella': 'Andorra',
    Luanda: 'Angola',
    "Saint John's": 'Antigua and Barbuda',
    'Buenos Aires': 'Argentina',
    Yerevan: 'Armenia',
    Canberra: 'Australia',
    Vienna: 'Austria',
    Baku: 'Azerbaijan',
    Nassau: 'Bahamas',
    Manama: 'Bahrain',
    Dhaka: 'Bangladesh',
    Bridgetown: 'Barbados',
    Minsk: 'Belarus',
    Brussels: 'Belgium',
    Belmopan: 'Belize',
    'Porto-Novo': 'Benin',
    Thimphu: 'Bhutan',
    'La Paz': 'Bolivia',
    Sarajevo: 'BosniaHerzegovina',
    Gaborone: 'Botswana',
    Brasilia: 'Brazil',
    'Bandar Seri Begawan': 'BruneiDarussalam',
    Sofia: 'Bulgaria',
    Ouagadougou: 'BurkinaFaso',
    Gitega: 'Burundi',
    'Phnom Penh': 'Cambodia',
    Yaounde: 'Cameroon',
    Ottawa: 'Canada',
    Praia: 'CapeVerde',
    Bangui: 'CentralAfricanRepublic',
    "N'Djamena": 'Chad',
    Santiago: 'Chile',
    Beijing: 'China',
    Bogota: 'Colombia',
    Moroni: 'Comoros',
    Brazzaville: 'Congo',
    Kinshasa: 'CongoDR',
    'San Jose': 'CostaRica',
    Zagreb: 'Croatia',
    Havana: 'Cuba',
    Nicosia: 'Cyprus',
    Prague: 'CzechRepublic',
    Copenhagen: 'Denmark',
    Djibouti: 'Djibouti',
    Roseau: 'Dominica',
    'Santo Domingo': 'DominicanRepublic',
    Dili: 'TimorLeste',
    Quito: 'Ecuador',
    Cairo: 'Egypt',
    'San Salvador': 'ElSalvador',
    London: 'England',
    Malabo: 'EquatorialGuinea',
    Asmara: 'Eritrea',
    Tallinn: 'Estonia',
    'Addis Ababa': 'Ethiopia',
    Suva: 'Fiji',
    Helsinki: 'Finland',
    Paris: 'France',
    Libreville: 'Gabon',
    Banjul: 'Gambia',
    Tbilisi: 'Georgia',
    Berlin: 'Germany',
    Accra: 'Ghana',
    Athens: 'Greece',
    "Saint George's": 'Grenada',
    'Guatemala City': 'Guatemala',
    Conakry: 'Guinea',
    Bissau: 'GuineaBissau',
    Georgetown: 'Guyana',
    'Port-au-Prince': 'Haiti',
    Tegucigalpa: 'Honduras',
    Budapest: 'Hungary',
    Reykjavik: 'Iceland',
    'New Delhi': 'India',
    Jakarta: 'Indonesia',
    Tehran: 'Iran',
    Baghdad: 'Iraq',
    Dublin: 'Ireland',
    Jerusalem: 'Israel',
    Rome: 'Italy',
    Yamoussoukro: 'IvoryCoast',
    Kingston: 'Jamaica',
    Tokyo: 'Japan',
    Amman: 'Jordan',
    'Nur-Sultan': 'Kazakhstan',
    Nairobi: 'Kenya',
    Tarawa: 'Kiribati',
    Pristina: 'Kosovo',
    'Kuwait City': 'Kuwait',
    Bishkek: 'Kyrgyzstan',
    Vientiane: 'Laos',
    Riga: 'Latvia',
    Beirut: 'Lebanon',
    Maseru: 'Lesotho',
    Monrovia: 'Liberia',
    Tripoli: 'Libya',
    Vaduz: 'Liechtenstein',
    Vilnius: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Skopje: 'Macedonia',
    Antananarivo: 'Madagascar',
    Lilongwe: 'Malawi',
    'Kuala Lumpur': 'Malaysia',
    Male: 'Maldives',
    Bamako: 'Mali',
    Valletta: 'Malta',
    'Delap-Uliga-Darrit': 'Marshall Islands',
    Nouakchott: 'Mauritania',
    'Port Louis': 'Mauritius',
    'Mexico City': 'Mexico',
    Palikir: 'Micronesia',
    Chisinau: 'Moldova',
    Monaco: 'Monaco',
    Ulaanbaatar: 'Mongolia',
    Podgorica: 'Montenegro',
    Rabat: 'Morocco',
    Maputo: 'Mozambique',
    Naypyidaw: 'Myanmar',
    Windhoek: 'Namibia',
    Kathmandu: 'Nepal',
    Amsterdam: 'Netherlands',
    Wellington: 'NewZealand',
    Managua: 'Nicaragua',
    Niamey: 'Niger',
    Abuja: 'Nigeria',
    Belfast: 'Northern Ireland',
    Pyongyang: 'NorthKorea',
    Oslo: 'Norway',
    Muscat: 'Oman',
    Islamabad: 'Pakistan',
    Melekeok: 'Palau',
    'Panama City': 'Panama',
    'Port Moresby': 'PapuaNewGuinea',
    Asuncion: 'Paraguay',
    Lima: 'Peru',
    Manila: 'Philippines',
    Warsaw: 'Poland',
    Lisbon: 'Portugal',
    Doha: 'Qatar',
    Bucharest: 'Romania',
    Moscow: 'Russia',
    Kigali: 'Rwanda',
    Apia: 'Samoa',
    'San Marino': 'SanMarino',
    'Sao Tome': 'SaoTomePrincipe',
    Riyadh: 'SaudiArabia',
    Dakar: 'Senegal',
    Belgrade: 'Serbia',
    Victoria: 'Seychelles',
    Freetown: 'SierraLeone',
    Singapore: 'Singapore',
    Bratislava: 'Slovakia',
    Ljubljana: 'Slovenia',
    Honiara: 'SolomonIslands',
    Mogadishu: 'Somalia',
    Pretoria: 'SouthAfrica',
    Seoul: 'SouthKorea',
    Juba: 'SouthSudan',
    Madrid: 'Spain',
    'Sri Jayawardenepura Kotte': 'SriLanka',
    Basseterre: 'SaintKittsNevis',
    Castries: 'SaintLucia',
    Kingstown: 'SaintVincent',
    Edinburgh: 'Scotland',
    Khartoum: 'Sudan',
    Paramaribo: 'Suriname',
    Mbabane: 'Swaziland',
    Stockholm: 'Sweden',
    Bern: 'Switzerland',
    Damascus: 'Syria',
    Taipei: 'Taiwan',
    Dushanbe: 'Tajikistan',
    Dodoma: 'Tanzania',
    Bangkok: 'Thailand',
    Lome: 'Togo',
    "Nuku'alofa": 'Tonga',
    'Port-of-Spain': 'TrinidadAndTobago',
    Tunis: 'Tunisia',
    Ankara: 'Turkey',
    Ashgabat: 'Turkmenistan',
    Funafuti: 'Tuvalu',
    Kampala: 'Uganda',
    Kyiv: 'Ukraine',
    'Abu Dhabi': 'UnitedArabEmirates',
    Montevideo: 'Uruguay',
    Washington: 'UnitedStates',
    Tashkent: 'Uzbekistan',
    'Port-Vila': 'Vanuatu',
    'Vatican City': 'Vatican',
    Caracas: 'Venezuela',
    Hanoi: 'Vietnam',
    Cardiff: 'Wales',
    Sanaa: 'Yemen',
    Lusaka: 'Zambia',
    Harare: 'Zimbabwe'
  },

  fr: {
    Kaboul: 'Afghanistan',
    Tirana: 'Albanie',
    Alger: 'Algérie',
    'Andorre-la-Vieille': 'Andorre',
    Luanda: 'Angola',
    "Saint John's": 'Antigua-et-Barbuda',
    'Buenos Aires': 'Argentine',
    Erevan: 'Arménie',
    Canberra: 'Australie',
    Vienne: 'Autriche',
    Bakou: 'Azerbaïdjan',
    Nassau: 'Bahamas',
    Manama: 'Bahreïn',
    Dacca: 'Bangladesh',
    Bridgetown: 'Barbade',
    Minsk: 'Biélorussie',
    Bruxelles: 'Belgique',
    Belmopan: 'Bélize',
    'Porto-Novo': 'Bénin',
    Thimphou: 'Bhoutan',
    'La Paz': 'Bolivie', // La Paz
    Sarajevo: 'Bosnie-Herzégovine',
    Gaborone: 'Botswana',
    Brasilia: 'Brésil',
    'Bandar Seri Begawan': 'Brunei',
    Sofia: 'Bulgarie',
    Ouagadougou: 'Burkina',
    Gitega: 'Burundi',
    'Phnom Penh': 'Cambodge',
    Yaoundé: 'Cameroun',
    Ottawa: 'Canada',
    Praia: 'Cap-Vert',
    Bangui: 'Centrafrique',
    Ndjamena: 'Tchad',
    Santiago: 'Chili',
    Pékin: 'Chine',
    Bogota: 'Colombie',
    Moroni: 'Comores',
    Brazzaville: 'Congo',
    Kinshasa: 'République démocratique du Congo',
    'San José': 'Costa Rica',
    Zagreb: 'Croatie',
    'La Havane': 'Cuba',
    Nicosie: 'Chypre',
    Prague: 'République Tchèque',
    Copenhague: 'Danemark',
    Djibouti: 'Djibouti',
    Roseau: 'Dominique',
    'Saint-Domingue': 'République Dominicaine',
    Dili: 'Timor oriental',
    Quito: 'Équateur',
    'Le Caire': 'Égypte',
    'San Salvador': 'Salvador',
    Londres: 'Royaume-Uni', // Angleterre
    Malabo: 'Guinée équatoriale',
    Asmara: 'Érythrée',
    Tallinn: 'Estonie',
    'Addis Abeba': 'Éthiopie',
    Suva: 'Fidji',
    Helsinki: 'Finlande',
    Paris: 'France',
    Libreville: 'Gabon',
    Banjul: 'Gambie',
    Tbilissi: 'Géorgie',
    Berlin: 'Allemagne',
    Accra: 'Ghana',
    Athènes: 'Grèce',
    'Saint-Georges': 'Grenade',
    Guatémala: 'Guatémala',
    Conakry: 'Guinée',
    Bissao: 'Guinée-Bissao',
    Georgetown: 'Guyana',
    'Port-au-Prince': 'Haïti',
    Tégucigalpa: 'Honduras',
    Budapest: 'Hongrie',
    Reykjavik: 'Islande',
    'New Delhi': 'Inde',
    Jakarta: 'Indonésie',
    Téhéran: 'Iran',
    Bagdad: 'Irak',
    Dublin: 'Irlande',
    Jérusalem: 'Israël',
    Rome: 'Italie',
    Yamoussoukro: "Côte d'Ivoire",
    Kingston: 'Jamaïque',
    Tokyo: 'Japon',
    Amman: 'Jordanie',
    'Nour-Soultan': 'Kazakhstan',
    Nairobi: 'Kénya',
    Tarawa: 'Kiribati',
    Pristina: 'Kosovo',
    Koweït: 'Koweït',
    Bichkek: 'Kirghizstan',
    Vientiane: 'Laos',
    Riga: 'Lettonie',
    Beyrouth: 'Liban',
    Maséru: 'Lésotho',
    Monrovia: 'Libéria',
    Tripoli: 'Libye',
    Vaduz: 'Liechtenstein',
    Vilnius: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Skopje: 'Macédoine',
    Antananarivo: 'Madagascar',
    Lilongwé: 'Malawi',
    'Kuala Lumpur': 'Malaisie',
    Malé: 'Maldives',
    Bamako: 'Mali',
    'La Valette': 'Malte',
    'Delap-Uliga-Darrit': 'Îles Marshall',
    Nouakchott: 'Mauritanie',
    'Port-Louis': 'Maurice',
    Mexico: 'Mexique',
    Palikir: 'Micronésie',
    Chisinau: 'Moldavie',
    Monaco: 'Monaco',
    'Oulan-Bator': 'Mongolie',
    Podgorica: 'Monténégro',
    Rabat: 'Maroc',
    Maputo: 'Mozambique',
    Naypyidaw: 'Birmanie',
    Windhoek: 'Namibie',
    Katmandou: 'Népal',
    Amsterdam: 'Pays-Bas',
    Wellington: 'Nouvelle-Zélande',
    Managua: 'Nicaragua',
    Niamey: 'Niger',
    Abuja: 'Nigéria',
    Belfast: 'Irlande Du Nord',
    Pyongyang: 'Corée du Nord',
    Oslo: 'Norvège',
    Mascate: 'Oman',
    Islamabad: 'Pakistan',
    Melekeok: 'Palaos',
    Panama: 'Panama',
    'Port Moresby': 'Papouasie-Nouvelle-Guinée',
    Assomption: 'Paraguay', // Asuncion
    Lima: 'Pérou',
    Manille: 'Philippines',
    Varsovie: 'Pologne',
    Lisbonne: 'Portugal',
    Doha: 'Qatar',
    Bucarest: 'Roumanie',
    Moscou: 'Russie',
    Kigali: 'Rwanda',
    Apia: 'Samoa',
    'Saint-Marin': 'Saint-Marin',
    'Sao Tomé': 'Sao Tomé-et-Principe',
    Riyad: 'Arabie Saoudite',
    Dakar: 'Sénégal',
    Belgrade: 'Serbie',
    Victoria: 'Seychelles',
    Freetown: 'Sierra Leone',
    Singapour: 'Singapour',
    Bratislava: 'Slovaquie',
    Ljubljana: 'Slovénie',
    Honiara: 'Salomon',
    Mogadiscio: 'Somalie',
    Prétoria: 'Afrique du Sud',
    Séoul: 'Corée du Sud',
    Djouba: 'Soudan du Sud',
    Madrid: 'Espagne',
    'Sri Jayawardenepura Kotte': 'Sri Lanka',
    Basseterre: 'Saint-Christophe-et-Niévès',
    Castries: 'Sainte-Lucie',
    Kingstown: 'Saint-Vincent-et-les-Grenadines',
    Édimbourg: 'Ecosse',
    Khartoum: 'Soudan',
    Paramaribo: 'Suriname',
    Mbabané: 'Eswatini',
    Stockholm: 'Suède',
    Berne: 'Suisse',
    Damas: 'Syrie',
    Taipei: 'Taïwan',
    Douchanbé: 'Tadjikistan',
    Dodoma: 'Tanzanie',
    Bangkok: 'Thaïlande',
    Lomé: 'Togo',
    "Nuku'alofa": 'Tonga',
    "Port-d'Espagne": 'Trinité-et-Tobago', // Port of Spain
    Tunis: 'Tunisie',
    Ankara: 'Turquie',
    Achgabat: 'Turkménistan',
    Funafuti: 'Tuvalu',
    Kampala: 'Ouganda',
    Kiev: 'Ukraine',
    'Abou Dabi': 'Émirats Arabes Unis',
    Montévidéo: 'Uruguay',
    Washington: 'États-Unis',
    Tachkent: 'Ouzbékistan',
    'Port-Vila': 'Vanuatu',
    'Cité du Vatican': 'Vatican',
    Caracas: 'Vénézuéla',
    Hanoï: 'Vietnam',
    Cardiff: 'Pays De Galles',
    Sanaa: 'Yémen',
    Lusaka: 'Zambie',
    Harare: 'Zimbabwé'
  }
}

const alsoAcceptedAsAnswersMap = {
  en: {
    'Addis Ababa': ['at least a'],
    Amman: ['Aman'],
    Antananarivo: ['antenna', 'internet', 'antonella', 'tanana', 'river'],
    'Andorra la Vella': ['Andorra', 'Andora la v', 'and Dora'],
    Apia: ['appia', 'appiah', 'happier', 'up here'],
    Ashgabat: ['gaba', 'gabba', 'ask about', 'ashkabet', 'ashkabat'],
    Asmara: ['smaller', 'as mara', 'as mira', 'as mirror'],
    Asuncion: ['as soon', 'ascension', 'asunci', 'santien', 'sancien'],
    'Bandar Seri Begawan': [
      'bender ser',
      'bend our cer',
      'bender cer',
      'bandar ser',
      'bendar ers',
      'bigger one',
      'bigger 1',
      'bundor s'
    ],
    Bamako: ['Bomako'],
    Bangui: ['bengi', 'bingi', 'bangi'],
    Banjul: ['banjo', 'banju', 'benjo', 'benju'],
    Basseterre: ['pass', 'bass', 'tear', 'tier', 'buster'],
    Belgrade: ['grad'],
    Belmopan: ['build my pan', 'u pan', 'o pan'],
    Bern: ['Burn'],
    Bishkek: ['bisc'],
    Bissau: ['Biss', 'bisa', 'be sa', 'be so'],
    Bratislava: ['s lover', 'ze lover', 'se lover'],
    Chisinau: ['chisn', 'kiss', "she's", 'sino', 'now'],
    Conakry: ['konachr', 'connec', 'can I cr', 'kunachr', 'kona cr'],
    Dakar: ['dhaka', 'decor', 'deca', 'ducker', 'darker'],
    'Delap-Uliga-Darrit': [
      'the lap',
      'the lab',
      'illegal',
      'liga',
      'derrit',
      'direct',
      'dare it',
      'the rate',
      'a rate',
      'get rid',
      'a rit'
    ],
    Dili: ['delete', 'dilly', 'didi', 'daily'],
    Dushanbe: ['dushamb', 'dushemb', 'december', 'duchamb', 'duchemb'],
    Funafuti: ['fity', 'futi', 'fun of'],
    Gitega: ['tiga', 'tega', 'taga', 'get t', 'gita', 'geeta', 'guitar'],
    Hanoi: ['annoy'],
    Harare: ['har', 'hur'],
    Honiara: ['ara'],
    Kampala: ['compiler'],
    Khartoum: ['cartoo'],
    Kigali: ['Kiyali'],
    Kyiv: ['kiev', 'kev'],
    'La Paz': ['Sucre'],
    Lilongwe: ['lungw'],
    Ljubljana: ['ana'],
    Lusaka: ['soccer'],
    Male: ['mal'],
    Maputo: ['my budd'],
    Maseru: ['maser', 'masar', 'masser', 'mazer', 'mazar', 'mazur', 'measure'],
    Mbabane: ['baban', 'bye-bye'],
    Melekeok: ['mini', 'milli', 'mele', 'kirk', 'kauque', 'molecuc'],
    Mogadishu: ['ishu', 'issue'],
    Monaco: ['noko', 'nico', 'nukau'],
    Moroni: ['moron', 'more than'],
    Muscat: ['cat', 'skat'],
    "N'Djamena": ['in gemin', 'in German', 'jamina', 'gemina'],
    Nassau: ['Nestle', 'nesso', 'naso', 'nasal', 'now so'],
    Naypyidaw: ['Napier', 'napi', 'nappy', 'nipidal', 'naipidal', 'naipid', '"nighty day"'],
    Niamey: ['Naomi', 'nami'],
    Nouakchott: ['no act', 'akshat', 'shot'],
    "Nuku'alofa": ['nuku', 'nukku', 'nikku', 'niku', 'nucle', 'alafa', 'alpha'],
    'Nur-Sultan': ['sultan', 'north', 'sutton', 'certain'],
    Ouagadougou: ['wagadou', 'wagadu', 'wakadu', 'got to go', 'gotta go'],
    Palikir: ['palick', 'pelik', 'palik'],
    Paramaribo: ['param', 'parram'],
    'Phnom Penh': ['mpin', 'pen', 'onpan'],
    Podgorica: ['gorika', 'gorica'],
    'Port-au-Prince': ['porto', 'Porter', 'puerto', 'prince'],
    'Port-of-Spain': ['port of spain'],
    'Port-Vila': ['vila', 'villa'],
    'Porto-Novo': ['porter no', 'porto no'],
    'Port Louis': ['loui'],
    'Port Moresby': ['put more spy'],
    Praia: ['prior', 'prayer'],
    Pristina: ['Christina'],
    Quito: ['Kito', 'keto', 'kuito'],
    Rabat: ['Rabbit'],
    Reykjavik: ['Red jovic', 'shawvic', 'shawvik', 'shawik'],
    Roseau: ['roso', 'rosa', 'rosu', 'rozu', `rozo`],
    Sanaa: ['Sana'],
    'Sao Tome': ['São T', 'Tommy', 'tomi', 'tomy', 'to me', 'tell me'],
    Sarajevo: ['sorry', 'saraj'],
    Seoul: ['so', 'sil'],
    Skopje: ['scorp', 'scop'],
    'Sri Jayawardenepura Kotte': ['Jayawardenepura', 'Kotte', 'cut', 'cutter'],
    Tallinn: ['talian', 'talin', 'telin', 'tellin'],
    Tashkent: ['dash', 'tash', 'kent', 'kint'],
    Tegucigalpa: ['teguciga', 'take you', 'take your', 'singalpa', 'take us', 'take a s'],
    Tbilisi: ['BBC', 'be easy', 'be lazy', 'lissi', 'lizzi', 'bilis'],
    Thimphu: ['simple', 'team', 'temp', 'timp', 'tim fu', 'timfu', 'some food'],
    Tunis: ['to this'],
    Ulaanbaatar: ['batar', 'battar', 'bhatar', 'butter'],
    Vaduz: ['vadu', 'vado', 'vedu'],
    Valletta: ['valet'],
    Vientiane: ['vientian'],
    Vilnius: ['venu'],
    Windhoek: ['windo'],
    Yaounde: ['yaund', 'yahund', 'young de', 'yond', 'yong'],
    Yamoussoukro: ['sukru', 'suku', 'sukra', 'suka', 'suko', 'sukro', 'you miss', 'yemus', 'yamus'],
    Yerevan: ['yarra', 'yara', 'van', 'ur event'],
    Zagreb: ['our crab']
  },
  fr: {
    'Addis Ababa': ['abeba', 'a dit ça', 'Abiba', 'Alice', 'à Beba'],
    'Andorra la Vella': ['Andorre', 'la vieille'],
    Antananarivo: ['Tananarive'],
    Ashgabat: ['hdback', 'hdbat', 'âge Gabat', 'âge Gabin', 'âge gabba'],
    'Bandar Seri Begawan': ['Bandar Seri', 'Bandar série'],
    Banjul: ['bonjour', 'bon Jules', 'bon Jul'],
    Basseterre: ['Basse Terre', 'Basse-Terre'],
    Bishkek: ['bifteck', 'bizkake', 'cheesecake', 'biche cake'],
    'Delap-Uliga-Darrit': [
      'Majuro',
      'majureau',
      'majoreau',
      'majoro',
      'de la bulliga',
      'de la plu',
      'des lapins',
      'de la hulgari',
      'des laps il y a',
      'des laps hulliga',
      'des laps bulliga',
      'gadarit'
    ],
    Bissau: ['Bisso', 'bisou'],
    Chisinau: ['Chisinau', 'chicino', 'shisino', 'shisinau', 'chichino', 'chicinot', 'shisinau'],
    Dhaka: ['Dhaka', "d'accord", 'Daka', 'Dacca'],
    Edinburgh: ['Edimbourg'],
    "Nuku'alofa": ['nuque Europa', 'Nico a', 'Nico ha', 'Nuki a', 'Nuki ha', 'Nico à'],
    Funafuti: ['Funafu', 'Funa Fu', 'funafou', 'founafou'],
    Gitega: ['10 dégât', 'tiga', 'tega'],
    Honiara: ['on ira', 'onira'],
    'Sri Jayawardenepura Kotte': ['Jayawardenepura', 'Kotte', 'cot', 'côté'],
    Juba: ['du bas', 'Duba'],
    Khartoum: ['cartoon', 'Kartoom'],
    'La Paz': ['Sucre'],
    Ljubljana: ['lana', 'liana', 'biana'],
    Lilongwe: ['les longues', 'li Long', "l'île-en"],
    Male: ['mal est', 'mal et', 'mali', 'Mallet', 'Malet'],
    Maseru: ['Maseru', 'ma serru', 'Massey rue', 'masséru', 'masseyru'],
    Mbabane: ['Baban'],
    Melekeok: ['mais les kio', 'mets les kio'],
    Muscat: ['Mascate', 'maskat', 'Mascat', 'maskats'],
    Naypyidaw: ['night p', 'night i', 'Nighty d', 'napido', 'nepido', 'libido'],
    "N'Djamena": ['Jamila', 'jamena'],
    'Nur-Sultan': ['Nour sultan', 'sous le temps'],
    Paramaribo: ['Maribo', 'Maribou', 'Marivaux'],
    Podgorica: ['gorika', 'gorica'],
    'Port-of-Spain': ['port of spain', 'Espagne'],
    Pristina: ['Christina'],
    Skopje: ['Skype', 'Skop', 'scapi', 'skapi', 'Scorpio', 'scopi'],
    Suva: ['tu va'],
    Tbilisi: ['BBC', 'Billy si', 'ici', 'Tibilisi'],
    Tegucigalpa: [
      'tu es du Cigale',
      'tu es Lucie',
      'galpa',
      'tegucie',
      'galva',
      'Galba',
      'cigale pas'
    ],
    Thimphu: ['team fu', 'Tim fu', 'timfu', 'team fou', 'timfu', 'teamfu'],
    Ulaanbaatar: ["ou l'emb", "ou l'amb", 'ou en ba', 'ou lamba'],
    Vaduz: ['va 12', 'va douce', 'vadu'],
    Vientiane: ['Diane', 'viens Tian'],
    Windhoek: ['Windo']
  }
}

// for reverse capitals
const invertedAnswerQuestionMap = createInvertedAnswerQuestionMap(answerQuestionMap)

const foreignAnswers = {
  fr: makeLanguageAnswers('fr', answerQuestionMap)
}

const alsoAcceptedReverseAsAnswersMap = {
  en: {
    'Antigua and Barbuda': ['Antigua', 'Barbuda'],
    BosniaHerzegovina: ['Bosnia', 'Herzegovina'],
    BruneiDarussalam: ['BruneiDarussalam', 'Brunei', 'Darussalam'],
    BurkinaFaso: ['Burkina Faso'],
    CapeVerde: ['Cape Verde'],
    CentralAfricanRepublic: ['Central African Republic'],
    Congo: [
      'Republic of the Congo',
      'Republic of Congo',
      'Congo Republic',
      'Congo-Brazzaville',
      'Congo Brazzaville'
    ],
    CongoDR: ['Democratic Republic of the Congo', 'Congo-Kinshasa', 'Congo Kinshasa'],
    CostaRica: ['Costa Rica'],
    CzechRepublic: ['Czech Republic', 'Czechia', 'Czechoslovakia'],
    DominicanRepublic: ['Dominican Republic'],
    TimorLeste: ['Timor-Leste', 'Timor', 'Leste'],
    ElSalvador: ['El Salvador'],
    EquatorialGuinea: ['Equatorial Guinea'],
    GuineaBissau: ['Guinea-Bissau', 'Guinea Bissau'],
    IvoryCoast: ['Ivory Coast', "Côte d'Ivoire"],
    'Marshall Islands': ['Marshall Islands'],
    Myanmar: ['Burma'],
    Netherlands: ['Holland'],
    NewZealand: ['New Zealand'],
    NorthKorea: [
      'North Korea',
      "Democratic People's Republic of Korea",
      'Democratic Republic of Korea',
      'Democratic Republic Korea',
      'Democratic Korea',
      "People's Republic of Korea",
      'People Republic of Korea',
      "People's Republic Korea",
      'People Republic Korea'
    ],
    PapuaNewGuinea: ['Papua New Guinea'],
    Russia: ['Russia Federation', 'Russian Federation'],
    SanMarino: ['San Marino'],
    SaoTomePrincipe: ['São Tomé and Principe', 'Sao Tome', 'Principe', 'São Tomé'],
    SaudiArabia: ['Saudi Arabia'],
    SierraLeone: ['Sierra Leone'],
    SolomonIslands: ['Solomon Islands', 'Solomon'],
    SouthAfrica: ['South Africa'],
    SouthKorea: ['South Korea', 'Republic of Korea', 'Republic Korea'],
    SouthSudan: ['South Sudan'],
    SriLanka: ['Sri Lanka'],
    SaintKittsNevis: ['Saint Kitts and Nevis', 'Saint Kitts', 'Nevis'],
    SaintLucia: ['Saint Lucia', 'Lucia'],
    SaintVincent: ['Saint Vincent and the Grenadines', 'Saint Vincent', 'Grenadines'],
    Swaziland: ['Swaziland', 'Eswatini'],
    TrinidadAndTobago: ['Trinidad and Tobago', 'Trinidad', 'Tobago'],
    UnitedArabEmirates: ['United Arab Emirates', 'U.A.E', 'UAE', 'U A E'],
    UnitedStates: ['United States', 'U.S.A.', 'USA', 'U S A'],
    Vatican: ['Holy See']
  },

  fr: {
    ...makeLanguageAnswers('fr', invertedAnswerQuestionMap),
    'Antigua and Barbuda': ['Antigua', 'Barbuda'],
    BosniaHerzegovina: ['Bosnie', 'Herzégovine'],
    CongoDR: ['République démocratique du Congo'],
    CzechRepublic: ['République Tchèque', 'Tchéquie', 'Tchécoslovaquie'],
    England: ['Royaume-Uni', 'Angleterre'],
    EquatorialGuinea: ['Guinée équatoriale'],
    Georgia: ['Georgie'],
    Guatemala: ['Guatemala'], // because Annyang does not compute the accent so we allow without, but if we want to show the answer, it needs the accent
    GuineaBissau: ['Guinée', 'Bissao'],
    IvoryCoast: ["Côte d'Ivoire"],
    Kenya: ['Kenya'],
    Kyrgyzstan: ['Kirghizistan', 'Kirghistan'],
    Lesotho: ['Lesotho', 'Lesotos'],
    'Marshall Islands': ['Marshall'],
    Netherlands: ['Pays-Bas', 'Pays Bas', 'Hollande'],
    NewZealand: ['Nouvelle-Zélande', 'Nouvelle Zélande'],
    PapuaNewGuinea: ['Papouasie', 'Nouvelle-Guinée', 'Nouvelle Guinée'],
    SanMarino: ['Saint-Marin', 'Saint Marin'],
    SaoTomePrincipe: ['Sao Tomé', 'Principe'],
    SaintKittsNevis: ['Saint-Christophe', 'Saint Christophe', 'Niévès', 'Nieves'],
    SaintLucia: ['Sainte-Lucie', 'Sainte Lucie'],
    SaintVincent: ['Saint-Vincent', 'Saint Vincent', 'Grenadines'],
    Swaziland: ['Swaziland', 'Eswatini', 'et sois tini', 'et soit tini', 'et soit tiny'], // because Eswatini (new country name) is not understood
    TimorLeste: ['Timor'],
    TrinidadAndTobago: ['Trinité-et-Tobago', 'Trinité', 'Tobago'],
    UnitedArabEmirates: ['Émirats arabes unis', 'Emirats Arabes Unis'],
    UnitedStates: ['États-Unis', 'États Unis'],
    Wales: ['Pays-De-Galles', 'Pays De Galles'],
    Zimbabwe: ['Zimbabwe']
  }
}

export {
  answerQuestionMap,
  alsoAcceptedAsAnswersMap,
  invertedAnswerQuestionMap,
  foreignAnswers,
  alsoAcceptedReverseAsAnswersMap
}
