import axios from 'axios'
import {
  changePathAction,
  updateIsListeningAction,
  saveTranscriptAction
} from '../redux/actions/actions'
import { mainCommandpathMap, extendedCommandPathMap } from './commandPathMaps'
import { commonTranslateArray, addAcceptedOrtographCommands } from './languageUtil'

// TODO - From Annayng util
// TODO - Not sure saveTranscript had a point in the first place
// - Not sure that annyang.addTranscriptListener(dispatch) calls are needed via Google cloud
// No need for bindNavigationCommands. It's mapped when startRecording goes
// - not sure that setLanguage is needed either. When called updateLanguageWith was also called and then
// dispatch(updateLanguageAction(supportedLanguage)) to update the state

// TODO
// Choose between Annyang and Google based on a dev boolean for now

let isListening = false
let mediaRecorder
let silenceDetected = false
let silenceTimer = null
let analyser, dataArray
let isForceStopRecording = false
let commands = {}

const _createCommands = (navigationCommands, lngCode = 'en') => {
  // tranlated array of allowed navigation commands / words that can be spoken
  // ex: with en navigationCommands = ['menu', 'capitals', 'flags', 'locations']
  // translatedNavigationCommands = ['menu', 'capitales', 'drapeaux', 'localisations']
  const translatedNavigationCommands = commonTranslateArray(navigationCommands)

  // commandpathMap is an object with 'translated command' keys and /path values.
  // ex: { menu: '/menu', capitales: '/geography/capitals', drapeaux: '/geography/flags' }
  let commandpathMap = {}
  translatedNavigationCommands.forEach(command => {
    commandpathMap[command] = mainCommandpathMap[lngCode][command]
  })

  // to handle additional commands for sub-topic pages
  // ex if we are in geography capitals, new keys/pairs such as { "facile": "/geography/capitals/easy",
  // "moyen": "/geography/capitals/medium" } are valid commands
  const pathName = window.location.pathname
  const subPathsKeysList = Object.keys(extendedCommandPathMap[lngCode])
  if (subPathsKeysList.includes(pathName)) {
    commandpathMap = { ...commandpathMap, ...extendedCommandPathMap[lngCode][pathName] }
  }

  // to add extra specific orthograph discrepancies in command creations
  // ex: { drapeau: "/geography/flags", drapeaux: "/geography/flags" } (with X) are both accepted
  return addAcceptedOrtographCommands(lngCode, commandpathMap)
}

const stopRecording = ({ dispatch, forceStopRecording = false }) => {
  if (mediaRecorder) {
    isListening = false
    dispatch(updateIsListeningAction(false))

    // if a stop recording button is pressed. Also no navigation is allowed on voice (commands = {})
    isForceStopRecording = forceStopRecording
    if (forceStopRecording) {
      commands = {}
    }

    mediaRecorder.stop()
    mediaRecorder.removeEventListener('dataavailable', _recordAudio)
    mediaRecorder = null
  }
}

// Function to convert audio blob to base64 encoded string
const _audioBlobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const arrayBuffer = reader.result
      const base64Audio = btoa(
        new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
      resolve(base64Audio)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })

// If the volume level is below a certain threshold (whisper, blow, low level speaking), we do not want to send to the API
const _isAcceptableAudioVolume = async audioBlob =>
  new Promise((resolve, reject) => {
    const volumeThreshold = 0.06 // after a few tests, decided on this number.

    const audioContext = new (window.AudioContext || window.webkitAudioContext)()
    const reader = new FileReader()

    reader.onload = event => {
      audioContext.decodeAudioData(event.target.result, audioBuffer => {
        const rawData = audioBuffer.getChannelData(0) // Get the audio samples
        let total = 0
        for (let i = 0; i < rawData.length; i++) {
          total += Math.abs(rawData[i])
        }
        const rms = Math.sqrt(total / rawData.length)
        resolve(rms > volumeThreshold) // Return the RMS value of the audio
      })
    }

    reader.onerror = function (event) {
      reject(event.error)
    }

    reader.readAsArrayBuffer(audioBlob)
  })

// The audio data must be less than 1 minute and under 10 MB is size.
// what was suggested (10 * 1024 * 1024 // 10 MB) didn't work. I made my own limit using trial and error:
// 8 * 8 * 1024 seem to be fine to avoid "PayloadTooLargeError: request entity too large" + messages are supposed to be short anyway
const maxSizeInBytes = 8 * 8 * 1024
const _isUnderPayloadSizeLimit = audioBlob => audioBlob.size < maxSizeInBytes

const _recordAudio = async (event, dispatch, language) => {
  const audioBlob = event.data
  const isAcceptableAudioVolume = await _isAcceptableAudioVolume(audioBlob)

  // Not 100% accurate but these limit sending crap to the API: limit unneeded billing + API errors
  const isLikelyValidAudio = isAcceptableAudioVolume && _isUnderPayloadSizeLimit(audioBlob)
  if (isLikelyValidAudio) {
    const base64Audio = await _audioBlobToBase64(audioBlob)

    try {
      await axios
        .post('/.netlify/functions/getSpeech', { language, base64Audio })
        .then(response => {
          if (response && response.data && response.data.transcript) {
            const newText = response.data.transcript
            dispatch(saveTranscriptAction([newText]))

            // if a known navigation command is spoken, navigate to that page
            if (Object.keys(commands).includes(newText)) {
              dispatch(changePathAction(commands[newText]))
            }
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
}

const startRecording = async ({
  dispatch,
  withAutoRestart = false,
  navigationCommands = [],
  language = 'en_US',
  lngCode = 'en'
}) => {
  if (isForceStopRecording) {
    isForceStopRecording = false
  }
  if (isListening || isForceStopRecording) {
    return // Prevent multiple starts
  }

  if (navigationCommands.length > 0) {
    commands = _createCommands(navigationCommands, lngCode)
  }
  isListening = true
  dispatch(updateIsListeningAction(true))
  dispatch(saveTranscriptAction([]))
  const silenceThreshold = 0.01
  const silenceTimeout = 1000 // 1 second of silence
  silenceDetected = false
  silenceTimer = null
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()

  const _handleSilence = () => {
    if (silenceDetected) return
    silenceDetected = true
    setTimeout(() => {
      if (silenceDetected) {
        isListening = false
        dispatch(updateIsListeningAction(false))
        if (mediaRecorder) {
          mediaRecorder.stop() // Stop recording
        }
      }
    }, silenceTimeout)
  }

  const _resetSilenceTimer = () => {
    clearTimeout(silenceTimer)
    silenceDetected = false
    silenceTimer = setTimeout(_handleSilence, silenceTimeout)
  }

  const _checkSilence = () => {
    analyser.getByteTimeDomainData(dataArray)
    let sum = 0.0
    for (let i = 0; i < dataArray.length; i++) {
      const sample = dataArray[i] / 128.0 - 1.0 // Convert to [-1, 1] range
      sum += sample * sample
    }
    const volume = Math.sqrt(sum / dataArray.length)

    if (volume < silenceThreshold) {
      _handleSilence()
    } else {
      _resetSilenceTimer()
    }

    if (isListening) {
      requestAnimationFrame(_checkSilence)
    }
  }

  const _startMediaRecorder = stream => {
    mediaRecorder = new MediaRecorder(stream)

    mediaRecorder.ondataavailable = event => {
      _recordAudio(event, dispatch, language)
    }

    mediaRecorder.onstop = () => {
      if (withAutoRestart && !isForceStopRecording) {
        // Restart the recording if auto-restart is enabled
        setTimeout(() => {
          if (!isListening) {
            isListening = true
            dispatch(updateIsListeningAction(true))
            if (mediaRecorder) {
              mediaRecorder.start()
            }
            _checkSilence()
          }
        }, 1000) // Restart after 1 second delay
      }
    }

    mediaRecorder.start() // Start recording
    _checkSilence() // Start checking for silence
  }

  await navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(stream => {
      const source = audioContext.createMediaStreamSource(stream)
      analyser = audioContext.createAnalyser()
      analyser.fftSize = 2048
      dataArray = new Uint8Array(analyser.fftSize)

      source.connect(analyser)

      _startMediaRecorder(stream) // Start the media recorder
    })
    .catch(error => {
      dispatch(updateIsListeningAction(false))
      isListening = false
      console.error('Error accessing media devices.', error)
    })
}

export { startRecording, stopRecording }
