import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 808
const height = 281

const cities = {
  Havana: { x: 197, y: 16 }
}

const Cuba = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map cuba">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="CU-16"
          title="Mayabeque"
          className="land"
          d="M242.59,8.73L211.84,6.27L211.03,15.91L187.02,24.77L190.3,46.32L245.04,55.39L242.59,8.73z"
        />
        <path
          id="CU-05"
          title="Villa Clara"
          className="land"
          d="M398.78,37.05L394.07,38.67L416.75,52.08L398.78,37.05zM390.64,32.61L387.14,29.04L386.89,32.08L390.64,32.61zM373.58,24.04L370.72,27.08L375.29,25.65L373.58,24.04zM379.2,28.41L380.42,25L377.27,24.34L379.2,28.41zM348.42,22.42L341.31,22.42L349.88,24.61L348.42,22.42zM333.38,23.58L323.18,15.88L311.71,30.92L323.79,36.29L328.61,58.04L336.18,56.08L348.14,55.35L365.23,106.21L384.3,101.19L384.25,85.94L414.21,82.87L410.1,63.6L377.56,35.72L382.3,29.54L373.18,33.43L363.59,24.61L346.35,28.4L333.38,23.58zM362.23,16.68L360,17.46L362.85,19.2L362.23,16.68zM356.48,15.25L352.45,11.36L350.56,13.33L356.48,15.25zM348.93,14.49L346.88,10.09L344.42,10.54L348.93,14.49zM339.08,9.48L334.04,9.24L332.85,10.48L339.08,9.48z"
        />
        <path
          id="CU-04"
          title="Matanzas"
          className="land"
          d="M252.53,96.66L249.61,95.52L250.13,97.28L252.53,96.66zM304.67,7.02L295.21,12.45L307.54,9.06L304.67,7.02zM328.14,5.87L324.11,6.97L332.22,6.17L328.14,5.87zM317.97,5.16L315.66,4.96L314.97,6.85L317.97,5.16zM279.46,17.16L271.97,10.86L282.1,4.28L254.05,17.55L242.59,8.73L245.04,55.39L242.62,64.31L207.12,69.64L233.26,86.65L275.45,89.27L278,96.51L278.79,78.85L286.58,94.47L323.16,96.99L297.33,72.99L328.61,58.04L323.79,36.29L311.71,30.92L323.18,15.88L321.95,8.63L279.46,17.16zM314.36,5.28L303.36,3.64L312.27,6.54L314.36,5.28zM299.55,0L297.35,0.67L302.66,2.03L299.55,0z"
        />
        <path
          id="CU-15"
          title="Artemisa"
          className="land"
          d="M169.06,54.92L168.51,53.66L167.62,54.57L169.06,54.92zM180.9,56.14L181.72,54.19L180.47,50.13L180.9,56.14zM179.75,15.98L179.43,15.43L149.45,22.54L146.97,36.81L158.31,52.55L163.09,44.91L190.3,46.32L187.02,24.77L179.75,15.98z"
        />
        <path
          id="CU-03"
          title="Ciudad de la Habana"
          className="land"
          d="M211.84,6.27L179.43,15.43L179.75,15.98L187.02,24.77L211.03,15.91L211.84,6.27z"
        />
        <path
          id="CU-13"
          title="Santiago de Cuba"
          className="land"
          d="M666.07,220.12L659.1,214.43L649.28,225.15L635.72,230.75L628.38,255.59L594.15,259.35L586.11,268.43L647.61,260.21L708.21,268.84L700.26,254.76L708.7,226.32L707.42,216.48L675.51,227.52L666.07,220.12z"
        />
        <path
          id="CU-10"
          title="Las Tunas"
          className="land"
          d="M639.39,160.77L625.07,157.94L622.84,166.1L622.29,157.94L608.27,158.11L612.94,152.52L596.99,156.38L602.57,149.22L592.74,144.44L581.24,147.72L567.1,181.12L556.33,174.7L527.26,202.43L563.79,203.16L585.2,201.38L609.21,202.68L612.7,183.07L624.07,182.44L639.39,160.77z"
        />
        <path
          id="CU-11"
          title="Holguín"
          className="land"
          d="M700.55,200.51L698.23,197L693.7,197.54L700.55,200.51zM653.2,172.64L639.39,160.77L624.07,182.44L612.7,183.07L609.21,202.68L649.28,225.15L659.1,214.43L666.07,220.12L675.51,227.52L707.42,216.48L708.7,226.32L748.36,227.59L757.48,215.81L736.56,204.39L695.72,205.68L689.66,198.08L682.91,204.8L679.53,193.71L695.72,194.84L681.51,189.48L691.14,175.77L653.2,172.64z"
        />
        <path
          id="CU-12"
          title="Granma"
          className="land"
          d="M534.2,270.95L586.11,268.43L594.15,259.35L628.38,255.59L635.72,230.75L649.28,225.15L609.21,202.68L585.2,201.38L563.79,203.16L580.28,230.6L545.34,252.65L534.2,270.95z"
        />
        <path
          id="CU-14"
          title="Guantánamo"
          className="land"
          d="M798.53,238.09L781.29,237.06L757.48,215.81L748.36,227.59L708.7,226.32L700.26,254.76L708.21,268.84L729.61,254.8L729.66,267.7L750.26,256.68L790.57,254.24L798.53,238.09z"
        />
        <path
          id="CU-09"
          title="Camagüey"
          className="land"
          d="M486.39,215.33L480.54,212.15L490.52,218.55L486.39,215.33zM472.04,207.79L469.46,207.77L470.89,208.96L472.04,207.79zM468.14,208.15L466.41,207.3L467.01,208.21L468.14,208.15zM479.43,202.14L474.48,204.11L482.55,202.39L479.43,202.14zM509.97,94.38L508.73,94.71L496.17,107.24L501.24,119.69L492.68,115.8L467.37,138.4L478.73,177.67L509.94,203.53L527.26,202.43L556.33,174.7L567.1,181.12L581.24,147.72L592.74,144.44L582.14,132.65L576.71,142.67L569.6,142.96L561.75,132.21L577.26,136.75L578.77,128.02L546.43,106.83L563.8,129.16L556.87,128.56L555,119.08L533.13,116.87L529.1,104.01L523.05,111.68L509.97,94.38zM523.05,95.26L541.81,105.69L540.51,95.71L523.05,95.26zM532.68,87.09L527.03,77.71L523.6,76.68L532.68,87.09zM515.71,82.43L516.63,77.47L513.46,76.19L514.53,78.47L514.43,81.37L512.12,86.69L524.87,93.41L524.56,83.23L515.71,82.43zM500.18,66.42L491.68,69.05L496.58,69.34L497.23,74.51L499.62,71.95L502.11,76.52L507.37,77.23L512.74,80.09L513.97,78.87L511.4,78.32L509.83,76.55L505.98,68.29L500.18,66.42z"
        />
        <path
          id="CU-08"
          title="Ciego de Ávila"
          className="land"
          d="M459.3,202.39L453.71,200.24L456.24,202.87L459.3,202.39zM446.35,194.91L443.53,195.43L452.19,198.67L446.35,194.91zM442.17,193.02L443.02,190.73L435,188.85L442.17,193.02zM427.44,180.09L424.31,180.26L434.45,188.85L427.44,180.09zM416.37,171.77L412.16,170.97L419.27,174.81L416.37,171.77zM446.27,69.49L437.36,92L443.81,101.06L431.87,121.23L443.85,133.18L458.68,129.92L467.37,138.4L492.68,115.8L501.24,119.69L496.17,107.24L508.73,94.71L446.27,69.49zM460.93,58.07L458.02,58.07L458.18,59.9L460.93,58.07zM488.77,57.96L463.34,58.08L493.98,66.29L488.77,57.96z"
        />
        <path
          id="CU-07"
          title="Sancti Spíritus"
          className="land"
          d="M415.64,68.34L410.1,63.6L414.21,82.87L384.25,85.94L384.3,101.19L365.23,106.21L359.54,115.27L422.46,136.96L443.85,133.18L431.87,121.23L443.81,101.06L437.36,92L446.27,69.49L415.64,68.34zM425.64,51.44L427.82,49.82L424.3,50.22L425.64,51.44zM443.76,49.47L442.63,47.91L434.45,47.65L443.76,49.47z"
        />
        <path
          id="CU-01"
          title="Pinar del Río"
          className="land"
          d="M103.37,104.01L100.44,103.98L105.06,104.4L103.37,104.01zM143.05,20.48L134.57,22.03L132.83,26.95L128.63,21.19L116.28,31.21L105.82,31.2L72.64,46.43L67.25,44.33L38.53,84.96L50.14,99.66L32.94,97.48L1.47,112.44L34.8,106.59L33.9,119.88L69.29,107.7L76.97,87.64L114.65,86.47L158.31,52.55L146.97,36.81L149.45,22.54L143.05,20.48z"
        />
        <path
          id="CU-99"
          title="Isla de la Juventud"
          className="land"
          d="M212.7,135.67L221.18,129.97L210.79,135.5L212.7,135.67zM228.83,130.01L231.13,127.88L223.98,129.81L228.83,130.01zM262.27,127.75L265.66,124.02L251.69,132.91L262.27,127.75zM155.76,106.6L139.79,113.8L148.37,136.16L131.18,130.73L139.55,143.53L179.28,133.5L168.24,110.73L155.76,106.6zM174.69,107.53L171.56,104.94L169.87,105.37L174.69,107.53z"
        />
        <path
          id="CU-06"
          title="Cienfuegos"
          className="land"
          d="M336.18,56.08L328.61,58.04L297.33,72.99L323.16,96.99L332.22,98.03L331.21,86L359.54,115.27L365.23,106.21L348.14,55.35L336.18,56.08z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Cuba))
