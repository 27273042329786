import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import '../styles/scss/App.scss'
import SEO from '../../src/components/SEO'
import PageHeader from '../../src/components/PageHeader'
import { translateFrom, common } from '../lib/languageUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import AnnyangNotSupportedMenuButton from '../components/AnnyangNotSupportedMenuButton'
import NavigationButtons from '../../src/components/NavigationButtons'
import { createBorderRadius } from '../lib/methodsUtil'
import { changePathAction } from '../redux/actions/actions'

const navigationCommands = ['questions', 'about', 'menu']

const translate = translateFrom('contact_page')

const makeOptions = arr =>
  arr.map((option, i) => (
    <option value={option} key={i}>
      {option}
    </option>
  ))

class ContactPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      topic: '',
      message: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClearForm = this.handleClearForm.bind(this)
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDropdownChange(e) {
    this.setState({ topic: `Mini Einstein - ${e.target.value}` })
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    event.preventDefault()

    const formData = new FormData(event.target)
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => dispatch(changePathAction('/contact-success')))
      .catch(() => {
        dispatch(changePathAction('/contact-error'))
      })
  }

  handleClearForm() {
    this.setState({
      name: '',
      email: '',
      topic: '',
      message: ''
    })
  }

  render() {
    const { path, dispatch, lngCode } = this.props
    const { name, email, topic, message } = this.state

    const isValidEmail = !!email && email.indexOf('@') > 0 && email.indexOf('.') > -1
    const disableSubmit = !name || !isValidEmail || topic === '' || !message
    const disableClear = !name && !email && topic === '' && !message
    const optionsList = [1, 2, 3].map(n => translate(`option_${n}`))

    const customNavigationCommands = {}
    navigationCommands.forEach(command => {
      customNavigationCommands[common(command).toLowerCase()] = `/${command.replace(/_/g, '-')}`
    })

    return (
      <div className="contact-page top-navigation">
        <SEO description="E=MC² - Everyone May Connect²" path={path} />

        <div className="navigation-box" style={createBorderRadius(0.38)}>
          <PageHeader title={translate('title')} intro={translate('intro')} />

          <div className="contact-page-note">{translate('notice')}</div>

          <NavigationButtons
            customNavigationCommands={customNavigationCommands}
            lngCode={lngCode}
            dispatch={dispatch}
          />

          <hr />

          <form className="contact-page-form" name="contact" onSubmit={this.handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="contact-page-form-fields">
              <div className="contact-page-form-column">
                <div className="contact-page-input-title">{translate('name')}:</div>
                <input
                  name="name"
                  type="text"
                  onChange={this.handleInputChange}
                  value={name}
                  style={createBorderRadius(0.1)}
                />

                <div className="contact-page-input-title">{translate('email')}:</div>
                <input
                  name="email"
                  type="email"
                  onChange={this.handleInputChange}
                  value={email}
                  style={createBorderRadius(0.4, true)}
                />

                <div className="contact-page-input-title">{translate('topic')}:</div>
                <select
                  name="topic"
                  onChange={this.handleDropdownChange}
                  style={createBorderRadius(0.9, true)}
                  // The below removes the value of `Mini Einstein - ` from the dropdown
                  value={topic !== '' ? topic.substring(18) : topic}>
                  <option value="" disabled>
                    {translate('topic_select')}
                  </option>
                  {makeOptions(optionsList)}
                </select>
              </div>

              <div className="contact-page-form-column">
                <div className="contact-page-input-title">{translate('message')}:</div>
                <textarea
                  onChange={this.handleInputChange}
                  value={message}
                  name="message"
                  // style={createBorderRadius(0.001)}
                  style={{ borderRadius: '8px 25px / 51px 242px' }}
                />
              </div>
            </div>

            <div className="contact-page-form-buttons">
              <button
                type="submit"
                disabled={disableSubmit}
                className={disableSubmit ? 'disabled' : ''}
                style={createBorderRadius(0.9, true)}>
                {translate('send')}
              </button>
              <button
                onClick={this.handleClearForm}
                disabled={disableClear}
                className={disableClear ? 'disabled' : ''}
                style={createBorderRadius(0.1)}>
                {translate('clear')}
              </button>
            </div>
          </form>

          <AnnyangNotSupportedMenuButton />

          {path !== '/contact' && <Navigate to={path} replace />}
        </div>
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(ContactPage))
