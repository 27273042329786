import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './redux/store'
import './i18n'
import AppRouter from './router/AppRouter'
import './styles/scss/index.scss'
import { initializeAmplitude } from './lib/amplitudeUtil'

const root = createRoot(document.getElementById('root'))

initializeAmplitude()

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
)
