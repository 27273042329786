import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 632
const height = 713

const cities = {
  'La Paz': { x: 62, y: 338 }
}

const Bolivia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map bolivia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-10 -10 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="BO-B"
          title="El Beni"
          className="land"
          d="M144.82,309.4L130.84,295.27L128.41,293.8L127.5,286.83L120.47,274.88L119.49,273.89L115.84,273.85L113.09,271.91L112.06,267.47L113.04,262.67L112.66,260.24L107.92,255.43L106.48,250.99L106.6,244.96L105.66,237.15L107.57,231.87L106.78,221.65L108.8,209.69L110.23,206.03L111.82,205.28L116.35,199.07L116.22,197.88L114.82,197.09L113.7,191.06L114.16,189.54L117.33,184.84L119.54,183.65L126.23,175.25L131.29,171.18L137.09,150.65L136.31,146.86L136.84,138.74L137.46,137.94L137.46,137.94L138.95,135.71L138.27,132.59L140.62,121.31L137.46,113.63L138.3,105.55L138.95,104.44L145.81,102.23L148.34,100.05L147.92,96.83L149.06,91.53L150.09,89.96L153.51,89.2L161.16,89.28L169.32,84.47L170.69,82.25L170.57,80.9L180.38,65.61L181.97,60.6L179.36,59.77L183.22,56.76L187.72,57.36L196.25,51.42L201.62,45.21L208.14,41.24L211.76,36.86L214.86,36.35L214.86,36.35L214.53,38.83L212.53,40.42L212.73,47.83L215.15,54.82L218.89,61.19L219.56,66.54L214.55,75.94L215.12,81.3L219.17,92.52L223.58,93.78L226.28,98.69L224.27,103.65L225.15,105.71L227.28,106.74L228.85,103L231.68,105.86L233.97,116.87L235.61,118.98L244.43,119.88L245.36,120.54L248.01,126.1L254.11,129.22L259.21,130.82L260.19,137.19L264,141.03L266.44,142.42L276.36,143.31L283.26,144.97L284.25,145.95L288.89,146.48L291.78,143.73L295.47,142L301.8,142.54L311.91,147.66L314.73,151.95L319.3,154.53L323.23,154.62L328.96,151.02L332.3,152.26L333.21,156.76L335.98,161.48L340.9,164.61L347.3,170.04L351.41,168.12L353.55,170.12L353.57,171.4L366.72,177.24L372.58,176.99L375.34,175.96L380.39,177.65L381.81,183.7L385.99,188.38L392.47,193.11L394.17,197.48L395.7,197.99L406.62,195.85L406.62,195.85L306.07,253.79L286.81,259.15L288.53,265.36L287.49,276.89L291.97,288.38L301.56,303.1L304.17,305.68L311.16,309.44L316.83,319.71L315.4,320.56L259.93,318.29L250.78,317.04L250.78,317.04L225.35,320.32L220.88,329.3L221.35,331.25L218.82,338.89L216.96,341.82L212.51,344.13L204.87,346.51L194.29,349.41L190.67,349.47L179.7,346.5L172.29,341.63L162.38,330.67L160.94,328.65L159.31,324.03L156.99,321.11z"
        />
        <path
          id="BO-C"
          title="Cochabamba"
          className="land"
          d="M152.87,429.43L148.91,420.43L143.83,413.68L134.46,406.27L134.46,406.27L142.43,402.08L139.35,386.53L132.66,376.51L136.63,368.72L142.83,368.17L145.52,366.69L146.54,364.81L147.65,357.56L147.79,355.39L146.2,352.42L144.69,340.7L139.98,331.76L138.33,329.93L138.08,316.29L140.74,312.52L144.82,309.4L144.82,309.4L144.82,309.4L144.82,309.4L156.99,321.11L159.31,324.03L160.94,328.65L162.38,330.67L172.29,341.63L179.7,346.5L190.67,349.47L194.29,349.41L204.87,346.51L212.51,344.13L216.96,341.82L218.82,338.89L221.35,331.25L220.88,329.3L225.35,320.32L250.78,317.04L250.78,317.04L249.62,320.49L249.95,324.88L251.98,333.27L253.02,344.36L249.99,352.96L245.67,361.85L245.86,366.28L250.1,375.48L268.14,387.63L274.38,395.64L274.15,399.38L267.99,399.41L265,401.67L264.23,405.72L256.13,417.49L249.81,423.43L249.41,424.59L249.55,426.37L253.2,430.41L261.01,443.12L263.75,451.87L269.92,456.27L270.5,461.36L267.93,465.04L267.93,465.04L266.3,462.14L264.24,462.25L262.31,464.32L257.32,465.41L253.62,463.4L250.34,458.2L244.5,456.07L241.17,456.01L233.66,460.8L231.89,462.82L232.24,465.61L222.57,460.6L222.02,458.1L217.29,452.23L217.29,452.23L210.73,447.12L199.17,433.96L190.57,428.6L187.81,429.22L182.42,422.55L179.44,422.14L175.09,423.06L167.12,429.19z"
        />
        <path
          id="BO-H"
          title="Chuquisaca"
          className="land"
          d="M222.89,618.34L212.17,608.37L210.7,603.84L211.1,593.98L212.66,589.25L213.31,581.38L219.88,564.47L216.31,550.17L216.15,547.1L218.64,538.27L223.29,535.46L232.92,533.97L240.03,530.05L245.52,524.67L248.15,520.83L244.81,515.8L243.41,511L243.6,508.68L241.84,504.39L238.98,501.91L235.18,501.59L232.43,502.92L227.5,502.7L219.65,499.1L215.64,496.4L215.15,494.77L208.7,486.96L207.59,483.6L209.66,479.4L209.22,472.95L213.19,469.1L213.45,466.91L212.51,466.05L204.19,464.64L202.95,457.79L200.91,452.9L201.31,448.78L216.13,452.74L217.29,452.23L217.29,452.23L222.02,458.1L222.57,460.6L232.24,465.61L231.89,462.82L233.66,460.8L241.17,456.01L244.5,456.07L250.34,458.2L253.62,463.4L257.32,465.41L262.31,464.32L264.24,462.25L266.3,462.14L267.93,465.04L267.93,465.04L269.2,465.85L269.33,469.35L271.05,473.68L282.86,487.02L284.98,491.69L287.24,492.12L289.3,491.01L290.24,485.88L291.01,484.98L298.3,485.08L296.92,496.23L298.83,522.05L298.91,533.09L300.45,537.29L300.9,541.09L301.65,559.27L302.49,559.97L310.12,560.62L314.59,557.61L321.07,561.32L376.09,561.12L376.09,561.12L372.53,565.98L372.95,589.48L372.95,589.48L286.43,589.33L283.11,586.98L281.74,585.06L276.44,583.64L275.21,595.38L273.52,597.22L266.9,596.73L260.3,594.07L252.66,586.35L249.26,585.74L247.4,588.68L245.92,593.86L243.3,594.41L239.24,591.26L230.89,587L226.75,586.51L225.98,588.08z"
        />
        <path
          id="BO-L"
          title="La Paz"
          className="land"
          d="M144.82,309.4L140.74,312.52L138.08,316.29L138.33,329.93L139.98,331.76L144.69,340.7L146.2,352.42L147.79,355.39L147.65,357.56L146.54,364.81L145.52,366.69L142.83,368.17L136.63,368.72L132.66,376.51L139.35,386.53L142.43,402.08L134.46,406.27L134.46,406.27L128.36,398.64L124.99,398.65L118.7,400.69L95.94,415.64L96.97,420.36L96.06,422.05L94.68,422.73L93.02,422.47L82.66,414.74L75.81,411.7L66.6,411.11L60.37,411.7L53.88,415.04L48.08,422.17L42.19,426.87L29.31,431.88L29.31,431.88L18.41,428.8L17.19,426.04L17.02,420.67L15.16,416.48L8.52,410.56L7.82,397.21L3.3,392.82L0,392.39L1.88,388.59L6.29,384.14L13.58,380.1L16.92,373.46L23.41,365.7L32.47,359.36L31.01,353.65L31.37,349.75L33.56,346.96L41.5,343.53L41.96,342.08L41.24,340.62L38.49,339.85L34.7,335.96L30.84,335.89L29.53,336.92L26.03,336.65L22.31,333.25L11.86,305.34L19.56,290.02L23.21,286.63L25.88,286.25L26.18,284.86L18.22,277.16L13.86,271.21L14.7,262.7L17.43,260.96L20.54,256.84L21.22,251.73L33.89,240.82L33.1,238.64L32.97,234.13L33.78,233.25L40.26,231.62L38.21,222.92L34.35,219.53L32.38,210.49L29.93,206.19L34.14,198.89L35.05,195.83L34.44,162.82L39.17,157.27L42.4,156.02L46.02,148.4L49.54,144.11L49.54,144.11L51.32,143.98L59.43,139.88L61.49,136.58L63,132.05L68.61,128.1L69.05,122.39L70.29,120.02L81.37,116.12L83.68,114.72L84.45,113.35L122.16,130.25L137.46,137.94L137.46,137.94L136.84,138.74L136.31,146.86L137.09,150.65L131.29,171.18L126.23,175.25L119.54,183.65L117.33,184.84L114.16,189.54L113.7,191.06L114.82,197.09L116.22,197.88L116.35,199.07L111.82,205.28L110.23,206.03L108.8,209.69L106.78,221.65L107.57,231.87L105.66,237.15L106.6,244.96L106.48,250.99L107.92,255.43L112.66,260.24L113.04,262.67L112.06,267.47L113.09,271.91L115.84,273.85L119.49,273.89L120.47,274.88L127.5,286.83L128.41,293.8L130.84,295.27z"
        />
        <path
          id="BO-N"
          title="Pando"
          className="land"
          d="M49.54,144.11L36.86,118.32L4.43,64.78L12.3,64.1L29.25,65.6L40.71,68.1L45.1,67.44L44.86,74.28L48.19,74.15L54.45,72.54L57.17,70.3L69.36,66.24L77.24,54.99L78.38,52.24L80.44,50.77L82.64,49.94L90.29,49.77L92.73,50.2L96.52,52.63L98.49,51.7L99.92,47.89L104.58,42L111.29,38.8L117.48,34.59L117.65,32.78L122.59,32.19L124.72,32.79L130.08,29.99L139.32,20.97L146.05,15.85L151.14,13.7L152.59,11.52L160.22,10.3L162.35,10.65L180.48,4.9L197.21,4.64L204.26,7.92L206.11,7.93L208.81,4.82L208.99,3.16L212.52,0L215.81,1.23L219.6,8.29L217.85,13.62L218.16,18.55L219.5,21.45L220.24,27.4L219.11,31.01L215.75,34.38L214.86,36.35L214.86,36.35L211.76,36.86L208.14,41.24L201.62,45.21L196.25,51.42L187.72,57.36L183.22,56.76L179.36,59.77L181.97,60.6L180.38,65.61L170.57,80.9L170.69,82.25L169.32,84.47L161.16,89.28L153.51,89.2L150.09,89.96L149.06,91.53L147.92,96.83L148.34,100.05L145.81,102.23L138.95,104.44L138.3,105.55L137.46,113.63L140.62,121.31L138.27,132.59L138.95,135.71L137.46,137.94L137.46,137.94L122.16,130.25L84.45,113.35L83.68,114.72L81.37,116.12L70.29,120.02L69.05,122.39L68.61,128.1L63,132.05L61.49,136.58L59.43,139.88L51.32,143.98z"
        />
        <path
          id="BO-O"
          title="Oruro"
          className="land"
          d="M29.31,431.88L42.19,426.87L48.08,422.17L53.88,415.04L60.37,411.7L66.6,411.11L75.81,411.7L82.66,414.74L93.02,422.47L94.68,422.73L96.06,422.05L96.97,420.36L95.94,415.64L118.7,400.69L124.99,398.65L128.36,398.64L134.46,406.27L134.46,406.27L143.83,413.68L148.91,420.43L152.87,429.43L152.87,429.43L152.14,430.96L147.01,433.24L146.51,437.85L146.79,440.14L147.76,441.41L150.8,442.65L153.85,442.19L157.78,443.12L157.34,444.31L150.17,448.52L149.5,455.7L151.75,460.79L157.87,466.27L163.57,468.46L169.11,469.47L170.51,470.61L174.35,481.27L182.22,491.12L182.39,492.2L181.52,493.38L162.38,495.18L158.36,497.5L149.26,505.47L97.35,527.59L54.65,512.84L54.65,512.84L61.15,505.77L58.73,502.33L55.73,499.69L51.14,497.71L44.06,490.27L38.14,486.18L34.82,480.26L36.48,476.53L33.07,469.13L31.86,464.66L31.91,455.4L29.33,450.58L28.51,441.92L25.58,437.83L26.05,436.48L28.94,434.47z"
        />
        <path
          id="BO-P"
          title="Potosí"
          className="land"
          d="M222.89,618.34L222.07,623.57L220.23,625.83L218.26,632.35L224.4,640.78L226.11,648.22L225.23,648.47L225.23,648.47L197,649.3L193.83,647.21L188.12,639.74L180.48,634.26L173.14,631.49L168.91,647.51L166.97,649.66L153.3,653.73L149.65,653.96L147.24,655.66L145.32,666.47L132.82,671.73L133.32,677.72L127.68,682.36L124.7,687.91L104.75,692.24L93.95,690.98L89.76,688.26L89.37,681.99L91.1,673.41L86.92,659.6L87.43,656.29L86.33,649.01L84.9,646.61L79.23,641.27L78.22,629.58L76.53,625.36L74.14,622.27L74.01,605.42L62.14,586.17L58.02,586.11L55.28,583.63L55.03,575.17L56.85,572.7L59.71,570.72L59.97,569.64L55.82,566.26L48.68,562.92L45.59,558.58L45.46,555.88L48.04,553.98L47.16,548.63L43.95,542.04L44.33,540.69L50.72,538.95L54.39,538.79L57.13,531.47L54.93,527.07L48.74,522.21L48.87,520.14L54.65,512.84L54.65,512.84L97.35,527.59L149.26,505.47L158.36,497.5L162.38,495.18L181.52,493.38L182.39,492.2L182.22,491.12L174.35,481.27L170.51,470.61L169.11,469.47L163.57,468.46L157.87,466.27L151.75,460.79L149.5,455.7L150.17,448.52L157.34,444.31L157.78,443.12L153.85,442.19L150.8,442.65L147.76,441.41L146.79,440.14L146.51,437.85L147.01,433.24L152.14,430.96L152.87,429.43L152.87,429.43L167.12,429.19L175.09,423.06L179.44,422.14L182.42,422.55L187.81,429.22L190.57,428.6L199.17,433.96L210.73,447.12L217.29,452.23L217.29,452.23L216.13,452.74L201.31,448.78L200.91,452.9L202.95,457.79L204.19,464.64L212.51,466.05L213.45,466.91L213.19,469.1L209.22,472.95L209.66,479.4L207.59,483.6L208.7,486.96L215.15,494.77L215.64,496.4L219.65,499.1L227.5,502.7L232.43,502.92L235.18,501.59L238.98,501.91L241.84,504.39L243.6,508.68L243.41,511L244.81,515.8L248.15,520.83L245.52,524.67L240.03,530.05L232.92,533.97L223.29,535.46L218.64,538.27L216.15,547.1L216.31,550.17L219.88,564.47L213.31,581.38L212.66,589.25L211.1,593.98L210.7,603.84L212.17,608.37z"
        />
        <path
          id="BO-S"
          title="Santa Cruz"
          className="land"
          d="M532.58,501.26L487.9,498.5L456.05,507.12L399.11,517.01L390.5,540.2L376.09,561.12L376.09,561.12L321.07,561.32L314.59,557.61L310.12,560.62L302.49,559.97L301.65,559.27L300.9,541.09L300.45,537.29L298.91,533.09L298.83,522.05L296.92,496.23L298.3,485.08L291.01,484.98L290.24,485.88L289.3,491.01L287.24,492.12L284.98,491.69L282.86,487.02L271.05,473.68L269.33,469.35L269.2,465.85L267.93,465.04L267.93,465.04L270.5,461.36L269.92,456.27L263.75,451.87L261.01,443.12L253.2,430.41L249.55,426.37L249.41,424.59L249.81,423.43L256.13,417.49L264.23,405.72L265,401.67L267.99,399.41L274.15,399.38L274.38,395.64L268.14,387.63L250.1,375.48L245.86,366.28L245.67,361.85L249.99,352.96L253.02,344.36L251.98,333.27L249.95,324.88L249.62,320.49L250.78,317.04L250.78,317.04L259.93,318.29L315.4,320.56L316.83,319.71L311.16,309.44L304.17,305.68L301.56,303.1L291.97,288.38L287.49,276.89L288.53,265.36L286.81,259.15L306.07,253.79L406.62,195.85L406.62,195.85L407.43,196.82L411.7,198.23L434.59,195.12L438.68,199.75L447.06,204.73L449.45,204.88L452.91,207.78L462.94,211.13L467.59,220.62L465.51,224.74L463.54,226.53L462.68,229.95L464.26,236.32L472.81,253.51L473.93,277.9L458.17,278.1L475.52,297.9L478.9,338.78L565.7,342.26L566.92,341.75L568.24,339.81L571.33,339.62L571.49,345.73L570.38,351.47L566.7,355L563.85,360.29L564.48,372.12L566.44,377.84L567.2,387.32L568.16,390.94L569.13,391.96L575.24,394.44L581.77,398.99L583.23,401.23L587.01,403.44L588.68,403.61L592.81,401.57L600.68,408.51L598.97,410.81L599.99,413.4L602.11,415.54L602.03,421.72L612,440.64L609.87,444.38L599.43,477.77L601.82,478.17L602.74,485.06L599.11,485.27L581.74,522.14L595.32,534.62L592.85,537.48L579.84,544.9L581.14,540.32L580.24,526.92z"
        />
        <path
          id="BO-T"
          title="Tarija"
          className="land"
          d="M353.65,656.28L347.54,651.72L346.16,649.59L345.74,644.27L344.91,643.2L288.23,643.39L278.87,665.57L274.01,671.14L272.48,674.11L271.14,678.34L270.06,688.5L269.3,690.03L268.2,690.53L266.95,683.31L262.4,678.25L262.87,674.78L261.96,671.22L258.72,665.49L257.67,657.45L255.37,654.89L250.78,652.84L249.16,653.2L236.36,649.17L235.41,648.26L225.23,648.47L225.23,648.47L226.11,648.22L224.4,640.78L218.26,632.35L220.23,625.83L222.07,623.57L222.89,618.34L222.89,618.34L222.89,618.34L222.89,618.34L225.98,588.08L226.75,586.51L230.89,587L239.24,591.26L243.3,594.41L245.92,593.86L247.4,588.68L249.26,585.74L252.66,586.35L260.3,594.07L266.9,596.73L273.52,597.22L275.21,595.38L276.44,583.64L281.74,585.06L283.11,586.98L286.43,589.33L372.95,589.48L372.95,589.48L373.06,592.52z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Bolivia))
