import React from 'react'
import { getRandomItemFromList } from '../lib/methodsUtil'
import { answerAnimations } from '../lib/animationsUtil'

const ShowClue = ({ clue, color, isRepeat = false, hasAnswers = false }) => {
  const RandomAnimation = getRandomItemFromList(answerAnimations)

  return isRepeat || hasAnswers ? (
    <div className={`show-clue-${color}`}>
      {clue?.replace('Congo D R', 'Democratic Republic of the Congo')}
    </div>
  ) : (
    <RandomAnimation className={`show-clue-${color}`}>
      {clue?.replace('Congo D R', 'Democratic Republic of the Congo')}
    </RandomAnimation>
  )
}

export default ShowClue
