import React from 'react'
import {
  fadeIn,
  fadeOut,
  shake,
  swing,
  jello,
  flip,
  flipInX,
  flipInY,
  flipOutX,
  flipOutY,
  bounceOut,
  bounce,
  bounceIn,
  pulse,
  rollIn,
  rollOut,
  rotateIn,
  rotateOut,
  rubberBand,
  tada,
  hinge,
  wobble,
  bounceInDown,
  bounceInUp,
  bounceOutDown,
  bounceOutUp,
  fadeInUp,
  fadeInDown,
  fadeOutUp,
  fadeOutDown,
  rotateInUpRight,
  rotateOutUpRight,
  rotateInDownRight,
  rotateOutDownRight,
  rotateInUpLeft,
  rotateInDownLeft,
  rotateOutDownLeft,
  slideInLeft,
  slideOutRight,
  slideOutLeft,
  slideInRight
} from 'react-animations'
import styled, { keyframes, css } from 'styled-components'

const createAnimatedComponent = (animation, timing, iterations) => styled.div`
  ${({ duration = 1, delay = 0 }) => css`
    animation: ${animation} ${duration}s ${timing} ${delay}s ${iterations} both;
  `}
`

const FadeIn = createAnimatedComponent(keyframes`${fadeIn}`, 'ease-in-out', 1)
const FadeOut = createAnimatedComponent(keyframes`${fadeOut}`, 'ease-out', 1)
const Shake = createAnimatedComponent(keyframes`${shake}`, 'ease-in-out')
const Swing = createAnimatedComponent(keyframes`${swing}`, 'ease-in-out')
const Jello = createAnimatedComponent(keyframes`${jello}`, 'ease-in-out')
const Flip = createAnimatedComponent(keyframes`${flip}`, 'ease-in-out', 1)
const FlipInX = createAnimatedComponent(keyframes`${flipInX}`, 'ease-in-out', 1)
const FlipInY = createAnimatedComponent(keyframes`${flipInY}`, 'ease-in-out', 1)
const FlipOutX = createAnimatedComponent(keyframes`${flipOutX}`, 'ease-in-out', 1)
const FlipOutY = createAnimatedComponent(keyframes`${flipOutY}`, 'ease-in-out', 1)
const BounceOut = createAnimatedComponent(keyframes`${bounceOut}`, 'ease-in-out', 1)
const Bounce = createAnimatedComponent(keyframes`${bounce}`, 'ease-in-out', 1)
const BounceIn = createAnimatedComponent(keyframes`${bounceIn}`, 'ease-in-out', 1)
const Pulse = createAnimatedComponent(keyframes`${pulse}`, 'ease-in-out', 1)
const RollIn = createAnimatedComponent(keyframes`${rollIn}`, 'ease-in-out', 1)
const RollOut = createAnimatedComponent(keyframes`${rollOut}`, 'ease-in-out', 1)
const RotateIn = createAnimatedComponent(keyframes`${rotateIn}`, 'ease-in-out', 1)
const RotateOut = createAnimatedComponent(keyframes`${rotateOut}`, 'ease-in-out', 1)
const RubberBand = createAnimatedComponent(keyframes`${rubberBand}`, 'ease-in-out', 1)
const Tada = createAnimatedComponent(keyframes`${tada}`, 'ease-in-out', 1)
const Hinge = createAnimatedComponent(keyframes`${hinge}`, 'ease-in-out', 1)
const Wobble = createAnimatedComponent(keyframes`${wobble}`, 'ease-in-out', 1)
const BounceInDown = createAnimatedComponent(keyframes`${bounceInDown}`, 'ease-in-out', 1)
const BounceInUp = createAnimatedComponent(keyframes`${bounceInUp}`, 'ease-in-out', 1)
const BounceOutDown = createAnimatedComponent(keyframes`${bounceOutDown}`, 'ease-in-out', 1)
const BounceOutUp = createAnimatedComponent(keyframes`${bounceOutUp}`, 'ease-in-out', 1)
const FadeInUp = createAnimatedComponent(keyframes`${fadeInUp}`, 'ease-in-out', 1)
const FadeInDown = createAnimatedComponent(keyframes`${fadeInDown}`, 'ease-in-out', 1)
const FadeOutUp = createAnimatedComponent(keyframes`${fadeOutUp}`, 'ease-in-out', 1)
const FadeOutDown = createAnimatedComponent(keyframes`${fadeOutDown}`, 'ease-in-out', 1)
const RotateInUpRight = createAnimatedComponent(keyframes`${rotateInUpRight}`, 'ease-in-out', 1)
const RotateOutUpRight = createAnimatedComponent(keyframes`${rotateOutUpRight}`, 'ease-in-out', 1)
const RotateInUpLeft = createAnimatedComponent(keyframes`${rotateInUpLeft}`, 'ease-in-out', 1)
const RotateInDownRight = createAnimatedComponent(keyframes`${rotateInDownRight}`, 'ease-in-out', 1)
const RotateInDownLeft = createAnimatedComponent(keyframes`${rotateInDownLeft}`, 'ease-in-out', 1)
const RotateOutDownLeft = createAnimatedComponent(keyframes`${rotateOutDownLeft}`, 'ease-in-out', 1)
const RotateOutDownRight = createAnimatedComponent(
  keyframes`${rotateOutDownRight}`,
  'ease-in-out',
  1
)
const SlideInLeft = createAnimatedComponent(keyframes`${slideInLeft}`, 'ease-in-out', 1)
const SlideOutRight = createAnimatedComponent(keyframes`${slideOutRight}`, 'ease-in-out', 1)
const SlideOutLeft = createAnimatedComponent(keyframes`${slideOutLeft}`, 'ease-in-out', 1)
const SlideInRight = createAnimatedComponent(keyframes`${slideInRight}`, 'ease-in-out', 1)

const DrawBarAnim = ({ number, classname }) => (
  <div className={`math-bar math-bar-${number} draw-bar-anim-${number} ${classname}`}></div>
)

const createDrawCircleAnim = (number, dPath) => (
  <svg className={`math-circle math-circle-${number}`} viewBox="0 0 500 500">
    <path fill="transparent" stroke="red" strokeWidth="40" d={dPath} />
  </svg>
)

const drawCircleAnimOne = createDrawCircleAnim(
  'one',
  'M223,250 c65,-242 177,-200 152,-75 c-32,273 -126,254 -116,24 l22,-124'
)

const drawCircleAnimTwo = createDrawCircleAnim(
  'two',
  'M209,178 c-73,-106 196,-104 142,165 q-86,134 -165,-187'
)

const drawCircleAnimThree = createDrawCircleAnim(
  'three',
  'M256,144 c55,-139 126,-83 43,215 q-84,141 -51,-257'
)

const drawCircleAnimFour = createDrawCircleAnim(
  'four',
  'M186,171 c-39,-150 258,-101 181,207 q-79,102 -146,-125'
)

const drawCircleAnimFive = createDrawCircleAnim(
  'five',
  'M166,309 c-148,-248 34,-279 84,-112 q69,292 -165,16'
)

const drawCircleAnimSix = createDrawCircleAnim(
  'six',
  'M79,148 c13,-124 329,-96 354,-24 c53,139 -2,206 -142,187 c-79,-27 -154,-63 -166,-151'
)

const drawCircleSixStatic = createDrawCircleAnim(
  'six-static',
  'M79,148 c13,-124 329,-96 354,-24 c53,139 -2,206 -142,187 c-79,-27 -154,-63 -166,-151'
)

const drawCircleAnimSeven = createDrawCircleAnim(
  'seven',
  'M193,318 c13,-317 299,-312 261,-18 c-44,200 -305,198 -300,124'
)

const drawCircleSevenStatic = createDrawCircleAnim(
  'seven-static',
  'M193,318 c13,-317 299,-312 261,-18 c-44,200 -305,198 -300,124'
)

const drawCircleAnimEight = createDrawCircleAnim(
  'eight',
  'M166,183 c18,-179 313,-176 309,71 c10,225 -205,207 -248,-62'
)

const drawCircleEightStatic = createDrawCircleAnim(
  'eight-static',
  'M166,183 c18,-179 313,-176 309,71 c10,225 -205,207 -248,-62'
)

const drawCircleAnimNine = createDrawCircleAnim(
  'nine',
  'M182,124 c53,-93 276,-79 295,68 c10,265 -281,271 -293,-13'
)

const drawCircleNineStatic = createDrawCircleAnim(
  'nine-static',
  'M182,124 c53,-93 276,-79 295,68 c10,265 -281,271 -293,-13'
)

const drawCircleAnimTen = createDrawCircleAnim(
  'ten',
  'M142,244 c-36,-191 206,-231 256,-123 c95,292 -121,372 -247,216'
)

const drawCircleTenStatic = createDrawCircleAnim(
  'ten-static',
  'M142,244 c-36,-191 206,-231 256,-123 c95,292 -121,372 -247,216'
)

const drawCircleAnimEleven = createDrawCircleAnim(
  'eleven',
  'M283,17 c68,5 215,30 192,363 c-7,110 -370,-125 -277,-306'
)

const drawCircleElevenStatic = createDrawCircleAnim(
  'eleven-static',
  'M283,17 c68,5 215,30 192,363 c-7,110 -370,-125 -277,-306'
)

const answerAnimations = [
  FadeIn,
  Shake,
  Jello,
  Flip,
  FlipInX,
  FlipInY,
  Bounce,
  BounceIn,
  Pulse,
  RollIn,
  RotateIn,
  RubberBand,
  Tada,
  Wobble,
  BounceInDown,
  BounceInUp,
  FadeInUp,
  FadeInDown,
  RotateInUpRight,
  RotateInDownRight,
  RotateInUpLeft,
  RotateInDownLeft,
  SlideInLeft,
  SlideInRight
]

const imageInAnimations = [
  FadeIn,
  FlipInX,
  FlipInY,
  BounceIn,
  RollIn,
  RotateIn,
  FadeInUp,
  FadeInDown,
  SlideInLeft,
  SlideInRight
]

const imageAnimations = [
  createAnimatedComponent(keyframes`${shake}`, 'ease-in-out', 'infinite'),
  createAnimatedComponent(keyframes`${swing}`, 'ease-in-out', 'infinite'),
  createAnimatedComponent(keyframes`${bounce}`, 'ease-in-out', 'infinite'),
  createAnimatedComponent(keyframes`${pulse}`, 'ease-in-out', 'infinite'),
  createAnimatedComponent(keyframes`${tada}`, 'ease-in-out', 'infinite'),
  createAnimatedComponent(keyframes`${wobble}`, 'ease-in-out', 'infinite')
]

export {
  FadeIn,
  FadeOut,
  Shake,
  Swing,
  Jello,
  Flip,
  FlipInX,
  FlipInY,
  FlipOutX,
  FlipOutY,
  BounceOut,
  Bounce,
  BounceIn,
  Pulse,
  RollIn,
  RollOut,
  RotateIn,
  RotateOut,
  RubberBand,
  Tada,
  Hinge,
  Wobble,
  BounceInDown,
  BounceInUp,
  BounceOutDown,
  BounceOutUp,
  FadeInUp,
  FadeInDown,
  FadeOutUp,
  FadeOutDown,
  RotateInUpRight,
  RotateOutUpRight,
  RotateInDownRight,
  RotateOutDownRight,
  RotateInUpLeft,
  RotateInDownLeft,
  RotateOutDownLeft,
  SlideInLeft,
  SlideOutRight,
  SlideOutLeft,
  SlideInRight,
  DrawBarAnim,
  drawCircleAnimOne,
  drawCircleAnimTwo,
  drawCircleAnimThree,
  drawCircleAnimFour,
  drawCircleAnimFive,
  drawCircleAnimSix,
  drawCircleAnimSeven,
  drawCircleAnimEight,
  drawCircleAnimNine,
  drawCircleAnimTen,
  drawCircleAnimEleven,
  drawCircleSixStatic,
  drawCircleSevenStatic,
  drawCircleEightStatic,
  drawCircleNineStatic,
  drawCircleTenStatic,
  drawCircleElevenStatic,
  answerAnimations,
  imageInAnimations,
  imageAnimations
}
