import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1600

const cities = {
  Antananarivo: { x: 466, y: 800 }
}

const Magdagascar = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map magdagascar">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="MG-FI-VF"
          title="Vatovavy-Fitovinany"
          className="land"
          d="M592.93,970.91L543.45,947.72L534.24,973.04L501.16,974.56L482.84,987.22L464.95,1037.28L451.18,1161.58L422.27,1177.92L512.3,1227.93L592.93,970.91z"
        />
        <path
          id="MG-AV-VA"
          title="Vakinankaratra"
          className="land"
          d="M511.51,874.37L477.34,853.91L480.09,823.28L467.7,823.28L452.56,842.23L405.75,832.03L368.58,853.91L323.15,856.83L284.28,857.8L281.68,908.04L323.31,931.54L331.32,923.33L338.83,945.85L365.22,953.41L401.06,952.07L442.67,927.57L494.2,930.55L508.71,897.86L511.51,874.37z"
        />
        <path
          id="MG-MA-SF"
          title="Sofia"
          className="land"
          d="M645.75,254.06L631.89,248.16L616.07,259.72L582.6,240.24L566.57,245.51L545.15,233.49L552.35,216.62L528.62,239.11L524.8,268.81L516.43,241.65L519,258.26L502.23,257.2L492.41,282.51L498.06,300.01L503.66,293.77L517.08,296.15L526.64,303.37L527.06,315.52L521.76,302.47L516.49,312.42L502.92,296.13L462.32,357.66L472.14,324.9L464.2,308.08L421.46,367.45L441.18,392.73L431.54,396.26L436.04,460.75L452.56,493.71L516.07,518.4L522.66,488.48L557.05,487.04L593.96,512.86L600.6,546.51L625.96,537.18L659.18,487.35L635.6,472.11L648.44,397.83L636.89,386.55L674.73,372.95L685.99,341.01L699.49,338.72L678.38,287.84L645.75,254.06z"
        />
        <path
          id="MG-AS-SV"
          title="Sava"
          className="land"
          d="M759.12,450.27L770.28,456.14L782,437.89L799.75,374.11L768.04,317.07L760.69,207.36L738.63,122.71L704.94,82.22L678.33,112.36L687.96,122.25L674.2,159.01L681.08,181.66L671.44,222.76L645.75,254.06L678.38,287.84L699.49,338.72L749.2,365.09L759.12,450.27z"
        />
        <path
          id="MG-TL-ME"
          title="Menabe"
          className="land"
          d="M258.7,722.57L199.63,711.31L175.45,732.44L207.33,833.4L176.79,841.05L115.51,822.16L138.37,861.51L126.4,895.35L137.72,920.15L74.83,1025.3L65.07,1065.5L35.34,1075.54L75.4,1141.73L121.97,1140.32L151.87,1130.06L167.59,1071.22L202,1081.57L258.45,1035.8L282.4,1030.73L281.68,908.04L284.28,857.8L284.36,832.16L250.73,796.58L264.63,752.05L258.7,722.57z"
        />
        <path
          id="MG-MA-ME"
          title="Melaky"
          className="land"
          d="M182.11,483.38L134.51,481.84L132.97,539.66L78.24,629.87L115.51,822.16L176.79,841.05L207.33,833.4L175.45,732.44L199.63,711.31L258.7,722.57L284.34,716.13L302.13,667.43L291.49,623.18L259.82,623.18L250.19,571.3L197.87,519.55L182.11,483.38z"
        />
        <path
          id="MG-AV-IT"
          title="Itasy"
          className="land"
          d="M429.67,774.59L378.22,772.34L323.15,856.83L368.58,853.91L405.75,832.03L452.56,842.23L467.7,823.28L448.97,781.03L429.67,774.59z"
        />
        <path
          id="MG-FI-HO"
          title="Ihorombe"
          className="land"
          d="M414.01,1189.82L364.45,1182.38L349.31,1146.73L330.03,1136.35L252.13,1117.74L221.01,1146.43L230.46,1174.52L196.22,1260.91L205.29,1281.43L234.95,1289.91L241.17,1259.44L304.93,1238.43L356.85,1298.16L358.83,1354.49L397.49,1349.78L396.11,1276.33L437.41,1288.3L414.01,1189.82z"
        />
        <path
          id="MG-FI-HM"
          title="Haute Matsiatra"
          className="land"
          d="M464.95,1037.28L438.79,1025.49L398.87,1044.65L369.96,1009.29L352.06,1009.29L281,1043.21L252.13,1117.74L330.03,1136.35L349.31,1146.73L364.45,1182.38L414.01,1189.82L422.27,1177.92L451.18,1161.58L464.95,1037.28z"
        />
        <path
          id="MG-AS-DI"
          title="Diana"
          className="land"
          d="M560.78,147.32L545.95,148.71L549.89,164.1L565.45,164.12L560.78,147.32zM663.67,0L629.14,60.3L605.43,55.24L630.19,97.67L610.94,162.22L591.69,169.18L578.21,159.58L583.91,177.69L561.84,180.58L554.92,209.87L532.72,177.3L514.69,187.01L513.26,214.89L528.62,239.11L552.35,216.62L545.15,233.49L566.57,245.51L582.6,240.24L616.07,259.72L631.89,248.16L645.75,254.06L671.44,222.76L681.08,181.66L674.2,159.01L687.96,122.25L678.33,112.36L704.94,82.22L694.37,48.85L660.34,31.3L664.09,22.09L676.09,28.61L663.67,0z"
        />
        <path
          id="MG-AV-BO"
          title="Bongolava"
          className="land"
          d="M370.35,659.82L324.76,675.65L302.13,667.43L284.34,716.13L258.7,722.57L264.63,752.05L250.73,796.58L284.36,832.16L284.28,857.8L323.15,856.83L378.22,772.34L429.67,774.59L383.47,704.81L370.35,659.82z"
        />
        <path
          id="MG-MA-BO"
          title="Boeny"
          className="land"
          d="M431.54,396.26L410.73,408.02L423.53,382.3L409.77,368L341.99,417.21L338.28,438.4L356.94,454.51L341.81,456.85L321.28,425.61L307.16,435.72L312.69,443.68L268.16,437.04L262.8,465.12L258.18,452.95L237.23,456.46L228.27,472.26L224.81,450.76L182.11,483.38L197.87,519.55L250.19,571.3L306.63,572.74L331.41,601.55L369.96,568.42L382.85,529.23L449.8,536.78L436.04,512.37L452.56,493.71L436.04,460.75L431.54,396.26z"
        />
        <path
          id="MG-MA-BE"
          title="Betsiboka"
          className="land"
          d="M516.07,518.4L452.56,493.71L436.04,512.37L449.8,536.78L382.85,529.23L369.96,568.42L331.41,601.55L306.63,572.74L250.19,571.3L259.82,623.18L291.49,623.18L302.13,667.43L324.76,675.65L370.35,659.82L403.35,657.25L420.32,672.2L447.84,660.84L482.42,683.21L514.53,677.77L547.64,592.88L516.07,518.4z"
        />
        <path
          id="MG-TM-AI"
          title="Atsinanana"
          className="land"
          d="M592.93,970.91L683.54,707.12L687.3,633.18L671.44,634.73L667.31,652.06L621.88,660.73L597.1,675.19L615,707.04L587.47,718.63L583.34,734.58L598.48,823.28L568.2,849.53L558.56,897.75L532.4,888.97L508.71,897.86L494.2,930.55L490.4,963.23L501.16,974.56L534.24,973.04L543.45,947.72L592.93,970.91z"
        />
        <path
          id="MG-FI-AA"
          title="Atsimo-Atsinanana"
          className="land"
          d="M512.3,1227.93L422.27,1177.92L414.01,1189.82L437.41,1288.3L396.11,1276.33L397.49,1349.78L358.83,1354.49L351.19,1373.26L400.2,1410.25L404.56,1430.05L428.75,1393.84L462.36,1410.98L512.3,1227.93z"
        />
        <path
          id="MG-TL-AF"
          title="Atsimo-Andrefana"
          className="land"
          d="M281,1043.21L282.4,1030.73L258.45,1035.8L202,1081.57L167.59,1071.22L151.87,1130.06L121.97,1140.32L75.4,1141.73L35.34,1075.54L0.25,1187.41L15.45,1257.42L59.41,1331.6L44.41,1367.35L49.7,1436.3L76.67,1470.61L88.81,1516.6L130.15,1539.56L118.22,1535.35L126.25,1547.9L165.62,1553.59L205.45,1503L220.59,1395.63L248.12,1382.07L246.75,1345.98L226.1,1347.48L234.95,1289.91L205.29,1281.43L196.22,1260.91L230.46,1174.52L221.01,1146.43L252.13,1117.74L281,1043.21z"
        />
        <path
          id="MG-TL-AY"
          title="Anosy"
          className="land"
          d="M358.83,1354.49L356.85,1298.16L304.93,1238.43L241.17,1259.44L234.95,1289.91L226.1,1347.48L246.75,1345.98L248.12,1382.07L252.48,1397.07L288.73,1408.49L299.75,1511.39L331.54,1540.78L390.02,1535.35L429.18,1508.77L462.36,1410.98L428.75,1393.84L404.56,1430.05L400.2,1410.25L351.19,1373.26L358.83,1354.49z"
        />
        <path
          id="MG-TL-AD"
          title="Androy"
          className="land"
          d="M213.41,1589.49L257.21,1584.79L331.54,1540.78L299.75,1511.39L288.73,1408.49L252.48,1397.07L248.12,1382.07L220.59,1395.63L205.45,1503L165.62,1553.59L213.41,1589.49z"
        />
        <path
          id="MG-TM-AN"
          title="Analanjirofo"
          className="land"
          d="M699.49,338.72L685.99,341.01L674.73,372.95L636.89,386.55L648.44,397.83L635.6,472.11L659.18,487.35L625.96,537.18L620.8,617.11L605.36,639.06L621.88,660.73L667.31,652.06L671.44,634.73L687.3,633.18L682.5,606.71L700.56,563.96L727.45,554.26L714.76,545.82L729.95,510.33L728.04,483.72L710.17,465.59L713.98,396.29L734.13,393.51L759.12,450.27L749.2,365.09L699.49,338.72z"
        />
        <path
          id="MG-AV-AG"
          title="Analamanga"
          className="land"
          d="M514.53,677.77L482.42,683.21L447.84,660.84L420.32,672.2L403.35,657.25L370.35,659.82L383.47,704.81L429.67,774.59L448.97,781.03L467.7,823.28L480.09,823.28L477.34,853.91L511.51,874.37L528.91,730.52L514.53,677.77z"
        />
        <path
          id="MG-FI-AM"
          title="Amoron'i Mania"
          className="land"
          d="M494.2,930.55L442.67,927.57L401.06,952.07L365.22,953.41L338.83,945.85L331.32,923.33L323.31,931.54L281.68,908.04L282.4,1030.73L281,1043.21L352.06,1009.29L369.96,1009.29L398.87,1044.65L438.79,1025.49L464.95,1037.28L482.84,987.22L501.16,974.56L490.4,963.23L494.2,930.55z"
        />
        <path
          id="MG-TM-AO"
          title="Alaotra-Mangoro"
          className="land"
          d="M625.96,537.18L600.6,546.51L593.96,512.86L557.05,487.04L522.66,488.48L516.07,518.4L547.64,592.88L514.53,677.77L528.91,730.52L511.51,874.37L508.71,897.86L532.4,888.97L558.56,897.75L568.2,849.53L598.48,823.28L583.34,734.58L587.47,718.63L615,707.04L597.1,675.19L621.88,660.73L605.36,639.06L620.8,617.11L625.96,537.18z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Magdagascar))
