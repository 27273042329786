import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 637

const cities = {
  Niamey: { x: 100, y: 533 }
}

const Niger = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map niger">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="NE-5"
          title="Tahoua"
          className="land"
          d="M329.74,433.86L327.59,393.74L313.84,387.3L301.56,358.11L230.96,300.1L238.29,265.63L206.31,263.87L203.97,381.98L188.3,418.52L207.49,474.16L226.29,522.87L271.55,514.69L310.06,525.75L322.06,505.39L329.74,433.86z"
        />
        <path
          id="NE-8"
          title="Niamey"
          className="land"
          d="M101.14,539.14L107.61,530.65L93.5,528.61L101.14,539.14z"
        />
        <path
          id="NE-3"
          title="Dosso"
          className="land"
          d="M176.88,626.3L176.78,584.84L199.16,560.61L201.03,535.87L226.29,522.87L207.49,474.16L171.05,517.01L152.96,518.98L135.74,546.23L117.92,552.03L130.47,562.76L124.16,568.42L125.55,585.51L136.3,591.52L176.88,626.3z"
        />
        <path
          id="NE-7"
          title="Zinder"
          className="land"
          d="M530.5,541.25L538.96,518.52L559.47,499.49L597.87,485.55L598.67,324.71L463.46,403.86L415.71,425.52L368.69,424.98L362.61,438.78L382.36,484.42L397.34,481.16L422.96,502.71L423.76,513.62L407.62,523.42L412.8,528.8L409.2,549.46L431.06,564.38L477.07,570.71L503.82,547.44L530.5,541.25z"
        />
        <path
          id="NE-4"
          title="Maradi"
          className="land"
          d="M362.61,438.78L329.74,433.86L322.06,505.39L310.06,525.75L343.05,560.65L386.11,542.67L409.2,549.46L412.8,528.8L407.62,523.42L423.76,513.62L422.96,502.71L397.34,481.16L382.36,484.42L362.61,438.78z"
        />
        <path
          id="NE-1"
          title="Agadez"
          className="land"
          d="M597.23,0L370.61,144.31L285.32,220.88L206.22,237.34L206.31,263.87L238.29,265.63L230.96,300.1L301.56,358.11L313.84,387.3L327.59,393.74L329.74,433.86L362.61,438.78L368.69,424.98L415.71,425.52L463.46,403.86L598.67,324.71L649.32,298.23L779.02,298.21L787.53,196.53L799.36,173.25L779.15,150.83L781.13,140.09L759.67,110.8L749.33,28.68L710.75,49.47L673.68,18.58L597.23,0z"
        />
        <path
          id="NE-2"
          title="Diffa"
          className="land"
          d="M779.02,298.21L649.32,298.23L598.67,324.71L597.87,485.55L559.47,499.49L538.96,518.52L530.5,541.25L570.51,541.51L622.68,557.09L641.35,535.03L679.99,523.86L672.02,485.58L682.55,479.86L718.47,417.01L773.86,356.74L779.02,298.21z"
        />
        <path
          id="NE-6"
          title="Tillabéri"
          className="land"
          d="M207.49,474.16L188.3,418.52L169.78,437.76L59.88,441.44L40.57,457.36L3.38,457.05L0.64,482.61L12.4,495.65L15.28,513.17L22.64,524.65L56.7,542.46L42.3,541.3L42.3,558.74L71.61,579.47L99.15,575.67L106.02,590.24L96.26,594.49L113.35,617.5L112.34,599.95L136.3,591.52L125.55,585.51L124.16,568.42L130.47,562.76L117.92,552.03L135.74,546.23L152.96,518.98L171.05,517.01L207.49,474.16zM101.14,539.14L93.5,528.61L107.61,530.65L101.14,539.14z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Niger))
