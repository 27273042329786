import React, { Suspense, Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import '@fontsource/luckiest-guy'
import '@fontsource/orbitron'
import '@fontsource/kalam'
import annyang from './lib/annyangSetUp'
import { translateFrom, common } from './lib/languageUtil'
import readOutLoud from './lib/readOutLoud'
import { baseUrl } from './lib/_processUtil'
import { isSpeakRequest, isSpeakSoundDisabled, createBorderRadius } from './lib/methodsUtil'
import { passDispatchToProps, mapMinimalStateToProps } from './redux/mapToPropsUtil'
import { changePathAction } from './redux/actions/actions'
import SEO from '../src/components/SEO'
import ImageWithDimensions from '../src/components/ImageWithDimensions'
import { wasPathChanged } from '../src/router/routerUtil'

const SpeechButton = React.lazy(() => import('../src/components/SpeechButton'))
const ParentsInfoButton = React.lazy(() => import('../src/components/ParentsInfoButton'))
// const Footer = React.lazy(() => import('../src/components/Footer'))
const AppListeningSpeakingVisual = React.lazy(
  () => import('../src/components/AppListeningSpeakingVisual')
)

const translate = translateFrom('home_page')

const navigationCommands = ['menu']

class Home extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    annyang.bindNavigationCommands(dispatch, navigationCommands)
    annyang.start()
  }

  componentWillUnmount() {
    annyang.abort()
  }

  render() {
    const { isAnnyangSupported, path, dispatch, transcript, language, gender, soundLevel } =
      this.props
    const supportedState = common(
      isAnnyangSupported ? 'menu_command' : 'voice_recognition_not_supported'
    )

    const title = translate('title')
    const slogan = translate('slogan')

    const readOutLoudText = `${title}! ${slogan}! ${supportedState}`
    const isSpeakDisabled = isSpeakSoundDisabled(soundLevel)
    if (isSpeakRequest(transcript) && !isSpeakDisabled) {
      readOutLoud(readOutLoudText, dispatch, language, gender)
    }

    return (
      <div className="home-wrapper">
        <SEO title="Where geniuses are made!" path={path} />
        {!isSpeakDisabled && (
          <Suspense fallback={<div>Loading...</div>}>
            <SpeechButton className="home-speech-button" text={readOutLoudText} />
          </Suspense>
        )}

        <Suspense fallback={<div>Loading...</div>}>
          <AppListeningSpeakingVisual />
        </Suspense>

        <div className="navigation-box home-box" style={createBorderRadius(0.75)}>
          <div className="home-box-header">
            <div className="home-box-header-title">{title}</div>
            <div className="home-box-header-slogan">...{slogan}.</div>
          </div>
          <ImageWithDimensions
            alt="mini Einstein menu"
            className="mini-einstein-menu-picture"
            src={`${baseUrl}/images/mini-einstein-menu-image.webp`}
            // srcSet={`${baseUrl}/images/mini-einstein-menu-image.webp 1x, ${baseUrl}/images/mini-einstein-menu-image.webp 2x, ${baseUrl}/images/mini-einstein-menu-image.webp 3x`}
          />
          <div className="home-box-supported-state">{supportedState}</div>
          <button
            style={createBorderRadius(0.5)}
            className="home-box-button"
            onClick={() => dispatch(changePathAction('/menu'))}>
            {common('menu')}
          </button>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <ParentsInfoButton />
        </Suspense>

        {wasPathChanged(path, 'home_page') && <Navigate to={path} replace />}

        {/* <Suspense fallback={<div>Footer Loading...</div>}>
          <Footer />
        </Suspense> */}
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(Home))
