/* eslint-disable */
const isDevEnvironment = process.env.NODE_ENV === 'development'

const baseUrl = process.env.PUBLIC_URL
/* eslint-enable */

const mathsNumbersUrls = `${baseUrl}/images/mathematics/numbers/`

// it seems to run twice in dev, but only once in Prod so we get previous answer from -3 in dev -2 in prod
const minusIndexNumber = isDevEnvironment ? 3 : 2

// ------------------------------------------------------------------------
// DEVELPOMENT ENVIRONMENT VARIABLES SET-UP
// Read out loud disabled in Dev
const isDevDisableReadOutLoud = isDevEnvironment // Set to false to hear all spoken

// ------------------------------------------------------------------------

export { isDevEnvironment, baseUrl, mathsNumbersUrls, minusIndexNumber, isDevDisableReadOutLoud }
