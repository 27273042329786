import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 800
const height = 500

const cities = {
  Victoria: { x: 740, y: 64 }
}

const Seychelles = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map seychelles">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="SC-25"
          title="Roche Caiman"
          className="land"
          d="M736.17,68.32L734.87,67.41L734.82,68.01L735.96,68.88L736.17,68.32z"
        />
        <path
          id="SC-24"
          title="Les Mamelles"
          className="land"
          d="M735.96,68.88L734.82,68.01L735.01,69.41L735.43,69.18L735.96,68.88z"
        />
        <path
          id="SC-23"
          title="Takamaka"
          className="land"
          d="M736.26,78.26L737.13,79.8L739.8,80.43L738.84,77.75L737.56,77.85L736.26,78.26z"
        />
        <path
          id="SC-22"
          title="Saint Louis"
          className="land"
          d="M732.63,65.88L731.93,65.43L731.37,66.13L731.65,66.65L732.63,65.88z"
        />
        <path
          id="SC-21"
          title="Port Glaud"
          className="land"
          d="M733.56,68.81L732.45,68.26L731.65,66.65L731.37,66.13L729.57,67.52L727.33,66.4L726.15,67.63L728.88,68.44L731.68,70.31L732.76,70.42L733.56,68.81z"
        />
        <path
          id="SC-20"
          title="Pointe La Rue"
          className="land"
          d="M739.06,70.61L737.88,69.49L737.3,70.38L739.06,70.61z"
        />
        <path
          id="SC-19"
          title="Plaisance"
          className="land"
          d="M734.87,67.41L734.56,67.01L733.98,67.98L733.56,68.81L735.01,69.41L734.82,68.01L734.87,67.41z"
        />
        <path
          id="SC-18"
          title="Mont Fleuri"
          className="land"
          d="M733.98,67.98L734.56,67.01L733.67,66.27L733.98,67.98z"
        />
        <path
          id="SC-17"
          title="Mont Buxton"
          className="land"
          d="M732.63,65.88L732.1,64.59L731.93,65.43L732.63,65.88z"
        />
        <path
          id="SC-16"
          title="English River"
          className="land"
          d="M733.62,64.12L732.1,64.59L732.63,65.88L733.54,65.67L733.62,64.12z"
        />
        <path
          id="SC-15"
          title="La Digue and Inner Islands"
          className="land"
          d="M717.81,56.3L718.52,54.71L717.78,53.35L715.05,51.91L714.74,53.39L715.26,55.3L716.36,56.61L717.81,56.3zM764.05,46.3L765.08,45.56L764.05,43.79L763.08,43.38L762.58,46.13L764.05,46.3zM749.44,0L748.82,0.8L749.93,1.64L749.44,0z"
        />
        <path
          id="SC-14"
          title="Grand'Anse Praslin"
          className="land"
          d="M758.02,44.4L755.89,42.72L751.28,39.96L750.83,40.12L752,42.49L754.03,42.8L755.23,44.55L757.32,45.04L758.02,44.4z"
        />
        <path
          id="SC-13"
          title="Grand'Anse"
          className="land"
          d="M735.01,69.41L733.56,68.81L732.76,70.42L734.28,72.62L735.05,72.69L736.42,70.42L735.43,69.18L735.01,69.41z"
        />
        <path
          id="SC-12"
          title="Glacis"
          className="land"
          d="M733.93,61.96L731.55,61.26L731.47,63.2L732.1,63.2L732.59,62.19L733.93,61.96z"
        />
        <path
          id="SC-11"
          title="Cascade"
          className="land"
          d="M737.88,69.49L736.17,68.32L735.96,68.88L735.43,69.18L736.42,70.42L736.81,70.56L737.11,70.66L737.3,70.38L737.88,69.49z"
        />
        <path
          id="SC-10"
          title="Bel Ombre"
          className="land"
          d="M731.37,66.13L730.02,65.25L727.33,66.4L729.57,67.52L731.37,66.13z"
        />
        <path
          id="SC-09"
          title="Bel Air"
          className="land"
          d="M733.67,66.27L733.54,65.67L732.63,65.88L731.65,66.65L732.45,68.26L733.56,68.81L733.98,67.98L733.67,66.27z"
        />
        <path
          id="SC-08"
          title="Beau Vallon"
          className="land"
          d="M732.1,63.2L731.47,63.2L730.02,65.25L731.37,66.13L731.93,65.43L732.1,64.59L732.1,63.2z"
        />
        <path
          id="SC-07"
          title="Baie Sainte Anne"
          className="land"
          d="M758.02,44.4L758.25,42.89L759.53,42.83L758.75,41.87L756.95,42.08L756.33,41.31L754.12,40.66L752.99,39.63L751.28,39.96L755.89,42.72L758.02,44.4zM755.27,39.54L756.04,38.68L753.83,38.56L755.27,39.54z"
        />
        <path
          id="SC-06"
          title="Baie Lazare"
          className="land"
          d="M737.2,75.29L736.13,75.07L734.28,75.48L733.91,76.93L735.76,77.12L736.26,78.26L737.56,77.85L737.2,75.29z"
        />
        <path
          id="SC-05"
          title="Anse Royale"
          className="land"
          d="M739.02,75.11L737.43,73.76L737.2,75.29L737.56,77.85L738.84,77.75L738.13,76.19L739.02,75.11z"
        />
        <path
          id="SC-04"
          title="Au Cap"
          className="land"
          d="M737.11,70.66L736.81,70.56L737.43,73.76L739.02,75.11L738.5,72.8L738.88,71.32L737.11,70.66z"
        />
        <path
          id="SC-03"
          title="Anse Etoile"
          className="land"
          d="M733.62,64.12L733.93,61.96L732.59,62.19L732.1,63.2L732.1,64.59L733.62,64.12z"
        />
        <path
          id="SC-02"
          title="Anse Boileau"
          className="land"
          d="M736.81,70.56L736.42,70.42L735.05,72.69L736.13,75.07L737.2,75.29L737.43,73.76L736.81,70.56z"
        />
        <path
          id="SC-01"
          title="Anse aux Pins"
          className="land"
          d="M739.06,70.61L737.3,70.38L737.11,70.66L738.88,71.32L739.06,70.61z"
        />
        <path
          id="SC"
          title="Outer Islands"
          className="land"
          d="M22.4,475.55L24.07,475.19L24.79,473.05L22.98,473.87L22.4,475.55zM25.6,446.19L26.01,445.85L23.66,443.95L19.88,442.35L20.28,444.34L21.54,446.19L18.83,445.6L15.78,447.22L12.26,447.83L9.8,449.26L6.12,449.47L2.67,448.45L0.84,446.19L2.98,451.56L8.16,451.31L14.57,449.33L20.42,449.48L25.6,446.19zM18.24,442.35L9.38,441.45L6.67,442.44L6.9,443.45L9.63,443.41L10.64,442.85L14.46,443.91L16.94,443.61L18.24,442.35zM797.97,267.56L799.16,264.08L797.73,264.86L796.89,266.49L795.96,270.68L797.97,267.56z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Seychelles))
