import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 839

const cities = {
  Monrovia: { x: 146, y: 444 }
}

const Liberia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map liberia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="LR-GD"
          title="Grand Gedeh"
          className="land"
          d="M559.21,408.33L539.54,454.86L513.24,481.42L513.28,498.33L496.42,505.79L503.66,529.81L565.81,563.25L580.34,547.03L600.02,553.51L611.42,576.59L586.39,595.69L595.96,609.42L795.59,600.9L788.41,535.56L779.06,543.37L721.92,513.5L717.41,491.27L707.21,489.25L710.18,466.44L696.94,451.83L599.67,438.4L598.41,416.52L588.78,409.47L582.97,420.43L559.21,408.33z"
        />
        <path
          id="LR-RI"
          title="River Cess"
          className="land"
          d="M467.77,538.32L470.87,495.09L461.09,477.02L472.66,451.19L456.49,435.44L395.43,443.29L394.56,473.99L357.19,498.93L352.84,571.77L341.3,587.59L402.88,648.47L422.79,618.71L414.77,587.24L454.65,561.71L467.77,538.32z"
        />
        <path
          id="LR-NI"
          title="Nimba"
          className="land"
          d="M559.21,408.33L611.41,356.66L624.14,309.7L593.45,202.6L569.42,185.58L569.25,172.79L545.95,171.58L512.46,254.67L493.03,252.02L463.92,268.48L447.9,312.46L463.24,332.51L448.61,382.08L466.84,385.93L456.49,435.44L472.66,451.19L461.09,477.02L470.87,495.09L467.77,538.32L503.66,529.81L496.42,505.79L513.28,498.33L513.24,481.42L539.54,454.86L559.21,408.33z"
        />
        <path
          id="LR-MG"
          title="Margibi"
          className="land"
          d="M338.9,377L343.09,359.55L329.2,349.42L310.91,381.94L219.35,355.35L208.67,384.11L214.12,416.33L169.93,446.52L164.61,461.64L217.36,475.57L246.93,408.7L241.56,400.82L296.7,405.95L320.09,398.74L338.9,377z"
        />
        <path
          id="LR-MO"
          title="Montserrado"
          className="land"
          d="M219.35,355.35L190.16,340.76L179.64,343.66L172.96,366.64L179.3,393.92L170.2,400.19L153.84,391.5L133.08,402.86L131.41,426.14L140.64,425.77L131.16,444.14L164.61,461.64L169.93,446.52L214.12,416.33L208.67,384.11L219.35,355.35z"
        />
        <path
          id="LR-LF"
          title="Lofa"
          className="land"
          d="M389.42,234.92L415.54,185.77L415.15,162.2L396.98,129.45L401.72,106.08L391.93,105.56L390.53,78.72L381,74.61L387.06,43.4L367.31,30.08L352.23,33.9L357.46,19.42L347.15,14.98L343.46,25.18L333.4,0L326.9,11.22L277.07,27.85L271.32,10.46L233.94,15.5L226.37,75.08L185.64,86.71L168.63,106.81L244.77,131.64L249.85,124.33L268,149.98L299.21,142.66L348.58,163.17L321.72,243.73L325.64,266.75L351.13,267.97L389.42,234.92z"
        />
        <path
          id="LR-CM"
          title="Grand Cape Mount"
          className="land"
          d="M105.51,309.83L188.53,247.29L172.68,213.06L120.18,201.3L31.57,266.39L0.48,324.35L18.91,340.61L24.53,367.33L78.52,388.71L98.73,361.99L105.51,309.83z"
        />
        <path
          id="LR-GB"
          title="Grand Bassa"
          className="land"
          d="M448.61,382.08L420.14,384.16L397.28,405.28L359.05,419.11L370.5,393.55L338.9,377L320.09,398.74L296.7,405.95L241.56,400.82L246.93,408.7L217.36,475.57L341.3,587.59L352.84,571.77L357.19,498.93L394.56,473.99L395.43,443.29L456.49,435.44L466.84,385.93L448.61,382.08z"
        />
        <path
          id="LR-BG"
          title="Bong"
          className="land"
          d="M463.92,268.48L438.84,233.32L416.38,225.39L405.01,233.97L399,225.39L389.42,234.92L351.13,267.97L325.64,266.75L273.48,299.63L267.23,284.28L250.47,282.1L221.81,311.91L200.47,317.29L197.46,332.65L190.16,340.76L219.35,355.35L310.91,381.94L329.2,349.42L343.09,359.55L338.9,377L370.5,393.55L359.05,419.11L397.28,405.28L420.14,384.16L448.61,382.08L463.24,332.51L447.9,312.46L463.92,268.48z"
        />
        <path
          id="LR-BM"
          title="Bomi"
          className="land"
          d="M197.46,332.65L146.49,344.65L143.3,314.52L105.51,309.83L98.73,361.99L78.52,388.71L131.41,426.14L133.08,402.86L153.84,391.5L170.2,400.19L179.3,393.92L172.96,366.64L179.64,343.66L190.16,340.76L197.46,332.65z"
        />
        <path
          id="LR-SI"
          title="Sinoe"
          className="land"
          d="M595.96,609.42L586.39,595.69L611.42,576.59L600.02,553.51L580.34,547.03L565.81,563.25L503.66,529.81L467.77,538.32L454.65,561.71L414.77,587.24L422.79,618.71L402.88,648.47L464.13,695.52L542.62,736.67L572.2,697.98L619.75,668.33L595.96,609.42z"
        />
        <path
          id="LR-MY"
          title="Maryland"
          className="land"
          d="M770.01,828.68L757.23,737.56L733.97,725.03L712.68,728.97L710.11,687L675.31,679.57L661.07,728.65L700.37,750.67L717.55,780.39L710.7,808.53L736.37,826.98L770.01,828.68z"
        />
        <path
          id="LR-GK"
          title="Grand Kru"
          className="land"
          d="M675.31,679.57L619.75,668.33L572.2,697.98L542.62,736.67L629.88,784.13L710.7,808.53L717.55,780.39L700.37,750.67L661.07,728.65L675.31,679.57z"
        />
        <path
          id="LR-GP"
          title="Gbapolu"
          className="land"
          d="M325.64,266.75L321.72,243.73L348.58,163.17L299.21,142.66L268,149.98L249.85,124.33L244.77,131.64L168.63,106.81L168.84,156.84L120.18,201.3L172.68,213.06L188.53,247.29L105.51,309.83L143.3,314.52L146.49,344.65L197.46,332.65L200.47,317.29L221.81,311.91L250.47,282.1L267.23,284.28L273.48,299.63L325.64,266.75z"
        />
        <path
          id="LR-RG"
          title="River Gee"
          className="land"
          d="M757.23,737.56L757.33,721.85L768.27,718.62L763.14,687.57L780.13,674.1L782.71,649.84L799.52,639.45L787.75,618.71L795.59,600.9L595.96,609.42L619.75,668.33L675.31,679.57L710.11,687L712.68,728.97L733.97,725.03L757.23,737.56z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Liberia))
