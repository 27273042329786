import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 623
const height = 745

const cities = {
  Podgorica: { x: 262, y: 477 }
}

const Montenegro = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map montenegro">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="ME-01"
          title="Andrijevica"
          className="land"
          d="M463.63,377.32L463.16,377.55L463.16,377.55L451.89,381.89L438.01,396.63L435.41,403.57L434.11,411.81L424.14,414.41L420.64,416.82L420.64,416.82L418.9,415.03L417.35,411.18L415.07,409.6L414.13,404.08L416.27,401.35L416.15,399.72L411.54,397.88L408.54,391.38L406.99,390.1L399.85,392.49L398.15,394.63L398.21,397.07L392.72,403.44L391.14,403.91L387.51,402.97L384.92,405.98L384.92,405.98L381.04,399.28L376.7,386.7L376.7,386.7L374.6,383.79L374.6,383.79L379.74,381.67L382.77,377.34L382.77,370.83L387.11,368.66L388.41,361.73L384.94,359.13L384.07,349.59L386.67,344.38L387.11,339.18L380.6,334.41L375.83,328.77L374.53,322.27L380.17,316.2L380.17,316.2L386.76,314.26L386.76,314.26L428.48,338.09L436.28,340.7L445.39,341.56L449.29,338.09L453.62,338.53L453.19,344.6L450.59,348.5L445.39,352.84L445.39,358.04L449.29,363.24L452.32,369.31z"
        />
        <path
          id="ME-02"
          title="Bar"
          className="land"
          d="M206.05,540.22L205.86,540.42L205.86,540.42L213.23,549.09L214.53,553.86L214.96,562.53L216.7,568.6L219.73,572.07L231.44,574.24L237.94,574.24L240.98,575.54L244.01,582.48L248.78,599.39L254.42,603.73L257.46,610.23L270.46,614.57L278.7,625.41L287.04,631.49L287.04,631.49L286.94,631.48L286.94,631.48L283.47,636.68L279.14,639.72L272.2,638.42L269.16,639.72L264.83,639.72L259.19,635.82L257.02,631.48L253.55,629.31L247.92,633.65L247.92,637.98L253.55,639.72L254.42,641.45L250.95,649.26L250.52,653.16L253.12,655.33L254.85,658.8L257.89,659.66L259.62,668.34L257.89,672.24L253.55,673.97L248.78,673.11L234.47,665.74L224.59,666.3L224.59,666.3L224.68,664.85L222.28,663.54L222.38,662.56L223.77,662.14L224.08,659.35L221.46,657.69L222.25,656.63L221.87,652.61L205.71,640.45L203.94,638.79L204.51,638.33L199.61,634.13L199.45,633.16L201.22,633.03L204.25,634.26L203.78,632.56L205.17,633.07L204.54,631.29L205.36,631.59L205.99,630.7L206.08,629L204.03,623.87L198.66,619.38L196.58,618.91L197.27,617.17L196.14,614.16L193.42,612.54L185.46,613.18L183.98,611.15L180.85,614.24L177.85,607.79L178.48,604.23L176.69,601.71L176.69,601.71L182.01,591.15L185.91,587.25L183.31,580.31L179.41,575.11L168.13,565.14L165.96,556.9L161.63,546.49L161.63,546.49L166.4,545.62L166.4,545.62L177.24,549.53L179.84,553.43L188.08,554.3L193.71,551.69L194.58,548.23L190.68,540.42L192.41,536.95L198.48,536.08L203.69,538.25z"
        />
        <path
          id="ME-03"
          title="Berane"
          className="land"
          d="M463.63,377.32L452.32,369.31L449.29,363.24L445.39,358.04L445.39,352.84L450.59,348.5L453.19,344.6L453.62,338.53L449.29,338.09L445.39,341.56L436.28,340.7L428.48,338.09L386.76,314.26L386.76,314.26L387.54,314.03L387.54,314.03L383.64,301.45L384.94,291.05L384.94,291.05L384.04,291.15L384.04,291.15L392.74,282.81L392.74,282.81L394.33,280.7L394.33,280.7L402.02,281.51L410.7,278.9L418.5,275.44L427.61,265.46L434.55,262.86L438.45,264.6L441.05,267.63L445.82,268.93L452.32,270.23L459.69,268.93L464.9,264.6L485.28,257.66L489.61,252.89L493.95,251.15L502.62,250.29L508.69,247.25L513.46,244.65L518.42,237.83L518.42,237.83L525.28,241.08L533.46,249.77L536.59,251.92L540.73,251.28L545.24,248.26L546.85,248.09L546.85,248.09L545.98,260.26L540.35,268.5L538.61,277.6L528.64,287.14L527.77,297.12L524.74,307.09L516.93,313.6L509.99,322.27L512.6,336.14L514.39,339.53L514.39,339.53L513,342L512.15,346.55L507.98,353.02L513.1,366.9L516.76,368.52L518.93,371L518.93,371L491.35,374.95L470.1,374.08L470.1,374.08z"
        />
        <path
          id="ME-04"
          title="Bijelo Polje"
          className="land"
          d="M518.42,237.83L513.46,244.65L508.69,247.25L502.62,250.29L493.95,251.15L489.61,252.89L485.28,257.66L464.9,264.6L459.69,268.93L452.32,270.23L445.82,268.93L441.05,267.63L438.45,264.6L434.55,262.86L427.61,265.46L418.5,275.44L410.7,278.9L402.02,281.51L394.33,280.7L394.33,280.7L395.35,279.34L395.35,279.34L383.21,270.23L380.6,266.33L385.37,257.23L385.37,250.29L380.17,243.35L372.8,238.58L355.02,236.85L347.65,234.68L334.21,226L325.1,225.14L317.3,221.67L317.3,221.67L316.77,221.83L316.77,221.83L319.03,214.73L318.6,204.76L322.06,199.55L326.83,195.65L334.21,195.65L336.81,190.45L337.24,183.94L341.58,181.34L344.18,178.31L344.18,172.67L341.58,168.33L341.58,164.43L346.78,161.4L349.82,155.76L353.72,153.59L355.89,138.85L352.42,133.21L353.25,129.05L353.25,129.05L372.54,140.76L381.16,157.38L393.16,165.24L408.28,169.38L413.72,172.79L414.54,172.45L415.77,165.84L418.3,163.6L420.25,164.33L423.73,169.25L424.07,174.6L427.86,181.42L429.76,190.3L440.65,191.29L444.69,196.33L448.61,193.23L454.07,197.2L461.12,198.19L473.4,196.9L478.67,191.08L481.64,190.6L487.67,204.05L490.23,205.86L492.95,210.64L505.9,225.63L513.25,231.83z"
        />
        <path
          id="ME-05"
          title="Budva"
          className="land"
          d="M162.75,546.29L161.63,546.49L161.63,546.49L165.96,556.9L168.13,565.14L179.41,575.11L183.31,580.31L185.91,587.25L182.01,591.15L176.69,601.71L176.69,601.71L174.76,602.74L174.32,601L173.08,601.34L172.9,599.9L167.59,596.5L167.05,594.89L168.51,591.32L165.66,587.59L162.54,587.37L162.13,585.38L160.71,585.42L158.81,583.17L154.52,582.79L153.88,580.36L152.34,580.19L148.52,577.18L147.6,572.21L144.63,569.15L145.55,567.74L144.76,565.41L145.26,563.92L143.75,561.33L143.37,556.65L141.66,553.97L140.05,553.76L140.05,551.67L135.76,551.55L134.15,552.14L133.74,553.42L131.05,549.68L128.91,549.72L126.63,550.99L126.88,552.95L123.92,554.48L120.22,552.78L119.57,550.85L119.57,550.85L119.57,546.49L118.27,545.19L122.17,535.22L118.7,532.18L117.4,529.58L116.1,525.68L117.83,522.64L117.83,522.64L122.17,520.47L122.17,520.47L126.07,521.34L129.97,524.81L146.88,530.45L155.99,531.75z"
        />
        <path
          id="ME-06"
          title="Cetinje"
          className="land"
          d="M227.54,527.59L227.54,526.54L227.54,526.54L218.86,522.64L215.4,524.81L210.19,523.08L206.72,519.61L203.25,519.61L201.09,524.38L206.72,532.61L207.59,538.69L207.59,538.69L206.05,540.22L206.05,540.22L203.69,538.25L198.48,536.08L192.41,536.95L190.68,540.42L194.58,548.23L193.71,551.69L188.08,554.3L179.84,553.43L177.24,549.53L166.4,545.62L166.4,545.62L162.75,546.29L162.75,546.29L155.99,531.75L146.88,530.45L129.97,524.81L126.07,521.34L122.17,520.47L122.17,520.47L119.04,522.04L119.04,522.04L117.83,507.25L110.03,499.01L107.42,494.24L108.29,489.47L111.76,485.13L112.19,478.2L110.46,465.62L108.29,458.25L102.22,451.75L96.15,449.58L90.95,442.64L85.74,429.2L75.77,417.49L75.77,417.49L71.73,405.38L71.73,405.38L77.94,404.05L83.14,401.01L83.14,383.23L91.81,375.86L92.25,366.76L97.89,366.76L110.89,375.43L126.94,375L133.01,378.9L141.25,388.87L154.69,396.68L158.59,401.01L158.59,401.01L160.76,409.69L160.76,409.69L159.89,417.06L166.83,418.79L171.6,422.26L175.07,430.5L179.84,432.67L184.61,437.44L183.31,440.9L175.94,444.81L175.94,453.91L175.94,453.91L167.7,456.95L167.7,456.95L168.13,460.85L180.71,464.75L186.34,470.82L188.94,476.03L196.32,481.23L200.22,482.53L205.86,495.11L210.19,500.31L223.63,508.98L227.97,512.89L230.57,522.42z"
        />
        <path
          id="ME-07"
          title="Danilovgrad"
          className="land"
          d="M175.5,454.07L175.94,453.91L175.94,453.91L175.94,444.81L183.31,440.9L184.61,437.44L179.84,432.67L175.07,430.5L171.6,422.26L166.83,418.79L159.89,417.06L160.76,409.69L160.76,409.69L160.24,407.62L160.24,407.62L174.2,407.95L179.84,404.48L179.41,395.38L180.27,384.54L185.04,378.46L197.62,370.23L205.42,370.66L214.53,374.13L234.04,374.13L243.58,375.43L248.35,371.96L241.85,365.46L238.38,359.39L237.94,355.05L241.85,352.88L244.88,349.85L244.88,349.85L248.35,349.85L248.35,349.85L254.42,353.31L258.32,371.96L268.3,383.67L268.3,383.67L270.03,388L270.03,388L259.19,394.94L258.76,404.48L260.93,410.12L259.19,418.36L248.78,423.56L246.18,432.23L248.35,452.18L247.05,457.38L240.98,464.75L235.34,468.66L222.77,462.15L218.43,465.62L208.02,465.19L199.35,466.92L195.88,461.29L188.51,459.55z"
        />
        <path
          id="ME-08"
          title="Herceg-Novi"
          className="land"
          d="M64.65,494.86l0.72,6.32l3.9,2.6l1.68,2.99l0,0l-2.17,1.24v2.47l1.58,1.66l-1.99,1.62v2.38l-2.46,-0.6l-0.41,-1.96l-1.2,-0.72l-2.72,0.21l0.51,2.13l-0.79,1.23l-3.47,-1.02l-0.6,-1.4l-2.65,1.66l0.06,-1.75l-1.14,-0.94l-1.8,0.51l-0.19,-1.28l-3.28,-2l0.6,-1.87l-1.89,0.34l-0.85,-1.75l-2.31,-0.38l-1.99,-2.6l2.56,-1.24l-0.6,-0.98l1.14,-0.94l-6.82,-2.47l-0.41,-2.94l-3.06,-0.64l-0.32,-3.49l4.39,-4.01l2.53,-0.64l14.65,4.09L64.65,494.86zM23.94,420.88l6.19,0.3l1.05,-0.63l0,0l6.87,9.52l3.47,9.54l2.6,3.04l5.2,3.9l15.61,2.6l6.94,3.47l1.62,4.06l0,0l-5.69,4.18l-0.38,1.19l1.33,0.9l4.55,0.3l5.81,2.9l-3.92,6.02l-0.32,1.45l0.82,1.07l-0.76,1.11l-4.64,2.22l-1.17,1.45l-2.12,0.04l-5.97,3.97l-9.63,2.86l-7.07,-3.79l-3.35,-4.39l-2.21,-0.43l-2.37,1.58l-5.59,0.17l-6.32,-4.01l-3.16,2.6l-0.54,1.58l2.34,4.26l5.84,5.88l-2.24,2.43l-3.41,-0.85L2.62,467l-0.6,-6.4l2.84,-3.71l0.47,-2.13L0,435.71l0.35,-3.33l6.28,-0.26l6.88,-1.54l6.95,-9.66L23.94,420.88z"
        />
        <path
          id="ME-09"
          title="Kolašin"
          className="land"
          d="M374.6,383.79L371.06,378.9L371.06,378.9L361.96,378.46L356.75,372.39L351.98,358.08L342.44,357.22L336.81,358.08L332.91,375.43L319.46,378.03L318.6,383.67L304.72,389.3L299.52,393.21L292.58,392.34L284.77,388.44L280,389.74L274.37,386.27L269.67,387.1L269.67,387.1L268.3,383.67L268.3,383.67L258.32,371.96L254.42,353.31L248.35,349.85L248.35,349.85L246.34,349.85L246.34,349.85L240.98,345.25L238.81,340.48L239.68,330.07L243.58,326.6L246.18,321.4L244.88,316.63L241.41,310.99L235.78,305.79L235.78,305.79L234.66,305.87L234.66,305.87L233.61,298.85L240.55,291.05L241.41,287.14L250.95,285.84L255.72,287.14L282.17,284.98L288.24,280.21L288.24,280.21L290.21,273.9L290.21,273.9L296.92,274.14L301.69,280.64L309.06,284.98L317.73,286.71L324.67,289.75L339.84,291.05L344.18,288.45L351.12,286.71L358.06,289.75L365.43,288.88L371.06,292.35L377.57,291.91L377.57,291.91L384.94,291.05L384.94,291.05L383.64,301.45L387.54,314.03L387.54,314.03L380.17,316.2L380.17,316.2L374.53,322.27L375.83,328.77L380.6,334.41L387.11,339.18L386.67,344.38L384.07,349.59L384.94,359.13L388.41,361.73L387.11,368.66L382.77,370.83L382.77,377.34L379.74,381.67z"
        />
        <path
          id="ME-10"
          title="Kotor"
          className="land"
          d="M73.49,456.67L71.87,452.61L64.93,449.14L49.32,446.54L44.12,442.64L41.52,439.6L38.05,430.06L31.18,420.55L31.18,420.55L32.75,419.17L32.84,417.64L28.6,413.82L28.6,413.82L39.35,412.29L65.36,402.75L71.43,404.48L71.43,404.48L75.77,417.49L75.77,417.49L85.74,429.2L90.95,442.64L96.15,449.58L102.22,451.75L108.29,458.25L110.46,465.62L112.19,478.2L111.76,485.13L108.29,489.47L107.42,494.24L110.03,499.01L117.83,507.25L119.04,522.04L119.04,522.04L117.83,522.64L117.83,522.64L116.1,525.68L117.4,529.58L118.7,532.18L122.17,535.22L118.27,545.19L119.57,546.49L119.57,550.85L119.57,550.85L117.51,549.8L115.01,551.59L115.96,554.69L109.83,550.65L110.46,555.93L108.35,557.16L106.77,556.65L99.03,548.02L98.94,546.53L94.48,541.89L93.32,541.93L93.66,540.45L91.52,539.68L91.39,538.57L85.48,533.64L87.95,531.17L85.93,528.83L86.12,526.23L82.14,523.08L79.48,519.38L79.8,518.23L81.13,518.23L85.17,519.97L83.51,517.38L83.51,517.38L85.92,516.84L94.44,509.7L97.46,505.71L98.43,498.16L103.62,490.92L103.62,490.92L104.18,487.3L102.25,483.29L103.3,480.82L102.63,463.07L100.71,462.82L92.72,465.68L85.93,465.59L82.77,464.61L79.83,462.26L79.93,457.48L81.38,454.5L79.58,451.25L77.84,451.94L75.79,456.12z"
        />
        <path
          id="ME-11"
          title="Mojkovac"
          className="land"
          d="M384.04,291.15L377.57,291.91L377.57,291.91L371.06,292.35L365.43,288.88L358.06,289.75L351.12,286.71L344.18,288.45L339.84,291.05L324.67,289.75L317.73,286.71L309.06,284.98L301.69,280.64L296.92,274.14L290.21,273.9L290.21,273.9L290.41,273.27L290.41,273.27L286.94,266.33L280,265.46L277.4,259.39L275.67,251.15L275.67,251.15L275.47,251.05L275.47,251.05L277.4,248.99L277.4,239.88L296.48,237.28L298.65,227.31L302.99,223.84L302.99,223.84L302.8,223.71L302.8,223.71L310.36,223.84L310.36,223.84L317.3,221.67L317.3,221.67L325.1,225.14L334.21,226L347.65,234.68L355.02,236.85L372.8,238.58L380.17,243.35L385.37,250.29L385.37,257.23L380.6,266.33L383.21,270.23L395.35,279.34L395.35,279.34L392.74,282.81L392.74,282.81z"
        />
        <path
          id="ME-12"
          title="Nikšić"
          className="land"
          d="M246.34,349.85L244.88,349.85L244.88,349.85L241.85,352.88L237.94,355.05L238.38,359.39L241.85,365.46L248.35,371.96L243.58,375.43L234.04,374.13L214.53,374.13L205.42,370.66L197.62,370.23L185.04,378.46L180.27,384.54L179.41,395.38L179.84,404.48L174.2,407.95L160.24,407.62L160.24,407.62L158.59,401.01L158.59,401.01L154.69,396.68L141.25,388.87L133.01,378.9L126.94,375L110.89,375.43L97.89,366.76L92.25,366.76L91.81,375.86L83.14,383.23L83.14,401.01L77.94,404.05L71.73,405.38L71.73,405.38L71.43,404.48L71.43,404.48L65.36,402.75L39.35,412.29L28.6,413.82L28.6,413.82L25.92,407.36L29.65,397.21L34.77,396.94L42.32,394.72L38.97,375.46L42.35,367.11L41.43,364.11L38.24,361.84L32.53,363.81L25.17,362.96L22.9,360.17L22.83,358.07L18.1,350.62L13.17,349.55L11.72,348.05L13.07,341.49L11.12,338.83L10.83,334.72L9.54,333.39L10.23,332.27L13.2,332.4L16.11,331.29L17.24,329.61L17.34,327.51L13.61,316.7L13.99,310.27L17.27,304.08L20.15,301.42L19.33,295.46L23.02,293.14L23.4,292.06L23.21,290.26L20.56,289.06L17.59,283.99L17.72,277.33L15.16,273.2L15.22,271.48L19.7,268.09L25.83,255.4L30.22,242.07L36.1,235.66L39.76,235.18L67.2,240.65L82.8,235.7L82.58,232.52L71.07,204.24L71.07,204.24L83.58,202.16L92.68,205.19L100.05,211.26L110.46,242.48L120.43,262L126.5,267.63L131.27,270.23L169,270.67L169,270.67L172.47,277.6L172.47,277.6L176.8,279.34L195.45,291.05L206.29,295.82L218,307.09L218,307.09L235.78,305.79L235.78,305.79L241.41,310.99L244.88,316.63L246.18,321.4L243.58,326.6L239.68,330.07L238.81,340.48L240.98,345.25z"
        />
        <path
          id="ME-13"
          title="Plav"
          className="land"
          d="M420.64,416.82L424.14,414.41L434.11,411.81L435.41,403.57L438.01,396.63L451.89,381.89L463.16,377.55L463.16,377.55L470.1,374.08L470.1,374.08L491.35,374.95L518.93,371L518.93,371L520.36,372.42L523.93,372.98L526.45,374.39L531.69,382.78L536.97,386.8L537.22,387.96L526.52,401.39L522.82,403.36L519.57,407.38L519.25,414.56L521.18,421.14L516.38,435.03L510.66,433.53L508.11,437.2L498.1,440.45L497.37,441.94L497.59,445.96L494.21,454.2L486.57,453L481.67,454.03L478.04,453.39L475.14,451L471.69,451.64L465.88,455.48L463.52,455.78L462.92,461.75L460.71,464.65L454.55,460.09L451.45,466.06L447.16,465.46L445.45,467.38L441.98,467.47L441.76,470.5L439.99,472.89L436.14,471.56L427.64,466.06L423.66,459.92L418.93,457.44L417.38,449.2L412.23,439.38L412.39,438.14L414.16,437.29L414.06,434.73L415.71,431.31L413.91,426.99L415.14,423.83L421.39,423.88L422.46,423.15L422.05,418.53z"
        />
        <path
          id="ME-14"
          title="Pljevlja"
          className="land"
          d="M353.25,129.05L352.42,133.21L355.89,138.85L353.72,153.59L349.82,155.76L346.78,161.4L341.58,164.43L341.58,168.33L344.18,172.67L344.18,178.31L341.58,181.34L337.24,183.94L336.81,190.45L334.21,195.65L326.83,195.65L322.06,199.55L318.6,204.76L319.03,214.73L316.77,221.83L316.77,221.83L310.36,223.84L310.36,223.84L302.8,223.71L302.8,223.71L295.61,218.63L295.61,218.63L288.68,203.89L280,202.59L275.23,195.22L267.43,190.01L264.39,184.38L256.15,180.91L252.69,177.87L240.11,173.1L227.54,159.66L215.4,159.66L210.63,165.3L196.75,165.3L193.71,160.53L188.51,160.96L187.64,150.12L185.91,141.88L186.78,136.25L185.61,134.61L185.61,134.61L188.84,128.88L192.35,125.03L196.67,123.13L206.62,122.09L208.11,110.33L206.46,104.75L200.05,92.81L190.3,80.91L184.96,68.4L183.98,64.54L183.98,58L181.93,54.49L181.83,51.06L179.56,50.33L177.73,52.8L174.41,47.47L170.91,45.65L169.23,39.01L169.39,35.63L167.91,31.9L168.1,27.09L169.8,23.22L169.23,20.53L167.62,18.54L166.2,18.88L164.78,20.19L162.16,25.91L160.93,25.52L159.92,23.61L159.13,17.45L159.92,15.85L163.9,12.9L169.26,12.94L171.06,12.07L175.58,1.56L178.93,0L180.66,0.61L181.99,3.73L183.44,1L185.15,0.35L188.84,1.56L194.75,1.04L199.42,6.25L204.44,8.47L218.18,18.88L220.9,18.15L229.8,9.51L231.95,9.38L235.99,11.59L240,9.6L242.46,12.07L245.18,10.2L247.67,11.29L249.7,13.42L250.9,28.82L253.11,32.2L260.68,39.32L272.84,53.36L284.02,60.82L298.39,66.53L306.1,75.11L312.32,78.83L321.41,81.56L324.35,93.81L328.8,99.95L333.03,102.94L343.9,105.84L346.55,110.46L350.12,126.11z"
        />
        <path
          id="ME-15"
          title="Plužine"
          className="land"
          d="M176.24,133.25L173.33,150.56L174.2,163.13L172.9,173.1L172.9,180.91L183.31,194.35L183.31,194.35L183.31,202.59L183.31,202.59L180.71,201.72L166.4,206.06L159.89,213.43L156.42,226.87L151.65,231.64L152.52,245.08L155.99,251.59L172.9,258.96L176.37,264.6L174.2,268.93L169.29,271.25L169.29,271.25L169,270.67L169,270.67L131.27,270.23L126.5,267.63L120.43,262L110.46,242.48L100.05,211.26L92.68,205.19L83.58,202.16L71.07,204.24L71.07,204.24L66.28,189.74L67.93,178.35L71.65,170.42L79.8,158.81L86.34,151.64L88.83,147.11L88.04,144.04L81.54,139.98L80.4,138.21L80.34,134.11L81.98,132.03L90.66,125.72L112.52,111.5L125.24,106.57L132.22,105.92L130.67,100.43L127.3,96.02L127.3,93.59L138.92,90.26L144.66,91.04L148.99,88.49L150.66,89.22L154.86,93.2L160.2,101.16L164.72,101.68L165.41,104.37L164.87,107.13L162.85,109.25L161.84,113.23L163.26,118.2L164.08,119.46L169.9,121.23L172.74,123.17L174.13,126.5L172.8,130.26z"
        />
        <path
          id="ME-16"
          title="Podgorica"
          className="land"
          d="M384.92,405.98L370.64,425.58L370.11,431.99L371.27,433.83L372.13,440.54L368.24,442.88L367.83,445.23L355.33,452.4L353.97,460.26L350.87,463.84L347.84,469.39L347.21,472.5L344.5,476.98L341.94,479.03L341.08,482.91L336.85,484.74L334.74,487.43L333.98,491.13L332.12,491.69L331.14,496.04L326.43,504.09L321.57,507.33L317.72,507.54L315.76,508.56L311.94,512.99L310.67,515.55L310.83,519.97L307.99,522.1L308.84,528.53L310.55,532.02L298.71,545.72L297.85,552.06L291.03,560.26L288.36,566.06L288.36,566.06L283.91,565.57L280,563.4L272.2,556.9L260.49,553.86L253.55,555.16L248.78,557.33L237.51,560.37L235.78,556.03L230.14,553L228.84,549.96L223.63,546.49L222.33,543.89L221.9,537.39L223.63,532.61L227.54,530.45L227.54,530.45L227.54,527.59L227.54,527.59L230.57,522.42L227.97,512.89L223.63,508.98L210.19,500.31L205.86,495.11L200.22,482.53L196.32,481.23L188.94,476.03L186.34,470.82L180.71,464.75L168.13,460.85L167.7,456.95L167.7,456.95L175.5,454.07L175.5,454.07L188.51,459.55L195.88,461.29L199.35,466.92L208.02,465.19L218.43,465.62L222.77,462.15L235.34,468.66L240.98,464.75L247.05,457.38L248.35,452.18L246.18,432.23L248.78,423.56L259.19,418.36L260.93,410.12L258.76,404.48L259.19,394.94L270.03,388L270.03,388L269.67,387.1L269.67,387.1L274.37,386.27L280,389.74L284.77,388.44L292.58,392.34L299.52,393.21L304.72,389.3L318.6,383.67L319.46,378.03L332.91,375.43L336.81,358.08L342.44,357.22L351.98,358.08L356.75,372.39L361.96,378.46L371.06,378.9L371.06,378.9L376.7,386.7L376.7,386.7L381.04,399.28z"
        />
        <path
          id="ME-17"
          title="Rožaje"
          className="land"
          d="M514.39,339.53L512.6,336.14L509.99,322.27L516.93,313.6L524.74,307.09L527.77,297.12L528.64,287.14L538.61,277.6L540.35,268.5L545.98,260.26L546.85,248.09L546.85,248.09L551.72,251.1L558.6,252.95L560.27,254.46L571.14,258.93L578.81,264.99L593.21,267.7L594.73,272.13L594.38,273.63L597.79,276.38L601.74,276.6L610.39,282.7L611.68,284.5L612,287.55L610.93,311.51L608.75,314.9L607.04,315.89L597.76,314.77L593.53,319.06L587.43,322.06L585.32,324.08L583.42,327.47L585.98,335.4L575.15,349.68L571.83,351.22L558.79,349.08L545.15,349.2L516.44,339.13z"
        />
        <path
          id="ME-18"
          title="Šavnik"
          className="land"
          d="M183.31,202.16L190.68,216.03L201.09,217.33L203.69,213L211.49,213.43L217.56,221.67L223.63,227.31L228.4,227.74L238.38,225.14L244.88,230.77L249.65,239.88L255.72,242.92L260.93,243.78L268.3,247.25L268.3,247.25L275.67,251.15L275.67,251.15L277.4,259.39L280,265.46L286.94,266.33L290.41,273.27L290.41,273.27L288.24,280.21L288.24,280.21L282.17,284.98L255.72,287.14L250.95,285.84L241.41,287.14L240.55,291.05L233.61,298.85L234.66,305.87L234.66,305.87L218,307.09L218,307.09L206.29,295.82L195.45,291.05L176.8,279.34L172.47,277.6L172.47,277.6L169.29,271.25L169.29,271.25L174.2,268.93L176.37,264.6L172.9,258.96L155.99,251.59L152.52,245.08L151.65,231.64L156.42,226.87L159.89,213.43L166.4,206.06L180.71,201.72L183.31,202.59L183.31,202.59z"
        />
        <path
          id="ME-19"
          title="Tivat"
          className="land"
          d="M103.62,490.92L98.43,498.16L97.46,505.71L94.44,509.7L85.92,516.84L83.51,517.38L83.51,517.38L82.42,514.31L79.45,512.1L80.43,510.39L78.28,508.05L78.38,506.6L77.62,506.47L77.31,507.67L72.73,506.22L70.95,506.77L70.95,506.77L69.27,503.78L65.36,501.18L64.65,494.86L64.65,494.86L73.64,498.34L79.77,498.72L84.1,502.81L86.46,503.71L87.1,503.19L87.47,501.36L85.45,499.32L86.5,498.76L83.53,497.57L87.19,497.36L87.25,493.82L81.51,487.85L80.72,484.61L78.35,483.67L78.79,481.88L76.52,477.02L78.13,469.86L80.08,467.08L94.26,472.25L100.17,479.75L101.87,489.17z"
        />
        <path
          id="ME-20"
          title="Ulcinj"
          className="land"
          d="M301.12,633.01L297.32,639.43L296.5,649.05L294.06,654.43L294.85,668.32L297.06,671.75L297.92,676.07L296.27,681.61L299.87,685.68L298.26,687.37L290.56,688.09L288.16,689.7L288.13,696.25L293.87,701.07L294.7,703.7L292.45,706.36L286.67,707.84L285.44,709.78L286.55,712.24L292.58,714.22L294,718.28L297.63,719.63L295.93,723.27L295.67,729.18L294.35,734.25L291.6,734.76L288.85,731L284.78,728.67L281.94,724.03L277.77,720.27L261.79,712.07L253.33,709.49L251.65,710.93L247.36,706.78L243.54,704.88L242.12,705.01L242.08,703.7L240.98,703.99L239.68,702.55L236.12,701.67L224.97,692.78L224.37,690.88L228.85,692.11L229.74,691.18L225.25,685.8L222.82,684.32L221.34,681.53L222.22,679.46L224.72,679.54L225.51,678.57L224.97,676.79L222.32,675.31L224.08,674L223.39,672.68L223.86,671.2L222.44,669.04L224.59,666.3L224.59,666.3L234.47,665.74L248.78,673.11L253.55,673.97L257.89,672.24L259.62,668.34L257.89,659.66L254.85,658.8L253.12,655.33L250.52,653.16L250.95,649.26L254.42,641.45L253.55,639.72L247.92,637.98L247.92,633.65L253.55,629.31L257.02,631.48L259.19,635.82L264.83,639.72L269.16,639.72L272.2,638.42L279.14,639.72L283.47,636.68L286.94,631.48L286.94,631.48L296.48,632.35L296.48,632.35z"
        />
        <path
          id="ME-21"
          title="Žabljak"
          className="land"
          d="M185.61,134.61L186.78,136.25L185.91,141.88L187.64,150.12L188.51,160.96L193.71,160.53L196.75,165.3L210.63,165.3L215.4,159.66L227.54,159.66L240.11,173.1L252.69,177.87L256.15,180.91L264.39,184.38L267.43,190.01L275.23,195.22L280,202.59L288.68,203.89L295.61,218.63L295.61,218.63L302.99,223.84L302.99,223.84L298.65,227.31L296.48,237.28L277.4,239.88L277.4,248.99L275.47,251.05L275.47,251.05L268.3,247.25L268.3,247.25L260.93,243.78L255.72,242.92L249.65,239.88L244.88,230.77L238.38,225.14L228.4,227.74L223.63,227.31L217.56,221.67L211.49,213.43L203.69,213L201.09,217.33L190.68,216.03L183.31,202.16L183.31,202.16L183.31,194.35L183.31,194.35L172.9,180.91L172.9,173.1L174.2,163.13L173.33,150.56L176.24,133.25L176.24,133.25L177.25,134.71L180.54,136.31L181.39,138.69z"
        />
        <path
          id="ME-SLK"
          title="Skadar Lake"
          className="land"
          d="M288.36,566.06L287.4,568.77L265.96,594.72L304.07,626.76L301.12,633.01L301.12,633.01L296.48,632.35L296.48,632.35L287.04,631.49L287.04,631.49L278.7,625.41L270.46,614.57L257.46,610.23L254.42,603.73L248.78,599.39L244.01,582.48L240.98,575.54L237.94,574.24L231.44,574.24L219.73,572.07L216.7,568.6L214.96,562.53L214.53,553.86L213.23,549.09L205.86,540.42L205.86,540.42L207.59,538.69L207.59,538.69L206.72,532.61L201.09,524.38L203.25,519.61L206.72,519.61L210.19,523.08L215.4,524.81L218.86,522.64L227.54,526.54L227.54,526.54L227.54,530.45L227.54,530.45L223.63,532.61L221.9,537.39L222.33,543.89L223.63,546.49L228.84,549.96L230.14,553L235.78,556.03L237.51,560.37L248.78,557.33L253.55,555.16L260.49,553.86L272.2,556.9L280,563.4L283.91,565.57z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Montenegro))
