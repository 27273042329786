import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 565

const cities = {
  Ashgabat: { x: 333, y: 363 }
}

const Turkmenistan = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map turkmenistan">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="TM-M"
          title="Mary"
          className="land"
          d="M555.09,554.82L578.02,547.4L599.78,534.38L601.13,505.33L654.1,495.44L678.45,476.42L692.57,435.85L623.9,435.46L622.78,401.01L635.16,381.56L483.94,245.08L452.26,300.24L481.71,361.48L480.68,397.68L506.31,406.19L498.05,413.69L482.82,399.9L511.15,448.94L516.26,498.67L506.29,526.86L539.4,534.25L555.09,554.82z"
        />
        <path
          id="TM-L"
          title="Lebap"
          className="land"
          d="M692.57,435.85L695.39,417L738.07,407.17L750.71,384.4L790.11,402.41L799.28,354.81L741.85,337.39L635.19,268.6L564.47,208.81L532.11,126.14L509.15,113.25L498.53,123.72L482.93,118.32L481.6,135.27L440.33,135.43L455.51,246.91L483.94,245.08L635.16,381.56L622.78,401.01L623.9,435.46L692.57,435.85z"
        />
        <path
          id="TM-D"
          title="Daşoguz"
          className="land"
          d="M346.91,0L320.65,12.79L341.44,37.71L313.69,22.79L305.4,45.78L279.05,47.9L254.72,70.44L265.86,109.21L231.28,113.12L266.83,202.71L340.71,193.76L380.51,209.19L426.08,209.58L427.04,235.84L455.51,246.91L440.33,135.43L481.6,135.27L482.93,118.32L453.31,119.62L431.7,106.33L429.17,78.94L440.62,76.94L421.13,62.83L427.99,45.17L384.66,33.91L346.91,0z"
        />
        <path
          id="TM-A"
          title="Ahal"
          className="land"
          d="M455.51,246.91L427.04,235.84L426.08,209.58L380.51,209.19L340.71,193.76L266.83,202.71L256.72,209.04L263.33,236.49L233.39,317.17L265.29,335.6L277.37,356.69L326.42,378.07L387.69,387.81L396.1,410.67L427.48,423.51L445.67,450.84L492.02,450.9L490,497.4L506.29,526.86L516.26,498.67L511.15,448.94L482.82,399.9L498.05,413.69L506.31,406.19L480.68,397.68L481.71,361.48L452.26,300.24L483.94,245.08L455.51,246.91z"
        />
        <path
          id="TM-B"
          title="Balkan"
          className="land"
          d="M231.28,113.12L170.3,114.62L139.72,65.44L95.56,34.67L31.17,50.71L0.72,79.29L25.75,131.7L27.67,84.92L19.38,84.82L30.14,61.49L78.46,50.87L92.83,98.93L131.86,132.72L124.68,147.48L102.74,143.62L111.38,159.75L72.96,163.31L67.54,143.88L59.57,155.04L39.07,152.6L27.67,129.11L17.55,173.76L17.65,205.55L32.83,226.63L27.51,207.9L64.71,211.85L55.83,235.15L73.64,244.06L44.59,244.27L47.9,234.06L37.17,253.36L41.46,269.42L40.81,257.46L64.01,256.79L87.69,288.53L78.92,316.7L83.81,401.11L125.47,394.3L136.88,371.41L170.14,348.39L265.29,335.6L233.39,317.17L263.33,236.49L256.72,209.04L266.83,202.71L231.28,113.12z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Turkmenistan))
