import annyang from 'annyang'
import { browserName } from 'react-device-detect'
import { createCommands, saveTranscript } from './annyangUtil'

class Annyang {
  isSupported() {
    // Annyang works best on Chromium-based browsers: Google Chrome, Microsoft Edge, Opera & Samsung Internet / Not Mozilla Firefox & Safari
    return annyang !== null && ['Chrome', 'Opera', 'Edge', 'SamsungBrowser'].includes(browserName)
  }

  start() {
    if (annyang) {
      // annyang.start()
      // TODO - Test the below
      annyang.start({ autoRestart: true, continuous: false })
      // annyang.start({ autoRestart: true, continuous: false }) // to make speech recognition results return faster
      // paused (boolean) can also be used to start annyang in paused mode.
    }
  }

  abort() {
    if (annyang) {
      annyang.abort()
    }
  }

  resume() {
    if (annyang) {
      annyang.resume()
    }
  }

  bindNavigationCommands(dispatch, navigationCommands, lngCode) {
    if (annyang) {
      annyang.removeCommands()
      annyang.addCommands(createCommands(dispatch, navigationCommands, lngCode))
    }
  }

  addTranscriptListener(dispatch) {
    if (annyang) {
      annyang.addCallback('result', transcript => {
        saveTranscript(
          dispatch,
          transcript.map(ts => ts.trim())
        )
      })
    }
  }

  setLanguage(language) {
    if (annyang) {
      annyang.setLanguage(language)
    }
  }

  // addCallback (engineCallback, resultCallback) {
  //   if (annyang) {
  //     annyang.addCallback('start', (event) => engineCallback('on'))
  //     annyang.addCallback('soundstart', (event) => engineCallback('listening'))
  //     annyang.addCallback('end', (event) => engineCallback('off'))
  //     annyang.addCallback('error', (event) => engineCallback(event.error))
  //     annyang.addCallback('errorNetwork', (event) =>
  //       engineCallback('network error')
  //     )
  //     annyang.addCallback('errorPermissionBlocked', (event) =>
  //       engineCallback('permission blocked')
  //     )
  //     annyang.addCallback('errorPermissionDenied', (event) =>
  //       engineCallback('permission denied')
  //     )
  //     annyang.addCallback('result', (event) => resultCallback(event))
  //   }
  // }
}

export default new Annyang()
