import React from 'react'
import {
  FadeIn,
  FadeOut,
  Shake,
  Jello,
  Flip,
  FlipInY,
  FlipOutY,
  BounceOut,
  Bounce,
  BounceIn,
  Pulse,
  RotateIn,
  RotateOut,
  Tada,
  Hinge,
  Wobble,
  BounceInDown,
  BounceInUp,
  BounceOutDown,
  BounceOutUp,
  RotateInUpRight,
  RotateInDownRight,
  RotateOutDownRight,
  SlideInLeft,
  SlideOutRight
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnMediumSubtractionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_medium_subtractions"
    sentencesNumberPlusRedirect={23}
    startTimesOf={{
      2: 3000,
      3: 7000,
      4: 13000,
      5: 17500,
      6: 23000,
      7: 28000,
      8: 38500,
      9: 43000,
      10: 47000,
      11: 55000,
      12: 64500,
      13: 79500,
      14: 84500,
      15: 94000,
      16: 98500,
      17: 103500,
      18: 110000,
      19: 113000,
      20: 122000,
      21: 131000,
      22: 137000,
      23: 141500
    }}
    animations={{
      1: (
        <div className="learn-flex-center-center">
          <FadeIn>
            <SlideInLeft>
              <FadeOut delay={2}>
                <SlideOutRight delay={2}>
                  <img
                    className="width-100"
                    src={`${baseUrl}/images/learn/pen-and-paper-medium-subtractions.webp`}
                  />
                </SlideOutRight>
              </FadeOut>
            </SlideInLeft>
          </FadeIn>
        </div>
      ),
      2: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <FadeIn className="math-line-small-fifth" delay={1}>
            <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/12/5.webp`} />
          </FadeIn>
          <FadeIn className="math-line-small-fifth mathematics-question-display" delay={2}>
            <img className="small-number height-50" src={`${mathsNumbersUrls}/12/minus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/12/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
          </FadeIn>
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth visibility-hidden" />
        </div>
      ),
      3: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <Wobble className="height-100" delay={5} duration={0.5}>
              <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
            </Wobble>
            <Wobble className="height-100" delay={4} duration={0.5}>
              <img className="small-number" src={`${mathsNumbersUrls}/12/5.webp`} />
            </Wobble>
          </div>
          <div className="math-line-small-fifth mathematics-question-display">
            <img className="small-number height-50" src={`${mathsNumbersUrls}/12/minus.webp`} />
            <Wobble className="height-100" delay={5} duration={0.5}>
              <img className="small-number" src={`${mathsNumbersUrls}/12/1.webp`} />
            </Wobble>
            <Wobble className="height-100" delay={4} duration={0.5}>
              <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
            </Wobble>
          </div>
          <FadeIn className="math-line-small-fifth" duration={0.5}>
            <img className="math-line-line" src={`${baseUrl}/images/lines/3.webp`} />
            <Shake className="arrow arrow-thirteen height-100" duration={3} delay={0.5}>
              <FadeOut className="height-100" delay={3.5} duration={0.5}>
                <img className="height-100" src={`${baseUrl}/images/arrows/13.webp`} />
              </FadeOut>
            </Shake>
          </FadeIn>
          <div className="math-line-small-fifth visibility-hidden" />
        </div>
      ),
      4: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth">
              <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
              <Wobble className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/12/5.webp`} />
              </Wobble>
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img className="small-number height-50" src={`${mathsNumbersUrls}/12/minus.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/12/1.webp`} />
              <Wobble className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
              </Wobble>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line" src={`${baseUrl}/images/lines/3.webp`} />
              <BounceInDown className="arrow arrow-one width-100" delay={1.5} duration={1.5}>
                <BounceOutDown className="width-80" delay={2.5} duration={2}>
                  <FadeOut className="width-100" delay={3}>
                    <img
                      className="arrow arrow-fourteen width-100"
                      src={`${baseUrl}/images/arrows/14.webp`}
                    />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>
            </div>
            <BounceIn className="math-line-small-fifth" delay={2.75}>
              <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
            </BounceIn>
          </div>
        </div>
      ),
      5: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <Wobble className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
            </Wobble>
            <img className="small-number" src={`${mathsNumbersUrls}/12/5.webp`} />
          </div>
          <div className="math-line-small-fifth mathematics-question-display">
            <img className="small-number height-50" src={`${mathsNumbersUrls}/12/minus.webp`} />
            <Wobble className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/12/1.webp`} />
            </Wobble>
            <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line" src={`${baseUrl}/images/lines/3.webp`} />
            <BounceInDown className="arrow arrow-one width-100" delay={1.5} duration={1.5}>
              <BounceOutDown className="width-80" delay={2.5} duration={2}>
                <FadeOut className="width-100" delay={3}>
                  <img
                    className="arrow arrow-fifteen width-100"
                    src={`${baseUrl}/images/arrows/15.webp`}
                  />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>
          </div>
          <div className="math-line-small-fifth">
            <BounceIn className="small-number" delay={3}>
              <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
            </BounceIn>
            <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
          </div>
        </div>
      ),
      6: (
        <FadeOut
          className="learn-math-equation-from-the-right-container"
          delay={4.5}
          duration={0.5}>
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <Pulse delay={1} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
            </Pulse>
            <Pulse delay={1} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/12/5.webp`} />
            </Pulse>
          </div>
          <div className="math-line-small-fifth mathematics-question-display">
            <img className="small-number height-50" src={`${mathsNumbersUrls}/12/minus.webp`} />
            <Pulse delay={2} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/12/1.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
            </Pulse>
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line" src={`${baseUrl}/images/lines/3.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <Tada delay={3} duration={1.5} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/12/2.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/12/3.webp`} />
            </Tada>
          </div>
        </FadeOut>
      ),
      7: (
        <div className="learn-flex-center-center">
          <FadeIn className="position-absolute max-100 magic-hat" duration={0.5}>
            <FadeOut className="max-100" delay={10} duration={0.5}>
              <img
                className="max-100 flexible-image"
                src={`${baseUrl}/images/learn/magic-hat.webp`}
              />
            </FadeOut>
          </FadeIn>
          <FadeIn delay={0.5} className="position-absolute max-100 magic-wand">
            <FadeOut className="max-100" delay={4} duration={0.5}>
              <Tada delay={1} duration={3.5}>
                <img
                  className="max-100 flexible-image"
                  src={`${baseUrl}/images/learn/magic-wand.webp`}
                />
              </Tada>
            </FadeOut>
          </FadeIn>
          <FadeIn
            delay={4.5}
            duration={0.25}
            className="position-absolute max-100 magic-hat-sparkle">
            <FadeOut className="max-100" delay={4.75} duration={0.25}>
              <img
                className="max-100 flexible-image"
                src={`${baseUrl}/images/learn/magic-hat-sparkle.webp`}
              />
            </FadeOut>
          </FadeIn>
          <FadeIn delay={5} duration={0.25} className="position-absolute max-100 magic-bunny">
            <SlideOutRight className="max-100" delay={9.75} duration={0.75}>
              <FadeOut className="max-100" delay={9.75} duration={0.75}>
                <Wobble delay={5.25} duration={4}>
                  <Shake delay={5.25} duration={4}>
                    <img
                      className="max-100 flexible-image"
                      src={`${baseUrl}/images/learn/magic-bunny.webp`}
                    />
                  </Shake>
                </Wobble>
              </FadeOut>
            </SlideOutRight>
          </FadeIn>
        </div>
      ),
      8: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <FadeIn className="math-line-small-fifth" delay={1}>
              <img className="small-number right-20" src={`${mathsNumbersUrls}/7/6.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
            </FadeIn>
            <FadeIn className="math-line-small-fifth mathematics-question-display" delay={2}>
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <img className="small-number right-13" src={`${mathsNumbersUrls}/7/2.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
            </FadeIn>
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth visibility-hidden" />
          </div>
        </div>
      ),
      9: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth">
              <img className="small-number right-20" src={`${mathsNumbersUrls}/7/6.webp`} />
              <Wobble delay={1.5} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
              </Wobble>
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <img className="small-number right-13" src={`${mathsNumbersUrls}/7/2.webp`} />
              <Wobble delay={1.75} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
              </Wobble>
            </div>
            <FadeIn className="math-line-small-fifth" duration={0.5}>
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
              <Shake className="flex height-100" duration={1.5} delay={0.5}>
                <FadeOut className="height-100" delay={2} duration={0.5}>
                  <img
                    className="arrow arrow-twenty-four height-100"
                    src={`${baseUrl}/images/arrows/24.webp`}
                  />
                </FadeOut>
              </Shake>
            </FadeIn>
            <div className="math-line-small-fifth visibility-hidden" />
          </div>
        </div>
      ),
      10: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth">
              <FadeIn delay={4.5} duration={0.5}>
                <Bounce className="medium-subtraction-plus" delay={5.5} duration={1.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/plus.webp`}
                  />
                </Bounce>
                <Bounce className="medium-subtraction-plus-one" delay={5.5} duration={1.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/1.webp`}
                  />
                </Bounce>
                <Bounce className="medium-subtraction-plus-zero" delay={5.5} duration={1.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/0.webp`}
                  />
                </Bounce>
              </FadeIn>
            </div>
            <div className="math-line-small-fifth">
              <img className="small-number right-20" src={`${mathsNumbersUrls}/7/6.webp`} />
              <Jello delay={1.5} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
              </Jello>
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <img className="small-number right-13" src={`${mathsNumbersUrls}/7/2.webp`} />
              <Jello delay={0.5} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
              </Jello>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
              <RotateInUpRight className="arrow arrow-twenty-three" delay={4}>
                <RotateOut className="height-100" delay={7} duration={0.5}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/23.webp`} />
                </RotateOut>
              </RotateInUpRight>
            </div>
            <div className="math-line-small-fifth visibility-hidden" />
          </div>
        </div>
      ),
      11: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth">
              <FadeOut delay={5}>
                <Wobble className="medium-subtraction-plus" delay={0.5} duration={2}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/plus.webp`}
                  />
                </Wobble>
                <Wobble className="medium-subtraction-plus-one" delay={0.5} duration={2}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/1.webp`}
                  />
                </Wobble>
                <Wobble className="medium-subtraction-plus-zero" delay={0.5} duration={2}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/0.webp`}
                  />
                </Wobble>
              </FadeOut>
              <BounceIn className="medium-subtraction-minus" delay={7}>
                <img className="width-100" src={`${mathsNumbersUrls}/13/minus.webp`} />
              </BounceIn>
              <BounceIn className="medium-subtraction-minus-one" delay={7}>
                <img className="width-100" src={`${mathsNumbersUrls}/13/1.webp`} />
              </BounceIn>
            </div>
            <div className="math-line-small-fifth">
              <img className="small-number right-20" src={`${mathsNumbersUrls}/7/6.webp`} />
              <Wobble delay={1.5} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
              </Wobble>
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <img className="small-number right-13" src={`${mathsNumbersUrls}/7/2.webp`} />
              <Wobble delay={2.5} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
              </Wobble>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
              <BounceInDown className="arrow arrow-sixteen" delay={4.5}>
                <BounceOutDown delay={5.5}>
                  <FadeOut delay={5.5}>
                    <img className="arrow arrow-sixteen" src={`${baseUrl}/images/arrows/16.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>
              <RotateIn delay={6} duration={0.5}>
                <FadeOut delay={7} duration={0.5}>
                  <img className="arrow arrow-eighteen" src={`${baseUrl}/images/arrows/18.webp`} />
                </FadeOut>
              </RotateIn>
            </div>
            <BounceIn className="math-line-small-fifth" delay={5}>
              <img className="small-number" src={`${mathsNumbersUrls}/7/9.webp`} />
            </BounceIn>
          </div>
        </div>
      ),
      12: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth">
              <FadeIn delay={4} duration={0.5}>
                <FadeOut className="medium-subtraction-plus" delay={4.5} duration={0.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/plus.webp`}
                  />
                </FadeOut>
                <FadeOut className="medium-subtraction-plus-one" delay={4.5} duration={0.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/1.webp`}
                  />
                </FadeOut>
                <FadeOut className="medium-subtraction-plus-zero" delay={4.5} duration={0.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/0.webp`}
                  />
                </FadeOut>
              </FadeIn>
              <Shake className="medium-subtraction-minus" duration={1.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/13/minus.webp`} />
              </Shake>
              <Shake className="medium-subtraction-minus-one" duration={1.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/13/1.webp`} />
              </Shake>
              <FadeIn delay={6} duration={0.5}>
                <FadeOut className="medium-subtraction-minus-zero" delay={10} duration={0.5}>
                  <img
                    className="position-absolute width-100"
                    src={`${mathsNumbersUrls}/13/0.webp`}
                  />
                </FadeOut>
              </FadeIn>
            </div>
            <div className="math-line-small-fifth">
              <img className="small-number right-20" src={`${mathsNumbersUrls}/7/6.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <img className="small-number right-13" src={`${mathsNumbersUrls}/7/2.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
              <FadeIn className="arrow arrow-thirteen-two height-100" delay={12} duration={0.5}>
                <Shake className="height-100" delay={12.5} duration={2}>
                  <FadeOut className="height-100" delay={14.5} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/13.webp`} />
                  </FadeOut>
                </Shake>
              </FadeIn>
            </div>
            <div className="math-line-small-fifth">
              <img className="small-number" src={`${mathsNumbersUrls}/7/9.webp`} />
            </div>
          </div>
        </div>
      ),
      13: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth">
              <div className="medium-subtraction-minus">
                <img className="width-100" src={`${mathsNumbersUrls}/13/minus.webp`} />
              </div>
              <div className="medium-subtraction-minus-one">
                <img className="width-100" src={`${mathsNumbersUrls}/13/1.webp`} />
              </div>
            </div>
            <div className="math-line-small-fifth">
              <Wobble delay={1} className="small-number right-20">
                <img className="height-100" src={`${mathsNumbersUrls}/7/6.webp`} />
              </Wobble>
              <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <Wobble delay={1.5} className="small-number right-13 height-100">
                <img className="height-100" src={`${mathsNumbersUrls}/7/2.webp`} />
              </Wobble>
              <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <FadeIn delay={1}>
                <FadeOut delay={3}>
                  <Wobble
                    delay={2}
                    className="small-number height-100 medium-subtraction-opacity-four">
                    <img className="height-100" src={`${mathsNumbersUrls}/7/4.webp`} />
                  </Wobble>
                </FadeOut>
              </FadeIn>
              <img
                className="small-number right-20 flex visibility-hidden"
                src={`${mathsNumbersUrls}/7/3.webp`}
              />
              <img className="small-number" src={`${mathsNumbersUrls}/7/9.webp`} />
            </div>
          </div>
        </div>
      ),
      14: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth">
              <Hinge className="medium-subtraction-minus" delay={2} duration={6}>
                <img className="width-100" src={`${mathsNumbersUrls}/13/minus.webp`} />
              </Hinge>
              <Hinge className="medium-subtraction-minus-one" delay={2} duration={6}>
                <img className="width-100" src={`${mathsNumbersUrls}/13/1.webp`} />
              </Hinge>
            </div>
            <div className="math-line-small-fifth">
              <img className="small-number right-20" src={`${mathsNumbersUrls}/7/6.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
            </div>
            <div className="math-line-small-fifth mathematics-question-display">
              <img
                className="small-number height-50 right-20"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <img className="small-number right-13" src={`${mathsNumbersUrls}/7/2.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
              <BounceInDown className="arrow-seventeen" delay={5.5} duration={0.5}>
                <BounceOutDown className="height-100" delay={6} duration={0.5}>
                  <FadeOut className="height-100" delay={6} duration={0.5}>
                    <img className="arrow height-100" src={`${baseUrl}/images/arrows/17.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>
            </div>
            <div className="math-line-small-fifth">
              <FadeIn delay={4} className="small-number height-100 medium-subtraction-opacity-four">
                <FadeOut
                  className="small-number height-100 medium-subtraction-opacity-four"
                  delay={6}>
                  <Flip
                    className="small-number height-100 medium-subtraction-opacity-four"
                    delay={5}>
                    <img className="height-100" src={`${mathsNumbersUrls}/7/4.webp`} />
                  </Flip>
                </FadeOut>
              </FadeIn>
              <BounceIn className="flex width-100 height-100" delay={6.5}>
                <Jello delay={6.5} duration={2.5} className="small-number height-100">
                  <img
                    className="small-number right-20 flex"
                    src={`${mathsNumbersUrls}/7/3.webp`}
                  />
                </Jello>
              </BounceIn>
              <img className="small-number" src={`${mathsNumbersUrls}/7/9.webp`} />
            </div>
          </div>
        </div>
      ),
      15: (
        <BounceOut delay={3} className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth">
              <Pulse className="small-number right-20">
                <img className="height-100" src={`${mathsNumbersUrls}/7/6.webp`} />
              </Pulse>
              <Pulse className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/5.webp`} />
              </Pulse>
            </div>
            <div className="math-line-small-fifth">
              <img
                className="small-number height-50 right-20 bottom-25"
                src={`${mathsNumbersUrls}/7/minus.webp`}
              />
              <Pulse delay={1} className="small-number right-13">
                <img className="small-number height-100" src={`${mathsNumbersUrls}/7/2.webp`} />
              </Pulse>
              <Pulse delay={1} className="height-100">
                <img className="small-number" src={`${mathsNumbersUrls}/7/6.webp`} />
              </Pulse>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-three" src={`${baseUrl}/images/lines/4.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <Tada className="flex width-100 height-100" delay={2}>
                <img className="small-number right-20 flex" src={`${mathsNumbersUrls}/7/3.webp`} />
              </Tada>
              <Tada className="flex width-100 height-100" delay={2}>
                <img className="small-number" src={`${mathsNumbersUrls}/7/9.webp`} />
              </Tada>
            </div>
          </div>
        </BounceOut>
      ),
      16: (
        <RotateIn className="learn-flex-center-center" duration={3}>
          <FadeOut delay={4} duration={0.5}>
            <RotateOut delay={4} duration={0.5}>
              <img className="width-100" src={`${baseUrl}/images/learn/mandala-1.webp`} />
            </RotateOut>
          </FadeOut>
        </RotateIn>
      ),
      17: (
        <div className="learn-flex-center-center">
          <RotateIn duration={2}>
            <FadeOut delay={2.5} duration={0.5}>
              <RotateOut delay={2.5} duration={0.5}>
                <img className="width-100" src={`${baseUrl}/images/learn/mandala-2.webp`} />
              </RotateOut>
            </FadeOut>
          </RotateIn>
        </div>
      ),
      18: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <BounceInDown className="math-line-small-fifth">
              <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
            </BounceInDown>
            <BounceInUp delay={1} className="math-line-small-fifth">
              <img
                className="super-small-sign medium-subtraction-minus-two"
                src={`${mathsNumbersUrls}/15/minus.webp`}
              />
              <img className="small-number" src={`${mathsNumbersUrls}/15/2.webp`} />
              <img className="small-number" src={`${mathsNumbersUrls}/15/6.webp`} />
            </BounceInUp>
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth visibility-hidden" />
          </div>
        </div>
      ),
      19: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth">
              <BounceOutDown className="small-number height-100" delay={4}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              </BounceOutDown>
              <BounceInUp className="small-number left-minus-12" delay={4}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/2.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/6.webp`} />
              </BounceInUp>
            </div>
            <div className="math-line-small-fifth">
              <img
                className="super-small-sign medium-subtraction-minus-two-nineteen"
                src={`${mathsNumbersUrls}/15/minus.webp`}
              />
              <BounceInDown className="small-number height-100" delay={4}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              </BounceInDown>
              <BounceOutUp className="small-number left-minus-12" delay={4}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/2.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/6.webp`} />
              </BounceOutUp>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-five" src={`${baseUrl}/images/lines/5.webp`} />
              <FadeIn delay={2.5} className="position-absolute width-100 height-100">
                <Flip delay={3} className="position-absolute width-100 height-100">
                  <FlipInY delay={3} className="position-absolute width-100 height-100">
                    <FlipOutY delay={5} className="position-absolute width-100 height-100">
                      <Flip delay={5} className="position-absolute width-100 height-100">
                        <FadeOut delay={5} className="position-absolute width-100 height-100">
                          <img
                            className="arrow arrow-twenty"
                            src={`${baseUrl}/images/arrows/20.webp`}
                          />
                        </FadeOut>
                      </Flip>
                    </FlipOutY>
                  </FlipInY>
                </Flip>
              </FadeIn>
              <RotateInDownRight delay={6} className="position-absolute width-100 height-100">
                <RotateOutDownRight
                  delay={8}
                  duration={0.5}
                  className="position-absolute width-100 height-100">
                  <img
                    className="arrow arrow-twenty-one"
                    src={`${baseUrl}/images/arrows/21.webp`}
                  />
                </RotateOutDownRight>
              </RotateInDownRight>
            </div>
            <div className="math-line-small-fifth">
              <BounceIn delay={7} duration={0.5} className="position-absolute width-100 height-100">
                <img
                  className="super-small-sign medium-subtraction-minus-two"
                  src={`${mathsNumbersUrls}/15/minus.webp`}
                />
              </BounceIn>
              <img
                className="small-number visibility-hidden"
                src={`${mathsNumbersUrls}/15/1.webp`}
              />
              <img
                className="small-number visibility-hidden"
                src={`${mathsNumbersUrls}/15/5.webp`}
              />
            </div>
          </div>
        </div>
      ),
      20: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth">
              <BounceInUp className="small-number height-100" delay={6}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              </BounceInUp>
              <BounceOutDown className="small-number left-minus-12" delay={6}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/2.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/6.webp`} />
              </BounceOutDown>
            </div>
            <div className="math-line-small-fifth">
              <img
                className="super-small-sign medium-subtraction-minus-two-nineteen"
                src={`${mathsNumbersUrls}/15/minus.webp`}
              />
              <BounceOutUp className="small-number height-100" delay={6}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              </BounceOutUp>
              <BounceInDown className="small-number left-minus-12" delay={6}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/2.webp`} />
                <img className="small-number" src={`${mathsNumbersUrls}/15/6.webp`} />
              </BounceInDown>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-five" src={`${baseUrl}/images/lines/5.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <img
                className="super-small-sign medium-subtraction-minus-two"
                src={`${mathsNumbersUrls}/15/minus.webp`}
              />
              <BounceIn delay={1} duration={0.5}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              </BounceIn>
              <BounceIn delay={1} duration={0.5}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/5.webp`} />
              </BounceIn>
            </div>
          </div>
        </div>
      ),
      21: (
        <BounceOut delay={4} className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-fifth visibility-hidden" />
            <div className="math-line-small-fifth">
              <Pulse className="small-number">
                <img className="height-100" src={`${mathsNumbersUrls}/15/1.webp`} />
              </Pulse>
              <Pulse className="small-number">
                <img className="height-100" src={`${mathsNumbersUrls}/15/1.webp`} />
              </Pulse>
            </div>
            <div className="math-line-small-fifth">
              <img
                className="super-small-sign medium-subtraction-minus-two"
                src={`${mathsNumbersUrls}/15/minus.webp`}
              />
              <Pulse delay={1} className="small-number">
                <img className="small-number" src={`${mathsNumbersUrls}/15/2.webp`} />
              </Pulse>
              <Pulse delay={1} className="small-number">
                <img className="small-number" src={`${mathsNumbersUrls}/15/6.webp`} />
              </Pulse>
            </div>
            <div className="math-line-small-fifth">
              <img className="math-line-line-five" src={`${baseUrl}/images/lines/5.webp`} />
            </div>
            <div className="math-line-small-fifth">
              <Tada className="medium-subtraction-minus-two super-small-sign" delay={2}>
                <img
                  className="flex width-100 height-100"
                  src={`${mathsNumbersUrls}/15/minus.webp`}
                />
              </Tada>
              <Tada className="flex width-100 height-100" delay={2}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/1.webp`} />
              </Tada>
              <Tada className="flex width-100 height-100" delay={2}>
                <img className="small-number" src={`${mathsNumbersUrls}/15/5.webp`} />
              </Tada>
            </div>
          </div>
        </BounceOut>
      ),
      22: (
        <div className="learn-flex-center-center">
          <BounceIn duration={0.5}>
            <Bounce delay={1} duration={1}>
              <BounceOut delay={2} duration={0.5}>
                <img
                  className="width-100"
                  src={`${baseUrl}/images/learn/learn-medium-subtractions-end.webp`}
                />
              </BounceOut>
            </Bounce>
          </BounceIn>
        </div>
      )
    }}
  />
)

export default LearnMediumSubtractionsPage
