import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
// import '../styles/scss/App.scss'
import annyang from '../lib/annyangSetUp'
import { translateFrom, common, commonTranslateArray } from '../lib/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import { baseUrl, isDevDisableReadOutLoud } from '../lib/_processUtil'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import {
  isGeographyPage,
  getRandomItemFromList,
  isRedirectButtonClick,
  isSpeakRequest,
  isStartRequest,
  isStopRequest,
  isSpeakSoundDisabled,
  createBorderRadius,
  isTranscriptAMatch,
  capitalizeWord,
  getPageTitleColor,
  getTopic
} from '../lib/methodsUtil'
import PageHeader from './PageHeader'
import SpeechButton from './SpeechButton'
import ImageWithDimensions from './ImageWithDimensions'
import NavigationButtons from './NavigationButtons'
import LearnDisplay from './LearnDisplay'
import { learnMapStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import { wasPathChanged } from '../../src/router/routerUtil'
import { translatedLearning } from '../../src/lib/languageUtil'
import {
  saveTranscriptAction,
  prepareLearningAction,
  startLearningAction,
  updateLearningItemAction,
  resetLearningAction
} from '../redux/actions/actions'

const disableReadOutLoud = isDevDisableReadOutLoud

const intervalTime = 5000

// TODO - stop doesnt seem to work in learning page

// This is a hack because for all my cases clearInterval(this.myIntervalRef.current) doesn't work
const clearAllIntervals = () => {
  for (let i = 0; i < 1000000; i++) {
    clearInterval(i)
  }
}

class LearnPage extends Component {
  constructor(props) {
    super(props)
    this.startLearning = this.startLearning.bind(this)
    this.startLearningClick = this.startLearningClick.bind(this)
    this.stopLearning = this.stopLearning.bind(this)
    this.myIntervalRef = createRef()
    this.state = { isStopClick: false }
  }

  componentDidMount() {
    const { dispatch, lngCode, pageName, parentPage, grandParentPage } = this.props
    const navigationCommands = [parentPage, grandParentPage, 'menu']
    annyang.bindNavigationCommands(dispatch, commonTranslateArray(navigationCommands), lngCode)
    annyang.start()
    annyang.addTranscriptListener(dispatch)
    dispatch(prepareLearningAction({ pageName }))
  }

  componentDidUpdate(prevProps) {
    const { actual: prevActual } = prevProps
    const {
      dispatch,
      lngCode,
      language,
      gender,
      transcript,
      pageName,
      actual,
      translatedActual,
      extra
    } = this.props

    if (isStopRequest(transcript) || !actual) {
      clearInterval(this.myIntervalRef.current)
    } else {
      if (actual && prevActual !== actual) {
        annyang.abort()
        const phrase = getRandomItemFromList(
          translatedLearning[pageName.replace(/famous_|other_/g, '')][lngCode](
            translatedActual,
            extra
          )
        )
        disableReadOutLoud ? null : readOutLoud(phrase, dispatch, language, gender, annyang.start())
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const { isLearning: oldIsLearning, actual: prevActual } = this.props
    const { transcript, path, parentPage, grandParentPage, isLearning, actual } = nextProps

    const navigationOptions = ['start', 'stop', parentPage, grandParentPage, 'menu']
    const translatedCommands = commonTranslateArray(navigationOptions)

    return (
      isTranscriptAMatch(transcript, translatedCommands) ||
      isRedirectButtonClick(path, navigationOptions) ||
      oldIsLearning !== isLearning ||
      prevActual !== actual ||
      isSpeakRequest(transcript)
    )
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    annyang.abort()
    dispatch(resetLearningAction())
    clearInterval(this.myIntervalRef.current)
  }

  startLearning() {
    const { dispatch, lngCode, pageName } = this.props
    dispatch(startLearningAction())
    this.setState({ isStopClick: false })
    // first iterm being read right away, then intervals take over
    dispatch(updateLearningItemAction({ pageName, lngCode }))
    sendAmplitudeEvent('Learning Page Visits', {
      topic: getTopic(pageName),
      action: 'vocal'
    })

    this.myIntervalRef.current = setInterval(() => {
      const { transcript } = this.props
      const { isStopClick } = this.state
      if (isStopRequest(transcript) || isStopClick) {
        clearInterval(this.myIntervalRef.current)
        clearAllIntervals()
        dispatch(prepareLearningAction({ pageName }))
      } else {
        dispatch(updateLearningItemAction({ pageName, lngCode }))
      }
    }, intervalTime)
  }

  // for some reasons, this.myIntervalRef.current = setInterval(() => {works well with the voice
  // but not with the click, where a new name needs to be given.
  startLearningClick() {
    const { dispatch, lngCode, pageName } = this.props
    dispatch(startLearningAction())
    this.setState({ isStopClick: false })
    // first iterm being read right away, then intervals take over
    dispatch(updateLearningItemAction({ pageName, lngCode }))
    sendAmplitudeEvent('Learning Page Visits', {
      topic: getTopic(pageName),
      action: 'click'
    })

    const intervalStartClick = setInterval(() => {
      const { transcript } = this.props
      const { isStopClick } = this.state
      if (isStopRequest(transcript) || isStopClick) {
        clearInterval(intervalStartClick)
        clearAllIntervals()
        dispatch(prepareLearningAction({ pageName }))
      } else {
        dispatch(updateLearningItemAction({ pageName, lngCode }))
      }
    }, intervalTime)
  }

  stopLearning() {
    const { dispatch, pageName } = this.props
    this.setState({ isStopClick: true })
    clearInterval(this.myIntervalRef.current)
    dispatch(prepareLearningAction({ pageName }))
  }

  render() {
    const {
      path,
      dispatch,
      pageName,
      parentPage,
      grandParentPage,
      parentPagePath,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel,
      isLearning,
      actual,
      translatedActual,
      getImageUrl,
      randomNumber = 0,
      mapFillColorOne,
      mapFillColorTwo
    } = this.props

    const translate = translateFrom(pageName)
    const learnGeoTranslate = translateFrom('learn_geography_page')
    const isGeographyLearnPage = isGeographyPage(pageName)

    const translatedTopic = isGeographyLearnPage
      ? [
          learnGeoTranslate(`topic_${pageName.split('_').reverse()[0]}`),
          learnGeoTranslate('topic_geography')
        ]
      : [parentPage, grandParentPage].map(command => translate(`topic_${command}`).toLowerCase())

    const intro = translate('intro')
    const topics = [
      common('topic_start'),
      common('topic_stop'),
      ...translatedTopic,
      common('topic_menu')
    ]

    const readOutLoudNavigationText = `${intro} ${topics}`
    const isSpeakDisabled = isSpeakSoundDisabled(soundLevel)
    if (isSpeakRequest(transcript) && !isSpeakDisabled && !disableReadOutLoud) {
      readOutLoud(readOutLoudNavigationText, dispatch, language, gender)
    }

    const isStart = isStartRequest(transcript)
    if (isStart) {
      dispatch(saveTranscriptAction([]))
      this.startLearning()
    }

    const grandParentSliceIndex = parentPagePath.lastIndexOf('/')
    const mappedCommandpathNavigation = {
      [common(parentPage).toLowerCase()]: parentPagePath,
      [common(grandParentPage).toLowerCase()]: parentPagePath.substr(0, grandParentSliceIndex),
      [common('menu').toLowerCase()]: '/menu'
    }

    // Funky logic so the stop button border radius depends on a logc on the page name
    const multiplier = pageName.split('_')[1].length
    const createStopButtonRadius = multiplier =>
      `50%/${15 + (multiplier.length > 10 ? multiplier * 2 : multiplier * 5)}% ${
        70 - (multiplier % 2 === 0 ? multiplier : multiplier * 3)
      }% ${15 + multiplier * 3}%`

    return (
      <div className={`${pageName.replace(/_/g, '-')} top-navigation learn-page`}>
        {isLearning ? (
          <>
            <div className="learn-page-title" style={{ color: getPageTitleColor(path) }}>
              {isGeographyLearnPage ? translateFrom(pageName)('title') : common(pageName)}
            </div>
            <LearnDisplay
              pageName={pageName}
              actual={actual}
              translatedActual={translatedActual}
              getImageUrl={getImageUrl}
              randomNumber={randomNumber}
              mapFillColorOne={mapFillColorOne}
              mapFillColorTwo={mapFillColorTwo}
            />
            <div>
              <div
                style={{ borderRadius: createStopButtonRadius(multiplier) }}
                className="learn-page-stop-button-image"
                onClick={this.stopLearning}
                alt="learn-page-stop-button">
                <span>STOP</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="navigation-box" style={createBorderRadius(0.35)}>
              <PageHeader title={translate('title')} intro={intro} topics={topics} />

              <AppListeningSpeakingVisual />

              <AnnyangNotSupportedMenuButton />

              <NavigationButtons
                customNavigationCommands={mappedCommandpathNavigation}
                lngCode={lngCode}
                dispatch={dispatch}
                otherButton={
                  <button style={createBorderRadius(0.14)} onClick={this.startLearningClick}>
                    <span className="menu-button-icon-wrapper">
                      <ImageWithDimensions
                        alt="start icon"
                        className="menu-button-icon"
                        src={`${baseUrl}/images/icons/start.webp`}
                      />
                    </span>
                    <span className="menu-button-command-wrapper">
                      {capitalizeWord(common('start'))}
                    </span>
                  </button>
                }
              />
            </div>

            {!isSpeakDisabled && (
              <SpeechButton
                className={`${pageName.replace(/_/g, '-')}-speech-button`}
                text={readOutLoudNavigationText}
              />
            )}

            {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
          </>
        )}
      </div>
    )
  }
}

export default connect(learnMapStateToProps, passDispatchToProps)(LearnPage)
