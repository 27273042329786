import { baseUrl } from '../../../lib/_processUtil'
import { answerQuestionMap, alsoAcceptedAsAnswersMap, foreignAnswers } from './answerQuestionMap'
import {
  createInvertedAnswerQuestionMap,
  convertCamelCaseWordToTitleCase
} from '../../../lib/methodsUtil'
import { top30Countries } from '../../../lib/geographyUtil'
import { translatedQuestionsAndAnswers } from '../../../lib/languageUtil'
import { randomizeACountryAction, resetGeographyAction } from '../../../redux/actions/actions'

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.geography.capitals

const getSmallCountryImageUrl = country =>
  `${baseUrl}/images/geography/capitalsAndLocations/smallCountriesCapitals/${answerQuestionMap.en[country]}.webp`

const allCapitalsList = Object.keys(answerQuestionMap.en)

const allCountriesList = Object.values(answerQuestionMap.en)

const makeQuestionText = (country, lngCode, multipleChoices) =>
  questionText[lngCode](country, multipleChoices)

const makeQuestionAnswer = (answer, lngCode = 'en') =>
  questionAnswer[lngCode](answer, answerQuestionMap)

const questionAnswerMap = (lngCode = 'en') =>
  createInvertedAnswerQuestionMap(answerQuestionMap)[lngCode]

const easyCapitalsList = top30Countries.map(country => questionAnswerMap('en')[country])

const isEasyQuestion = enCountry => top30Countries.includes(enCountry)

const verySmallCountriesList = [
  'Antigua and Barbuda',
  'Kiribati',
  'Maldives',
  'Marshall Islands',
  'Monaco',
  'Palau',
  'Swaziland',
  'Tuvalu',
  'Vatican'
]

const isVerySmallCountry = country => verySmallCountriesList.includes(country)

const commonProps = {
  navigationCommands: ['menu', 'geography'],
  imageAlt: 'country capital',
  randomizeQuestionFunction: randomizeACountryAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetGeographyAction
}

const gameTypeProps = ({
  lngCode,
  questionType,
  questionLevel,
  country,
  capital,
  multipleChoices,
  enCountry
}) => {
  const isCountryTooSmallForMap = isVerySmallCountry(enCountry)

  const levelQuestionTextMap = {
    easy: makeQuestionText(country, lngCode, multipleChoices),
    medium: makeQuestionText(
      country,
      lngCode,
      isEasyQuestion(enCountry) ? undefined : multipleChoices
    ),
    hard: makeQuestionText(country, lngCode)
  }

  return {
    pageName: `${questionLevel}_${questionType}`,
    questionClue: convertCamelCaseWordToTitleCase(country),
    questionText: levelQuestionTextMap[questionLevel],
    questionAnswer: makeQuestionAnswer(capital, lngCode),
    answerQuestionMap,
    displayType: isCountryTooSmallForMap ? 'image' : 'countryMap',
    getImageUrl: isCountryTooSmallForMap && getSmallCountryImageUrl,
    alsoAcceptedAsAnswers:
      lngCode === 'en'
        ? alsoAcceptedAsAnswersMap['en']
        : { ...foreignAnswers[lngCode], ...alsoAcceptedAsAnswersMap['fr'] }
  }
}

export {
  easyCapitalsList,
  allCapitalsList,
  allCountriesList,
  getSmallCountryImageUrl,
  isVerySmallCountry,
  isEasyQuestion,
  questionAnswerMap,
  commonProps,
  gameTypeProps
}
