import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnCapitalsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'famous_ones', 'others', 'capitals', 'geography']}
    pageName="learn_capitals_page"
    topics={['topic_famous_ones', 'topic_others', 'topic_capitals', 'topic_geography']}
    topicNavigationPath="geography/capitals/learn"
    subTopics={['famous_ones', 'others']}
    borderRadius={0.36}
    canonicalPath="/geography"
  />
)

export default LearnCapitalsPage
