import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const GeographyPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'capitals', 'flags', 'locations', 'country_select']}
    pageName="geography_page"
    topics={['topic_capitals', 'topic_flags', 'topic_locations', 'topic_country_select']}
    topicNavigationPath="geography"
    subTopics={['capitals', 'flags', 'locations', 'country_select']}
    comingSoonTopics={['country_select']}
    newTopics={['flags', 'locations']}
    borderRadius={0.25}
    SEODescription="E=MC² - Explore Many Countries² - Speech Commanded Games: Easy, Medium & Hard levels. Play, Learn & have fun with Countries Flags, Capitals & Locations"
  />
)

export default GeographyPage
