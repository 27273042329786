import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnSubtractionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'beginner', 'easy', 'medium', 'subtractions', 'mathematics']}
    pageName="learn_subtractions_page"
    topics={[
      'topic_beginner',
      'topic_easy',
      'topic_medium',
      'topic_subtractions',
      'topic_mathematics'
    ]}
    topicNavigationPath="mathematics/subtractions/learn"
    subTopics={['beginner', 'easy', 'medium']}
    borderRadius={0.65}
    canonicalPath="/mathematics"
  />
)

export default LearnSubtractionsPage
