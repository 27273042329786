import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import EN from './locales/en.json'
import FR from './locales/fr.json'

const resources = {
  en: { translation: EN },
  fr: { translation: FR }
}

const detectionOptions = {
  order: ['navigator']
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: detectionOptions,
    resources,
    fallbackLng: 'en',
    nsSeparator: '::',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
