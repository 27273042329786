import { colors } from '../../../../src/lib/dataUtil'
import {
  createSameKeyValueObject,
  createInvertedAnswerQuestionMap
} from './../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from './../../../lib/languageUtil'
import { randomizeAWordAction, resetWordsAction } from '../../../redux/actions/actions'

const colorsList = colors

const questionAnswerMap = {
  en: createSameKeyValueObject(colorsList),
  fr: {
    red: 'rouge',
    yellow: 'jaune',
    blue: 'bleu',
    green: 'vert',
    orange: 'orange',
    purple: 'violet',
    pink: 'rose',
    brown: 'marron',
    grey: 'gris',
    black: 'noir',
    white: 'blanc'
  }
}

const answerQuestionMap = createInvertedAnswerQuestionMap(questionAnswerMap)

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.words.colors

const makeQuestionText = lngCode => questionText[lngCode]

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const commonProps = {
  navigationCommands: ['menu', 'words'],
  imageAlt: 'colors',
  randomizeQuestionFunction: randomizeAWordAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetWordsAction,
  displayType: 'color'
}

const gameTypeProps = ({ lngCode, questionType, color }) => {
  return {
    pageName: `${questionType}_page`,
    questionText: makeQuestionText(lngCode),
    questionAnswer: makeQuestionAnswer(color, lngCode),
    answerQuestionMap
  }
}

export { colorsList, questionAnswerMap, answerQuestionMap, commonProps, gameTypeProps }
