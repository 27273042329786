import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction } from '../redux/actions/actions'

// NOT USED AT THE MOMENT
export const premiumTopics = [
  'food',
  'letters-and-numbers',
  'subtractions',
  'multiplications',
  'divisions',
  'capitals',
  'flags/medium',
  'flags/hard'
]

const isPremiumTopicPath = path =>
  premiumTopics.some(premiumTopic => path.indexOf(premiumTopic) > -1)

const isLockedPremiumTopic = (user, path) => {
  let isUnlockedTopic = false
  if (user) {
    isUnlockedTopic = user.premium
      ? true
      : user.unlockedPremiumTopics.some(unlockedTopic => path.indexOf(unlockedTopic) > -1)
  }
  return isPremiumTopicPath(path) && !isUnlockedTopic
}

class LockedPremiumTopicsRedirect extends Component {
  componentDidMount() {
    const { dispatch, path, user } = this.props

    if (isLockedPremiumTopic(user, path)) {
      dispatch(changePathAction('/shop'))
    }
  }

  componentDidUpdate() {
    const { dispatch, path, user } = this.props

    if (isLockedPremiumTopic(user, path)) {
      dispatch(changePathAction('/shop'))
    }
  }

  render() {
    const { path } = this.props
    return <Navigate to={path} replace />
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(LockedPremiumTopicsRedirect)
