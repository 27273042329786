import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 808
const height = 323

const cities = {
  Kingston: { x: 582, y: 207 }
}

const Jamaica = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map jamaica">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="JM-03"
          title="Saint Thomas"
          className="land"
          d="M773.63,200.63L674.36,190.73L638.33,170.99L642.54,207.33L627.62,226.18L647.46,249.97L734.89,252.57L798.73,231.63L773.63,200.63z"
        />
        <path
          id="JM-02"
          title="Saint Andrew"
          className="land"
          d="M593.24,146.05L548.97,142.34L542.84,188.68L557.31,202.19L570.99,210.14L591.78,200.53L602.17,216.94L563.76,223.54L627.62,226.18L642.54,207.33L638.33,170.99L601.83,167.58L593.24,146.05z"
        />
        <path
          id="JM-05"
          title="Saint Mary"
          className="land"
          d="M611.47,96.85L578.31,90.47L540.91,42.11L476.94,40.54L503.9,106.38L519.87,106.05L548.97,142.34L593.24,146.05L611.47,96.85z"
        />
        <path
          id="JM-14"
          title="Saint Catherine"
          className="land"
          d="M548.97,142.34L519.87,106.05L503.9,106.38L425.69,127.83L453.51,243.27L479.18,237.76L488.62,258.03L523.7,263.02L557.31,202.19L542.84,188.68L548.97,142.34z"
        />
        <path
          id="JM-06"
          title="Saint Ann"
          className="land"
          d="M476.94,40.54L332.91,16.43L323.48,123.99L425.69,127.83L503.9,106.38L476.94,40.54z"
        />
        <path
          id="JM-04"
          title="Portland"
          className="land"
          d="M773.63,200.63L739.22,139.38L611.47,96.85L593.24,146.05L601.83,167.58L638.33,170.99L674.36,190.73L773.63,200.63z"
        />
        <path
          id="JM-01"
          title="Kingston"
          className="land"
          d="M602.17,216.94L591.78,200.53L570.99,210.14L602.17,216.94z"
        />
        <path
          id="JM-10"
          title="Westmoreland"
          className="land"
          d="M166.57,85.58L101.37,69.26L11.71,84.88L1.27,97.31L13.65,114.1L119.57,128.2L150.81,179.83L175.88,124.2L166.57,85.58z"
        />
        <path
          id="JM-07"
          title="Trelawny"
          className="land"
          d="M332.91,16.43L234.35,4.65L217.63,109.27L269.09,114.49L323.48,123.99L332.91,16.43z"
        />
        <path
          id="JM-08"
          title="Saint James"
          className="land"
          d="M173.53,0L137.85,27.72L166.57,85.58L175.88,124.2L217.63,109.27L234.35,4.65L173.53,0z"
        />
        <path
          id="JM-11"
          title="Saint Elizabeth"
          className="land"
          d="M269.09,114.49L217.63,109.27L175.88,124.2L150.81,179.83L194.23,192.69L196.38,218.54L236.12,254.88L296.71,254L269.09,114.49z"
        />
        <path
          id="JM-12"
          title="Manchester"
          className="land"
          d="M323.48,123.99L269.09,114.49L296.71,254L358.7,255.43L371.68,238.49L323.48,123.99z"
        />
        <path
          id="JM-09"
          title="Hanover"
          className="land"
          d="M137.85,27.72L53.8,28.66L15.58,59.72L11.71,84.88L101.37,69.26L166.57,85.58L137.85,27.72z"
        />
        <path
          id="JM-13"
          title="Clarendon"
          className="land"
          d="M444.93,312.94L451.27,289.17L423.65,286.55L438.8,284.2L453.51,243.27L425.69,127.83L323.48,123.99L371.68,238.49L358.7,255.43L444.93,312.94z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Jamaica))
