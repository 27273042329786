import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 464

const cities = {
  Kathmandu: { x: 521, y: 291 }
}

const Nepal = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map nepal">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="NP-BA"
          title="Bagmati"
          className="land"
          d="M507.44,235.82L511.05,241.31L519.81,239.21L523.57,241.14L527.9,237.06L531.47,235.78L538.42,236.28L544.31,239.2L548.05,244.42L550.62,244.52L557.99,250.62L561.83,249.25L574.11,259.77L575.79,266.13L580.89,274.44L580.09,279.29L590.93,282.63L590.93,282.63L589.48,288.49L586.11,291.19L588.65,303.65L580.95,304.42L572.95,319.28L567.35,322.87L568,334.81L561.6,339.3L556.95,339.71L556.92,343L554.47,346.71L546.6,346.09L534.81,341.98L534.81,341.98L517.51,335.44L511.77,331.26L511.56,322L506.82,309.83L500.8,309.04L490.85,304.85L481.17,308.42L477.43,306.58L474.94,310.02L468.06,309.3L466.29,308.08L465.89,305.65L462.3,303.22L458,304.13L449.74,302.45L449.44,300.98L451.97,297.74L451.61,294.69L454.46,291.51L454.46,291.51L458.15,294.95L463.42,292.72L461.91,281.05L468.21,273.94L472.18,263.17L478.94,262.52L483.42,257.55L485.07,247.63L488.44,245.45L490.65,239.14L497.36,235.42L497.36,235.42L501.14,236.55L505.77,234.63z"
        />
        <path
          id="NP-BH"
          title="Bheri"
          className="land"
          d="M148.15,147.37L150.36,148.09L148.32,152.3L148.79,154.8L152.6,157.22L158.55,155.43L161.49,156.18L163.47,159.67L172.37,160.79L177.29,165.09L181.43,162.43L184.42,163.39L187.78,162.34L189.78,160.34L200.32,164.22L203.11,162.72L207.55,154.52L219.97,147.44L232.47,149.2L231.45,160.41L233.48,162.01L240.21,159.89L247.58,162.1L247.58,162.1L242.5,165.08L242.11,171.65L239.55,174.52L230.53,179.4L225.79,185.68L221.94,185.09L219.35,188.47L219.95,192.81L212.16,196.54L209.72,204.62L198.02,201.85L193.49,202.77L192.26,207.29L194.65,216.13L189.2,223.14L190.61,229.59L185.36,235.88L182.76,235.78L170.94,229.22L167.75,232.21L166.86,237.48L171.83,242.87L175.84,242.47L182.55,246.44L184.87,250.2L192.95,254.35L197.74,261.88L205.71,264.25L206.55,267.95L210.1,270.06L210.84,278.68L206.25,277.76L197.78,280.64L197.78,280.64L188.15,280.27L183.48,287.9L179.62,287.75L174.18,283.36L171.38,282.82L160.91,273.22L154.28,271.67L139.44,262.97L139.66,259.33L134.25,253.01L129.55,252.77L129.37,255.86L123.49,256.95L124.38,254.56L122.28,253.77L124.25,253.01L123.57,249.58L115.7,240.8L115.37,235.86L113.26,232.3L102.22,230.04L98.62,227.78L98.62,227.78L101.7,221.07L109.67,214.61L114.19,206.87L121.47,198.74L115.81,184.87L114.69,178.04L112.5,178.62L110.02,182.84L106.73,180.63L106.69,177.55L94.84,173.24L91.38,167.5L102.22,164.97L113.43,171.62L121.59,172.31L126.88,171.14L128.53,173.19L136.01,171.45L146.15,187.02L148.16,187.94L150.75,187.1L146.28,178.36L136.5,165.08L136.47,158.31L140.09,153.39L148.32,148.4z"
        />
        <path
          id="NP-DH"
          title="Dhawalagiri"
          className="land"
          d="M387.65,126.94L387.76,129.36L389.67,130.52L392.05,129.31L394.63,130.84L397.39,130.41L401.37,134.66L407.17,136.74L404.5,140.56L403.43,150.87L399.51,155.75L404.39,162.77L402.4,173.8L402.4,173.8L399.14,174.82L391.04,183.06L382.54,181.26L380.69,187.88L370.77,194.13L370.84,197.3L376,205.04L375.4,206.42L373.15,205.77L369.74,207.69L371.27,215.03L362.56,223.36L358.44,230.74L362.79,232.59L365.05,235.22L366.52,241.84L370.66,244.51L370.47,245.58L368.51,248.75L364.43,247.81L363.48,256.21L360.6,258.98L360.46,267.02L357.45,270.81L353.86,272.71L349.19,271.07L349.19,271.07L345.25,263.49L340.19,260.84L336.24,261.95L332.31,260L328.95,255.1L319,251.9L317.56,250.34L319.91,246.95L319.72,243.54L315.15,243.75L313.65,242.44L298.61,245.14L298.61,245.14L298.55,243.19L293.85,237.85L286.41,233.67L280.87,227.53L277.27,222.07L277.2,220.59L279.74,220.09L276.87,215.9L280.67,213.9L282.31,211.27L287.12,212.23L288.16,210.12L285.56,206.42L287.63,204.51L294.01,204.98L300.56,201.83L304.64,193.75L304.15,190.78L304.15,190.78L305.05,189.85L308.97,192.52L316.91,193.75L337.57,185.14L339.37,175.91L350.66,161.91L351.75,151.08L352.86,149.48L355.68,148.99L357.29,145.28L353.23,140.01L353.23,140.01L360.35,133.91L368.57,134.05L371.5,129.68L375.83,127.19L385.28,126.01z"
        />
        <path
          id="NP-GA"
          title="Gandaki"
          className="land"
          d="M413.53,171L416.19,174.42L421.82,177.1L427.21,177.82L429.86,180.45L430.72,182.54L429.1,190.65L432.03,189.81L438.43,192.83L447.53,190.35L454.04,192.69L457.01,199.7L455.79,204.51L460.61,206.29L468.63,206.95L472.26,209.48L480.13,204.37L486.06,205.46L493.17,198.12L499.09,197.27L501.03,198.72L505.16,203.93L504.51,210.33L499.94,221.85L497.36,235.42L497.36,235.42L490.65,239.14L488.44,245.45L485.07,247.63L483.42,257.55L478.94,262.52L472.18,263.17L468.21,273.94L461.91,281.05L463.42,292.72L458.15,294.95L454.46,291.51L454.46,291.51L450.19,285.92L446.19,286.27L439.25,291.66L431.15,294.31L432.96,301.17L432.96,301.17L424.78,299.59L422.61,301.73L415.73,290.18L369.24,287.17L366.28,284.71L360.99,283.17L357.29,282.85L353.96,284.35L345.96,280.86L333.13,280.93L335.28,277.76L345.71,275.73L349.19,271.07L349.19,271.07L353.86,272.71L357.45,270.81L360.46,267.02L360.6,258.98L363.48,256.21L364.43,247.81L368.51,248.75L370.47,245.58L370.66,244.51L366.52,241.84L365.05,235.22L362.79,232.59L358.44,230.74L362.56,223.36L371.27,215.03L369.74,207.69L373.15,205.77L375.4,206.42L376,205.04L370.84,197.3L370.77,194.13L380.69,187.88L382.54,181.26L391.04,183.06L399.14,174.82L402.4,173.8L402.4,173.8z"
        />
        <path
          id="NP-JA"
          title="Janakpur"
          className="land"
          d="M606.82,274.72L615.89,275.21L619.26,278.72L626.38,276.31L624.2,280.69L625.67,282.49L630.99,279.74L635.32,281.61L636.55,279.13L635.62,277.55L635.62,277.55L639.16,281.96L640.51,286.46L639.04,292.98L641.61,300.02L641.24,304.62L633.13,308.35L625.87,320.5L622.45,322.47L608.11,341.09L609.55,350.35L607.01,355.42L612.08,356.9L622.94,364.72L620.64,371.85L622.09,379.48L619.95,388.41L614.09,391.05L609.35,395.82L605.33,396.12L599.02,394.28L600.06,403.95L598.99,409.47L604.44,418.68L601.41,424.9L601.41,424.9L597.49,425.9L597.56,423.45L590.66,419.94L581.23,419.88L579.35,420.94L578.15,424.67L568.71,429.67L569.07,425.46L565.74,426.02L564.16,423.62L557.77,420.86L556.47,417.5L557.58,411.23L556.68,402.65L548.51,398.65L547.91,396.55L543.11,399.18L541.93,397.93L541.05,399.99L530.38,406.42L525.53,405.45L518.38,410.85L518.38,410.85L520.86,401.81L527.71,389.98L526.4,382.27L527.89,377.79L534.63,371.14L536.37,359.55L534.83,355.95L531.17,352.9L529.99,348.95L534.81,341.98L534.81,341.98L546.6,346.09L554.47,346.71L556.92,343L556.95,339.71L561.6,339.3L568,334.81L567.35,322.87L572.95,319.28L580.95,304.42L588.65,303.65L586.11,291.19L589.48,288.49L590.93,282.63L590.93,282.63L596.91,279.35L594.97,273.78L592.22,271.15L592.26,261.44L600.84,253.7L605.23,261.47L603.29,264.04L604.47,271.37z"
        />
        <path
          id="NP-KA"
          title="Karnali"
          className="land"
          d="M156.18,0.26L168.35,5.48L186.99,8.95L192.09,11.08L196.66,10.42L206.53,12.15L205.4,15.46L207.08,23L211.97,29.14L209.79,37L210.88,38.58L231.37,45.6L233.82,47.64L240.36,48.19L242.27,50.93L248.49,54.28L250.96,58.81L260.28,67.12L265.12,75.94L271.65,78.75L274.22,81.73L281.76,82.61L297.2,90.73L303.67,91.56L305.29,93.92L313.11,96L314.64,98.33L314.72,102.9L322.27,106.22L321.88,110.31L328.4,116.54L331.88,122.85L333.17,129.03L337.44,131.64L343.6,139.12L350.73,142.45L353.23,140.01L353.23,140.01L357.29,145.28L355.68,148.99L352.86,149.48L351.75,151.08L350.66,161.91L339.37,175.91L337.57,185.14L316.91,193.75L308.97,192.52L305.05,189.85L304.15,190.78L304.15,190.78L284.96,181.93L269.92,181.8L260.58,177.54L260.92,173.08L254.59,164.77L247.58,162.1L247.58,162.1L240.21,159.89L233.48,162.01L231.45,160.41L232.47,149.2L219.97,147.44L207.55,154.52L203.11,162.72L200.32,164.22L189.78,160.34L187.78,162.34L184.42,163.39L181.43,162.43L177.29,165.09L172.37,160.79L163.47,159.67L161.49,156.18L158.55,155.43L152.6,157.22L148.79,154.8L148.32,152.3L150.36,148.09L148.15,147.37L148.15,147.37L142.5,143.82L141.2,141.18L141.87,133.5L139.52,130.27L150.46,119.8L154.21,119.7L157.06,122.82L157.49,125.59L159.1,126.27L165.77,123.28L172.28,116.12L173.22,112.28L169.52,101.91L169.13,92.37L167.03,90.51L163.77,90.23L162.74,84L162.99,81.52L166.59,79.05L167.51,76.6L168.6,68.09L171.19,66.27L171.11,58.4L162.59,57.11L158.02,54.06L150.99,56.36L147.47,56.09L139.35,59.93L138.97,49.68L137.21,47.71L133.18,45.79L130.29,48.1L127.91,48.16L118.37,42.4L118.37,42.4L119.18,32.71L126.45,31.86L131.52,24.65L133.62,14.54L131.49,9.13L132.29,2.94L140.62,1.2L144.19,5.38L149.84,7.03L150.73,0.76z"
        />
        <path
          id="NP-KO"
          title="Koshi"
          className="land"
          d="M695.66,291.66L705.67,292.76L709.26,289.96L716.48,291.44L723.87,288.97L725.06,289.6L724.45,291.5L728.23,292.21L739.69,287.18L740.96,292.64L742.92,293.53L750.67,289.98L750.67,289.98L749.05,301.37L742.66,310.13L739.53,307.88L736.15,317.77L731.48,320.55L728.91,337.49L729.31,344.27L734.03,351.66L738.46,354.24L749.66,355.83L757.81,360.58L749.55,370.11L741.9,374.15L732.86,388.25L735.2,393.54L734.69,395.94L750.13,405.33L749.67,413.36L746.38,422.73L747.69,428.43L746.1,429.9L749.82,444.44L749.82,444.44L746,447.72L746.13,449.11L742.33,449.33L741.85,450.4L739.98,450.24L735.98,446.29L727.44,443.99L718.33,447.51L717.17,452.63L715.49,453.96L712.77,451.39L708.17,451.18L707.68,447.17L697.42,447.68L691.02,442.61L689.03,427.93L686.41,427.85L683.49,433.69L676.55,435.52L676.55,435.52L677.48,426.98L690.1,409.85L698.55,401.57L701.05,394.88L699.22,392.62L696.43,392.12L688.81,394.08L683.62,397.5L680.13,393.25L681.17,388.89L678.89,384.31L678.75,375.85L674.88,373.05L674.37,370.92L676.56,364.21L674.29,356.19L678.29,355.05L682.1,350.88L679.05,349.92L679.16,346.43L675.9,342.83L674.9,337.1L678.33,332.11L679.85,318.73L682.53,313.31L684.34,303.62L681.06,300.11L684.37,294.14L680.81,284.63L681.46,277.81L681.46,277.81L684.95,277.79L692.02,284.62L696.13,286.55L697.5,288.51z"
        />
        <path
          id="NP-LU"
          title="Lumbini"
          className="land"
          d="M298.61,245.14L313.65,242.44L315.15,243.75L319.72,243.54L319.91,246.95L317.56,250.34L319,251.9L328.95,255.1L332.31,260L336.24,261.95L340.19,260.84L345.25,263.49L349.19,271.07L349.19,271.07L345.71,275.73L335.28,277.76L333.13,280.93L345.96,280.86L353.96,284.35L357.29,282.85L360.99,283.17L366.28,284.71L369.24,287.17L415.73,290.18L422.61,301.73L424.78,299.59L432.96,301.17L432.96,301.17L429.25,306.17L425.66,307.5L424.19,306.39L406.46,315.76L402.34,322.36L381.83,324.19L380.94,325.96L385.19,327.15L381.84,333.65L381.84,333.65L374.59,334.57L372.28,333.35L371.35,334.33L370.92,335.99L375.77,341.05L373.83,344.48L348.96,330.8L328.18,329.78L328.64,332.41L327.08,335.23L328.55,338.53L327.11,341.38L323.82,344.98L319.32,346.16L316.06,344.24L315.44,340.5L306.76,332.53L290.93,332.54L283.76,330.37L281.36,327.26L263.28,327.21L264.58,318.12L262.89,314.92L263.51,312.79L260.75,309.41L260.51,302.1L259.27,303.07L259.27,303.07L259.16,301.29L260.91,299.84L268.72,300.99L275.44,296.32L267.38,292.64L270.05,285.65L273.92,283.25L292.3,281.16L290.51,276.26L286.06,273.52L287.12,266.24L296.21,258.18L292.51,255.74L292.76,251.16L291.57,249.9z"
        />
        <path
          id="NP-MA"
          title="Mahakali"
          className="land"
          d="M85.52,28.42L98.67,25.92L103.28,32.02L102.11,38.88L105,41.05L105,41.05L95.49,47.34L92.97,54.77L99.63,67.88L100.64,72.4L98.39,76.66L83.8,79.07L71.04,84.9L71.8,94.12L70.5,97.06L68.61,97.58L69.07,100.56L72.66,103.02L73.08,106.47L77.45,106.38L79.82,107.9L81.5,112.23L76.46,118.78L72.25,119.33L68.63,121.61L69.48,127.24L63.4,137.78L51.47,143.32L50.08,147.69L47.25,149.22L48.53,152L45.36,161.1L44.37,178.57L48.18,188.02L47.83,195.26L47.83,195.26L43.77,197.97L44.97,207.55L46.18,208.79L45.17,210.02L40.17,206.62L39.56,203.04L36.87,201.13L30.08,201.37L26.46,197.86L25.52,194.53L19.56,191.39L19.55,188.19L14.49,187.46L6.89,180.52L0.7,179.73L0.32,169.74L3.85,162.74L7.6,159.96L6.55,156.8L8.7,148.96L12.01,145.89L17.35,147.38L20.62,144.52L18.69,136.15L23.42,138.47L24.82,124.57L21.75,122.19L20.97,116.54L18.15,114.97L17.89,111.31L21.25,109.44L23.45,110.22L22.38,108.09L23.42,106.24L24.73,106.83L28.41,102.8L28.2,98.98L32.73,96.72L34.32,93.67L35.29,88.84L32.44,85.69L30.04,79.36L30.39,76.67L33.15,75.44L33.92,72.07L36.68,70.32L42.41,71.35L43.54,68.62L48.72,63.9L49.86,57.05L52.59,53.62L60.56,52.82L64.44,48.44L66.72,48.15L69.16,42.13L79.51,33.65L78.83,28.96L82.25,23.98L83.73,24.06L83.98,27.37z"
        />
        <path
          id="NP-ME"
          title="Mechi"
          className="land"
          d="M789.59,286.89L797.65,287.32L799.89,288.98L797.46,292.5L799.63,295.93L795.61,302.32L794.95,309.12L788.32,318.15L788.32,320.92L784.78,328.45L787.09,333.99L784.49,340.63L786.53,345.56L783.61,351.88L781.88,364.79L779.32,370.43L782.65,373.38L782.96,376.6L784.69,378.5L788.49,378.79L789.33,381.93L793.45,384.26L792.33,388.45L794.38,390.62L794.39,394.83L797.56,397.93L796.81,400.41L798.39,404.93L798.58,413.57L795.94,422.6L793.22,426.23L793.28,429.34L790.72,431.39L790.43,443.31L783.55,450.82L783.33,453.2L780.2,452.41L779.6,449.1L773.32,446.32L772.17,444.73L773.06,443.33L770.49,443.01L771.54,441.96L769.57,438.93L766.51,441.47L765.56,444.45L759.53,440.93L757.31,447.35L754.73,446.29L754.26,447.5L749.82,444.44L749.82,444.44L746.1,429.9L747.69,428.43L746.38,422.73L749.67,413.36L750.13,405.33L734.69,395.94L735.2,393.54L732.86,388.25L741.9,374.15L749.55,370.11L757.81,360.58L749.66,355.83L738.46,354.24L734.03,351.66L729.31,344.27L728.91,337.49L731.48,320.55L736.15,317.77L739.53,307.88L742.66,310.13L749.05,301.37L750.67,289.98L750.67,289.98L753.02,290.82L753.99,293.07L756.49,290.97L760.81,293.29L762.78,290.61L765.9,291.23L768.58,293.94L771.54,293.46L775.09,290.24L776.64,285.79L781.6,281.69L785.76,282.9z"
        />
        <path
          id="NP-NA"
          title="Narayani"
          className="land"
          d="M454.46,291.51L451.61,294.69L451.97,297.74L449.44,300.98L449.74,302.45L458,304.13L462.3,303.22L465.89,305.65L466.29,308.08L468.06,309.3L474.94,310.02L477.43,306.58L481.17,308.42L490.85,304.85L500.8,309.04L506.82,309.83L511.56,322L511.77,331.26L517.51,335.44L534.81,341.98L534.81,341.98L529.99,348.95L531.17,352.9L534.83,355.95L536.37,359.55L534.63,371.14L527.89,377.79L526.4,382.27L527.71,389.98L520.86,401.81L518.38,410.85L518.38,410.85L505.85,408.76L502.91,402.83L504.51,396.87L496.13,396.58L493.86,397.29L493.85,398.5L488.63,398.6L488.51,396.26L491.06,395.15L486.34,394.07L485.44,391.73L482.47,391.24L483.43,388.97L481.5,386.56L475.53,385.41L468.46,380.19L465.34,382.84L463.4,380.49L461.99,382.45L450.48,377.33L450.91,374.44L453.64,371.2L454.91,357.93L448.05,345.19L415.85,340.15L412.01,332.97L408.41,334.08L407.67,331.01L402.18,328.9L402.08,325.37L399.61,326.23L397.38,325.31L392.19,333.67L381.84,333.65L381.84,333.65L385.19,327.15L380.94,325.96L381.83,324.19L402.34,322.36L406.46,315.76L424.19,306.39L425.66,307.5L429.25,306.17L432.96,301.17L432.96,301.17L431.15,294.31L439.25,291.66L446.19,286.27L450.19,285.92z"
        />
        <path
          id="NP-RA"
          title="Rapti"
          className="land"
          d="M247.58,162.1L254.59,164.77L260.92,173.08L260.58,177.54L269.92,181.8L284.96,181.93L304.15,190.78L304.15,190.78L304.64,193.75L300.56,201.83L294.01,204.98L287.63,204.51L285.56,206.42L288.16,210.12L287.12,212.23L282.31,211.27L280.67,213.9L276.87,215.9L279.74,220.09L277.2,220.59L277.27,222.07L280.87,227.53L286.41,233.67L293.85,237.85L298.55,243.19L298.61,245.14L298.61,245.14L291.57,249.9L292.76,251.16L292.51,255.74L296.21,258.18L287.12,266.24L286.06,273.52L290.51,276.26L292.3,281.16L273.92,283.25L270.05,285.65L267.38,292.64L275.44,296.32L268.72,300.99L260.91,299.84L259.16,301.29L259.27,303.07L259.27,303.07L255.34,302.95L249.19,305.68L235.44,307.84L210.73,289.09L202.75,286.87L197.78,280.64L197.78,280.64L206.25,277.76L210.84,278.68L210.1,270.06L206.55,267.95L205.71,264.25L197.74,261.88L192.95,254.35L184.87,250.2L182.55,246.44L175.84,242.47L171.83,242.87L166.86,237.48L167.75,232.21L170.94,229.22L182.76,235.78L185.36,235.88L190.61,229.59L189.2,223.14L194.65,216.13L192.26,207.29L193.49,202.77L198.02,201.85L209.72,204.62L212.16,196.54L219.95,192.81L219.35,188.47L221.94,185.09L225.79,185.68L230.53,179.4L239.55,174.52L242.11,171.65L242.5,165.08z"
        />
        <path
          id="NP-SA"
          title="Sagarmatha"
          className="land"
          d="M657.09,262.19L658.77,268.04L660.45,267.82L663.63,270.88L668.85,269.73L669.64,271.92L674.68,274.52L676.39,277.09L681.46,277.81L681.46,277.81L680.81,284.63L684.37,294.14L681.06,300.11L684.34,303.62L682.53,313.31L679.85,318.73L678.33,332.11L674.9,337.1L675.9,342.83L679.16,346.43L679.05,349.92L682.1,350.88L678.29,355.05L674.29,356.19L676.56,364.21L674.37,370.92L674.88,373.05L678.75,375.85L678.89,384.31L681.17,388.89L680.13,393.25L683.62,397.5L688.81,394.08L696.43,392.12L699.22,392.62L701.05,394.88L698.55,401.57L690.1,409.85L677.48,426.98L676.55,435.52L676.55,435.52L675.46,435.66L675.23,438.63L671.74,438.82L671.78,441.57L666.48,443.94L659.17,441.98L655.89,445.62L639.95,437.59L636.53,432.83L632.86,432.74L622.58,427L617.34,425.76L617.58,424.6L608.82,424.95L606.29,427.5L603.38,427L602.97,424.92L601.41,424.9L601.41,424.9L604.44,418.68L598.99,409.47L600.06,403.95L599.02,394.28L605.33,396.12L609.35,395.82L614.09,391.05L619.95,388.41L622.09,379.48L620.64,371.85L622.94,364.72L612.08,356.9L607.01,355.42L609.55,350.35L608.11,341.09L622.45,322.47L625.87,320.5L633.13,308.35L641.24,304.62L641.61,300.02L639.04,292.98L640.51,286.46L639.16,281.96L635.62,277.55L635.62,277.55L633.67,275.4L634.21,271.45L641.75,263.57L646.89,264.15L651.21,260.81z"
        />
        <path
          id="NP-SE"
          title="Seti"
          className="land"
          d="M110.77,44.51L114.1,45.43L118.37,42.4L118.37,42.4L127.91,48.16L130.29,48.1L133.18,45.79L137.21,47.71L138.97,49.68L139.35,59.93L147.47,56.09L150.99,56.36L158.02,54.06L162.59,57.11L171.11,58.4L171.19,66.27L168.6,68.09L167.51,76.6L166.59,79.05L162.99,81.52L162.74,84L163.77,90.23L167.03,90.51L169.13,92.37L169.52,101.91L173.22,112.28L172.28,116.12L165.77,123.28L159.1,126.27L157.49,125.59L157.06,122.82L154.21,119.7L150.46,119.8L139.52,130.27L141.87,133.5L141.2,141.18L142.5,143.82L148.15,147.37L148.15,147.37L148.32,148.4L140.09,153.39L136.47,158.31L136.5,165.08L146.28,178.36L150.75,187.1L148.16,187.94L146.15,187.02L136.01,171.45L128.53,173.19L126.88,171.14L121.59,172.31L113.43,171.62L102.22,164.97L91.38,167.5L94.84,173.24L106.69,177.55L106.73,180.63L110.02,182.84L112.5,178.62L114.69,178.04L115.81,184.87L121.47,198.74L114.19,206.87L109.67,214.61L101.7,221.07L98.62,227.78L98.62,227.78L94.26,226.4L95.97,224.25L93.69,223.86L93.42,222.23L89.33,223.71L89.93,221.91L86.1,221.89L86.39,220.2L85.07,221.51L82.47,220.78L83.18,217.12L81.47,215.93L78.76,215.9L78.89,217.31L71.14,213.7L69.59,209.17L62.84,208.05L59.51,200.86L52.16,200.17L51.86,196.46L47.83,195.26L47.83,195.26L48.18,188.02L44.37,178.57L45.36,161.1L48.53,152L47.25,149.22L50.08,147.69L51.47,143.32L63.4,137.78L69.48,127.24L68.63,121.61L72.25,119.33L76.46,118.78L81.5,112.23L79.82,107.9L77.45,106.38L73.08,106.47L72.66,103.02L69.07,100.56L68.61,97.58L70.5,97.06L71.8,94.12L71.04,84.9L83.8,79.07L98.39,76.66L100.64,72.4L99.63,67.88L92.97,54.77L95.49,47.34L105,41.05L105,41.05z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Nepal))
