import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 1234
const height = 751

const cities = {
  Riga: { x: 522, y: 346 }
}

const Latvia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map latvia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="LV-DGV"
          title="Daugavpils"
          className="land"
          d="M946.27,657.96L950.23,668.15L952.27,683.51L943.75,686.99L939.91,691.91L934.51,689.03L930.44,691.79L926.6,685.67L929.6,681.35L921.32,666.95L925.64,665.4L938.47,670.91z"
        />
        <path
          id="LV-JEL"
          title="Jelgava"
          className="land"
          d="M473.28,448.76L470.88,447.8L468.32,450.68L473.28,453.08L472.8,455L461.44,458.68L457.76,463.8L455.36,462.04L456,451.16L449.44,445.24L446.88,439.32L450.24,436.6L455.84,439.48L461.6,435.01L461.6,435.01L463.98,433.67L463.98,433.67L474.56,435.33L473.76,443L476.32,446.36z"
        />
        <path
          id="LV-JKB"
          title="Jēkabpils"
          className="land"
          d="M817.05,498.03L816.89,496.95L816.89,496.95L818.49,485.35L818.49,485.35L826.99,483.87L835.39,486.87L835.09,489.37L831.19,490.97L831.19,494.07L831.19,494.07L826.79,501.07z"
        />
        <path
          id="LV-JUR"
          title="Jūrmala"
          className="land"
          d="M505.13,342.16L496.23,351.86L489.23,350.56L480.23,354.96L473.73,350.96L472.43,356.56L459.14,357.86L457.64,360.76L449.44,360.76L446.04,358.16L443.84,364.96L435.82,364.96L432.92,362.26L423.8,361.09L423.8,361.09L421.21,358.89L421.21,358.89L421.21,358.89L421.21,358.89L422.08,350.87L434.52,346.55L434.52,346.55L446.21,350.93L456.78,351.36L471.12,348.83L496.55,338.49L496.55,338.49z"
        />
        <path
          id="LV-LPX"
          title="Liepāja"
          className="land"
          d="M5.23,501.97L2.83,487.67L5.15,488.47L2.14,486.4L4.38,486.18L5.28,479.73L13.7,480.85L11.44,477.72L10.2,480.21L4.78,478.63L9.58,458.96L9.58,458.96L18.54,460.41L18.44,471.63L23.79,471.53L23.79,481.08L18.24,484.68L15.44,489.98L15.64,496.17L15.64,496.17L14.34,497.57L14.34,497.57L11.34,497.87L8.64,502.07z"
        />
        <path
          id="LV-REZ"
          title="Rēzekne"
          className="land"
          d="M1077.73,482.75L1080.59,485.28L1080.29,492.35L1075.81,503.71L1073.89,503.71L1072.45,499.71L1068.13,499.07L1067.81,492.19L1069.73,485.47z"
        />
        <path
          id="LV-RIX"
          title="Rīga"
          className="land"
          d="M564.21,348.96L563.01,349.43L563.01,349.43L549.21,359.39L549.21,370.55L555.45,378.35L555.45,378.35L562.12,383.1L558.52,384.31L543.93,377.39L543.93,377.39L539.85,368.87L537.57,368.63L535.41,372.59L532.41,370.19L527.98,372.23L525.69,377.14L525.69,377.14L520.52,377.14L520.52,377.14L520.92,368.56L500.63,358.56L500.63,358.56L505.03,352.76L511.53,349.16L509.63,345.46L513.53,344.66L510.93,341.66L505.13,342.16L505.13,342.16L496.55,338.49L496.55,338.49L507.17,330.92L514.93,319.99L528.68,314.12L528.68,314.12L534.03,318.62L534.03,322.22L539.63,323.02L539.63,323.02L543.02,325.92L544.82,333.71L555.12,337.61z"
        />
        <path
          id="LV-VEN"
          title="Ventspils"
          className="land"
          d="M92.71,221.7L94.75,216.33L95.56,218.05L97.78,214.9L94.46,213.28L98.01,214.34L101.71,210.72L110.76,192.88L115.21,197.07L118.11,214.76L108.51,218.86L109.91,227.16L106.71,229.16L104.21,225.16L102.41,231.76z"
        />
        <path
          id="LV-VMR"
          title="Valmiera"
          className="land"
          d="M755.53,172.29L756.38,177.44L753.42,181.84L741.98,181.84L741.98,181.84L740.25,170.85L740.25,170.85L752.25,168.3z"
        />
        <path
          id="LV-001"
          title="Aglonas novads"
          className="land"
          d="M1035.06,590.31L1039.63,587.65L1041.87,582.93L1048.75,585.33L1068.89,585.45L1068.89,585.45L1067.37,593.13L1074.08,600.33L1068.33,607.77L1054.04,611.36L1057.08,613.04L1055.72,617.92L1058.84,620.16L1058.84,620.16L1054.76,625.92L1046.44,627.84L1045.64,640.96L1039.4,646.21L1025.89,644.21L1019.89,648.28L1010.95,643.13L1010.95,643.13L1006.49,633.58L1009.97,625.88L1003.36,626.57L999.69,615.98L996.19,612.58L997,607.22L997,607.22L1001.93,606.89L1004.65,609.13L1006.09,606.17L1004.09,601.29L1011.05,595.77L1033.66,595.02z"
        />
        <path
          id="LV-002"
          title="Aizkraukles novads"
          className="land"
          d="M732.5,464.26L725.63,464.14L722.51,470.98L700.49,455.15L700.49,455.15L701.45,450.38L710.01,446.7L710.57,439.82L706.73,438.14L705.45,434.38L710.41,431.58L710.41,431.58L710.41,431.44L710.41,431.44L715.09,428.52L726.09,432.72L726.09,432.72L729.49,440.72L728.79,451.42z"
        />
        <path
          id="LV-003"
          title="Aizputes novads"
          className="land"
          d="M85.17,376.59L91.28,381.26L92.04,388.91L105.84,387.89L113.81,403.88L122.19,404.37L131.18,411.82L134.22,418.22L133.21,422.11L136.39,422.81L136.16,428.87L133.45,431.01L144.59,435.84L144.59,435.84L146.13,451.32L141.57,456.19L135.59,458.23L139.62,465.74L139.32,471.1L139.32,471.1L125.63,475.62L125.63,475.62L115.24,472.12L112.74,476.92L108.54,475.02L105.94,477.12L102.74,473.32L102.74,473.32L102.64,472.42L102.64,472.42L109.34,468.92L105.74,444.92L100.34,442.52L81.34,447.92L77.24,443.42L77.64,438.53L72.64,440.83L71.75,434.13L63.55,430.63L56.05,423.53L55.71,418.79L55.71,418.79L58.41,416.04L56.06,400.79L60.96,399.72L61.66,393.92L82.56,388.87L81.61,377.57z"
        />
        <path
          id="LV-004"
          title="Aknīstes novads"
          className="land"
          d="M848.32,618.06L836.09,625.88L830.15,615.77L817.72,607.76L819.65,613.87L825.51,618.45L819.92,622.15L819.5,628.31L817.15,628.86L817.15,628.86L794.87,618.69L796.63,608.6L793.22,605.41L792.97,600.11L788.49,603.99L783.78,603.9L783.78,603.9L785.22,594.07L793.32,576.77L793.32,576.77L793.32,572.79L793.32,572.79L812,571.77L825.11,576.57L828.19,591.39L840.25,597.5L840.97,599.9L837.73,604.1L842.11,610.1L847.27,611.42z"
        />
        <path
          id="LV-005"
          title="Alojas novads"
          className="land"
          d="M650.01,35.72l0.42,-0.09l0,0l0.37,22.56l5.3,3.4l4.6,24l8.3,6l4.3,-1.7l5.3,3.1l0,0l4.1,6.5l7.84,1.8l3.14,11.77l-5.28,11.04l4.47,3.57l-1.51,6.03l2.76,2.7l0,0l-6.62,1.18l1.98,7.37l-2.04,2.88l-5.46,-0.12l-1.2,-4.8l-2.94,-1.2l-11.64,10.98l-5.16,-1.26l0,0l-0.01,-0.94l0,0l1.33,-9.4l-10.1,-5.4l-1.5,-15.82l-7.2,-4.7l-8.5,0.2l-9.3,-11.3h-3.4l0,0l0,0l0,0l-7.46,-27.75l-5.92,-7.04l5.44,-6.4l-3.68,-4.32l-3.81,-17.21l0,0l1.82,-1.47l6.63,6.2l3.75,-5.66l4.31,-0.32l2.91,3.7l3.67,-4.49l4.75,-1.08l0.17,-5.6l10.69,-2.47L650.01,35.72z"
        />
        <path
          id="LV-006"
          title="Alsungas novads"
          className="land"
          d="M97.32,316.5L106.45,324.13L110.75,324.73L110.15,331.51L120.05,341.91L123.74,354.01L121.25,358.11L113.35,360.81L111.55,364.91L102.05,363.31L97.55,372.01L88.34,368.75L88.34,368.75L79.5,366.44L80.28,363.4L76.78,364.87L72.77,362.6L72.67,356.27L72.67,356.27L82.23,356.26L81.52,351.76L85.09,348.47L83.49,331.15L87.98,331.16L86.62,322.31L90.34,320.6L90.41,315.51z"
        />
        <path
          id="LV-007"
          title="Alūksnes novads"
          className="land"
          d="M945.81,218.31L953.78,208.59L965.46,210.03L970.57,200.43L971.53,187.96L977.29,184.44L987.53,183.8L984.81,177.56L987.21,176.12L988.01,170.04L984.17,163.32L986.45,159.14L986.45,159.14L991.23,155.7L992.17,148.56L1002.32,143.19L1006.46,147.3L1006.03,152.82L1011.28,149.44L1020.08,151.91L1022.68,159.15L1029.29,160.62L1035.77,165.57L1059.31,169.2L1074.28,179.12L1078.08,173.68L1084.73,177.62L1099.57,172.24L1107.19,172.93L1106.82,178.65L1108.86,177.77L1110.31,181.59L1107.26,197.27L1105.19,196.87L1102.04,208.19L1109.45,212.47L1115.37,210.97L1126.69,217.45L1131.27,225.34L1131.27,225.34L1131.51,237.38L1122.29,244.13L1114.23,245.47L1113.67,253.42L1109.89,254.14L1109.65,257.83L1107.56,258.49L1107.56,258.49L1105.91,258.3L1105.91,258.3L1099.81,260.1L1098.73,252.4L1092.49,252.09L1092.18,259.54L1088.16,258.74L1082.85,261.56L1073.98,251.65L1064.4,259.48L1064.62,262.12L1055.71,262.09L1053.18,268.39L1048.89,265.63L1047.73,268.89L1041.91,269.97L1041.91,269.97L1022.9,262.74L1023.52,259.17L1028.06,255.97L1019.95,252.65L1011.84,244.47L992.94,246.84L990.82,242.2L980.74,243.23L975.29,239.09L971.69,240.12L971.81,245.44L967.03,245.41L960.92,241.21L961.41,236.26L953.2,235.52L954.92,231L948.12,226.55L946.67,219.17L946.67,219.17z"
        />
        <path
          id="LV-008"
          title="Amatas novads"
          className="land"
          d="M772.77,292.41L766.64,300.43L775.88,302.23L781.49,311.17L781.41,319.57L766.61,332.76L762.53,328.85L758.78,329.33L761.73,335.08L758.46,338.76L758.76,342.18L758.76,342.18L754.77,342.76L756.74,348.19L744.74,361L740.3,357.67L736.35,345.45L725.58,349.6L725.81,352.5L721.56,354.47L722.51,357.18L719.1,358.75L720.68,362.01L701.63,355.12L701.63,355.12L703.03,349.36L700.34,345.11L702.46,342.98L700.48,337.9L700.48,337.9L702.04,331.18L695.8,321.1L696.16,317.98L702.16,313.78L693.4,303.58L693.4,303.58L693.4,302.26L693.4,302.26L705.08,299.54L711.64,288.51L706.44,286.99L704.36,267.07L692.44,263.17L692.44,257.73L692.44,257.73L698.7,253.49L709.5,256.19L714.4,248.29L714.4,248.29L714.41,248.2L714.41,248.2L721.88,247.52L728.98,252.62L732.68,258.22L731.48,264.42L739.68,266.62L736.88,273.11L741.28,279.31L748.18,281.81L752.68,289.61L760.17,291.61L761.67,287.21z"
        />
        <path
          id="LV-009"
          title="Apes novads"
          className="land"
          d="M986.45,159.14L984.17,163.32L988.01,170.04L987.21,176.12L984.81,177.56L987.53,183.8L977.29,184.44L971.53,187.96L970.57,200.43L965.46,210.03L953.78,208.59L945.81,218.31L945.81,218.31L943.51,216.02L943.51,216.02L939.07,219.45L934.32,216.58L934.96,213.43L930.2,216.02L926.43,214.59L924.14,217.14L928.85,224.53L919.48,225.52L917.8,230.66L912.03,230.5L912.72,235.05L918.44,231.81L920.12,238.16L905.6,241.74L892.96,237.6L892.96,237.6L892.85,226.89L898.62,223.93L895.72,222.53L895.23,218.54L898.52,216.33L898.67,210.5L901.39,208.57L894.16,204.2L895.35,199.46L899.34,199.71L900.43,197.18L898.76,183.19L898.76,183.19L909.25,180.87L905.06,179.68L905.48,175.37L902.67,173.83L902.86,168.54L908.06,159.37L908.06,159.37L914.37,161.97L925.67,160.94L930.79,176.87L940.75,180.68L941.59,175.52L945.26,172.36L945.9,179.21L949.67,181.56L950.02,175.52L956.25,167.42L960.3,167.2L959.56,164.76L964.12,162.31L969.64,163.19L970.52,157.65L976.74,161.53L974.1,164.79L976.67,165.91L978.96,161.81L977.73,159.37L980.41,161.75L981.72,159.09z"
        />
        <path
          id="LV-010"
          title="Auces novads"
          className="land"
          d="M372.31,532.97L370.41,533.82L372.36,542.45L357.34,552.44L353.8,546.87L349.14,547.84L345.96,545L342.29,533.12L344.57,532L341.87,522.14L332.38,519.23L328.41,523.93L328.41,523.93L324.54,523.2L321.58,525.6L305.95,517.01L294.32,515.9L293.84,508.82L300.25,507.26L291.32,496.47L295.64,493.71L295.04,486.63L298.64,480.15L297.57,472.84L297.57,472.84L301.27,475.43L305.53,473.6L304.4,471.84L309.1,472.45L309.45,470.74L309.45,470.74L313.59,471.17L313.71,478.84L316.71,480.16L324.51,471.41L332.79,468.41L329.43,466.49L330.51,462.77L335.67,461.69L340.35,467.33L347.07,465.14L353.43,474.02L357.87,475.7L355.47,478.7L358.11,490.34L356.67,498.14L363.74,501.38L362.3,506.66L366.62,509.06L366.62,509.06L368.06,511.45L368.06,511.45L362.06,519.85L368.06,524.77z"
        />
        <path
          id="LV-011"
          title="Ādažu novads"
          className="land"
          d="M550.32,318.92L555.11,312.44L554.31,307.16L566.47,300.76L568.95,281.16L568.95,281.16L572.95,281.4L572.95,281.4L575.58,281.64L575.58,281.64L588.7,283.96L595.26,305L595.26,305L594.41,308.82L594.41,308.82L588.12,316.92L575.82,321.82L572.62,320.42L565.22,329.62L551.82,325.22z"
        />
        <path
          id="LV-012"
          title="Babītes novads"
          className="land"
          d="M470.91,399.06L465.9,396.67L460.8,383.6L447.04,373.7L444.36,375.76L442.42,371.66L426.01,371.36L426.01,371.36L425.93,367.89L425.93,367.89L423.8,361.09L423.8,361.09L432.92,362.26L435.82,364.96L443.84,364.96L446.04,358.16L449.44,360.76L457.64,360.76L459.14,357.86L472.43,356.56L473.73,350.96L480.23,354.96L489.23,350.56L496.23,351.86L505.13,342.16L505.13,342.16L510.93,341.66L513.53,344.66L509.63,345.46L511.53,349.16L505.03,352.76L500.63,358.56L500.63,358.56L492.23,367.86L488.43,368.26L481.43,374.06L485.69,390.73L485.69,390.73L482.01,399.29L475.54,394.49L473.38,395.45L473.38,399.29L470.93,399.07L470.93,399.07z"
        />
        <path
          id="LV-013"
          title="Baldones novads"
          className="land"
          d="M598.72,415.49L598.98,426.73L598.98,426.73L593.32,426.94L583.4,437.16L573.5,439.48L571.2,441.68L574.14,442.51L572.02,443.52L572.02,443.52L555.77,436.46L552.08,428.53L552.08,428.53L549.74,425.54L549.74,425.54L548,425.54L548,425.54L545.63,418.74L552.72,414.34L554.82,418.24L557.32,416.64L557.12,411.74L553.62,408.94L556.32,403.44L562.42,407.74L572.02,403.74L579.72,405.04z"
        />
        <path
          id="LV-014"
          title="Baltinavas novads"
          className="land"
          d="M1087.05,385.68L1087.53,378.07L1084.57,375.27L1086.01,373.59L1091.05,371.11L1102.32,372.87L1104.56,359.19L1116.64,354.95L1121.44,349.65L1126.72,350.05L1122.98,338.37L1122.98,338.37L1128.86,339.93L1135.44,336.78L1135.44,336.78L1135.07,338.6L1143.81,339.19L1139.01,364.11L1136.56,366.32L1130.7,365.55L1127.96,371.92L1125.72,390.2L1125.72,390.2L1119.11,388.94L1117.07,384.09L1113.12,384.64L1110.04,378L1104.35,378.03L1106.1,383.87z"
        />
        <path
          id="LV-015"
          title="Balvu novads"
          className="land"
          d="M1041.91,269.97L1047.73,268.89L1048.89,265.63L1053.18,268.39L1055.71,262.09L1064.62,262.12L1064.4,259.48L1073.98,251.65L1082.85,261.56L1088.16,258.74L1092.18,259.54L1092.49,252.09L1098.73,252.4L1099.81,260.1L1105.91,258.3L1105.91,258.3L1107.1,258.43L1107.1,258.43L1106.91,261.68L1096.83,266.36L1088.91,276.8L1084.71,299.26L1104.27,320.49L1108.95,317.61L1110.03,321.57L1107.51,325.89L1109.31,333.69L1115.43,331.53L1121.31,333.45L1122.98,338.37L1122.98,338.37L1126.72,350.05L1121.44,349.65L1116.64,354.95L1104.56,359.19L1102.32,372.87L1091.05,371.11L1086.01,373.59L1084.57,375.27L1087.53,378.07L1087.05,385.68L1087.05,385.68L1078.47,391.27L1084.55,392.13L1081.13,396.06L1076.52,393.17L1072.89,396L1074.83,406.61L1074.83,406.61L1074.08,406.76L1074.08,406.76L1067.4,407.44L1060.43,404.06L1045.41,404.68L1042.09,398.18L1037.8,400.51L1035.11,397.99L1026.48,398.3L1026.36,402.5L1019.71,402.16L1020.42,397.1L1012.51,395.54L1008.78,400.23L1003.14,391.49L1003.14,391.49L1005.72,388.26L1005.72,388.26L1009.45,386.3L1013.56,378.5L1013.56,378.5L1025.34,378.63L1035.58,381.35L1038.78,385.43L1044.37,385.35L1055.41,370.31L1058.93,370.15L1062.29,366.31L1067.2,368.15L1070.64,362.95L1068.4,350.4L1066.96,349.19L1064.56,353.91L1058.32,350.55L1056.48,346.06L1065.76,342.7L1067.92,333.98L1072.24,329.42L1070.16,325.66L1063.12,327.1L1053.92,324.14L1041.53,317.98L1041.45,315.65L1039.05,315.17L1036.17,318.37L1033.85,317.33L1032.09,315.33L1033.29,307.81L1014.12,307.81L1014.12,307.81L1014.46,307.35L1014.46,307.35L1026.66,288.91L1032.54,285.75z"
        />
        <path
          id="LV-016"
          title="Bauskas novads"
          className="land"
          d="M576.94,481.08L577.37,485.83L582.49,486.31L586.65,497.02L592.56,494.06L596.48,499.66L600.96,501.66L596.96,506.7L597.84,509.34L602.32,510.78L600.24,518.88L587.13,526.71L587.68,529.35L597.28,533.27L589.28,541.75L596.8,547.75L599.84,544.87L607.17,547.45L607.17,547.45L607.49,557.56L597.9,559.07L586.36,566.54L581.02,565.51L575.99,560.49L570.02,562.07L566.79,554.8L558.19,554.11L558.53,558.07L544.39,563.52L539.68,568.32L530.43,569.44L526.29,560.77L518.03,559.95L518.01,554.62L513.65,550.84L512.42,544.91L512.42,544.91L513.11,542.28L517.91,540.68L519.99,533.96L526.07,533.64L523.99,525.32L528.95,528.2L538.71,527.4L536.31,522.12L528.47,520.84L517.75,510.61L520.47,505.33L517.31,490.91L517.31,490.91L523.34,483.35L523.44,478.97L523.44,478.97L540.47,483.48L552.46,489.55L556.78,481.88L565.58,479.32L568.94,481.24L571.34,477.88z"
        />
        <path
          id="LV-017"
          title="Beverīnas novads"
          className="land"
          d="M776.5,151.48L794.73,149.82L801.53,157.9L799.69,164.3L807.93,165.1L810.89,168.94L802.33,172.06L805.69,179.02L810.65,181.34L815.69,176.14L816.41,181.9L809.69,187.02L809.69,187.02L802.09,186.14L799.29,190.29L795.13,188.78L788.84,196.37L788.84,196.37L778.19,195.02L771.66,199.36L771.02,205.17L767.9,206.48L765.76,206.7L762.7,199.27L754.9,204.39L747.72,200.33L745.36,203.23L745.36,203.23L742.3,198.95L744.14,195.84L740.78,186.56L741.98,181.84L741.98,181.84L753.42,181.84L756.38,177.44L755.53,172.29L755.53,172.29L760.01,170.69L765.45,161.74L774.4,158.14L776.72,152.78L776.72,152.78z"
        />
        <path
          id="LV-018"
          title="Brocēnu novads"
          className="land"
          d="M256.43,384.15L262.67,389.37L272.1,388.08L276.18,394.95L280.54,394.09L280.96,398.64L287.29,397.35L292.54,401.95L305.55,398.18L306.64,401.98L306.64,401.98L312.07,410.41L313.45,426.97L309.57,427.62L312.26,429.51L311.25,433.64L311.25,433.64L307.83,433.98L306.05,438.02L313.27,437.89L318.5,449.84L315.39,451.4L313.99,460.52L303.78,459.3L303.63,465.62L309.45,470.74L309.45,470.74L309.1,472.45L304.4,471.84L305.53,473.6L301.27,475.43L297.57,472.84L297.57,472.84L286.94,454.65L279.38,452.62L277.82,458.97L273.14,461.61L271.64,465.75L268.16,464.25L267.86,450.88L262.52,451.12L258.08,445.48L259.4,427.4L256.76,425L256.76,418.76L252.4,420.26L248.74,410.84L253.72,384.72z"
        />
        <path
          id="LV-019"
          title="Burtnieku novads"
          className="land"
          d="M776.72,152.78L774.4,158.14L765.45,161.74L760.01,170.69L755.53,172.29L755.53,172.29L752.25,168.3L740.25,170.85L740.25,170.85L735.29,161.5L724.65,155.18L723.45,149.98L712.7,137.74L710.54,131.1L699.99,137.34L694.11,136.39L694.11,136.39L691.35,133.69L692.87,127.65L688.4,124.08L693.67,113.04L690.54,101.27L682.7,99.47L678.6,92.97L678.6,92.97L684.5,87.61L690.09,87.81L698.49,93.31L699.19,83.41L695.69,79.11L701.09,76.51L703.59,76.61L709.79,86.21L718.29,92.31L721.19,89.61L721.19,89.61L724.47,90.79L728.27,97.59L754.26,95.89L754.26,95.89L757.96,95.89L757.96,95.89L767.46,96.99L767.46,96.99L777.06,100.09L795.25,113.69L795.25,113.69L792.85,119.38L783.86,119.38L785.06,123.58L779.96,126.68L781.16,134.88L766.83,136.15L766.46,138.93L762.89,140.46L764.65,146.46L776.4,150.94L776.4,150.94z"
        />
        <path
          id="LV-020"
          title="Carnikavas novads"
          className="land"
          d="M568.95,281.16L566.47,300.76L554.31,307.16L555.11,312.44L550.32,318.92L550.32,318.92L547.32,317.32L539.63,323.02L539.63,323.02L534.03,322.22L534.03,318.62L528.68,314.12L528.68,314.12L542.78,304.32L567.17,279.67L567.17,279.67z"
        />
        <path
          id="LV-021"
          title="Cesvaines novads"
          className="land"
          d="M909.02,326.63L911.54,334.14L910.1,340.14L916.34,345.9L911.78,355.74L904.98,357.02L906.5,363.82L895.38,359.98L892.02,362.86L884.27,363.26L879.71,351.02L872.28,353.5L877.96,347.26L876.2,330.78L878.28,327.82L878.16,315.48L878.16,315.48L879.1,311.99L896.5,314.93L900.55,309.67L901.27,317.52z"
        />
        <path
          id="LV-022"
          title="Cēsu novads"
          className="land"
          d="M771.77,270.13L774.07,271.61L775.77,282.01L773.07,284.31L772.77,292.41L772.77,292.41L761.67,287.21L760.17,291.61L752.68,289.61L748.18,281.81L741.28,279.31L736.88,273.11L739.68,266.62L731.48,264.42L732.68,258.22L728.98,252.62L721.88,247.52L714.41,248.2L714.41,248.2L714.9,245.89L714.9,245.89L716.3,243.09L713.8,240.19L716.1,236.19L716.1,236.19L716.72,234.56L716.72,234.56L730.58,238.82L732.68,242.12L728.58,245.22L730.08,247.82L745.28,245.42L756.47,251.34L761.67,258.44L759.97,269.83L766.67,273.23z"
        />
        <path
          id="LV-023"
          title="Ciblas novads"
          className="land"
          d="M1114.17,473.79L1110.27,471.84L1109.06,463.54L1113.79,460.35L1113.79,460.35L1118.79,458.55L1119.99,449.95L1127.99,446.45L1131.19,441.35L1138.19,439.75L1148.38,442.25L1153.18,440.35L1155.18,446.55L1146.78,446.65L1158.48,450.95L1160.78,447.65L1158.38,442.65L1161.58,427.26L1166.18,427.36L1169.38,435.56L1179.33,433.89L1179.33,433.89L1185.75,440.28L1184.9,451.27L1190.26,464.58L1192.85,467.57L1204.04,467.9L1206.79,470.74L1206.84,477.26L1200.61,480.4L1198.96,484.6L1200.19,490.08L1204.15,488.34L1205.98,489.93L1205.22,494.98L1207.28,497.88L1207.28,497.88L1199.06,498.02L1196.42,502.1L1193.43,495.98L1189.47,494.18L1185.75,499.34L1181.55,499.82L1178.43,506.3L1175.07,506.42L1172.31,505.22L1172.55,499.22L1167.03,495.26L1165.59,491.18L1160.19,488.91L1157.91,484.23L1151.43,485.79L1150.11,490.58L1136.08,476.43L1133.32,476.07L1131.04,479.79L1119.4,472.95z"
        />
        <path
          id="LV-024"
          title="Dagdas novads"
          className="land"
          d="M1139.57,652.91L1124.21,642.03L1122.21,644.33L1123.21,651.03L1114.61,650.53L1086.02,633.74L1076.32,639.43L1074.52,636.44L1078.62,622.84L1058.84,620.16L1058.84,620.16L1055.72,617.92L1057.08,613.04L1054.04,611.36L1068.33,607.77L1074.08,600.33L1067.37,593.13L1068.89,585.45L1068.89,585.45L1072.64,582.97L1079.97,583.15L1082.39,583.55L1082.32,586.01L1088.4,586.57L1092,579.08L1090.16,576.01L1099.84,577.13L1103.92,574.65L1105.15,569.3L1115.52,574.89L1125.19,572.65L1129.49,574.13L1129.49,574.13L1136.09,585.36L1141.99,588.16L1151.29,588.26L1159.69,592.56L1165.09,590.36L1167.48,593.46L1173.38,588.26L1180.48,588.96L1191.46,580.6L1193.96,580.8L1193.98,586.88L1197.86,584.6L1197.86,584.6L1198.27,583.77L1198.27,583.77L1200.63,586.95L1191.43,600.31L1194.05,604.05L1194.05,604.05L1190.52,609.09L1182.63,606.67L1179.28,608.36L1167.15,630.48L1162.16,628.65L1151.66,633.52L1155.25,637.6L1150,641.93L1151.63,647.6L1146.68,651.83z"
        />
        <path
          id="LV-025"
          title="Daugavpils novads"
          className="land"
          d="M1005.07,686.2l-1.85,-7.93l5.57,-6.82l-3.24,-5.78l4.92,-5.49l-5.58,-4.34l-0.96,-10.56l5.58,0.39l1.44,-2.52l-4.46,-9.56l3.48,-7.7l-6.61,0.69l-3.67,-10.59l-3.5,-3.4l0.81,-5.36l-0.66,-3.01l-6.17,6.21l-5.44,-4.08l-11.84,7.68h-7.44l-2.2,-7.8l-6.71,-1.15l-2.88,2.4l-8.5,-3.85l-2.57,-7.72l-2.85,2.29l-0.56,8.72l-5.76,0.56l-2.55,-13.07l-6.91,-7.27l-6.12,4.86l-6.16,-6.97l-8.29,4.44l-6.11,1.59l0.77,0.56l6.47,8.91l-0.4,18.65l0.99,1.15l2.16,18.96l3.84,9.48l-2.28,3.12l-6.36,-1.32l-6.6,5.21l-1.32,4.27h-2.88v5.57l6.84,1.56l-7.56,3.48l-1.8,5.4l2.88,5.52l9.84,7.68l-2.04,4.92l2.04,3.12l-2.88,2.04l-4.44,-2.16l-7.94,1.61l5.52,15.29l7.4,7.13l6.93,1.28l4.98,10.4l13.58,-0.15l9.83,7.75l12.86,1.58l6.26,-1.9l3.69,-7.6l10.53,1.82l4.04,5.51l6.61,1.37l2.91,-5.9l11.93,-4.98l8.67,-22.34l4.16,0.24l5.33,-7.83l0.61,-14.62L1005.07,686.2zM943.75,686.99l-3.84,4.92l-5.4,-2.88l-4.08,2.76l-3.84,-6.12l3,-4.32l-8.28,-14.4l4.32,-1.56l12.84,5.52l7.8,-12.96l3.96,10.2l2.04,15.36L943.75,686.99z"
        />
        <path
          id="LV-026"
          title="Dobeles novads"
          className="land"
          d="M411.05,495.4L407.85,482.08L402.21,476.92L400.77,482.08L389.37,490.59L390.33,499.59L395.01,501.15L392.97,505.35L390.69,506.67L380.61,502.95L370.9,505.71L367.22,510.04L367.22,510.04L366.62,509.06L366.62,509.06L362.3,506.66L363.74,501.38L356.67,498.14L358.11,490.34L355.47,478.7L357.87,475.7L353.43,474.02L347.07,465.14L340.35,467.33L335.67,461.69L330.51,462.77L329.43,466.49L332.79,468.41L324.51,471.41L316.71,480.16L313.71,478.84L313.59,471.17L309.45,470.74L309.45,470.74L303.63,465.62L303.78,459.3L313.99,460.52L315.39,451.4L318.5,449.84L313.27,437.89L306.05,438.02L307.83,433.98L311.25,433.64L311.25,433.64L314.99,433.64L316.57,430.8L319.5,431.9L319.48,429.18L324.27,427.74L344.67,426.61L346.97,433.77L360.8,434.77L361.87,431.53L361.87,431.53L361.87,430.23L361.87,430.23L372.74,433.62L374.47,431.66L372.26,428.41L375.24,424.28L388.65,428.53L400.09,416.11L393.92,409.03L398.01,407.65L403.76,399.89L405.43,399.1L406.97,403.51L417.84,401.89L424.09,396.7L424.09,396.7L427.49,396.09L431.24,414.39L419.17,419.84L413.79,426.42L412.85,430.65L421.41,433.31L425.15,437.16L419.27,438.32L422.98,445.01L415.71,448.83L415.52,454.48L409.92,456.43L409.43,461.32L422.99,463.27L424.02,466.26L413.5,468.85L413.81,473.57L421.28,477.81L416.88,488.5L417.86,491.6z"
        />
        <path
          id="LV-027"
          title="Dundagas novads"
          className="land"
          d="M269.98,128.76L265.1,130.14L263.5,140.06L266.06,146.3L275.02,156.03L270.86,164.98L273.74,167.38L275.5,178.58L275.5,178.58L276.3,182.26L276.3,182.26L267.82,185.94L263.34,182.1L261.42,183.06L259.02,193.3L254.23,196.5L249.75,211.37L241.75,209.29L231.19,215.21L229.75,207.85L222.71,204.18L220.15,208.5L218.39,206.1L214.07,208.33L212.79,210.89L214.85,213.96L214.85,213.96L204.54,217.2L196.48,215.62L186.55,208.88L202.87,200.8L205.03,197.37L202.22,196.3L201.01,191.68L205.62,192.9L215.75,183.21L220.84,182.9L218.93,167.54L214.55,158.08L214.94,151.85L217.01,151.69L211.88,143.85L207.48,146.68L210.93,142.19L206.56,135.73L259.48,108.29L274.62,103.57z"
        />
        <path
          id="LV-028"
          title="Durbes novads"
          className="land"
          d="M102.68,472.8L98.46,469.92L90.42,475.43L89.1,478.79L93.3,485.15L92.1,487.91L88.62,487.91L73.5,475.43L71.46,477.71L72.3,485.75L64.27,485.39L64.27,485.39L62.83,480.47L52.27,482.51L48.43,480.95L50.83,478.19L48.43,476.27L48.19,468.72L52.39,461.16L46.61,446.39L46.61,446.39L47.56,442.39L51.11,442.54L53.21,439.34L53.21,433.04L46.36,423.1L50.31,417.54L55.71,418.79L55.71,418.79L56.05,423.53L63.55,430.63L71.75,434.13L72.64,440.83L77.64,438.53L77.24,443.42L81.34,447.92L100.34,442.52L105.74,444.92L109.34,468.92L102.64,472.42L102.64,472.42z"
        />
        <path
          id="LV-029"
          title="Engures novads"
          className="land"
          d="M421.21,358.89L403.77,364.63L389.45,361.83L382.81,362.95L376.25,359.11L367.85,361.83L362.98,360.95L367.45,353.19L374.65,350.23L374.09,346.71L370.01,346.55L366.65,343.35L372.09,335.99L369.61,331.76L377.37,328.32L377.61,323.36L381.05,320.57L374.57,304.42L366.97,299.06L363.46,300.58L353.06,292.5L360.1,287.7L361.3,279.14L364.89,276.42L362.5,273.94L361.58,262.27L361.58,262.27L358.95,247.43L360.82,243.51L365.95,241.74L365.95,241.74L371.54,258.77L371.4,267.8L381.25,289.96L381.52,306.3L385.02,312.42L400.97,325.24L423.97,329.56L429.66,341.65L434.52,346.55L434.52,346.55L422.08,350.87z"
        />
        <path
          id="LV-030"
          title="Ērgļu novads"
          className="land"
          d="M795.88,412.12L790,401.86L777.76,397.06L770.44,398.98L763.66,394.78L763.66,394.78L763.79,388.2L761.74,388.69L758.74,384.76L760.86,385.07L760.36,380.68L771.31,378.83L764.01,371.55L769.06,361.21L765.77,357.46L763.76,346L759.63,344.95L760.39,341.78L760.39,341.78L762.17,339.47L774.54,347.63L785.71,340.14L785.68,346.65L783.24,348.71L785.39,352.84L796.85,344.8L798.75,340.64L803.58,340.58L807.03,346.55L809.66,347.26L814.15,343.35L820.01,349.45L820.41,352.81L827.73,352.07L827.73,352.07L827.83,351.93L827.83,351.93L837.23,361.76L835.79,367.51L827.51,367.27L826.43,369.91L821.15,367.51L817.67,370.39L813.95,369.07L806.39,372.19L801.71,376.99L801.35,382.03L805.91,388.99L805.11,393.73L805.11,393.73L801.93,395.72L805.47,399.25L802.12,403.57L802.77,407.71z"
        />
        <path
          id="LV-031"
          title="Garkalnes novads"
          className="land"
          d="M571.17,351.11L565.77,348.35L565.77,348.35L564.21,348.96L564.21,348.96L555.12,337.61L544.82,333.71L543.02,325.92L539.63,323.02L539.63,323.02L547.32,317.32L550.32,318.92L550.32,318.92L551.82,325.22L565.22,329.62L572.62,320.42L575.82,321.82L588.12,316.92L594.41,308.82L594.41,308.82L597.81,310.02L595.41,314.62L597.11,316.02L604.01,313.42L607.91,320.02L607.91,320.02L605.11,322.02L601.11,318.92L596.81,324.32L598.91,337.01L577.22,339.91L575.42,343.41L577.42,346.41z"
        />
        <path
          id="LV-032"
          title="Grobiņas novads"
          className="land"
          d="M64.27,485.39L64.03,493.79L71.22,496.07L70.86,503.39L77.1,512.03L79.5,520.79L76.86,535.3L71.1,544.78L71.1,544.78L52.39,546.1L45.31,538.06L45.31,538.06L42.43,514.79L39.19,513.83L34.99,516.95L29.23,512.51L27.79,494.03L15.44,496.39L15.44,496.39L15.64,496.17L15.64,496.17L15.44,489.98L18.24,484.68L23.79,481.08L23.79,471.53L18.44,471.63L18.54,460.41L9.58,458.96L9.58,458.96L11.56,452L11.56,452L17.23,454.82L25.63,454.1L25.27,443.54L28.87,438.02L38.21,448.54L46.61,446.39L46.61,446.39L52.39,461.16L48.19,468.72L48.43,476.27L50.83,478.19L48.43,480.95L52.27,482.51L62.83,480.47z"
        />
        <path
          id="LV-033"
          title="Gulbenes novads"
          className="land"
          d="M892.96,237.6L905.6,241.74L920.12,238.16L918.44,231.81L912.72,235.05L912.03,230.5L917.8,230.66L919.48,225.52L928.85,224.53L924.14,217.14L926.43,214.59L930.2,216.02L934.96,213.43L934.32,216.58L939.07,219.45L943.51,216.02L943.51,216.02L946.67,219.17L946.67,219.17L948.12,226.55L954.92,231L953.2,235.52L961.41,236.26L960.92,241.21L967.03,245.41L971.81,245.44L971.69,240.12L975.29,239.09L980.74,243.23L990.82,242.2L992.94,246.84L1011.84,244.47L1019.95,252.65L1028.06,255.97L1023.52,259.17L1022.9,262.74L1041.91,269.97L1041.91,269.97L1032.54,285.75L1026.66,288.91L1014.46,307.35L1014.46,307.35L1012.9,309.46L1012.9,309.46L1004.34,325.8L991.13,332.74L991.31,338.17L994.09,337.68L991.18,345.29L991.18,345.29L979.42,343.87L977.45,341.19L969.64,343.47L965.64,348.62L962.86,346.59L954.72,349.3L944.28,356.78L941.96,355.49L941.96,355.49L937.77,333.36L930.22,323.05L927.41,324.35L921.57,320.05L918.54,323.61L909.02,326.63L909.02,326.63L901.27,317.52L900.55,309.67L896.5,314.93L879.1,311.99L878.16,315.48L878.16,315.48L875.01,313.81L866.75,316.81L864.04,315.57L864.04,315.57L868.46,310.94L869.2,305.13L874.56,300.3L874.49,296.4L866.48,287.52L863.75,276.51L857.39,273.01L847.82,273.44L847.82,273.44L845.55,264.32L852.19,261.72L853.71,264.26L856.69,263.05L854.87,257.43L854.87,257.43L860.67,252.4L867.05,256.03L872.92,253.24L890.91,253.05L889.03,245.47L892.8,245.75L894.78,243.39z"
        />
        <path
          id="LV-034"
          title="Iecavas novads"
          className="land"
          d="M572.02,443.52L575.02,452.44L574.38,455L564.62,455L565.42,470.68L567.18,473.08L576.94,473.88L576.94,481.08L576.94,481.08L571.34,477.88L568.94,481.24L565.58,479.32L556.78,481.88L552.46,489.55L540.47,483.48L523.44,478.97L523.44,478.97L526.15,477.56L529.99,468.42L517.37,468.15L516.88,464.43L512.79,464.49L511.01,459.24L514.85,458.02L513.68,461.16L516.8,462.48L516.38,459.3L521.66,458.66L520.15,453.05L517.1,452.86L520.97,450.24L511.57,448.01L509.5,443.73L510.17,441.41L512.71,441.65L514.75,445.47L518.45,445.1L517.08,434.25L517.08,434.25L520.57,432.82L526.09,436.46L527.77,433.86L529.57,440.43L534.3,442.11L535.64,440.06L537.31,443.12L537.43,440.67L541.87,438.96L538.42,437.19L540.73,436.58L540.73,432.94L541.73,435.66L551.66,433.34L552.08,428.53L552.08,428.53L555.77,436.46z"
        />
        <path
          id="LV-035"
          title="Ikšķiles novads"
          className="land"
          d="M594.52,370.75L605.71,368.97L607.87,371.91L621.19,371.91L631.57,369.69L631.57,369.69L631.29,371.35L631.29,371.35L631.24,374.39L639.4,381.03L638.76,382.79L633.8,383.27L630.28,386.55L627.8,384.31L624.04,384.87L618.29,393.51L610.05,390.87L604.61,394.95L604.61,394.95L597.73,397.99L597.73,397.99L594.63,398.25L594.63,398.25L584.54,388.08L584.54,388.08L586.73,380.34L582.89,373.02L595.49,380.23z"
        />
        <path
          id="LV-036"
          title="Ilūkstes novads"
          className="land"
          d="M905.12,620.28L907.28,639.24L911.12,648.72L908.84,651.84L902.48,650.52L895.88,655.73L894.56,660L891.68,660L891.68,665.56L898.52,667.12L890.96,670.6L889.16,676L892.04,681.52L901.88,689.2L899.84,694.12L901.88,697.24L899,699.28L894.56,697.12L886.62,698.73L886.62,698.73L885.49,692.93L880.61,686.35L867.35,680.6L862.63,674.74L854.75,658.1L829.61,644.03L822.95,643.94L822.92,637.6L819.01,634.66L817.15,628.86L817.15,628.86L819.5,628.31L819.92,622.15L825.51,618.45L819.65,613.87L817.72,607.76L830.15,615.77L836.09,625.88L848.32,618.06L848.32,618.06L854.77,624.65L862.19,618.78L865.05,611.16L869.9,615.35L872.65,608.39L869.78,605.35L881.91,592.3L884.82,592.24L888.66,593.87L889.46,599.8L895.4,596.43L899.86,598.18L901.66,601.79L899.61,604.87L900.37,614.78L903.56,618.48L903.56,618.48z"
        />
        <path
          id="LV-037"
          title="Inčukalna novads"
          className="land"
          d="M642.72,297.71L640.53,306.27L635.65,306.11L639.57,310.18L639.49,314.82L631.25,317.38L631.01,319.94L626.53,321.7L619.73,318.58L617.22,320.25L617.22,320.25L607.91,320.02L607.91,320.02L604.01,313.42L597.11,316.02L595.41,314.62L597.81,310.02L594.41,308.82L594.41,308.82L595.26,305L595.26,305L603.63,301.91L606.73,295.62L622.62,299.21L622.62,299.21L624.12,300.21L634.32,293.92L641.22,294.12z"
        />
        <path
          id="LV-038"
          title="Jaunjelgavas novads"
          className="land"
          d="M771.05,470.16L771.5,472.81L780.41,470.92L779.54,479.09L773.46,481.65L770.55,489.35L778.7,498.44L778.76,502.58L778.76,502.58L771.5,510.42L765.49,508.51L757.13,520.2L743.66,521.26L729.65,517.43L728.77,520.8L728.77,520.8L705.16,517.05L693.67,517.65L690.67,503.35L686.97,501.45L682.61,490.89L682.61,490.89L681.91,479.19L674.51,474.29L673.11,468.39L673.11,468.39L683.16,465.82L686.88,462.88L687,459.41L687,459.41L693.36,453.59L700.49,455.15L700.49,455.15L722.51,470.98L725.63,464.14L732.5,464.26L732.5,464.26L741.02,456.23L741.02,453.65L749.42,449.81L762.8,459.65L762.8,459.65L763.22,468.88z"
        />
        <path
          id="LV-039"
          title="Jaunpiebalgas novads"
          className="land"
          d="M832.78,310.29L827.01,310.3L819.34,299.86L808.78,297.1L807.34,294.71L809.26,289.31L805.78,285.11L807.53,275.85L807.53,275.85L809.38,275.63L809.38,275.63L817.06,273.95L821.49,281.75L825.57,283.07L835.29,281.51L847.82,273.44L847.82,273.44L857.39,273.01L863.75,276.51L866.48,287.52L874.49,296.4L874.56,300.3L869.2,305.13L868.46,310.94L864.04,315.57L864.04,315.57L857.24,310.75L850.46,316.25L838.5,308.81z"
        />
        <path
          id="LV-040"
          title="Jaunpils novads"
          className="land"
          d="M330.6,384.24L331.97,385.54L322.68,393.14L326.88,395.23L328.38,399.83L335.57,402.23L349.27,397.93L355.07,419.13L357.57,423.83L360.87,424.83L361.87,429.03L361.87,429.03L361.87,431.53L361.87,431.53L360.8,434.77L346.97,433.77L344.67,426.61L324.27,427.74L319.48,429.18L319.5,431.9L316.57,430.8L314.99,433.64L311.25,433.64L311.25,433.64L312.26,429.51L309.57,427.62L313.45,426.97L312.07,410.41L306.64,401.98L306.64,401.98L310.7,395.33L315.1,392.94L313.8,384.34L318.5,382.54z"
        />
        <path
          id="LV-041"
          title="Jelgavas novads"
          className="land"
          d="M426.01,371.36L442.42,371.66L444.36,375.76L447.04,373.7L460.8,383.6L465.9,396.67L470.91,399.06L470.91,399.06L470.96,399.1L470.96,399.1L468.37,417.7L468.37,417.7L462.88,419.01L461.12,423.65L455.52,425.57L457.6,428.45L464.48,428.45L466.24,433.09L464.16,433.57L464.16,433.57L461.6,435.01L461.6,435.01L455.84,439.48L450.24,436.6L446.88,439.32L449.44,445.24L456,451.16L455.36,462.04L457.76,463.8L461.44,458.68L472.8,455L473.28,453.08L468.32,450.68L470.88,447.8L473.28,448.76L473.28,448.76L473.28,448.76L473.28,448.76L480.96,450.68L488.32,456.12L497.43,457.08L503.83,468.76L506.71,482.2L517.31,490.91L517.31,490.91L517.31,490.91L517.31,490.91L512.05,493.18L502.6,492.42L497.45,499.51L489.12,500.69L483.04,511.94L482.91,514.97L486.09,516.74L483.15,518.38L484.31,523.05L481.73,523.41L481.03,526.69L489.99,527.36L487.08,532.75L487.76,542.24L487.76,542.24L476.7,541.63L472.95,547.9L470.36,547.12L472.78,535.18L470.31,531.78L448.25,535.54L433.48,544.3L415.54,538.45L411.99,532.91L407.21,530.63L407.21,530.63L415.32,528.54L421.09,522.17L415.57,515.1L414.46,509.72L416.31,507.02L411.82,505.95L416.14,498.93L411.05,495.4L411.05,495.4L411.05,495.4L411.05,495.4L417.86,491.6L416.88,488.5L421.28,477.81L413.81,473.57L413.5,468.85L424.02,466.26L422.99,463.27L409.43,461.32L409.92,456.43L415.52,454.48L415.71,448.83L422.98,445.01L419.27,438.32L425.15,437.16L421.41,433.31L412.85,430.65L413.79,426.42L419.17,419.84L431.24,414.39L427.49,396.09L424.09,396.7L424.09,396.7L420.55,375.51L426.06,373.09L426.06,373.09z"
        />
        <path
          id="LV-042"
          title="Jēkabpils novads"
          className="land"
          d="M831.19,494.07L834.77,499.21L855.96,507L861.65,521.71L861.65,521.71L869.19,524.38L872.5,530.6L877.16,555.71L880.41,562.34L877.03,574.7L892.32,583.58L897.19,590.94L897.19,590.94L898.06,591.57L898.06,591.57L904.53,600.49L904.13,619.14L904.13,619.14L903.56,618.48L903.56,618.48L900.37,614.78L899.61,604.87L901.66,601.79L899.86,598.18L895.4,596.43L889.46,599.8L888.66,593.87L884.82,592.24L881.91,592.3L869.78,605.35L872.65,608.39L869.9,615.35L865.05,611.16L862.19,618.78L854.77,624.65L848.32,618.06L848.32,618.06L847.27,611.42L842.11,610.1L837.73,604.1L840.97,599.9L840.25,597.5L828.19,591.39L825.11,576.57L812,571.77L793.32,572.79L793.32,572.79L793.32,567.78L793.32,567.78L790.52,556.88L793.22,553.38L800.41,553.38L801.81,535.76L801.81,535.76L811.59,535.44L812.99,530.84L817.09,528.94L816.79,522.24L821.19,520.94L821.49,518.24L814.29,509.74L817.19,498.95L817.19,498.95L817.05,498.03L817.05,498.03L826.79,501.07z"
        />
        <path
          id="LV-043"
          title="Kandavas novads"
          className="land"
          d="M256.43,384.15L260.2,378.4L260.97,370.56L260.03,372.01L259.26,369.64L263.35,368.23L263.59,363.48L253.99,361.8L249.11,364.66L249.11,364.66L248.54,358.26L252.06,356.87L252.61,345.6L254.16,346.28L258.2,334.69L258.2,334.69L266.56,338.39L269.98,335.7L267.55,335.06L268.06,332.65L273.07,331.85L281.01,335.36L282.95,329.63L279.99,318.66L280.46,310.1L302.79,305.5L308.94,296.62L311.08,298.29L319.29,295.94L330.03,299.31L330.03,299.31L331.5,303.75L322,312.95L313.6,316.25L315.3,319.85L313.7,326.75L319.2,329.95L319.5,332.45L318.1,337.05L311.2,338.75L309.7,347.94L311.9,356.24L318,363.34L326.3,363.34L327.3,372.94L324.9,374.94L330.6,384.24L330.6,384.24L318.5,382.54L313.8,384.34L315.1,392.94L310.7,395.33L306.64,401.98L306.64,401.98L305.55,398.18L292.54,401.95L287.29,397.35L280.96,398.64L280.54,394.09L276.18,394.95L272.1,388.08L262.67,389.37z"
        />
        <path
          id="LV-044"
          title="Kārsavas novads"
          className="land"
          d="M1113.79,460.35L1114.81,453.25L1107.83,452.07L1103.69,445.26L1100.18,444.59L1096.68,448.16L1092.76,440.31L1097.01,436.52L1098.58,424.06L1102.47,421.16L1101.58,414.26L1095.68,411.86L1097.28,407.56L1080.18,409.06L1075.58,406.46L1075.58,406.46L1074.83,406.61L1074.83,406.61L1072.89,396L1076.52,393.17L1081.13,396.06L1084.55,392.13L1078.47,391.27L1087.05,385.68L1087.05,385.68L1106.1,383.87L1104.35,378.03L1110.04,378L1113.12,384.64L1117.07,384.09L1119.11,388.94L1125.72,390.2L1125.72,390.2L1130.92,389.95L1138.39,383.78L1151.33,381.54L1158.61,377.05L1162.28,377.94L1161.44,380.8L1167.31,394.49L1177.9,390.2L1179.26,397.47L1173.86,400.57L1173.86,407.99L1169.2,409.15L1166.37,415.71L1174.26,426.94L1180.02,429.76L1179.33,433.89L1179.33,433.89L1169.38,435.56L1166.18,427.36L1161.58,427.26L1158.38,442.65L1160.78,447.65L1158.48,450.95L1146.78,446.65L1155.18,446.55L1153.18,440.35L1148.38,442.25L1138.19,439.75L1131.19,441.35L1127.99,446.45L1119.99,449.95L1118.79,458.55z"
        />
        <path
          id="LV-045"
          title="Kocēnu novads"
          className="land"
          d="M694.11,136.39L699.99,137.34L710.54,131.1L712.7,137.74L723.45,149.98L724.65,155.18L735.29,161.5L740.25,170.85L740.25,170.85L741.98,181.84L741.98,181.84L740.78,186.56L744.14,195.84L742.3,198.95L745.36,203.23L745.36,203.23L747.15,203.98L743.04,207.82L745.99,212.5L742.74,215.83L742.29,221.6L742.29,221.6L736.89,223.3L736.16,217.63L733.04,215.63L727.52,220.43L722.17,218.19L719.21,212.67L715.06,211.25L714.25,214.99L710.73,213.79L706.97,203.64L700.73,199.16L701.21,190.84L697.61,188.52L693.77,190.12L692.09,186.6L689.01,186.18L689.01,186.18L691.42,183.62L684.97,182.28L686.38,177.85L678.16,165.19L664.48,169.15L662.5,167.77L659.38,161.65L661.05,152.32L661.05,152.32L661.04,151.41L661.04,151.41L666.2,152.67L677.84,141.7L680.78,142.9L681.98,147.7L687.44,147.82L689.48,144.94L687.5,137.57z"
        />
        <path
          id="LV-046"
          title="Kokneses novads"
          className="land"
          d="M763.66,394.78L770.44,398.98L777.76,397.06L790,401.86L795.88,412.12L795.88,412.12L792.52,416.11L781.6,413.23L785.2,418.15L785.2,427.39L776.8,429.31L779.68,436.87L770.44,447.19L767.08,459.79L762.8,459.65L762.8,459.65L749.42,449.81L741.02,453.65L741.02,456.23L732.5,464.26L732.5,464.26L728.79,451.42L729.49,440.72L726.09,432.72L726.09,432.72L728.34,424.14L722.18,421.42L722.1,419.18L733.21,411.98L745.37,413.66L748.65,409.18L753.13,410.38L759.05,404.78L761.21,396.31z"
        />
        <path
          id="LV-047"
          title="Krāslavas novads"
          className="land"
          d="M1139.57,652.91L1125.23,665.99L1125.25,670.66L1120.42,676.95L1122.96,690.81L1118.23,708.41L1087.93,706.17L1076.82,700.16L1073.83,704.62L1062.69,708.77L1057.17,706.02L1049.7,695.05L1044.21,693.53L1041.17,688.98L1033.02,691.49L1031.58,696.64L1017.73,696.01L1011.84,700.4L1011.84,700.4L1012.45,685.78L1005.07,686.2L1003.23,678.27L1008.79,671.45L1005.55,665.66L1010.47,660.17L1004.89,655.82L1003.93,645.26L1009.51,645.65L1010.95,643.13L1010.95,643.13L1019.89,648.28L1025.89,644.21L1039.4,646.21L1045.64,640.96L1046.44,627.84L1054.76,625.92L1058.84,620.16L1058.84,620.16L1078.62,622.84L1074.52,636.44L1076.32,639.43L1086.02,633.74L1114.61,650.53L1123.21,651.03L1122.21,644.33L1124.21,642.03z"
        />
        <path
          id="LV-048"
          title="Krimuldas novads"
          className="land"
          d="M624.32,271.49L629.94,269.72L631.42,266.34L626.93,266.27L624.69,250.1L619.01,248.34L617.73,244.34L625.09,240.1L629.73,233.14L626.61,222.18L636.13,214.74L638.45,216.18L637.73,220.58L651.73,221.94L651.73,221.94L657.73,236.02L663.49,236.95L658.59,243.35L661.52,248.14L664.31,247.89L669.85,253.61L667.43,262.18L667.43,262.18L667.06,262.27L667.06,262.27L662.31,267.92L662.31,267.92L661.92,270.28L661.92,270.28L658.42,281.72L648.12,284.22L645.02,291.52L645.32,297.92L642.72,297.71L642.72,297.71L641.22,294.12L634.32,293.92L624.12,300.21L622.62,299.21L622.62,299.21L624.72,295.21L623.92,285.72L622.02,283.82L614.72,284.12L614.72,276.22L621.62,275.92z"
        />
        <path
          id="LV-049"
          title="Krustpils novads"
          className="land"
          d="M861.65,521.71L855.96,507L834.77,499.21L831.19,494.07L831.19,494.07L831.19,490.97L835.09,489.37L835.39,486.87L826.99,483.87L818.49,485.35L818.49,485.35L805.79,482.29L802.19,472.29L805.79,465.49L806,458.54L806,458.54L806.12,458.54L806.12,458.54L810.28,460.68L819.69,459.12L835.03,451.4L835.03,451.4L843.02,443.15L845.45,450.21L849.15,451.34L851.62,456.95L869.41,445.53L873.29,450.24L878.48,448.53L883.22,462.05L892.78,456.68L894.21,467.11L899.41,467.45L907.35,459.94L910.41,449.02L910.41,449.02L928.38,463.79L946.79,463.85L944.47,479.73L949.21,476.35L951.74,481.28L951.74,481.28L946.79,493.64L949.68,492.97L949.36,497.2L943.59,497.26L943.59,497.26L941.34,497.32L941.34,497.32L938.14,497.11L938.44,494.7L928,493.15L920.71,501.12L908.09,508.38L908.12,501.24L899.32,493.43L901.26,490.35L891.73,486.76L891.38,490.6L885.31,493.82L873.04,514.82z"
        />
        <path
          id="LV-050"
          title="Kuldīgas novads"
          className="land"
          d="M201.91,402.29L197.26,398.09L192.3,399.29L189.1,393.05L181.18,392.33L179.18,395.13L174.46,392.25L171.9,393.21L166.3,398.01L163.26,404.89L167.9,424.92L165.26,427.16L159.66,424.84L154.71,426.36L151.75,430.12L146.31,431.24L144.59,435.84L144.59,435.84L133.45,431.01L136.16,428.87L136.39,422.81L133.21,422.11L134.22,418.22L131.18,411.82L122.19,404.37L113.81,403.88L105.84,387.89L92.04,388.91L91.28,381.26L85.17,376.59L85.17,376.59L85.17,376.59L85.17,376.59L88.34,368.75L88.34,368.75L97.55,372.01L102.05,363.31L111.55,364.91L113.35,360.81L121.25,358.11L123.74,354.01L120.05,341.91L110.15,331.51L110.75,324.73L106.45,324.13L97.32,316.5L97.32,316.5L101.65,312.11L105.5,313.32L114.06,302.87L121.77,306.08L123.82,301.57L131.68,307.07L137.92,306.61L139.25,310.23L136.61,312.48L138.66,315.3L140.15,312.89L144.2,315.08L147.43,307.85L153.34,309.3L155.52,312.33L159.01,309.18L164.31,310.85L166.81,308.22L173.49,307.85L175.67,304.17L184.68,308.81L193.97,308.59L193.62,305.62L203.7,304.17L205.95,301.85L208.63,281.94L208.63,281.94L211.47,285.75L215.68,286.21L214.05,294.67L219.04,299.93L233.56,304.97L235.84,322.06L233.31,323.11L233.4,326.82L233.77,329.19L236.74,329.04L238.09,336.2L247.83,334.19L253.39,336.35L256.72,332.93L258.2,334.69L258.2,334.69L254.16,346.28L252.61,345.6L252.06,356.87L248.54,358.26L249.11,364.66L249.11,364.66L245.41,374.07L240.21,375.7L233.09,370.78L231.68,363.12L223.88,360.48L220.96,364.78L222.36,369.7L219.35,369.92L218.49,374.44L228.99,375.51L229.29,379.6L232.92,382.74L230.43,386.82L226.8,386.3L224.76,391.21L215.88,391.98L211.91,394.8L208.95,403.33z"
        />
        <path
          id="LV-051"
          title="Ķeguma novads"
          className="land"
          d="M687,459.41L686.88,462.88L683.16,465.82L673.11,468.39L673.11,468.39L664.02,470.25L661.24,468.6L655.8,471.16L651.4,469.88L633.48,479.16L628.12,477.24L623.61,478.44L621.49,471.9L627.33,471.38L627.19,466.87L624.84,464.76L621.66,466.1L622.52,461.71L613.89,461.68L608.25,446.11L608.55,440.03L617.39,439.6L616.46,428.2L599.62,429.21L598.98,426.73L598.98,426.73L598.72,415.49L598.72,415.49L601.23,410.75L599.81,405.24L596.37,403L596.62,398.08L596.62,398.08L597.73,397.99L597.73,397.99L604.61,394.95L604.61,394.95L609.86,397.38L617.46,410.17L628.35,416.17L636.15,414.77L637.15,409.48L642.45,407.28L643.05,405.18L639.25,404.58L640.95,396.28L646.85,396.68L647.65,387.28L649.65,385.98L655.35,391.18L657.55,388.68L659.55,392.78L666.05,396.28L666.05,396.28L666.35,397.8L666.35,397.8L657.95,408.9L653.45,410.69L652.25,421.29L650.25,422.79L641.85,421.09L639.65,424.19L644.65,428.09L655.65,431.19L659.15,444.83L672.25,457.33L680.34,460.33L685.24,459.63L685.24,459.63z"
        />
        <path
          id="LV-052"
          title="Ķekavas novads"
          className="land"
          d="M584.54,388.08L594.63,398.25L594.63,398.25L596.62,398.08L596.62,398.08L596.37,403L599.81,405.24L601.23,410.75L598.72,415.49L598.72,415.49L579.72,405.04L572.02,403.74L562.42,407.74L556.32,403.44L553.62,408.94L557.12,411.74L557.32,416.64L554.82,418.24L552.72,414.34L545.63,418.74L548,425.54L548,425.54L541.65,425.54L541.65,425.54L538.05,422.94L509.15,424.18L509.15,424.18L509.16,424.13L509.16,424.13L509.45,422.93L509.45,422.93L510.65,413.85L516.97,409.05L525.61,409.61L528.57,400.97L532.25,397.53L531.92,390.33L525.69,383.54L525.69,377.14L525.69,377.14L527.98,372.23L532.41,370.19L535.41,372.59L537.57,368.63L539.85,368.87L543.93,377.39L543.93,377.39L542.61,381.47L549.81,389.26L553.65,390.34L563.73,386.98L578.01,389.86z"
        />
        <path
          id="LV-053"
          title="Lielvārdes novads"
          className="land"
          d="M685.24,459.63L680.34,460.33L672.25,457.33L659.15,444.83L655.65,431.19L644.65,428.09L639.65,424.19L641.85,421.09L650.25,422.79L652.25,421.29L653.45,410.69L657.95,408.9L666.35,397.8L666.35,397.8L666.14,396.75L666.14,396.75L670.36,394.15L672.16,398.75L681.06,401.25L685.36,399.95L695.05,416.05L695.05,419.95L695.05,419.95L685.66,420.65L684.46,422.75L689.65,431.05L682.16,434.35L680.56,449.64L685.26,451.94z"
        />
        <path
          id="LV-054"
          title="Limbažu novads"
          className="land"
          d="M622.36,104.06L625.76,104.06L635.06,115.35L643.56,115.15L650.76,119.85L652.26,135.67L662.36,141.07L661.03,150.47L661.03,150.47L661.05,152.32L661.05,152.32L659.38,161.65L662.5,167.77L664.48,169.15L678.16,165.19L686.38,177.85L684.97,182.28L691.42,183.62L689.01,186.18L689.01,186.18L686.34,192.53L679.69,190.75L680.9,193.21L678.23,193.52L677.72,196.93L663.14,209.24L653.17,213.81L641.8,209.14L641,212.13L650.29,216.63L651.73,221.94L651.73,221.94L637.73,220.58L638.45,216.18L636.13,214.74L626.61,222.18L629.73,233.14L625.09,240.1L617.73,244.34L619.01,248.34L624.69,250.1L626.93,266.27L631.42,266.34L629.94,269.72L624.32,271.49L624.32,271.49L621.93,268.08L616.86,267.69L615.16,258.75L606.71,250.93L606.06,242.08L601.05,241.36L585.13,248.58L585.13,248.58L582.27,248.4L581.73,237.35L577.74,231.53L577.74,231.53L577.04,216.17L577.04,216.17L593.55,212.48L599.05,201.78L605.05,201.58L608.15,196.18L602.25,190.88L601.65,173.58L595.75,173.28L595.35,166.98L593.15,165.48L583.55,165.68L581.95,134.55L594.87,131.55L599.17,134.25L608.37,132.85L603.07,130.35L604.47,123.15L607.97,120.15L615.47,119.95L613.07,116.55L614.97,114.05L613.57,108.75z"
        />
        <path
          id="LV-055"
          title="Līgatnes novads"
          className="land"
          d="M693.4,302.26L693.4,300.82L693.4,300.82L675.91,299.53L673.05,295.47L675.87,294.14L674.46,282.5L669.24,273.95L661.93,270.22L661.93,270.22L662.31,267.92L662.31,267.92L667.06,262.27L667.06,262.27L667.32,262.21L667.32,262.21L687.24,260.69L692.44,257.73L692.44,257.73L692.44,263.17L704.36,267.07L706.44,286.99L711.64,288.51L705.08,299.54z"
        />
        <path
          id="LV-056"
          title="Līvānu novads"
          className="land"
          d="M897.19,590.94L892.32,583.58L877.03,574.7L880.41,562.34L877.16,555.71L872.5,530.6L869.19,524.38L861.65,521.71L861.65,521.71L873.04,514.82L885.31,493.82L891.38,490.6L891.73,486.76L901.26,490.35L899.32,493.43L908.12,501.24L908.09,508.38L920.71,501.12L928,493.15L938.44,494.7L938.14,497.11L941.34,497.32L941.34,497.32L941.83,497.31L941.83,497.31L941.83,511.39L945.51,513.31L945.51,513.31L946.31,513.31L946.31,513.31L944.79,523.79L940.23,531.23L932.96,525.31L929.28,525.31L926.32,536.67L930.16,536.67L934.24,542.26L944.55,547.46L946.79,546.42L945.91,551.3L949.75,558.68L949.75,558.68L945.55,560.6L943.95,564.84L930.92,560.37L920.04,561.48L912.52,547.33L908.84,549.33L908.36,560.37L903.56,560.37L897,555.33L895.88,560.52L900.04,575.43L896.76,575.99L903.4,589.43L903.4,589.43L897.29,591.02L897.29,591.02z"
        />
        <path
          id="LV-057"
          title="Lubānas novads"
          className="land"
          d="M1005.72,388.26L1003.61,385.06L997.11,383.46L993.41,375.96L989.71,374.86L975.31,378.86L967.42,374.96L944.12,403.36L941.12,401.06L934.82,402.36L931.42,399.16L935.42,395.56L934.92,393.46L928.52,391.16L926.72,385.16L941.42,363.27L941.96,355.49L941.96,355.49L944.28,356.78L954.72,349.3L962.86,346.59L965.64,348.62L969.64,343.47L977.45,341.19L979.42,343.87L991.18,345.29L991.18,345.29L992.12,348.25L1000.84,347.85L998.33,359.09L1008.34,364.63L1010.02,374.35L1013.56,378.5L1013.56,378.5L1009.45,386.3z"
        />
        <path
          id="LV-058"
          title="Ludzas novads"
          className="land"
          d="M1213.48,524.34L1205.26,522.95L1198.16,526.85L1192.26,520.35L1184.66,520.35L1183.36,522.25L1187.66,533.65L1186.36,536.15L1177.26,537.75L1159.97,552.71L1163.47,565.91L1174.26,564.61L1173.16,558.01L1180.06,556.91L1185.66,551.21L1193.16,555.61L1205.46,569.3L1198.56,583.2L1198.56,583.2L1197.86,584.6L1197.86,584.6L1193.98,586.88L1193.96,580.8L1191.46,580.6L1180.48,588.96L1173.38,588.26L1167.48,593.46L1165.09,590.36L1159.69,592.56L1151.29,588.26L1141.99,588.16L1136.09,585.36L1129.49,574.13L1129.49,574.13L1134.52,570.17L1131.35,563.69L1137.46,553.08L1130.01,544.42L1133.12,532.79L1122.88,530.51L1120.72,525.99L1123.76,523.51L1120.1,521.26L1122.4,518.47L1117.09,515.86L1113.76,517.77L1115.3,514.58L1112.58,513.58L1107.6,504.43L1108.07,500.39L1100.5,504.62L1098.22,500.12L1099.29,496.38L1096.42,494.28L1098.21,490.6L1096.58,488.47L1100.11,482.41L1112.49,482.67L1114.17,473.79L1114.17,473.79L1119.4,472.95L1131.04,479.79L1133.32,476.07L1136.08,476.43L1150.11,490.58L1151.43,485.79L1157.91,484.23L1160.19,488.91L1165.59,491.18L1167.03,495.26L1172.55,499.22L1172.31,505.22L1175.07,506.42L1178.43,506.3L1181.55,499.82L1185.75,499.34L1189.47,494.18L1193.43,495.98L1196.42,502.1L1199.06,498.02L1207.28,497.88L1207.28,497.88L1215.62,508.69L1213.64,516.86L1215.94,521.56z"
        />
        <path
          id="LV-059"
          title="Madonas novads"
          className="land"
          d="M976,438.75L965.09,435.03L965.09,429.87L953.82,429.99L949.74,433.11L947.22,439.71L943.74,436.59L932.94,442.47L910.74,440.55L910.41,449.02L910.41,449.02L907.35,459.94L899.41,467.45L894.21,467.11L892.78,456.68L883.22,462.05L878.48,448.53L873.29,450.24L869.41,445.53L851.62,456.95L849.15,451.34L845.45,450.21L843.02,443.15L835.03,451.4L835.03,451.4L833.45,445.35L837.82,437.4L833.23,433.73L826.82,434.47L819.21,429.64L816.32,418.5L821.06,412.43L819.31,403.91L814.92,402.1L807.47,392.65L805.11,393.73L805.11,393.73L805.91,388.99L801.35,382.03L801.71,376.99L806.39,372.19L813.95,369.07L817.67,370.39L821.15,367.51L826.43,369.91L827.51,367.27L835.79,367.51L837.23,361.76L827.83,351.93L827.83,351.93L827.86,351.88L827.86,351.88L833.77,348.28L833.89,339.31L826.67,336.5L832.78,310.29L832.78,310.29L838.5,308.81L850.46,316.25L857.24,310.75L864.04,315.57L864.04,315.57L866.75,316.81L875.01,313.81L878.16,315.48L878.16,315.48L878.28,327.82L876.2,330.78L877.96,347.26L872.28,353.5L879.71,351.02L884.27,363.26L892.02,362.86L895.38,359.98L906.5,363.82L904.98,357.02L911.78,355.74L916.34,345.9L910.1,340.14L911.54,334.14L909.02,326.63L909.02,326.63L918.54,323.61L921.57,320.05L927.41,324.35L930.22,323.05L937.77,333.36L941.96,355.49L941.96,355.49L941.42,363.27L926.72,385.16L928.52,391.16L934.92,393.46L935.42,395.56L931.42,399.16L934.82,402.36L941.12,401.06L944.12,403.36L967.42,374.96L975.31,378.86L989.71,374.86L993.41,375.96L997.11,383.46L1003.61,385.06L1005.72,388.26L1005.72,388.26L1003.14,391.49L1003.14,391.49L978.93,428.23z"
        />
        <path
          id="LV-060"
          title="Mazsalacas novads"
          className="land"
          d="M694.87,6.51L698.19,12.91L694.09,27.1L701.99,40.6L698.89,46.52L702.39,52.52L709.79,55.72L714.39,63.82L714.49,69.41L721.39,74.11L721.19,89.61L721.19,89.61L718.29,92.31L709.79,86.21L703.59,76.61L701.09,76.51L695.69,79.11L699.19,83.41L698.49,93.31L690.09,87.81L684.5,87.61L678.6,92.97L678.6,92.97L673.3,89.87L669,91.57L660.7,85.57L656.1,61.58L650.8,58.18L650.43,35.62L650.43,35.62L670.17,23.33L679.64,22.66L691.69,6.38z"
        />
        <path
          id="LV-061"
          title="Mālpils novads"
          className="land"
          d="M647.9,345.07L651.66,345.08L653.1,340.19L660.66,335.75L657.66,327.95L660.06,325.19L660.66,317.51L656.22,311.99L656.58,306.36L661.5,313.43L668.34,315.71L675.42,314.34L677.27,318.85L675.82,321.69L683.95,324.65L685.55,331.6L688.31,330.58L700.48,337.9L700.48,337.9L702.46,342.98L700.34,345.11L703.03,349.36L701.63,355.12L701.63,355.12L691.44,355.53L686.68,360.41L682.44,354.93L678.15,357.53L677.67,360.6L674.15,359.63L671.25,354.63L667.56,359.46L664.92,358.78L661.47,361.61L654.95,361.97L650.25,359.03L644.78,360.38L644.78,360.38L643.68,361.25L643.68,361.25L647.82,352.64L647.82,352.64z"
        />
        <path
          id="LV-062"
          title="Mārupes novads"
          className="land"
          d="M520.52,377.14L517.53,377.14L517.53,377.14L515.69,382.98L510.65,385.3L504.65,382.74L485.69,390.73L485.69,390.73L481.43,374.06L488.43,368.26L492.23,367.86L500.63,358.56L500.63,358.56L520.92,368.56z"
        />
        <path
          id="LV-063"
          title="Mērsraga novads"
          className="land"
          d="M340.51,218.25L342.33,215.7L342.33,215.7L348.25,223L362.35,226.58L365.95,241.74L365.95,241.74L360.82,243.51L358.95,247.43L361.58,262.27L361.58,262.27L356.7,263.42L356.13,266.9L356.13,266.9L347.87,260.65L351.23,254.25L347.71,245.77L335.39,248.49L333.47,242.73L327.88,241.13L324.52,232.17L330.27,227.53L334.11,228.97L341.31,225.93L338.43,222.09z"
        />
        <path
          id="LV-064"
          title="Naukšēnu novads"
          className="land"
          d="M759.87,34.67L765.82,33.28L764.56,36.89L771.24,35.91L772.39,39.33L775.84,35.81L778.41,40.56L776.73,45.12L795.02,57.84L795.02,57.84L794.91,65.31L791.79,67.77L790.53,74.36L784.11,74.72L773.97,66.68L767.1,67.47L763.23,72.38L759.7,70.4L758.36,75.29L771.26,87.99L767.46,96.99L767.46,96.99L757.96,95.89L757.96,95.89L756.06,95.89L756.06,95.89L756.14,86.39L751.26,88.79L751.1,82.87L744.46,68.47L741.1,67.67L733.02,58.55L729.26,57.11L727.9,60.87L723.5,49.66L733.34,30.62L739.02,40.94L741.26,38.06L747.9,40.14L749.26,32.3L753.82,29.72L753.82,29.72z"
        />
        <path
          id="LV-065"
          title="Neretas novads"
          className="land"
          d="M728.77,520.8L729.72,541.42L727.49,544.39L735.54,544.94L738.43,542.63L741.06,546.66L732.32,550.02L729.68,556.98L734.81,559.4L738.47,566.36L742.12,566.45L745.84,572.16L743.55,580.26L745.48,589.07L748.98,592.33L747.99,595.83L739.69,596.1L739.34,598.42L739.34,598.42L731.82,601.19L717.85,586.87L712.28,592.09L704.22,592.09L691.4,586.05L687.85,564.67L682.06,561.46L680.9,556.77L674.56,550.72L673.67,534.94L669.01,531.48L663.64,519.98L664.89,511.88L659.52,508.99L659.52,508.99L660.11,504.38L667.11,494.18L682.61,490.89L682.61,490.89L686.97,501.45L690.67,503.35L693.67,517.65L705.16,517.05z"
        />
        <path
          id="LV-066"
          title="Nīcas novads"
          className="land"
          d="M45.31,538.06L39.93,534.78L36.41,537.33L37.05,556.21L28.89,576.85L26.33,578.13L25.53,575.89L22.65,576.21L22.49,594.92L17.05,594.12L15.45,576.69L9.05,572.69L13.37,570.93L13.69,565.81L11.45,564.21L2.09,565.75L2.09,565.75L0,532.79L5.06,512.06L5.23,501.97L5.23,501.97L8.64,502.07L11.34,497.87L14.34,497.57L14.34,497.57L15.44,496.39L15.44,496.39L27.79,494.03L29.23,512.51L34.99,516.95L39.19,513.83L42.43,514.79z"
        />
        <path
          id="LV-067"
          title="Ogres novads"
          className="land"
          d="M701.63,355.12L720.68,362.01L719.1,358.75L722.51,357.18L721.56,354.47L725.81,352.5L725.58,349.6L736.35,345.45L740.3,357.67L744.74,361L756.74,348.19L754.77,342.76L758.76,342.18L758.76,342.18L760.39,341.78L760.39,341.78L759.63,344.95L763.76,346L765.77,357.46L769.06,361.21L764.01,371.55L771.31,378.83L760.36,380.68L760.86,385.07L758.74,384.76L761.74,388.69L763.79,388.2L763.66,394.78L763.66,394.78L761.21,396.31L759.05,404.78L753.13,410.38L748.65,409.18L745.37,413.66L733.21,411.98L722.1,419.18L722.18,421.42L728.34,424.14L726.09,432.72L726.09,432.72L715.09,428.52L710.41,431.44L710.41,431.44L710.41,430.46L710.41,430.46L705.29,429.18L695.05,419.95L695.05,419.95L695.05,416.05L685.36,399.95L681.06,401.25L672.16,398.75L670.36,394.15L666.14,396.75L666.14,396.75L666.05,396.28L666.05,396.28L659.55,392.78L657.55,388.68L655.35,391.18L649.65,385.98L647.65,387.28L646.85,396.68L640.95,396.28L639.25,404.58L643.05,405.18L642.45,407.28L637.15,409.48L636.15,414.77L628.35,416.17L617.46,410.17L609.86,397.38L604.61,394.95L604.61,394.95L610.05,390.87L618.29,393.51L624.04,384.87L627.8,384.31L630.28,386.55L633.8,383.27L638.76,382.79L639.4,381.03L631.24,374.39L631.29,371.35L631.29,371.35L631.69,369.03L631.69,369.03L641.61,362.87L641.61,362.87L644.78,360.38L644.78,360.38L650.25,359.03L654.95,361.97L661.47,361.61L664.92,358.78L667.56,359.46L671.25,354.63L674.15,359.63L677.67,360.6L678.15,357.53L682.44,354.93L686.68,360.41L691.44,355.53z"
        />
        <path
          id="LV-068"
          title="Olaines novads"
          className="land"
          d="M509.15,424.18l28.89,-1.24l3.6,2.6l0,0h8.1l0,0l2.34,3l0,0l-0.42,4.8l-9.93,2.32l-1.01,-2.72v3.64l-2.31,0.61l3.45,1.77l-4.44,1.71l-0.12,2.44l-1.67,-3.06l-1.35,2.05l-4.73,-1.68l-1.8,-6.57l-1.68,2.6l-5.52,-3.64l-3.48,1.44l0,0l-6.16,-1.96l1.18,-4.89l-2.98,-3l0,0L509.15,424.18zM525.69,377.14v6.4l6.24,6.8l0.32,7.2l-3.68,3.44l-2.96,8.64l-8.64,-0.56l-6.32,4.8l-1.2,9.08l0,0l-10.97,0.37l-1.48,-7.26l-2.99,2.79l-11.66,0.92l-0.03,4.22l-2.59,-2.48l-3.72,2.76l-7.62,-6.55l0,0l2.59,-18.6l0,0l-0.04,-0.02l0,0l2.45,0.22v-3.84l2.16,-0.96l6.48,4.8l3.68,-8.56l0,0l18.96,-8l6,2.56l5.04,-2.32l1.84,-5.84l0,0H525.69z"
        />
        <path
          id="LV-069"
          title="Ozolnieku novads"
          className="land"
          d="M473.28,448.76L476.32,446.36L473.76,443L474.56,435.33L463.98,433.67L463.98,433.67L464.16,433.57L464.16,433.57L466.24,433.09L464.48,428.45L457.6,428.45L455.52,425.57L461.12,423.65L462.88,419.01L468.37,417.7L468.37,417.7L475.99,424.25L479.71,421.5L482.3,423.98L482.34,419.75L494,418.83L496.99,416.05L498.47,423.3L509.45,422.93L509.45,422.93L509.16,424.13L509.16,424.13L509.13,424.4L509.13,424.4L512.1,427.4L510.93,432.3L517.08,434.25L517.08,434.25L518.45,445.1L514.75,445.47L512.71,441.65L510.17,441.41L509.5,443.73L511.57,448.01L520.97,450.24L517.1,452.86L520.15,453.05L521.66,458.66L516.38,459.3L516.8,462.48L513.68,461.16L514.85,458.02L511.01,459.24L512.79,464.49L516.88,464.43L517.37,468.15L529.99,468.42L526.15,477.56L523.44,478.97L523.44,478.97L523.34,483.35L517.31,490.91L517.31,490.91L506.71,482.2L503.83,468.76L497.43,457.08L488.32,456.12L480.96,450.68z"
        />
        <path
          id="LV-070"
          title="Pārgaujas novads"
          className="land"
          d="M689.01,186.18L692.09,186.6L693.77,190.12L697.61,188.52L701.21,190.84L700.73,199.16L706.97,203.64L710.73,213.79L714.25,214.99L715.06,211.25L719.21,212.67L722.17,218.19L727.52,220.43L733.04,215.63L736.16,217.63L736.89,223.3L742.29,221.6L742.29,221.6L738.59,229L718.69,227.3L716.9,234.1L716.9,234.1L716.1,236.19L716.1,236.19L713.8,240.19L716.3,243.09L714.9,245.89L714.9,245.89L714.4,248.29L714.4,248.29L709.5,256.19L698.7,253.49L692.44,257.73L692.44,257.73L687.24,260.69L667.32,262.21L667.32,262.21L667.43,262.18L667.43,262.18L669.85,253.61L664.31,247.89L661.52,248.14L658.59,243.35L663.49,236.95L657.73,236.02L651.73,221.94L651.73,221.94L650.29,216.63L641,212.13L641.8,209.14L653.17,213.81L663.14,209.24L677.72,196.93L678.23,193.52L680.9,193.21L679.69,190.75L686.34,192.53z"
        />
        <path
          id="LV-071"
          title="Pāvilostas novads"
          className="land"
          d="M85.17,376.59L81.61,377.57L82.56,388.87L61.66,393.92L60.96,399.72L56.06,400.79L58.41,416.04L55.71,418.79L55.71,418.79L50.31,417.54L46.36,423.1L53.21,433.04L53.21,439.34L51.11,442.54L47.56,442.39L46.61,446.39L46.61,446.39L38.21,448.54L28.87,438.02L25.27,443.54L25.63,454.1L17.23,454.82L11.56,452L11.56,452L14.96,432.45L15.16,390.04L46.84,364.17L60.41,348.03L64.44,350.96L66.95,349.08L71.71,351.37L72.67,356.27L72.67,356.27L72.77,362.6L76.78,364.87L80.28,363.4L79.5,366.44L88.34,368.75L88.34,368.75z"
        />
        <path
          id="LV-072"
          title="Pļaviņu novads"
          className="land"
          d="M762.8,459.65L767.08,459.79L770.44,447.19L779.68,436.87L776.8,429.31L785.2,427.39L785.2,418.15L781.6,413.23L792.52,416.11L795.88,412.12L795.88,412.12L802.77,407.71L802.12,403.57L805.47,399.25L801.93,395.72L805.11,393.73L805.11,393.73L807.47,392.65L814.92,402.1L819.31,403.91L821.06,412.43L816.32,418.5L819.21,429.64L826.82,434.47L833.23,433.73L837.82,437.4L833.45,445.35L835.03,451.4L835.03,451.4L819.69,459.12L810.28,460.68L806.12,458.54L806.12,458.54L805.84,458.54L805.84,458.54L800.6,458.42L785.43,467.17L771.05,470.16L771.05,470.16L771.05,470.16L771.05,470.16L763.22,468.88z"
        />
        <path
          id="LV-073"
          title="Preiļu novads"
          className="land"
          d="M956.55,605.05L949.43,599.55L947.67,594.91L960.63,593.31L960.79,578.59L965.51,578.59L965.51,574.51L963.83,569.96L959.75,569.96L959.35,560.2L949.75,558.68L949.75,558.68L945.91,551.3L946.79,546.42L944.55,547.46L934.24,542.26L930.16,536.67L926.32,536.67L929.28,525.31L932.96,525.31L940.23,531.23L944.79,523.79L946.31,513.31L946.31,513.31L947.67,513.31L947.67,513.31L948.23,508.35L953.11,506.27L975.35,524.11L976.23,526.43L962.63,531.47L958.07,537.18L957.11,543.98L961.67,548.14L968.63,549.66L971.51,554.69L981.67,560.45L985.99,576.18L990.7,578.1L989.02,586.42L983.51,589.78L991.98,594.01L996.3,599.29L996.34,604.21L996.34,604.21L990.18,610.41L984.74,606.33L972.9,614.01L965.46,614.01L963.26,606.21z"
        />
        <path
          id="LV-074"
          title="Priekules novads"
          className="land"
          d="M87.97,556.83L84.56,551.93L72.36,547.63L71.1,544.78L71.1,544.78L76.86,535.3L79.5,520.79L77.1,512.03L70.86,503.39L71.22,496.07L64.03,493.79L64.27,485.39L64.27,485.39L72.3,485.75L71.46,477.71L73.5,475.43L88.62,487.91L92.1,487.91L93.3,485.15L89.1,478.79L90.42,475.43L98.46,469.92L102.68,472.8L102.68,472.8L102.74,473.32L102.74,473.32L105.94,477.12L108.54,475.02L112.74,476.92L115.24,472.12L125.63,475.62L125.63,475.62L125.48,491.24L129.68,493.64L130.4,497.72L120.68,502.4L123.92,511.15L117.44,514.99L124.52,519.07L128.6,525.31L134.48,525.31L128,533.59L131.92,543.69L131.92,543.69L126.87,550.21L118.64,550.84L112.13,547.18L105.47,547.24L100.07,549.42L95.37,556.26z"
        />
        <path
          id="LV-075"
          title="Priekuļu novads"
          className="land"
          d="M779.94,256.18L779.57,261.94L772.27,265.64L771.77,270.13L771.77,270.13L766.67,273.23L759.97,269.83L761.67,258.44L756.47,251.34L745.28,245.42L730.08,247.82L728.58,245.22L732.68,242.12L730.58,238.82L716.72,234.56L716.72,234.56L716.9,234.1L716.9,234.1L718.69,227.3L738.59,229L742.29,221.6L742.29,221.6L742.74,215.83L745.99,212.5L743.04,207.82L747.15,203.98L745.36,203.23L745.36,203.23L747.72,200.33L754.9,204.39L762.7,199.27L765.76,206.7L767.9,206.48L771.02,205.17L771.66,199.36L778.19,195.02L788.84,196.37L788.84,196.37L790.07,202.8L790.07,202.8L785.3,207.52L780.58,223.07L775.46,220.83L764.03,227.23L773.62,246.82L778.9,250.74L777.78,253.78z"
        />
        <path
          id="LV-076"
          title="Raunas novads"
          className="land"
          d="M804.91,256.13l1.36,-2.95l7.2,-1.18l5.38,1.24l-0.47,2.45l9.99,-4.88l9.24,1.62l10.65,-2.55l3.06,3.48l3,-0.22l0.54,4.29l0,0l1.82,5.62l-2.98,1.21l-1.51,-2.54l-6.65,2.61l2.27,9.12l0,0l-12.53,8.06l-9.72,1.56l-4.08,-1.32l-4.44,-7.8l-7.68,1.68l0,0l-3,0.36l0,0l-3.24,-2.64L804.91,256.13zM790.07,202.8l7.71,12.91l-3.45,2.81l3.77,6.92l-0.23,5.89l8.75,6.72l-6.04,1.99l2.2,2.89l-3.88,10.71l1.52,1.76l0,0l1.71,0.28l0,0l-3.63,3.64l-2.8,-5.2l-15.76,2.08l0,0l-2.16,-2.4l1.12,-3.04l-5.28,-3.92l-9.6,-19.6l11.44,-6.4l5.12,2.24l4.72,-15.55L790.07,202.8z"
        />
        <path
          id="LV-077"
          title="Rēzeknes novads"
          className="land"
          d="M1137.46,553.08l-7.45,-8.65l3.11,-11.63l-10.24,-2.27l-2.15,-4.52l3.04,-2.48l-3.66,-2.25l2.3,-2.79l-5.31,-2.61l-3.33,1.91l1.55,-3.19l-2.73,-1l-4.98,-9.14l0.47,-4.04l-7.57,4.23l-2.27,-4.5l1.06,-3.74l-2.86,-2.1l1.78,-3.68l-1.63,-2.13l3.53,-6.06l12.38,0.26l1.68,-8.88l-3.9,-1.95l-1.21,-8.29l4.73,-3.19l1.02,-7.1l-6.98,-1.18l-4.14,-6.81l-3.52,-0.67l-3.5,3.57l-3.92,-7.85l4.26,-3.79l1.56,-12.46l3.9,-2.9l-0.9,-6.9l-5.9,-2.4l1.6,-4.3l-17.1,1.5l-4.6,-2.6l-1.5,0.3l-6.68,0.67l-6.97,-3.37l-15.03,0.61l-3.31,-6.5l-4.29,2.33l-2.69,-2.51l-8.63,0.31l-0.12,4.2l-6.65,-0.34l0.71,-5.06l-7.91,-1.56l-3.73,4.69l-5.64,-8.74l-24.21,36.74L976,438.75l0.86,0.73l9.49,-0.43l2.7,4.5l7,-0.7l7.2,17.02l5.1,4.2l8.1,1l3.7,4.6l-1.2,3.6l2.2,3.6l-2.7,5l9.1,21.7l-4.2,5.4l-6.7,-1.8l-6.6,3.1l-3.37,-4.03l-1.92,-1l-3.95,5.44l-0.32,5.59l-3.35,4.37l10.27,10.01l6.7,13.91l-1.97,3.88l-3.48,-2.33l-3.26,1.12l1.25,8.29l11.91,9.68l-4.98,8.91l3.79,-0.18l2.24,3.32l3.27,12.92l12.22,0.15l4.57,-2.66l2.24,-4.72l6.88,2.4l20.14,0.12l3.76,-2.48l7.33,0.19l2.42,0.39l-0.07,2.46l6.08,0.56l3.6,-7.49l-1.84,-3.07l9.68,1.12l4.08,-2.48l1.23,-5.35l10.37,5.59l9.68,-2.24l4.29,1.47l5.04,-3.95l-3.18,-6.48L1137.46,553.08zM1080.29,492.35l-4.48,11.36h-1.92l-1.44,-4l-4.32,-0.64l-0.32,-6.88l1.92,-6.72l8,-2.72l2.86,2.52L1080.29,492.35z"
        />
        <path
          id="LV-078"
          title="Riebiņu novads"
          className="land"
          d="M1006.67,506.23L1004.76,505.22L1000.8,510.66L1000.48,516.25L997.13,520.62L1007.4,530.63L1014.09,544.54L1012.13,548.42L1008.64,546.09L1005.38,547.21L1006.62,555.5L1018.54,565.18L1013.56,574.1L1017.34,573.91L1019.58,577.24L1022.84,590.16L1035.06,590.31L1035.06,590.31L1033.66,595.02L1011.05,595.77L1004.09,601.29L1006.09,606.17L1004.65,609.13L1001.93,606.89L997,607.22L997,607.22L996.34,604.21L996.34,604.21L996.3,599.29L991.98,594.01L983.51,589.78L989.02,586.42L990.7,578.1L985.99,576.18L981.67,560.45L971.51,554.69L968.63,549.66L961.67,548.14L957.11,543.98L958.07,537.18L962.63,531.47L976.23,526.43L975.35,524.11L953.11,506.27L948.23,508.35L947.67,513.31L947.67,513.31L945.51,513.31L945.51,513.31L941.83,511.39L941.83,497.31L941.83,497.31L943.59,497.26L943.59,497.26L949.36,497.2L949.68,492.97L946.79,493.64L951.74,481.28L951.74,481.28L967.52,479.15L970.41,482.1L972.57,474.91L972.57,474.91L984.48,481.22L985.79,483.96L977.3,489.81L981.91,489.77L983.67,494.49L991.06,494.31L990.71,498.38L1007.38,500.3z"
        />
        <path
          id="LV-079"
          title="Rojas novads"
          className="land"
          d="M342.33,215.7L340.51,218.25L340.51,218.25L328.68,212.49L329.32,220.81L324.04,224.17L320.52,223.53L310.44,213.93L307.08,205.78L297.48,197.14L290.28,201.14L287.4,200.02L286.12,196.02L290.28,192.5L290.12,188.5L281.64,185.94L283.4,181.78L276.09,181.3L276.09,181.3L275.5,178.58L275.5,178.58L273.74,167.38L270.86,164.98L275.02,156.03L266.06,146.3L263.5,140.06L265.1,130.14L269.98,128.76L269.98,128.76L274.32,147.46L282.17,160.53L296.29,173.93L309.85,181.4L309.21,183.12L310.36,181.71L327.87,204.95z"
        />
        <path
          id="LV-080"
          title="Ropažu novads"
          className="land"
          d="M607.91,320.02L617.22,320.25L617.22,320.25L621.32,334.35L629.52,339.85L641.42,339.45L647.92,342.85L647.92,342.85L647.82,352.64L647.82,352.64L643.68,361.25L643.68,361.25L641.61,362.87L641.61,362.87L631.69,369.03L631.69,369.03L631.57,369.69L631.57,369.69L621.19,371.91L607.87,371.91L605.71,368.97L594.52,370.75L594.52,370.75L585.74,368.56L585.14,363.73L583.11,363.47L583.11,363.47L581.36,357.95L576.21,357.71L574.41,360.59L571.17,351.11L571.17,351.11L571.17,351.11L571.17,351.11L577.42,346.41L575.42,343.41L577.22,339.91L598.91,337.01L596.81,324.32L601.11,318.92L605.11,322.02z"
        />
        <path
          id="LV-081"
          title="Rucavas novads"
          className="land"
          d="M71.1,544.78L72.36,547.63L84.56,551.93L87.97,556.83L87.97,556.83L81.49,569.71L60.56,578.69L51.4,592.6L44.07,595.49L44.71,609.57L37.47,621.7L31.85,618.45L15.94,623.41L2.34,580.35L2.09,565.75L2.09,565.75L11.45,564.21L13.69,565.81L13.37,570.93L9.05,572.69L15.45,576.69L17.05,594.12L22.49,594.92L22.65,576.21L25.53,575.89L26.33,578.13L28.89,576.85L37.05,556.21L36.41,537.33L39.93,534.78L45.31,538.06L45.31,538.06L52.39,546.1z"
        />
        <path
          id="LV-082"
          title="Rugāju novads"
          className="land"
          d="M1013.56,378.5L1010.02,374.35L1008.34,364.63L998.33,359.09L1000.84,347.85L992.12,348.25L991.18,345.29L991.18,345.29L994.09,337.68L991.31,338.17L991.13,332.74L1004.34,325.8L1012.9,309.46L1012.9,309.46L1014.12,307.81L1014.12,307.81L1033.29,307.81L1032.09,315.33L1033.85,317.33L1036.17,318.37L1039.05,315.17L1041.45,315.65L1041.53,317.98L1053.92,324.14L1063.12,327.1L1070.16,325.66L1072.24,329.42L1067.92,333.98L1065.76,342.7L1056.48,346.06L1058.32,350.55L1064.56,353.91L1066.96,349.19L1068.4,350.4L1070.64,362.95L1067.2,368.15L1062.29,366.31L1058.93,370.15L1055.41,370.31L1044.37,385.35L1038.78,385.43L1035.58,381.35L1025.34,378.63z"
        />
        <path
          id="LV-083"
          title="Rundāles novads"
          className="land"
          d="M512.42,544.91L497.85,544.97L495.26,542.09L487.76,542.24L487.76,542.24L487.08,532.75L489.99,527.36L481.03,526.69L481.73,523.41L484.31,523.05L483.15,518.38L486.09,516.74L482.91,514.97L483.04,511.94L489.12,500.69L497.45,499.51L502.6,492.42L512.05,493.18L517.31,490.91L517.31,490.91L520.47,505.33L517.75,510.61L528.47,520.84L536.31,522.12L538.71,527.4L528.95,528.2L523.99,525.32L526.07,533.64L519.99,533.96L517.91,540.68L513.11,542.28z"
        />
        <path
          id="LV-084"
          title="Rūjienas novads"
          className="land"
          d="M753.82,29.72L749.26,32.3L747.9,40.14L741.26,38.06L739.02,40.94L733.34,30.62L723.5,49.66L727.9,60.87L729.26,57.11L733.02,58.55L741.1,67.67L744.46,68.47L751.1,82.87L751.26,88.79L756.14,86.39L756.06,95.89L756.06,95.89L754.26,95.89L754.26,95.89L728.27,97.59L724.47,90.79L721.19,89.61L721.19,89.61L721.39,74.11L714.49,69.41L714.39,63.82L709.79,55.72L702.39,52.52L698.89,46.52L701.99,40.6L694.09,27.1L698.19,12.91L694.87,6.51L694.87,6.51L696.11,2.73L710.06,3.24L709.47,1.02L711.98,0.1L714.02,1.97L712.84,6.51L714.81,11.56L716.49,10.7L715.97,20.06L719.52,20.79L716.76,21.55L721.82,28.87L726.2,29.95L728.94,28.65L729.56,16.79L722.62,6.99L727.7,1.02L739.68,17.49L747.25,17.87L754.35,22.69L756.62,25.77L753.74,26.81z"
        />
        <path
          id="LV-085"
          title="Salas novads"
          className="land"
          d="M771.05,470.16L785.43,467.17L800.6,458.42L805.84,458.54L805.84,458.54L806,458.54L806,458.54L805.79,465.49L802.19,472.29L805.79,482.29L818.49,485.35L818.49,485.35L816.89,496.95L816.89,496.95L817.19,498.95L817.19,498.95L814.29,509.74L821.49,518.24L821.19,520.94L816.79,522.24L817.09,528.94L812.99,530.84L811.59,535.44L801.81,535.76L801.81,535.76L800.31,531.06L792.42,531.06L788.92,523.27L783.62,523.46L783.62,518.47L781.02,518.47L779.82,512.69L781.42,505.09L778.76,502.58L778.76,502.58L778.7,498.44L770.55,489.35L773.46,481.65L779.54,479.09L780.41,470.92L771.5,472.81z"
        />
        <path
          id="LV-086"
          title="Salacgrīvas novads"
          className="land"
          d="M622.36,104.06L613.57,108.75L614.97,114.05L613.07,116.55L615.47,119.95L607.97,120.15L604.47,123.15L603.07,130.35L608.37,132.85L599.17,134.25L594.87,131.55L581.95,134.55L583.55,165.68L593.15,165.48L595.35,166.98L595.75,173.28L601.65,173.58L602.25,190.88L608.15,196.18L605.05,201.58L599.05,201.78L593.55,212.48L577.04,216.17L577.04,216.17L573.92,181.34L570.91,172.11L571.26,159.87L575.02,148.43L567.7,122.6L569.19,95.22L566.72,78.83L569.28,67.02L579.48,70.12L587.37,65.76L587.42,57.45L585.03,56.06L587.72,51.48L595.81,49.55L597.88,44.64L606.93,41.33L606.93,41.33L610.75,58.54L614.42,62.86L608.99,69.26L614.9,76.3z"
        />
        <path
          id="LV-087"
          title="Salaspils novads"
          className="land"
          d="M583.11,363.47L585.14,363.73L585.74,368.56L594.52,370.75L594.52,370.75L595.49,380.23L582.89,373.02L586.73,380.34L584.54,388.08L584.54,388.08L578.01,389.86L563.73,386.98L553.65,390.34L549.81,389.26L542.61,381.47L543.93,377.39L543.93,377.39L558.52,384.31L562.12,383.1L555.45,378.35L555.45,378.35L552.69,365.03L563.49,363.47L566.01,364.79L567.57,370.55L570.33,369.83L572.49,365.15z"
        />
        <path
          id="LV-088"
          title="Saldus novads"
          className="land"
          d="M256.43,384.15L253.72,384.72L248.74,410.84L252.4,420.26L256.76,418.76L256.76,425L259.4,427.4L258.08,445.48L262.52,451.12L267.86,450.88L268.16,464.25L271.64,465.75L273.14,461.61L277.82,458.97L279.38,452.62L286.94,454.65L297.57,472.84L297.57,472.84L298.64,480.15L295.04,486.63L295.64,493.71L291.32,496.47L300.25,507.26L293.84,508.82L294.32,515.9L305.95,517.01L321.58,525.6L324.54,523.2L328.41,523.93L328.41,523.93L330.67,527.08L324.83,531.39L314.75,534.06L295.96,532.45L288,538.48L271.29,526.72L263.92,527.02L245.24,520.68L232.39,525.45L226.18,521.17L214.38,523.05L208.27,516.4L201.27,517.71L194.68,513.94L181.39,518.56L176.25,516.43L170.63,524.81L170.63,524.81L167.34,523.17L164.83,516.92L174.5,514.97L175.18,508.26L165.75,501.24L164.76,495.77L167.94,490.99L167.94,490.99L176.47,489.87L180.7,493.97L184.31,486.21L175.7,481.16L175.5,475.83L195.42,471.93L192.96,464.03L194.02,459.79L191.21,456.04L193.94,453.99L193.53,448.95L188.74,435.23L194.61,436.98L201.31,432.11L204.24,422.23L206.74,421.43L203.06,414.48L201.91,402.29L201.91,402.29L208.95,403.33L211.91,394.8L215.88,391.98L224.76,391.21L226.8,386.3L230.43,386.82L232.92,382.74L229.29,379.6L228.99,375.51L218.49,374.44L219.35,369.92L222.36,369.7L220.96,364.78L223.88,360.48L231.68,363.12L233.09,370.78L240.21,375.7L245.41,374.07L249.11,364.66L249.11,364.66L253.99,361.8L263.59,363.48L263.35,368.23L259.26,369.64L260.03,372.01L260.97,370.56L260.2,378.4z"
        />
        <path
          id="LV-089"
          title="Saulkrastu novads"
          className="land"
          d="M577.74,231.53L581.73,237.35L582.27,248.4L585.13,248.58L585.13,248.58L591.13,254.58L591.03,264.58L583.53,267.88L574.14,281.51L574.14,281.51L572.95,281.4L572.95,281.4L568.95,281.16L568.95,281.16L567.17,279.67L567.17,279.67L576.03,266.93L578.75,258.52z"
        />
        <path
          id="LV-090"
          title="Sējas novads"
          className="land"
          d="M585.13,248.58L601.05,241.36L606.06,242.08L606.71,250.93L615.16,258.75L616.86,267.69L621.93,268.08L624.32,271.49L624.32,271.49L621.62,275.92L614.72,276.22L614.72,284.12L622.02,283.82L623.92,285.72L624.72,295.21L622.62,299.21L622.62,299.21L606.73,295.62L603.63,301.91L595.26,305L595.26,305L588.7,283.96L575.58,281.64L575.58,281.64L574.14,281.51L574.14,281.51L583.53,267.88L591.03,264.58L591.13,254.58z"
        />
        <path
          id="LV-091"
          title="Siguldas novads"
          className="land"
          d="M661.93,270.22L669.24,273.95L674.46,282.5L675.87,294.14L673.05,295.47L675.91,299.53L693.4,300.82L693.4,300.82L693.4,303.58L693.4,303.58L702.16,313.78L696.16,317.98L695.8,321.1L702.04,331.18L700.48,337.9L700.48,337.9L688.31,330.58L685.55,331.6L683.95,324.65L675.82,321.69L677.27,318.85L675.42,314.34L668.34,315.71L661.5,313.43L656.58,306.36L656.22,311.99L660.66,317.51L660.06,325.19L657.66,327.95L660.66,335.75L653.1,340.19L651.66,345.08L647.9,345.07L647.9,345.07L647.92,342.85L647.92,342.85L641.42,339.45L629.52,339.85L621.32,334.35L617.22,320.25L617.22,320.25L619.73,318.58L626.53,321.7L631.01,319.94L631.25,317.38L639.49,314.82L639.57,310.18L635.65,306.11L640.53,306.27L642.72,297.71L642.72,297.71L645.32,297.92L645.02,291.52L648.12,284.22L658.42,281.72L661.92,270.28L661.92,270.28z"
        />
        <path
          id="LV-092"
          title="Skrīveru novads"
          className="land"
          d="M700.49,455.15L693.36,453.59L687,459.41L687,459.41L685.24,459.63L685.24,459.63L685.26,451.94L680.56,449.64L682.16,434.35L689.65,431.05L684.46,422.75L685.66,420.65L695.05,419.95L695.05,419.95L705.29,429.18L710.41,430.46L710.41,430.46L710.41,431.58L710.41,431.58L705.45,434.38L706.73,438.14L710.57,439.82L710.01,446.7L701.45,450.38z"
        />
        <path
          id="LV-093"
          title="Skrundas novads"
          className="land"
          d="M139.32,471.1L139.62,465.74L135.59,458.23L141.57,456.19L146.13,451.32L144.59,435.84L144.59,435.84L146.31,431.24L151.75,430.12L154.71,426.36L159.66,424.84L165.26,427.16L167.9,424.92L163.26,404.89L166.3,398.01L171.9,393.21L174.46,392.25L179.18,395.13L181.18,392.33L189.1,393.05L192.3,399.29L197.26,398.09L201.91,402.29L201.91,402.29L203.06,414.48L206.74,421.43L204.24,422.23L201.31,432.11L194.61,436.98L188.74,435.23L193.53,448.95L193.94,453.99L191.21,456.04L194.02,459.79L192.96,464.03L195.42,471.93L175.5,475.83L175.7,481.16L184.31,486.21L180.7,493.97L176.47,489.87L167.94,490.99L167.94,490.99L167.82,487.31L159.02,490.29L157.56,484.42L147.6,485.64L140.41,481.68L142.37,478.91L137.97,476.89z"
        />
        <path
          id="LV-094"
          title="Smiltenes novads"
          className="land"
          d="M835.04,174.75L845.73,167.71L864.45,166.51L872.01,171.67L878.85,169.15L880.64,171.31L885.68,170.95L886.76,172.87L882.92,175.51L882.44,178.75L884.36,180.55L889.4,181.15L891.56,178.03L896.96,177.31L898.76,183.19L898.76,183.19L900.43,197.18L899.34,199.71L895.35,199.46L894.16,204.2L901.39,208.57L898.67,210.5L898.52,216.33L895.23,218.54L895.72,222.53L898.62,223.93L892.85,226.89L892.96,237.6L892.96,237.6L894.78,243.39L892.8,245.75L889.03,245.47L890.91,253.05L872.92,253.24L867.05,256.03L860.67,252.4L854.87,257.43L854.87,257.43L854.33,253.14L851.34,253.36L848.27,249.88L837.62,252.43L828.38,250.81L818.39,255.69L818.86,253.24L813.48,252L806.27,253.18L804.91,256.13L804.91,256.13L804.91,256.13L804.91,256.13L800.42,255.38L800.42,255.38L798.9,253.62L802.77,242.92L800.57,240.03L806.61,238.04L797.86,231.31L798.1,225.43L794.33,218.51L797.78,215.71L790.07,202.8L790.07,202.8L788.84,196.37L788.84,196.37L795.13,188.78L799.29,190.29L802.09,186.14L809.69,187.02L809.69,187.02L825.36,188.51L826.96,186.51L830,188.35L828.08,193.95L831.52,194.67L835.28,191.63L836.72,186.59z"
        />
        <path
          id="LV-095"
          title="Stopiņu novads"
          className="land"
          d="M571.17,351.11L574.41,360.59L576.21,357.71L581.36,357.95L583.11,363.47L583.11,363.47L572.49,365.15L570.33,369.83L567.57,370.55L566.01,364.79L563.49,363.47L552.69,365.03L555.45,378.35L555.45,378.35L549.21,370.55L549.21,359.39L563.01,349.43L563.01,349.43L565.77,348.35L565.77,348.35z"
        />
        <path
          id="LV-096"
          title="Strenču novads"
          className="land"
          d="M809.69,187.02L816.41,181.9L815.69,176.14L810.65,181.34L805.69,179.02L802.33,172.06L810.89,168.94L807.93,165.1L799.69,164.3L801.53,157.9L794.73,149.82L776.5,151.48L776.5,151.48L776.4,150.94L776.4,150.94L764.65,146.46L762.89,140.46L766.46,138.93L766.83,136.15L781.16,134.88L779.96,126.68L785.06,123.58L783.86,119.38L792.85,119.38L795.25,113.69L795.25,113.69L801.93,114.81L818.25,108.73L816.25,121.21L822.01,126.25L842.88,131.94L837.36,148.1L830.08,153.54L821.53,152.74L824.4,156.82L832.4,158.26L831.92,163.79L825.69,170.91L828.24,177.23L835.04,174.75L835.04,174.75L836.72,186.59L835.28,191.63L831.52,194.67L828.08,193.95L830,188.35L826.96,186.51L825.36,188.51z"
        />
        <path
          id="LV-097"
          title="Talsu novads"
          className="land"
          d="M356.13,266.9L356.88,269.47L354.76,268.33L351.58,271.33L348.96,277.54L340.01,279.86L337.04,284.98L332.37,284.94L330.03,299.31L330.03,299.31L319.29,295.94L311.08,298.29L308.94,296.62L302.79,305.5L280.46,310.1L279.99,318.66L282.95,329.63L281.01,335.36L273.07,331.85L268.06,332.65L267.55,335.06L269.98,335.7L266.56,338.39L258.2,334.69L258.2,334.69L256.72,332.93L253.39,336.35L247.83,334.19L238.09,336.2L236.74,329.04L233.77,329.19L233.4,326.82L233.31,323.11L235.84,322.06L233.56,304.97L219.04,299.93L214.05,294.67L215.68,286.21L211.47,285.75L208.63,281.94L208.63,281.94L203.71,278.75L206.96,278.9L208.38,269.38L211.89,271.74L213.17,263.42L216.12,264.04L218.45,257.27L225.42,256.69L223.3,241.05L219.67,236.39L221.9,231.75L220.02,221.85L222.05,220.94L216.65,220.41L214.85,213.96L214.85,213.96L212.79,210.89L214.07,208.33L218.39,206.1L220.15,208.5L222.71,204.18L229.75,207.85L231.19,215.21L241.75,209.29L249.75,211.37L254.23,196.5L259.02,193.3L261.42,183.06L263.34,182.1L267.82,185.94L276.3,182.26L276.3,182.26L276.09,181.3L276.09,181.3L283.4,181.78L281.64,185.94L290.12,188.5L290.28,192.5L286.12,196.02L287.4,200.02L290.28,201.14L297.48,197.14L307.08,205.78L310.44,213.93L320.52,223.53L324.04,224.17L329.32,220.81L328.68,212.49L340.51,218.25L340.51,218.25L338.43,222.09L341.31,225.93L334.11,228.97L330.27,227.53L324.52,232.17L327.88,241.13L333.47,242.73L335.39,248.49L347.71,245.77L351.23,254.25L347.87,260.65z"
        />
        <path
          id="LV-098"
          title="Tērvetes novads"
          className="land"
          d="M411.05,495.4L416.14,498.93L411.82,505.95L416.31,507.02L414.46,509.72L415.57,515.1L421.09,522.17L415.32,528.54L407.21,530.63L407.21,530.63L394.2,528.9L388.95,532.51L372.31,532.97L372.31,532.97L368.06,524.77L362.06,519.85L368.06,511.45L368.06,511.45L367.22,510.04L367.22,510.04L370.9,505.71L380.61,502.95L390.69,506.67L392.97,505.35L395.01,501.15L390.33,499.59L389.37,490.59L400.77,482.08L402.21,476.92L407.85,482.08z"
        />
        <path
          id="LV-099"
          title="Tukuma novads"
          className="land"
          d="M421.21,358.89L423.8,361.09L423.8,361.09L425.93,367.89L425.93,367.89L426.06,373.09L426.06,373.09L420.55,375.51L424.09,396.7L424.09,396.7L417.84,401.89L406.97,403.51L405.43,399.1L403.76,399.89L398.01,407.65L393.92,409.03L400.09,416.11L388.65,428.53L375.24,424.28L372.26,428.41L374.47,431.66L372.74,433.62L361.87,430.23L361.87,430.23L361.87,429.03L361.87,429.03L360.87,424.83L357.57,423.83L355.07,419.13L349.27,397.93L335.57,402.23L328.38,399.83L326.88,395.23L322.68,393.14L331.97,385.54L330.6,384.24L330.6,384.24L324.9,374.94L327.3,372.94L326.3,363.34L318,363.34L311.9,356.24L309.7,347.94L311.2,338.75L318.1,337.05L319.5,332.45L319.2,329.95L313.7,326.75L315.3,319.85L313.6,316.25L322,312.95L331.5,303.75L330.03,299.31L330.03,299.31L332.37,284.94L337.04,284.98L340.01,279.86L348.96,277.54L351.58,271.33L354.76,268.33L356.88,269.47L356.13,266.9L356.13,266.9L356.7,263.42L361.58,262.27L361.58,262.27L362.5,273.94L364.89,276.42L361.3,279.14L360.1,287.7L353.06,292.5L363.46,300.58L366.97,299.06L374.57,304.42L381.05,320.57L377.61,323.36L377.37,328.32L369.61,331.76L372.09,335.99L366.65,343.35L370.01,346.55L374.09,346.71L374.65,350.23L367.45,353.19L362.98,360.95L367.85,361.83L376.25,359.11L382.81,362.95L389.45,361.83L403.77,364.63z"
        />
        <path
          id="LV-100"
          title="Vaiņodes novads"
          className="land"
          d="M131.92,543.69L128,533.59L134.48,525.31L128.6,525.31L124.52,519.07L117.44,514.99L123.92,511.15L120.68,502.4L130.4,497.72L129.68,493.64L125.48,491.24L125.63,475.62L125.63,475.62L139.32,471.1L139.32,471.1L137.97,476.89L142.37,478.91L140.41,481.68L147.6,485.64L157.56,484.42L159.02,490.29L167.82,487.31L167.94,490.99L167.94,490.99L164.76,495.77L165.75,501.24L175.18,508.26L174.5,514.97L164.83,516.92L167.34,523.17L170.63,524.81L170.63,524.81L168.77,530.88L166.13,529.6L151.65,536.06L145.98,532.57z"
        />
        <path
          id="LV-101"
          title="Valkas novads"
          className="land"
          d="M811.17,57.45L816,70.37L823.14,72.36L825.83,69.65L827.74,75.33L835.25,73.88L840.33,76.18L846.83,71.57L850.54,75.71L856.08,75.39L850.11,101.11L859.93,101.49L869.49,106.46L871.02,112.1L879.37,115.84L880.55,117.79L877.26,125.93L882.53,128.44L883.05,132.87L887.24,135.07L888.18,147.4L892.17,151.57L908.06,159.37L908.06,159.37L902.86,168.54L902.67,173.83L905.48,175.37L905.06,179.68L909.25,180.87L898.76,183.19L898.76,183.19L896.96,177.31L891.56,178.03L889.4,181.15L884.36,180.55L882.44,178.75L882.92,175.51L886.76,172.87L885.68,170.95L880.64,171.31L878.85,169.15L872.01,171.67L864.45,166.51L845.73,167.71L835.04,174.75L835.04,174.75L828.24,177.23L825.69,170.91L831.92,163.79L832.4,158.26L824.4,156.82L821.53,152.74L830.08,153.54L837.36,148.1L842.88,131.94L822.01,126.25L816.25,121.21L818.25,108.73L801.93,114.81L795.25,113.69L795.25,113.69L777.06,100.09L767.46,96.99L767.46,96.99L771.26,87.99L758.36,75.29L759.7,70.4L763.23,72.38L767.1,67.47L773.97,66.68L784.11,74.72L790.53,74.36L791.79,67.77L794.91,65.31L795.02,57.84L795.02,57.84L802.44,51.82z"
        />
        <path
          id="LV-102"
          title="Varakļānu novads"
          className="land"
          d="M910.41,449.02L910.74,440.55L932.94,442.47L943.74,436.59L947.22,439.71L949.74,433.11L953.82,429.99L965.09,429.87L965.09,435.03L976,438.75L976,438.75L976.86,439.48L976.86,439.48L978.94,448.65L976.02,454.54L980.77,461.6L978.38,464.6L969.56,465.31L968.34,469.82L972.57,474.91L972.57,474.91L970.41,482.1L967.52,479.15L951.74,481.28L951.74,481.28L949.21,476.35L944.47,479.73L946.79,463.85L928.38,463.79z"
        />
        <path
          id="LV-103"
          title="Vārkavas novads"
          className="land"
          d="M903.4,589.43L896.76,575.99L900.04,575.43L895.88,560.52L897,555.33L903.56,560.37L908.36,560.37L908.84,549.33L912.52,547.33L920.04,561.48L930.92,560.37L943.95,564.84L945.55,560.6L949.75,558.68L949.75,558.68L959.35,560.2L959.75,569.96L963.83,569.96L965.51,574.51L965.51,578.59L960.79,578.59L960.63,593.31L947.67,594.91L949.43,599.55L956.55,605.05L956.55,605.05L953.67,607.45L945.17,603.6L942.6,595.89L939.75,598.17L939.19,606.89L933.44,607.45L930.89,594.38L923.97,587.11L917.85,591.97L911.69,585z"
        />
        <path
          id="LV-104"
          title="Vecpiebalgas novads"
          className="land"
          d="M758.76,342.18L758.46,338.76L761.73,335.08L758.78,329.33L762.53,328.85L766.61,332.76L781.41,319.57L781.49,311.17L775.88,302.23L766.64,300.43L772.77,292.41L772.77,292.41L773.07,284.31L775.77,282.01L774.07,271.61L771.77,270.13L771.77,270.13L772.27,265.64L779.57,261.94L779.94,256.18L779.94,256.18L795.7,254.1L798.5,259.3L802.13,255.67L802.13,255.67L804.91,256.13L804.91,256.13L804.91,256.13L804.91,256.13L803.14,273.35L806.38,275.99L806.38,275.99L807.53,275.85L807.53,275.85L805.78,285.11L809.26,289.31L807.34,294.71L808.78,297.1L819.34,299.86L827.01,310.3L832.78,310.29L832.78,310.29L826.67,336.5L833.89,339.31L833.77,348.28L827.86,351.88L827.86,351.88L827.73,352.07L827.73,352.07L820.41,352.81L820.01,349.45L814.15,343.35L809.66,347.26L807.03,346.55L803.58,340.58L798.75,340.64L796.85,344.8L785.39,352.84L783.24,348.71L785.68,346.65L785.71,340.14L774.54,347.63L762.17,339.47L760.39,341.78L760.39,341.78z"
        />
        <path
          id="LV-105"
          title="Vecumnieku novads"
          className="land"
          d="M598.98,426.73L599.62,429.21L616.46,428.2L617.39,439.6L608.55,440.03L608.25,446.11L613.89,461.68L622.52,461.71L621.66,466.1L624.84,464.76L627.19,466.87L627.33,471.38L621.49,471.9L623.61,478.44L628.12,477.24L633.48,479.16L651.4,469.88L655.8,471.16L661.24,468.6L664.02,470.25L673.11,468.39L673.11,468.39L674.51,474.29L681.91,479.19L682.61,490.89L682.61,490.89L667.11,494.18L660.11,504.38L659.52,508.99L659.52,508.99L654.69,513.27L655.13,519.47L649.25,520.86L645.57,517.52L641.03,524.2L629.04,523.66L629.57,527.63L626.55,531.39L616.27,531.12L615.79,539.39L607.17,547.45L607.17,547.45L599.84,544.87L596.8,547.75L589.28,541.75L597.28,533.27L587.68,529.35L587.13,526.71L600.24,518.88L602.32,510.78L597.84,509.34L596.96,506.7L600.96,501.66L596.48,499.66L592.56,494.06L586.65,497.02L582.49,486.31L577.37,485.83L576.94,481.08L576.94,481.08L576.94,473.88L567.18,473.08L565.42,470.68L564.62,455L574.38,455L575.02,452.44L572.02,443.52L572.02,443.52L574.14,442.51L571.2,441.68L573.5,439.48L583.4,437.16L593.32,426.94z"
        />
        <path
          id="LV-106"
          title="Ventspils novads"
          className="land"
          d="M92.7,221.7L102.41,231.76L104.21,225.16L106.71,229.16L109.9,227.16L108.5,218.86L118.1,214.77L115.2,197.07L110.75,192.89L116.55,182.31L120.77,166.64L125.74,161.35L146.23,154.14L178.27,150.38L206.55,135.73L210.93,142.19L207.48,146.68L211.87,143.86L217,151.7L214.93,151.85L214.55,158.09L218.92,167.55L220.84,182.9L215.74,183.22L205.61,192.9L201,191.69L202.21,196.31L205.02,197.37L202.87,200.8L186.55,208.88L196.47,215.62L204.53,217.21L214.85,213.97L216.65,220.42L222.05,220.95L220.01,221.85L221.9,231.75L219.66,236.39L223.3,241.06L225.42,256.69L218.45,257.28L216.11,264.05L213.17,263.43L211.89,271.74L208.37,269.38L206.96,278.9L203.71,278.75L208.61,281.66L205.95,301.85L203.69,304.17L193.61,305.62L193.97,308.59L184.68,308.81L175.66,304.17L173.49,307.85L166.81,308.22L164.3,310.85L159,309.18L155.52,312.33L153.33,309.3L147.7,307.79L143.57,315.27L140.17,312.89L139.05,315.18L137.1,314.13L136.71,311.38L139.25,310.23L137.92,306.61L131.68,307.08L123.82,301.57L121.77,306.09L114.06,302.87L105.5,313.32L101.64,312.12L97.32,316.51L90.4,315.52L90.33,320.6L86.62,322.31L87.98,331.16L83.48,331.15L85.08,348.48L81.52,351.76L82.23,356.26L72.67,356.27L71.71,351.38L66.95,349.08L64.44,350.96L60.4,348.04L67.67,339.59L74.79,319.69L73.47,285.57L75.21,251.41z"
        />
        <path
          id="LV-107"
          title="Viesītes novads"
          className="land"
          d="M783.78,603.9L774.07,602.46L772.12,596.22L769.06,594.74L764.97,596.49L763.12,601.37L751.81,598.18L749.4,600.92L746.68,600.08L746.73,597.21L739.34,598.42L739.34,598.42L739.69,596.1L747.99,595.83L748.98,592.33L745.48,589.07L743.55,580.26L745.84,572.16L742.12,566.45L738.47,566.36L734.81,559.4L729.68,556.98L732.32,550.02L741.06,546.66L738.43,542.63L735.54,544.94L727.49,544.39L729.72,541.42L728.77,520.8L728.77,520.8L729.65,517.43L743.66,521.26L757.13,520.2L765.49,508.51L771.5,510.42L778.76,502.58L778.76,502.58L781.42,505.09L779.82,512.69L781.02,518.47L783.62,518.47L783.62,523.46L788.92,523.27L792.42,531.06L800.31,531.06L801.81,535.76L801.81,535.76L800.41,553.38L793.22,553.38L790.52,556.88L793.32,567.78L793.32,567.78L793.32,576.77L793.32,576.77L785.22,594.07z"
        />
        <path
          id="LV-108"
          title="Viļakas novads"
          className="land"
          d="M1135.44,336.78L1128.86,339.93L1122.98,338.37L1122.98,338.37L1121.31,333.45L1115.43,331.53L1109.31,333.69L1107.51,325.89L1110.03,321.57L1108.95,317.61L1104.27,320.49L1084.71,299.26L1088.91,276.8L1096.83,266.36L1106.91,261.68L1107.1,258.43L1107.1,258.43L1107.56,258.49L1107.56,258.49L1109.65,257.83L1109.89,254.14L1113.67,253.42L1114.23,245.47L1122.29,244.13L1131.51,237.38L1131.27,225.34L1131.27,225.34L1145.98,233.34L1147.02,240.46L1154.49,239.9L1160.95,249.48L1159.3,258.7L1161.17,262.77L1154.46,277.38L1155.08,288.07L1152.44,295.1L1138.39,303.39L1141.48,306.89L1141.06,309.83L1139.4,307.85L1134.91,308.4L1135.63,311.22L1144.06,313.78L1140.21,318.23L1140.86,323.39z"
        />
        <path
          id="LV-109"
          title="Viļānu novads"
          className="land"
          d="M1006.67,506.23L1007.38,500.3L990.71,498.38L991.06,494.31L983.67,494.49L981.91,489.77L977.3,489.81L985.79,483.96L984.48,481.22L972.57,474.91L972.57,474.91L968.34,469.82L969.56,465.31L978.38,464.6L980.77,461.6L976.02,454.54L978.94,448.65L976.86,439.48L976.86,439.48L986.35,439.05L989.05,443.55L996.05,442.85L1003.25,459.87L1008.34,464.07L1016.44,465.06L1020.14,469.66L1018.94,473.26L1021.14,476.86L1018.44,481.86L1027.54,503.56L1023.34,508.96L1016.64,507.16L1010.04,510.26z"
        />
        <path
          id="LV-110"
          title="Zilupes novads"
          className="land"
          d="M1198.56,583.2L1205.46,569.3L1193.16,555.61L1185.66,551.21L1180.06,556.91L1173.16,558.01L1174.26,564.61L1163.47,565.91L1159.97,552.71L1177.26,537.75L1186.36,536.15L1187.66,533.65L1183.36,522.25L1184.66,520.35L1192.26,520.35L1198.16,526.85L1205.26,522.95L1213.48,524.34L1213.48,524.34L1211.04,530.57L1215.44,535.54L1223.92,561.55L1214.54,575.3L1217.74,579.35L1212.96,582.19L1213,589.76L1202.75,595.58L1198.9,603.84L1194.05,604.05L1194.05,604.05L1191.43,600.31L1200.63,586.95L1198.27,583.77L1198.27,583.77z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Latvia))
