import actions from '../actions/action-types'
import { getRandomNotAlreadySeenItemFromList, getMapFillColors } from '../../lib/methodsUtil'
import { easyFlagsList, allFlagsList } from '../../pages/geography/flags/util'
import {
  easyCapitalsList,
  allCapitalsList,
  allCountriesList
} from '../../pages/geography/capitals/util'
import { easyLocationsList, allLocationsList } from '../../pages/geography/locations/util'
import { top30Countries } from '../../../src/lib/geographyUtil'

const initialState = {
  actual: '',
  seenList: [],
  multipleChoicesToChooseFrom: [],
  actualMultipleChoices: [],
  mapFillColorOne: null,
  mapFillColorTwo: null
}

const pageCountriesList = {
  learn_flags: allFlagsList,
  easy_flags: easyFlagsList,
  medium_flags: allFlagsList,
  hard_flags: allFlagsList,
  learn_capitals: allCapitalsList,
  easy_capitals: easyCapitalsList,
  medium_capitals: allCapitalsList,
  hard_capitals: allCapitalsList,
  easy_reverse_capitals: top30Countries,
  medium_reverse_capitals: allCountriesList,
  hard_reverse_capitals: allCountriesList,
  learn_locations: allLocationsList,
  easy_locations: easyLocationsList,
  medium_locations: allLocationsList,
  hard_locations: allLocationsList
}

const geographyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.randomizeACountry: {
      let seenList = state.seenList
      const pageName = action.payload
      const answersList = pageCountriesList[action.payload]

      const haveAllItemsBeenSeen = seenList.length === answersList.length
      if (haveAllItemsBeenSeen) {
        seenList = []
      }

      const actual = getRandomNotAlreadySeenItemFromList(answersList, seenList)
      seenList.push(actual)

      const multipleChoicesToChooseFrom = answersList.filter(choice => choice !== actual)
      const actualMultipleChoices = multipleChoicesToChooseFrom
        .sort(() => 0.5 - Math.random())
        .slice(0, 4)

      const { mapFillColorOne, mapFillColorTwo } = getMapFillColors(pageName)

      return {
        ...state,
        actual,
        seenList,
        multipleChoicesToChooseFrom,
        actualMultipleChoices,
        mapFillColorOne,
        mapFillColorTwo
      }
    }

    case actions.resetGeography: {
      // seenList has to be reset fully because seenList.push that is used earlier modify the original array.
      return { ...initialState, seenList: [] }
    }

    default:
      return state
  }
}

export default geographyReducer
