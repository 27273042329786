import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1055

const cities = {
  Beirut: { x: 210, y: 511 }
}

const Lebanon = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map lebanon">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="LB-JA"
          title="South Lebanon"
          className="land"
          d="M273.41,755.63L264.27,694.12L200.72,716.14L147.77,704.79L91.43,788.9L40.24,971.57L0.38,1015.27L100.9,1016.58L175.98,880.26L135.43,877.84L105.02,845.77L117.7,829.04L142.76,838.77L165.44,779.76L225.62,761.03L235.91,858.19L284.72,804.3L273.41,755.63z"
        />
        <path
          id="LB-NA"
          title="An Nabatiyah"
          className="land"
          d="M130.82,1044.23L202.53,1024.07L239.37,901.16L268.25,927.16L396.42,813.57L327.9,774.11L284.72,804.3L235.91,858.19L225.62,761.03L165.44,779.76L142.76,838.77L117.7,829.04L105.02,845.77L135.43,877.84L175.98,880.26L100.9,1016.58L130.82,1044.23z"
        />
        <path
          id="LB-JL"
          title="Mount Lebanon"
          className="land"
          d="M484.71,344.74L290.09,302.72L287.92,442.75L238.63,502.45L204.05,528.71L147.77,704.79L200.72,716.14L264.27,694.12L273.41,755.63L341.11,612.09L376.47,582.57L356.59,565.23L453.8,446.78L484.71,344.74z"
        />
        <path
          id="LB-BA"
          title="Beirut"
          className="land"
          d="M238.63,502.45L200.24,500.03L204.05,528.71L238.63,502.45z"
        />
        <path
          id="LB-AS"
          title="North Lebanon"
          className="land"
          d="M642.81,0L617.66,38.95L462.71,24.35L469.75,96.12L427.34,135.23L375.33,143.64L375.72,175.8L300.85,242.5L290.09,302.72L484.71,344.74L610.99,145.8L653.93,121.93L712.54,37.58L673.94,37.68L642.81,0z"
        />
        <path
          id="LB-BI"
          title="Beqaa"
          className="land"
          d="M396.42,813.57L435.93,785.81L443.23,744.1L497.42,718.62L437.03,672.38L475.51,595.5L552.16,538.01L668.61,555.18L674,533.58L621.56,502.08L631.99,476.64L686.46,413.71L731.15,408.08L799.62,314.64L787.87,249.86L754.21,215.29L757.67,170.68L708.97,124.69L653.93,121.93L610.99,145.8L484.71,344.74L453.8,446.78L356.59,565.23L376.47,582.57L341.11,612.09L273.41,755.63L284.72,804.3L327.9,774.11L396.42,813.57z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Lebanon))
