import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 891

const cities = {
  Nouakchott: { x: 75, y: 648 }
}

const Mauritania = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map mauritania">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="MR-09"
          title="Tagant"
          className="land"
          d="M516.87,707.24L592.77,538.97L393.76,539.94L368.44,560.91L366.6,575.15L351.76,567.07L287.02,619.74L299.46,639.21L295.14,692.9L310.46,707.71L336.61,720.65L358.78,718.07L368.92,683.23L358.08,659.06L397.95,636.68L390.84,677.07L439.62,691.09L479.45,688.48L516.87,707.24z"
        />
        <path
          id="MR-02"
          title="Hodh el Gharbi"
          className="land"
          d="M516.87,707.24L479.45,688.48L439.62,691.09L390.84,677.07L396.66,739.07L415.64,754.17L404.37,763.03L423.15,833.45L498.97,832.61L503.37,815.16L503.85,828.88L526.44,828.88L522.25,790.43L573.07,724.96L552.89,702.57L516.87,707.24z"
        />
        <path
          id="MR-01"
          title="Hodh ech Chargui"
          className="land"
          d="M526.44,828.88L754.53,828.97L764.82,773.44L747.18,758.74L723.1,528.89L698.31,300.48L592.77,538.97L516.87,707.24L552.89,702.57L573.07,724.96L522.25,790.43L526.44,828.88z"
        />
        <path
          id="MR-07"
          title="Adrar"
          className="land"
          d="M698.31,300.48L689.52,218.31L409.89,413.73L306.03,425.54L263.31,409.38L264.41,426.82L180.79,426.83L188.79,440.06L185.62,589.15L249.46,589.15L287.02,619.74L351.76,567.07L366.6,575.15L368.44,560.91L393.76,539.94L592.77,538.97L698.31,300.48z"
        />
        <path
          id="MR-04"
          title="Gorgol"
          className="land"
          d="M330.4,798.77L314,778.55L302.78,780.68L302.68,764.53L280.94,763.44L276.97,736.21L253.55,766.49L218.5,783.82L233.74,784.12L249.88,820.21L267.74,827.96L281.96,853.56L300.48,817.83L330.4,798.77z"
        />
        <path
          id="MR-10"
          title="Guidimaka"
          className="land"
          d="M326.12,880.38L342.45,869.19L350.2,826.04L363.36,796.73L342.96,790.88L341.55,800.83L330.4,798.77L300.48,817.83L281.96,853.56L326.12,880.38z"
        />
        <path
          id="MR-03"
          title="Assaba"
          className="land"
          d="M390.84,677.07L397.95,636.68L358.08,659.06L368.92,683.23L358.78,718.07L336.61,720.65L310.46,707.71L283.18,723.32L276.97,736.21L280.94,763.44L302.68,764.53L302.78,780.68L314,778.55L330.4,798.77L341.55,800.83L342.96,790.88L363.36,796.73L350.2,826.04L367.63,820.22L401.57,855.47L413.25,833.82L423.15,833.45L404.37,763.03L415.64,754.17L396.66,739.07L390.84,677.07z"
        />
        <path
          id="MR-06"
          title="Trarza"
          className="land"
          d="M287.02,619.74L249.46,589.15L185.62,589.15L55.74,589.47L65.55,631.18L83.78,631.03L83.75,665.84L67.38,665.85L39.49,750.83L34.06,807.63L51.6,759.06L90.89,761.91L138.12,749.01L179.19,720.52L217.5,671.78L287.02,619.74z"
        />
        <path
          id="MR-13"
          title="Nouakchott"
          className="land"
          d="M67.38,665.85L83.75,665.84L83.78,631.03L65.55,631.18L67.38,665.85z"
        />
        <path
          id="MR-12"
          title="Inchiri"
          className="land"
          d="M188.79,440.06L79.94,439.61L97.01,468.52L48.2,557.71L34.51,562.85L55.74,589.47L185.62,589.15L188.79,440.06z"
        />
        <path
          id="MR-08"
          title="Dakhlet Nouadhibou"
          className="land"
          d="M180.79,426.83L6.24,427.44L0.45,466.46L9.55,439.03L25.55,472.7L34.06,480.85L34.91,468.42L42.79,472.88L56.27,503.67L54.21,534.23L39.1,560.65L50.37,551.37L48.2,557.71L97.01,468.52L79.94,439.61L188.79,440.06L180.79,426.83z"
        />
        <path
          id="MR-05"
          title="Brakna"
          className="land"
          d="M310.46,707.71L295.14,692.9L299.46,639.21L287.02,619.74L217.5,671.78L179.19,720.52L138.12,749.01L177.68,751.32L210.23,786.08L218.5,783.82L253.55,766.49L276.97,736.21L283.18,723.32L310.46,707.71z"
        />
        <path
          id="MR-11"
          title="Tiris Zemmour"
          className="land"
          d="M547.4,0L547.05,93.93L329.72,94.3L329.46,276.52L290.27,290.05L257.58,317.54L263.31,409.38L306.03,425.54L409.89,413.73L689.52,218.31L683.85,166.71L799.55,166.64L547.4,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Mauritania))
