import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 854

const cities = {
  'Guatemala City': { x: 342, y: 665 }
}

const Guatemala = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map guatemala">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="GT-QC"
          title="Quiché"
          className="land"
          d="M346.36,528.88L351.56,514.73L306.88,501.68L327.86,474.58L316.49,448.1L283.96,428.26L300.95,404.03L294.8,383.05L320.45,367.46L353.2,374.59L357.4,360.43L248.89,362.37L206.17,447.7L187.18,457.9L182.37,479.41L211.26,503.24L212.31,522.7L185.26,528.69L211.54,601.16L230.5,618.49L252.39,595.41L315.83,602.54L273.29,552.28L274.53,529.98L332.35,539.63L346.36,528.88z"
        />
        <path
          id="GT-ZA"
          title="Zacapa"
          className="land"
          d="M615.71,568.15L603.21,521.59L524.68,545.81L470.59,549.94L493.1,596.36L479.32,608.17L498.7,628.02L511.79,603.13L607.31,600.66L615.71,568.15z"
        />
        <path
          id="GT-JU"
          title="Jutiapa"
          className="land"
          d="M427.91,843.27L424.07,822.96L441.81,800.74L478.95,775.75L496.46,780.14L507.94,753.05L540.76,740.4L527.64,708.81L546.38,701.24L543.01,680.57L508.8,669.52L469.14,698.46L436.07,699.07L408.44,736.07L434.79,753.1L434.52,773.6L415.26,792.59L388.46,785.85L398.61,831.05L427.91,843.27z"
        />
        <path
          id="GT-JA"
          title="Jalapa"
          className="land"
          d="M479.32,608.17L404.86,649.68L389.75,682.5L420.96,681.53L436.07,699.07L469.14,698.46L508.8,669.52L512.47,632.12L498.7,628.02L479.32,608.17z"
        />
        <path
          id="GT-CQ"
          title="Chiquimula"
          className="land"
          d="M607.31,600.66L511.79,603.13L498.7,628.02L512.47,632.12L508.8,669.52L543.01,680.57L546.38,701.24L573.09,702.76L596.1,670.47L613.03,668.77L616.19,642.71L598.73,613.92L607.31,600.66z"
        />
        <path
          id="GT-IZ"
          title="Izabal"
          className="land"
          d="M615.71,568.15L650.15,553.94L799.53,433.42L723.19,383.07L746.34,408.43L732.68,407.16L722.44,438.27L687.09,405.89L598.02,396.1L566.2,405.43L513.59,467.38L528.12,483.79L516.39,519.93L524.68,545.81L603.21,521.59L615.71,568.15z"
        />
        <path
          id="GT-SR"
          title="Santa Rosa"
          className="land"
          d="M436.07,699.07L420.96,681.53L389.75,682.5L369.29,686.15L358.26,720.57L334.22,735.39L329.8,744.36L343.99,746.39L321.88,776.41L321.84,804.07L398.61,831.05L388.46,785.85L415.26,792.59L434.52,773.6L434.79,753.1L408.44,736.07L436.07,699.07z"
        />
        <path
          id="GT-PR"
          title="El Progreso"
          className="land"
          d="M479.32,608.17L493.1,596.36L470.59,549.94L451.73,550.62L386.14,605.49L366.98,603.54L379.1,633.95L404.86,649.68L479.32,608.17z"
        />
        <path
          id="GT-TO"
          title="Totonicapán"
          className="land"
          d="M185.26,528.69L144.87,550.73L130.98,564.01L162.84,620.26L211.54,601.16L185.26,528.69z"
        />
        <path
          id="GT-SO"
          title="Sololá"
          className="land"
          d="M230.5,618.49L211.54,601.16L162.84,620.26L154.62,636.29L156.17,661.6L174.39,653.01L225.2,679.2L230.5,618.49z"
        />
        <path
          id="GT-SA"
          title="Sacatepéquez"
          className="land"
          d="M317.24,691.85L323.66,655.63L294.46,628.42L269.59,687.63L283.55,704.93L317.24,691.85z"
        />
        <path
          id="GT-SU"
          title="Suchitepéquez"
          className="land"
          d="M225.2,679.2L174.39,653.01L156.17,661.6L154.62,636.29L146.52,641.75L127.1,677.82L117.14,763.83L142.97,778.2L165.63,715.09L213.9,732.15L225.76,699.7L225.2,679.2z"
        />
        <path
          id="GT-GU"
          title="Guatemala"
          className="land"
          d="M366.98,603.54L315.83,602.54L294.46,628.42L323.66,655.63L317.24,691.85L334.22,735.39L358.26,720.57L369.29,686.15L389.75,682.5L404.86,649.68L379.1,633.95L366.98,603.54z"
        />
        <path
          id="GT-ES"
          title="Escuintla"
          className="land"
          d="M317.24,691.85L283.55,704.93L269.59,687.63L252.13,709.45L225.76,699.7L213.9,732.15L165.63,715.09L142.97,778.2L214.96,802.83L321.84,804.07L321.88,776.41L343.99,746.39L329.8,744.36L334.22,735.39L317.24,691.85z"
        />
        <path
          id="GT-CM"
          title="Chimaltenango"
          className="land"
          d="M315.83,602.54L252.39,595.41L230.5,618.49L225.2,679.2L225.76,699.7L252.13,709.45L269.59,687.63L294.46,628.42L315.83,602.54z"
        />
        <path
          id="GT-AV"
          title="Alta Verapaz"
          className="land"
          d="M566.2,405.43L475.76,395.08L452.17,373.95L357.47,360.34L357.4,360.43L353.2,374.59L320.45,367.46L294.8,383.05L300.95,404.03L283.96,428.26L316.49,448.1L327.86,474.58L306.88,501.68L351.56,514.73L346.36,528.88L384.78,540.23L396.98,521.99L456.54,523.61L451.73,550.62L470.59,549.94L524.68,545.81L516.39,519.93L528.12,483.79L513.59,467.38L566.2,405.43z"
        />
        <path
          id="GT-SM"
          title="San Marcos"
          className="land"
          d="M125.56,537.38L105.78,510.79L40.61,525.19L28.32,489.3L7.41,526.85L34.7,567.65L18.74,583.64L17.43,647.8L0.47,675.95L17.12,688.03L46.05,679.09L21.97,657.55L82.67,636.51L125.56,537.38z"
        />
        <path
          id="GT-RE"
          title="Retalhuleu"
          className="land"
          d="M146.52,641.75L114.09,652.12L110.03,636.3L77.98,680.63L46.05,679.09L17.12,688.03L117.14,763.83L127.1,677.82L146.52,641.75z"
        />
        <path
          id="GT-QZ"
          title="Quezaltenango"
          className="land"
          d="M144.87,550.73L125.56,537.38L82.67,636.51L21.97,657.55L46.05,679.09L77.98,680.63L110.03,636.3L114.09,652.12L146.52,641.75L154.62,636.29L162.84,620.26L130.98,564.01L144.87,550.73z"
        />
        <path
          id="GT-PE"
          title="Petén"
          className="land"
          d="M255.36,0L249.45,117.43L160.86,117.07L192.37,132.36L236.96,188.85L300.4,222.33L320.69,276.86L367.97,290.14L357.47,360.34L452.17,373.95L475.76,395.08L566.2,405.43L598.02,396.1L613.01,0.36L255.36,0z"
        />
        <path
          id="GT-HU"
          title="Huehuetenango"
          className="land"
          d="M185.26,528.69L212.31,522.7L211.26,503.24L182.37,479.41L187.18,457.9L206.17,447.7L248.89,362.37L100.99,364.2L28.32,489.3L40.61,525.19L105.78,510.79L125.56,537.38L144.87,550.73L185.26,528.69z"
        />
        <path
          id="GT-BV"
          title="Baja Verapaz"
          className="land"
          d="M451.73,550.62L456.54,523.61L396.98,521.99L384.78,540.23L346.36,528.88L332.35,539.63L274.53,529.98L273.29,552.28L315.83,602.54L366.98,603.54L386.14,605.49L451.73,550.62z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Guatemala))
