import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 481

const cities = {
  Bissau: { x: 294, y: 216 }
}

const GuineaBissau = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map guinea-bissau">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="GW-TO"
          title="Tombali"
          className="land"
          d="M433.3,470.94L531.76,318.04L605.13,301.11L585.44,279.35L525.76,291.16L500.52,264.02L465.82,332.43L383.84,338.85L368.95,352.7L356.26,350.04L346.79,361.3L347.94,400.7L394.27,418.69L401.35,388.84L395.16,452.94L455.49,393.41L433.3,470.94z"
        />
        <path
          id="GW-GA"
          title="Gabú"
          className="land"
          d="M790.4,1.58L628.6,0.9L616.98,47.84L578.69,39.19L598.6,59.19L571.95,79.3L604.79,135.96L579.74,157.24L585.44,279.35L605.13,301.11L642.65,272.02L789.97,261.01L791.54,182.23L726.12,142.19L799.15,104.12L790.4,1.58z"
        />
        <path
          id="GW-BA"
          title="Bafatá"
          className="land"
          d="M628.6,0.9L489.37,0.3L494.97,54.87L466.41,67.88L475.56,130.63L437.06,136.28L468.05,174.75L457.36,194.4L438.42,223.9L475.35,244.99L500.52,264.02L525.76,291.16L585.44,279.35L579.74,157.24L604.79,135.96L571.95,79.3L598.6,59.19L578.69,39.19L616.98,47.84L628.6,0.9z"
        />
        <path
          id="GW-QU"
          title="Quinara"
          className="land"
          d="M475.35,244.99L437.44,235.8L438.1,199.09L340.27,213.8L310.65,259.25L343.2,268.93L365.88,268.27L397.93,248.84L395.71,274.78L421.29,261.51L410.49,274.52L453.8,287.36L423.28,305.17L425.23,285.76L381.74,283.85L392.53,296.71L331.11,318.87L323.43,361.24L362.72,327.23L368.6,327.42L360.54,344.47L365.42,350.04L383.84,338.85L465.82,332.43L500.52,264.02L475.35,244.99z"
        />
        <path
          id="GW-OI"
          title="Oio"
          className="land"
          d="M417.24,0L344.73,32.93L334.59,80.69L259.53,106.84L280.51,174.52L289.81,186.6L291.47,192.16L312.15,210.4L457.36,194.4L468.05,174.75L437.06,136.28L475.56,130.63L466.41,67.88L494.97,54.87L489.37,0.3L417.24,0z"
        />
        <path
          id="GW-CA"
          title="Cacheu"
          className="land"
          d="M334.59,80.69L344.73,32.93L268.47,66.6L134.02,60.44L0.85,93.46L72.12,136.71L175.93,88.48L98.52,155.76L135.92,206.17L159.76,213.8L197.39,204.15L228.37,175.05L269.67,180.57L280.51,174.52L259.53,106.84L334.59,80.69z"
        />
        <path
          id="GW-BL"
          title="Bolama"
          className="land"
          d="M218.45,413.39L206.66,397.84L198.29,438.66L223.43,435.29L218.45,413.39zM199.45,408.84L177.55,407.68L188.06,433.13L199.45,408.84zM175.23,405.17L127.27,423.48L172.51,441.86L175.23,405.17zM232.95,397.64L236.98,370.28L219.8,384.84L232.95,397.64zM281.63,375.21L257.29,377.53L252.21,405.13L281.63,375.21zM138.2,368.36L118.12,389.68L147.03,392.34L138.2,368.36zM280.64,318.87L262.27,330.85L276.88,333.38L280.64,318.87zM138.45,330.74L148.99,313.2L113.04,329.87L138.45,330.74zM128.71,296.48L82.17,320.54L120.04,318.59L128.71,296.48zM210.74,296.25L171.7,328.94L219.39,329.87L210.74,296.25zM331.11,281.85L289.85,309.59L331.59,299.62L331.11,281.85zM361.83,272.68L365.88,268.27L343.2,268.93L340.27,302.04L361.83,272.68z"
        />
        <path
          id="GW-BS"
          title="Bissau"
          className="land"
          d="M312.15,210.4L291.47,192.16L277.61,235.49L312.15,210.4z"
        />
        <path
          id="GW-BM"
          title="Biombo"
          className="land"
          d="M196.34,213.26L148.99,217.61L151.82,234.68L181.65,248.84L196.34,213.26zM289.81,186.6L280.51,174.52L269.67,180.57L230.16,188.06L201.25,250.65L246.35,213.8L262.5,221.14L237.19,250.65L277.61,235.49L291.47,192.16L289.81,186.6z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(GuineaBissau))
