import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnFlagsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'famous_ones', 'others', 'flags', 'geography']}
    pageName="learn_flags_page"
    topics={['topic_famous_ones', 'topic_others', 'topic_flags', 'topic_geography']}
    topicNavigationPath="geography/flags/learn"
    subTopics={['famous_ones', 'others']}
    borderRadius={0.44}
    canonicalPath="/geography"
  />
)

export default LearnFlagsPage
