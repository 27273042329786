import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 763

const cities = {
  'Port-of-Spain': { x: 235, y: 396 }
}

const TrinidadAndTobago = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map trinidad-and-tobago">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="TT-TOB"
          title="Tobago"
          className="land"
          d="M799.47,0L709.53,27.36L615.05,104.13L640.17,116.13L793.8,44.72L799.47,0z"
        />
        <path
          id="TT-POS"
          title="Port of Spain"
          className="land"
          d="M237.71,386.7L216.3,391.77L246.64,409.94L237.71,386.7z"
        />
        <path
          id="TT-CHA"
          title="Chaguanas"
          className="land"
          d="M318.68,450.5L295.49,450.05L277.72,450.8L265.66,466.33L265.63,475.3L312.68,485.51L318.68,450.5z"
        />
        <path
          id="TT-SFO"
          title="San Fernando"
          className="land"
          d="M277.45,627.59L285.21,598.16L267.14,598.33L249.93,633.23L277.45,627.59z"
        />
        <path
          id="TT-TUP"
          title="Tunapuna/Piarco"
          className="land"
          d="M430.86,312.59L327.97,312.49L334.46,351L286,356.06L295.49,450.05L318.68,450.5L392.46,435.5L413.97,416.13L406.59,365.38L392.24,359.29L439.04,332.68L430.86,312.59zM372.35,426.29L360.4,422.53L366.16,399.34L372.35,426.29z"
        />
        <path
          id="TT-PTF"
          title="Point Fortin"
          className="land"
          d="M126.32,681.41L135.06,694.98L146.94,692.29L169.89,666.18L165.01,655.89L126.32,681.41z"
        />
        <path
          id="TT-PED"
          title="Penal-Debe"
          className="land"
          d="M312.92,735.13L316.47,651.65L277.45,627.59L249.93,633.23L232.38,638.52L261.93,669.94L241.84,737.04L312.92,735.13z"
        />
        <path
          id="TT-CTT"
          title="Couva-Tabaquite-Talparo"
          className="land"
          d="M416.34,513.59L404.46,507.91L408.92,480.76L392.46,435.5L318.68,450.5L312.68,485.51L265.63,475.3L251.05,528.76L267.14,598.33L285.21,598.16L351.34,584.84L389.95,599.83L416.34,513.59z"
        />
        <path
          id="TT-PRT"
          title="Princes Town"
          className="land"
          d="M389.95,599.83L351.34,584.84L285.21,598.16L277.45,627.59L316.47,651.65L312.92,735.13L427.41,733.77L419.27,644.3L389.95,599.83z"
        />
        <path
          id="TT-SJL"
          title="San Juan-Laventille"
          className="land"
          d="M295.49,450.05L286,356.06L334.46,351L327.97,312.49L301,333.23L277.32,341.14L277.32,328.92L273.83,336.83L266.68,329.7L249.82,341.12L237.71,386.7L246.64,409.94L265.66,466.33L277.72,450.8L295.49,450.05z"
        />
        <path
          id="TT-MRC"
          title="Rio Claro-Mayaro"
          className="land"
          d="M506.54,509.38L416.34,513.59L389.95,599.83L419.27,644.3L427.41,733.77L471.73,722.22L504.76,698.86L530.66,697.36L526.8,634.24L550.39,586.6L519.09,556.48L506.54,509.38z"
        />
        <path
          id="TT-SGE"
          title="Sangre Grande"
          className="land"
          d="M506.54,509.38L504.25,482.61L520.69,468.43L509.8,382.24L556.93,335.99L581.08,293.37L483.76,296.95L430.86,312.59L439.04,332.68L392.24,359.29L406.59,365.38L413.97,416.13L392.46,435.5L408.92,480.76L404.46,507.91L416.34,513.59L506.54,509.38z"
        />
        <path
          id="TT-ARI"
          title="Arima"
          className="land"
          d="M372.35,426.29L366.16,399.34L360.4,422.53L372.35,426.29z"
        />
        <path
          id="TT-SIP"
          title="Siparia"
          className="land"
          d="M22.66,752.75L77.48,728.6L138.37,730.06L174.33,745.05L241.84,737.04L261.93,669.94L232.38,638.52L172.04,634.41L165.01,655.89L169.89,666.18L146.94,692.29L135.06,694.98L126.32,681.41L70.33,700.46L0.53,744.58L22.66,752.75z"
        />
        <path
          id="TT-DMN"
          title="Diego Martin"
          className="land"
          d="M136.2,385.23L140.71,372.38L127.94,383.98L136.2,385.23zM237.71,386.7L249.82,341.12L186.15,345.1L148.94,368.8L155.32,378.64L216.3,391.77L237.71,386.7z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(TrinidadAndTobago))
