import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 1004
const height = 1234

const cities = {
  'San Marino': { x: 315, y: 733 }
}

const SanMarino = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map san-marino">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="SM-01"
          title="Acquaviva"
          className="land"
          d="M291.04,490.18L305.25,493.88L314.28,507.52L321.56,542.18L315.8,551.05L315.04,559.74L322.33,574.39L278.44,591.77L217.43,587.79L241.61,609.28L241.07,633.36L241.07,633.36L255.01,679.89L214.94,716.62L203.55,722.91L193.97,732.47L172.07,744.16L155.53,761.3L144.97,764.07L133.62,772.99L116.89,776.41L103,792.44L103,792.44L96.84,793.92L92.34,787.54L87.77,776.87L87.59,764.86L76.92,759.96L77.64,749.61L72.25,742.45L40.11,720.09L22.34,712.46L7.97,696.85L3.23,673.33L5.12,623.42L0,604.34L0.02,576.79L5,555.72L15.48,546.94L34.11,553.59L53.6,556.74L66.94,563.9L76.33,561.22L87.23,561.82L91.48,551.1L113.5,547.12L147.15,493.93L154.17,490.6L166.54,491.06L178.99,496.61L220.44,493.19L261.08,494.16z"
        />
        <path
          id="SM-02"
          title="Chiesanuova"
          className="land"
          d="M250.94,1000.73L251.14,1014.44L254.73,1018.32L267.16,1023.08L273.46,1030.98L278.05,1042.48L294.13,1054.53L303.2,1070.74L304.45,1095.45L284.52,1121.49L285.55,1131.65L285.55,1131.65L280.29,1133.91L268.97,1145.37L254.91,1149.38L239.87,1148.37L229.06,1133.17L222.06,1136.5L214.08,1134.74L191.28,1146.52L183.54,1161.02L170.47,1172.61L153.77,1178.66L122.95,1184.38L104.42,1185.45L88.42,1178.52L80.47,1177.64L62.63,1166.61L45.79,1162.36L59.57,1138.67L60.32,1128.33L67.15,1113.92L73.41,1103.3L83.67,1095.95L86.17,1086.26L67.82,1038.5L45.35,954.31L43.48,946.78L44.99,930.33L50.9,910.89L62.09,892.59L98.54,848.57L101.97,840.49L98.92,815.12L105.08,812.77L105.08,812.77L135.9,806.16L150.02,806.39L164.31,816.93L183.78,819.19L192.68,823.44L195.51,836.84L208.93,848.29L224.67,837.25L225.97,865.39L242.63,857.67L247.94,857.99L246.63,888.99L230.77,892.32L216.63,891.21L202.64,901.23L208.04,906.68L214.44,921.42L214.54,929.13L205.18,952.37L205.28,960.08L226.78,980.13L247.25,990.84z"
        />
        <path
          id="SM-03"
          title="Domagnano"
          className="land"
          d="M919.23,329.32L919.37,338.75L932.02,357.99L911.59,410.68L957.68,480.52L957.68,480.52L947.21,488.47L931.6,508.07L915.87,519.12L902.82,533.31L890.06,566.35L884.01,573.84L877.76,570.18L856.6,572.36L853.92,570.92L857.35,563.72L855.58,562.19L842.34,563.58L830.68,551.05L815.58,546.62L799.78,552.53L791.69,542.23L783.02,553.41L767.07,549.9L758.35,557.66L751.31,558.4L746.06,562.38L733.72,563.62L717.97,574.67L703.87,575.27L702.25,585.76L695.41,601.01L696.45,612.89L675.59,633.91L661.56,640.52L661.13,670.56L641.91,685.39L641.91,685.39L615.42,686.4L598.61,683.86L567.46,668.2L556.87,667.6L560.19,653.55L555.41,629.2L540.51,637.56L545.46,613.07L522.37,604.29L511.77,604.57L492.42,609.98L484.36,602.26L484.06,581.69L502.2,554.1L505.59,545.18L508.06,533.81L507.7,508.12L517.12,488.29L519.53,473.45L525.58,463.42L537.82,455.29L537.82,455.29L573.05,449.09L594.69,420.25L638.58,401.16L640.23,394.13L646.36,390.95L651.71,392.93L666.14,412.9L680.31,416.55L684.9,428.11L696.44,432.04L707.29,448.96L712.55,446.69L724.73,433.43L733.49,429.08L747.34,410.5L756.1,406.15L767.33,387.89L782.21,380.31L789.99,369.22L797.99,371.85L828.87,369.5L853.39,355.81L871.03,353.18L890.21,337.46z"
        />
        <path
          id="SM-04"
          title="Faetano"
          className="land"
          d="M982.14,646.3L961.99,650.64L951.58,663.72L942.16,683.54L924.42,700.91L923.16,713.76L901.64,751.14L899.21,765.09L892.36,780.34L892.63,798.31L884.11,818.91L872.8,831.2L856.12,838.08L840.24,839.7L828.86,845.15L813.27,865.62L811.59,873.52L805.51,879.29L792.63,902.9L794.72,925.81L799.19,928.76L799.27,933.89L799.27,933.89L779.87,935.88L733.92,933.8L732.38,949.36L717.36,949.18L715.58,948.53L714.47,932.37L687.86,925.67L678.76,906.91L664.61,905.81L660.66,877.95L645.79,887.19L645.79,887.19L636.8,878.69L620.85,872.64L617.21,867L617.21,867L638.27,857.12L640.81,850L652.15,840.26L648.5,832.08L656.4,827.83L655.29,814.25L659.65,809.49L664.93,808.1L664.83,801.27L658.39,784.77L676.6,762.36L685.96,738.25L696.31,721.75L695.91,694.4L680.95,699.34L668.74,708.31L641.91,685.39L641.91,685.39L661.13,670.56L661.56,640.52L675.59,633.91L696.45,612.89L695.41,601.01L702.25,585.76L703.87,575.27L717.97,574.67L733.72,563.62L746.06,562.38L751.31,558.4L758.35,557.66L767.07,549.9L783.02,553.41L791.69,542.23L799.78,552.53L815.58,546.62L830.68,551.05L842.34,563.58L855.58,562.19L857.35,563.72L853.92,570.92L856.6,572.36L877.76,570.18L884.01,573.84L890.06,566.35L902.82,533.31L915.87,519.12L931.6,508.07L947.21,488.47L957.68,480.52L957.68,480.52L973.95,505.39L991.71,571.29L993.06,603.69z"
        />
        <path
          id="SM-05"
          title="Fiorentino"
          className="land"
          d="M617.21,867L620.85,872.64L636.8,878.69L645.79,887.19L645.79,887.19L642.56,908.21L646.52,928.39L647.51,954.54L639.88,959.57L630.6,971.53L611.05,982.53L611.37,1001.79L627.63,1004.51L635.59,988.95L656.14,999.94L656.14,1016.01L641.55,1027.47L639.88,1040.77L634.59,1047.65L626.63,1050.37L623.64,1054.53L648.52,1099.46L641.21,1115.49L628.62,1115.49L594.49,1154.92L594.13,1175.19L594.13,1175.19L582.09,1177.27L574.2,1182.35L567.09,1178.8L552.26,1191.49L533.85,1200.22L528.83,1217.86L511.05,1211.16L506.64,1211.58L489.94,1215.87L477.7,1224L453.8,1220.49L447.53,1215.14L413.71,1198.05L410.05,1189.88L413.38,1175.84L403.64,1172.56L399.16,1168.73L398.18,1163.7L402.52,1157.28L398.93,1154.19L404.9,1139.87L402.17,1135.02L389.66,1124.31L364.75,1112.35L354.78,1094.52L327.48,1100.76L307.46,1119.97L313.74,1127.86L308.55,1134.42L305.16,1131.51L300.67,1131.7L294.47,1137.56L291.74,1131L285.55,1131.65L285.55,1131.65L284.52,1121.49L304.45,1095.45L303.2,1070.74L294.13,1054.53L278.05,1042.48L273.46,1030.98L267.16,1023.08L254.73,1018.32L251.14,1014.44L250.94,1000.73L250.94,1000.73L279.05,990.98L294.94,989.36L328.49,989.32L345.36,995.27L388.28,971.16L398.9,973.47L423.6,971.81L430.72,975.32L448.71,938.37L448.71,938.37L466.35,936.57L495.35,926.68L533.2,917.68L558.58,901.32L586.68,890.7L596.21,879.48z"
        />
        <path
          id="SM-06"
          title="Borgo Maggiore"
          className="land"
          d="M537.82,455.29L525.58,463.42L519.53,473.45L517.12,488.29L507.7,508.12L508.06,533.81L505.59,545.18L502.2,554.1L484.06,581.69L484.36,602.26L492.42,609.98L511.77,604.57L522.37,604.29L545.46,613.07L540.51,637.56L555.41,629.2L560.19,653.55L556.87,667.6L567.46,668.2L598.61,683.86L615.42,686.4L641.91,685.39L641.91,685.39L668.74,708.31L680.95,699.34L695.91,694.4L696.31,721.75L685.96,738.25L676.6,762.36L658.39,784.77L664.83,801.27L664.93,808.1L659.65,809.49L655.29,814.25L656.4,827.83L648.5,832.08L652.15,840.26L640.81,850L638.27,857.12L617.21,867L617.21,867L596.21,879.48L586.68,890.7L558.58,901.32L533.2,917.68L495.35,926.68L466.35,936.57L448.71,938.37L448.71,938.37L453,928.49L454.55,914.63L466.64,896.24L475.35,888.48L474.41,883.45L474.41,883.45L472.49,873.38L472.49,873.38L470.3,845.34L464.68,823.62L464.11,785.14L452.56,780.34L450.53,762.55L445.95,750.17L413.62,715.79L398.46,706.23L356.79,694.26L316.12,691.58L299.85,667.55L284.63,652.86L280.98,645.51L266.76,639.27L241.07,633.36L241.07,633.36L241.61,609.28L217.43,587.79L278.44,591.77L322.33,574.39L315.04,559.74L315.8,551.05L321.56,542.18L314.28,507.52L305.25,493.88L291.04,490.18L291.04,490.18L304.03,471.7L314.52,464.63L376.78,375.04L384.64,369.96L403.11,363.76L403.11,363.76L434.97,368.2L458.77,364.87L490.33,349.62L503.35,334.55L517.37,327.94L532.57,340.1L530.22,360.07L507.68,388.13L522.06,406.34L509.12,425.66L529.64,440.68z"
        />
        <path
          id="SM-07"
          title="San Marino"
          className="land"
          d="M241.07,633.36L266.76,639.27L280.98,645.51L284.63,652.86L299.85,667.55L316.12,691.58L356.79,694.26L398.46,706.23L413.62,715.79L445.95,750.17L450.53,762.55L452.56,780.34L464.11,785.14L464.68,823.62L470.3,845.34L472.49,873.38L472.49,873.38L474.41,883.45L474.41,883.45L475.35,888.48L466.64,896.24L454.55,914.63L453,928.49L448.71,938.37L448.71,938.37L430.72,975.32L423.6,971.81L398.9,973.47L388.28,971.16L345.36,995.27L328.49,989.32L294.94,989.36L279.05,990.98L250.94,1000.73L250.94,1000.73L247.25,990.84L226.78,980.13L205.28,960.08L205.18,952.37L214.54,929.13L214.44,921.42L208.04,906.68L202.64,901.23L216.63,891.21L230.77,892.32L246.63,888.99L247.94,857.99L242.63,857.67L225.97,865.39L224.67,837.25L208.93,848.29L195.51,836.84L192.68,823.44L183.78,819.19L164.31,816.93L150.02,806.39L135.9,806.16L105.08,812.77L105.08,812.77L103,792.44L103,792.44L116.89,776.41L133.62,772.99L144.97,764.07L155.53,761.3L172.07,744.16L193.97,732.47L203.55,722.91L214.94,716.62L255.01,679.89z"
        />
        <path
          id="SM-08"
          title="Montegiardino"
          className="land"
          d="M645.79,887.19L660.66,877.95L664.61,905.81L678.76,906.91L687.86,925.67L714.47,932.37L715.58,948.53L717.36,949.18L732.38,949.36L733.92,933.8L779.87,935.88L799.27,933.89L799.27,933.89L796.71,941L799.57,953.57L797.3,979.48L801.76,983.31L801.89,991.86L808.96,992L815.18,995.64L815.35,1005.9L794.48,1028.62L792.04,1042.57L784.38,1062.2L762.84,1097.8L755.29,1125.14L749.63,1160.83L643.74,1166.65L610.32,1174.36L594.13,1175.19L594.13,1175.19L594.49,1154.92L628.62,1115.49L641.21,1115.49L648.52,1099.46L623.64,1054.53L626.63,1050.37L634.59,1047.65L639.88,1040.77L641.55,1027.47L656.14,1016.01L656.14,999.94L635.59,988.95L627.63,1004.51L611.37,1001.79L611.05,982.53L630.6,971.53L639.88,959.57L647.51,954.54L646.52,928.39L642.56,908.21z"
        />
        <path
          id="SM-09"
          title="Serravalle"
          className="land"
          d="M978.57,42.41L984.51,85.88L973.94,87.87L961.68,95.13L936.65,133.75L942.2,150.3L947.29,195.25L956.44,217.44L956.58,226.87L930.42,250.13L905.75,254.38L913.25,283.65L919.23,329.32L919.23,329.32L890.21,337.46L871.03,353.18L853.39,355.81L828.87,369.5L797.99,371.85L789.99,369.22L782.21,380.31L767.33,387.89L756.1,406.15L747.34,410.5L733.49,429.08L724.73,433.43L712.55,446.69L707.29,448.96L696.44,432.04L684.9,428.11L680.31,416.55L666.14,412.9L651.71,392.93L646.36,390.95L640.23,394.13L638.58,401.16L594.69,420.25L573.05,449.09L537.82,455.29L537.82,455.29L529.64,440.68L509.12,425.66L522.06,406.34L507.68,388.13L530.22,360.07L532.57,340.1L517.37,327.94L503.35,334.55L490.33,349.62L458.77,364.87L434.97,368.2L403.11,363.76L403.11,363.76L430.84,329.19L582.71,206.3L598.47,196.96L612.51,192.1L621.19,182.62L636.97,174.11L657.23,170.32L683.49,154.74L712.63,153.44L724.04,149.7L756.32,122.32L781.93,122.28L788,114.79L798.49,107.71L806.21,92.31L814.02,83.8L832.54,81.86L842.14,73.17L854.48,71.87L862.37,67.66L871.89,53.79L868.26,48.15L869.06,43.8L878.71,39.36L882.07,27.89L887.32,23.91L897.05,23.73L903.93,12.77L913.66,14.29L920.59,3.28L923.22,3.05L930.51,16.88L934.03,17.34L938.36,11.75L944.56,11.1L952.57,17.16L958.78,18.22L963.12,14.34L963.85,2.27L969.1,0L974.44,2.04z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(SanMarino))
