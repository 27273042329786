import React, { Component } from 'react'
import { connect } from 'react-redux'
import TopicNavigationPage from '../components/TopicNavigationPage'
import annyang from '../lib/annyangSetUp'
import { createAndList, capitalizeWord } from '../lib/methodsUtil'
import {
  lngMap,
  languageToLngCodeMap,
  findSpokenFromList,
  lngCodeToLanguageMap,
  supportedLanguagesNames,
  supportedLngCodes,
  updateLanguageWith,
  common
} from '../lib/languageUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import ImageChoiceButton from '../components/ImageChoiceButton'

const navigationCommands = ['menu', 'settings']

const bindNavigationCommandsAndStart = (dispatch, language) => {
  const navCommands = navigationCommands.map(command => common(command).toLowerCase())
  annyang.bindNavigationCommands(dispatch, navCommands, languageToLngCodeMap[language])
  annyang.start()
}

const updateAnnyangLanguageAndRestart = (dispatch, language) => {
  updateLanguageWith(language, dispatch)
  annyang.abort()
  annyang.setLanguage(language)
  bindNavigationCommandsAndStart(dispatch, language)
}

class LanguagePage extends Component {
  componentDidMount() {
    this.handleLanguageFlagClick = this.handleLanguageFlagClick.bind(this)
  }

  componentDidUpdate(oldProps) {
    const { transcript, language, lngCode, dispatch } = this.props

    if (oldProps.transcript !== transcript) {
      const foundLanguageName = findSpokenFromList(lngCode, transcript, supportedLanguagesNames)

      if (foundLanguageName) {
        const foundLanguage = lngMap[lngCode][capitalizeWord(foundLanguageName)]
        if (foundLanguage !== language) {
          updateAnnyangLanguageAndRestart(dispatch, foundLanguage)
        }
      }
    }
  }

  handleLanguageFlagClick(language) {
    const { dispatch } = this.props
    updateAnnyangLanguageAndRestart(dispatch, language)
  }

  render() {
    const { lngCode } = this.props
    const topics = ['topic_language', 'topic_settings', 'topic_menu']

    return (
      <TopicNavigationPage
        navigationCommands={navigationCommands}
        pageName="language_page"
        topics={topics}
        extraIntro={createAndList(supportedLngCodes, 'languages')}
        subTopics={navigationCommands}
        allowedContentKeyWords={supportedLngCodes.map(lng => common(`languages.${lng}`))}
        borderRadius={0.36}
        SEODescription="E=MC² - Extend Main Communication² - Via Speech Commands, Children and Kids can update the Games to English or French and Play, Learn a New Language. 100% free">
        {() => (
          <div className="image-choice-images-wrapper">
            {supportedLngCodes.map((lng, index) => (
              <ImageChoiceButton
                key={lng}
                value={lng}
                index={index}
                altName="language"
                isSelected={lng === lngCode}
                imageText={`${common(`languages.${lng}`)}${lng === lngCode ? ' ✓' : ''}`}
                onClick={() =>
                  lng !== lngCode ? this.handleLanguageFlagClick(lngCodeToLanguageMap[lng]) : {}
                }
                srcUrl={`images/geography/flags/${lng === 'en' ? 'gb' : lng}.svg`}
              />
            ))}
          </div>
        )}
      </TopicNavigationPage>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(LanguagePage)
