import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const LearnLocationsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'famous_ones', 'others', 'locations', 'geography']}
    pageName="learn_locations_page"
    topics={['topic_famous_ones', 'topic_others', 'topic_locations', 'topic_geography']}
    topicNavigationPath="geography/locations/learn"
    subTopics={['famous_ones', 'others']}
    borderRadius={0.63}
    canonicalPath="/geography"
  />
)

export default LearnLocationsPage
