import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, geographyMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps } from './util'
import { answerQuestionMap } from './answerQuestionMap'
import { getTranslatedAnswer } from './../../../lib/methodsUtil'

const HardFlagsPage = props => {
  const { lngCode = 'en', actual: enCountry } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'flags',
        questionLevel: 'hard',
        country:
          lngCode === 'en' ? enCountry : getTranslatedAnswer(lngCode, answerQuestionMap, enCountry)
      })}
    />
  )
}

export default connect(geographyMapStateToProps, passDispatchToProps)(HardFlagsPage)
