import { baseUrl } from './_processUtil'

const today = new Date()

// getMonth() returns as 0-11 indexes so I add + 1 to make it normal month number
export const currentMonth = today.getMonth() + 1
export const isChristmasTheme = currentMonth === 12
export const isHalloweenTheme = currentMonth === 10
export const isThemeMonth = isChristmasTheme || isHalloweenTheme

const correctAnswerImagesCount = 2271

// Images - { month(key): number of images(value) }
const themeMax = {
  // 2: xx // February - Valentine's day
  // 4: xx // April - Easter
  // 7: xx // July - summer
  10: 346, // October - Halloween
  // 11: xx // November - Thanks giving
  12: 570 // December - Christmas
}

// 2271 is the  images in normal months / will be different for Xmas and seaons
const createInitialWinningImagesList = () => {
  const themeMaxNumberOfImages = isThemeMonth ? themeMax[currentMonth] : correctAnswerImagesCount
  const winningImagesList = []
  // index will be going from 0 to number of images - 1 (the index of the last image);
  for (let i = 0; i < themeMaxNumberOfImages; i++) {
    winningImagesList.push(i)
  }
  return winningImagesList
}

const getCorrectAnswerImageUrl = winningImage =>
  isThemeMonth
    ? `${baseUrl}/images/correctAnswer/${currentMonth}/${winningImage}.webp`
    : `${baseUrl}/images/correctAnswer/${winningImage}.webp`

export { createInitialWinningImagesList, getCorrectAnswerImageUrl }
