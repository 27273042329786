import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
// import Snowfall from 'react-snowfall'
import annyang from './lib/annyangSetUp'
import readOutLoud from './lib/readOutLoud'
// import { isChristmasTheme } from './lib/themeUtil'
import {
  isSpeakRequest,
  isSpeakSoundDisabled,
  createBorderRadius,
  capitalizeWord
} from './lib/methodsUtil'
import { isDevEnvironment } from './lib/_processUtil'
import {
  supportedLngCodes,
  findSpokenFromList,
  translateFrom,
  lngCodeToLanguageMap,
  lngMap,
  ownLanguageIntro,
  updateLanguageWith,
  supportedLanguagesNames
} from './lib/languageUtil'
import {
  changePathAction,
  updateIsAnnyangSupportedAction,
  updateIsListeningAction
} from './redux/actions/actions'
import { mapMinimalStateToProps } from './redux/mapToPropsUtil'
import SEO from '../src/components/SEO'
import SpeechButton from '../src/components/SpeechButton'
import ImageChoiceButton from '../src/components/ImageChoiceButton'
import ParentsInfoButton from '../src/components/ParentsInfoButton'
import StyledI18n from '../src/components/StyledI18n'
// import Footer from '../src/components/Footer'
import AppListeningSpeakingVisual from '../src/components/AppListeningSpeakingVisual'
import { wasPathChanged } from '../src/router/routerUtil'

const translate = translateFrom('home_language_choice')

const navigationCommands = ['menu']

const mapDispatchToProps = dispatch => ({
  updateIsAnnyangSupported: isSupported => dispatch(updateIsAnnyangSupportedAction(isSupported)),
  dispatch
})

const updatelanguageWithAndRedirectToHome = (language, dispatch) => {
  updateLanguageWith(language, dispatch)
  annyang.setLanguage(language)
  dispatch(changePathAction('/home'))
}

class LanguageChoice extends Component {
  constructor(props) {
    super(props)
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    // All this logic is to override isReadyForRelease
    this.state = {
      isReadyForRelease: isDevEnvironment,
      pass: ''
    }
  }

  handleKeyUp(e) {
    const { pass } = this.state
    const newWord = pass + e.key
    this.setState({ pass: newWord })
    if (newWord === 'qqq') {
      this.setState({ isReadyForRelease: true })
    }
  }

  componentDidMount() {
    const { updateIsAnnyangSupported, dispatch } = this.props

    // browserDetectedLanguage comes back as 'en-GB', 'en-US', 'en', 'fr' but we only use the 2 first digits to set-up
    const browserDetectedLanguage = i18n.language
    updateLanguageWith(browserDetectedLanguage, dispatch)

    const isSupported = !!annyang.isSupported()
    updateIsAnnyangSupported(isSupported)

    annyang.bindNavigationCommands(dispatch, navigationCommands)
    annyang.start()
    dispatch(updateIsListeningAction(true))
    annyang.addTranscriptListener(dispatch)
    annyang.setLanguage(browserDetectedLanguage)
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentDidUpdate(oldProps) {
    const { transcript, dispatch, lngCode } = this.props

    if (oldProps.transcript !== transcript) {
      const foundLanguageName = findSpokenFromList(lngCode, transcript, supportedLanguagesNames)

      if (foundLanguageName) {
        const foundLanguage = lngMap[lngCode][capitalizeWord(foundLanguageName)]
        updatelanguageWithAndRedirectToHome(foundLanguage, dispatch)
      }
    }
  }

  handleLanguageChange(language) {
    const { dispatch } = this.props
    updatelanguageWithAndRedirectToHome(language, dispatch)
  }

  componentWillUnmount() {
    annyang.abort()
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  render() {
    const { isAnnyangSupported, path, transcript, language, gender, soundLevel, dispatch } =
      this.props
    const { isReadyForRelease } = this.state

    const detectedLanguage = translate('detected_language')
    const isAnnyangSupportedMessage = translate(
      isAnnyangSupported ? 'language_question' : 'not_supported'
    )

    const readOutLoudText = `${detectedLanguage}. ${isAnnyangSupportedMessage}`

    const isSpeakDisabled = isSpeakSoundDisabled(soundLevel)
    if (isSpeakRequest(transcript) && !isSpeakDisabled) {
      readOutLoud(readOutLoudText, dispatch, language, gender)
    }

    return isReadyForRelease ? (
      <div className="language-choice-wrapper">
        <SEO />

        {/* {isChristmasTheme && <Snowfall color="white" snowflakeCount={200} />} */}

        {!isSpeakDisabled && (
          <SpeechButton className="language-choice-speech-button" text={readOutLoudText} />
        )}

        <AppListeningSpeakingVisual />

        <div className="navigation-box" style={createBorderRadius(0.25)}>
          <div className="detected-language">{detectedLanguage}</div>
          <div className="annyang-supported-state">
            {isAnnyangSupported ? (
              translate('language_question')
            ) : (
              <StyledI18n defaultString={translate('styled_not_supported')} />
            )}
          </div>
          {isAnnyangSupported && (
            <div className="image-choice-images-wrapper">
              {supportedLngCodes.map((lng, index) => (
                <ImageChoiceButton
                  key={lng}
                  value={lng}
                  index={index}
                  altName="language"
                  imageText={
                    ownLanguageIntro[isAnnyangSupported ? 'supported' : 'notSupported'][lng]
                  }
                  onClick={() => this.handleLanguageChange(lngCodeToLanguageMap[lng])}
                  srcUrl={`images/geography/flags/${lng === 'en' ? 'gb' : lng}.svg`}
                  isFirstPage={true}
                />
              ))}
            </div>
          )}
        </div>

        <ParentsInfoButton />

        {wasPathChanged(path, '/') && <Navigate to={path} replace />}

        {/* <Footer /> */}
      </div>
    ) : (
      <div className="coming-soon" />
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, mapDispatchToProps)(LanguageChoice)
)
