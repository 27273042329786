import React, { useState, useEffect } from 'react'
import { baseUrl } from '../lib/_processUtil'
import ImageWithDimensions from './ImageWithDimensions'

const ProgressBar = ({ percentage = 50, actualAmount, target }) => {
  const [progressBarStyle, setProgressBarStyle] = useState({})
  const [opacityStyle, setOpacityStyle] = useState({})

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgressBarStyle({ opacity: 1, width: `${percentage}%` })
      setOpacityStyle({ opacity: 1 })
    }, 500)

    return () => clearTimeout(timer)
  }, [percentage])

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-bar" style={progressBarStyle}>
        <div className="progress-bar-bar-actual-amount" style={opacityStyle}>
          {`${parseInt(percentage)}%`}
        </div>
        {actualAmount > 1 && (
          <ImageWithDimensions
            className="progress-bar-bar-unicorn"
            src={`${baseUrl}/images/support/unicorn.webp`}
            style={opacityStyle}
          />
        )}
      </div>
      <div className="progress-bar-bar-target">{target}</div>
    </div>
  )
}

export default ProgressBar
