import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 724

const cities = {
  'Port-au-Prince': { x: 602, y: 454 }
}

const Haiti = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map haiti">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="HT-SE"
          title="Sud-Est"
          className="land"
          d="M580.81,507.97L413.67,524.43L409.49,554.88L410.74,566.79L676.53,549.91L760.42,609.14L776.07,533.04L580.81,507.97z"
        />
        <path
          id="HT-OU"
          title="Ouest"
          className="land"
          d="M463.99,411.3L456.71,373.4L328.94,340.57L463.99,411.3zM544.74,333.4L496.47,338.25L603.1,416.51L596.46,462.39L397.14,483.84L413.67,524.43L580.81,507.97L776.07,533.04L694.11,441.82L756.01,417.29L593.23,361.42L544.74,333.4z"
        />
        <path
          id="HT-ND"
          title="Nord"
          className="land"
          d="M683.3,107.27L536.46,57.89L505.12,78.7L559.96,158.2L604.01,151.54L640.11,230.78L702.9,220.6L665.47,151.24L683.3,107.27z"
        />
        <path
          id="HT-NE"
          title="Nord-Est"
          className="land"
          d="M765.76,107.85L683.3,107.27L665.47,151.24L702.9,220.6L742.99,238.24L768.01,237.46L765.76,107.85z"
        />
        <path
          id="HT-CE"
          title="Centre"
          className="land"
          d="M742.99,238.24L702.9,220.6L640.11,230.78L626.66,358.38L593.23,361.42L756.01,417.29L774.13,362.31L735.11,332.19L799.35,257.79L768.01,237.46L742.99,238.24z"
        />
        <path
          id="HT-AR"
          title="L'Artibonite"
          className="land"
          d="M505.12,78.7L378.73,132.27L498.43,187.47L468.84,251.83L496.15,285.88L464.59,297.92L496.47,338.25L544.74,333.4L593.23,361.42L626.66,358.38L640.11,230.78L604.01,151.54L559.96,158.2L505.12,78.7z"
        />
        <path
          id="HT-SD"
          title="Sud"
          className="land"
          d="M215.94,588.95L223.61,602.01L248.95,600.87L215.94,588.95zM165.12,613.04L229.55,543.58L410.74,566.79L409.49,554.88L380.13,514.76L247.84,497.51L190.45,493.94L0.65,513L165.12,613.04z"
        />
        <path
          id="HT-NO"
          title="Nord-Ouest"
          className="land"
          d="M476.6,37.3L365.59,39.18L280.13,103.42L378.73,132.27L505.12,78.7L536.46,57.89L476.6,37.3zM480.46,0L424.28,1.86L515.53,24.93L480.46,0z"
        />
        <path
          id="HT-NI"
          title="Nippes"
          className="land"
          d="M397.14,483.84L203.14,458.29L190.45,493.94L247.84,497.51L380.13,514.76L409.49,554.88L413.67,524.43L397.14,483.84z"
        />
        <path
          id="HT-GA"
          title="Grand'Anse"
          className="land"
          d="M210.17,441.07L186.92,432.88L194.58,444.95L210.17,441.07zM83.73,423.74L10.34,436.22L0.65,513L190.45,493.94L203.14,458.29L83.73,423.74z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Haiti))
