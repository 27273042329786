const passDispatchToProps = dispatch => ({
  dispatch
})

const mapIsAnnyangSupportedStateToProps = state => ({
  isAnnyangSupported: state.app.isAnnyangSupported
})

const mapListeningStateToProps = state => ({
  ...mapIsAnnyangSupportedStateToProps(state),
  isListening: state.app.isListening
})

const mapMinimalStateToProps = state => ({
  user: state.app.user,
  path: state.app.path,
  transcript: state.app.transcript,
  language: state.app.language,
  lngCode: state.app.lngCode,
  gender: state.app.gender,
  soundLevel: state.app.soundLevel,
  temporarilyUnlocked: state.app.temporarilyUnlocked,
  ...mapListeningStateToProps(state)
})

const gameMapStateToProps = state => ({
  ...mapMinimalStateToProps(state),
  isCorrectAnswer: state.app.isCorrectAnswer,
  isPlaying: state.app.isPlaying,
  winningImage: state.app.winningImage
})

const learnMapStateToProps = state => ({
  ...mapMinimalStateToProps(state),
  isLearning: state.learn.isLearning,
  learnList: state.learn.learnList,
  actual: state.learn.actual,
  translatedActual: state.learn.translatedActual,
  randomNumber: state.learn.randomNumber,
  extra: state.learn.extra,
  mapFillColorOne: state.learn.mapFillColorOne,
  mapFillColorTwo: state.learn.mapFillColorTwo
})

const wordsMapStateToProps = state => ({
  ...gameMapStateToProps(state),
  actual: state.words.actual,
  seenList: state.words.seenList,
  randomNumber: state.words.randomNumber
})

const geographyMapStateToProps = state => ({
  ...gameMapStateToProps(state),
  actual: state.geography.actual,
  actualMultipleChoices: state.geography.actualMultipleChoices,
  seenList: state.geography.seenList,
  multipleChoicesToChooseFrom: state.geography.multipleChoicesToChooseFrom,
  mapFillColorOne: state.geography.mapFillColorOne,
  mapFillColorTwo: state.geography.mapFillColorTwo
})

const mathematicsMapStateToProps = state => ({
  ...gameMapStateToProps(state),
  actual: state.mathematics.actual,
  randomList: state.mathematics.randomList,
  resultsList: state.mathematics.resultsList
})

const readingMapStateToProps = state => ({
  ...gameMapStateToProps(state),
  actual: state.reading.actual,
  seenList: state.reading.seenList,
  actualColor: state.reading.actualColor,
  seenColorsList: state.reading.seenColorsList
})

export {
  passDispatchToProps,
  mapMinimalStateToProps,
  mapListeningStateToProps,
  mapIsAnnyangSupportedStateToProps,
  learnMapStateToProps,
  wordsMapStateToProps,
  geographyMapStateToProps,
  mathematicsMapStateToProps,
  readingMapStateToProps
}
