import React, { useEffect } from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  XIcon,
  TwitterShareButton
} from 'react-share'
import { connect } from 'react-redux'
import { passDispatchToProps } from '../redux/mapToPropsUtil'
import { getRandomItemFromList } from '../lib/methodsUtil'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import { updateTemporarilyUnlockedAction } from '../redux/actions/actions'

const sharesList = [
  {
    url: 'https://www.mini-Einstein.com',
    title: 'Mini Einstein! Where geniuses are made...'
  },
  {
    url: 'https://www.youtube.com/watch?v=db3Td2RRfV4c',
    title: 'For real'
  }
]

const sharedItem = getRandomItemFromList(sharesList)

const SocialSharing = ({ dispatch }) => {
  const commonProps = shareType => ({
    url: sharedItem.url,
    title: sharedItem.title,
    onClick: () => {
      sendAmplitudeEvent('Social Sharing Page', { action: 'shared', type: shareType })
      setTimeout(() => dispatch(updateTemporarilyUnlockedAction(Date.now())), 15000)
    }
  })

  useEffect(() => {
    sendAmplitudeEvent('Social Sharing Page', { action: 'visit' })
  }, [])

  return (
    <div className="social-share-container">
      <FacebookShareButton {...commonProps('Facebook')}>
        <FacebookIcon size={64} round={true} />
      </FacebookShareButton>

      <TwitterShareButton {...commonProps('Twitter')} hashtags={['#metal']}>
        <XIcon size={64} round />
      </TwitterShareButton>

      <LinkedinShareButton {...commonProps('LinkedIn')} summary="blabla">
        <LinkedinIcon size={64} round />
      </LinkedinShareButton>
    </div>
  )
}

export default connect(() => ({}), passDispatchToProps)(SocialSharing)
