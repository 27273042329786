import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1792

const cities = {
  'Port-Vila': { x: 430, y: 1156 }
}

const Vanuatu = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map vanuatu">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="VU-SEE"
          title="Shefa"
          className="land"
          d="M430.15,1103.95L414.7,1106.26L387.29,1146.03L392.91,1151.71L405.12,1140.73L418.41,1143.39L423.74,1154.46L410.12,1163.06L426.33,1156.25L424.84,1164.93L432.96,1161.52L443.6,1172.32L479.65,1165.58L489.98,1137.55L480.34,1137.75L455.67,1100.91L430.15,1103.95zM402.07,1109.13L421.54,1098.27L408.13,1097.14L402.07,1109.13zM439.74,1086.49L429.13,1071.62L430.73,1084.22L439.74,1086.49zM432.9,990.61L452.33,978.99L434.35,982.22L428.03,989.62L432.9,990.61zM486.79,951.92L493.13,941.4L488.71,936.86L478.16,941.22L477.87,952L486.79,951.92zM395.34,862.13L385.65,861.54L381.49,881.02L387,912.48L406.7,923.48L434.1,915.06L463.9,929.71L461.58,912.34L439.31,909.62L420.3,887.65L410.12,890.61L395.34,862.13z"
        />
        <path
          id="VU-PAM"
          title="Penama"
          className="land"
          d="M405.25,717.59L414.97,707.28L414.99,688.92L398.76,627.99L399.79,605L388.1,587.48L380.43,646.32L397.08,716.26L405.25,717.59zM318.56,590.79L334.3,577.7L352,542.81L305,552.2L273.76,584.16L288.31,592.66L318.56,590.79zM395.31,563.4L397.01,524.23L377.69,452.81L372.9,459.63L382.31,512.26L382.54,570.69L393.9,570.69L395.31,563.4z"
        />
        <path
          id="VU-MAP"
          title="Malampa"
          className="land"
          d="M434.6,853.18L434.35,842.96L421.54,842.96L434.6,853.18zM409.29,842.12L413.37,826.08L404.27,828.76L399.63,842.32L409.29,842.12zM427.91,798.5L427.68,789.74L406.18,776.1L392.16,739.66L367.91,764.25L330.73,777.43L356.63,800.55L384.64,809.29L427.91,798.5zM214.8,747.4L193.82,697.36L167.75,687.1L158.03,692.86L149.05,740.32L169.37,761.74L190.58,748.25L203.02,767.51L205.32,810.86L222.94,860.29L237.71,863.4L261.67,842.04L297.29,853.48L314.37,832.99L309.34,823.43L297.75,833.46L302.68,820.69L297.4,802.89L245.83,770.33L240.96,760.05L229.64,760.05L225.78,745.23L225.6,753.17L214.8,747.4z"
        />
        <path
          id="VU-TAE"
          title="Tafea"
          className="land"
          d="M787.74,1781.3L798.06,1776.48L799.78,1762.4L783.7,1752.56L768.13,1754.78L763.96,1771.25L787.74,1781.3zM694.57,1629.63L707.88,1599.27L675.78,1584.7L672.55,1549.06L650.86,1549.12L640.66,1589.86L673.69,1626.64L694.57,1629.63zM633.12,1400.13L627.35,1379.41L616.9,1371.18L590.72,1378.84L585.78,1435.64L654.08,1466.44L666.6,1456.67L667.68,1442.75L634.65,1413.19L652.48,1408.9L647.67,1400.01L633.12,1400.13z"
        />
        <path
          id="VU-SAM"
          title="Sanma"
          className="land"
          d="M169.52,656.02L169.48,631.4L144.18,626.46L132.01,633.38L138.11,648.57L158.16,658.33L169.52,656.02zM163.77,620.27L159.19,604.32L140.04,618.08L163.77,620.27zM69.69,512.94L54,429.19L39.55,419.46L18.71,381.49L8.69,386.56L0.73,432.26L21.44,481.35L30.77,527.19L22.9,568.21L44.54,604.6L56.17,611.9L55.63,627.99L70.27,635.53L92.2,617.8L132.18,619.4L151.4,602.16L171.15,600.91L154.8,563.62L159.56,548.66L147.46,534.92L148.27,504.99L135.2,508.22L123.84,479.67L133.71,456.03L115.86,454.35L105.62,461.55L105.31,499.11L95,511.84L69.69,512.94z"
        />
        <path
          id="VU-TOB"
          title="Torba"
          className="land"
          d="M238.68,263.39L210.77,276.02L209.28,303.01L247.45,301.49L257.19,274.59L238.68,263.39zM250.7,190.74L230.1,156.97L208.66,168.66L207.81,185.51L224.15,214.6L232.38,209.85L229.64,200.77L250.7,190.74zM277.24,149.59L284.15,144.31L280.83,135.38L264.78,153.39L277.24,149.59zM193.98,111.87L193.52,102.12L184.46,107.22L188.96,118.71L201.55,116.72L202.07,110.36L193.98,111.87zM40.15,87.44L32.97,85.49L36.35,95.14L40.15,87.44zM26.34,47.97L26.57,37.02L13.21,41.78L26.34,47.97zM0.22,0L2.39,25.59L9.61,29.44L11.18,5.91L0.22,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Vanuatu))
