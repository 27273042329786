import { changePathAction, saveTranscriptAction } from '../redux/actions/actions'
import { mainCommandpathMap, extendedCommandPathMap } from './commandPathMaps'

export const createCommands = (dispatch, navigationCommands, lngCode = 'en') => {
  // filter mainCommandpathMap to limit main commands to the ones passed within the navigationCommands array for each component
  let commandpathMap = {}
  navigationCommands.forEach(command => {
    commandpathMap[command] = mainCommandpathMap[lngCode][command]
  })

  // Sub-paths commands for sub-pages (ex: Capitals, Locations, Flags - easy, medium, hard)
  const pathName = window.location.pathname
  const subPathsKeysList = Object.keys(extendedCommandPathMap[lngCode])
  // if is a subtpath, ex /geography/capitals, handle the easy medium hardm etc for that page
  if (subPathsKeysList.includes(pathName)) {
    commandpathMap = { ...commandpathMap, ...extendedCommandPathMap[lngCode][pathName] }
  }

  // then make a new commands object for Annyang via Redux
  const annyangReduxCommandsObject = {}
  navigationCommands.forEach(command => {
    annyangReduxCommandsObject[command] = () => dispatch(changePathAction(commandpathMap[command]))
  })
  return annyangReduxCommandsObject
}

export const saveTranscript = (dispatch, transcript) => {
  dispatch(saveTranscriptAction(transcript))
}
