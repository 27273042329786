import React, { Component, useState } from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'
import StyledI18n from '../components/StyledI18n'
import { createBorderRadius } from '../lib/methodsUtil'

const questions = [
  { question: 'what_is', color: 'deeppink' },
  { question: 'how_does_it_work', color: 'dodgerblue' },
  { question: 'how_to_play', color: 'limegreen' },
  { question: 'permissions', color: 'darkorange' },
  { question: 'microphone_not_supported', color: 'crimson' },
  { question: 'microphone_not_picking_up', color: 'darkorchid' },
  { question: 'microphone_permissions', color: 'darkgoldenrod' },
  { question: 'languages', color: 'darkturquoise' },
  { question: 'difficulties_levels', color: 'violet' },
  { question: 'settings', color: 'red' },
  { question: 'non_supported', color: 'cornflowerblue' },
  { question: 'created', color: 'lightseagreen' },
  { question: 'cost', color: 'goldenrod' },
  { question: 'contact', color: 'indianred' }
]

const HoverColorLink = ({ question, color, translate }) => {
  const [textColor, changeColor] = useState({ color: 'black' })

  const handleMouseEnter = () => {
    changeColor({ color, background: 'linear-gradient(wheat, papayawhip, wheat)' })
  }

  const handleMouseLeave = () => {
    changeColor({ color: 'black' })
  }

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={textColor}>
      {translate(`${question}_question`)}
    </div>
  )
}

const scrollTo = question => {
  const element = document.getElementById(question)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
  }
}

const Content = translate => {
  const handleClickScroll = question => {
    scrollTo(question)
  }

  return (
    <>
      <div className="navigation-box jump-to-questions-box" style={createBorderRadius(0.9)}>
        <div className="jump-to-questions-box-title">{translate('jump_to_questions')}:</div>
        <ul>
          {questions.map(({ question, color }) => (
            <li key={question} onClick={() => handleClickScroll(question)}>
              <HoverColorLink question={question} color={color} translate={translate} />
            </li>
          ))}
        </ul>
      </div>

      <hr />

      <div>
        {questions.map(({ question, color }) => (
          <div
            className="navigation-box question-answer-box"
            key={`${question}-qa`}
            style={createBorderRadius(0.1)}>
            <div className="question-answer-box-title" id={question} style={{ color: color }}>
              {translate(`${question}_question`)}
            </div>
            <StyledI18n defaultString={translate(`${question}_answer`)} />
          </div>
        ))}
      </div>
    </>
  )
}

class QuestionsPage extends Component {
  componentDidMount() {
    // an override of the root height is needed for that page
    const rootElement = document.getElementById('root')
    rootElement.style.height = 'auto'

    // a has is only in the url if a user clicked the link from ParentsInfoButton
    if (window.location.hash) {
      scrollTo('microphone_not_picking_up')
    }
  }

  componentWillUnmount() {
    // set up to original value on unmount
    const rootElement = document.getElementById('root')
    rootElement.style.height = 'inherit'
  }

  render() {
    return (
      <TopicNavigationPage
        navigationCommands={['menu', 'about']}
        pageName="questions_page"
        topics={['topic_about', 'topic_menu']}
        subTopics={['menu', 'about']}
        disableSpeechButton={true}
        borderRadius={0.45}
        SEODescription="E=MC² - Explained Mysteries & Curiosities² - You have questions! We have answers! Discover Information about Microphone & Artificial Intelligence on our FAQ">
        {Content}
      </TopicNavigationPage>
    )
  }
}

export default QuestionsPage
