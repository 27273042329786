import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, readingMapStateToProps } from './../../../redux/mapToPropsUtil'
import { answerQuestionMap, commonProps, gameTypeProps } from './util'
import { getTranslatedAnswer } from './../../../lib/methodsUtil'

// like 2 syllabs: rabbit, lion, piano, guitar

const MediumWordsReadingPage = props => {
  const { lngCode = 'en', actual: enWord, actualColor } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'medium_words_reading',
        word: lngCode === 'en' ? enWord : getTranslatedAnswer(lngCode, answerQuestionMap, enWord),
        actualColor
      })}
    />
  )
}

export default connect(readingMapStateToProps, passDispatchToProps)(MediumWordsReadingPage)
