import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1983

const cities = {
  Lilongwe: { x: 256, y: 1157 }
}

const Malawi = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map malawi">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="MW-MW1"
          title="Neno"
          className="land"
          d="M537.02,1504.49L466.7,1475.34L432.05,1571.69L517.19,1580.77L578.62,1514.05L589.41,1507.27L537.02,1504.49z"
        />
        <path
          id="MW-NA"
          title="Likoma"
          className="land"
          d="M504.47,690.55L517.04,670.16L500.15,660.06L490.75,684.58L504.47,690.55zM484.69,673.25L483.64,656.74L462.42,658.59L484.69,673.25z"
        />
        <path
          id="MW-PH"
          title="Phalombe"
          className="land"
          d="M773.13,1663.76L783.27,1541.84L739.54,1548.59L706.27,1579.75L698.89,1601.4L724.6,1664.35L773.13,1663.76z"
        />
        <path
          id="MW-MJ"
          title="Mulanje"
          className="land"
          d="M773.13,1663.76L724.6,1664.35L698.89,1601.4L706.27,1579.75L660.23,1599.48L652.75,1682.34L649.96,1734.76L673.3,1714.09L703.29,1725.12L748.61,1708.66L770.29,1691.85L773.13,1663.76z"
        />
        <path
          id="MW-MW2"
          title="Mwanza"
          className="land"
          d="M517.19,1580.77L432.05,1571.69L413.01,1611.54L513.93,1632.61L517.19,1580.77z"
        />
        <path
          id="MW-BL"
          title="Blantyre"
          className="land"
          d="M611.14,1561.92L578.62,1514.05L517.19,1580.77L513.93,1632.61L543.09,1636.59L549.04,1687.83L612.1,1645.16L611.14,1561.92z"
        />
        <path
          id="MW-ZO"
          title="Zomba"
          className="land"
          d="M771.14,1478.23L618.62,1483.04L589.41,1507.27L578.62,1514.05L611.14,1561.92L660.23,1599.48L706.27,1579.75L739.54,1548.59L783.27,1541.84L771.14,1478.23z"
        />
        <path
          id="MW-NS"
          title="Nsanje"
          className="land"
          d="M648.17,1972.72L644.76,1861.79L608.55,1819.65L634.63,1783.87L599.68,1766.39L590.88,1806.09L547.8,1861.82L605.17,1896.11L592.22,1967.66L648.17,1972.72z"
        />
        <path
          id="MW-TH"
          title="Thyolo"
          className="land"
          d="M652.75,1682.34L612.1,1645.16L549.04,1687.83L599.68,1766.39L634.63,1783.87L649.96,1734.76L652.75,1682.34z"
        />
        <path
          id="MW-CR"
          title="Chiradzulu"
          className="land"
          d="M660.23,1599.48L611.14,1561.92L612.1,1645.16L652.75,1682.34L660.23,1599.48z"
        />
        <path
          id="MW-CK"
          title="Chikwawa"
          className="land"
          d="M513.93,1632.61L413.01,1611.54L386.01,1652.59L428.44,1696.35L433.86,1751.56L458.2,1758.5L547.8,1861.82L590.88,1806.09L599.68,1766.39L549.04,1687.83L543.09,1636.59L513.93,1632.61z"
        />
        <path
          id="MW-BA"
          title="Balaka"
          className="land"
          d="M643.98,1361.41L573.48,1361.33L537.02,1504.49L589.41,1507.27L618.62,1483.04L651.49,1412L643.98,1361.41z"
        />
        <path
          id="MW-SA"
          title="Salima"
          className="land"
          d="M542.18,1044.92L489.55,1040.23L472.57,1016.24L409.41,1016.29L361.35,1038.13L357.17,1041.95L358.36,1072.34L398.27,1085.55L404.23,1103.46L390.28,1112.21L374.42,1134.28L398.5,1130.17L451.18,1197.42L510.51,1188.17L542.18,1044.92z"
        />
        <path
          id="MW-NI"
          title="Ntchisi"
          className="land"
          d="M361.35,1038.13L353.81,957.37L342.48,940.64L318.97,955.08L308.67,919.37L233.9,973.07L268.52,1042.03L357.17,1041.95L361.35,1038.13z"
        />
        <path
          id="MW-NU"
          title="Ntcheu"
          className="land"
          d="M573.48,1361.33L525.61,1310.04L513.54,1245.67L460.79,1277.91L422.47,1273.89L456.56,1323.46L466.7,1475.34L537.02,1504.49L573.48,1361.33z"
        />
        <path
          id="MW-MC"
          title="Mchinji"
          className="land"
          d="M163.21,1073.45L128.62,1039.5L116.59,999.97L57.25,1002.95L0.2,1073.4L23.67,1078.19L34.44,1094.57L21.98,1111.41L49.49,1122.47L80.61,1181.12L115.39,1149.1L169.08,1227.11L164.67,1162.53L143.3,1125.36L163.21,1073.45z"
        />
        <path
          id="MW-MG"
          title="Mangochi"
          className="land"
          d="M761.72,1307.01L596.7,1092.81L542.18,1044.92L510.51,1188.17L513.54,1245.67L525.61,1310.04L573.48,1361.33L643.98,1361.41L647.17,1341.89L688.55,1362.37L714.8,1323.03L761.72,1307.01z"
        />
        <path
          id="MW-LI"
          title="Lilongwe"
          className="land"
          d="M390.28,1112.21L340.52,1123.19L275.36,1106.53L266.25,1081.44L210.27,1092.49L185.22,1035.8L163.21,1073.45L143.3,1125.36L164.67,1162.53L169.08,1227.11L233.78,1311.79L258.02,1266.85L311.82,1242.52L398.5,1130.17L374.42,1134.28L390.28,1112.21z"
        />
        <path
          id="MW-DO"
          title="Dowa"
          className="land"
          d="M357.17,1041.95L268.52,1042.03L233.9,973.07L210.92,1000.51L194.98,996.61L185.22,1035.8L210.27,1092.49L266.25,1081.44L275.36,1106.53L340.52,1123.19L390.28,1112.21L404.23,1103.46L398.27,1085.55L358.36,1072.34L357.17,1041.95z"
        />
        <path
          id="MW-DE"
          title="Dedza"
          className="land"
          d="M510.51,1188.17L451.18,1197.42L398.5,1130.17L311.82,1242.52L258.02,1266.85L233.78,1311.79L244.68,1323.35L259.04,1293.34L279.34,1304.37L422.47,1273.89L460.79,1277.91L513.54,1245.67L510.51,1188.17z"
        />
        <path
          id="MW-MA"
          title="Machinga"
          className="land"
          d="M771.14,1478.23L799.8,1395.01L785.67,1334.46L761.72,1307.01L714.8,1323.03L688.55,1362.37L647.17,1341.89L643.98,1361.41L651.49,1412L618.62,1483.04L771.14,1478.23z"
        />
        <path
          id="MW-RU"
          title="Rumphi"
          className="land"
          d="M482,302.01L355.89,302.04L315.5,269.15L247.51,299.65L204.7,349.06L140.43,373.4L152.7,400.98L188.36,410.33L191.87,434.7L214.76,412.55L260.15,428.65L265.83,414.01L309.32,413.58L314.58,398.12L342.25,426.69L386.44,398.61L481.25,398.62L495.41,344.84L482,302.01z"
        />
        <path
          id="MW-NK"
          title="Nkhotakota"
          className="land"
          d="M416.31,711.86L289.31,696.25L270.97,728.99L309.59,776.24L354.27,786.03L287.43,839.82L285.61,873.07L311.24,874.63L328.49,896.56L308.67,919.37L318.97,955.08L342.48,940.64L353.81,957.37L361.35,1038.13L409.41,1016.29L472.57,1016.24L450.7,834.19L416.31,711.86z"
        />
        <path
          id="MW-NA"
          title="Nkhata Bay"
          className="land"
          d="M481.25,398.62L386.44,398.61L342.25,426.69L333.23,469.44L353.55,519.55L294.3,542.63L271.51,576.66L284,658.08L257.79,683.36L289.31,696.25L416.31,711.86L467.88,617.16L480.98,553.29L567.12,552.09L547.8,502.03L517.83,494.38L482.05,437.48L481.25,398.62z"
        />
        <path
          id="MW-MZ"
          title="Mzimba"
          className="land"
          d="M342.25,426.69L314.58,398.12L309.32,413.58L265.83,414.01L260.15,428.65L214.76,412.55L191.87,434.7L188.36,410.33L152.7,400.98L177.45,448.85L137.68,512.42L133.3,549.63L154.14,557.16L158.41,602.31L143.23,695.28L167.19,743.02L207.9,754.77L225.64,761.11L222.22,823.04L287.43,839.82L354.27,786.03L309.59,776.24L270.97,728.99L289.31,696.25L257.79,683.36L284,658.08L271.51,576.66L294.3,542.63L353.55,519.55L333.23,469.44L342.25,426.69z"
        />
        <path
          id="MW-KS"
          title="Kasungu"
          className="land"
          d="M287.43,839.82L222.22,823.04L225.64,761.11L207.9,754.77L123.55,817.09L86.8,815.23L66.08,855.13L84.01,892.56L80.2,965.05L57.25,1002.95L116.59,999.97L128.62,1039.5L163.21,1073.45L185.22,1035.8L194.98,996.61L210.92,1000.51L233.9,973.07L308.67,919.37L328.49,896.56L311.24,874.63L285.61,873.07L287.43,839.82z"
        />
        <path
          id="MW-CT1"
          title="Chitipa"
          className="land"
          d="M74.39,0L61.06,6.69L77.41,62.07L100.57,70.56L105,52.02L124.09,55.5L165.7,113.06L155.95,171.47L212.7,213.59L247.51,299.65L315.5,269.15L254.3,172.38L199.78,146.67L253.57,100.58L241.64,57.66L189.35,60.33L155.01,27.86L123.48,32.58L74.39,0z"
        />
        <path
          id="MW-CT2"
          title="Chitipa"
          className="land"
          d="M482,302.01L458.2,157.64L406.99,84.86L331.42,24.15L306.44,84.55L262.55,50.93L241.64,57.66L253.57,100.58L199.78,146.67L254.3,172.38L315.5,269.15L355.89,302.04L482,302.01z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Malawi))
