import React from 'react'
import ImageWithDimensions from './ImageWithDimensions'
import { baseUrl } from '../lib/_processUtil'
import { createBorderRadius } from '../lib/methodsUtil'

const ImageChoiceButton = ({
  value,
  index,
  altName = '',
  isSelected = false,
  imageText,
  onClick,
  srcUrl,
  style = {},
  areSmallImages = false,
  isFirstPage = false
}) => (
  <div
    className={areSmallImages ? 'image-choice-button-box-small' : 'image-choice-button-box'}
    style={style}
    key={value}>
    <span>{imageText}</span>
    <ImageWithDimensions
      alt={`${value} ${altName} select`}
      className={`${
        areSmallImages ? 'image-choice-button-image-small' : 'image-choice-button-image'
      } ${isFirstPage ? 'clickable' : isSelected ? 'selected-image' : 'not-selected-image'}`}
      style={createBorderRadius(0.4, index % 2)}
      onClick={onClick}
      src={`${baseUrl}/${srcUrl}`}
    />
  </div>
)

export default ImageChoiceButton
