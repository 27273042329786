import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 737

const cities = {
  Tehran: { x: 312, y: 220 }
}

const Iran = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map iran">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="IR-32"
          title="Alborz"
          className="land"
          d="M282.23,181.75L266.45,182.9L274.96,189.67L260.83,215.23L277.89,221.13L282.13,218.36L305.3,192.16L282.23,181.75z"
        />
        <path
          id="IR-15"
          title="Kerman"
          className="land"
          d="M574.19,416.2L524.35,396.07L487.94,419.17L484.94,441.79L438.03,449.21L431.65,462.85L440.62,502.62L432.87,505.24L461.91,545.37L480.28,554.32L498.69,545.94L500.49,573.81L512.03,584.46L538.77,577.2L556.48,634.91L589.15,647.03L583.95,673.39L616.87,682.97L613.22,626.36L624.34,575.43L617.91,568.05L637.78,556.73L635.06,509.77L643.95,466.17L621.43,437.57L574.19,416.2z"
        />
        <path
          id="IR-13"
          title="Sistan and Baluchestan"
          className="land"
          d="M722.66,726.71L740.15,673.02L764.45,657.41L794.46,654.48L799.45,643.03L799.41,627.09L777.9,624.62L778.69,578.67L742.63,564.69L699.11,502.13L738.87,454.56L730.74,428.3L710.77,425.37L707.02,475.74L676.4,456.7L643.95,466.17L635.06,509.77L637.78,556.73L617.91,568.05L624.34,575.43L613.22,626.36L616.87,682.97L632.98,709.67L683.46,716.12L686.88,708.93L722.66,726.71z"
        />
        <path
          id="IR-31"
          title="North Khorasan"
          className="land"
          d="M560.14,98.26L547.26,79.58L504.95,89.91L510.61,113.4L492.53,128.03L501.76,138.49L506.52,159.78L525.91,165.03L538.44,148.1L588.75,163.23L581.41,143.31L589.67,123.13L562.3,110.29L570.04,99.53L560.14,98.26z"
        />
        <path
          id="IR-30"
          title="Razavi Khorasan"
          className="land"
          d="M572.75,101.14L570.04,99.53L562.3,110.29L589.67,123.13L581.41,143.31L588.75,163.23L538.44,148.1L525.91,165.03L529.47,199.42L542.53,217.05L506.77,253.88L550.68,238.9L567.4,249.42L539.74,292.34L541.98,315.21L552.18,319.28L562.78,316.81L572.01,349.42L587.18,361.69L615.58,325.19L606.37,316.14L607.6,292.06L643.52,285.55L655.26,296.05L684.34,295.43L701.03,283.49L693.11,273.55L708.29,258.5L717.61,221.25L712.46,165.35L678.27,165.31L664.86,145.15L641.72,135.69L635.52,118.83L572.75,101.14z"
        />
        <path
          id="IR-29"
          title="South Khorasan"
          className="land"
          d="M655.26,296.05L643.52,285.55L607.6,292.06L606.37,316.14L615.58,325.19L587.18,361.69L590.27,383.89L574.19,416.2L621.43,437.57L643.95,466.17L676.4,456.7L707.02,475.74L710.77,425.37L698.17,423.15L687.43,346.38L702.29,323.74L685.31,317.55L684.34,295.43L655.26,296.05z"
        />
        <path
          id="IR-16"
          title="Kordestan"
          className="land"
          d="M175.33,213.99L161.39,202.46L160.37,177.38L138.25,172L130.31,180.58L84.88,175.07L62.25,197.85L96.22,207.51L81.26,219.69L88.15,234.65L108.94,256.16L120.2,261.91L142.75,240.49L159.8,251.59L172.25,244.16L157.64,219.25L175.33,213.99z"
        />
        <path
          id="IR-19"
          title="Gilan"
          className="land"
          d="M202.26,71.24L191.41,73.27L197.28,78.57L184.9,100.47L202.6,135.94L217.68,156.2L232.78,167.67L261.62,163.59L272.9,147.06L257.1,126.2L210.18,111.05L202.26,71.24z"
        />
        <path
          id="IR-17"
          title="Kermanshah"
          className="land"
          d="M108.94,256.16L88.15,234.65L88.89,244.07L69.12,258.89L70.56,271.79L58.75,277.18L64.81,284.98L57.23,300.81L71.52,317.82L75.56,298.79L118.47,309.75L140.49,297.59L153.26,281.94L165.55,277.85L164.69,268.93L148.96,265.73L159.8,251.59L142.75,240.49L120.2,261.91L108.94,256.16z"
        />
        <path
          id="IR-01"
          title="East Azarbaijan"
          className="land"
          d="M117.81,45.81L128.34,40.5L125.02,31.13L105.03,47.38L61.97,42.1L45.15,80.08L56.25,129.45L95.11,152.84L109.47,143.43L118.6,159.7L132.5,159.11L148.75,138.44L184.86,134.84L163.63,116.97L154.04,81.74L134.07,80.85L149.87,64.67L146.27,36.69L124.35,57.72L107.61,58.12L117.81,45.81z"
        />
        <path
          id="IR-02"
          title="West Azarbaijan"
          className="land"
          d="M23.42,0L16.04,18.93L0.55,21.1L18.98,76.31L8.32,99.58L24.68,109.08L34.44,157.29L51.36,177.4L54.73,198.43L62.25,197.85L84.88,175.07L130.31,180.58L138.25,172L132.5,159.11L118.6,159.7L109.47,143.43L95.11,152.84L56.25,129.45L45.15,80.08L61.97,42.1L33.43,6.8L23.42,0z"
        />
        <path
          id="IR-28"
          title="Qazvin"
          className="land"
          d="M232.78,167.67L217.68,156.2L208.09,164.29L224.25,192.52L190.8,205.89L199.28,216.77L225.76,226.88L260.83,215.23L274.96,189.67L266.45,182.9L282.23,181.75L261.62,163.59L232.78,167.67z"
        />
        <path
          id="IR-11"
          title="Zanjan"
          className="land"
          d="M202.6,135.94L184.86,134.84L148.75,138.44L132.5,159.11L138.25,172L160.37,177.38L161.39,202.46L175.33,213.99L199.28,216.77L190.8,205.89L224.25,192.52L208.09,164.29L217.68,156.2L202.6,135.94z"
        />
        <path
          id="IR-24"
          title="Hamadan"
          className="land"
          d="M175.33,213.99L157.64,219.25L172.25,244.16L159.8,251.59L148.96,265.73L164.69,268.93L165.55,277.85L153.26,281.94L206.27,300.03L203.12,268.69L211.69,269.26L213.3,253.71L231.01,260.88L217.67,246.42L225.76,226.88L199.28,216.77L175.33,213.99z"
        />
        <path
          id="IR-26"
          title="Qom"
          className="land"
          d="M327.12,255.42L281.56,238.04L277.78,253.37L257.75,258.26L251.47,270.49L267.3,288.22L291.86,294.18L293.11,278.49L323.24,272.26L327.12,255.42z"
        />
        <path
          id="IR-22"
          title="Markazi"
          className="land"
          d="M277.89,221.13L260.83,215.23L225.76,226.88L217.67,246.42L231.01,260.88L213.3,253.71L211.69,269.26L203.12,268.69L206.27,300.03L206.41,313.64L226.72,305.16L249.05,324.51L286.3,308.74L291.86,294.18L267.3,288.22L251.47,270.49L257.75,258.26L277.78,253.37L281.56,238.04L282.13,218.36L277.89,221.13z"
        />
        <path
          id="IR-03"
          title="Ardebil"
          className="land"
          d="M164.21,3.17L125.02,31.13L128.34,40.5L117.81,45.81L107.61,58.12L124.35,57.72L146.27,36.69L149.87,64.67L134.07,80.85L154.04,81.74L163.63,116.97L184.86,134.84L202.6,135.94L184.9,100.47L197.28,78.57L191.41,73.27L165.68,49.13L178.16,40.89L170.37,26.73L180.03,20.84L164.21,3.17z"
        />
        <path
          id="IR-23"
          title="Hormozgan"
          className="land"
          d="M458.78,689.69L458.34,688.39L457.02,688.91L458.78,689.69zM437.98,688.07L437.7,686.84L436.18,687.42L437.98,688.07zM436.86,671.8L437.74,670.01L436.05,668.59L436.86,671.8zM417.01,660.59L415.8,657.72L410.98,658.62L417.01,660.59zM493.45,654.56L493.64,651.97L491.94,654.69L493.45,654.56zM400.87,652.46L399.62,651.43L398.46,651.97L400.87,652.46zM389.36,646.22L379.71,644.14L382.76,646.39L389.36,646.22zM513.77,644.57L514.81,642.04L511.41,644.77L513.77,644.57zM505.15,641.4L487.99,639.2L468.37,657.52L505.15,641.4zM518.03,634.9L516.94,632.27L516.07,634.51L518.03,634.9zM480.28,554.32L461.91,545.37L476.75,572.24L478.69,598.62L454.06,614.35L417.3,616.04L408.99,630.56L372.3,619.23L360.18,622.02L403.23,650.62L447.85,660.5L503.17,629.57L531.61,630.56L552.47,693.4L632.98,709.67L616.87,682.97L583.95,673.39L589.15,647.03L556.48,634.91L538.77,577.2L512.03,584.46L500.49,573.81L498.69,545.94L480.28,554.32z"
        />
        <path
          id="IR-05"
          title="Ilam"
          className="land"
          d="M140.49,297.59L118.47,309.75L75.56,298.79L71.52,317.82L89.4,336.37L86.98,351.52L138.71,375.97L148.54,393.41L161.77,368.94L117.31,321.77L140.62,306.79L140.49,297.59z"
        />
        <path
          id="IR-20"
          title="Lorestan"
          className="land"
          d="M153.26,281.94L140.49,297.59L140.62,306.79L117.31,321.77L161.77,368.94L171.95,348.97L223.24,359.36L226.29,358.72L249.05,324.51L226.72,305.16L206.41,313.64L206.27,300.03L153.26,281.94z"
        />
        <path
          id="IR-10"
          title="Khuzestan"
          className="land"
          d="M223.24,359.36L171.95,348.97L161.77,368.94L148.54,393.41L159.21,409.02L152.39,447.42L166.47,447.69L166.53,473.05L185.24,495.86L203.96,493.32L201.76,478.74L204.77,475.23L209.02,474.56L205.22,472.29L206.7,470.56L216.24,471.01L215.9,478.7L210.05,475.74L204.2,477.05L214.17,484.6L216.66,482.64L217.65,487.83L227.39,487.85L229.57,494.24L251.08,485.39L266.82,484.84L265.4,461.88L246.83,452.44L246.27,435.47L259.67,423.48L263.52,415.51L238.03,373.63L226.29,358.72L223.24,359.36z"
        />
        <path
          id="IR-08"
          title="Chahar Mahall and Bakhtiari"
          className="land"
          d="M303.39,439.21L302.83,396.34L284.41,367.71L238.03,373.63L263.52,415.51L259.67,423.48L269.89,428.22L288.79,442.01L303.39,439.21z"
        />
        <path
          id="IR-25"
          title="Yazd"
          className="land"
          d="M552.18,319.28L541.98,315.21L539.74,292.34L567.4,249.42L550.68,238.9L506.77,253.88L491.84,279.05L472.1,282.33L471.46,319.73L448.83,350.71L368.68,373.68L368.14,418.14L390.32,434.48L417.99,496.09L432.87,505.24L440.62,502.62L431.65,462.85L438.03,449.21L484.94,441.79L487.94,419.17L524.35,396.07L574.19,416.2L590.27,383.89L587.18,361.69L572.01,349.42L562.78,316.81L552.18,319.28z"
        />
        <path
          id="IR-07"
          title="Tehran"
          className="land"
          d="M336.49,210.06L305.3,192.16L282.13,218.36L281.56,238.04L327.12,255.42L326.61,220.14L360.02,232.05L375.47,222.26L374.95,206.79L348.54,199.35L336.49,210.06z"
        />
        <path
          id="IR-12"
          title="Semnan"
          className="land"
          d="M501.76,138.49L492.53,128.03L477.46,158.28L420,173.18L396.06,201.1L374.95,206.79L375.47,222.26L360.02,232.05L326.61,220.14L327.12,255.42L323.24,272.26L335.33,277.29L472.1,282.33L491.84,279.05L506.77,253.88L542.53,217.05L529.47,199.42L525.91,165.03L506.52,159.78L501.76,138.49z"
        />
        <path
          id="IR-21"
          title="Mazandaran"
          className="land"
          d="M402.79,156.03L415.29,148.95L328.22,168.12L272.9,147.06L261.62,163.59L282.23,181.75L305.3,192.16L336.49,210.06L348.54,199.35L374.95,206.79L396.06,201.1L420,173.18L402.79,156.03z"
        />
        <path
          id="IR-27"
          title="Golestan"
          className="land"
          d="M497.14,90.7L474.09,90.17L442.16,123.61L411.44,128.64L416.39,154.5L402.79,156.03L420,173.18L477.46,158.28L492.53,128.03L510.61,113.4L504.95,89.91L497.14,90.7z"
        />
        <path
          id="IR-14"
          title="Fars"
          className="land"
          d="M350.82,422.26L337.9,412.53L317.52,420.53L326.67,431.64L317.1,458.18L317.06,468.05L296.65,463.23L297.93,480.91L273.95,488.39L304.86,517.88L372.3,619.23L408.99,630.56L417.3,616.04L454.06,614.35L478.69,598.62L476.75,572.24L461.91,545.37L432.87,505.24L417.99,496.09L390.32,434.48L368.14,418.14L350.82,422.26z"
        />
        <path
          id="IR-04"
          title="Esfahan"
          className="land"
          d="M335.33,277.29L323.24,272.26L293.11,278.49L291.86,294.18L286.3,308.74L249.05,324.51L226.29,358.72L238.03,373.63L284.41,367.71L302.83,396.34L303.39,439.21L317.1,458.18L326.67,431.64L317.52,420.53L337.9,412.53L350.82,422.26L368.14,418.14L368.68,373.68L448.83,350.71L471.46,319.73L472.1,282.33L335.33,277.29z"
        />
        <path
          id="IR-06"
          title="Bushehr"
          className="land"
          d="M262.53,533.11L262.87,530.74L260.74,530.25L262.53,533.11zM304.86,517.88L273.95,488.39L266.82,484.84L251.08,485.39L276.18,521.67L275.71,536.22L287.58,540.44L282.39,546.18L307.09,593.47L351.08,607.5L360.18,622.02L372.3,619.23L304.86,517.88z"
        />
        <path
          id="IR-18"
          title="Kohgiluyeh and Buyer Ahmad"
          className="land"
          d="M269.89,428.22L259.67,423.48L246.27,435.47L246.83,452.44L265.4,461.88L266.82,484.84L273.95,488.39L297.93,480.91L296.65,463.23L317.06,468.05L317.1,458.18L303.39,439.21L288.79,442.01L269.89,428.22z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Iran))
