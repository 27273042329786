import React, { useState, useEffect } from 'react'

const ImageWithDimensions = ({ src, alt, onClick, className, style, srcSet }) => {
  // This solves the "Image elements do not have explicit width and height" lighthouse warning
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height })
    }
    img.src = src
  }, [src])

  return (
    <img
      src={src}
      alt={alt}
      onClick={onClick}
      className={className}
      style={style}
      srcSet={srcSet}
      // To avoid "Largest Contentful Paint image was lazily loaded" lighthouse warning
      loading={src.indexOf('microphone.webp') > -1 ? null : 'lazy'}
      width={dimensions.width}
      height={dimensions.height}
    />
  )
}

export default ImageWithDimensions
