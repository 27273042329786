import React from 'react'
import {
  FadeIn,
  FadeOut,
  FlipInY,
  FlipOutY,
  BounceOut,
  Bounce,
  BounceIn
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import LearnCircle from '../../../../components/LearnCircle'
import { baseUrl } from '../../../../lib/_processUtil'

const LearnEasyMultiplicationsPage = () => (
  <LearnMathematicsPage
    pageName="learn_easy_multiplications"
    frReadSpeed="1"
    sentencesNumberPlusRedirect={23}
    startTimesOf={{
      2: 7000,
      3: 17000,
      4: 27000,
      5: 37000,
      6: 47500,
      7: 58000,
      8: 69000,
      9: 80000,
      10: 91000,
      11: 102500,
      12: 114000,
      13: 125500,
      14: 137000,
      15: 149000,
      16: 161000,
      17: 172000,
      18: 183500,
      19: 195000,
      20: 206500,
      21: 217000,
      22: 228000,
      23: 233000
    }}
    animations={{
      1: (
        <FlipInY className="learn-flex-center-center" duration={2}>
          <FlipOutY delay={6}>
            <img className="width-100" src={`${baseUrl}/images/learn/times-tables.webp`} />
          </FlipOutY>
        </FlipInY>
      ),
      // times 1 table
      2: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="1"
            timeColorOne={0.5}
            timeColorTwo={1.8}
            timeColorThree={3.6}
            timeColorFour={5.4}
            timeColorFive={7.2}
            timeOne={1.25}
            resultTwo="2"
            timeTwo={2.55}
            resultThree="3"
            timeThree={4.35}
            resultFour="4"
            timeFour={6.15}
            resultFive="5"
            timeFive={7.95}
          />
        </FadeIn>
      ),
      3: (
        <FadeOut className="learn-flex-center-center" delay={9.5} duration={0.5}>
          <LearnCircle
            number="1"
            resultTwo="2"
            resultThree="3"
            resultFour="4"
            resultFive="5"
            timeColorSix={0.5}
            timeColorSeven={1.8}
            timeColorEight={3.6}
            timeColorNine={5.4}
            timeColorTen={7.2}
            resultSix="6"
            timeSix={1.25}
            resultSeven="7"
            timeSeven={2.55}
            resultEight="8"
            timeEight={4.35}
            resultNine="9"
            timeNine={6.15}
            resultTen="10"
            timeTen={7.95}
          />
        </FadeOut>
      ),
      // times 2 table
      4: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="2"
            timeColorOne={0.5}
            timeColorTwo={1.8}
            timeColorThree={3.6}
            timeColorFour={5.4}
            timeColorFive={7.2}
            timeOne={1.25}
            resultTwo="4"
            timeTwo={2.55}
            resultThree="6"
            timeThree={4.35}
            resultFour="8"
            timeFour={6.15}
            resultFive="10"
            timeFive={7.95}
          />
        </FadeIn>
      ),
      5: (
        <FadeOut className="learn-flex-center-center" delay={10} duration={0.5}>
          <LearnCircle
            number="2"
            resultTwo="4"
            resultThree="6"
            resultFour="8"
            resultFive="10"
            timeColorSix={0.5}
            timeColorSeven={1.9}
            timeColorEight={3.8}
            timeColorNine={5.7}
            timeColorTen={7.6}
            resultSix="12"
            timeSix={1.35}
            resultSeven="14"
            timeSeven={2.65}
            resultEight="16"
            timeEight={4.55}
            resultNine="18"
            timeNine={6.45}
            resultTen="20"
            timeTen={8.35}
          />
        </FadeOut>
      ),
      // times 3 table
      6: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="3"
            timeColorOne={0.5}
            timeColorTwo={1.9}
            timeColorThree={3.8}
            timeColorFour={5.7}
            timeColorFive={7.6}
            timeOne={1.35}
            resultTwo="6"
            timeTwo={2.65}
            resultThree="9"
            timeThree={4.55}
            resultFour="12"
            timeFour={6.45}
            resultFive="15"
            timeFive={8.35}
          />
        </FadeIn>
      ),
      7: (
        <FadeOut className="learn-flex-center-center" delay={10.5} duration={0.5}>
          <LearnCircle
            number="3"
            resultTwo="6"
            resultThree="9"
            resultFour="12"
            resultFive="15"
            timeColorSix={0.5}
            timeColorSeven={2}
            timeColorEight={4}
            timeColorNine={6}
            timeColorTen={8}
            resultSix="18"
            timeSix={1.25}
            resultSeven="21"
            timeSeven={2.75}
            resultEight="24"
            timeEight={4.75}
            resultNine="27"
            timeNine={6.75}
            resultTen="30"
            timeTen={8.75}
          />
        </FadeOut>
      ),
      // times 4 table
      8: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="4"
            timeColorOne={0.5}
            timeColorTwo={2}
            timeColorThree={4}
            timeColorFour={6}
            timeColorFive={8}
            timeOne={1.25}
            resultTwo="8"
            timeTwo={2.75}
            resultThree="12"
            timeThree={4.75}
            resultFour="16"
            timeFour={6.75}
            resultFive="20"
            timeFive={8.75}
          />
        </FadeIn>
      ),
      9: (
        <FadeOut className="learn-flex-center-center" delay={10.5} duration={0.5}>
          <LearnCircle
            number="4"
            resultTwo="8"
            resultThree="12"
            resultFour="16"
            resultFive="20"
            timeColorSix={0.5}
            timeColorSeven={2}
            timeColorEight={4}
            timeColorNine={6}
            timeColorTen={8}
            resultSix="24"
            timeSix={1.25}
            resultSeven="28"
            timeSeven={2.75}
            resultEight="32"
            timeEight={4.75}
            resultNine="36"
            timeNine={6.75}
            resultTen="40"
            timeTen={8.75}
          />
        </FadeOut>
      ),
      // times 5 table
      10: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="5"
            timeColorOne={0.5}
            timeColorTwo={2.1}
            timeColorThree={4.2}
            timeColorFour={6.3}
            timeColorFive={8.4}
            timeOne={1.25}
            resultTwo="10"
            timeTwo={2.85}
            resultThree="15"
            timeThree={4.95}
            resultFour="20"
            timeFour={7.05}
            resultFive="25"
            timeFive={9.15}
          />
        </FadeIn>
      ),
      11: (
        <FadeOut className="learn-flex-center-center" delay={11} duration={0.5}>
          <LearnCircle
            number="5"
            resultTwo="10"
            resultThree="15"
            resultFour="20"
            resultFive="25"
            timeColorSix={0.5}
            timeColorSeven={2.1}
            timeColorEight={4.2}
            timeColorNine={6.3}
            timeColorTen={8.4}
            resultSix="30"
            timeSix={1.25}
            resultSeven="35"
            timeSeven={2.85}
            resultEight="40"
            timeEight={4.95}
            resultNine="45"
            timeNine={7.05}
            resultTen="50"
            timeTen={9.15}
          />
        </FadeOut>
      ),
      // times 6 table
      12: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="6"
            timeColorOne={0.5}
            timeColorTwo={2.1}
            timeColorThree={4.2}
            timeColorFour={6.3}
            timeColorFive={8.4}
            timeOne={1.25}
            resultTwo="12"
            timeTwo={2.85}
            resultThree="18"
            timeThree={4.95}
            resultFour="24"
            timeFour={7.05}
            resultFive="30"
            timeFive={9.15}
          />
        </FadeIn>
      ),
      13: (
        <FadeOut className="learn-flex-center-center" delay={11} duration={0.5}>
          <LearnCircle
            number="6"
            resultTwo="12"
            resultThree="18"
            resultFour="24"
            resultFive="30"
            timeColorSix={0.5}
            timeColorSeven={2.1}
            timeColorEight={4.2}
            timeColorNine={6.3}
            timeColorTen={8.4}
            resultSix="36"
            timeSix={1.25}
            resultSeven="42"
            timeSeven={2.85}
            resultEight="48"
            timeEight={4.95}
            resultNine="54"
            timeNine={7.05}
            resultTen="60"
            timeTen={9.15}
          />
        </FadeOut>
      ),
      // times 7 table
      14: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="7"
            timeColorOne={0.5}
            timeColorTwo={2.2}
            timeColorThree={4.4}
            timeColorFour={6.6}
            timeColorFive={8.8}
            timeOne={1.25}
            resultTwo="14"
            timeTwo={2.95}
            resultThree="21"
            timeThree={5.15}
            resultFour="28"
            timeFour={7.35}
            resultFive="35"
            timeFive={9.55}
          />
        </FadeIn>
      ),
      15: (
        <FadeOut className="learn-flex-center-center" delay={11.5} duration={0.5}>
          <LearnCircle
            number="7"
            resultTwo="14"
            resultThree="21"
            resultFour="28"
            resultFive="35"
            timeColorSix={0.5}
            timeColorSeven={2.2}
            timeColorEight={4.4}
            timeColorNine={6.6}
            timeColorTen={8.8}
            resultSix="42"
            timeSix={1.25}
            resultSeven="49"
            timeSeven={2.95}
            resultEight="56"
            timeEight={5.15}
            resultNine="63"
            timeNine={7.35}
            resultTen="70"
            timeTen={9.55}
          />
        </FadeOut>
      ),
      // times 8 table
      16: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="8"
            timeColorOne={0.5}
            timeColorTwo={2}
            timeColorThree={4}
            timeColorFour={6}
            timeColorFive={8}
            timeOne={1.25}
            resultTwo="16"
            timeTwo={2.75}
            resultThree="24"
            timeThree={4.75}
            resultFour="32"
            timeFour={6.75}
            resultFive="40"
            timeFive={8.75}
          />
        </FadeIn>
      ),
      17: (
        <FadeOut className="learn-flex-center-center" delay={11} duration={0.5}>
          <LearnCircle
            number="8"
            resultTwo="16"
            resultThree="24"
            resultFour="32"
            resultFive="40"
            timeColorSix={0.5}
            timeColorSeven={2}
            timeColorEight={4}
            timeColorNine={6}
            timeColorTen={8}
            resultSix="48"
            timeSix={1.25}
            resultSeven="56"
            timeSeven={2.75}
            resultEight="64"
            timeEight={4.75}
            resultNine="72"
            timeNine={6.75}
            resultTen="80"
            timeTen={8.75}
          />
        </FadeOut>
      ),
      // times 9 table
      18: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="9"
            timeColorOne={0.5}
            timeColorTwo={2.1}
            timeColorThree={4.2}
            timeColorFour={6.3}
            timeColorFive={8.4}
            timeOne={1.25}
            resultTwo="18"
            timeTwo={2.85}
            resultThree="27"
            timeThree={4.95}
            resultFour="36"
            timeFour={7.05}
            resultFive="45"
            timeFive={9.15}
          />
        </FadeIn>
      ),
      19: (
        <FadeOut className="learn-flex-center-center" delay={11} duration={0.5}>
          <LearnCircle
            number="9"
            resultTwo="18"
            resultThree="27"
            resultFour="36"
            resultFive="45"
            timeColorSix={0.5}
            timeColorSeven={2}
            timeColorEight={4}
            timeColorNine={6}
            timeColorTen={8}
            resultSix="54"
            timeSix={1.25}
            resultSeven="63"
            timeSeven={2.75}
            resultEight="72"
            timeEight={4.75}
            resultNine="81"
            timeNine={6.75}
            resultTen="90"
            timeTen={8.75}
          />
        </FadeOut>
      ),
      // times 10 table
      20: (
        <FadeIn className="learn-flex-center-center" duration={0.25}>
          <LearnCircle
            number="10"
            timeColorOne={0.5}
            timeColorTwo={1.8}
            timeColorThree={3.6}
            timeColorFour={5.4}
            timeColorFive={7.2}
            timeOne={1.25}
            resultTwo="20"
            timeTwo={2.55}
            resultThree="30"
            timeThree={4.35}
            resultFour="40"
            timeFour={6.15}
            resultFive="50"
            timeFive={7.95}
          />
        </FadeIn>
      ),
      21: (
        <FadeOut className="learn-flex-center-center" delay={10} duration={0.5}>
          <LearnCircle
            number="10"
            resultTwo="20"
            resultThree="30"
            resultFour="40"
            resultFive="50"
            timeColorSix={0.5}
            timeColorSeven={1.9}
            timeColorEight={3.8}
            timeColorNine={5.7}
            timeColorTen={7.6}
            resultSix="60"
            timeSix={1.23}
            resultSeven="70"
            timeSeven={2.65}
            resultEight="80"
            timeEight={4.55}
            resultNine="90"
            timeNine={6.45}
            resultTen="100"
            timeTen={8.35}
          />
        </FadeOut>
      ),
      22: (
        <div className="learn-flex-center-center">
          <BounceIn duration={0.5}>
            <Bounce delay={1} duration={3}>
              <BounceOut delay={4} duration={0.5}>
                <img src={`${baseUrl}/images/learn/learn-easy-multiplications-end.webp`} />
              </BounceOut>
            </Bounce>
          </BounceIn>
        </div>
      )
    }}
  />
)

export default LearnEasyMultiplicationsPage
