import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'
import SocialSharing from '../components/SocialSharing'

const navigationCommands = ['menu', 'about', 'questions']

const TemporarilyUnlockPage = () => (
  <TopicNavigationPage
    navigationCommands={navigationCommands}
    pageName="temporarily_unlock_page"
    topics={['topic_about', 'topic_questions']}
    subTopics={navigationCommands}
    borderRadius={0.45}
    SEODescription="E=MC² - Embrace More Challenge² - Unlock capitals game mode and share your geography learning experience via Mini-Einstein and its speech recognition tech">
    {() => <SocialSharing />}
  </TopicNavigationPage>
)

export default TemporarilyUnlockPage
