import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 790

const cities = {
  Maseru: { x: 167, y: 266 }
}

const Lesotho = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map lesotho">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="LS-E"
          title="Mafeteng"
          className="land"
          d="M386.37,420.32L355.25,417.56L368.14,494.36L418.66,490.64L386.37,420.32zM351.4,494.62L305.73,471.41L243.14,460.64L202.48,417.46L139.66,404.35L113.8,352.43L94.71,348.43L0.51,388.45L52.15,500.96L92.02,479.81L124.34,478.86L122.65,531.7L148.81,543.69L177.04,509.99L220.25,521.44L305.27,498.08L351.4,494.62z"
        />
        <path
          id="LS-K"
          title="Thaba-Tseka"
          className="land"
          d="M511.12,249.49L506.77,280.43L463.39,263.51L438,206.55L412.91,196.69L419.87,240.19L392.74,277.24L364.87,297.76L386.37,420.32L418.66,490.64L503.81,445.68L569.99,473.93L601.97,461.5L646.87,473.71L663.31,431.46L693.12,436.8L708.86,404.15L747.58,383.9L746.45,351.53L704.79,368.94L662.44,337.46L586.19,337.34L590.95,285.04L511.12,249.49z"
        />
        <path
          id="LS-H"
          title="Qacha's Nek"
          className="land"
          d="M693.12,436.8L663.31,431.46L646.87,473.71L601.97,461.5L569.99,473.93L503.81,445.68L506.47,499.97L449.37,541.37L412.98,531.35L400.51,583.86L442.97,596.94L498.66,575.36L536.72,581L609.44,556.17L703.24,500.28L693.12,436.8z"
        />
        <path
          id="LS-J"
          title="Mokhotlong"
          className="land"
          d="M746.45,351.53L783.73,317.22L799.49,281.09L758.52,185.58L724.42,177.7L649.31,117.38L598.85,61.06L586.76,109.92L573.95,178.58L516.19,226.31L511.12,249.49L590.95,285.04L586.19,337.34L662.44,337.46L704.79,368.94L746.45,351.53z"
        />
        <path
          id="LS-C"
          title="Leribe"
          className="land"
          d="M586.76,109.92L555.51,156.67L530.63,171.07L484.96,161.02L486.96,106.88L413.47,76.84L397.6,38.9L376.76,39.53L331.8,97.7L296.47,99.18L279.86,119.79L242.27,117.22L204.99,186.05L267.88,163.54L290.41,171.36L325.7,128.05L412.91,196.69L438,206.55L463.39,263.51L506.77,280.43L511.12,249.49L516.19,226.31L573.95,178.58L586.76,109.92z"
        />
        <path
          id="LS-B"
          title="Butha-Buthe"
          className="land"
          d="M598.85,61.06L556.47,34.32L545.59,0L458.75,8.16L430.3,38.67L397.6,38.9L413.47,76.84L486.96,106.88L484.96,161.02L530.63,171.07L555.51,156.67L586.76,109.92L598.85,61.06z"
        />
        <path
          id="LS-G"
          title="Quthing"
          className="land"
          d="M442.97,596.94L400.51,583.86L412.98,531.35L344.51,558.51L307.21,556.17L293.48,600.55L223.27,671.22L171.62,681.08L193.15,718.18L241.01,760.32L343.67,779.26L397.63,678.77L396.91,632.9L442.97,596.94z"
        />
        <path
          id="LS-F"
          title="Mohale's Hoek"
          className="land"
          d="M412.98,531.35L449.37,541.37L506.47,499.97L503.81,445.68L418.66,490.64L368.14,494.36L351.4,494.62L305.27,498.08L220.25,521.44L177.04,509.99L148.81,543.69L122.65,531.7L124.34,478.86L92.02,479.81L52.15,500.96L83.36,543.56L116.44,649.57L147.21,651.7L171.62,681.08L223.27,671.22L293.48,600.55L307.21,556.17L344.51,558.51L412.98,531.35z"
        />
        <path
          id="LS-A"
          title="Maseru"
          className="land"
          d="M392.74,277.24L339.42,292.83L329.02,251.74L299.52,276.55L250.42,262.73L231.01,276.42L164.14,253.98L94.71,348.43L113.8,352.43L139.66,404.35L202.48,417.46L243.14,460.64L305.73,471.41L351.4,494.62L368.14,494.36L355.25,417.56L386.37,420.32L364.87,297.76L392.74,277.24z"
        />
        <path
          id="LS-D"
          title="Berea"
          className="land"
          d="M412.91,196.69L325.7,128.05L290.41,171.36L267.88,163.54L204.99,186.05L164.14,253.98L231.01,276.42L250.42,262.73L299.52,276.55L329.02,251.74L339.42,292.83L392.74,277.24L419.87,240.19L412.91,196.69z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Lesotho))
