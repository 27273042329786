import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import '../styles/scss/App.scss'
import GCloudSpeechToTextTestButtons from '../components/GCloudSpeechToTextButtons'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { common } from '../lib/languageUtil'
import { changePathAction, updateIsListeningAction } from '../redux/actions/actions'

// REMOVE ALL THE
// TODO - Delete Speech

class SpeechPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(updateIsListeningAction(false))
  }

  render() {
    const { dispatch, lngCode, language, isListening, transcript } = this.props
    return (
      <div className="speech-page top-navigation">
        <GCloudSpeechToTextTestButtons
          isListening={isListening}
          dispatch={dispatch}
          // check if it's language or lngCode
          language={language}
          lngCode={lngCode}
          navigationCommands={['menu', 'capitals', 'flags', 'locations']}
        />
        This is just a test page for Speech
        <button
          className="annyang-not-supported-menu-button"
          onClick={() => dispatch(changePathAction('/menu'))}>
          {common('menu')}
        </button>
        {transcript.length > 0 && (
          <div
            style={{
              marginTop: '25px',
              background: 'lightblue',
              border: '3px solid black',
              borderRadius: '5px',
              padding: '15px 25px'
            }}>
            {transcript[0]}
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(connect(mapMinimalStateToProps, passDispatchToProps)(SpeechPage))
