import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, readingMapStateToProps } from './../../../redux/mapToPropsUtil'
import { answerQuestionMap, commonProps, gameTypeProps } from './util'
import { getTranslatedAnswer } from './../../../lib/methodsUtil'

// like 3+ syllabs: tomato, umbrela, tornado, labrador

const HardWordsReadingPage = props => {
  const { lngCode = 'en', actual: enWord, actualColor } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'hard_words_reading',
        word: lngCode === 'en' ? enWord : getTranslatedAnswer(lngCode, answerQuestionMap, enWord),
        actualColor
      })}
    />
  )
}

export default connect(readingMapStateToProps, passDispatchToProps)(HardWordsReadingPage)
