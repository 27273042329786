import React from 'react'
import TopicNavigationPage from '../../../components/TopicNavigationPage'

const BeginnerDivisionsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'quotient', 'remainder']}
    pageName="beginner_level_divisions_page"
    topics={['topic_quotient', 'topic_remainder']}
    topicNavigationPath="mathematics/divisions/beginner"
    subTopics={['quotient', 'remainder']}
    borderRadius={0.05}
    canonicalPath="/mathematics"
  />
)

export default BeginnerDivisionsPage
