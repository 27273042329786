import actions from '../actions/action-types'
import { getRandomNotAlreadySeenItemFromList } from '../../lib/methodsUtil'
import { allSvgFillColors } from '../../lib/dataUtil'
import {
  easyWordsReadingList,
  mediumWordsReadingList,
  hardWordsReadingList
} from '../../pages/reading/words/util'
import {
  easySentencesReadingList,
  mediumSentencesReadingList,
  hardSentencesReadingList
} from '../../pages/reading/sentences/util'

const initialState = {
  actual: '',
  seenList: [],
  actualColor: '',
  seenColorsList: []
}

const pageList = {
  easy_words_reading: easyWordsReadingList,
  medium_words_reading: mediumWordsReadingList,
  hard_words_reading: hardWordsReadingList,
  easy_sentences_reading: easySentencesReadingList,
  medium_sentences_reading: mediumSentencesReadingList,
  hard_sentences_reading: hardSentencesReadingList
}

const readingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.randomizeReadingItems: {
      let seenList = state.seenList
      let seenColorsList = state.seenColorsList
      const pageName = action.payload
      const answersList = pageList[pageName]

      const haveAllItemsBeenSeen = seenList.length === answersList.length
      if (haveAllItemsBeenSeen) {
        seenList = []
      }

      const actual = getRandomNotAlreadySeenItemFromList(answersList, seenList)
      seenList.push(actual)

      const haveAllColorsBeenSeen = seenColorsList.length === allSvgFillColors.length
      if (haveAllColorsBeenSeen) {
        seenColorsList = []
      }

      const actualColor = getRandomNotAlreadySeenItemFromList(allSvgFillColors, seenColorsList)
      seenColorsList.push(actualColor)

      return {
        ...state,
        actual,
        seenList,
        actualColor,
        seenColorsList
      }
    }

    case actions.resetReading: {
      // seenList & seenColorsList have to be reset fully because seenList.push modify the original array.
      return { ...initialState, seenList: [], seenColorsList: [] }
    }

    default:
      return state
  }
}

export default readingReducer
