import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, wordsMapStateToProps } from './../../../redux/mapToPropsUtil'
import { answerQuestionMap, commonProps, gameTypeProps } from './util'
import { getTranslatedAnswer } from './../../../lib/methodsUtil'

const PlayAnimalsPage = props => {
  const { lngCode = 'en', actual: enAnimal } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'play_animals',
        animal:
          lngCode === 'en' ? enAnimal : getTranslatedAnswer(lngCode, answerQuestionMap, enAnimal)
      })}
    />
  )
}

export default connect(wordsMapStateToProps, passDispatchToProps)(PlayAnimalsPage)
