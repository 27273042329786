import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 805
const height = 705

const cities = {
  Asmara: { x: 308, y: 334 }
}

const Eritrea = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map eritrea">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="ER-DK"
          title="Debubawi Keyih Bahri"
          className="land"
          d="M743.22,694.96L765.85,662.59L795.41,652.64L781.55,629.21L772.88,642.67L755.45,635.11L726.08,588.7L707.73,589.91L696.92,546.94L624.25,502.23L563.8,416.84L516.17,409.68L493.11,378.24L467.15,453.85L524.09,481.94L707.73,682.09L743.22,694.96z"
        />
        <path
          id="ER-DU"
          title="Debub"
          className="land"
          d="M392.24,433.44L345.13,350.59L281.22,353.1L218.39,379.19L216.81,414.94L239.3,443.91L290.47,434.43L307.54,415.09L331.1,440.99L367.55,425.33L392.24,433.44z"
        />
        <path
          id="ER-AS"
          title="Anseba"
          className="land"
          d="M301.47,301.37L285.21,266.33L248.1,251.83L226.17,177.51L192.17,167.15L189.33,58.05L127.76,86.33L116.77,121.42L66.49,116.43L60.22,195.15L77.16,227.38L134.31,268L281,303.69L301.47,301.37z"
        />
        <path
          id="ER-SK"
          title="Semenawi Keyih Bahri"
          className="land"
          d="M428.26,263.49L416.07,278.96L434.8,290.83L417.7,285.78L422.63,297.56L473.6,300.83L444.6,292.9L428.26,263.49zM438.74,250.74L431.14,235.74L423.95,246.81L438.74,250.74zM259.06,0L215.33,59.75L189.33,58.05L192.17,167.15L226.17,177.51L248.1,251.83L285.21,266.33L301.47,301.37L345.13,350.59L392.24,433.44L467.15,453.85L493.11,378.24L444.08,374.43L434.63,330.32L410.33,310.19L398.24,324.18L407.88,350.9L391.36,360.68L341.31,257.79L281.41,41.91L259.06,0z"
        />
        <path
          id="ER-MA"
          title="Maekel"
          className="land"
          d="M345.13,350.59L301.47,301.37L281,303.69L281.22,353.1L345.13,350.59z"
        />
        <path
          id="ER-GB"
          title="Gash Barka"
          className="land"
          d="M134.31,268L77.16,227.38L60.22,195.15L0.57,355.83L12.6,462.64L76,461.01L103.74,438.87L134.61,482L174.73,387.05L216.81,414.94L218.39,379.19L281.22,353.1L281,303.69L134.31,268z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Eritrea))
