import React from 'react'
import {
  FadeIn,
  FadeOut,
  Shake,
  Flip,
  FlipInX,
  FlipInY,
  FlipOutX,
  FlipOutY,
  Pulse,
  RotateIn,
  RotateOut,
  RubberBand,
  Wobble,
  SlideInLeft,
  SlideOutRight,
  SlideOutLeft,
  SlideInRight,
  DrawBarAnim,
  drawCircleAnimSix,
  drawCircleAnimSeven,
  drawCircleAnimEight,
  drawCircleAnimNine,
  drawCircleAnimTen,
  drawCircleAnimEleven,
  drawCircleSixStatic,
  drawCircleSevenStatic,
  drawCircleEightStatic,
  drawCircleNineStatic,
  drawCircleTenStatic,
  drawCircleElevenStatic
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnBeginnerDivisionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_beginner_divisions"
    sentencesNumberPlusRedirect={20}
    startTimesOf={{
      2: 9000,
      3: 17000,
      4: 24000,
      5: 31000,
      6: 33000,
      7: 35000,
      8: 39000,
      9: 41500,
      10: 48500,
      11: 56000,
      12: 63000,
      13: 66500,
      14: 68500,
      15: 70500,
      16: 72500,
      17: 80000,
      18: 85500,
      19: 92500,
      20: 99500
    }}
    animations={{
      1: (
        <div className="learn-flex-center-center">
          <SlideInLeft className="max-100 flex width-100 height-100" duration={0.5}>
            <FadeIn className="learn-beginner-division-dog-one" duration={0.5}>
              <SlideOutRight className="width-100" delay={4.5} duration={0.5}>
                <FadeOut className="width-100" delay={4.5} duration={0.5}>
                  <img
                    className="width-100"
                    src={`${baseUrl}/images/learn/learn-beginner-division-dog-1.webp`}
                  />
                </FadeOut>
              </SlideOutRight>
            </FadeIn>
          </SlideInLeft>
          <SlideInRight delay={1.5} className="position-absolute max-100 flex width-100 height-100">
            <FadeIn className="learn-beginner-division-dog-two" delay={1.5} duration={0.5}>
              <SlideOutLeft className="width-100" delay={4.5} duration={0.5}>
                <FadeOut className="width-100" delay={4.5} duration={0.5}>
                  <img
                    className="width-100"
                    src={`${baseUrl}/images/learn/learn-beginner-division-dog-2.webp`}
                  />
                </FadeOut>
              </SlideOutLeft>
            </FadeIn>
          </SlideInRight>
          <FlipInY delay={6} duration={0.5} className="position-absolute max-100 flex">
            <FlipOutY className="width-100" delay={8} duration={0.5}>
              <img
                className="width-100"
                src={`${baseUrl}/images/learn/learn-beginner-division-girl-1.webp`}
              />
            </FlipOutY>
          </FlipInY>
        </div>
      ),
      2: (
        <div className="learn-flex-center-center">
          <SlideInLeft
            className="learn-beginner-division-quotient-illustration"
            delay={10}
            duration={0.5}>
            <FadeIn delay={10} duration={0.5}>
              <Shake delay={1.5} duration={4}>
                <SlideOutRight delay={4} duration={3}>
                  <FadeOut delay={6}>
                    <img src={`${baseUrl}/images/learn/quotient_illustration.webp`} />
                  </FadeOut>
                </SlideOutRight>
              </Shake>
            </FadeIn>
          </SlideInLeft>
          <SlideInRight className="width-100 height-100" delay={12}>
            <FadeIn className="width-100 height-100" delay={12} duration={0.5}>
              <Shake className="width-100 height-100" delay={2.5} duration={4}>
                <Wobble className="width-100 height-100" delay={4.5} duration={2}>
                  <SlideOutLeft className="width-100 height-100" delay={4} duration={3}>
                    <FadeOut className="width-100 height-100" delay={6}>
                      <img
                        className="learn-beginner-division-remainder-illustration"
                        src={`${baseUrl}/images/learn/bd-2.webp`}
                      />
                    </FadeOut>
                  </SlideOutLeft>
                </Wobble>
              </Shake>
            </FadeIn>
          </SlideInRight>
        </div>
      ),
      3: (
        <div className="learn-flex-center-center">
          <FlipInY className="position-absolute" duration={0.5}>
            <FlipOutY className="position-relative" delay={2.5} duration={0.5}>
              <img className="width-100 height-100" src={`${baseUrl}/images/learn/bd-3.webp`} />
            </FlipOutY>
          </FlipInY>
          <FlipInX delay={3} className="learn-flex-column-space-between learn-horizontal-numbers">
            <FlipOutX delay={6.5} duration={0.5}>
              <RubberBand delay={4.25} duration={0.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/24/6.webp`} />
              </RubberBand>
            </FlipOutX>
            <FlipOutX delay={6.5} duration={0.5}>
              <RubberBand delay={4.5} duration={0.5}>
                <img
                  className="width-100 plus-sign minus-sign"
                  src={`${mathsNumbersUrls}/24/by.webp`}
                />
              </RubberBand>
            </FlipOutX>
            <FlipOutX delay={6.5} duration={0.5}>
              <RubberBand delay={4.75} duration={0.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/24/2.webp`} />
              </RubberBand>
            </FlipOutX>
          </FlipInX>
        </div>
      ),
      4: (
        <div className="math-bars-container">
          <DrawBarAnim number="one" />
          <DrawBarAnim number="two" />
          <DrawBarAnim number="three" />
          <DrawBarAnim number="four" />
          <DrawBarAnim number="five" />
          <DrawBarAnim number="six" />
        </div>
      ),
      5: (
        <div className="math-bars-container position-relative">
          <div className="math-bar math-bar-one" />
          <div className="math-bar math-bar-two" />
          <div className="math-bar math-bar-three" />
          <div className="math-bar math-bar-four" />
          <div className="math-bar math-bar-five" />
          <div className="math-bar math-bar-six" />
          {drawCircleAnimSix}
        </div>
      ),
      6: (
        <div className="math-bars-container position-relative">
          <div className="math-bar math-bar-one" />
          <div className="math-bar math-bar-two" />
          <div className="math-bar math-bar-three" />
          <div className="math-bar math-bar-four" />
          <div className="math-bar math-bar-five" />
          <div className="math-bar math-bar-six" />
          {drawCircleSixStatic}
          {drawCircleAnimSeven}
        </div>
      ),
      7: (
        <div className="math-bars-container position-relative">
          <div className="math-bar math-bar-one" />
          <div className="math-bar math-bar-two" />
          <div className="math-bar math-bar-three" />
          <div className="math-bar math-bar-four" />
          <div className="math-bar math-bar-five" />
          <div className="math-bar math-bar-six" />
          {drawCircleSixStatic}
          {drawCircleSevenStatic}
          {drawCircleAnimEight}
        </div>
      ),
      8: (
        <div className="math-bars-container position-relative">
          <div className="math-bar math-bar-one" />
          <div className="math-bar math-bar-two" />
          <div className="math-bar math-bar-three" />
          <div className="math-bar math-bar-four" />
          <div className="math-bar math-bar-five" />
          <div className="math-bar math-bar-six" />
          <Pulse className="position-absolute width-100 height-100" duration={0.5}>
            {drawCircleSixStatic}
          </Pulse>
          <Pulse className="position-absolute width-100 height-100" delay={0.5} duration={0.5}>
            {drawCircleSevenStatic}
          </Pulse>
          <Pulse className="position-absolute width-100 height-100" delay={1} duration={0.5}>
            {drawCircleEightStatic}
          </Pulse>
        </div>
      ),
      9: (
        <FadeOut
          className="learn-flex-column-space-between learn-horizontal-numbers justify-content-center"
          delay={6.5}
          duration={0.5}>
          <FadeIn className="width-100 flex" delay={0.25} duration={0.5}>
            <Pulse delay={0.5} duration={0.5}>
              <Pulse delay={5.5} duration={0.5}>
                <img className="width-100 flex" src={`${mathsNumbersUrls}/24/6.webp`} />
              </Pulse>
            </Pulse>
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.5} duration={0.5}>
            <img className="plus-sign width-100 flex" src={`${mathsNumbersUrls}/24/by.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.75} duration={0.5}>
            <Pulse delay={1} duration={0.5}>
              <Pulse delay={4.5} duration={0.5}>
                <img className="width-100 flex" src={`${mathsNumbersUrls}/24/2.webp`} />
              </Pulse>
            </Pulse>
          </FadeIn>
          <FadeIn className="width-100 flex" delay={1} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/24/equal.webp`} />
          </FadeIn>
          <FadeIn className="learn-easy-six-one flex" delay={1.25} duration={0.5}>
            <Pulse delay={1.5} duration={0.5}>
              <Pulse delay={5} duration={0.5}>
                <img className="width-150 flex" src={`${mathsNumbersUrls}/24/3.webp`} />
              </Pulse>
            </Pulse>
          </FadeIn>
        </FadeOut>
      ),
      10: (
        <div className="learn-flex-center-center">
          <SlideInRight delay={0.5} className="position-absolute max-100 flex">
            <FadeIn className="width-100" delay={0.5} duration={0.5}>
              <SlideOutLeft className="width-100" delay={4.5} duration={0.5}>
                <FadeOut className="width-100" delay={4.5} duration={0.5}>
                  <img className="width-100" src={`${baseUrl}/images/learn/bd-10.webp`} />
                </FadeOut>
              </SlideOutLeft>
            </FadeIn>
          </SlideInRight>
        </div>
      ),
      11: (
        <RotateIn className="learn-flex-center-center" duration={0.5}>
          <RotateOut className="position-relative" delay={6} duration={0.5}>
            <img
              className="width-100 height-100"
              src={`${baseUrl}/images/learn/learning-kid.webp`}
            />
          </RotateOut>
        </RotateIn>
      ),
      12: (
        <div className="math-bars-container width-100 justify-content-center">
          <DrawBarAnim number="one-bd" />
          <DrawBarAnim number="two-bd" />
          <DrawBarAnim number="three-bd" />
          <DrawBarAnim number="four-bd" />
          <DrawBarAnim number="five-bd" />
          <DrawBarAnim number="six-bd" />
          <DrawBarAnim number="seven-bd" />
        </div>
      ),

      13: (
        <div className="math-bars-container width-100 justify-content-center position-relative">
          <div className="math-bar math-bar-one-bd" />
          <div className="math-bar math-bar-two-bd" />
          <div className="math-bar math-bar-three-bd" />
          <div className="math-bar math-bar-four-bd" />
          <div className="math-bar math-bar-five-bd" />
          <div className="math-bar math-bar-six-bd" />
          <div className="math-bar math-bar-seven-bd" />
          {drawCircleAnimNine}
        </div>
      ),
      14: (
        <div className="math-bars-container width-100 justify-content-center position-relative">
          <div className="math-bar math-bar-one-bd" />
          <div className="math-bar math-bar-two-bd" />
          <div className="math-bar math-bar-three-bd" />
          <div className="math-bar math-bar-four-bd" />
          <div className="math-bar math-bar-five-bd" />
          <div className="math-bar math-bar-six-bd" />
          <div className="math-bar math-bar-seven-bd" />
          {drawCircleNineStatic}
          {drawCircleAnimTen}
        </div>
      ),
      15: (
        <div className="math-bars-container width-100 justify-content-center position-relative">
          <div className="math-bar math-bar-one-bd" />
          <div className="math-bar math-bar-two-bd" />
          <div className="math-bar math-bar-three-bd" />
          <div className="math-bar math-bar-four-bd" />
          <div className="math-bar math-bar-five-bd" />
          <div className="math-bar math-bar-six-bd" />
          <div className="math-bar math-bar-seven-bd" />
          {drawCircleNineStatic}
          {drawCircleTenStatic}
          {drawCircleAnimEleven}
        </div>
      ),
      16: (
        <FadeOut
          delay={7}
          duration={0.5}
          className="math-bars-container width-100 justify-content-center position-relative">
          <div className="math-bar math-bar-one-bd" />
          <div className="math-bar math-bar-two-bd" />
          <div className="math-bar math-bar-three-bd" />
          <div className="math-bar math-bar-four-bd" />
          <div className="math-bar math-bar-five-bd" />
          <div className="math-bar math-bar-six-bd" />
          <Shake delay={2} duration={3}>
            <Flip className="height-100" delay={2} duration={3}>
              <div className="math-bar math-bar-seven-bd yellow-pulse" />
            </Flip>
          </Shake>
          {drawCircleNineStatic}
          {drawCircleTenStatic}
          {drawCircleElevenStatic}
        </FadeOut>
      ),
      17: (
        <div className="learn-flex-center-center">
          <FadeOut
            className="learn-flex-column-space-between learn-horizontal-numbers justify-content-center"
            delay={3.5}
            duration={0.5}>
            <FadeIn className="width-100 flex" delay={0.25} duration={0.5}>
              <Pulse delay={1} duration={0.5}>
                <img className="width-100 flex" src={`${mathsNumbersUrls}/22/7.webp`} />
              </Pulse>
            </FadeIn>
            <FadeIn className="width-100 flex" delay={0.5} duration={0.5}>
              <img className="plus-sign width-100 flex" src={`${mathsNumbersUrls}/22/by.webp`} />
            </FadeIn>
            <FadeIn className="width-100 flex" delay={0.75} duration={0.5}>
              <Pulse delay={1.5} duration={0.5}>
                <img className="width-100 flex" src={`${mathsNumbersUrls}/22/2.webp`} />
              </Pulse>
            </FadeIn>
            <FadeIn className="width-100 flex" delay={1} duration={0.5}>
              <img
                className="equal-sign width-100 flex"
                src={`${mathsNumbersUrls}/22/equal.webp`}
              />
            </FadeIn>
            <FadeIn className="learn-easy-six-one flex" delay={1.25} duration={0.5}>
              <Pulse delay={2} duration={0.5}>
                <img className="width-150 flex" src={`${mathsNumbersUrls}/22/3.webp`} />
              </Pulse>
            </FadeIn>
          </FadeOut>
          <RotateIn className="position-absolute" delay={4} duration={0.5}>
            <RotateOut className="height-100" delay={5.5} duration={0.5}>
              <img className="height-50 flex" src={`${mathsNumbersUrls}/22/1.webp`} />
            </RotateOut>
          </RotateIn>
        </div>
      ),
      18: (
        <FlipInX className="learn-flex-center-center" duration={0.5}>
          <FlipOutX className="position-relative" delay={6.5} duration={0.5}>
            <img className="width-100 height-100" src={`${baseUrl}/images/learn/bd-18.webp`} />
          </FlipOutX>
        </FlipInX>
      ),
      19: (
        <FlipInY className="learn-flex-center-center" duration={0.5}>
          <FlipOutY className="position-relative" delay={6.5} duration={0.5}>
            <img className="width-100 height-100" src={`${baseUrl}/images/learn/bd-19.webp`} />
          </FlipOutY>
        </FlipInY>
      )
    }}
  />
)

export default LearnBeginnerDivisionsPage
