import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 2101

const cities = {
  Lome: { x: 557, y: 2069 }
}

const Togo = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map togo">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="TG-S"
          title="Savanes"
          className="land"
          d="M0.19,0L75.01,30.28L32.19,209.58L231.12,355.75L230.29,459L295.84,525.26L347.04,524.52L370.79,403.01L477.54,383.57L380.54,314.48L388.98,184.71L438.31,59.48L268.48,84.35L268.06,55.1L0.19,0z"
        />
        <path
          id="TG-C"
          title="Centre"
          className="land"
          d="M726.59,1152.51L723.25,858L651.17,768.41L479.05,804.39L458.92,880.84L368.43,869.72L266.83,958.47L221.3,979.74L222.02,1004.24L321.29,1102.19L360.86,1181.34L303.86,1220.33L315.69,1349.15L564.9,1313.63L587.31,1237L575.78,1150.63L726.59,1152.51z"
        />
        <path
          id="TG-K"
          title="Kara"
          className="land"
          d="M651.17,768.41L611.36,664.42L614.59,475.29L477.54,383.57L370.79,403.01L347.04,524.52L295.84,525.26L230.29,459L211.9,461.81L210.8,614.12L175.46,616.61L223.38,644.81L162.83,650.5L192.26,678.92L161.44,710.17L237.3,685.25L286.42,723.17L245.35,878.46L266.83,958.47L368.43,869.72L458.92,880.84L479.05,804.39L651.17,768.41z"
        />
        <path
          id="TG-M"
          title="Maritime"
          className="land"
          d="M554.83,2090.37L799.81,2017.4L711.04,1851.83L710.29,1758.86L577.62,1833.82L528.21,1798.32L447.41,1797.43L430.53,1865.88L360.99,1911.41L383.04,1958.73L471.99,1997.86L554.83,2090.37z"
        />
        <path
          id="TG-P"
          title="Plateaux"
          className="land"
          d="M726.59,1152.51L575.78,1150.63L587.31,1237L564.9,1313.63L315.69,1349.15L319.64,1429.38L271.75,1510.17L332.83,1593.92L313.78,1705.9L271.33,1729.91L360.99,1911.41L430.53,1865.88L447.41,1797.43L528.21,1798.32L577.62,1833.82L710.29,1758.86L697.04,1722.14L735.51,1720.13L726.59,1152.51z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Togo))
