import React from 'react'
import { baseUrl } from '../../src/lib/_processUtil'

const AnswerSound = ({ isCorrect }) => (
  <audio autoPlay>
    <source type="audio/mp3" src={`${baseUrl}/audio/${isCorrect ? 'correct' : 'incorrect'}.mp3`} />
  </audio>
)

export default AnswerSound
