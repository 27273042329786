import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import ImageWithDimensions from './ImageWithDimensions'
import { baseUrl } from '../lib/_processUtil'

const ClickToHearAnimation = () => (
  <div className="click-to-hear-animation">
    <ImageWithDimensions
      className="click-to-hear-animation-hand-down"
      alt="hand-down"
      src={`${baseUrl}/images/hand-down.webp`}
    />

    <ImageWithDimensions
      className={`click-to-hear-animation-${
        isMobile ? 'mobile' : isTablet ? 'tablet' : 'keyboard'
      }`}
      alt="keyboard"
      src={`${baseUrl}/images/${isMobile ? 'mobile' : isTablet ? 'tablet' : 'keyboard'}.webp`}
    />

    <ImageWithDimensions
      className="click-to-hear-animation-click-sound"
      alt="click sound"
      src={`${baseUrl}/images/click-sound.webp`}
    />
  </div>
)

export default ClickToHearAnimation
