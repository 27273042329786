import React, { Component } from 'react'
import { connect } from 'react-redux'
import isTouchDevice from 'is-touch-device'
import readOutLoud from '../lib/readOutLoud'
import ClickToHearAnimation from './ClickToHearAnimation'
import { baseUrl } from '../lib/_processUtil'
import { isSpeakRequest } from '../lib/methodsUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { saveTranscriptAction } from '../redux/actions/actions'
import ImageWithDimensions from './ImageWithDimensions'

class SpeechButton extends Component {
  constructor(props) {
    super(props)
    this.handleKeydown = this.handleKeydown.bind(this)
    this.countDown = this.countDown.bind(this)
    this.state = {
      secondsRemaining: this.props.count || 10,
      wasClicked: false
    }
  }

  countDown() {
    const { secondsRemaining } = this.state
    if (secondsRemaining <= 0) {
      clearInterval(this.interval)
    } else {
      this.setState({ secondsRemaining: secondsRemaining - 1 })
    }
  }

  componentDidMount() {
    const { disableSpeechButton = false } = this.props
    if (!disableSpeechButton) {
      document.addEventListener('keydown', this.handleKeydown)
      this.interval = setInterval(this.countDown, 1000)
    }
  }

  componentDidUpdate(oldProps) {
    const { count: oldCount } = oldProps
    const {
      count: newCount,
      disableSpeakRequestWhilePlaying = false,
      transcript,
      dispatch,
      disableSpeechButton = false,
      forceSpeechButtonReset = false
    } = this.props

    if (!disableSpeechButton) {
      // reset the seconds remaining if the count prop passed has changed
      if (oldCount !== newCount) {
        this.setState({ secondsRemaining: newCount || 10 })
      }

      // When speak is said, the count is reset, the transcriptValue reset to empty.
      // We add an extra 10 seconds for the speaking value. I trid using a callback like for handleKeydown() but it didn't work. I can't be bothered to fix.
      if (
        forceSpeechButtonReset ||
        (isSpeakRequest(transcript) && !disableSpeakRequestWhilePlaying)
      ) {
        this.setState({ secondsRemaining: (newCount || 10) + 10 })
        dispatch(saveTranscriptAction([]))
      }

      const { secondsRemaining } = this.state

      // we have 2 methods that can reset the seconds remaing just above
      // if we have reset  the seconds remaing (which mean they equal the count number) or the first time it mounts (it has no newCount)
      // then we stat the count down
      if (secondsRemaining === newCount || !newCount) {
        clearInterval(this.interval)
        this.interval = setInterval(this.countDown, 1000)
      }
    }
  }

  componentWillUnmount() {
    const { disableSpeechButton = false } = this.props
    if (!disableSpeechButton) {
      document.removeEventListener('keydown', this.handleKeydown)
      clearInterval(this.interval)
    }
  }

  handleKeydown() {
    const { text, dispatch, count, language = 'en', gender = 'man' } = this.props
    this.setState({ wasClicked: true })
    const callBack = () => {
      this.setState({ secondsRemaining: count || 10, wasClicked: false })
    }
    readOutLoud(text, dispatch, language, gender, callBack)
  }

  render() {
    const { isAnnyangSupported, className, disableSpeechButton = false, language } = this.props
    const { secondsRemaining, wasClicked } = this.state
    const isTenSecondsSilence = secondsRemaining === 0 || !secondsRemaining

    return (
      !disableSpeechButton &&
      isAnnyangSupported && (
        <div className="speech-button-wrapper">
          {isTouchDevice() ? (
            <ImageWithDimensions
              alt="touch device speech button"
              className={`mobile-device-speech-button ${
                wasClicked
                  ? 'mobile-device-flag-disappear'
                  : isTenSecondsSilence
                    ? 'mobile-device-flag-pulse'
                    : 'mobile-device-flag-appears'
              }`}
              onClick={this.handleKeydown}
              src={`${baseUrl}/images/speech-buttons-flags-icons/${language}.webp`}
            />
          ) : null}

          <div
            className={`speech-button-style ${className} ${
              isTenSecondsSilence ? 'show-speech-button-background' : ''
            } ${wasClicked ? 'speech-button-hide-background' : ''}`}
            onClick={isTouchDevice() ? this.handleKeydown : null}>
            {isTenSecondsSilence && (wasClicked ? null : <ClickToHearAnimation />)}
          </div>
        </div>
      )
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(SpeechButton)
