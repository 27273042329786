import annyang from './annyangSetUp'
import { updateIsListeningAction } from '../redux/actions/actions'

// List of available Google voice below
const voiceName = {
  'en-GB': {
    man: 'Google UK English Male',
    woman: 'Google UK English Female'
  },
  'en-US': {
    man: 'Google US English',
    woman: 'Google US English'
  },
  'fr-FR': {
    man: 'Google français',
    woman: 'Google français'
  }

  // Google Voices to use in the future
  // es: {
  //   man: 'Google español',
  //   woman: 'Google español'
  // },
  // it: {
  //   man: 'Google italiano',
  //   woman: 'Google italiano'
  // },
  // de: { // German
  //   man: 'Google Deutsch',
  //   woman: 'Google Deutsch'
  // },
  // 'en-US': {
  //   man: 'Google US English',
  //   woman: 'Google US English'
  // },
  // 'es-US': {
  //   man: 'Google español de Estados Unidos',
  //   woman: 'Google español de Estados Unidos'
  // },
  // nl: {
  //   man: 'Google Nederlands',
  //   woman: 'Google Nederlands'
  // },
  // 'pt-BR': {
  //   man: 'Google português do Brasil',
  //   woman: 'Google português do Brasil'
  // },
  // 'hi-IN': {
  //   man: 'Google हिन्दी',
  //   woman: 'Google हिन्दी'
  // },
  // 'id-ID': {
  //   man: 'Google Bahasa Indonesia',
  //   woman: 'Google Bahasa Indonesia'
  // },
  // 'ja-JP': {
  //   man: 'Google 日本語',
  //   woman: 'Google 日本語'
  // },
  // 'ko-KR': {
  //   man: 'Google 한국의',
  //   woman: 'Google 한국의'
  // },
  // 'pl-PL': {
  //   man: 'Google polski',
  //   woman: 'Google polski'
  // },
  // 'ru-RU': {
  //   man: 'Google русский',
  //   woman: 'Google русский'
  // },
  // 'zh-CN': {
  // eslint-disable-next-line
  //   man: 'Google 普通话（中国大陆）',
  // eslint-disable-next-line
  //   woman: 'Google 普通话（中国大陆）'
  // },
  // 'zh-HK': {
  // eslint-disable-next-line
  //   man: 'Google 粤語（香港）',
  // eslint-disable-next-line
  //   woman: 'Google 粤語（香港）'
  // },
  // 'zh-TW': {
  //   man: 'Google 國語（臺灣）',
  //   woman: 'Google 國語（臺灣）'
  // }
}

// const voiceschanged = () => {
//   console.log(`Voices #: ${speechSynthesis.getVoices().length}`)
//   speechSynthesis.getVoices().forEach(voice => {
//     console.log(voice.name, voice.lang)
//   })
// }

const getVoices = () => {
  return new Promise(resolve => {
    let voices = window.speechSynthesis.getVoices()
    if (voices.length) {
      resolve(voices)
      return
    }
    window.speechSynthesis.onvoiceschanged = () => {
      voices = window.speechSynthesis.getVoices()
      resolve(voices)
    }
  })
}

const chooseVoice = async (language, gender) => {
  // Chrome updates broke non-Google voices in the past so I only filter and use the one that are "Google ones". They also sound less robotic.
  const googleVoices = (await getVoices()).filter(voice => voice.name.indexOf('Google') > -1)
  const selectedVoice =
    googleVoices.find(voice => voiceName[language][gender] === voice.name) || voiceName.en.man
  return new Promise(resolve => {
    resolve(selectedVoice)
  })
}

const readOutLoud = async (
  text,
  dispatch,
  language = 'en-GB',
  gender = 'man',
  callBack,
  readSpeed = '1.15'
) => {
  annyang.abort()
  dispatch(updateIsListeningAction(false))
  const speechSetUp = new window.SpeechSynthesisUtterance()
  const speech = window.speechSynthesis

  speechSetUp.pitch = '1'
  speechSetUp.rate = readSpeed // speed
  speechSetUp.volume = '1'
  speechSetUp.text = text.replace(':', '')
  speechSetUp.voice = await chooseVoice(language, gender)
  // speech.onvoiceschanged = voiceschanged

  /*   SpeechSynthesis has a known bug. After 14 seconds of speaking, it stops, stays on speech-mode and the
  only option unfreeze is to restart the browser. The bug only seems to happen when you the promise options
  to fetch the voices and languages is used. A working fix is to pause and resume speechSynthesis if it's still
  speaking after 14 seconds; else the interval is cleared. */
  let r = setInterval(() => {
    if (!speechSynthesis.speaking) {
      clearInterval(r)
    } else {
      speechSynthesis.pause()
      speechSynthesis.resume()
    }
  }, 14000)

  speechSetUp.onend = () => {
    speech.cancel()
    clearInterval(r)
    annyang.start()
    if (callBack) {
      callBack()
    }
    dispatch(updateIsListeningAction(true))
  }
  speech.speak(speechSetUp)
}

export default readOutLoud
