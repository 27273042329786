import React from 'react'
import {
  FadeIn,
  BounceIn,
  BounceOut,
  FlipInY,
  FlipOutX,
  FlipOutY,
  FadeOut,
  Shake,
  Swing,
  Pulse,
  Wobble,
  BounceInDown,
  BounceOutDown,
  RotateInUpRight,
  RotateOutUpRight,
  RotateIn,
  RotateOut,
  Tada
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnMediumAdditionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_medium_additions"
    sentencesNumberPlusRedirect={19}
    startTimesOf={{
      2: 5000,
      3: 11000,
      4: 19000,
      5: 21000,
      6: 23000,
      7: 29000,
      8: 37000,
      9: 45000,
      10: 53000,
      11: 59000,
      12: 65000,
      13: 71000,
      14: 77000,
      15: 83000,
      16: 92500,
      17: 110000,
      18: 118000,
      19: 125000
    }}
    animations={{
      1: (
        <BounceIn className="learn-flex-center-center" delay={1}>
          <BounceOut delay={3.5} duration={0.5}>
            <img className="width-100" src={`${baseUrl}/images/learn/pen-and-paper-crosses.webp`} />
          </BounceOut>
        </BounceIn>
      ),
      2: (
        <div className="learn-flex-center-center">
          <FadeIn duration={0.5} style={{ height: '100%' }}>
            <FadeOut delay={5.5} duration={0.5} style={{ height: '100%' }}>
              <div className="learn-flex-column-space-between position-relative">
                <div className="math-bars-container position-relative">
                  <div className="math-bar math-bar-one" />
                  <div className="math-bar math-bar-one-cross" />
                  <div className="math-bar math-bar-two" />
                  <div className="math-bar math-bar-two-cross" />
                  <div className="math-bar math-bar-three" />
                  <div className="math-bar math-bar-three-cross" />
                  <div className="math-bar math-bar-four" />
                  <div className="math-bar math-bar-four-cross" />
                  <div className="math-bar math-bar-five" />
                  <div className="math-bar math-bar-five-cross" />
                  <div className="math-bar math-bar-six" />
                  <div className="math-bar math-bar-six-cross" />
                </div>
                <div className="learn-maths-math-sign position-relative">
                  <div className="math-bar math-bar-one" />
                  <div className="math-bar math-bar-four learn-medium-additions-math-sign" />
                </div>
                <div className="math-bars-container position-relative">
                  <div className="math-bar math-bar-seven" />
                  <div className="math-bar math-bar-seven-cross" />
                  <div className="math-bar math-bar-eight" />
                  <div className="math-bar math-bar-eight-cross" />
                  <div className="math-bar math-bar-nine" />
                  <div className="math-bar math-bar-nine-cross" />
                  <div className="math-bar math-bar-ten" />
                  <div className="math-bar math-bar-ten-cross" />
                  <div className="math-bar math-bar-eleven" />
                  <div className="math-bar math-bar-eleven-cross" />
                  <div className="math-bar math-bar-twelve" />
                  <div className="math-bar math-bar-twelve-cross" />
                  <div className="math-bar math-bar-thirteen" />
                  <div className="math-bar math-bar-thirteen-cross" />
                </div>
              </div>
            </FadeOut>
          </FadeIn>
        </div>
      ),
      3: (
        <FlipInY className="learn-flex-center-center" duration={6}>
          <FlipOutX className="flex align-items-center height-100" delay={600}>
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/6.webp`} />
            <img className="very-small-maths-sign" src={`${mathsNumbersUrls}/10/plus.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/7.webp`} />
            <img className="very-small-maths-sign" src={`${mathsNumbersUrls}/10/equal.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/1.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/3.webp`} />
          </FlipOutX>
        </FlipInY>
      ),
      4: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <FadeIn className="math-line-small-fifth" duration={0.5}>
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </FadeIn>
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth visibility-hidden" />
        </div>
      ),
      5: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <FadeIn className="math-line-small-fifth" duration={0.5}>
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </FadeIn>
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth visibility-hidden" />
        </div>
      ),
      6: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </div>
          <FadeIn className="math-line-small-fifth" duration={0.5}>
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
            <Shake className="arrow arrow-four" duration={3} delay={1}>
              <FadeOut className="flex height-100" duration={0.5} delay={4}>
                <img src={`${baseUrl}/images/arrows/4.webp`} />
              </FadeOut>
            </Shake>
          </FadeIn>
          <div className="math-line-small-fifth visibility-hidden" />
        </div>
      ),
      7: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <Wobble className="height-100" duration={5}>
              <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
            </Wobble>
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <Wobble className="height-100" delay={0.5} duration={4.5}>
              <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
            </Wobble>
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
          </div>
          <div className="math-line-small-fifth visibility-hidden" />
        </div>
      ),
      8: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
            <BounceInDown className="arrow arrow-one flex width-100" duration={1.5}>
              <BounceOutDown delay={2.5} duration={2}>
                <FadeOut delay={3}>
                  <img className="flex width-100" src={`${baseUrl}/images/arrows/1.webp`} />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>
          </div>
          <BounceIn className="math-line-small-fifth" delay={7}>
            <img className="small-number" src={`${mathsNumbersUrls}/14/3.webp`} />
          </BounceIn>
        </div>
      ),
      9: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <FadeIn delay={3}>
              <img
                className="super-small-sign medium-addition-plus top-line"
                src={`${mathsNumbersUrls}/4/plus.webp`}
              />
              <img
                className="super-small-number medium-addition-plus-one top-line"
                src={`${mathsNumbersUrls}/4/1.webp`}
              />
            </FadeIn>
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
            <RotateInUpRight className="arrow arrow-two height-100" duration={0.5}>
              <RotateOutUpRight className="height-100" delay={2.5} duration={2}>
                <FadeOut className="position-relative height-100" delay={3} duration={1}>
                  <img className="arrow-two-move" src={`${baseUrl}/images/arrows/2.webp`} />
                </FadeOut>
              </RotateOutUpRight>
            </RotateInUpRight>
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/3.webp`} />
          </div>
        </div>
      ),
      10: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <Wobble className="height-100" delay={3.5} duration={2}>
              <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            </Wobble>
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <Wobble className="height-100" delay={4} duration={1.5}>
              <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            </Wobble>
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img
              className="super-small-sign medium-addition-plus top-line"
              src={`${mathsNumbersUrls}/4/plus.webp`}
            />
            <img
              className="super-small-number medium-addition-plus-one top-line"
              src={`${mathsNumbersUrls}/4/1.webp`}
            />
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
            <FadeIn className="arrow arrow-four arrow-four-two" duration={0.5}>
              <Shake className="flex height-100" duration={2.5} delay={0.5}>
                <FadeOut className="flex height-100" duration={0.5} delay={3}>
                  <img src={`${baseUrl}/images/arrows/4.webp`} />
                </FadeOut>
              </Shake>
            </FadeIn>
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/3.webp`} />
          </div>
        </div>
      ),
      11: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <FadeOut delay={3.5}>
              <img
                className="super-small-sign medium-addition-plus top-line"
                src={`${mathsNumbersUrls}/4/plus.webp`}
              />
            </FadeOut>
            <FadeOut delay={3.5}>
              <img
                className="super-small-number medium-addition-plus-one top-line"
                src={`${mathsNumbersUrls}/4/1.webp`}
              />
            </FadeOut>
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
            <BounceInDown className="arrow arrow-three" duration={1.5}>
              <BounceOutDown className="height-100" delay={2.5} duration={2}>
                <FadeOut delay={3}>
                  <img className="arrow-three-move" src={`${baseUrl}/images/arrows/3.webp`} />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/3.webp`} />
          </div>
        </div>
      ),
      12: (
        <FlipOutY className="learn-math-equation-from-the-right-container" delay={5}>
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/6.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/14/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/2.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/14/7.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line" src={`${baseUrl}/images/lines/1.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <BounceIn className="small-number flex">
              <Swing className="small-number" delay={2.25} duration={1.75}>
                <img className="small-number" src={`${mathsNumbersUrls}/14/4.webp`} />
              </Swing>
            </BounceIn>
            <Swing className="small-number" delay={2} duration={2}>
              <img className="small-number" src={`${mathsNumbersUrls}/14/3.webp`} />
            </Swing>
          </div>
        </FlipOutY>
      ),
      13: (
        <FlipInY className="learn-flex-center-center height-100" duration={2}>
          <FlipOutX className="flex align-items-center height-100" delay={4}>
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/1.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/6.webp`} />
            <img className="very-small-maths-sign" src={`${mathsNumbersUrls}/10/plus.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/2.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/7.webp`} />
            <img className="very-small-maths-sign" src={`${mathsNumbersUrls}/10/equal.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/4.webp`} />
            <img className="very-small-number" src={`${mathsNumbersUrls}/10/3.webp`} />
          </FlipOutX>
        </FlipInY>
      ),
      14: (
        <RotateIn className="learn-flex-center-center" delay={1}>
          <RotateOut delay={3.5} duration={0.5}>
            <img className="width-100" src={`${baseUrl}/images/learn/pen-and-paper-medium.webp`} />
          </RotateOut>
        </RotateIn>
      ),
      15: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <FadeIn className="math-line-small-fifth" duration={0.5}>
            <img className="small-number" src={`${mathsNumbersUrls}/5/3.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/5/1.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/5/9.webp`} />
          </FadeIn>
          <FadeIn className="math-line-small-fifth" delay={0.5} duration={0.5}>
            <img className="small-number" src={`${mathsNumbersUrls}/5/plus.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/5/4.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
          </FadeIn>
          <FadeIn className="math-line-small-fifth" delay={5} duration={0.5}>
            <img className="math-line-line-two" src={`${baseUrl}/images/lines/2.webp`} />
            <Shake className="arrow arrow-four" delay={5.5} duration={3}>
              <FadeOut className="flex height-100" delay={8.5} duration={0.5}>
                <img src={`${baseUrl}/images/arrows/5.webp`} />
              </FadeOut>
            </Shake>
          </FadeIn>
          <div className="math-line-small-fifth  visibility-hidden" />
        </div>
      ),
      16: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth position-relative width-100">
            <FadeIn className="flex width-100 height-100" delay={4.5}>
              <Pulse className="flex width-100" delay={6.5}>
                <FadeOut className="flex width-100" delay={9.5}>
                  <img
                    className="super-small-sign medium-addition-plus-two flex"
                    src={`${mathsNumbersUrls}/3/plus.webp`}
                  />
                  <img
                    className="super-small-number medium-addition-plus-one-two flex"
                    src={`${mathsNumbersUrls}/3/1.webp`}
                  />
                </FadeOut>
              </Pulse>
            </FadeIn>

            <FadeIn className="flex width-100 height-100" delay={11}>
              <Pulse className="flex width-100" delay={13}>
                <FadeOut className="flex width-100" delay={16}>
                  <img
                    className="super-small-sign medium-addition-plus-three flex"
                    src={`${mathsNumbersUrls}/7/plus.webp`}
                  />
                  <img
                    className="super-small-number medium-addition-plus-one-three flex"
                    src={`${mathsNumbersUrls}/7/1.webp`}
                  />
                </FadeOut>
              </Pulse>
            </FadeIn>
          </div>
          <div className="math-line-small-fifth">
            <Wobble delay={14} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/3.webp`} />
            </Wobble>
            <Wobble delay={7} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/1.webp`} />
            </Wobble>
            <Wobble className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/9.webp`} />
            </Wobble>
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/5/plus.webp`} />
            <Wobble delay={14.25} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/4.webp`} />
            </Wobble>
            <Wobble delay={7.25} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            </Wobble>
            <Wobble delay={0.25} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            </Wobble>
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line-two" src={`${baseUrl}/images/lines/2.webp`} />
            <BounceInDown className="arrow arrow-six" delay={1}>
              <BounceOutDown className="height-100" delay={2.5} duration={2}>
                <FadeOut delay={3}>
                  <img className="arrow-six-move" src={`${baseUrl}/images/arrows/6.webp`} />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>

            <BounceInDown className="arrow arrow-seven" delay={8}>
              <BounceOutDown className="height-100" delay={10.5} duration={2}>
                <FadeOut delay={11}>
                  <img className="arrow-seven-move" src={`${baseUrl}/images/arrows/7.webp`} />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>

            <BounceInDown className="arrow arrow-nine" delay={15}>
              <BounceOutDown className="height-100" delay={17.5} duration={2}>
                <FadeOut delay={18}>
                  <img className="arrow-nine-move" src={`${baseUrl}/images/arrows/9.webp`} />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>

            <RotateInUpRight className="arrow arrow-eight" delay={4} duration={0.5}>
              <RotateOutUpRight
                className="position-relative height-100 width-100"
                delay={6.5}
                duration={1.5}>
                <FadeOut delay={7} duration={0.5}>
                  <img className="arrow-eight-move" src={`${baseUrl}/images/arrows/8.webp`} />
                </FadeOut>
              </RotateOutUpRight>
            </RotateInUpRight>

            <RotateInUpRight className="arrow arrow-ten" delay={10.5} duration={0.5}>
              <RotateOutUpRight
                className="position-relative height-100 width-100"
                delay={13}
                duration={1.5}>
                <FadeOut className="arrow arrow-ten" delay={13.5} duration={0.5}>
                  <img className="arrow-ten-move" src={`${baseUrl}/images/arrows/10.webp`} />
                </FadeOut>
              </RotateOutUpRight>
            </RotateInUpRight>
          </div>
          <div className="math-line-small-fifth">
            <BounceIn delay={15.5} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            </BounceIn>
            <BounceIn delay={9.5} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/5/0.webp`} />
            </BounceIn>
            <BounceIn delay={3} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/5/7.webp`} />
            </BounceIn>
          </div>
        </div>
      ),
      17: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-fifth visibility-hidden" />
          <div className="math-line-small-fifth">
            <Pulse delay={4} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/3.webp`} />
            </Pulse>
            <Pulse delay={4} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/1.webp`} />
            </Pulse>
            <Pulse delay={4} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/9.webp`} />
            </Pulse>
          </div>
          <div className="math-line-small-fifth">
            <img className="small-number" src={`${mathsNumbersUrls}/5/plus.webp`} />
            <Pulse delay={5} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/4.webp`} />
            </Pulse>
            <Pulse delay={5} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            </Pulse>
            <Pulse delay={5} className="height-100">
              <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            </Pulse>
          </div>
          <div className="math-line-small-fifth">
            <img className="math-line-line-two" src={`${baseUrl}/images/lines/2.webp`} />
          </div>
          <div className="math-line-small-fifth">
            <Tada delay={6} duration={2} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/5/8.webp`} />
            </Tada>
            <Tada delay={6} duration={2} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/5/0.webp`} />
            </Tada>
            <Tada delay={6} duration={2} className="small-number flex">
              <img className="small-number" src={`${mathsNumbersUrls}/5/7.webp`} />
            </Tada>
          </div>
        </div>
      ),
      18: (
        <BounceIn className="learn-flex-center-center" delay={1} duration={5}>
          <Shake delay={2} duration={2}>
            <BounceOut delay={5.5} duration={1}>
              <img className="width-100" src={`${baseUrl}/images/mini-einstein-menu-image.webp`} />
            </BounceOut>
          </Shake>
        </BounceIn>
      )
    }}
  />
)

export default LearnMediumAdditionsPage
