import React from 'react'
import { baseUrl } from '../lib/_processUtil'
import { Helmet } from 'react-helmet-async'

const miniEinsteinLogoImage = `${baseUrl}/miniEinstein192.png`

const SEO = ({
  title = 'Mini Einstein',
  // The SEO Description must be < than 160 characters in total.
  description = 'E=MC² - Educate My Children from 2! Unique Self-Learning Artificial Intelligence Games for Kids: 100% Free, No Sign-up, Fun: Words, Maths, Geography, Reading...',
  name = 'Guillaume Bouffard',
  type = 'website',
  path = ''
}) => (
  <Helmet>
    {/* Standard metadata tags */}
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={`https://mini-einstein.com${path}`} />

    {/* Facebook tags */}
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={miniEinsteinLogoImage} />

    {/* Twitter tags */}
    <meta name="twitter:creator" content={name} />
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={miniEinsteinLogoImage} />
  </Helmet>
)

export default SEO
