import React from 'react'
import EasyMediumHardNavigationPage from '../../../src/components/EasyMediumHardNavigationPage'

const LocationsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'geography', 'learn', 'easy', 'medium', 'hard']}
    pageName="locations_page"
    customNavigationPath="/geography/locations"
  />
)

export default LocationsPage
