import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 622
const height = 690

const cities = {
  Djibouti: { x: 509, y: 488 }
}

const Djibouti = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map djibouti">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 50 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="DJ-AR"
          title="Arta"
          className="land"
          d="M231.8,466.29L238.32,473.65L260.54,488.35L265.86,489.57L273.59,494.23L277.7,492.76L278.41,488.04L278.41,488.04L282.63,487.09L282.32,489.09L280.88,489.5L285.52,495.26L285.31,496.3L280.77,496.2L282.42,497.45L280.98,500.07L278.81,500.9L281.39,505.92L288.2,509.98L293.97,516.17L296.86,517.63L299.03,517.95L308.1,526L317.38,527.25L322.33,523.38L322.02,521.29L322.95,519.94L326.56,521.5L341.1,522.97L340.69,520.98L340.79,518.99L341.82,513.24L336.46,510L334.4,510.1L330.48,504.88L332.13,502.26L336.46,502.05L336.46,500.48L338.11,500.28L339.97,495.78L341.93,498.08L343.99,498.6L347.18,496.41L351.82,495.67L353.27,493.9L362.76,492.22L365.23,488.56L370.18,490.34L376.37,488.98L379.67,486.16L381.94,484.27L383.9,481.14L392.04,482.71L393.69,481.14L401.73,481.03L401.74,476.43L404.93,481.14L408.44,480L411.02,481.03L413.9,482.18L424.01,479.36L433.24,479.04L433.24,479.04L433.24,502.26L507.66,501.53L509.1,504.26L511.26,504.81L518.63,502.62L525.9,502.13L525.9,502.13L529.3,509.79L535.48,511.26L536.2,510.52L534.66,509.16L534.25,510L530.02,509.16L534.35,507.39L541.36,512.3L545.28,516.8L545.69,522.23L550.31,528.01L552.97,528.88L539.59,550.16L539.59,550.16L483.35,523.42L468.85,525.71L425.16,534.15L404.11,573.37L404.11,573.37L336.1,613.54L336.1,613.54L271.17,615.03L216.34,562.55L210.3,490.06L225.76,471.2z"
        />
        <path
          id="DJ-AS"
          title="Ali Sabieh"
          className="land"
          d="M468.51,665.01L442.72,708.02L427.23,709.96L416.9,713.37L414.37,713.44L412.45,712.13L408.09,713.74L404.42,709.3L402.51,709.83L399.76,708.69L379.08,706.48L376.34,699.81L377.58,694.56L376.2,689.56L372.89,685.04L371.93,681.21L368.7,679.26L367.9,674.66L365.14,672.3L362.93,672.61L362.08,676.23L360.28,677.41L356.6,676.16L350.08,679.07L341.4,681.02L338.22,677.02L331.47,672.54L328.9,672.91L326.13,669.03L320.85,666.11L318.11,666.72L311.85,671.14L311.85,671.14L311.76,628.73L331.55,616.22L331.55,616.22L404.11,573.37L404.11,573.37L425.16,534.15L468.85,525.71L483.35,523.42L539.59,550.16L539.59,550.16z"
        />
        <path
          id="DJ-DI"
          title="Dikhil"
          className="land"
          d="M231.8,466.29L225.76,471.2L210.3,490.06L216.34,562.55L271.17,615.03L336.1,613.54L336.1,613.54L331.55,616.22L331.55,616.22L311.76,628.73L311.85,671.14L311.85,671.14L310.42,675.47L273.92,687.1L242.29,709.05L220.88,702.9L218.51,703.49L218.2,705.95L215.17,707.49L202.75,705.04L193.02,707.05L190.98,708.06L188.39,711.75L185.36,712.94L159.41,715.62L158.69,713.53L156.29,711.98L146.76,711.31L140.01,713L139.18,711.67L140.63,709.91L139.51,709.28L134.34,712.41L126.53,713.12L124.66,717.94L117.96,717.93L113.25,726.69L106.88,727L102.78,725.92L92.61,731.15L78.79,729.76L72.42,734.68L70.29,735.14L68.01,734.99L62.55,727.61L57.39,725.46L47.97,724.23L31.88,720.24L20.5,719.32L17.61,715.02L9.72,714.25L7.59,708.1L7.74,705.64L12.45,700.88L14.88,679.8L17,644.01L17.76,606.65L16.24,582.8L14.27,569.73L1.8,521.33L0,512.6L0.76,509.06L6.68,496.84L10.75,491.05L10.32,485.05L11.54,480.74L16.4,472.42L14.88,467.34L15.49,462.41L19.74,450.86L20.95,443.93L26.63,425.95L48.33,414.13L50.12,406.85L52.34,405.2L71.9,395.32L73.63,390.6L85.53,373.13L85.53,373.13L140.29,383.69L154.62,389.4z"
        />
        <path
          id="DJ-DJ"
          title="Djibouti"
          className="land"
          d="M527.03,493.27l0.82,1.99l-1.55,0.42l-0.72,-1.88L527.03,493.27zM513,467.64l-0.21,4.09l2.07,1.14l1.05,2.32l-2.38,1.02l0.21,3.35l2.78,6.17l2.99,3.45l0.52,4.6l3.92,2.4l1.96,5.93l0,0l-7.27,0.5l-7.37,2.19l-2.16,-0.55l-1.44,-2.73l-74.42,0.73l0,-23.22l0,0l8.09,-0.62l4.33,1.46l13.2,0.11l0.72,-1.05l7.73,-0.63l13.41,3.35l-1.75,-1.67l0.1,-1.57l4.23,-0.42l5.16,1.57l3.51,4.2l3.09,1.03l4.64,-2.82l6.19,-0.94l1.13,-1.67l2.48,0.43l1.85,-4.92l-2.99,0.1l-0.21,1.36l-1.55,-0.1l1.86,-2.61l-1.24,0.31l-0.42,-0.94l-1.23,1.68l-0.31,-1.05l2.27,-1.87l3.59,-0.09l0.44,-3.17L513,467.64z"
        />
        <path
          id="DJ-OB"
          title="Obock"
          className="land"
          d="M505.08,56.86L509.36,58.56L511.73,69.82L518.68,87.2L524.75,92.8L535.74,108.99L549.34,124.89L558.01,139.91L562.2,143.24L563.51,146.33L565.7,147.26L565.49,150.51L569.1,153.45L570.44,153.45L574.98,148.73L573.74,147.68L572.93,144.04L574.66,143.91L578.07,146.42L576.32,153.34L578.07,164.57L580.86,172.34L583.33,175.9L586.32,176.53L589.42,182.41L595.4,211.98L594.88,215.86L596.22,223.73L599.42,225.93L600.86,229.92L603.93,233.9L604.88,238.72L608.49,242.92L609.11,255.18L607.67,258.01L610.62,264.2L609.94,267.02L611.9,277.93L611.9,283.69L610.96,293.64L608.49,303.7L593.85,333.45L585.29,334.92L570.44,333.56L564.77,341.2L547.66,340.45L542.08,341.41L540.85,343.3L535.79,341.62L525.38,343.72L522.9,345.29L516.1,353.98L510.94,358.07L508.88,362.88L505.48,363.51L501.76,367.59L501.14,370.32L499.29,371.36L498.26,374.5L493.93,379.23L492.07,379.84L489.6,384.97L489.6,384.97L481.75,380.25L475.23,374.12L468.71,361.12L467.74,355.72L462.67,345.67L450.35,333.4L390.39,267.86L388.7,265.41L388.46,262.46L383.39,258.04L376.86,254.85L368.41,252.89L358.99,247.73L347.88,237.66L343.29,232.01L335.07,212.6L332.66,210.39L331.21,206.71L335.31,199.09L343.29,194.42L344.2,189.83L344.2,189.83L346.78,190.92L345.58,186.66L351.6,180.46L353.99,180.14L358.5,181.68L362.92,180.3L364.89,178.05L364.69,175.3L366.9,174.29L369.2,169.88L378.29,168.15L383.77,168.4L387.32,166.02L391.36,161.3L389.36,151.42L386.47,147.76L383.84,147.55L380.72,144L382.62,134.26L393.18,119.76L397.86,120.3L400.52,117.4L404.14,117.61L404.99,115.37L403.17,112.81L405.66,110.5L404.18,108.7L404.35,107.29L407.02,105.92L407.1,101.76L412.34,100.25L414.1,94.31L415.95,92.13L420.33,91.56L423.2,93.53L425.66,93.86L429.65,89.89L434.73,89.67L437.93,88.12L444.05,81.6z"
        />
        <path
          id="DJ-TA"
          title="Tadjourah"
          className="land"
          d="M272.48,134.61L277.03,134.98L287.35,139.4L304.14,151.72L325.23,175.31L344.2,189.83L344.2,189.83L343.29,194.42L335.31,199.09L331.21,206.71L332.66,210.39L335.07,212.6L343.29,232.01L347.88,237.66L358.99,247.73L368.41,252.89L376.86,254.85L383.39,258.04L388.46,262.46L388.7,265.41L390.39,267.86L450.35,333.4L462.67,345.67L467.74,355.72L468.71,361.12L475.23,374.12L481.75,380.25L489.6,384.97L489.6,384.97L488.05,386.96L484.23,387.8L481.55,391.05L481.55,393.04L479.28,393.77L477.12,397.33L467.11,398.9L464.23,398.37L461.24,399.94L459.48,399.63L457.5,397.34L453.41,399.31L448.24,399.21L443.71,403.29L441.54,408.63L435.87,412.4L434.12,411.04L434.01,408.53L432.4,409.89L433.19,411.36L431.95,411.88L429.99,411.25L428.55,408.63L421.43,408.84L419.58,407.58L415.97,408.73L411.22,406.12L409.15,406.53L403.69,411.46L396.79,411.88L393.9,416.17L389.98,419.31L375.23,421.11L372.76,423.91L368.63,432.91L364.61,437.3L363.48,446.09L360.18,449.23L353.27,452.58L345.23,463.25L343.16,468.9L342.23,481.45L339.55,484.07L338.83,486.68L337.08,487.1L338.93,493.16L330.48,489.71L330.17,492.43L327.07,489.19L323.88,488.88L320.99,487.1L320.58,488.04L326.56,492.75L322.23,492.85L310.78,486.05L302.12,490.45L298.51,486.78L297.58,487.83L292.94,482.29L289.13,481.77L284.9,479.57L281.08,479.67L278.3,484.27L278.19,484.8L277.07,486.79L278.41,488.04L278.41,488.04L277.7,492.76L273.59,494.23L265.86,489.57L260.54,488.35L238.32,473.65L231.8,466.29L231.8,466.29L231.8,466.29L231.8,466.29L154.62,389.4L140.29,383.69L85.53,373.13L85.53,373.13L99.83,352.17L113.57,334.73L116.67,326.8L124.97,313.28L131.69,297.33L134.87,294.29L144.96,292.39L149.05,276.89L154.01,270.67L161.2,257.5L170.94,246.42L180.25,232.29L203.41,209.37L212,181.95L232.1,154.69L241.13,143.83L254.94,129.98L259.18,129.9L265.83,133.13z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Djibouti))
