import React from 'react'
import { minusIndexNumber } from './_processUtil'

const getMathematicsSign = type => {
  const mathematicsSigns = {
    additions: 'plus',
    subtractions: 'minus',
    multiplications: 'times',
    divisions: 'by'
  }

  return mathematicsSigns[type]
}

// This method is so a random number/directory is selected and we use the same style for numbers in questions (present, index - 1) and answers for I dont know (past, - 3)
const getRandomImagesDirectory = (pageName, randomList, minusIndex = 1) =>
  pageName === 'beginner_additions'
    ? 0
    : randomList
      ? randomList[randomList.length - minusIndex]
      : 1

const mapNumberImages = (numbers, pageName, randomList, getImageUrl, altName, existingRandom) => {
  const stringNumbersArray = numbers
    .toString()
    .split('')
    .map(n => (n === '-' ? 'minus' : n))
  const randomImagesDirectory =
    existingRandom || getRandomImagesDirectory(pageName, randomList, minusIndexNumber)

  return stringNumbersArray.map((number, index) => (
    <img
      key={index}
      className={`number-image ${pageName.replace('_', '-')}-number ${
        number === 'minus' && 'result-minus'
      }`}
      alt={altName}
      src={getImageUrl(randomImagesDirectory, number)}
    />
  ))
}

const getMathDisplay = (numbers, type, pageName, randomList, getImageUrl) => {
  const random = getRandomImagesDirectory(pageName, randomList)
  const firstNumberImages = mapNumberImages(
    numbers[0],
    pageName,
    randomList,
    getImageUrl,
    'first-number',
    random
  )
  const secondNumberImages = mapNumberImages(
    numbers[1],
    pageName,
    randomList,
    getImageUrl,
    'second-number',
    random
  )

  const signImage = (
    <img
      className="mathematics-sign"
      key="sign"
      alt="sign"
      src={getImageUrl(random, getMathematicsSign(type))}
    />
  )

  return [
    <div className="first-numbers" key="first-numbers">
      {firstNumberImages}
    </div>,
    signImage,
    <div className="second-numbers" key="second-numbers">
      {secondNumberImages}
    </div>
  ]
}

export { getMathDisplay, mapNumberImages }
