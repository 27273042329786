import { Component } from 'react'

class PassComponent extends Component {
  componentDidMount() {
    const {
      getNewQuestionAndAnswer,
      resetWrongAnswerList,
      resetReduxStateForNextQuestion,
      toggleIsPassedValue
    } = this.props

    getNewQuestionAndAnswer()
    resetWrongAnswerList()
    resetReduxStateForNextQuestion()
    toggleIsPassedValue()
  }

  render() {
    return null
  }
}

export default PassComponent
