import React from 'react'
import EasyMediumHardNavigationPage from '../../../src/components/EasyMediumHardNavigationPage'
import { common } from '../../lib/languageUtil'

const CapitalsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'geography', 'learn', 'easy', 'medium', 'hard', 'reverse']}
    pageName="capitals_page"
    customNavigationPath="/geography/capitals"
    extraNavigationCommands={{
      [common('reverse').toLowerCase()]: '/geography/capitals/reverse'
    }}
  />
)

export default CapitalsPage
