import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 808
const height = 346

const cities = {
  Dili: { x: 372, y: 106 }
}

const TimorLeste = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map timor-leste">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="TL-LA"
          title="Lautem"
          className="land"
          d="M656.72,153.74L727.86,132.78L798.81,68.79L714.71,42.71L646.96,72.71L676.97,119.14L659.3,137.88L656.72,153.74z"
        />
        <path
          id="TL-VI"
          title="Viqueque"
          className="land"
          d="M605.81,134.63L590.4,115.94L584.77,140.32L513.79,134.1L487.95,161.87L513.3,212.49L656.72,153.74L659.3,137.88L605.81,134.63z"
        />
        <path
          id="TL-MF"
          title="Manufahi"
          className="land"
          d="M432.45,151.82L404.04,162.01L377.48,212.29L399.63,260.06L491.32,226.61L432.45,151.82z"
        />
        <path
          id="TL-MT"
          title="Manatuto"
          className="land"
          d="M514.24,90.31L438.12,87.38L442.36,92.94L422.36,109.96L432.45,151.82L491.32,226.61L513.3,212.49L487.95,161.87L513.79,134.1L514.24,90.31z"
        />
        <path
          id="TL-ER"
          title="Ermera"
          className="land"
          d="M357.03,126.4L280.37,152.13L337.19,208.13L355.38,177.01L357.03,126.4z"
        />
        <path
          id="TL-CO"
          title="Cova Lima"
          className="land"
          d="M355.39,271.76L336.59,220.91L312.44,264.06L273.92,254.54L226.61,264.66L251.8,332.08L355.39,271.76z"
        />
        <path
          id="TL-BO"
          title="Bobonaro"
          className="land"
          d="M271.42,152.57L261.84,148.65L214.46,221.73L259.06,209.71L273.92,254.54L312.44,264.06L336.59,220.91L337.19,208.13L280.37,152.13L271.42,152.57z"
        />
        <path
          id="TL-BA"
          title="Baucau"
          className="land"
          d="M646.96,72.71L514.24,90.31L513.79,134.1L584.77,140.32L590.4,115.94L605.81,134.63L659.3,137.88L676.97,119.14L646.96,72.71z"
        />
        <path
          id="TL-AN"
          title="Ainaro"
          className="land"
          d="M404.04,162.01L355.38,177.01L337.19,208.13L336.59,220.91L355.39,271.76L399.63,260.06L377.48,212.29L404.04,162.01z"
        />
        <path
          id="TL-AL"
          title="Aileu"
          className="land"
          d="M421.87,110.1L361.18,119.15L357.03,126.4L355.38,177.01L404.04,162.01L432.45,151.82L422.36,109.96L421.87,110.1z"
        />
        <path
          id="TL-OE"
          title="Oecussi"
          className="land"
          d="M56.45,335.89L103.34,256.84L1.19,296.67L56.45,335.89z"
        />
        <path
          id="TL-LI"
          title="Liquica"
          className="land"
          d="M361.18,119.15L355.83,106.37L271.89,121.9L261.84,148.65L271.42,152.57L280.37,152.13L357.03,126.4L361.18,119.15z"
        />
        <path
          id="TL-DI"
          title="Dili"
          className="land"
          d="M442.36,92.94L438.12,87.38L355.83,106.37L361.18,119.15L421.87,110.1L422.36,109.96L442.36,92.94zM390.49,0L359.85,30.92L378.47,44.03L390.49,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(TimorLeste))
