import React from 'react'
import {
  FadeIn,
  FadeOut,
  Shake,
  Swing,
  Jello,
  Flip,
  FlipInX,
  FlipInY,
  FlipOutX,
  FlipOutY,
  BounceOut,
  BounceIn,
  Pulse,
  RotateIn,
  RotateOut,
  Tada,
  BounceInDown,
  BounceOutDown,
  RotateInUpRight,
  RotateInDownRight,
  RotateOutDownRight,
  SlideInRight,
  Hinge
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnMediumMultiplicationsPage = () => (
  <LearnMathematicsPage
    pageName="learn_medium_multiplications"
    sentencesNumberPlusRedirect={14}
    startTimesOf={{
      2: 9000,
      3: 23000,
      4: 37000,
      5: 52000,
      6: 67500,
      7: 76500,
      8: 85000,
      9: 97000,
      10: 108000,
      11: 120000,
      12: 134500,
      13: 141500,
      14: 148500
    }}
    animations={{
      1: (
        <div className="learn-flex-center-center">
          <FlipInX className="position-absolute">
            <FlipOutX delay={3.5}>
              <img
                className="max-100"
                src={`${baseUrl}/images/learn/learn-medium-multiplications-1-1.webp`}
              />
            </FlipOutX>
          </FlipInX>
          <FlipInY delay={4.5} className="position-absolute">
            <FlipOutY delay={8}>
              <img
                className="max-100"
                src={`${baseUrl}/images/learn/learn-medium-multiplications-1-2.webp`}
              />
            </FlipOutY>
          </FlipInY>
        </div>
      ),
      2: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-eight" />
          <BounceIn delay={1.75} className="math-line-small-eight">
            <Flip className="height-100" delay={8.5} duration={0.25}>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
            </Flip>
            <Flip className="height-100" delay={8.25} duration={0.25}>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
            </Flip>
            <Flip className="height-100" delay={8} duration={0.25}>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
            </Flip>
          </BounceIn>
          <BounceIn delay={3} className="math-line-small-eight mathematics-question-display">
            <img
              className="small-number-with-margins small-number-with-margins-2"
              src={`${mathsNumbersUrls}/19/times.webp`}
            />
            <Jello className="height-100" delay={12}>
              <Flip className="height-100" delay={8.35} duration={0.25}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              </Flip>
            </Jello>
            <Jello className="height-100" delay={11.25}>
              <Flip className="height-100" delay={8.1} duration={0.25}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
              </Flip>
            </Jello>
          </BounceIn>
          <FadeIn className="math-line-small-eight flex" delay={3}>
            <SlideInRight className="math-line-small-eight height-100" delay={3} duration={0.5}>
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <FadeIn delay={4}>
                <Shake className="height-100" delay={5} duration={2}>
                  <FadeOut delay={8}>
                    <img
                      className="arrow arrow-twenty-five"
                      src={`${baseUrl}/images/arrows/25.webp`}
                    />
                  </FadeOut>
                </Shake>
              </FadeIn>
            </SlideInRight>
          </FadeIn>
          <div className="math-line-small-eight" />
          <div className="math-line-small-eight" />
          <div className="math-line-small-eight" />
          <div className="math-line-small-eight" />
        </div>
      ),
      3: (
        <div className="learn-math-equation-from-the-right-container">
          <div className="math-line-small-eight">
            <BounceIn className="small-number-with-container" delay={10}>
              <img src={`${mathsNumbersUrls}/13/2.webp`} />
            </BounceIn>
            <div className="small-number-with-container visibility-hidden">
              <img src={`${mathsNumbersUrls}/19/0.webp`} />
            </div>
          </div>
          <div className="math-line-small-eight">
            <Pulse className="height-100" delay={1.75}>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
            </Pulse>
            <Pulse className="height-100" delay={1.75}>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
            </Pulse>
            <Flip delay={5}>
              <Pulse className="height-100" delay={1.75}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
              </Pulse>
            </Flip>
          </div>
          <div className="math-line-small-eight mathematics-question-display">
            <img
              className="small-number-with-margins small-number-with-margins-2"
              src={`${mathsNumbersUrls}/19/times.webp`}
            />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
            <Flip className="height-100" delay={5}>
              <Pulse className="height-100" delay={1.25}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
              </Pulse>
            </Flip>
          </div>
          <div className="math-line-small-eight flex">
            <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
            <FadeIn delay={3}>
              <Shake className="height-100" delay={4}>
                <FadeOut delay={5}>
                  <img
                    className="arrow arrow-twenty-five"
                    src={`${baseUrl}/images/arrows/25.webp`}
                  />
                </FadeOut>
              </Shake>
            </FadeIn>
            <BounceInDown className="arrow arrow-twenty-six" delay={11}>
              <BounceOutDown className="height-100" delay={12.5} duration={2}>
                <FadeOut className="height-100" delay={13}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/26.webp`} />
                </FadeOut>
              </BounceOutDown>
            </BounceInDown>
            <RotateInUpRight className="arrow arrow-twenty-seven" delay={9}>
              <RotateOut className="height-100" delay={11} duration={0.5}>
                <img className="height-100" src={`${baseUrl}/images/arrows/27.webp`} />
              </RotateOut>
            </RotateInUpRight>
          </div>
          <BounceIn delay={12} className="math-line-small-eight">
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/8.webp`} />
            </div>
          </BounceIn>
          <div className="math-line-small-eight" />
          <div className="math-line-small-eight flex" />
          <div className="math-line-small-eight" />
        </div>
      ),
      4: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight flex">
              <BounceIn className="small-number-with-container" delay={5}>
                <img src={`${mathsNumbersUrls}/13/4.webp`} />
              </BounceIn>
              <Hinge className="height-100" delay={12}>
                <Shake className="small-number-with-container" delay={10.5}>
                  <img src={`${mathsNumbersUrls}/13/2.webp`} />
                </Shake>
              </Hinge>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/19/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
              <Flip className="height-100" delay={1}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              </Flip>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              <Flip className="height-100" delay={1}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
              </Flip>
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <BounceInDown className="arrow arrow-twenty-eight" delay={6} duration={0.5}>
                <BounceOutDown className="height-100" delay={7}>
                  <FadeOut className="height-100" delay={7}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/28.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <RotateInUpRight className="arrow arrow-twenty-nine" delay={4.5} duration={0.5}>
                <RotateOut className="height-100" delay={5.5} duration={0.5}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/29.webp`} />
                </RotateOut>
              </RotateInUpRight>

              <BounceInDown className="arrow arrow-thirty" delay={11}>
                <BounceOutDown className="height-100" delay={12.5}>
                  <FadeOut className="height-100" delay={12.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/30.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>
            </div>
            <div className="math-line-small-eight">
              <BounceIn delay={6.5} duration={0.5}>
                <Shake className="position-absolute height-100" delay={10.25}>
                  <BounceOut className="position-absolute height-100" delay={11}>
                    <div className="small-number-with-small-container">
                      <img src={`${mathsNumbersUrls}/27/2.webp`} />
                    </div>
                  </BounceOut>
                </Shake>
              </BounceIn>
              <BounceIn delay={12}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/4.webp`} />
                </div>
              </BounceIn>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/8.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight" />
            <div className="math-line-small-eight flex" />
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      5: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight">
              <BounceOut delay={7}>
                <Shake className="small-number-with-container" delay={6}>
                  <img src={`${mathsNumbersUrls}/13/4.webp`} />
                </Shake>
              </BounceOut>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/19/0.webp`} />
              </div>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/19/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <Flip className="height-100" delay={2.5}>
                <Flip className="height-100" delay={10.5}>
                  <img
                    className="small-number-with-margins"
                    src={`${mathsNumbersUrls}/19/1.webp`}
                  />
                </Flip>
              </Flip>
              <Flip className="height-100" delay={25.5}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              </Flip>
              <Flip className="height-100" delay={10.5}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
              </Flip>
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              <Flip className="height-100" delay={25}>
                <Flip className="height-100" delay={2.5}>
                  <img
                    className="small-number-with-margins"
                    src={`${mathsNumbersUrls}/19/7.webp`}
                  />
                </Flip>
              </Flip>
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <BounceInDown className="arrow arrow-thirty-one" delay={3} duration={0.5}>
                <BounceOutDown className="height-100" delay={3.5}>
                  <FadeOut className="height-100" delay={3.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/31.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <RotateInDownRight className="arrow arrow-thirty-two" delay={7} duration={0.5}>
                <RotateOutDownRight className="height-100" delay={7.5} duration={0.5}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/32.webp`} />
                </RotateOutDownRight>
              </RotateInDownRight>
            </div>
            <div className="math-line-small-eight flex">
              <BounceIn delay={23}>
                <Tada className="height-100" delay={12}>
                  <div className="small-number-with-small-container">
                    <img src={`${mathsNumbersUrls}/27/1.webp`} />
                  </div>
                </Tada>
              </BounceIn>
              <BounceIn delay={20}>
                <BounceOut className="position-absolute height-100" delay={6.5}>
                  <div className="small-number-with-small-container">
                    <img src={`${mathsNumbersUrls}/27/7.webp`} />
                  </div>
                </BounceOut>
              </BounceIn>
              <BounceIn delay={8}>
                <Tada className="height-100" delay={12}>
                  <div className="small-number-with-small-container">
                    <img src={`${mathsNumbersUrls}/27/1.webp`} />
                  </div>
                </Tada>
              </BounceIn>
              <Tada className="height-100" delay={12}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/4.webp`} />
                </div>
              </Tada>
              <Tada className="height-100" delay={12}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/8.webp`} />
                </div>
              </Tada>
            </div>
            <div className="math-line-small-eight" />
            <div className="math-line-small-eight flex" />
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      6: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight" />
            <div className="math-line-small-eight">
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <Flip className="height-100" delay={2}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              </Flip>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
            </div>
            <div className="math-line-small-eight">
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/4.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/8.webp`} />
              </div>
            </div>
            <BounceIn delay={5.5} className="math-line-small-eight">
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/0.webp`} />
              </div>
            </BounceIn>
            <div className="math-line-small-eight flex" />
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      7: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight">
              <BounceIn className="small-number-with-container" delay={6.5}>
                <img src={`${mathsNumbersUrls}/13/1.webp`} />
              </BounceIn>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/13/0.webp`} />
              </div>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/19/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              <Flip className="height-100" delay={1.5}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
              </Flip>
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <Flip className="height-100" delay={10.5}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              </Flip>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <BounceInDown className="arrow arrow-thirty-three" delay={3} duration={0.5}>
                <BounceOutDown className="height-100" delay={3.5} duration={0.5}>
                  <FadeOut className="height-100" delay={3.5} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/33.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <RotateInUpRight className="arrow arrow-thirty-four" delay={5.5} duration={0.5}>
                <RotateOut className="height-100" delay={6} duration={0.5}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/34.webp`} />
                </RotateOut>
              </RotateInUpRight>
            </div>
            <div className="math-line-small-eight">
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/4.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/8.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <BounceIn delay={4}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/2.webp`} />
                </div>
              </BounceIn>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight flex" />
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      8: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight">
              <BounceIn className="small-number-with-container" delay={14}>
                <img src={`${mathsNumbersUrls}/13/1.webp`} />
              </BounceIn>
              <Hinge className="small-number-with-container" delay={8.5} duration={2}>
                <img src={`${mathsNumbersUrls}/13/1.webp`} />
              </Hinge>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/13/0.webp`} />
              </div>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/19/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
              <Flip className="height-100" delay={1}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              </Flip>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <Flip className="height-100" delay={18.5}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              </Flip>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <RotateInUpRight className="arrow arrow-thirty-six" delay={4.5} duration={0.5}>
                <RotateOut className="height-100" delay={5} duration={0.5}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/36.webp`} />
                </RotateOut>
              </RotateInUpRight>

              <BounceInDown className="arrow arrow-forty-four" delay={3} duration={0.5}>
                <BounceOutDown className="height-100" delay={3.5} duration={0.5}>
                  <FadeOut className="height-100" delay={3.5} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/44.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <BounceInDown className="arrow arrow-thirty-five" delay={9} duration={0.5}>
                <BounceOutDown className="height-100" delay={9.5} duration={0.5}>
                  <FadeOut className="height-100" delay={9.5} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/35.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>
            </div>
            <div className="math-line-small-eight">
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/4.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/8.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <BounceIn delay={11.5}>
                <BounceOut
                  className="position-absolute small-number-with-small-container"
                  delay={9}>
                  <img src={`${mathsNumbersUrls}/27/8.webp`} />
                </BounceOut>
              </BounceIn>
              <BounceIn delay={10}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/9.webp`} />
                </div>
              </BounceIn>

              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/2.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight flex" />
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      9: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight">
              <Hinge delay={4}>
                <Swing className="small-number-with-container" delay={3}>
                  <img src={`${mathsNumbersUrls}/13/1.webp`} />
                </Swing>
              </Hinge>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/13/0.webp`} />
              </div>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/13/0.webp`} />
              </div>
              <div className="small-number-with-container visibility-hidden">
                <img src={`${mathsNumbersUrls}/19/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <Flip className="height-100">
                <Flip className="height-100" delay={6}>
                  <img
                    className="small-number-with-margins"
                    src={`${mathsNumbersUrls}/19/1.webp`}
                  />
                </Flip>
              </Flip>
              <Flip className="height-100" delay={18}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              </Flip>
              <Flip className="height-100" delay={6}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
              </Flip>
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <Flip className="height-100" delay={37}>
                <Flip className="height-100">
                  <img
                    className="small-number-with-margins"
                    src={`${mathsNumbersUrls}/19/3.webp`}
                  />
                </Flip>
              </Flip>
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <BounceInDown className="arrow arrow-thirty-eight" delay={4} duration={0.5}>
                <BounceOutDown className="height-100" delay={4.5} duration={0.5}>
                  <FadeOut className="height-100" delay={4.5} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/38.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>
            </div>
            <div className="math-line-small-eight">
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/4.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/8.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <BounceIn delay={22}>
                <BounceOut className="position-absolute height-100" delay={16} duration={0.5}>
                  <div className="small-number-with-small-container">
                    <img src={`${mathsNumbersUrls}/27/3.webp`} />
                  </div>
                </BounceOut>
              </BounceIn>
              <BounceIn delay={17}>
                <Tada className="height-100" delay={8}>
                  <div className="small-number-with-small-container">
                    <img src={`${mathsNumbersUrls}/27/4.webp`} />
                  </div>
                </Tada>
              </BounceIn>
              <Tada className="height-100" delay={8}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/9.webp`} />
                </div>
              </Tada>
              <Tada className="height-100" delay={8}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/2.webp`} />
                </div>
              </Tada>
              <Tada className="height-100" delay={8}>
                <Flip className="height-100" delay={7.5}>
                  <div className="small-number-with-small-container">
                    <img src={`${mathsNumbersUrls}/27/0.webp`} />
                  </div>
                </Flip>
              </Tada>
            </div>
            <div className="math-line-small-eight flex" />
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      10: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight" />
            <div className="math-line-small-eight">
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
            </div>
            <div className="math-line-small-eight">
              <Shake className="height-100 flex" delay={3.5}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/1.webp`} />
                </div>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/1.webp`} />
                </div>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/4.webp`} />
                </div>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/8.webp`} />
                </div>
              </Shake>
            </div>
            <div className="math-line-small-eight">
              <RotateIn delay={2}>
                <img
                  className="small-number-with-small-container height-75"
                  src={`${mathsNumbersUrls}/27/plus.webp`}
                />
              </RotateIn>
              <Shake className="height-100 flex" delay={5}>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/4.webp`} />
                </div>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/9.webp`} />
                </div>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/2.webp`} />
                </div>
                <div className="small-number-with-small-container">
                  <img src={`${mathsNumbersUrls}/27/0.webp`} />
                </div>
              </Shake>
            </div>
            <FadeIn className="math-line-small-eight flex">
              <SlideInRight className="math-line-small-eight height-100" duration={0.5}>
                <img src={`${baseUrl}/images/lines/7.webp`} />
              </SlideInRight>
            </FadeIn>
            <div className="math-line-small-eight" />
          </div>
        </div>
      ),
      11: (
        <div className="learn-flex-center-center">
          <div className="learn-math-equation-from-the-right-container">
            <div className="math-line-small-eight" />
            <div className="math-line-small-eight">
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
            </div>
            <div className="math-line-small-eight mathematics-question-display">
              <img
                className="small-number-with-margins small-number-with-margins-2"
                src={`${mathsNumbersUrls}/19/times.webp`}
              />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
              <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
            </div>
            <div className="math-line-small-eight flex">
              <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
              <BounceIn className="small-number-thirteen-plus" delay={9}>
                <BounceOut className="height-100" delay={11}>
                  <img className="height-100" src={`${mathsNumbersUrls}/13/plus.webp`} />
                </BounceOut>
              </BounceIn>
              <BounceIn className="small-number-thirteen-one" delay={9}>
                <BounceOut className="height-100" delay={11}>
                  <img className="height-100" src={`${mathsNumbersUrls}/13/1.webp`} />
                </BounceOut>
              </BounceIn>
            </div>
            <div className="math-line-small-eight">
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/1.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/4.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/8.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight">
              <img
                className="small-number-with-small-container height-75"
                src={`${mathsNumbersUrls}/27/plus.webp`}
              />
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/4.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/9.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/2.webp`} />
              </div>
              <div className="small-number-with-small-container">
                <img src={`${mathsNumbersUrls}/27/0.webp`} />
              </div>
            </div>
            <div className="math-line-small-eight flex">
              <img src={`${baseUrl}/images/lines/7.webp`} />

              <FadeIn duration={0.5}>
                <Shake className="height-100" delay={0.5}>
                  <FadeOut delay={1.5} duration={0.5}>
                    <img
                      className="arrow arrow-twenty-five"
                      src={`${baseUrl}/images/arrows/25.webp`}
                    />
                  </FadeOut>
                </Shake>
              </FadeIn>

              <BounceInDown className="arrow arrow-thirty-nine" delay={2} duration={0.5}>
                <BounceOutDown className="height-100" delay={2.5} duration={0.5}>
                  <FadeOut className="height-100" delay={2.5} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/39.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <BounceInDown className="arrow arrow-forty" delay={3.5} duration={0.5}>
                <BounceOutDown className="height-100" delay={4} duration={0.5}>
                  <FadeOut className="height-100" delay={4} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/40.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <BounceInDown className="arrow arrow-forty-one" delay={6.5} duration={0.5}>
                <BounceOutDown className="height-100" delay={7} duration={0.5}>
                  <FadeOut className="height-100" delay={7} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/41.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <BounceInDown className="arrow arrow-forty-two" delay={10.5} duration={0.5}>
                <BounceOutDown className="height-100" delay={11} duration={0.5}>
                  <FadeOut className="height-100" delay={11} duration={0.5}>
                    <img className="height-100" src={`${baseUrl}/images/arrows/42.webp`} />
                  </FadeOut>
                </BounceOutDown>
              </BounceInDown>

              <RotateInUpRight className="arrow arrow-forty-three" delay={8} duration={0.5}>
                <RotateOut className="height-100" delay={8.5} duration={0.5}>
                  <img className="height-100" src={`${baseUrl}/images/arrows/43.webp`} />
                </RotateOut>
              </RotateInUpRight>
            </div>
            <div className="math-line-small-eight">
              <BounceIn delay={11}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              </BounceIn>
              <BounceIn delay={7}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/0.webp`} />
              </BounceIn>
              <BounceIn delay={4}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
              </BounceIn>
              <BounceIn delay={2.5}>
                <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/8.webp`} />
              </BounceIn>
            </div>
          </div>
        </div>
      ),
      12: (
        <RotateOut
          className="learn-math-equation-from-the-right-container"
          delay={8}
          duration={0.5}>
          <div className="math-line-small-eight" />
          <Pulse className="math-line-small-eight">
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/1.webp`} />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/4.webp`} />
          </Pulse>
          <Pulse className="math-line-small-eight mathematics-question-display" delay={1}>
            <img
              className="small-number-with-margins small-number-with-margins-2"
              src={`${mathsNumbersUrls}/19/times.webp`}
            />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/3.webp`} />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/7.webp`} />
          </Pulse>
          <div className="math-line-small-eight flex">
            <img className="math-line-line-six" src={`${baseUrl}/images/lines/6.webp`} />
          </div>
          <div className="math-line-small-eight">
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/1.webp`} />
            </div>
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/1.webp`} />
            </div>
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/4.webp`} />
            </div>
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/8.webp`} />
            </div>
          </div>
          <div className="math-line-small-eight">
            <img
              className="small-number-with-small-container height-75"
              src={`${mathsNumbersUrls}/27/plus.webp`}
            />
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/4.webp`} />
            </div>
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/9.webp`} />
            </div>
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/2.webp`} />
            </div>
            <div className="small-number-with-small-container">
              <img src={`${mathsNumbersUrls}/27/0.webp`} />
            </div>
          </div>
          <div className="math-line-small-eight flex">
            <img src={`${baseUrl}/images/lines/7.webp`} />
          </div>
          <Tada className="math-line-small-eight" delay={4}>
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/0.webp`} />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/6.webp`} />
            <img className="small-number-with-margins" src={`${mathsNumbersUrls}/19/8.webp`} />
          </Tada>
        </RotateOut>
      ),
      13: (
        <div className="learn-flex-center-center">
          <RotateIn className="position-absolute">
            <RotateOut delay={5.5}>
              <img
                className="max-100 height-100"
                src={`${baseUrl}/images/learn/learn-medium-multiplications-13.webp`}
              />
            </RotateOut>
          </RotateIn>
        </div>
      )
    }}
  />
)

export default LearnMediumMultiplicationsPage
