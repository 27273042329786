import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 775
const height = 751

const cities = {
  Praia: { x: 400, y: 400 }
}

const CapeVerde = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map cape-verde">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`175 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="CV-BR"
          title="Brava"
          className="land"
          d="M397.42,759.51l0.71,1l-0.8,1.27l1.06,-0.46l2.3,1.46l-2.39,1.82l0.89,0.27l-0.18,3.37l0.89,0.09l0.8,2.91l1.77,2.27l-0.97,3l1.77,1.73l-1.42,0.82l-0.18,3.37l-1.42,0.46v3.46l-6.46,3.28l-2.66,2.37l-1.24,-1l0.44,-1.91l-3.45,1.09l-0.09,-0.91l-3.19,-1.18l-3.63,-6.28l-1.42,1.55l-2.04,-1.73l1.06,-4.91l-1.33,-1l1.06,-1.09l-1.24,-1.18l0.27,-3l1.5,-0.46l0.44,0.82h2.04l1.24,-0.82l-1.06,-4.46l0.8,0.36l1.59,-2l-0.09,-1.55l0.89,1.18l1.15,0.36l0.8,-1l1.68,1.55l1.59,-1.91l4.78,-0.82l0.53,-1.91l1.86,-0.09l0.62,1L397.42,759.51zM405.39,736.02l0.53,1.55l-2.3,0.09l0.18,-1.09l1.37,0.3L405.39,736.02zM408.22,735.93l0.53,1.37l-0.97,-0.27L408.22,735.93zM397.25,734.11l1.24,1.82l-1.24,1v1.82l-3.45,1.64l-1.33,-1.37l0.09,-1.18l0.8,0.55l0.44,-2.46l1.95,-1.73l0.89,1.09L397.25,734.11zM412.65,732.01l1.86,2l-0.18,1.64l-1.15,-0.37l0.44,0.82l-2.16,1.18l-0.76,1.82l-1.06,-1.18l-0.97,0.55l0.89,-1.55l2.57,-1.27L412.65,732.01z"
        />
        <path
          id="CV-BV"
          title="Boa Vista"
          className="land"
          d="M953.61,340.03l3.45,1.56l0.97,2.56l-2.57,-0.55L953.61,340.03zM1001.05,322.99v1.1l1.06,-1.37l0.09,1.28l1.15,-0.37l-0.71,1.19l0.8,0.92l3.19,-0.55l7.35,3.67l6.2,0.55l1.68,1.74l3.45,-0.64l-0.35,0.92l0.97,-0.09v0.83l2.13,-0.37v0.83l-1.86,1.1l1.5,1.28l-1.06,2.38l1.06,2.75l9.56,10.26l0.8,-0.27v4.67l1.5,1.65l-1.95,-0.64l-1.24,3.02l1.06,3.66l1.24,1.01l-0.27,2.29l2.04,4.85l-1.24,0.18v1.46l-3.63,5.49l-0.53,4.3l-2.13,2.75l-1.77,0.64l-0.97,-0.92l-1.95,2.11l-2.74,0.73l-1.95,3.11l-2.04,0.82l-1.24,-0.46l-8.06,4.76l-1.24,-0.64l-1.77,3.66l-5.22,1.1l-3.72,3.11l-2.21,3.48l-2.39,0.55l-1.59,-1.1h-14.07l-7.53,1.37l-2.04,-1.46l-1.86,-4.76l-2.3,-1.83l-2.66,-1.1l-0.88,0.82l-5.13,-1.28l-7.79,-3.57l-2.13,-2.75l0.44,-2.56l-1.15,-1.1l-0.97,1.1l0.09,-1.65l-1.59,-2.29l1.59,-2.75l0.35,-5.77l1.59,-1.92l-1.33,-3.3l3.72,-4.21l1.5,-0.92h2.74l7.26,-6.32l1.5,-2.01l-0.71,-3.75l1.06,-3.02l1.77,-0.92l-0.09,-1.65l-1.59,0.64l-3.28,-7.69l-1.77,-0.64l1.06,-3.21l-0.89,-0.64l1.59,-0.18l-0.44,-2.56l1.95,-1.74v-1.19l-2.92,-1.46l0.8,-2.02l-0.18,-1.56l-0.88,-0.37l0.35,-2.11l3.72,-1.19l-0.53,2.11l3.98,2.29l1.15,-0.27l0.18,1.37l2.21,1.83l5.4,1.74l5.49,0.37l5.93,-0.18l8.32,-1.74l4.07,-3.3l1.59,-0.28l-0.18,-0.64l-1.24,0.27l-0.71,-1.28l1.33,-0.18l-0.27,-1.74l1.5,-0.46L1001.05,322.99z"
        />
        <path
          id="CV-CA"
          title="Santa Catarina"
          className="land"
          d="M733.13,688.98L729.67,692.01L725.2,693.6L726.06,698.74L724.51,701.04L725.07,707.35L725.07,707.35L724.48,707.35L724.48,707.35L722.57,708.66L721.51,714.55L714.72,715.21L711.96,722.19L711.96,722.19L711.75,724.59L711.75,724.59L713.45,726.77L710.91,728.08L706.72,733.65L706.72,733.65L704.15,733.47L701.58,730.37L702.2,729.37L704.06,729.46L703.8,727.92L705.66,727L705.83,725.91L704.77,724.82L705.92,724.18L705.83,722.82L704.24,722.09L701.5,718.53L698.75,717.44L697.16,715.16L695.83,716.8L694.59,716.9L693.26,712.89L691.49,710.43L690.25,710.15L690.08,707.97L687.24,706.05L685.21,706.51L685.56,705.23L684.5,703.69L684.85,702.87L686.98,702.68L685.56,697.04L688.13,695.67L687.86,691.02L689.28,686.92L688.04,686.37L688.22,685.28L689.81,684.83L691.4,682.46L690.08,680.91L691.14,677.9L689.28,677.35L688.93,673.8L690.08,672.34L687.95,668.78L690.52,667.96L690.25,664.23L692.11,661.89L692.11,661.89L696.06,661.95L698.39,660.86L702.85,663.48L704.54,659.11L706.45,659.77L708.36,662.6L712.6,662.6L712.6,662.6L713.24,668.28L714.3,669.15L720.02,669.59L723.84,673.08L725.33,675.71L722.57,681.38L722.78,683.35L728.08,682.47L732.87,676.36L732.87,676.36L735.08,676.8L735.5,678.54L734.44,683.13L735.71,683.56L735.71,684.87L732.75,686.62L732.75,688.58L732.75,688.58z"
        />
        <path
          id="CV-CF"
          title="Santa Catarina do Fogo"
          className="land"
          d="M524.36,749.96L518.14,758.84L514.66,761.36L504.72,762.96L500.72,762.03L495.95,757.78L494.79,753.67L494.92,749.82L504.74,740.5L504.74,740.5L506.44,742.12L506.44,742.12L509.28,742.61L522.59,741.5L522.59,741.5z"
        />
        <path
          id="CV-CR"
          title="Santa Cruz"
          className="land"
          d="M755.65,704.95L754.1,696.79L750.83,695.9L746.87,697.85L744.81,697.14L741.86,693L741.86,693L742.71,692.51L742.71,692.51L745.04,692.3L745.04,690.55L743.35,689.24L733.38,689.24L733.38,689.24L732.75,688.58L732.75,688.58L732.75,686.62L735.71,684.87L735.71,683.56L734.44,683.13L735.5,678.54L735.08,676.8L732.87,676.36L732.87,676.36L737.84,675.49L746.66,667.78L746.66,667.78L748.15,668.06L748.85,669.33L749.56,668.33L750.45,668.6L749.83,671.06L751.86,671.34L750.45,673.8L754.08,675.99L756.2,675.35L756.02,677.35L760.01,675.71L762.22,677.08L761.51,678.54L764.17,677.44L764.52,679.17L765.85,679.36L766.2,680.63L765.05,682.55L765.85,684.55L773.55,685.92L773.73,689.2L775.06,692.12L775.32,692.66L776.03,693.57L777,696.49L777,696.49L771.34,708.01L767.31,711.28L762.22,711.94L760.95,709.1L757.34,707.35L756.5,705.83L756.5,705.83z"
        />
        <path
          id="CV-MA"
          title="Maio"
          className="land"
          d="M876.33,615.53l1.24,2.28l-0.53,1.09l0.89,2.55l3.45,0.27l1.33,-4.1l0.09,4.47l2.21,2.01l5.84,1.82l2.21,-1l1.06,-1.64l0.71,0.91l0.88,-0.91l3.19,3.1l2.92,9.03l-0.27,2.01l-1.15,0.18l-1.24,2.28l1.24,0.18l1.95,4.19l1.59,7.2l-0.62,1.19l1.06,3.74l-0.62,0.37l1.68,5.83l-1.24,2.28l1.59,3.46l-1.06,0.73l0.97,3.1l-2.21,0.82l-5.22,7.29l-1.06,-0.09l-1.24,1.55l-3.45,0.64l-1.86,2.46l-3.36,0.73l-0.97,2.55l-2.75,0.82l-4.96,-1.28l-4.16,0.37l-2.74,-1.64l-3.81,-0.64l-2.3,-4.28l-2.39,-1.09l0.09,-0.82l1.59,0.09l-0.09,-1.46l-4.96,-4.65l-0.44,0.09l0.44,-5.01l-0.62,-1.73l1.33,-2.83l0.44,-8.75l3.72,-1.37l0.18,-8.12l-1.5,-3.19l-3.36,-0.27v-2.83l1.24,-0.18l1.06,-1.82l-1.33,-0.82l0.44,-1.09l2.3,0.64l5.84,-5.11l1.68,-4.47l-1.95,-2.46l1.68,-0.37l2.92,1.28l1.42,-1.09l-1.77,-3.56l2.66,-1.92l-0.88,-1.1l0.8,-0.09L876.33,615.53z"
        />
        <path
          id="CV-MO"
          title="Mosteiros"
          className="land"
          d="M487.14,717.08L489.04,716.26L488.51,714.71L491.52,713.89L497.19,709.88L499.22,711.16L503.03,710.88L507.99,712.16L509.84,713.25L510.55,715.35L514.89,716.17L516.57,719.99L519.23,721.72L520.2,725.91L519.58,727.55L521.71,729.01L522.33,734.02L523.74,736.11L522.59,741.5L522.59,741.5L509.28,742.61L506.44,742.12L506.44,742.12L503.13,738.96L503.13,738.96L500.29,735.06L500.05,730.68L496.03,728.74z"
        />
        <path
          id="CV-PA"
          title="Paul"
          className="land"
          d="M289.87,10.87L291.37,11.79L291.37,15.19L292.08,15.75L290.84,17.22L296.6,26.24L298.81,27.07L298.54,27.99L299.34,28.45L301.11,27.35L301.11,28.91L303.85,29.47L304.74,30.57L305.71,29.47L307.04,30.29L308.1,38.85L303.41,44.83L303.41,44.83L289.73,33.74L286.65,32.79L278.67,33.36L275.07,30.12L275.07,30.12L284.29,18.65L288.1,16.38z"
        />
        <path
          id="CV-PN"
          title="Porto Novo"
          className="land"
          d="M188.51,39.32L188.33,37.38L189.93,37.11L190.72,35.54L199.75,32.87L201.17,31.31L205.15,32.23L207.81,31.58L210.46,28.73L213.03,28.36L213.47,27.16L217.72,27.72L223.03,24.31L223.03,24.31L231.15,33.17L233.51,34.68L238.59,36L239.86,41.47L241.31,41.47L248.93,37.13L260.53,37.32L264.16,36.57L275.07,30.12L275.07,30.12L278.67,33.36L286.65,32.79L289.73,33.74L303.41,44.83L303.41,44.83L300.76,47.6L300.76,49.9L297.13,51.37L295.8,53.12L288.27,57.9L283.85,59.38L282.52,61.49L280.13,62.32L275,62.05L269.42,63.52L253.84,79.06L253.93,80.9L248.53,82.38L243.57,90.47L240.56,93.87L238.88,94.06L238.7,95.16L235.87,94.88L232.68,96.17L232.15,97.73L230.03,98.56L228.97,98.01L227.73,96.72L223.03,97.18L217.81,94.42L213.92,96.17L214.09,94.33L213.03,93.69L209.67,94.42L209.14,95.8L205.59,96.45L201.17,99.21L198.6,97.27L197.01,93.87L195.24,93.04L195.86,91.67L194.35,89.83L194.71,87.34L197.63,83.48L196.83,80.54L193.82,75.94L194.88,71.06L193.82,65.27L190.81,61.58L190.19,62.05L184.97,60.11L181.25,53.67L181.96,51.74L181.07,49.71L182.4,48.89L181.87,47.78L183.73,44.47L186.65,42.63L186.21,40.42z"
        />
        <path
          id="CV-PR"
          title="Praia"
          className="land"
          d="M786.12,735.84l1.15,2.55l1.59,0.09l0.09,0.91l-3.54,3.28l-1.59,-1l0.89,1.37h1.33l0.18,1l-0.97,1.91l-1.77,1l-0.18,3l-2.3,2.55l0.44,2.91l-1.06,2.55l-1.06,-0.09l-0.18,-1.64l-2.57,0.91l-0.8,-0.82l-1.42,1l-1.42,-3.09l-2.3,1.64l0.35,4.1l-2.12,-1.46l-5.4,0.64l-3.45,-1.18l-1.16,0.82l0,0l-9.4,-13.58l-2.24,-5.13l-0.98,-5.23l0,0h1.14l0,0l7.85,-3.49l14,-1.96l7.85,3.05l6.15,1.09l3.09,2.19l0,0L786.12,735.84z"
        />
        <path
          id="CV-RB"
          title="Ribeira Brava"
          className="land"
          d="M502.23,176.49v0.12l0,0l0,0l0,0V176.49zM505.77,173.28l2.66,0.64l0.44,2.21l3.19,0.09l2.3,1.1l2.83,3.31l-0.09,0.92h1.42l1.33,3.4l3.72,0.83l0.35,2.75l2.39,1.65l5.13,-3.03l3.72,1.29l4.51,-0.83l2.48,-1.93l-0.44,-1.01l2.48,0.92l2.13,-0.46l3.63,2.94l1.42,0.09l0.35,-0.83l4.52,2.75l2.3,-0.09l2.21,3.3l3.72,2.3l6.46,0.83l2.83,-3.21l1.95,0.18l3.27,2.02l4.78,-0.37l11.42,1.93l5.13,2.29l3.45,6.43l3.9,-0.64l0.53,1.74l1.42,-0.37l3.1,3.3l-2.48,6.06l-2.39,1.65l-1.86,-0.92l-2.74,0.55l-1.51,-2.39l-2.57,0.64l-1.15,-1.01l-4.78,2.48l-3.36,0.09l-2.66,-1.84h-1.42l-0.71,1.38l-2.3,-1.1l-2.66,0.92l-2.74,-1.28l0.62,-0.73l-1.77,-1.01l-2.21,-3.58l-1.95,0.46l-2.13,-1.65l-3.81,-0.92l-5.05,-4.22l-4.87,-0.18l-1.95,0.74l-1.15,-0.83l-1.77,0.73l-2.92,-1.47l-6.46,0.64l-0.71,-0.64l-4.69,4.5l-4.51,-0.09l-3.72,5.14l-2.21,0.73l-1.5,2.94l0.18,3.67l-2.48,1.01l-1.42,7.16l-2.92,3.03l0.35,1.84l-1.68,3.21l1.15,1.29l-0.62,1.56l-2.92,0.64l0.26,1.47l-1.15,-1.51l0,0l3.13,-10.15v-3.21l2.58,-6.42l0.17,-10.52l-1.03,-2.85l-2.24,-1.96l-0.34,-4.46l-2.24,-3.57l-1.89,-1.78h-6.02l-2.41,-1.07l-1.03,-3.92l2.24,-2.85l0.34,-2.68l-2.41,-6.65l0,0L505.77,173.28z"
        />
        <path
          id="CV-RG"
          title="Ribeira Grande"
          className="land"
          d="M264.73,3.04L266.59,0L267.83,0.09L269.07,2.95L274.73,6.35L282.26,8.93L285.27,8.93L285.8,7.92L289.07,9.3L289.87,10.87L289.87,10.87L288.1,16.38L284.29,18.65L275.07,30.12L275.07,30.12L264.16,36.57L260.53,37.32L248.93,37.13L241.31,41.47L239.86,41.47L238.59,36L233.51,34.68L231.15,33.17L223.03,24.31L223.03,24.31L223.92,23.57L223.83,20.54L227.02,20.35L227.99,19.34L228.17,17.13L231.27,18.05L231.8,17.04L231.09,16.3L232.59,14.73L235.52,16.21L240.38,15.01L243.04,11.88L242.6,10.87L244.9,9.39L250.12,9.39L253.75,6.91z"
        />
        <path
          id="CV-RS"
          title="Ribeira Grande de Santiago"
          className="land"
          d="M746.23,734.84L747.22,740.08L749.45,745.21L758.85,758.78L758.85,758.78L756.64,758.78L755.76,756.96L754.87,757.42L753.63,756.6L751.16,757.78L750.89,756.78L749.12,756.42L745.31,757.6L745.14,756.6L741.24,756.05L739.74,754.68L734.34,756.51L733.36,755.14L733.54,753.5L728.58,752.32L723.01,747.67L720.08,747.86L715.04,743.12L713.09,742.67L712.29,740.66L709.2,738.57L706.72,733.65L706.72,733.65L710.91,728.08L713.45,726.77L711.75,724.59L711.75,724.59L711.96,722.19L711.96,722.19L714.72,715.21L721.51,714.55L722.57,708.66L724.48,707.35L724.48,707.35L726.17,707.35L726.17,707.35L728.9,708.24L728.9,708.24L732.32,713.68L733.79,714.18L733.79,714.18L736.99,719.79L736.56,725.25L734.87,727.86L735.5,729.83L744.83,734.84L744.83,734.84z"
        />
        <path
          id="CV-SD"
          title="São Domingos"
          className="land"
          d="M777,696.49L777.09,698.04L779.04,698.04L779.31,699.68L780.63,700.5L780.28,702.32L781.96,703.05L783.2,705.42L782.23,706.42L783.64,707.06L784.17,706.51L784.88,707.97L784.97,707.33L786.21,707.51L785.59,709.88L787.36,709.42L790.99,711.43L791.26,713.07L788.42,712.8L787.98,713.8L789.22,713.8L789.04,715.26L790.37,713.89L792.76,713.52L792.23,714.53L793.2,714.71L793.47,719.08L792.85,720.54L793.82,720.81L795.15,719.63L796.66,721.81L796.57,723.36L795.15,723.09L794.62,725.09L797.1,726.37L794,726.64L795.68,727.73L796.83,727.37L795.86,729.46L796.75,731.56L795.42,733.65L793.2,731.38L788.33,735.93L786.31,735.73L786.31,735.73L783.21,733.54L777.07,732.45L769.22,729.39L755.22,731.36L747.38,734.84L747.38,734.84L744.83,734.84L744.83,734.84L735.5,729.83L734.87,727.86L736.56,725.25L736.99,719.79L733.79,714.18L733.79,714.18L738.47,714.77L742.92,713.9L750.98,708.88L750.98,708.88L753.74,704.74L753.74,704.74L755.22,704.52L755.22,704.52L756.5,705.83L756.5,705.83L757.34,707.35L760.95,709.1L762.22,711.94L767.31,711.28L771.34,708.01z"
        />
        <path
          id="CV-SF"
          title="São Filipe"
          className="land"
          d="M504.74,740.5L494.92,749.82L494.79,753.67L495.95,757.78L500.72,762.03L504.72,762.96L514.66,761.36L518.14,758.84L524.36,749.96L524.36,749.96L525.16,751.32L525.16,757.6L526.58,764.33L524.45,771.71L519.41,777.62L515.78,780.72L513.21,780.99L511.26,783.9L510.29,783.63L504.71,787L500.55,788L487.27,788.18L485.68,786.81L483.11,787.09L481.96,785.54L477.98,783.72L471.43,776.17L466.2,773.98L464.88,770.89L461.16,767.7L456.55,765.7L454.96,762.6L455.14,754.05L454.08,752.77L455.14,750.68L455.94,744.03L458.24,740.39L458.33,737.93L459.56,736.93L459.03,735.29L459.83,733.56L461.87,732.83L462.22,731.47L464.43,729.92L464.08,729.1L469.75,725.37L470.28,723.63L476.12,720.99L476.21,719.45L478.24,719.45L479.48,717.9L483.2,718.81L487.14,717.08L487.14,717.08L496.03,728.74L500.05,730.68L500.29,735.06L503.13,738.96L503.13,738.96z"
        />
        <path
          id="CV-SL"
          title="Sal"
          className="land"
          d="M959.45,115.94l2.3,1.56l0.53,2.85l2.04,-0.18l0.18,1.38l4.43,1.29l-0.62,4.04l-1.59,0.92l-0.27,2.57l-1.68,2.3l2.48,1.93l2.3,6.62l3.19,1.84l-1.5,1.38l-0.71,3.12l-3.1,-0.64l-0.35,1.93l-1.59,1.38l1.42,0.55l0.71,2.39l-0.35,2.3l-1.42,1.1l1.24,0.73l2.04,7.81l3.54,3.77l-0.71,2.3l2.57,6.34l-2.66,1.84l-1.86,2.85l-0.62,4.04l-1.95,0.46l-1.68,2.02l0.27,5.6l2.04,4.68l1.42,1.01l0.53,3.12l-7.7,-1.84l-1.86,0.92l-2.48,3.03l-1.59,-1.38l1.06,-4.22l-1.5,-0.64l1.15,-2.39l-1.33,-4.41l-1.59,-2.66l-3.01,-2.11l0.18,-1.1l-1.15,-0.09v-2.02l0.71,-0.09l-1.06,-1.38l2.57,-1.65l1.33,-3.4l-0.8,-6.43l-4.69,-3.03l-4.96,-0.92l-0.53,1.01l-3.19,1.1l-1.86,-0.46l-0.71,-1.38l2.04,-3.49l2.04,-0.73l-3.72,-6.25l1.77,-2.94h1.24l-1.06,-1.47l1.33,-1.19v-1.93l-0.35,-0.55l-1.06,0.64l-2.3,-1.75l-0.44,-3.12l1.15,-1.19l-1.5,-2.67l-0.71,-5.61l0.35,-4.78l1.77,-1.93l0.62,-2.85l1.95,-1.84h2.39l3.98,-1.65l2.57,-2.85l3.54,1.2l4.96,-2.39L959.45,115.94z"
        />
        <path
          id="CV-SM"
          title="São Miguel"
          className="land"
          d="M723.58,645.45L729.73,646.36L729.82,648L731.59,648.55L730.88,649.64L733.27,651.46L734.6,651.01L736.02,652.19L737.61,652.19L738.94,656.11L741.24,656.93L741.77,660.22L743.37,660.4L743.54,661.95L744.43,662.31L744.16,664.59L747.08,665.69L746.66,667.78L746.66,667.78L737.84,675.49L732.87,676.36L732.87,676.36L728.08,682.47L722.78,683.35L722.57,681.38L725.33,675.71L723.84,673.08L720.02,669.59L714.3,669.15L713.24,668.28L712.6,662.6L712.6,662.6L715.36,659.33L715.46,656.86L721.4,650.57z"
        />
        <path
          id="CV-SO"
          title="São Lourenço dos Órgãos"
          className="land"
          d="M755.65,704.95L755.22,704.52L755.22,704.52L753.74,704.74L753.74,704.74L750.98,708.88L750.98,708.88L742.92,713.9L738.47,714.77L733.79,714.18L733.79,714.18L732.32,713.68L728.9,708.24L728.9,708.24L735.29,703.43L737.84,695.35L740.8,693.6L740.8,693.6L741.86,693L741.86,693L744.81,697.14L746.87,697.85L750.83,695.9L754.1,696.79z"
        />
        <path
          id="CV-SS"
          title="São Salvador do Mundo"
          className="land"
          d="M725.07,707.35L724.51,701.04L726.06,698.74L725.2,693.6L729.67,692.01L733.13,688.98L733.13,688.98L733.38,689.24L733.38,689.24L743.35,689.24L745.04,690.55L745.04,692.3L742.71,692.51L742.71,692.51L740.8,693.6L740.8,693.6L737.84,695.35L735.29,703.43L728.9,708.24L728.9,708.24L726.17,707.35L726.17,707.35z"
        />
        <path
          id="CV-SV"
          title="São Vicente"
          className="land"
          d="M429.29,190.26l3.45,2.66l-2.3,4.87l-6.64,-0.27l-1.5,-2.94l0.89,-2.3l2.48,-0.09L429.29,190.26zM398.4,176.86l5.05,3.95l2.75,4.59l-3.63,-1.1l-2.92,-2.66l-2.66,-3.12L398.4,176.86zM367.59,132.02l1.86,1.47l6.9,2.39l1.5,5.33l2.83,1.01l1.86,3.22l0.09,1.84l2.13,2.39l3.81,0.74l3.36,-1.65l4.51,-0.09l0.89,0.83l-0.53,2.39l-1.95,2.57l-2.3,-0.37l-1.24,1.1l-3.81,-0.64l-2.21,1.19l-7.7,-0.37l-0.35,-3.58l-3.98,-4.23l-5.05,-2.57l-1.68,0.46l-2.04,-0.92l-1.68,-5.05l1.06,-0.46l1.15,-3.86L367.59,132.02zM322.18,93.6l3.36,2.3l1.33,2.39l0.09,1.75l-0.71,-0.55l-0.62,0.74l0.35,1.29l-0.89,-0.83l-1.33,0.64l1.33,1.38l-1.5,1.47l0.09,1.84l1.77,2.76l7.61,4.96l2.74,0.37l0.89,-1.1l2.21,-0.37l1.95,1.1v2.48l-2.74,1.47l1.42,4.41l1.68,0.28l-1.68,7.35l-3.19,1.56l-6.11,-0.73l-7.17,4.87l-8.06,1.65l-2.12,-0.46l-2.3,1.1l-0.27,1.19l-4.96,0.09l-1.24,2.76l-2.75,0.28l-5.58,-2.94l-2.57,-2.57l-1.77,0.18l-2.12,-2.02l-1.86,-0.09l-1.86,-1.38l-1.86,0.55l-1.77,-2.11l-2.3,1.56l-2.74,-2.76l-0.18,-4.14l-0.97,-1.29l-1.86,-0.46l-5.05,1.01l2.48,-3.86l-1.95,-2.48l2.21,-0.74l1.68,-1.84l0.09,-2.57l1.42,-0.18l0.35,-1.38l0.97,1.01l2.39,-1.01l-0.53,-2.12l0.8,-1.56l1.68,-0.09l-0.88,-2.21l2.66,1.29v-1.56l1.86,0.74l1.68,-1.66l2.21,0.09l0.8,2.57l4.51,2.48l0.35,-0.92l3.01,-0.46l2.83,-2.94l-0.18,-1.29l-0.53,0.74l-1.24,-1.01l-1.42,0.55l0.71,-1.47l1.15,0.37l0.71,-0.74l-0.18,-1.47l-1.77,-0.83l-0.27,-1.75h2.57l0.18,-1.84l4.25,0.46l0.97,-2.57l2.3,-0.37l1.24,-1.29l0.71,0.74l-1.59,2.39l1.33,-0.55l0.62,1.75l4.6,1.65l2.92,-1.47l-0.8,-1.47l2.57,-1.29l-0.62,-1.29L322.18,93.6z"
        />
        <path
          id="CV-TA"
          title="Tarrafal"
          className="land"
          d="M689.81,621.83L690.7,622.83L691.76,622.38L691.14,620.91L692.82,620.64L692.11,617.72L696.27,617.54L696.36,615.35L698.04,615.71L698.84,617.18L700.08,616.72L700.17,617.54L702.82,618L703.71,619.46L705.13,618.91L705.48,617.08L709.2,617.81L708.75,619L709.46,619.46L711.68,618.27L715.04,621.74L716.46,624.84L715.48,627.57L713.27,627.85L714.68,629.03L713.53,633.14L715.22,639.43L717.78,642.62L720.88,643.8L721.94,643.53L723.58,645.45L723.58,645.45L721.4,650.57L715.46,656.86L715.36,659.33L712.6,662.6L712.6,662.6L708.36,662.6L706.45,659.77L704.54,659.11L702.85,663.48L698.39,660.86L696.06,661.95L692.11,661.89L692.11,661.89L693.17,658.39L693.17,653.56L696.89,650.64L697.6,646.81L696.27,643.53L693.97,641.98L692.47,642.25L691.4,635.69L693.35,634.87L693.44,634.05L690.78,631.49L687.77,631.13L687.6,629.4L686.71,628.94L688.48,626.57L690.96,627.03L691.4,626.39L691.85,623.92L689.63,623.47L689.19,622.1z"
        />
        <path
          id="CV-TS"
          title="Tarrafal de São Nicolau"
          className="land"
          d="M502.14,176.4L502.23,176.61L502.23,176.61L502.23,176.61L502.23,176.61L502.24,176.77L502.24,176.77L504.65,183.41L504.31,186.09L502.07,188.94L503.1,192.87L505.51,193.94L511.53,193.94L513.42,195.72L515.66,199.29L516,203.75L518.24,205.71L519.27,208.57L519.1,219.09L516.52,225.51L516.52,228.72L513.39,238.86L513.39,238.86L512.06,238.27L510.64,239.64L511.44,233.59L510.29,232.3L509.31,227.71L508.34,225.6L504.71,223.13L503.29,216.89L500.46,215.6L500.9,212.11L499.22,208.17L497.01,206.79L492.58,206.51L484.53,201.65L483.2,199.72L481.34,198.89L481.96,196.97L479.13,195.68L478.77,191.27L479.48,189.16L478.77,187.23L480.63,183.01L482.05,183.38L481.16,182.09L484.35,181.72L485.41,180.62L487.45,180.81L490.28,179.34L490.9,177.87L493.91,176.77L499.4,178.23z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(CapeVerde))
