import { Component } from 'react'
import { connect } from 'react-redux'
import { passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction } from '../redux/actions/actions'

class PageNotFoundPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(changePathAction('/page-not-found'))
  }

  render() {
    return null
  }
}

export default connect(() => ({}), passDispatchToProps)(PageNotFoundPage)
