import { baseUrl } from './../../../lib/_processUtil'
import {
  createSameKeyValueObject,
  createInvertedAnswerQuestionMap
} from './../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from './../../../lib/languageUtil'
import { randomizeAWordAction, resetWordsAction } from '../../../redux/actions/actions'

const answerQuestionImageIndexLimitMap = {
  ant: 5,
  anteater: 4,
  antilope: 0,
  armadillo: 0,
  bat: 6,
  bear: 23,
  beaver: 8,
  bee: 15,
  beetle: 19,
  bird: 2,
  bison: 1,
  boar: 3,
  buffalo: 3,
  bull: 4,
  butterfly: 15,
  camel: 5,
  cameleon: 1,
  cat: 22,
  caterpillar: 8,
  centipede: 0,
  cheetah: 17,
  chick: 13,
  chicken: 31,
  clam: 1,
  clownfish: 5,
  cockroach: 0,
  cow: 22,
  crab: 7,
  crocodile: 11,
  deer: 12,
  dog: 22,
  dolphin: 11,
  donkey: 1,
  dragon: 25,
  dragonfly: 5,
  duck: 17,
  eagle: 9,
  eel: 1,
  elephant: 19,
  fish: 31,
  fly: 3,
  'flying-squirrel': 5,
  fox: 16,
  frog: 24,
  gecko: 0,
  giraffe: 12,
  goat: 12,
  goose: 1,
  gorilla: 5,
  grasshopper: 4,
  hamster: 8,
  hedgehog: 3,
  'hermit-crab': 2,
  hippopotamus: 10,
  horse: 14,
  hyena: 5,
  jellyfish: 8,
  kangaroo: 13,
  koala: 7,
  'komodo-dragon': 1,
  ladybug: 8,
  lemur: 5,
  lion: 23,
  llama: 2,
  lobster: 3,
  lynx: 2,
  mandrill: 2,
  monkey: 13,
  mosquito: 7,
  moth: 5,
  mouse: 18,
  octopus: 8,
  orangutan: 3,
  ostrich: 9,
  otter: 8,
  owl: 20,
  panda: 9,
  panther: 2,
  parrot: 13,
  peacock: 0,
  pelican: 12,
  penguin: 19,
  pig: 26,
  pigeon: 1,
  platypus: 1,
  prairiedog: 0,
  'praying-mantis': 3,
  rabbit: 17,
  racoon: 7,
  ram: 1,
  rhinoceros: 15,
  salamander: 2,
  scorpion: 4,
  seahorse: 5,
  seal: 2,
  sealion: 0,
  shark: 46,
  sheep: 19,
  shrimp: 3,
  skunk: 3,
  sloth: 2,
  snail: 19,
  snake: 25,
  spider: 15,
  squid: 11,
  squirrel: 26,
  starfish: 7,
  'stick-bug': 0,
  stingray: 6,
  stork: 8,
  swordfish: 4,
  tadpole: 2,
  tapir: 3,
  tarantula: 0,
  tick: 2,
  tiger: 20,
  toad: 2,
  toucan: 14,
  turkey: 8,
  turtle: 28,
  unicorn: 26,
  urchin: 0,
  vulture: 3,
  walrus: 4,
  warthog: 1,
  wasp: 0,
  whale: 12,
  wolf: 3,
  worm: 11,
  zebra: 9
}

const animalsList = Object.keys(answerQuestionImageIndexLimitMap)

const questionAnswerMap = {
  en: createSameKeyValueObject(animalsList),
  fr: {
    ant: 'fourmi',
    anteater: 'fourmilier',
    antilope: 'antilope',
    armadillo: 'tatou',
    bat: 'chauve-souris',
    bear: 'ours',
    beaver: 'castor',
    bee: 'abeille',
    beetle: 'scarabée',
    bird: 'oiseau',
    bison: 'bison',
    boar: 'sanglier',
    buffalo: 'buffle',
    bull: 'taureau',
    butterfly: 'papillon',
    camel: 'chameau',
    cameleon: 'caméléon',
    cat: 'chat',
    caterpillar: 'chenille',
    centipede: 'mille-pattes',
    cheetah: 'guépard',
    chick: 'poussin',
    chicken: 'poule',
    clam: 'palourde',
    clownfish: 'poisson clown',
    cockroach: 'cafard',
    cow: 'vache',
    crab: 'crabe',
    crocodile: 'crocodile',
    deer: 'biche',
    dog: 'chien',
    dolphin: 'dauphin',
    donkey: 'âne',
    dragon: 'dragon',
    dragonfly: 'libellule',
    duck: 'canard',
    eagle: 'aigle',
    eel: 'anguille',
    elephant: 'éléphant',
    fish: 'poisson',
    fly: 'mouche',
    'flying-squirrel': 'écureuil volant',
    fox: 'renard',
    frog: 'grenouille',
    gecko: 'gecko',
    giraffe: 'girafe',
    goat: 'chèvre',
    goose: 'oie',
    gorilla: 'gorille',
    grasshopper: 'sauterelle',
    hamster: 'hamster',
    hedgehog: 'hérisson',
    'hermit-crab': "Bernard l'Hermite",
    hippopotamus: 'hippopotame',
    horse: 'cheval',
    hyena: 'hyène',
    jellyfish: 'méduse',
    kangaroo: 'kangourou',
    koala: 'koala',
    'komodo-dragon': 'Dragon de Komodo',
    ladybug: 'coccinelle',
    lemur: 'maki',
    lion: 'lion',
    llama: 'lama',
    lobster: 'homard',
    lynx: 'lynx',
    mandrill: 'mandrill',
    monkey: 'singe',
    mosquito: 'moustique',
    moth: 'papillon de nuit',
    mouse: 'souris',
    octopus: 'pieuvre',
    orangutan: 'orang-outan',
    ostrich: 'autruche',
    otter: 'loutre',
    owl: 'chouette',
    panda: 'panda',
    panther: 'panthère',
    parrot: 'perroquet',
    peacock: 'paon',
    pelican: 'pélican',
    penguin: 'pingouin',
    pig: 'cochon',
    pigeon: 'pigeon',
    platypus: 'ornithorynque',
    prairiedog: 'chien de prairie',
    'praying-mantis': 'mante religieuse',
    rabbit: 'lapin',
    racoon: 'raton laveur',
    ram: 'bélier',
    rhinoceros: 'rhinocéros',
    salamander: 'salamandre',
    scorpion: 'scorpion',
    seahorse: 'hippocampe',
    seal: 'phoque',
    sealion: 'lion de mer',
    shark: 'requin',
    sheep: 'mouton',
    shrimp: 'crevette',
    skunk: 'moufette',
    sloth: 'paresseux',
    snail: 'escargot',
    snake: 'serpent',
    spider: 'araignée',
    squid: 'calmar',
    squirrel: 'écureuil',
    starfish: 'étoile de mer',
    'stick-bug': 'stick-bug',
    stingray: 'raie',
    stork: 'cigogne',
    swordfish: 'espadon',
    tadpole: 'têtard',
    tapir: 'tapir',
    tarantula: 'tarentule',
    tick: 'tique',
    tiger: 'tigre',
    toad: 'crapeau',
    toucan: 'toucan',
    turkey: 'dinde',
    turtle: 'tortue',
    unicorn: 'licorne',
    urchin: 'oursin',
    vulture: 'vautour',
    walrus: 'morse',
    warthog: 'phacochère',
    wasp: 'guèpe',
    whale: 'baleine',
    wolf: 'loup',
    worm: 'ver',
    zebra: 'zèbre'
  }
}

const answerQuestionMap = createInvertedAnswerQuestionMap(questionAnswerMap)

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.words.animals

const makeQuestionText = lngCode => questionText[lngCode]

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const getAnimalImageUrl = (animal, randomNumber) =>
  `${baseUrl}/images/words/animals/${animal}/${randomNumber}.webp`

const commonProps = {
  navigationCommands: ['menu', 'words'],
  imageAlt: 'animal',
  randomizeQuestionFunction: randomizeAWordAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetWordsAction,
  displayType: 'image',
  getImageUrl: getAnimalImageUrl
}

const alsoAcceptedAsAnswersMap = {
  en: {
    gecko: ['lizard'],
    'flying-squirrel': ['flying squirrel'],
    'hermit-crab': ['hermit crab'],
    'komodo-dragon': ['komodo dragon'],
    'praying-mantis': ['praying mantis'],
    'stick-bug': ['stick bug', 'stickbug']
  },
  fr: {
    gecko: ['lézard'],
    lemur: ['lémurien'],
    owl: ['hibou'],
    skunk: ['putois'],
    'stick-bug': ['stick bug', 'stickbug']
  }
}

const gameTypeProps = ({ lngCode, questionType, animal }) => {
  return {
    pageName: `${questionType}_page`,
    questionText: makeQuestionText(lngCode),
    questionAnswer: makeQuestionAnswer(animal, lngCode),
    answerQuestionMap,
    alsoAcceptedAsAnswers: alsoAcceptedAsAnswersMap[lngCode]
  }
}

export {
  answerQuestionImageIndexLimitMap,
  animalsList,
  questionAnswerMap,
  answerQuestionMap,
  commonProps,
  gameTypeProps,
  getAnimalImageUrl
}
