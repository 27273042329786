import React from 'react'
import StyledAnswer from './StyledAnswer'
import { mapNumberImages } from '../lib/mathematicsUtil.js'
import { getRandomItemFromList } from '../lib/methodsUtil'
import { answerAnimations } from '../lib/animationsUtil'

const ShowAnswer = ({
  answer,
  isMathematics,
  pageName,
  getImageUrl,
  randomList,
  titleLettersColor,
  isAnnyangSupported
}) => {
  const RandomAnimation = getRandomItemFromList(answerAnimations)

  return (
    <div
      className={`show-answer-wrapper ${
        isMathematics ? `show-answer-wrapper-mathematics-${pageName.split('_')[0]}` : ''
      } show-answer-wrapper-${pageName.replace('_', '-')}`}>
      {isMathematics ? (
        !!answer?.toString.length && (
          <RandomAnimation className="height-100">
            {mapNumberImages(answer, pageName, randomList, getImageUrl, 'result')}
          </RandomAnimation>
        )
      ) : (
        <StyledAnswer
          answer={answer}
          color={titleLettersColor}
          isAnnyangSupported={isAnnyangSupported}
          RandomAnimation={RandomAnimation}
        />
      )}
    </div>
  )
}

export default ShowAnswer
