import React from 'react'
import EasyMediumHardNavigationPage from '../../../components/EasyMediumHardNavigationPage'

const ReverseCapitalsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'geography', 'easy', 'medium', 'hard']}
    pageName="reverse_capitals_page"
    customNavigationPath="/geography/capitals/reverse"
  />
)

export default ReverseCapitalsPage
