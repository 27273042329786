import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 872

const cities = {
  Asuncion: { x: 482, y: 620 }
}

const Paraguay = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map paraguay">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="PY-7"
          title="Itapúa"
          className="land"
          d="M597.35,861.75L620.5,831.51L655.73,847.04L671.3,834.67L670.64,817.43L688.19,797.35L714.45,794.56L735.27,764.71L746.02,763.34L759.55,718.39L737.96,708.27L725.04,718.19L705.08,714.08L699.87,701.88L683.27,723.56L632.46,747.04L622.61,763.76L593.14,775.54L577.85,769.86L581.48,812.45L566.88,826.92L571.58,848.03L597.35,861.75z"
        />
        <path
          id="PY-14"
          title="Canindeyú"
          className="land"
          d="M788.76,559.29L797.93,520.24L791.02,498.33L799.54,488.08L762.01,462.42L733,479.58L692.65,481.72L676.78,433.92L670.68,446.93L626.74,467.01L633.09,485.16L618.47,497.44L617.45,510.11L656.1,564.67L677.83,540.87L705.96,535.27L718.55,532.18L747.58,552.71L788.76,559.29z"
        />
        <path
          id="PY-6"
          title="Caazapá"
          className="land"
          d="M699.87,701.88L705.44,695.5L679.08,690.25L689.7,652.44L681.02,646.03L668.13,656.04L629.17,681.82L622.94,695.01L598.17,694.14L576.49,711.71L557.73,750.5L577.85,769.86L593.14,775.54L622.61,763.76L632.46,747.04L683.27,723.56L699.87,701.88z"
        />
        <path
          id="PY-5"
          title="Caaguazú"
          className="land"
          d="M681.02,646.03L706.02,611.83L732.45,623.05L726.58,607.26L696.22,583.26L705.96,535.27L677.83,540.87L656.1,564.67L637.19,570.89L628.01,584.5L609.85,564.74L585.25,589.78L578.65,624.26L554.74,641.53L561.26,656.37L570.74,662.39L598.16,648.37L668.13,656.04L681.02,646.03z"
        />
        <path
          id="PY-10"
          title="Alto Paraná"
          className="land"
          d="M788.76,559.29L747.58,552.71L718.55,532.18L705.96,535.27L696.22,583.26L726.58,607.26L732.45,623.05L706.02,611.83L681.02,646.03L689.7,652.44L679.08,690.25L705.44,695.5L699.87,701.88L705.08,714.08L725.04,718.19L737.96,708.27L759.55,718.39L764.81,633.07L782.26,603.22L788.76,559.29z"
        />
        <path
          id="PY-13"
          title="Amambay"
          className="land"
          d="M676.78,433.92L668.22,341.56L645.6,305.57L611.95,304.11L592.18,283.44L570.55,302.88L564.21,297.94L552.07,305.51L576.91,313.03L563.56,330.67L566.26,366.34L606.49,348.62L620.23,384.98L603.68,414.11L625.09,413.2L628.16,423.34L618.29,432.81L623.64,442.6L638.69,434.28L626.74,467.01L670.68,446.93L676.78,433.92z"
        />
        <path
          id="PY-9"
          title="Paraguarí"
          className="land"
          d="M561.26,656.37L546.59,656.88L546.37,644.32L528.69,642.92L521.68,632.28L498.73,659.55L489.12,694.22L491.65,710.78L469.88,736.85L502.12,759.05L519.2,754.86L533.35,735.94L557.73,750.5L576.49,711.71L570.74,662.39L561.26,656.37z"
        />
        <path
          id="PY-12"
          title="Ñeembucú"
          className="land"
          d="M489.12,694.22L470.67,673.21L459,677.14L453.95,694.14L427.41,712.3L424.8,762.21L411.74,786.06L396.52,792.88L379.1,819.32L384.26,833.37L439.73,827.32L507.55,845.49L518.43,809.74L496.91,804.24L478.05,780.06L469.88,736.85L491.65,710.78L489.12,694.22z"
        />
        <path
          id="PY-8"
          title="Misiones"
          className="land"
          d="M557.73,750.5L533.35,735.94L519.2,754.86L502.12,759.05L469.88,736.85L478.05,780.06L496.91,804.24L518.43,809.74L507.55,845.49L526.71,852.08L546.21,844.36L558.9,853.81L571.58,848.03L566.88,826.92L581.48,812.45L577.85,769.86L557.73,750.5z"
        />
        <path
          id="PY-4"
          title="Guairá"
          className="land"
          d="M668.13,656.04L598.16,648.37L570.74,662.39L576.49,711.71L598.17,694.14L622.94,695.01L629.17,681.82L668.13,656.04z"
        />
        <path
          id="PY-11"
          title="Central"
          className="land"
          d="M493.75,599.32L481.51,612.16L480.16,629.97L482.28,646.97L465.29,658.41L459,677.14L470.67,673.21L489.12,694.22L498.73,659.55L521.68,632.28L493.75,599.32z"
        />
        <path
          id="PY-2"
          title="San Pedro"
          className="land"
          d="M626.74,467.01L638.69,434.28L623.64,442.6L618.29,432.81L628.16,423.34L625.09,413.2L603.68,414.11L577,425.74L494.59,428.1L496.54,443L510.82,454.83L504.34,474.79L521.56,495.05L516.15,505.84L528.46,553.63L513.4,576.09L516.76,586.33L531.5,591.45L565.22,578.59L585.25,589.78L609.85,564.74L628.01,584.5L637.19,570.89L656.1,564.67L617.45,510.11L618.47,497.44L633.09,485.16L626.74,467.01z"
        />
        <path
          id="PY-15"
          title="Presidente Hayes"
          className="land"
          d="M456.21,319.32L416.74,296.15L330.47,292.34L313.94,284.32L317.25,296.5L288.64,307.42L257.53,357.78L245.22,430.74L179.78,445.97L176.23,469.6L219.14,484.51L248.07,483.53L364.73,564.05L454.14,596.46L473.07,618L481.51,612.16L493.75,599.32L516.76,586.33L513.4,576.09L528.46,553.63L516.15,505.84L521.56,495.05L504.34,474.79L510.82,454.83L496.54,443L494.59,428.1L490.98,407.2L461.89,381.12L461.6,364.1L447.52,349.58L463.24,343.29L456.21,319.32z"
        />
        <path
          id="PY-3"
          title="Cordillera"
          className="land"
          d="M585.25,589.78L565.22,578.59L531.5,591.45L516.76,586.33L493.75,599.32L521.68,632.28L528.69,642.92L546.37,644.32L546.59,656.88L561.26,656.37L554.74,641.53L578.65,624.26L585.25,589.78z"
        />
        <path
          id="PY-1"
          title="Concepción"
          className="land"
          d="M552.07,305.51L444.38,284.11L456.21,319.32L463.24,343.29L447.52,349.58L461.6,364.1L461.89,381.12L490.98,407.2L494.59,428.1L577,425.74L603.68,414.11L620.23,384.98L606.49,348.62L566.26,366.34L563.56,330.67L576.91,313.03L552.07,305.51z"
        />
        <path
          id="PY-19"
          title="Boquerón"
          className="land"
          d="M313.94,284.32L257.17,279.94L265.4,221.39L280.29,207.6L265.05,142.62L67.91,78.28L34.32,130.56L34.47,180.18L0.46,306.23L33.38,325.6L61.5,378.71L85.1,403.23L145.54,441.87L155.41,462.52L176.23,469.6L179.78,445.97L245.22,430.74L257.53,357.78L288.64,307.42L317.25,296.5L313.94,284.32z"
        />
        <path
          id="PY-16"
          title="Alto Paraguay"
          className="land"
          d="M444.38,284.11L459.2,168.65L449.94,163.76L455.19,156.22L447.5,152.34L455.38,146.86L443.67,142.06L442.83,115.65L426.32,96.35L426.16,54.75L339.92,0L250.67,0.67L85.77,35.17L67.91,78.28L265.05,142.62L280.29,207.6L265.4,221.39L257.17,279.94L313.94,284.32L330.47,292.34L416.74,296.15L456.21,319.32L444.38,284.11z"
        />
        <path
          id="PY-ASU"
          title="Asunción"
          className="land"
          d="M480.16,629.97L481.51,612.16L473.07,618L480.16,629.97z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Paraguay))
