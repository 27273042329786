import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const ReadingPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'read_words', 'read_sentences', 'read_stories']}
    pageName="reading_page"
    // Using read_words instead of words because each item needs to be unique; the icon for example uses the name + .webp
    topics={['topic_read_words', 'topic_read_sentences', 'topic_read_stories']}
    topicNavigationPath="reading"
    subTopics={['read_words', 'read_sentences', 'read_stories']}
    newTopics={['read_words']}
    // comingSoonTopics={['read_sentences', 'read_stories']}
    borderRadius={0.55}
    SEODescription="E=MC² - Enunciation Made Constructive² - Speech recognition: Play, Learn how to read & speak & have fun with words, phrases, vocabulary, sentences and stories"
  />
)

export default ReadingPage
