import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 847

const cities = {
  Kampala: { x: 449, y: 562 }
}

const Uganda = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map uganda">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="UG-223"
          title="Bududa"
          className="land"
          d="M730.15,457.43L708.01,459.8L694.24,472.65L723.65,472.53L730.15,457.43z"
        />
        <path
          id="UG-329"
          title="Nwoya"
          className="land"
          d="M390.58,217.76L323.78,207.83L276.1,234.29L291.41,253.09L264.63,298.79L294.11,284.32L324.96,281.3L343.14,271.15L399.26,287.41L396.6,257.4L414.33,256.05L386.94,234.85L390.58,217.76z"
        />
        <path
          id="UG-325"
          title="Buhweju"
          className="land"
          d="M140.65,657.44L114.12,650.93L87.95,665.05L86.8,681.23L114.53,692.21L135.71,685.43L140.65,657.44z"
        />
        <path
          id="UG-425"
          title="Sheema"
          className="land"
          d="M106.66,730.92L129.1,712.57L135.71,685.43L114.53,692.21L112.8,710.7L90.84,713.01L94.09,726.49L106.66,730.92z"
        />
        <path
          id="UG-422"
          title="Mitooma"
          className="land"
          d="M90.84,713.01L58.06,694.13L53.75,711.28L75.08,731.67L94.09,726.49L90.84,713.01z"
        />
        <path
          id="UG-424"
          title="Rubirizi"
          className="land"
          d="M96.92,631.44L67.15,645.4L19.58,640.07L14.07,672.48L53.86,678.92L86.8,681.23L87.95,665.05L114.12,650.93L103.27,637.42L96.92,631.44z"
        />
        <path
          id="UG-122"
          title="Kalungu"
          className="land"
          d="M330.56,612.39L309.27,612.83L319.91,658.37L357.22,653.69L351.5,638.43L339.74,620.85L330.56,612.39z"
        />
        <path
          id="UG-118"
          title="Bukomansimbi"
          className="land"
          d="M309.27,612.83L300.87,612.5L303.14,658.37L319.91,658.37L309.27,612.83z"
        />
        <path
          id="UG-116"
          title="Lyantonde"
          className="land"
          d="M254.7,658.18L256.1,630.95L225.17,626.31L233.58,697.57L248.63,688.1L254.7,658.18z"
        />
        <path
          id="UG-124"
          title="Lwengo"
          className="land"
          d="M303.14,658.37L254.7,658.18L248.63,688.1L281.35,708.66L305.24,688.96L319.91,658.37L303.14,658.37z"
        />
        <path
          id="UG-324"
          title="Amudat"
          className="land"
          d="M770.38,417.78L799.52,372.98L791.23,294.2L779.34,308.6L771.57,372.23L749.26,392.88L764.71,410.76L770.38,417.78z"
        />
        <path
          id="UG-328"
          title="Napak"
          className="land"
          d="M766.54,253.91L750.37,230.8L692.49,202.53L688.71,218.48L650.03,218.78L648.16,234.35L656.33,251.22L604.08,257.99L602.1,272.15L614.75,264.41L641.54,280.41L685.31,308.84L686.62,331.93L744.01,313.55L748.96,254.85L766.54,253.91z"
        />
        <path
          id="UG-230"
          title="Namayingo"
          className="land"
          d="M649.58,590.52L639.61,549.99L609.01,572.41L604.54,609L617.25,625.76L601.22,635L601.22,767.39L641.33,767.39L648.59,642.49L639.31,606.51L649.58,590.52z"
        />
        <path
          id="UG-225"
          title="Bulambuli"
          className="land"
          d="M711.04,390.47L689.25,390.69L686.96,436.95L732.61,448.64L700.93,425.22L719.21,406.91L711.04,390.47z"
        />
        <path
          id="UG-228"
          title="Kween"
          className="land"
          d="M749.26,392.88L711.04,390.47L719.21,406.91L738.85,417.54L736.18,443.02L764.71,410.76L749.26,392.88z"
        />
        <path
          id="UG-421"
          title="Kyegegwa"
          className="land"
          d="M245.3,528.13L204.5,517.02L196.62,558.77L192.06,592.42L213.85,589.98L215.03,589.33L234.02,548.35L249.68,542.63L245.3,528.13z"
        />
        <path
          id="UG-423"
          title="Ntoroko"
          className="land"
          d="M147.21,461.6L148.03,423.1L101.68,452.5L93.52,479.21L103.62,494.18L91.59,514.54L144.12,474.6L147.21,461.6z"
        />
        <path
          id="UG-420"
          title="Kiryandongo"
          className="land"
          d="M399.26,287.41L343.14,271.15L324.96,281.3L324.28,316.37L342.65,330.52L346.89,370.81L375.92,374.5L411.25,344.71L412.03,314.54L399.26,287.41z"
        />
        <path
          id="UG-322"
          title="Agago"
          className="land"
          d="M589.18,138.25L565.52,133.71L561.21,148.98L539.75,150.15L531.08,175.27L524.63,193.37L544.86,200.27L539.26,215.35L516.45,220.52L527.14,235.09L586.85,237.36L597.09,209.81L589.92,149.01L589.18,138.25z"
        />
        <path
          id="UG-330"
          title="Otuke"
          className="land"
          d="M586.85,237.36L527.14,235.09L510.36,246.68L503.11,258.43L588.6,272.8L602.1,272.15L604.08,257.99L586.85,237.36z"
        />
        <path
          id="UG-323"
          title="Alebtong"
          className="land"
          d="M588.6,272.8L503.11,258.43L491.91,273.08L516.88,287.3L520.84,315.48L551.08,319.43L562.86,322.4L588.6,272.8z"
        />
        <path
          id="UG-326"
          title="Kole"
          className="land"
          d="M480.64,309.52L480.38,255.28L446.62,271.67L441.91,298.68L480.64,309.52z"
        />
        <path
          id="UG-302"
          title="Apac"
          className="land"
          d="M480.64,309.52L441.91,298.68L430.27,317.79L412.03,314.54L411.25,344.71L375.92,374.5L376.31,377.13L405.56,377.01L448.53,376.53L474.83,354.48L506.63,367.16L511.84,352.15L488.8,332.63L491.72,314.24L480.64,309.52z"
        />
        <path
          id="UG-327"
          title="Lamwo"
          className="land"
          d="M547.02,66.72L507.79,47.26L415.71,70.05L386.87,100.12L413.6,135.6L436.82,157.85L476.23,151.34L474.27,120.09L531.08,98.96L549.59,114.49L547.02,66.72z"
        />
        <path
          id="UG-123"
          title="Kyankwanzi"
          className="land"
          d="M334.8,419.95L289.38,419.32L256.51,441.09L290.49,468.5L278.24,492.92L313.07,492.25L354.05,457.23L334.8,419.95z"
        />
        <path
          id="UG-319"
          title="Amuru"
          className="land"
          d="M386.87,100.12L368.95,95.35L350.8,172.54L295.45,197.17L272.96,184.43L271.58,209.49L276.1,234.29L323.78,207.83L390.58,217.76L387.28,170.03L413.6,135.6L386.87,100.12z"
        />
        <path
          id="UG-121"
          title="Gomba"
          className="land"
          d="M334.68,578.1L298.39,570.07L263.03,590.24L300.87,612.5L309.27,612.83L330.56,612.39L381.77,582.11L334.68,578.1z"
        />
        <path
          id="UG-119"
          title="Butambala"
          className="land"
          d="M339.74,620.85L399.95,580.42L381.77,582.11L330.56,612.39L339.74,620.85z"
        />
        <path
          id="UG-120"
          title="Buvuma"
          className="land"
          d="M565.18,575.21L548.37,568.2L485.71,687.8L485.71,767.39L570.98,767.39L565.18,575.21z"
        />
        <path
          id="UG-117"
          title="Buikwe"
          className="land"
          d="M522.71,539.64L512.86,533.34L511.23,566.77L450.05,643.78L485.71,643.78L485.71,687.8L548.37,568.2L522.71,539.64z"
        />
        <path
          id="UG-232"
          title="Serere"
          className="land"
          d="M613.53,385.4L583.16,386.35L560.35,366.97L545.72,369.55L538.83,418.21L567.83,423.12L590.55,423.92L613.53,385.4z"
        />
        <path
          id="UG-227"
          title="Kibuku"
          className="land"
          d="M632.41,481.52L641.26,447.81L601.39,459.62L617.58,481.94L632.41,481.52z"
        />
        <path
          id="UG-217"
          title="Budaka"
          className="land"
          d="M674.55,455.67L641.26,447.81L632.41,481.52L665.62,467.52L674.55,455.67z"
        />
        <path
          id="UG-226"
          title="Buyende"
          className="land"
          d="M502.93,402.79L480.43,402.55L502.51,445.78L551.96,480.82L558.08,460.86L573.36,430.18L567.83,423.12L538.83,418.21L502.93,402.79z"
        />
        <path
          id="UG-229"
          title="Luuka"
          className="land"
          d="M571.12,491.46L558.08,460.86L551.96,480.82L540.78,520.85L584.33,539.16L571.12,491.46z"
        />
        <path
          id="UG-224"
          title="Bukedea"
          className="land"
          d="M686.96,436.95L689.25,390.69L661.72,399.64L644.63,436.28L672.58,446.34L678.53,448.5L686.96,436.95z"
        />
        <path
          id="UG-231"
          title="Ngora"
          className="land"
          d="M640.1,378.5L627.54,374.11L613.53,385.4L590.55,423.92L624.39,426.35L640.1,378.5z"
        />
        <path
          id="UG-331"
          title="Zombo"
          className="land"
          d="M220.85,238.47L208.74,216.6L179.99,225.66L171.12,259.66L194.86,276.32L209.03,265.51L220.85,238.47z"
        />
        <path
          id="UG-414"
          title="Kanungu"
          className="land"
          d="M51.8,773.5L23.82,683.42L11.94,684.9L0.48,765.58L30.38,778.15L51.8,773.5z"
        />
        <path
          id="UG-408"
          title="Kisoro"
          className="land"
          d="M30.38,778.15L0.48,765.58L4.36,824.2L40.75,814.7L30.38,778.15z"
        />
        <path
          id="UG-404"
          title="Kabale"
          className="land"
          d="M57.45,836.65L109.34,789.48L92.63,795.5L76.58,779.77L51.8,773.5L30.38,778.15L40.75,814.7L57.45,836.65z"
        />
        <path
          id="UG-410"
          title="Mbarara"
          className="land"
          d="M179.17,708.85L185.72,693.05L158.02,653.22L140.65,657.44L135.71,685.43L129.1,712.57L106.66,730.92L153.37,738.39L161.83,709.88L179.17,708.85z"
        />
        <path
          id="UG-412"
          title="Rukungiri"
          className="land"
          d="M53.86,678.92L14.07,672.48L11.94,684.9L23.82,683.42L51.8,773.5L76.58,779.77L75.08,731.67L53.75,711.28L58.06,694.13L53.86,678.92z"
        />
        <path
          id="UG-411"
          title="Ntungamo"
          className="land"
          d="M94.09,726.49L75.08,731.67L76.58,779.77L92.63,795.5L109.34,789.48L134.36,776.35L153.37,738.39L106.66,730.92L94.09,726.49z"
        />
        <path
          id="UG-418"
          title="Mbarara"
          className="land"
          d="M225.17,626.31L213.85,589.98L192.06,592.42L161.34,595.71L174.63,602.06L155.56,630.38L158.02,653.22L185.72,693.05L179.17,708.85L193.91,722.11L224.77,715.87L233.58,697.57L225.17,626.31z"
        />
        <path
          id="UG-417"
          title="Isingiro"
          className="land"
          d="M179.17,708.85L161.83,709.88L153.37,738.39L134.36,776.35L255.04,767.39L224.77,715.87L193.91,722.11L179.17,708.85z"
        />
        <path
          id="UG-416"
          title="Ibanda"
          className="land"
          d="M158.02,653.22L155.56,630.38L174.63,602.06L161.34,595.71L125.5,623.94L134.84,642.72L103.27,637.42L114.12,650.93L140.65,657.44L158.02,653.22z"
        />
        <path
          id="UG-413"
          title="Kamwenge"
          className="land"
          d="M196.62,558.77L147.54,575.97L129.54,540.08L119.78,586.25L106.07,580.97L96.92,631.44L103.27,637.42L134.84,642.72L125.5,623.94L161.34,595.71L192.06,592.42L196.62,558.77z"
        />
        <path
          id="UG-406"
          title="Kasese"
          className="land"
          d="M106.07,580.97L75.24,565.66L41.87,570.15L19.58,640.07L67.15,645.4L96.92,631.44L106.07,580.97z"
        />
        <path
          id="UG-402"
          title="Bushenyi"
          className="land"
          d="M86.8,681.23L53.86,678.92L58.06,694.13L90.84,713.01L112.8,710.7L114.53,692.21L86.8,681.23z"
        />
        <path
          id="UG-105"
          title="Masaka"
          className="land"
          d="M358.94,733.71L358.94,680.61L370.96,671.3L357.22,653.69L319.91,658.37L305.24,688.96L324.44,727.54L358.94,733.71z"
        />
        <path
          id="UG-110"
          title="Rakai"
          className="land"
          d="M305.24,688.96L281.35,708.66L248.63,688.1L233.58,697.57L224.77,715.87L255.04,767.39L358.94,767.39L358.94,733.71L324.44,727.54L305.24,688.96z"
        />
        <path
          id="UG-311"
          title="Nakapiripirit"
          className="land"
          d="M779.34,308.6L766.54,253.91L748.96,254.85L744.01,313.55L686.62,331.93L686.6,379.56L689.25,390.69L711.04,390.47L749.26,392.88L771.57,372.23L779.34,308.6z"
        />
        <path
          id="UG-308"
          title="Moroto"
          className="land"
          d="M791.23,294.2L784.35,238.13L735.75,163.5L712.3,165.83L692.49,202.53L750.37,230.8L766.54,253.91L779.34,308.6L791.23,294.2z"
        />
        <path
          id="UG-317"
          title="Abim"
          className="land"
          d="M648.16,234.35L632.96,226.73L632.23,187.77L606.92,169.92L608.13,151.56L589.92,149.01L597.09,209.81L586.85,237.36L604.08,257.99L656.33,251.22L648.16,234.35z"
        />
        <path
          id="UG-315"
          title="Kaabong"
          className="land"
          d="M656.32,0L585.9,64.1L617.65,125.8L712.3,165.83L735.75,163.5L711.97,120.61L720.17,79.39L677.88,63.47L685.61,48.88L667.91,48.47L656.32,0z"
        />
        <path
          id="UG-216"
          title="Amuria"
          className="land"
          d="M641.54,280.41L614.75,264.41L602.1,272.15L588.6,272.8L562.86,322.4L573.54,341.83L586.37,323.34L627.31,368.21L641.54,280.41z"
        />
        <path
          id="UG-306"
          title="Kotido"
          className="land"
          d="M712.3,165.83L617.65,125.8L608.52,140.8L589.18,138.25L589.92,149.01L608.13,151.56L606.92,169.92L632.23,187.77L632.96,226.73L648.16,234.35L650.03,218.78L688.71,218.48L692.49,202.53L712.3,165.83z"
        />
        <path
          id="UG-207"
          title="Katakwi"
          className="land"
          d="M686.62,331.93L685.31,308.84L641.54,280.41L627.31,368.21L627.54,374.11L640.1,378.5L686.6,379.56L686.62,331.93z"
        />
        <path
          id="UG-212"
          title="Tororo"
          className="land"
          d="M679.54,497.85L667.94,486.91L635.58,518.98L649.12,536.71L674.81,535.93L699.05,509.82L679.54,497.85z"
        />
        <path
          id="UG-214"
          title="Mayuge"
          className="land"
          d="M597.84,554.65L591.49,553.41L565.18,575.21L570.98,767.39L601.22,767.39L601.22,635L617.25,625.76L604.54,609L609.01,572.41L597.84,554.65z"
        />
        <path
          id="UG-219"
          title="Butaleja"
          className="land"
          d="M667.94,486.91L665.62,467.52L632.41,481.52L617.58,481.94L631.5,518.23L635.58,518.98L667.94,486.91z"
        />
        <path
          id="UG-202"
          title="Busia"
          className="land"
          d="M674.81,535.93L649.12,536.71L639.61,549.99L649.58,590.52L674.81,535.93z"
        />
        <path
          id="UG-201"
          title="Bugiri"
          className="land"
          d="M631.5,518.23L601.04,511.86L597.84,554.65L609.01,572.41L639.61,549.99L649.12,536.71L635.58,518.98L631.5,518.23z"
        />
        <path
          id="UG-221"
          title="Manafwa"
          className="land"
          d="M723.65,472.53L694.24,472.65L679.54,497.85L699.05,509.82L723.65,472.53z"
        />
        <path
          id="UG-209"
          title="Mbale"
          className="land"
          d="M678.53,448.5L672.58,446.34L674.55,455.67L665.62,467.52L667.94,486.91L679.54,497.85L694.24,472.65L708.01,459.8L678.53,448.5z"
        />
        <path
          id="UG-215"
          title="Sironko"
          className="land"
          d="M732.61,448.64L686.96,436.95L678.53,448.5L708.01,459.8L730.15,457.43L732.61,448.64z"
        />
        <path
          id="UG-218"
          title="Bukwa"
          className="land"
          d="M770.38,417.78L764.71,410.76L736.18,443.02L737.89,458.81L772.91,438.38L770.38,417.78z"
        />
        <path
          id="UG-206"
          title="Kapchorwa"
          className="land"
          d="M736.18,443.02L738.85,417.54L719.21,406.91L700.93,425.22L732.61,448.64L730.15,457.43L737.89,458.81L736.18,443.02z"
        />
        <path
          id="UG-407"
          title="Kibale"
          className="land"
          d="M256.51,441.09L147.21,461.6L144.12,474.6L146.8,482.91L204.5,517.02L245.3,528.13L278.24,492.92L290.49,468.5L256.51,441.09z"
        />
        <path
          id="UG-415"
          title="Kyenjojo"
          className="land"
          d="M204.5,517.02L146.8,482.91L129.54,540.08L147.54,575.97L196.62,558.77L204.5,517.02z"
        />
        <path
          id="UG-405"
          title="Kabarole"
          className="land"
          d="M146.8,482.91L144.12,474.6L91.59,514.54L66.23,555.93L52.36,555.25L41.87,570.15L75.24,565.66L106.07,580.97L119.78,586.25L129.54,540.08L146.8,482.91z"
        />
        <path
          id="UG-403"
          title="Hoima"
          className="land"
          d="M266.95,363.81L261.88,351.62L224.86,348.72L148.03,423.1L147.21,461.6L256.51,441.09L289.38,419.32L266.95,363.81z"
        />
        <path
          id="UG-401"
          title="Bundibugyo"
          className="land"
          d="M91.59,514.54L103.62,494.18L93.52,479.21L58.75,498.36L52.36,555.25L66.23,555.93L91.59,514.54z"
        />
        <path
          id="UG-321"
          title="Oyam"
          className="land"
          d="M458.68,236.27L432.53,235.66L414.33,256.05L396.6,257.4L399.26,287.41L412.03,314.54L430.27,317.79L441.91,298.68L446.62,271.67L480.38,255.28L475.12,239.99L458.68,236.27z"
        />
        <path
          id="UG-409"
          title="Masindi"
          className="land"
          d="M375.92,374.5L346.89,370.81L342.65,330.52L324.28,316.37L324.96,281.3L294.11,284.32L293.19,315.67L308.73,336.88L294.6,360.92L266.95,363.81L289.38,419.32L334.8,419.95L355.01,402.44L376.31,377.13L375.92,374.5z"
        />
        <path
          id="UG-312"
          title="Pader"
          className="land"
          d="M531.08,175.27L506.58,152.32L497.62,161.86L476.23,151.34L436.82,157.85L468.8,217.37L458.68,236.27L475.12,239.99L483.94,231.23L510.36,246.68L527.14,235.09L516.45,220.52L539.26,215.35L544.86,200.27L524.63,193.37L531.08,175.27z"
        />
        <path
          id="UG-318"
          title="Dokolo"
          className="land"
          d="M551.08,319.43L520.84,315.48L491.72,314.24L488.8,332.63L511.84,352.15L506.63,367.16L522.12,366.93L551.08,319.43z"
        />
        <path
          id="UG-307"
          title="Lira"
          className="land"
          d="M510.36,246.68L483.94,231.23L475.12,239.99L480.38,255.28L480.64,309.52L491.72,314.24L520.84,315.48L516.88,287.3L491.91,273.08L503.11,258.43L510.36,246.68z"
        />
        <path
          id="UG-304"
          title="Gulu"
          className="land"
          d="M436.82,157.85L413.6,135.6L387.28,170.03L390.58,217.76L386.94,234.85L414.33,256.05L432.53,235.66L458.68,236.27L468.8,217.37L436.82,157.85z"
        />
        <path
          id="UG-305"
          title="Kitgum"
          className="land"
          d="M617.65,125.8L585.9,64.1L547.02,66.72L549.59,114.49L531.08,98.96L474.27,120.09L476.23,151.34L497.62,161.86L506.58,152.32L531.08,175.27L539.75,150.15L561.21,148.98L565.52,133.71L589.18,138.25L608.52,140.8L617.65,125.8z"
        />
        <path
          id="UG-115"
          title="Nakaseke"
          className="land"
          d="M420.75,459.21L355.01,402.44L334.8,419.95L354.05,457.23L367.34,485.85L393.24,500.79L388.02,516.34L393.75,535.24L409.61,539.36L433.09,516.97L420.75,459.21z"
        />
        <path
          id="UG-114"
          title="Mityana"
          className="land"
          d="M393.75,535.24L388.02,516.34L354.11,524.29L356.03,570.03L334.68,578.1L381.77,582.11L399.95,580.42L399.7,555.78L393.75,535.24z"
        />
        <path
          id="UG-107"
          title="Mubende"
          className="land"
          d="M354.11,524.29L313.07,492.25L278.24,492.92L245.3,528.13L249.68,542.63L234.02,548.35L215.03,589.33L263.03,590.24L298.39,570.07L334.68,578.1L356.03,570.03L354.11,524.29z"
        />
        <path
          id="UG-104"
          title="Luweero"
          className="land"
          d="M475.85,460.57L455.18,452.53L452.83,477.23L420.75,459.21L433.09,516.97L409.61,539.36L459.64,537.84L471.89,533.26L477.67,495.31L475.85,460.57z"
        />
        <path
          id="UG-113"
          title="Wakiso"
          className="land"
          d="M409.61,539.36L393.75,535.24L399.7,555.78L425.69,566.76L394.38,612.46L397.51,643.09L433.58,643.78L452.5,619.42L458.76,577.42L438.05,575.11L441.81,548.59L458.07,545.61L459.64,537.84L409.61,539.36z"
        />
        <path
          id="UG-109"
          title="Nakasongola"
          className="land"
          d="M405.56,377.01L376.31,377.13L355.01,402.44L420.75,459.21L452.83,477.23L455.18,452.53L475.85,460.57L462.26,403.24L405.56,377.01z"
        />
        <path
          id="UG-103"
          title="Kiboga"
          className="land"
          d="M388.02,516.34L393.24,500.79L367.34,485.85L354.05,457.23L313.07,492.25L354.11,524.29L388.02,516.34z"
        />
        <path
          id="UG-102"
          title="Kampala"
          className="land"
          d="M458.76,577.42L458.07,545.61L441.81,548.59L438.05,575.11L458.76,577.42z"
        />
        <path
          id="UG-320"
          title="Maracha"
          className="land"
          d="M221.65,131.72L199.74,120.95L178.91,159.67L201.34,160.59L221.65,131.72z"
        />
        <path
          id="UG-313"
          title="Yumbe"
          className="land"
          d="M304.13,75.08L288.16,80.56L245.37,61.01L223.85,69.95L221.65,131.72L243.99,128.49L289.18,160.39L290.96,156.54L282.02,110.34L304.13,75.08z"
        />
        <path
          id="UG-309"
          title="Moyo"
          className="land"
          d="M364.24,91.39L328.72,57.55L304.13,75.08L282.02,110.34L290.96,156.54L315.81,106.15L364.24,91.39z"
        />
        <path
          id="UG-310"
          title="Nebbi"
          className="land"
          d="M276.1,234.29L271.58,209.49L250.84,231.59L220.85,238.47L209.03,265.51L254.98,302.93L264.63,298.79L291.41,253.09L276.1,234.29z"
        />
        <path
          id="UG-419"
          title="Buliisa"
          className="land"
          d="M266.95,363.81L294.6,360.92L308.73,336.88L293.19,315.67L294.11,284.32L264.63,298.79L254.98,302.93L224.86,348.72L261.88,351.62L266.95,363.81z"
        />
        <path
          id="UG-316"
          title="Koboko"
          className="land"
          d="M221.65,131.72L223.85,69.95L190.74,103.27L199.74,120.95L221.65,131.72z"
        />
        <path
          id="UG-303"
          title="Arua"
          className="land"
          d="M289.18,160.39L243.99,128.49L221.65,131.72L201.34,160.59L178.91,159.67L192.37,193.62L179.99,225.66L208.74,216.6L220.85,238.47L250.84,231.59L271.58,209.49L272.96,184.43L289.18,160.39z"
        />
        <path
          id="UG-301"
          title="Adjumani"
          className="land"
          d="M368.95,95.35L364.24,91.39L315.81,106.15L290.96,156.54L289.18,160.39L272.96,184.43L295.45,197.17L350.8,172.54L368.95,95.35z"
        />
        <path
          id="UG-106"
          title="Mpigi"
          className="land"
          d="M397.51,643.09L394.38,612.46L425.69,566.76L399.7,555.78L399.95,580.42L339.74,620.85L351.5,638.43L397.51,643.09z"
        />
        <path
          id="UG-111"
          title="Sembabule"
          className="land"
          d="M263.03,590.24L215.03,589.33L213.85,589.98L225.17,626.31L256.1,630.95L254.7,658.18L303.14,658.37L300.87,612.5L263.03,590.24z"
        />
        <path
          id="UG-210"
          title="Pallisa"
          className="land"
          d="M624.39,426.35L590.55,423.92L567.83,423.12L573.36,430.18L601.39,459.62L641.26,447.81L674.55,455.67L672.58,446.34L644.63,436.28L624.39,426.35z"
        />
        <path
          id="UG-108"
          title="Mukono"
          className="land"
          d="M512.86,533.34L477.67,495.31L471.89,533.26L459.64,537.84L458.07,545.61L458.76,577.42L452.5,619.42L433.58,643.78L450.05,643.78L511.23,566.77L512.86,533.34z"
        />
        <path
          id="UG-211"
          title="Soroti"
          className="land"
          d="M627.31,368.21L586.37,323.34L573.54,341.83L560.35,366.97L583.16,386.35L613.53,385.4L627.54,374.11L627.31,368.21z"
        />
        <path
          id="UG-222"
          title="Namutumba"
          className="land"
          d="M617.58,481.94L601.39,459.62L586.5,490.06L601.04,511.86L631.5,518.23L617.58,481.94z"
        />
        <path
          id="UG-220"
          title="Kaliro"
          className="land"
          d="M601.39,459.62L573.36,430.18L558.08,460.86L571.12,491.46L586.5,490.06L601.39,459.62z"
        />
        <path
          id="UG-314"
          title="Amolatar"
          className="land"
          d="M506.63,367.16L474.83,354.48L448.53,376.53L405.56,377.01L462.26,403.24L480.43,402.55L502.93,402.79L506.63,367.16z"
        />
        <path
          id="UG-205"
          title="Kamuli"
          className="land"
          d="M551.96,480.82L502.51,445.78L515.41,522.13L540.78,520.85L551.96,480.82z"
        />
        <path
          id="UG-203"
          title="Iganga"
          className="land"
          d="M601.04,511.86L586.5,490.06L571.12,491.46L584.33,539.16L591.49,553.41L597.84,554.65L601.04,511.86z"
        />
        <path
          id="UG-112"
          title="Kayunga"
          className="land"
          d="M502.51,445.78L480.43,402.55L462.26,403.24L475.85,460.57L477.67,495.31L512.86,533.34L522.71,539.64L515.41,522.13L502.51,445.78z"
        />
        <path
          id="UG-213"
          title="Kaberamaido"
          className="land"
          d="M562.86,322.4L551.08,319.43L522.12,366.93L506.63,367.16L502.93,402.79L538.83,418.21L545.72,369.55L560.35,366.97L573.54,341.83L562.86,322.4z"
        />
        <path
          id="UG-208"
          title="Kumi"
          className="land"
          d="M686.6,379.56L640.1,378.5L624.39,426.35L644.63,436.28L661.72,399.64L689.25,390.69L686.6,379.56z"
        />
        <path
          id="UG-204"
          title="Jinja"
          className="land"
          d="M540.78,520.85L515.41,522.13L522.71,539.64L548.37,568.2L565.18,575.21L591.49,553.41L584.33,539.16L540.78,520.85z"
        />
        <path
          id="UG-101"
          title="Kalangala"
          className="land"
          d="M397.51,643.09L351.5,638.43L357.22,653.69L370.96,671.3L358.94,680.61L358.94,733.71L358.94,767.39L485.71,767.39L485.71,687.8L485.71,643.78L450.05,643.78L433.58,643.78L397.51,643.09z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Uganda))
