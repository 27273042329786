import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { passDispatchToProps, mapIsAnnyangSupportedStateToProps } from '../redux/mapToPropsUtil'
import { common } from '../lib/languageUtil'

const AnnyangNotSupportedShowAnswerButton = ({ isAnnyangSupported, onClick }) =>
  !isAnnyangSupported && (
    <button className="annyang-not-supported-show-answer-button" onClick={onClick}>
      {common('showAnswer')}
    </button>
  )

export default withTranslation()(
  connect(
    mapIsAnnyangSupportedStateToProps,
    passDispatchToProps
  )(AnnyangNotSupportedShowAnswerButton)
)
