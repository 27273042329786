const getSignName = {
  en: {
    additions: 'plus',
    subtractions: 'minus',
    multiplications: 'times',
    divisions: 'divided by'
  },
  fr: {
    additions: 'plusse',
    subtractions: 'moins',
    multiplications: 'fois',
    divisions: 'divisé par'
  }
}

export { getSignName }
