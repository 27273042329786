import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 805
const height = 162

const cities = {
  Palikir: { x: 716, y: 123 }
}

const Micronesia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map micronesia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="FM"
          title="Micronesia"
          className="land"
          d="M796.3,151.1L797.4,149.4L796.7,149L796.9,148.1L796.8,148.7L797.3,148.7L796.9,147.6L795.2,147.9L793.1,149.9L793.5,150.6L794.4,150.9L794,150.7L795.2,150.4L796.3,151.1zM503.8,143.5L504.2,143L503.4,143.5L503.8,143.5zM645.3,95.4L645.3,95L645,95.7L645.3,95.4zM647.4,100L647.2,100.4L647.8,100.4L647.4,100zM643.6,95.8L643.5,96L643.9,96.4L643.7,96.3L643.4,96.8L643.4,96L642.8,95.9L642.8,97.4L641.8,96.8L641.9,97.2L640.8,97.6L640.8,98.4L641.2,98.4L640.9,98.9L642,98.9L642.2,99.6L641.7,99.9L642.3,101.7L643.5,101.9L643.7,101.5L644.2,102.3L644.4,101.8L645.4,102.1L645.3,101.6L646.5,102.3L647.4,100.5L647.2,100.6L646.5,99.9L646.4,99.6L646.7,99.7L647,99.4L646.5,99L647.3,99.4L647.4,99.8L647.9,99.5L647.4,97.5L646.7,98.1L646.3,97.6L646.9,96.6L646.5,97L646.2,97.8L646,96.6L645.4,96.3L644.8,96.9L644.4,96L644.1,96.5L644.5,96.9L644.3,97.3L644.4,97L644.1,96.8L644.1,96.5L643.7,96.1L644.2,95.8L643.6,95.8zM442.3,85.3L442.4,86.2L442.8,85.6L442.3,85.3zM432.3,84.6L433.2,85.1L433.8,84.1L432.3,84.6zM186.9,83.3L186.3,83.6L187.4,83.7L186.9,83.3zM188.9,83.2L188.5,83.5L188.9,83.7L188.9,83.2zM442.3,80.7L441.1,80.8L441.3,82.2L443.1,81.1L442.3,80.7zM554.8,80.1L554.6,79.6L554.3,79.3L554.2,78.8L554.8,76.3L554.3,75.6L553.8,75.5L553.4,75.6L552.8,76.3L553.7,75.5L554.2,75.6L554.8,76.4L554.2,78.4L554.1,79L554.2,79.2L554.6,79.6L554.9,80.3L554.5,80.8L554.8,80.5L554.9,80.3L554.8,80.1zM550.8,76.6L552.5,76.8L546.6,75.4L550.8,76.6zM5.9,11.5L5.7,10.6L5.4,11L5.9,11.5zM51.8,0L51,1.7L51.4,2.6L51.1,1.8L51.8,0zM2.6,16.5L3,16.8L4.3,14.6L4.8,14.9L4.6,13.9L5.5,13L4.9,13L5.1,12.1L3.3,14.3L2.6,16.5zM7.1,13.3L6.9,12.9L6.2,13.4L6,12.5L5.2,13.7L5.4,14.5L6,14.5L6.4,13.8L6.7,14.1L7.1,13.3zM5.8,12.8L6,12.2L6.3,12.5L6.5,12.1L6.4,11.3L5.5,12.3L5.8,12.8zM441.2,84.9L441.6,85.2L441.6,84L440.6,83.6L441.2,84.9zM434.2,83.9L434.8,84.2L434.8,83.5L433.4,82.7L434,83.7L433.8,85.2L434.8,84.8L434.2,83.9zM442.3,82.9L441.9,83.8L443.2,83.3L442.3,82.9zM432.8,83.3L432.9,83.7L433.6,83.4L432.8,83.3zM437.9,83.3L437.1,82.7L436.7,83.4L437.9,83.3z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Micronesia))
