import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 729
const height = 553

const cities = {
  Kabul: { x: 429, y: 242 }
}

// cityName as city or actual argument / windowWidth, windowHeight from connect
const Afghanistan = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map afghanistan">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-10 -10 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="AF-PIA"
          title="Paktya"
          className="land"
          d="M456.99,266.63L439.77,266.33L413.9,291.86L402.4,291.87L402.22,304.65L411.23,304.41L413.61,318.55L423.52,311.51L432.91,313.29L441.76,295.26L461.79,284.34L454.25,272.67L457.37,267.17L456.99,266.63z"
        />
        <path
          id="AF-WAR"
          title="Wardak"
          className="land"
          d="M406.78,232.71L383.11,232.34L378.63,222.2L363.67,234.26L330.91,231.96L328.58,243.98L342.99,252.99L337.33,261.14L367.55,257.61L371.96,271.82L387.09,287.11L399.46,281.54L411.94,248.79L406.78,232.71z"
        />
        <path
          id="AF-SAM"
          title="Samangan"
          className="land"
          d="M374.09,115.07L335.12,116.17L332.65,138.08L319.67,146.95L322.22,154.77L311.78,167.66L317.01,176.95L308.37,188.11L369.21,182.99L390.7,141.62L374.97,122.83L376.54,116.4L374.09,115.07z"
        />
        <path
          id="AF-PAR"
          title="Parwan"
          className="land"
          d="M426.23,187.09L392.85,206.6L373.85,209.82L378.63,222.2L383.11,232.34L406.78,232.71L412.61,216.58L427.96,220.31L435.19,234.54L442.76,231.12L440.9,215.7L427.88,209.57L427.19,200.8L426.23,187.09z"
        />
        <path
          id="AF-LOG"
          title="Logar"
          className="land"
          d="M437.02,259.95L427.1,247.91L411.94,248.79L399.46,281.54L402.4,291.87L413.9,291.86L439.77,266.33L456.99,266.63L449.11,254.6L437.02,259.95z"
        />
        <path
          id="AF-LAG"
          title="Laghman"
          className="land"
          d="M489.83,210.74L475.11,214.17L460.61,196.4L452.1,205.6L456.97,219.05L452.84,245.15L479.11,240.69L491.83,222.21L489.83,210.74z"
        />
        <path
          id="AF-PAN"
          title="Panjshayr"
          className="land"
          d="M475.23,159.76L460.07,162.18L449.78,178.23L426.23,187.09L427.19,200.8L452.1,205.6L460.61,196.4L458.16,189.26L473.18,170.52L475.23,159.76z"
        />
        <path
          id="AF-KAP"
          title="Kapisa"
          className="land"
          d="M452.1,205.6L427.19,200.8L427.88,209.57L440.9,215.7L442.76,231.12L456.97,219.05L452.1,205.6z"
        />
        <path
          id="AF-KAB"
          title="Kabul"
          className="land"
          d="M442.76,231.12L435.19,234.54L427.96,220.31L412.61,216.58L406.78,232.71L411.94,248.79L427.1,247.91L437.02,259.95L452.84,245.15L456.97,219.05L442.76,231.12z"
        />
        <path
          id="AF-BGL"
          title="Baghlan"
          className="land"
          d="M424.15,132.94L399.99,118.63L376.54,116.4L374.97,122.83L390.7,141.62L369.21,182.99L365.67,206.57L373.85,209.82L392.85,206.6L426.23,187.09L449.78,178.23L460.07,162.18L446.49,129.74L430.16,136.99L424.15,132.94z"
        />
        <path
          id="AF-TAK"
          title="Takhar"
          className="land"
          d="M462.16,56.8L438.12,55.11L428.41,83.32L420.58,121.47L424.15,132.94L430.16,136.99L446.49,129.74L460.07,162.18L475.23,159.76L484.37,152.55L484.89,133.75L463.83,122.99L462.28,95.14L467.98,87.84L462.16,56.8z"
        />
        <path
          id="AF-NAN"
          title="Nangarhar"
          className="land"
          d="M509.68,236.66L497.96,238.09L491.83,222.21L479.11,240.69L452.84,245.15L437.02,259.95L449.11,254.6L456.99,266.63L457.37,267.17L487.29,272.85L512.75,266.81L516.62,249.78L509.68,236.66z"
        />
        <path
          id="AF-KDZ"
          title="Kunduz"
          className="land"
          d="M428.41,83.32L408,69.83L366.12,94.95L374.09,115.07L376.54,116.4L399.99,118.63L424.15,132.94L420.58,121.47L428.41,83.32z"
        />
        <path
          id="AF-KNR"
          title="Kunar"
          className="land"
          d="M509.68,236.66L541.02,200.3L539.28,182.81L520.6,192.59L515.88,204.26L493.6,202.59L489.83,210.74L491.83,222.21L497.96,238.09L509.68,236.66z"
        />
        <path
          id="AF-NUR"
          title="Nuristan"
          className="land"
          d="M539.28,182.81L531.4,160.15L518.73,148.94L503.91,181.62L495.31,178.47L488.33,183.88L485.23,170.72L473.18,170.52L458.16,189.26L460.61,196.4L475.11,214.17L489.83,210.74L493.6,202.59L515.88,204.26L520.6,192.59L539.28,182.81z"
        />
        <path
          id="AF-BDS"
          title="Badakhshan"
          className="land"
          d="M509.22,0L494.22,4.23L470.31,32.58L475.31,49.2L462.16,56.8L467.98,87.84L462.28,95.14L463.83,122.99L484.89,133.75L484.37,152.55L475.23,159.76L473.18,170.52L485.23,170.72L488.33,183.88L495.31,178.47L503.91,181.62L518.73,148.94L540.1,123.24L548.46,126.94L569.37,107.01L616.83,97.89L657.76,101.61L682.12,92.72L673.81,81.44L679.69,76.68L699.34,76.36L688.09,66.42L671.36,64.41L641.94,77.22L636.51,74.49L644.09,64.32L621.9,62.09L597.37,77.02L590.92,88.67L542.17,109.4L531.4,86.35L539.49,35.52L523.01,33.79L527.86,13.76L509.22,0z"
        />
        <path
          id="AF-PKA"
          title="Paktika"
          className="land"
          d="M432.91,313.29L423.52,311.51L413.61,318.55L411.23,304.41L402.22,304.65L376.24,340.6L367.09,343.61L360.46,338.03L356.13,344.38L367.69,356.13L367.41,379.35L369.69,398.75L372.64,394.94L389.08,400.68L385.24,397.39L390.51,395.11L403.92,407.68L416.92,402.25L428.23,388.02L424.74,358.45L434.11,347.37L436.64,326.8L430.19,324.95L432.91,313.29z"
        />
        <path
          id="AF-KHO"
          title="Khost"
          className="land"
          d="M436.64,326.8L462.29,319.82L476.25,308.97L467.69,285.83L461.79,284.34L441.76,295.26L432.91,313.29L430.19,324.95L436.64,326.8z"
        />
        <path
          id="AF-GHA"
          title="Ghazni"
          className="land"
          d="M399.46,281.54L387.09,287.11L371.96,271.82L367.55,257.61L337.33,261.14L333.23,267.12L334.8,277.45L308.85,293.77L308.11,311.17L316.16,313.63L311.83,325.6L316.95,331.67L321.03,323.78L330.67,330.51L337.03,326.93L336.12,339.08L354.28,363.28L347.09,370.54L367.41,379.35L367.69,356.13L356.13,344.38L360.46,338.03L367.09,343.61L376.24,340.6L402.22,304.65L402.4,291.87L399.46,281.54z"
        />
        <path
          id="AF-ZAB"
          title="Zabul"
          className="land"
          d="M367.41,379.35L347.09,370.54L354.28,363.28L336.12,339.08L337.03,326.93L330.67,330.51L321.03,323.78L316.95,331.67L299.56,346.08L297.23,341.1L288.16,346.29L283.72,356.78L294.06,355.88L283.67,373.28L283.87,388.98L276.71,394.41L283,402.42L301.49,392.02L314.18,400.35L325.55,398.65L336.61,410.88L352.7,410.99L369.69,398.75L367.41,379.35z"
        />
        <path
          id="AF-KAN"
          title="Kandahar"
          className="land"
          d="M283.72,356.78L254.13,359.76L254.05,352.37L248.35,352L240.56,369.12L230.77,366.42L224.68,381.5L216.74,382.45L210.24,394.2L195.07,522.24L221.12,523.91L277.37,507.43L284.4,500.64L278.52,494.97L286.09,445.48L311.83,424.57L333.2,430.12L353.5,422.95L343.38,412.82L352.7,410.99L336.61,410.88L325.55,398.65L314.18,400.35L301.49,392.02L283,402.42L276.71,394.41L283.87,388.98L283.67,373.28L294.06,355.88L283.72,356.78z"
        />
        <path
          id="AF-DAY"
          title="Daykundi"
          className="land"
          d="M291.51,246.43L244.67,249.03L244.61,270.83L238.73,277.09L247.35,292.34L229.98,306.41L236.25,322.1L270.29,323.89L283.16,309.71L308.11,311.17L308.85,293.77L334.8,277.45L333.23,267.12L299.15,270.76L292.91,265.69L291.51,246.43z"
        />
        <path
          id="AF-URU"
          title="Uruzgan"
          className="land"
          d="M308.11,311.17L283.16,309.71L270.29,323.89L236.25,322.1L228.3,333.46L230.77,366.42L240.56,369.12L248.35,352L254.05,352.37L254.13,359.76L283.72,356.78L288.16,346.29L297.23,341.1L299.56,346.08L316.95,331.67L311.83,325.6L316.16,313.63L308.11,311.17z"
        />
        <path
          id="AF-NIM"
          title="Nimroz"
          className="land"
          d="M148.03,373.52L129.95,370.52L123.94,383.95L109.86,388.99L99.96,380.88L72.78,383.78L54.68,419.84L61.62,424.75L64.19,450.55L17.72,506.15L103.03,530.72L111.03,486.74L126.79,469.07L129.25,445.5L138.95,434.8L148.03,373.52z"
        />
        <path
          id="AF-HEL"
          title="Helmand"
          className="land"
          d="M175.01,532.47L195.07,522.24L210.24,394.2L216.74,382.45L224.68,381.5L230.77,366.42L228.3,333.46L236.25,322.1L229.98,306.41L206.02,314.26L184.64,349.31L171.52,352.18L171.53,359.47L148.03,373.52L138.95,434.8L129.25,445.5L126.79,469.07L111.03,486.74L103.03,530.72L149.9,526.29L175.01,532.47z"
        />
        <path
          id="AF-FRA"
          title="Farah"
          className="land"
          d="M206.02,314.26L197.57,308.73L190.21,319.3L181.96,318.25L168.83,297.47L149.05,307.51L129.12,308.94L123.02,318.54L98.47,324.11L90.72,336.55L75.98,339.98L73.57,333.38L66.79,338.04L57.6,332.51L66.19,318.43L61.9,311.71L66.38,299.81L21.38,297.71L3.99,319.5L17.03,370.73L16.62,413.82L54.68,419.84L72.78,383.78L99.96,380.88L109.86,388.99L123.94,383.95L129.95,370.52L148.03,373.52L171.53,359.47L171.52,352.18L184.64,349.31L206.02,314.26z"
        />
        <path
          id="AF-SAR"
          title="Sari Pul"
          className="land"
          d="M311.78,167.66L292.28,170.18L298.43,158.24L297.1,140.97L290.26,137.32L300.88,118.16L293.46,115.41L244.14,130.87L244.93,152.11L252.6,150.76L258.98,160.58L242.65,171.97L241.26,195.32L250.52,194.39L269.66,206.67L272.97,223.14L285.52,215.47L285.23,200.22L300.34,198.36L308.37,188.11L317.01,176.95L311.78,167.66z"
        />
        <path
          id="AF-GHO"
          title="Ghor"
          className="land"
          d="M285.52,215.47L272.97,223.14L269.66,206.67L250.52,194.39L241.26,195.32L206.26,197.75L219.32,208.86L220.53,221.53L193.94,229.81L194.2,240.36L180.43,241.69L176.56,256.05L146.24,253.53L141.13,264.93L150.6,280.79L145.47,289.14L125.93,296.53L129.12,308.94L149.05,307.51L168.83,297.47L181.96,318.25L190.21,319.3L197.57,308.73L206.02,314.26L229.98,306.41L247.35,292.34L238.73,277.09L244.61,270.83L244.67,249.03L291.51,246.43L303.58,240.66L305.38,232.32L285.52,215.47z"
        />
        <path
          id="AF-JOW"
          title="Jawzjan"
          className="land"
          d="M280.05,68.52L256.3,55.15L243.03,75.7L235.44,93.41L242.88,127.6L231.26,129.99L224.32,152.32L239.5,156.21L252.6,150.76L244.93,152.11L244.14,130.87L293.46,115.41L288.13,106.24L295.42,103.22L292.31,83.59L280.05,68.52z"
        />
        <path
          id="AF-FYB"
          title="Faryab"
          className="land"
          d="M252.6,150.76L239.5,156.21L224.32,152.32L231.26,129.99L242.88,127.6L235.44,93.41L243.03,75.7L208.62,83.25L195,133.27L173.61,144.47L173.02,150.86L166.29,148.88L170.96,180.58L191.01,185.58L191.04,196.34L206.26,197.75L241.26,195.32L242.65,171.97L258.98,160.58L252.6,150.76z"
        />
        <path
          id="AF-BAL"
          title="Balkh"
          className="land"
          d="M366.12,94.95L352.62,76.46L340.81,73.26L328.97,79.16L316.68,67.01L280.05,68.52L292.31,83.59L295.42,103.22L288.13,106.24L293.46,115.41L300.88,118.16L290.26,137.32L297.1,140.97L298.43,158.24L292.28,170.18L311.78,167.66L322.22,154.77L319.67,146.95L332.65,138.08L335.12,116.17L374.09,115.07L366.12,94.95z"
        />
        <path
          id="AF-BAM"
          title="Bamyan"
          className="land"
          d="M369.21,182.99L308.37,188.11L300.34,198.36L285.23,200.22L285.52,215.47L305.38,232.32L303.58,240.66L291.51,246.43L292.91,265.69L299.15,270.76L333.23,267.12L337.33,261.14L342.99,252.99L328.58,243.98L330.91,231.96L363.67,234.26L378.63,222.2L373.85,209.82L365.67,206.57L369.21,182.99z"
        />
        <path
          id="AF-HER"
          title="Hirat"
          className="land"
          d="M113.73,190.95L103.12,197.37L94.74,193.74L87.68,202.05L74.15,184.32L54.61,184.81L38.35,173.61L28.44,221.35L10.7,238.95L19.95,250.56L7.98,251.29L0.66,261.12L1.58,290.38L21.38,297.71L66.38,299.81L61.9,311.71L66.19,318.43L57.6,332.51L66.79,338.04L73.57,333.38L75.98,339.98L90.72,336.55L98.47,324.11L123.02,318.54L129.12,308.94L125.93,296.53L145.47,289.14L150.6,280.79L141.13,264.93L146.24,253.53L176.56,256.05L180.43,241.69L194.2,240.36L193.94,229.81L160.42,239.36L123.97,231.34L105.57,217.97L113.73,190.95z"
        />
        <path
          id="AF-BDG"
          title="Badghis"
          className="land"
          d="M206.26,197.75L191.04,196.34L191.01,185.58L170.96,180.58L166.29,148.88L127.36,159.39L132.71,168.79L126.03,173.24L126.2,184.43L113.73,190.95L105.57,217.97L123.97,231.34L160.42,239.36L193.94,229.81L220.53,221.53L219.32,208.86L206.26,197.75z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Afghanistan))
