import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, geographyMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps } from './util'
import { answerQuestionMap } from './answerQuestionMap'
import { getTranslatedAnswer } from './../../../lib/methodsUtil'

const HardCapitalsPage = props => {
  const { lngCode = 'en', actual: enCapital } = props

  const capital =
    lngCode === 'en' ? enCapital : getTranslatedAnswer(lngCode, answerQuestionMap, enCapital)

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'capitals',
        questionLevel: 'hard',
        capital,
        country: answerQuestionMap[lngCode][capital],
        enCountry: answerQuestionMap.en[enCapital]
      })}
    />
  )
}

export default connect(geographyMapStateToProps, passDispatchToProps)(HardCapitalsPage)
