import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 558

const cities = {
  'Port Moresby': { x: 334, y: 430 }
}

const PapuaNewGuinea = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map papua-new-guinea">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="PG-WBK"
          title="West New Britain"
          className="land"
          d="M571.24,190.34L556.51,187.93L541.4,201.74L537.01,215.6L517.68,222.59L510.25,216.79L499.5,223.11L491.71,221.39L485.88,208.71L494.88,196.34L490.13,193.46L477.47,221.04L468.83,223.16L466.76,218.46L457.71,224.29L443.21,224.85L430.19,217.73L407.92,221.7L401.24,216.64L396.59,221.34L398.93,234.18L434.16,246.47L434.15,254.72L448.29,249.04L455.87,251.58L464.92,262.5L506.45,261.33L524.93,253.02L515.94,235.89L535.6,230.36L536.52,225.06L548.17,220.48L545.82,215.48L555.31,197.28L572.63,193.55L571.24,190.34z"
        />
        <path
          id="PG-NPP"
          title="Northern"
          className="land"
          d="M377.33,352.81L325.25,352.6L357.11,379.58L352.73,398.84L375.76,422.55L381.47,437.26L421.28,457.46L430.06,455.73L429.04,448.07L430.99,443.1L455.72,437.82L441.25,426.92L448.16,407.22L409.46,409.44L402.05,388.62L390.65,382.2L386.25,355.03L377.33,352.81z"
        />
        <path
          id="PG-NIK"
          title="New Ireland"
          className="land"
          d="M678.11,144.83L676.7,141.25L673.84,145.08L678.11,144.83zM624.38,98.5L624.23,89.58L620.21,89.24L618.15,93.37L624.38,98.5zM536.13,84.62L530.8,81.99L524.86,86.04L536.13,84.62zM589.55,76.68L585.26,71.54L585.4,78.88L589.55,76.68zM539.32,71.81L527.12,63.63L531.33,71.12L523.23,73.62L541.8,78.27L579.76,106.23L605.1,117.27L626.19,148.73L626.29,169.07L637.28,185.01L649.93,159.97L639.83,139.29L620.23,130.58L592.62,100.17L539.32,71.81zM496.35,54.51L481.95,59.49L493.55,70.01L508.4,68.79L507.82,59.85L496.35,54.51zM462.36,0L460.95,7.69L473.25,12.06L462.36,0z"
        />
        <path
          id="PG-NCD"
          title="National Capital District"
          className="land"
          d="M340.84,433.91L335.44,426.5L329.07,428.99L330.97,431.79L340.84,433.91z"
        />
        <path
          id="PG-MPL"
          title="Morobe"
          className="land"
          d="M377.33,352.81L364.09,348.97L351.44,332.13L335.65,323.94L329.52,300.07L322.69,293.12L323.72,285.84L330.06,284.02L370.81,282.86L369.36,263.95L350.34,244.5L331.84,244.48L314.77,236.36L298.23,246.06L265.88,245.93L279.66,259.36L273.67,293.12L264.74,311.91L277.9,327.87L305.91,340.38L312.07,350.39L325.25,352.6L377.33,352.81zM369.47,218.94L366.32,225.51L379.82,237.73L382.3,226.56L369.47,218.94z"
        />
        <path
          id="PG-MBA"
          title="Milne Bay"
          className="land"
          d="M672.02,547.56L683.79,546.12L656.3,532.34L652.81,532.79L657.9,541.49L662.95,539.99L672.02,547.56zM707.42,530.29L695.91,534.09L709.68,535.97L707.42,530.29zM539.04,491.96L533.54,491.79L533.03,496.11L539.04,491.96zM527.34,488.71L529.92,493.06L524.7,492.1L530.8,494.67L531.69,489.16L527.34,488.71zM539.56,456.1L524.55,443.64L532.12,459.43L536.62,459.25L534.8,465.21L549.29,470.33L552.02,455.71L543.76,461.84L539.56,456.1zM467.19,477.64L480.14,485.8L488.49,484.33L476.5,489.13L495.35,497.24L506.9,496.51L520.79,489.47L503.28,481.03L503.55,477.12L518.69,477.75L530.36,471.64L517.32,474.49L515.98,470.67L480.99,462.93L469.44,450.5L487.08,444.45L484.83,440.39L455.72,437.82L430.99,443.1L429.04,448.07L442.51,449.41L465.89,464.44L467.19,477.64zM518.85,429.08L507.02,425.56L511.32,435.29L507.09,439.81L533.04,441.57L526.36,428.8L518.85,429.08zM503.5,425.49L495.52,417.02L490.15,422.03L500.68,433.82L503.5,425.49zM614.77,403.31L610.38,405.54L612.12,410.49L613.71,404.15L628.73,419.94L629.17,415.71L641.4,419.16L639.59,410.49L614.77,403.31zM543.88,375.21L536.97,380.25L543.49,383.71L539.32,391.41L544.57,396.95L543.88,375.21z"
        />
        <path
          id="PG-MRL"
          title="Manus"
          className="land"
          d="M372.24,49.36L368.64,46.8L364.28,51.5L372.24,49.36zM320,31.7L303.53,33.99L301.29,45L311.3,43.58L309.97,40.28L336.56,44.08L349.1,36.12L346.6,31.97L348.02,36.66L320,31.7z"
        />
        <path
          id="PG-EBR"
          title="East New Britain"
          className="land"
          d="M524.93,253.02L527.59,246.87L540.24,247.69L555.8,237.32L565.15,228.32L561.54,221.4L581.09,224.51L595.07,217.23L597.64,211.39L588.66,200.93L588.5,192.21L602.13,192.15L606.88,187.28L611.64,157.5L599.66,156.4L599.63,147.6L590.31,150.2L587.98,157.6L565.56,149.41L573.89,183.75L571.24,190.34L572.63,193.55L555.31,197.28L545.82,215.48L548.17,220.48L536.52,225.06L535.6,230.36L515.94,235.89L524.93,253.02z"
        />
        <path
          id="PG-CPM"
          title="Central"
          className="land"
          d="M325.25,352.6L312.07,350.39L305.91,340.38L294.41,349.25L293.45,383.79L304.9,395.42L304.65,406.33L318.97,411.71L324.39,407.8L320.13,420.36L330.97,431.79L329.07,428.99L335.44,426.5L340.84,433.91L363.56,464.84L371.52,461.91L377.33,468.5L386.71,463.37L399.76,470.33L412.26,469.66L414.94,465.21L418.03,472.17L467.19,477.64L465.89,464.44L442.51,449.41L429.04,448.07L430.06,455.73L421.28,457.46L381.47,437.26L375.76,422.55L352.73,398.84L357.11,379.58L325.25,352.6z"
        />
        <path
          id="PG-WPD"
          title="Western"
          className="land"
          d="M148.3,391.37L139.45,380.64L123.81,374.96L148.3,391.37zM133.67,372.21L146.62,377.95L144.67,372.54L133.67,372.21zM114.29,290.63L97.46,289.15L95.26,242.91L72.13,221.23L72.57,214.63L7.47,192.44L7.48,263.93L0.73,283.49L7.49,293.8L7.48,411.69L16.76,418.43L26.97,413.58L41.52,418.53L72.66,414.51L96.01,423.68L134.04,406L135.24,393.64L119.75,377.53L100,369.49L85.42,372.52L80.23,362.66L69.25,364.19L78.26,361.07L81.68,362.91L84.18,369.89L98.53,366.54L113.33,370.5L147.6,364.89L143.94,354.98L133.59,347.06L154.9,354.74L162.64,352.99L156.84,348.99L164.17,352.14L114.48,301.34L114.29,290.63z"
        />
        <path
          id="PG-WHM"
          title="Western Highlands"
          className="land"
          d="M222.03,234.85L193.85,208.95L180.26,206.98L169.72,215.84L172.26,230L155.15,239.1L156.88,242.75L195.72,265.67L204.01,259.53L209.39,234.7L222.03,234.85z"
        />
        <path
          id="PG-SHM"
          title="Southern Highlands"
          className="land"
          d="M101.85,194.09L78.62,191.34L80.1,203.42L72.57,214.63L72.13,221.23L95.26,242.91L97.46,289.15L114.29,290.63L204.95,285.39L191.35,276.52L195.72,265.67L156.88,242.75L140.39,243.86L108.77,227.04L103.19,219.86L101.85,194.09z"
        />
        <path
          id="PG-NSB"
          title="North Solomons"
          className="land"
          d="M738.35,218.7L732.51,215.92L735,242.3L759.7,262.98L758.24,275.6L776.01,290.74L786.27,292.53L796.73,284.78L796.55,289.14L799.27,283.44L791.32,265.51L782.08,255.61L772.24,253.41L751.78,222.11L738.35,218.7zM732.15,198.83L728.5,193.76L724.12,198.53L728.9,217.4L732.15,198.83z"
        />
        <path
          id="PG-GPK"
          title="Gulf"
          className="land"
          d="M236,292.66L204.95,285.39L114.29,290.63L114.48,301.34L164.17,352.14L148.5,322.65L159.34,334.02L178.54,341.71L183.63,331.81L194.02,341.82L188.71,326.68L194.34,335.1L194.79,325.73L199.1,334.29L201.72,329.2L210.78,335.66L212.22,329.63L213.66,340.95L229.75,342.22L243.37,349.61L261.83,347.89L262.19,352.96L278.13,357.03L293.45,383.79L294.41,349.25L305.91,340.38L277.9,327.87L264.74,311.91L236,292.66z"
        />
        <path
          id="PG-EHG"
          title="Eastern Highlands"
          className="land"
          d="M265.88,245.93L228.81,239.46L233.76,262.1L219.3,271.48L236.76,283L236,292.66L264.74,311.91L273.67,293.12L279.66,259.36L265.88,245.93z"
        />
        <path
          id="PG-CPK"
          title="Chimbu"
          className="land"
          d="M222.03,234.85L209.39,234.7L204.01,259.53L195.72,265.67L191.35,276.52L204.95,285.39L236,292.66L236.76,283L219.3,271.48L233.76,262.1L228.81,239.46L222.03,234.85z"
        />
        <path
          id="PG-SAN"
          title="Sandaun"
          className="land"
          d="M118.86,105.81L64.85,90.3L62.08,85.03L23.27,68.2L7.35,65.99L7.47,192.44L72.57,214.63L80.1,203.42L78.62,191.34L77.31,175.96L71.89,172.81L28.13,171.21L26.79,155.3L47.76,140.89L50.77,145.07L88.62,143.34L95.03,132.91L95.91,110.92L117.28,114.72L118.86,105.81z"
        />
        <path
          id="PG-MPM"
          title="Madang"
          className="land"
          d="M337.49,215.56L336.56,206.32L326.64,205.99L330.93,215.53L337.49,215.56zM271,180.05L275.77,175.02L271.55,168.08L266.32,175.52L271,180.05zM314.77,236.36L297.21,224.85L260.16,218.36L261.58,183.69L238.29,160.73L224.14,158.94L213.04,145.31L197.93,139.83L196.96,165.5L190.73,175.49L165.53,192.03L180.26,206.98L193.85,208.95L222.03,234.85L228.81,239.46L265.88,245.93L298.23,246.06L314.77,236.36z"
        />
        <path
          id="PG-ESW"
          title="East Sepik"
          className="land"
          d="M197.93,139.83L194.32,130.39L168.33,129.62L141.99,110.19L118.86,105.81L117.28,114.72L95.91,110.92L95.03,132.91L88.62,143.34L50.77,145.07L47.76,140.89L26.79,155.3L28.13,171.21L71.89,172.81L77.31,175.96L78.62,191.34L101.85,194.09L114.43,196.97L165.53,192.03L190.73,175.49L196.96,165.5L197.93,139.83z"
        />
        <path
          id="PG-EPW"
          title="Enga"
          className="land"
          d="M180.26,206.98L165.53,192.03L114.43,196.97L101.85,194.09L103.19,219.86L108.77,227.04L140.39,243.86L156.88,242.75L155.15,239.1L172.26,230L169.72,215.84L180.26,206.98z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(PapuaNewGuinea))
