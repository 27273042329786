import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 782

const cities = {
  Tripoli: { x: 185, y: 22 }
}

const Libya = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map libya">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="LY-WD"
          title="Wadi al Hayaa"
          className="land"
          d="M236.97,352.67L188.1,345.8L105.65,404.02L106.9,441.29L145.18,439.93L234.51,402.37L245.18,378.51L236.97,352.67z"
        />
        <path
          id="LY-BU"
          title="Al Butnan"
          className="land"
          d="M790.67,297.68L790.32,232.14L775.94,177.43L791.36,142.24L784.01,109.12L799.48,90.55L790.69,72.49L703.58,57.71L689.36,141.4L694.05,295.25L790.67,297.68z"
        />
        <path
          id="LY-DR"
          title="Darnah"
          className="land"
          d="M703.58,57.71L696.27,32.47L639.88,16.11L636.44,113.04L646.24,122.59L689.36,141.4L703.58,57.71z"
        />
        <path
          id="LY-SR"
          title="Surt"
          className="land"
          d="M478.97,166.48L407.27,124.54L345.42,114.27L365.25,209.36L359.58,222.33L405.66,229.62L418.06,223.15L438.3,261.26L464.33,274.5L478.97,166.48z"
        />
        <path
          id="LY-BA"
          title="Benghazi"
          className="land"
          d="M606.3,127.57L601.08,97.76L567.51,95.48L557.53,74.82L568.89,37.08L536.87,73.09L547.02,116.47L569.78,129.22L606.3,127.57z"
        />
        <path
          id="LY-WA"
          title="Al Wahat"
          className="land"
          d="M606.3,127.57L569.78,129.22L547.02,116.47L541.73,140.25L520.58,163.52L496.89,172.65L478.97,166.48L464.33,274.5L498.21,283.36L498.09,359.23L790.67,357.18L790.67,297.68L694.05,295.25L689.36,141.4L646.24,122.59L626.34,125.78L606.3,127.57z"
        />
        <path
          id="LY-JA"
          title="Al Jabal al Akhdar"
          className="land"
          d="M639.88,16.11L608.21,24.06L626.34,125.78L646.24,122.59L636.44,113.04L639.88,16.11z"
        />
        <path
          id="LY-MJ"
          title="Al Marj"
          className="land"
          d="M608.21,24.06L568.89,37.08L557.53,74.82L567.51,95.48L601.08,97.76L606.3,127.57L626.34,125.78L608.21,24.06z"
        />
        <path
          id="LY-TB"
          title="Tripoli"
          className="land"
          d="M235.52,25.39L192.67,18.24L191.32,27.6L199.38,50.74L235.52,25.39z"
        />
        <path
          id="LY-JG"
          title="Al Jabal al Gharbi"
          className="land"
          d="M207.49,67.57L200.1,51.71L178.39,55.12L130.21,52.3L135.27,124.88L116.48,156.44L123.5,240.9L134.7,269.17L171.79,278.61L233.31,213.15L258.95,233.86L275.7,222.53L280.82,196.08L285.65,164.23L248.47,145.99L207.49,67.57z"
        />
        <path
          id="LY-ZA"
          title="Az Zawiyah"
          className="land"
          d="M191.32,27.6L192.67,18.24L165.98,22.13L130.21,52.3L178.39,55.12L191.32,27.6z"
        />
        <path
          id="LY-NQ"
          title="An Nuqat al Khams"
          className="land"
          d="M112.21,0L113.27,45.72L130.21,52.3L165.98,22.13L112.21,0z"
        />
        <path
          id="LY-JI"
          title="Al Jifarah"
          className="land"
          d="M199.38,50.74L191.32,27.6L178.39,55.12L200.1,51.71L199.38,50.74z"
        />
        <path
          id="LY-SB"
          title="Sabha"
          className="land"
          d="M328.23,319.08L291.61,324.15L259.02,350.19L236.97,352.67L245.18,378.51L234.51,402.37L259.18,395.1L279.48,373.62L352.59,355.71L328.23,319.08z"
        />
        <path
          id="LY-MI"
          title="Misratah"
          className="land"
          d="M345.42,114.27L314.06,92.6L301.74,51.03L275.87,43.83L271.38,72.5L243.96,62.12L207.49,67.57L248.47,145.99L285.65,164.23L280.82,196.08L325.45,223.19L359.58,222.33L365.25,209.36L345.42,114.27z"
        />
        <path
          id="LY-MQ"
          title="Murzuq"
          className="land"
          d="M498,416L449.47,395.09L409.49,413.29L385.86,407.05L352.53,368.64L352.59,355.71L279.48,373.62L259.18,395.1L234.51,402.37L145.18,439.93L106.9,441.29L110.31,509.32L135.56,553.87L211.75,572.39L248.7,603.18L337.76,557.86L498.9,644.09L498,416z"
        />
        <path
          id="LY-GT"
          title="Ghat"
          className="land"
          d="M105.65,404.02L92.16,335.26L32.98,313.53L22.41,343.44L31.22,379.55L6.29,409.86L36.84,453.56L38.05,479.97L49.54,495.47L110.31,509.32L106.9,441.29L105.65,404.02z"
        />
        <path
          id="LY-WS"
          title="Wadi ash Shati'"
          className="land"
          d="M258.95,233.86L233.31,213.15L171.79,278.61L134.7,269.17L103.09,272.05L82.24,254.85L58.63,267.48L27.74,267.82L32.98,313.53L92.16,335.26L105.65,404.02L188.1,345.8L236.97,352.67L259.02,350.19L291.61,324.15L328.23,319.08L332.41,298.68L317.85,290.94L289.05,294.93L286.08,279.91L259.09,252.9L258.95,233.86z"
        />
        <path
          id="LY-MB"
          title="Al Marqab"
          className="land"
          d="M275.87,43.83L235.52,25.39L199.38,50.74L200.1,51.71L207.49,67.57L243.96,62.12L271.38,72.5L275.87,43.83z"
        />
        <path
          id="LY-KF"
          title="Al Kufrah"
          className="land"
          d="M740.33,771.53L740.33,744.82L790.45,744.79L790.67,357.18L498.09,359.23L498,416L498.9,644.09L740.33,771.53z"
        />
        <path
          id="LY-JU"
          title="Al Jufrah"
          className="land"
          d="M359.58,222.33L325.45,223.19L280.82,196.08L275.7,222.53L258.95,233.86L259.09,252.9L286.08,279.91L289.05,294.93L317.85,290.94L332.41,298.68L328.23,319.08L352.59,355.71L352.53,368.64L385.86,407.05L409.49,413.29L449.47,395.09L498,416L498.09,359.23L498.21,283.36L464.33,274.5L438.3,261.26L418.06,223.15L405.66,229.62L359.58,222.33z"
        />
        <path
          id="LY-NL"
          title="Nalut"
          className="land"
          d="M130.21,52.3L113.27,45.72L49.73,89.52L41.79,104.37L46.11,145.4L29.96,167.38L0.52,181.25L19.71,210.79L27.74,267.82L58.63,267.48L82.24,254.85L103.09,272.05L134.7,269.17L123.5,240.9L116.48,156.44L135.27,124.88L130.21,52.3z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Libya))
