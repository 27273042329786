import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 704

const cities = {
  Kigali: { x: 475, y: 346 }
}

const Rwanda = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map rwanda">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="RW-01"
          title="Kigali City"
          className="land"
          d="M547.16,306.01L502.97,280.87L442.44,332.09L456.9,396.2L532.18,397.19L547.16,306.01z"
        />
        <path
          id="RW-03"
          title="Northern"
          className="land"
          d="M475.85,126.3L417.37,161.06L377.49,100.94L268.23,132.36L231.39,169.76L304.43,205.44L315.09,262.63L442.44,332.09L502.97,280.87L547.16,306.01L551.31,249.48L475.85,126.3z"
        />
        <path
          id="RW-02"
          title="Eastern"
          className="land"
          d="M582.74,0L475.85,126.3L551.31,249.48L547.16,306.01L532.18,397.19L456.9,396.2L432.86,496.63L495.86,537.81L598.93,487.18L655.29,525.14L748.64,517.93L782.46,496.47L799.42,400.28L763.66,339.94L777.06,207.91L740.26,134.07L668.52,99.31L635.63,0.24L582.74,0z"
        />
        <path
          id="RW-04"
          title="Western"
          className="land"
          d="M315.09,262.63L304.43,205.44L231.39,169.76L191.12,177.34L114.94,288.72L109.24,445.69L0.58,532.55L31.12,637.72L62.2,651.78L68.98,612.35L100.82,602.08L178,639.72L197.88,609.17L163.02,548.52L189.43,485.64L294.66,439.58L315.09,262.63z"
        />
        <path
          id="RW-05"
          title="Southern"
          className="land"
          d="M189.9,693.46L380.42,668.64L432.86,496.63L456.9,396.2L442.44,332.09L315.09,262.63L294.66,439.58L189.43,485.64L163.02,548.52L197.88,609.17L178,639.72L189.9,693.46z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Rwanda))
