import React, { Component } from 'react'
import { connect } from 'react-redux'
import TopicNavigationPage from '../components/TopicNavigationPage'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import {
  genderSupportedLanguages,
  supportedGenders,
  soundLevelsMap,
  supportedSoundLevels,
  findSpokenFromList,
  lngGendersMap,
  common
} from '../lib/languageUtil'
import ImageChoiceButton from '../../src/components/ImageChoiceButton'
import { updateGenderAction, updateSoundLevelAction } from '../redux/actions/actions'

/* LATER
 - settings data saved in cookie or localStorage (per browser))
 - Setting toggle ideas:
 1. wrongs answered questions can be repeated in the list
*/

const navigationCommands = ['menu', 'language', 'about']

class SettingsPage extends Component {
  componentDidMount() {
    this.handleGenderClickChange = this.handleGenderClickChange.bind(this)
    this.handleSoundLevelChange = this.handleSoundLevelChange.bind(this)
  }

  componentDidUpdate(oldProps) {
    const { transcript, dispatch, lngCode } = this.props

    if (oldProps.transcript !== transcript) {
      const foundGender = findSpokenFromList(lngCode, transcript, supportedGenders)
      if (foundGender) {
        dispatch(updateGenderAction(lngGendersMap[lngCode][foundGender]))
      }

      const foundSoundLevel = findSpokenFromList(lngCode, transcript, supportedSoundLevels)
      if (foundSoundLevel) {
        dispatch(updateSoundLevelAction(soundLevelsMap[lngCode][foundSoundLevel]))
      }
    }
  }

  handleGenderClickChange(type) {
    const { dispatch } = this.props
    dispatch(updateGenderAction(type))
  }

  handleSoundLevelChange(level) {
    const { dispatch } = this.props
    dispatch(updateSoundLevelAction(level))
  }

  render() {
    const { isAnnyangSupported, language, gender, soundLevel, lngCode } = this.props
    const topics = ['topic_sound_level', 'topic_language', 'topic_about']
    const isGenderChangeSupported = genderSupportedLanguages.includes(language)

    if (isGenderChangeSupported) {
      topics.unshift('topic_gender')
    }

    return (
      <TopicNavigationPage
        navigationCommands={navigationCommands}
        pageName="settings_page"
        topics={topics}
        subTopics={navigationCommands}
        allowedContentKeyWords={lngCode == 'en' ? supportedGenders[lngCode] : []}
        borderRadius={0.36}
        SEODescription="E=MC² - Edit My Choices² - Via Automatic Speech Recognition Commands and Dictation, Speak to the app & Choose Voice Gender, Sound Levels, Change Language & more">
        {translate => (
          <>
            {isGenderChangeSupported && (
              <>
                <h5>{translate('voice_gender')}</h5>
                <div className="image-choice-images-wrapper">
                  {supportedGenders['en'].map((type, index) => (
                    <ImageChoiceButton
                      key={type}
                      value={type}
                      index={index}
                      altName="gender"
                      isSelected={type === gender}
                      areSmallImages={true}
                      imageText={`${common(`genders.${type}`)}${type === gender ? ' ✓' : ''}`}
                      onClick={() => (type !== gender ? this.handleGenderClickChange(type) : {})}
                      srcUrl={`images/gender-settings/${type}.webp`}
                    />
                  ))}
                </div>
              </>
            )}
            {isAnnyangSupported && (
              <>
                <h5>{translate('sound_level')}</h5>
                <div className="image-choice-images-wrapper">
                  {['on', 'partial', 'off'].map((level, index) => (
                    <ImageChoiceButton
                      key={`sound-level-${level}`}
                      value={level}
                      index={index}
                      altName="sound-level"
                      isSelected={level === soundLevel}
                      areSmallImages={true}
                      imageText={`${common(`sound-levels.${level}`)}${
                        level === soundLevel ? ' ✓' : ''
                      }`}
                      onClick={() =>
                        level !== soundLevel ? this.handleSoundLevelChange(level) : {}
                      }
                      srcUrl={`images/sound-settings/${level}.webp`}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </TopicNavigationPage>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(SettingsPage)
