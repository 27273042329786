import React from 'react'
import EasyMediumHardNavigationPage from '../../../src/components/EasyMediumHardNavigationPage'

const SentencesReadingPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'reading', 'easy', 'medium', 'hard']}
    pageName="sentences_reading_page"
    customNavigationPath="/reading/read-sentences"
  />
)

export default SentencesReadingPage
