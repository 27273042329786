import React from 'react'

const PageHeader = ({ title, intro, topics, titleOnly = false }) => (
  <>
    {title && <h3>{title}</h3>}
    {!titleOnly && intro}
    {!titleOnly && topics ? (
      <ul>
        {topics.map(topic => (
          <li key={topic}>{topic}</li>
        ))}
      </ul>
    ) : null}
  </>
)

export default PageHeader
