import React from 'react'
import Afghanistan from '../components/countriesMaps/Afghanistan'
import Albania from '../components/countriesMaps/Albania'
import Algeria from '../components/countriesMaps/Algeria'
import Andorra from '../components/countriesMaps/Andorra'
import Angola from '../components/countriesMaps/Angola'
import Argentina from '../components/countriesMaps/Argentina'
import Armenia from '../components/countriesMaps/Armenia'
import Australia from '../components/countriesMaps/Australia'
import Austria from '../components/countriesMaps/Austria'
import Azerbaijan from '../components/countriesMaps/Azerbaijan'
import Bahamas from '../components/countriesMaps/Bahamas'
import Bahrain from '../components/countriesMaps/Bahrain'
import Bangladesh from '../components/countriesMaps/Bangladesh'
import Barbados from '../components/countriesMaps/Barbados'
import Belarus from '../components/countriesMaps/Belarus'
import Belgium from '../components/countriesMaps/Belgium'
import Belize from '../components/countriesMaps/Belize'
import Benin from '../components/countriesMaps/Benin'
import Bhutan from '../components/countriesMaps/Bhutan'
import Bolivia from '../components/countriesMaps/Bolivia'
import BosniaHerzegovina from '../components/countriesMaps/BosniaHerzegovina'
import Botswana from '../components/countriesMaps/Botswana'
import Brazil from '../components/countriesMaps/Brazil'
import BruneiDarussalam from '../components/countriesMaps/BruneiDarussalam'
import Bulgaria from '../components/countriesMaps/Bulgaria'
import BurkinaFaso from '../components/countriesMaps/BurkinaFaso'
import Burundi from '../components/countriesMaps/Burundi'
import Cambodia from '../components/countriesMaps/Cambodia'
import Cameroon from '../components/countriesMaps/Cameroon'
import Canada from '../components/countriesMaps/Canada'
import CapeVerde from '../components/countriesMaps/CapeVerde'
import CentralAfricanRepublic from '../components/countriesMaps/CentralAfricanRepublic'
import Chad from '../components/countriesMaps/Chad'
import Chile from '../components/countriesMaps/Chile'
import China from '../components/countriesMaps/China'
import Colombia from '../components/countriesMaps/Colombia'
import Comoros from '../components/countriesMaps/Comoros'
import Congo from '../components/countriesMaps/Congo'
import CongoDR from '../components/countriesMaps/CongoDR'
import CostaRica from '../components/countriesMaps/CostaRica'
import Croatia from '../components/countriesMaps/Croatia'
import Cuba from '../components/countriesMaps/Cuba'
import Cyprus from '../components/countriesMaps/Cyprus'
import CzechRepublic from '../components/countriesMaps/CzechRepublic'
import Denmark from '../components/countriesMaps/Denmark'
import Djibouti from '../components/countriesMaps/Djibouti'
import Dominica from '../components/countriesMaps/Dominica'
import DominicanRepublic from '../components/countriesMaps/DominicanRepublic'
import TimorLeste from '../components/countriesMaps/TimorLeste'
import Ecuador from '../components/countriesMaps/Ecuador'
import Egypt from '../components/countriesMaps/Egypt'
import ElSalvador from '../components/countriesMaps/ElSalvador'
import EquatorialGuinea from '../components/countriesMaps/EquatorialGuinea'
import Eritrea from '../components/countriesMaps/Eritrea'
import Estonia from '../components/countriesMaps/Estonia'
import Ethiopia from '../components/countriesMaps/Ethiopia'
import Fiji from '../components/countriesMaps/Fiji'
import Finland from '../components/countriesMaps/Finland'
import France from '../components/countriesMaps/France'
import Gabon from '../components/countriesMaps/Gabon'
import Gambia from '../components/countriesMaps/Gambia'
import Georgia from '../components/countriesMaps/Georgia'
import Germany from '../components/countriesMaps/Germany'
import Ghana from '../components/countriesMaps/Ghana'
import Greece from '../components/countriesMaps/Greece'
import Grenada from '../components/countriesMaps/Grenada'
import Guatemala from '../components/countriesMaps/Guatemala'
import Guinea from '../components/countriesMaps/Guinea'
import GuineaBissau from '../components/countriesMaps/GuineaBissau'
import Guyana from '../components/countriesMaps/Guyana'
import Haiti from '../components/countriesMaps/Haiti'
import Honduras from '../components/countriesMaps/Honduras'
import Hungary from '../components/countriesMaps/Hungary'
import Iceland from '../components/countriesMaps/Iceland'
import India from '../components/countriesMaps/India'
import Indonesia from '../components/countriesMaps/Indonesia'
import Iran from '../components/countriesMaps/Iran'
import Iraq from '../components/countriesMaps/Iraq'
import Ireland from '../components/countriesMaps/Ireland'
import Israel from '../components/countriesMaps/Israel'
import Italy from '../components/countriesMaps/Italy'
import IvoryCoast from '../components/countriesMaps/IvoryCoast'
import Jamaica from '../components/countriesMaps/Jamaica'
import Japan from '../components/countriesMaps/Japan'
import Jordan from '../components/countriesMaps/Jordan'
import Kazakhstan from '../components/countriesMaps/Kazakhstan'
import Kenya from '../components/countriesMaps/Kenya'
import Kosovo from '../components/countriesMaps/Kosovo'
import Kuwait from '../components/countriesMaps/Kuwait'
import Kyrgyzstan from '../components/countriesMaps/Kyrgyzstan'
import Laos from '../components/countriesMaps/Laos'
import Latvia from '../components/countriesMaps/Latvia'
import Lebanon from '../components/countriesMaps/Lebanon'
import Lesotho from '../components/countriesMaps/Lesotho'
import Liberia from '../components/countriesMaps/Liberia'
import Libya from '../components/countriesMaps/Libya'
import Liechtenstein from '../components/countriesMaps/Liechtenstein'
import Lithuania from '../components/countriesMaps/Lithuania'
import Luxembourg from '../components/countriesMaps/Luxembourg'
import Macedonia from '../components/countriesMaps/Macedonia'
import Madagascar from '../components/countriesMaps/Madagascar'
import Malawi from '../components/countriesMaps/Malawi'
import Malaysia from '../components/countriesMaps/Malaysia'
import Mali from '../components/countriesMaps/Mali'
import Malta from '../components/countriesMaps/Malta'
import Mauritania from '../components/countriesMaps/Mauritania'
import Mauritius from '../components/countriesMaps/Mauritius'
import Mexico from '../components/countriesMaps/Mexico'
import Micronesia from '../components/countriesMaps/Micronesia'
import Moldova from '../components/countriesMaps/Moldova'
import Mongolia from '../components/countriesMaps/Mongolia'
import Montenegro from '../components/countriesMaps/Montenegro'
import Morocco from '../components/countriesMaps/Morocco'
import Mozambique from '../components/countriesMaps/Mozambique'
import Myanmar from '../components/countriesMaps/Myanmar'
import Namibia from '../components/countriesMaps/Namibia'
import Nepal from '../components/countriesMaps/Nepal'
import Netherlands from '../components/countriesMaps/Netherlands'
import NewZealand from '../components/countriesMaps/NewZealand'
import Nicaragua from '../components/countriesMaps/Nicaragua'
import Niger from '../components/countriesMaps/Niger'
import Nigeria from '../components/countriesMaps/Nigeria'
import NorthKorea from '../components/countriesMaps/NorthKorea'
import Norway from '../components/countriesMaps/Norway'
import Oman from '../components/countriesMaps/Oman'
import Pakistan from '../components/countriesMaps/Pakistan'
import Panama from '../components/countriesMaps/Panama'
import PapuaNewGuinea from '../components/countriesMaps/PapuaNewGuinea'
import Paraguay from '../components/countriesMaps/Paraguay'
import Peru from '../components/countriesMaps/Peru'
import Philippines from '../components/countriesMaps/Philippines'
import Poland from '../components/countriesMaps/Poland'
import Portugal from '../components/countriesMaps/Portugal'
import Qatar from '../components/countriesMaps/Qatar'
import Romania from '../components/countriesMaps/Romania'
import Russia from '../components/countriesMaps/Russia'
import Rwanda from '../components/countriesMaps/Rwanda'
import Samoa from '../components/countriesMaps/Samoa'
import SanMarino from '../components/countriesMaps/SanMarino'
import SaoTomePrincipe from '../components/countriesMaps/SaoTomePrincipe'
import SaudiArabia from '../components/countriesMaps/SaudiArabia'
import Senegal from '../components/countriesMaps/Senegal'
import Serbia from '../components/countriesMaps/Serbia'
import Seychelles from '../components/countriesMaps/Seychelles'
import SierraLeone from '../components/countriesMaps/SierraLeone'
import Singapore from '../components/countriesMaps/Singapore'
import Slovakia from '../components/countriesMaps/Slovakia'
import Slovenia from '../components/countriesMaps/Slovenia'
import SolomonIslands from '../components/countriesMaps/SolomonIslands'
import Somalia from '../components/countriesMaps/Somalia'
import SouthAfrica from '../components/countriesMaps/SouthAfrica'
import SouthKorea from '../components/countriesMaps/SouthKorea'
import SouthSudan from '../components/countriesMaps/SouthSudan'
import Spain from '../components/countriesMaps/Spain'
import SriLanka from '../components/countriesMaps/SriLanka'
import SaintKittsNevis from '../components/countriesMaps/SaintKittsNevis'
import SaintLucia from '../components/countriesMaps/SaintLucia'
import SaintVincent from '../components/countriesMaps/SaintVincent'
import Sudan from '../components/countriesMaps/Sudan'
import Suriname from '../components/countriesMaps/Suriname'
import Sweden from '../components/countriesMaps/Sweden'
import Switzerland from '../components/countriesMaps/Switzerland'
import Syria from '../components/countriesMaps/Syria'
import Taiwan from '../components/countriesMaps/Taiwan'
import Tajikistan from '../components/countriesMaps/Tajikistan'
import Tanzania from '../components/countriesMaps/Tanzania'
import Thailand from '../components/countriesMaps/Thailand'
import Togo from '../components/countriesMaps/Togo'
import Tonga from '../components/countriesMaps/Tonga'
import TrinidadAndTobago from '../components/countriesMaps/TrinidadAndTobago'
import Tunisia from '../components/countriesMaps/Tunisia'
import Turkey from '../components/countriesMaps/Turkey'
import Turkmenistan from '../components/countriesMaps/Turkmenistan'
import Uganda from '../components/countriesMaps/Uganda'
import Ukraine from '../components/countriesMaps/Ukraine'
import UnitedArabEmirates from '../components/countriesMaps/UnitedArabEmirates'
import UnitedKingdom from '../components/countriesMaps/UnitedKingdom'
import Uruguay from '../components/countriesMaps/Uruguay'
import UnitedStates from '../components/countriesMaps/UnitedStates'
import Uzbekistan from '../components/countriesMaps/Uzbekistan'
import Vanuatu from '../components/countriesMaps/Vanuatu'
import Venezuela from '../components/countriesMaps/Venezuela'
import Vietnam from '../components/countriesMaps/Vietnam'
import Yemen from '../components/countriesMaps/Yemen'
import Zambia from '../components/countriesMaps/Zambia'
import Zimbabwe from '../components/countriesMaps/Zimbabwe'

const getCountryMapForCityArgument = (city, mapFillColorOne, mapFillColorTwo) => {
  const maps = {
    Kabul: <Afghanistan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tirana: <Albania cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Algiers: <Algeria cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Andorra la Vella': (
      <Andorra cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Luanda: <Angola cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Buenos Aires': (
      <Argentina cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Yerevan: <Armenia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Canberra: <Australia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Vienna: <Austria cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Baku: <Azerbaijan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Nassau: <Bahamas cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Manama: <Bahrain cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Dhaka: <Bangladesh cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bridgetown: <Barbados cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Minsk: <Belarus cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Brussels: <Belgium cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Belmopan: <Belize cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Porto-Novo': <Benin cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Thimphu: <Bhutan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'La Paz': <Bolivia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Sarajevo: (
      <BosniaHerzegovina cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Gaborone: <Botswana cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Brasilia: <Brazil cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Bandar Seri Begawan': (
      <BruneiDarussalam cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Sofia: <Bulgaria cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Ouagadougou: (
      <BurkinaFaso cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Gitega: <Burundi cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Phnom Penh': <Cambodia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Yaounde: <Cameroon cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Ottawa: <Canada cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Praia: <CapeVerde cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bangui: (
      <CentralAfricanRepublic cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    "N'Djamena": <Chad cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Santiago: <Chile cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Beijing: <China cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bogota: <Colombia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Moroni: <Comoros cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Brazzaville: <Congo cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Kinshasa: <CongoDR cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'San Jose': <CostaRica cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Zagreb: <Croatia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Havana: <Cuba cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Nicosia: <Cyprus cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Prague: <CzechRepublic cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Copenhagen: <Denmark cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Djibouti: <Djibouti cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Roseau: <Dominica cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Santo Domingo': (
      <DominicanRepublic cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Dili: <TimorLeste cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Quito: <Ecuador cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Cairo: <Egypt cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'San Salvador': (
      <ElSalvador cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Malabo: (
      <EquatorialGuinea cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Asmara: <Eritrea cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tallinn: <Estonia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Addis Ababa': <Ethiopia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Suva: <Fiji cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Helsinki: <Finland cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Paris: <France cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Libreville: <Gabon cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Banjul: <Gambia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tbilisi: <Georgia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Berlin: <Germany cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Accra: <Ghana cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Athens: <Greece cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    "Saint George's": (
      <Grenada cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    'Guatemala City': (
      <Guatemala cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Conakry: <Guinea cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bissau: <GuineaBissau cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Georgetown: <Guyana cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Port-au-Prince': <Haiti cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tegucigalpa: <Honduras cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Budapest: <Hungary cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Reykjavik: <Iceland cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'New Delhi': <India cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Jakarta: <Indonesia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tehran: <Iran cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Baghdad: <Iraq cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Dublin: <Ireland cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Jerusalem: <Israel cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Rome: <Italy cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Yamoussoukro: (
      <IvoryCoast cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Kingston: <Jamaica cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tokyo: <Japan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Amman: <Jordan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Nur-Sultan': (
      <Kazakhstan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Nairobi: <Kenya cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Pristina: <Kosovo cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Kuwait City': <Kuwait cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bishkek: <Kyrgyzstan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Vientiane: <Laos cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Riga: <Latvia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Beirut: <Lebanon cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Maseru: <Lesotho cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Monrovia: <Liberia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Tripoli: <Libya cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Vaduz: <Liechtenstein cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Vilnius: <Lithuania cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Luxembourg: <Luxembourg cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Skopje: <Macedonia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Antananarivo: (
      <Madagascar cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Lilongwe: <Malawi cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Kuala Lumpur': (
      <Malaysia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Bamako: <Mali cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Valletta: <Malta cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Nouakchott: <Mauritania cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Port Louis': <Mauritius cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Mexico City': <Mexico cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Palikir: <Micronesia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Chisinau: <Moldova cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Ulaanbaatar: <Mongolia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Podgorica: <Montenegro cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Rabat: <Morocco cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Maputo: <Mozambique cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Naypyidaw: <Myanmar cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Windhoek: <Namibia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Kathmandu: <Nepal cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Amsterdam: <Netherlands cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Wellington: <NewZealand cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Managua: <Nicaragua cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Niamey: <Niger cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Abuja: <Nigeria cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Pyongyang: <NorthKorea cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Oslo: <Norway cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Muscat: <Oman cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Islamabad: <Pakistan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Panama City': <Panama cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Port Moresby': (
      <PapuaNewGuinea cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Asuncion: <Paraguay cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Lima: <Peru cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Manila: <Philippines cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Warsaw: <Poland cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Lisbon: <Portugal cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Doha: <Qatar cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bucharest: <Romania cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Moscow: <Russia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Kigali: <Rwanda cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Apia: <Samoa cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'San Marino': <SanMarino cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Sao Tome': (
      <SaoTomePrincipe cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Riyadh: <SaudiArabia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Dakar: <Senegal cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Belgrade: <Serbia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Victoria: <Seychelles cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Freetown: <SierraLeone cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Singapore: <Singapore cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bratislava: <Slovakia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Ljubljana: <Slovenia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Honiara: <SolomonIslands cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Mogadishu: <Somalia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Pretoria: <SouthAfrica cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Seoul: <SouthKorea cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Juba: <SouthSudan Sudan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Madrid: <Spain cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Sri Jayawardenepura Kotte': (
      <SriLanka cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Basseterre: (
      <SaintKittsNevis cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Castries: <SaintLucia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Kingstown: <SaintVincent cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Khartoum: <Sudan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Paramaribo: <Suriname cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Stockholm: <Sweden cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bern: <Switzerland cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Damascus: <Syria cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Taipei: <Taiwan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Dushanbe: <Tajikistan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Dodoma: <Tanzania cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Bangkok: <Thailand cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Lome: <Togo cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    "Nuku'alofa": <Tonga cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Port-of-Spain': (
      <TrinidadAndTobago cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Tunis: <Tunisia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Ankara: <Turkey cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Ashgabat: <Turkmenistan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Kampala: <Uganda cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Kyiv: <Ukraine cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Abu Dhabi': (
      <UnitedArabEmirates cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    London: <UnitedKingdom cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Edinburgh: (
      <UnitedKingdom cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Cardiff: <UnitedKingdom cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Belfast: <UnitedKingdom cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Montevideo: <Uruguay cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Washington: (
      <UnitedStates cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
    ),
    Tashkent: <Uzbekistan cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    'Port-Vila': <Vanuatu cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Caracas: <Venezuela cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Hanoi: <Vietnam cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Sanaa: <Yemen cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Lusaka: <Zambia cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />,
    Harare: <Zimbabwe cityName={city} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
  }
  return maps[city]
}

// Top 30 countries as ranked by Gross domestic product (GDP) / Financial power - the ones that children are most likely to hear about in the news (2022 ranking).
const top30Countries = [
  'UnitedStates',
  'China',
  'Russia',
  'Germany',
  'England', // "United Kingdom",
  'Japan',
  'France',
  'SouthKorea',
  'SaudiArabia',
  'UnitedArabEmirates',
  'Israel',
  'Canada',
  'India',
  'Turkey',
  'Italy',
  'Australia',
  'CostaRica',
  'Switzerland',
  'Spain',
  'Brazil',
  'SriLanka',
  'Singapore',
  'Iraq',
  'Qatar',
  'Netherlands',
  'Belgium',
  'Vietnam',
  'Sweden',
  'Thailand',
  'SouthAfrica'
]

// Only used for capitals
const camelCasesCountriesMap = {
  BosniaHerzegovina: 'Bosnia Herzegovina',
  BruneiDarussalam: 'Brunei Darussalam',
  BurkinaFaso: 'Burkina Faso',
  CapeVerde: 'Cape Verde',
  CentralAfricanRepublic: 'Central African Republic',
  Congo: 'Congo',
  CongoDR: 'Congo DR',
  CostaRica: 'Costa Rica',
  CzechRepublic: 'Czech Republic',
  DominicanRepublic: 'Dominican Republic',
  TimorLeste: 'Timor Leste',
  ElSalvador: 'El Salvador',
  EquatorialGuinea: 'Equatorial Guinea',
  GuineaBissau: 'Guinea Bissau',
  IvoryCoast: 'Ivory Coast',
  NewZealand: 'New Zealand',
  NorthKorea: 'North Korea',
  PapuaNewGuinea: 'Papua New Guinea',
  SanMarino: 'San Marino',
  SaoTomePrincipe: 'Sao Tome Principe',
  SaudiArabia: 'Saudi Arabia',
  SierraLeone: 'Sierra Leone',
  SolomonIslands: 'Solomon Islands',
  SouthAfrica: 'South Africa',
  SouthKorea: 'South Korea',
  SouthSudan: 'South Sudan',
  SriLanka: 'Sri Lanka',
  SaintKittsNevis: 'Saint Kitts and Nevis',
  SaintLucia: 'Saint Lucia',
  SaintVincent: 'Saint Vincent and the Grenadines',
  TrinidadAndTobago: 'Trinidad and Tobago',
  UnitedArabEmirates: 'United Arab Emirates',
  UnitedStates: 'United States'
}

const englishCountryNamesAlsoAcceptedAsAnswers = {
  'Antigua and Barbuda': ['Antigua', 'Barbuda'],
  Bosnia: ['Herzegovina'],
  Brunei: ['Darussalam'],
  'Czech Republic': ['Czechoslovakia', 'Czechia'],
  'Democratic Republic of the Congo': ['Congo-Kinshasa', 'Congo Kinshasa'],
  'Guinea-Bissau': ['Guinea Bissau'],
  Guyana: ['Guiana', 'Guyane'],
  'Ivory Coast': ["Côte d'Ivoire", 'Ivoire'],
  Myanmar: ['Burma'],
  Netherlands: ['Holland'],
  'North Korea': [
    "Democratic People's Republic of Korea",
    'Democratic Republic of Korea',
    'Democratic Republic Korea',
    'Democratic Korea',
    "People's Republic of Korea",
    'People Republic of Korea',
    "People's Republic Korea",
    'People Republic Korea'
  ],
  'Republic of the Congo': [
    'Republic of Congo',
    'Congo Republic',
    'Congo-Brazzaville',
    'Congo Brazzaville'
  ],
  'Saint Kitts and Nevis': ['Saint Kitts', 'Nevis'],
  'Saint Vincent and the Grenadines': ['Saint Vincent', 'Saint-Vincent', 'Grenadines'],
  'South Korea': ['Republic of Korea', 'South Republic of Korea', 'Republic Korea'],
  'São Tomé and Principe': ['Sao Tome', 'Principe', 'São Tomé'],
  'Solomon Islands': ['Solomon'],
  Swaziland: ['Eswatini'],
  'Timor-Leste': ['Timor', 'Leste', 'Timor Leste'],
  'Trinidad and Tobago': ['Trinidad', 'Tobago'],
  'United Arab Emirates': ['U.A.E', 'UAE', 'U A E'],
  'United Kingdom': ['U.K', 'UK', 'U K'],
  'United States': ['U.S.A.', 'USA', 'U S A'],
  Vatican: ['Holy See']
}

// If defined, these replace fully the French names. Some have multiple / some have a name that work for both:
// example: Timor can be known as East-Timor & Timor-Leste. ['Timor'] covers both cases
const frenchCountryNamesAlsoAcceptedAsAnswers = {
  'Antigua and Barbuda': ['Antigua', 'Barbuda'],
  Bosnia: ['Bosnie', 'Herzégovine'],
  'Czech Republic': ['République Tchèque', 'Tchécoslovaquie'],
  'Guinea-Bissau': ['Guinée', 'Bissao'],
  'Marshall Islands': ['Marshall'],
  Netherlands: ['Pays-Bas', 'Pays Bas', 'Hollande'],
  Nauru: ['nos rue', 'nos rues', 'noru', 'norru'],
  'New Zealand': ['Nouvelle-Zélande', 'Nouvelle Zélande'],
  'Papua New Guinea': ['Papouasie', 'Nouvelle-Guinée', 'Nouvelle Guinée'],
  'Saint Kitts and Nevis': ['Saint-Christophe', 'Saint Christophe', 'Niévès'],
  'Saint Lucia': ['Sainte-Lucie', 'Sainte Lucie'],
  'Saint Vincent and the Grenadines': ['Saint-Vincent', 'Saint Vincent', 'Grenadines'],
  'San Marino': ['Saint-Marin', 'Saint Marin'],
  'São Tomé and Principe': ['Sao Tomé', 'Principe'],
  Swaziland: ['Swaziland', 'Eswatini', 'et sois tini'], // because Eswatini (new country name) is not understood
  'Timor-Leste': ['Timor'],
  'Trinidad and Tobago': ['Trinité-et-Tobago', 'Trinité', 'Tobago'],
  'United Arab Emirates': ['Émirats Arabes Unis'],
  'United Kingdom': ['Royaume Uni', 'Grande Bretagne', 'Grande-Bretagne'],
  'United States': ['États Unis', 'USA'],
  Wales: ['Pays-De-Galles', 'Pays De Galles']
}

const camelCasesCountriesList = Object.keys(camelCasesCountriesMap)

const removeCamelCase = country =>
  camelCasesCountriesList.includes(country) ? camelCasesCountriesMap[country] : country

export {
  getCountryMapForCityArgument,
  top30Countries,
  removeCamelCase,
  englishCountryNamesAlsoAcceptedAsAnswers,
  frenchCountryNamesAlsoAcceptedAsAnswers
}
