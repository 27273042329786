const actions = {
  updateIsAnnyangSupported: 'updateIsAnnyangSupported',
  updateIsListening: 'updateIsListening',
  changePath: 'changePath',
  saveTranscript: 'saveTranscript',
  isCorrectAnswer: 'isCorrectAnswer',
  isPlaying: 'isPlaying ',
  resetForNextQuestion: 'resetForNextQuestion',
  updateLanguage: 'updateLanguage',
  updateGender: 'updateGender',
  updateSoundLevel: 'updateSoundLevel',
  updateTemporarilyUnlocked: 'updateTemporarilyUnlocked',
  getAWinningImage: 'getAWinningImage',
  prepareLearning: 'prepareLearning',
  startLearning: 'startLearning',
  updateLearningItem: 'updateLearningItem',
  resetLearning: 'resetLearning',
  resetWords: 'resetWords',
  randomizeAWord: 'randomizeAWord',
  resetMathematics: 'resetMathematics',
  randomizeNumbers: 'randomizeNumbers',
  resetGeography: 'resetGeography',
  randomizeACountry: 'randomizeACountry',
  resetReading: 'resetReading',
  randomizeReadingItems: 'randomizeReadingItems'
}

export default actions
