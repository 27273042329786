import React from 'react'
import LearnPage from '../../../../src/components/LearnPage'

const LearnColorsPage = () => (
  <LearnPage
    parentPage="colors_words"
    grandParentPage="words"
    pageName="learn_colors"
    parentPagePath="/words/colors"
  />
)

export default LearnColorsPage
