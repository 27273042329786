import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 851

const cities = {
  Yamoussoukro: { x: 429, y: 510 }
}

const IvoryCoast = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map ivory-coast">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="CI-SC"
          title="Sud-Comoé"
          className="land"
          d="M710.81,738.67L698.86,740.53L719.35,744.05L710.81,738.67zM732.38,648.57L712.54,591.89L678.24,653.57L682.4,724.9L664.56,735.39L694.18,740.29L713.71,707.62L716.8,737.36L765.5,734.9L763.02,677.91L738.98,674.44L732.38,648.57z"
        />
        <path
          id="CI-LC"
          title="Lacs"
          className="land"
          d="M507.36,425.49L485.83,422.52L435.81,462L386.98,459.27L408.96,475.3L403.29,506.76L419.49,540.51L445.39,576.47L477.03,593.64L512.55,587.62L507.5,545.64L484.48,546.43L477.25,526.28L499.21,508.61L517.85,425.1L507.36,425.49z"
        />
        <path
          id="CI-MC"
          title="Moyen-Comoe"
          className="land"
          d="M672.39,447.93L665.04,448.6L650.61,469.73L660.48,483.66L633.65,495.89L633.69,542.22L646.07,541L655.07,577.91L683.27,603.3L673.85,646.05L678.24,653.57L712.54,591.89L700.85,541.32L722.36,485.95L672.39,447.93z"
        />
        <path
          id="CI-MV"
          title="Moyen-Cavally"
          className="land"
          d="M107.15,513.95L82.71,497.29L44.52,545.35L23.62,538.51L0.48,559.89L26.68,565.37L27.52,579.99L92.55,588.97L109.25,630.2L153.71,644.95L157.45,673.61L199.79,665.36L196.99,564.27L212.42,551.33L203.88,502.41L107.15,513.95z"
        />
        <path
          id="CI-LG"
          title="Lagunes"
          className="land"
          d="M431.12,730.99L431.12,730.99L416.58,721.93L417.54,743.06L473.22,739.15L419.43,733.68L431.12,730.99zM477.03,593.64L445.39,576.47L441.59,596.02L472.52,610.13L481.68,651.55L462.02,725.65L442.94,726.76L487.57,739.15L604.82,725.63L498.56,732.35L516.23,714.87L514.43,725.63L616.51,710.3L637.73,720.07L629.76,706.68L639.71,721.05L604.01,721.05L664.56,735.39L682.4,724.9L678.24,653.57L673.85,646.05L639.19,637.1L613.25,674.42L559.84,676.92L518.1,643.35L543.74,595.25L512.55,587.62L477.03,593.64z"
        />
        <path
          id="CI-NC"
          title="N'zi-Comoé"
          className="land"
          d="M602.97,365.65L602.94,357.53L535.35,387.7L536.69,414.18L517.85,425.1L499.21,508.61L477.25,526.28L484.48,546.43L507.5,545.64L512.55,587.62L543.74,595.25L585.1,593.9L591.5,561.98L633.69,542.22L633.65,495.89L660.48,483.66L650.61,469.73L665.04,448.6L659.34,415.75L602.97,365.65z"
        />
        <path
          id="CI-AG"
          title="Agnéby"
          className="land"
          d="M655.07,577.91L646.07,541L633.69,542.22L591.5,561.98L585.1,593.9L543.74,595.25L518.1,643.35L559.84,676.92L613.25,674.42L639.19,637.1L673.85,646.05L683.27,603.3L655.07,577.91z"
        />
        <path
          id="CI-VB"
          title="Vallée du Bandama"
          className="land"
          d="M449.02,190.12L429.34,178.13L408.73,217.18L413.5,245.21L382.43,251.06L393.74,291.36L428.37,336.72L376.81,361.21L369.51,395.07L377.68,427.93L386.98,459.27L435.81,462L485.83,422.52L507.36,425.49L517.85,425.1L536.69,414.18L535.35,387.7L602.94,357.53L613.19,280.17L575.89,276.5L534.75,241.66L481.9,241.14L488.61,214.31L471.03,190.25L449.02,190.12z"
        />
        <path
          id="CI-ZA"
          title="Zanzan"
          className="land"
          d="M655.73,107.4L584.62,120.66L562.11,148.42L581.11,197.67L618.17,207.18L630,228.11L617.64,236.2L631.56,258.68L613.19,280.17L602.94,357.53L602.97,365.65L659.34,415.75L665.04,448.6L672.39,447.93L722.36,485.95L762.36,368.76L799.52,333.64L784.74,239.2L764.42,221.81L777.39,166.61L759.77,174.65L706.91,107.74L655.73,107.4z"
        />
        <path
          id="CI-BS"
          title="Bas-Sassandra"
          className="land"
          d="M141.4,840.92L361.28,752.4L376.28,719.82L365.19,682.58L346.79,688.45L336.06,666.99L307.42,642L290.53,584.63L234.51,592.27L212.42,551.33L196.99,564.27L199.79,665.36L157.45,673.61L161.13,714.41L132.93,769.49L141.4,840.92z"
        />
        <path
          id="CI-SB"
          title="Sud-Bandama"
          className="land"
          d="M441.59,596.02L378.26,598.91L336.06,666.99L346.79,688.45L365.19,682.58L376.28,719.82L361.28,752.4L417.54,743.06L416.58,721.93L431.12,730.99L442.94,726.76L462.02,725.65L481.68,651.55L472.52,610.13L441.59,596.02z"
        />
        <path
          id="CI-HT"
          title="Haut-Sassandra"
          className="land"
          d="M282.83,395.45L202.72,398.29L214.36,418.1L203.88,502.41L212.42,551.33L234.51,592.27L290.53,584.63L329.93,575.57L337.48,556.35L314.52,495.31L351.72,471.34L284.54,459.55L328.99,411.62L310.28,390.74L282.83,395.45z"
        />
        <path
          id="CI-FR"
          title="Fromager"
          className="land"
          d="M419.49,540.51L376.81,560.44L337.48,556.35L329.93,575.57L290.53,584.63L307.42,642L336.06,666.99L378.26,598.91L441.59,596.02L445.39,576.47L419.49,540.51z"
        />
        <path
          id="CI-MR"
          title="Marahoué"
          className="land"
          d="M377.68,427.93L369.51,395.07L328.99,411.62L284.54,459.55L351.72,471.34L314.52,495.31L337.48,556.35L376.81,560.44L419.49,540.51L403.29,506.76L408.96,475.3L386.98,459.27L377.68,427.93z"
        />
        <path
          id="CI-WR"
          title="Worodougou"
          className="land"
          d="M304.97,201.85L282.64,219.1L245.21,213.49L224.19,236.67L181.66,236.63L187.71,292.1L201.13,362.69L202.72,398.29L282.83,395.45L310.28,390.74L328.99,411.62L369.51,395.07L376.81,361.21L428.37,336.72L393.74,291.36L348.52,276.64L304.97,201.85z"
        />
        <path
          id="CI-DH"
          title="Dix-Huit Montagnes"
          className="land"
          d="M184.64,362.77L153.3,339.25L145.33,374L124.69,382.02L85.85,357.72L72.33,357.41L51.42,421.4L17.9,419.59L44.22,490.77L23.62,538.51L44.52,545.35L82.71,497.29L107.15,513.95L203.88,502.41L214.36,418.1L202.72,398.29L201.13,362.69L184.64,362.77z"
        />
        <path
          id="CI-BF"
          title="Bafing"
          className="land"
          d="M187.71,292.1L114.27,219.11L90.27,229.55L85.42,253.86L120.99,278.72L125.48,311.76L85.21,295.63L48.1,302.29L46.59,328.79L80.93,336.97L85.85,357.72L124.69,382.02L145.33,374L153.3,339.25L184.64,362.77L201.13,362.69L187.71,292.1z"
        />
        <path
          id="CI-DE"
          title="Denguélé"
          className="land"
          d="M155.69,52.22L148.05,35.96L125.23,38.48L58.63,104.26L62.36,163.24L98.27,173.65L90.57,204.75L114.27,219.11L187.71,292.1L181.66,236.63L224.19,236.67L245.21,213.49L246.96,116.39L218.95,101.82L228.66,80.33L266.53,74.35L255.45,47.96L217.15,50.74L210.02,77.84L155.69,52.22z"
        />
        <path
          id="CI-SV"
          title="Savanes"
          className="land"
          d="M309.35,0L286.99,5.37L284.51,23.17L255.33,9.62L255.45,47.96L266.53,74.35L228.66,80.33L218.95,101.82L246.96,116.39L245.21,213.49L282.64,219.1L304.97,201.85L348.52,276.64L393.74,291.36L382.43,251.06L413.5,245.21L408.73,217.18L429.34,178.13L449.02,190.12L471.03,190.25L488.61,214.31L481.9,241.14L534.75,241.66L575.89,276.5L613.19,280.17L631.56,258.68L617.64,236.2L630,228.11L618.17,207.18L581.11,197.67L562.11,148.42L481.82,113.27L455.8,56.14L420.91,56.63L395.88,36.25L329.04,71.41L311.44,63.09L309.34,28.58L309.35,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(IvoryCoast))
