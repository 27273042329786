import {
  createSameKeyValueObject,
  createInvertedAnswerQuestionMap
} from './../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from './../../../lib/languageUtil'
import { randomizeReadingItemsAction, resetReadingAction } from '../../../redux/actions/actions'

const easySentencesReadingList = ['I am happy', 'He is here', 'She plays', "It's summer"]

const mediumSentencesReadingList = [
  'The cat is in the kitchen',
  'The snail is slow',
  'The house is expensive',
  'The leaves are falling',
  'Winter is coming'
]

const hardSentencesReadingList = [
  'The dog runs after the ball in the park',
  'The fat pig is taking a bath in the mud',
  'Every morning, the rooster wakes the all neighbourhood up',
  'The rocket is flying all around the earth'
]

const allSentencesList = [
  ...easySentencesReadingList,
  ...mediumSentencesReadingList,
  ...hardSentencesReadingList
]

const questionAnswerMap = {
  en: createSameKeyValueObject(allSentencesList),
  fr: {
    // easy
    'I am happy': 'Je suis content',
    'He is here': 'Il est là',
    'She plays': 'Ell joue',
    "It's summer": "C'est l'été",
    // medium
    'The cat is in the kitchen': 'Le chat est dans la cuisine',
    'The snail is slow': "L'escargot est lent",
    'The house is expensive': 'La maison est chère',
    'The leaves are falling': 'Les feuilles tombent',
    'Winter is coming': "L'hiver arrive",
    // hard
    'The dog runs after the ball in the park': 'Le chien court après la balle dans le parc',
    'The fat pig is taking a bath in the mud': 'Le gros cochon prend un bain de boue',
    'Every morning, the rooster wakes the all neighbourhood up':
      'Tous les matims, le coq réveille tout le voisinage',
    'The rocket is flying all around the earth': 'La fusée vole tout autour de la terre'
  }
}

const answerQuestionMap = createInvertedAnswerQuestionMap(questionAnswerMap)

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.reading.sentences

const makeQuestionText = lngCode => questionText[lngCode]

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const commonProps = {
  navigationCommands: ['menu', 'reading'],
  randomizeQuestionFunction: randomizeReadingItemsAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetReadingAction,
  displayType: 'text'
}

const gameTypeProps = ({ lngCode, questionType, word, actualColor }) => {
  return {
    pageName: questionType,
    questionText: makeQuestionText(lngCode),
    questionAnswer: makeQuestionAnswer(word, lngCode),
    answerQuestionMap,
    textDisplayColor: actualColor
  }
}

export {
  easySentencesReadingList,
  mediumSentencesReadingList,
  hardSentencesReadingList,
  questionAnswerMap,
  answerQuestionMap,
  commonProps,
  gameTypeProps
}
