import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImageWithDimensions from './ImageWithDimensions'
// import LazyLoad from 'react-lazy-load'
import '../../src/styles/scss/App.scss'
import { baseUrl } from '../lib/_processUtil'
import { mapListeningStateToProps } from '../../src/redux/mapToPropsUtil'

const AppListeningImage = ({ addAnimation = false }) => (
  <ImageWithDimensions
    className={`app-listening-image ${addAnimation ? 'app-listening-image-from-left' : ''}`}
    alt="app-listening"
    src={`${baseUrl}/images/microphone.webp`}
    // srcSet={`${baseUrl}/images/microphone.webp 1x, ${baseUrl}/images/microphone.webp 2x, ${baseUrl}/images/microphone.webp 3x`}
  />
)

const AppSpeakingImage = () => (
  <ImageWithDimensions
    className="app-speaking-image"
    alt="app-speaking"
    src={`${baseUrl}/images/speaker.webp`}
    // srcSet={`${baseUrl}/images/speaker.webp 1x, ${baseUrl}/images/speaker.webp 2x, ${baseUrl}/images/speaker.webp 3x`}
  />
)

class AppListeningSpeakingVisual extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addListeningAnimation: false
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.isListening && !this.props.isListening) {
      this.setState({ addListeningAnimation: true })
    }
  }

  render() {
    const { isAnnyangSupported, isListening } = this.props
    const { addListeningAnimation } = this.state

    return (
      isAnnyangSupported && (
        <div className={`app-listening-${isListening}-wrapper`}>
          {isListening ? (
            // <LazyLoad>
            <AppListeningImage addAnimation={addListeningAnimation} />
          ) : (
            // </LazyLoad>
            // <LazyLoad>
            <AppSpeakingImage />
            // </LazyLoad>
          )}
        </div>
      )
    )
  }
}

export default connect(mapListeningStateToProps)(AppListeningSpeakingVisual)
