import React from 'react'
import {
  FadeIn,
  Shake,
  FadeOut,
  Swing,
  BounceIn,
  Pulse,
  BounceOut,
  Tada
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnBeginnerAdditionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_beginner_additions"
    sentencesNumberPlusRedirect={9}
    startTimesOf={{
      2: 5000,
      3: 13000,
      4: 15750,
      5: 17000,
      6: 18300,
      7: 20000,
      8: 23000,
      9: 28500
    }}
    animations={{
      1: (
        <FadeIn duration={0.5} className="learn-flex-center-center">
          <FadeOut className="flex" delay={4.25} duration={0.5}>
            <Swing delay={1.5}>
              <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
            </Swing>
            <Swing delay={3}>
              <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
            </Swing>
          </FadeOut>
        </FadeIn>
      ),
      2: (
        <FadeOut delay={6.5} className="learn-flex-center-center justify-content-space-between">
          <FadeIn delay={3}>
            <Shake delay={3.25} duration={1.5}>
              <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/1.webp`} />
            </Shake>
          </FadeIn>
          <FadeIn delay={4.75}>
            <Shake delay={5} duration={1.5}>
              <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/2.webp`} />
            </Shake>
          </FadeIn>
        </FadeOut>
      ),
      3: (
        <BounceOut delay={2} duration={0.5} className="learn-flex-center-center">
          <BounceIn duration={0.5}>
            <Pulse delay={0.75} duration={1.25}>
              <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
            </Pulse>
          </BounceIn>
          <BounceIn duration={0.5}>
            <Pulse delay={0.5} duration={1.5}>
              <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
            </Pulse>
          </BounceIn>
        </BounceOut>
      ),
      4: (
        <div className="learn-flex-center-center">
          <FadeIn className="flex width-100">
            <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/1.webp`} />
          </FadeIn>
          <div className="flex width-100 visibility-hidden"></div>
        </div>
      ),
      5: (
        <div className="learn-flex-center-center">
          <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/1.webp`} />
          <FadeIn className="right-hand flex width-100">
            <img className="width-100" alt="right-hand" src={`${mathsNumbersUrls}/0/1.webp`} />
          </FadeIn>
        </div>
      ),
      6: (
        <FadeOut delay={1} duration={0.5} className="learn-flex-center-center">
          <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/1.webp`} />
          <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/2.webp`} />
        </FadeOut>
      ),
      7: (
        <div className="learn-flex-center-center">
          <FadeOut
            delay={200.5}
            duration={0.5}
            className="learn-flex-column-space-between learn-horizontal-numbers">
            <FadeIn delay={0.25} duration={0.5}>
              <img className="width-100" src={`${mathsNumbersUrls}/10/1.webp`} />
            </FadeIn>
            <FadeIn delay={0.5} duration={0.5}>
              <img className="width-100 plus-sign" src={`${mathsNumbersUrls}/10/plus.webp`} />
            </FadeIn>
            <FadeIn delay={0.75} duration={0.5}>
              <img className="width-100" src={`${mathsNumbersUrls}/10/2.webp`} />
            </FadeIn>
            <FadeIn delay={1} duration={0.5}>
              <img className="width-100 equal-sign" src={`${mathsNumbersUrls}/10/equal.webp`} />
            </FadeIn>
            <FadeIn delay={1.25} duration={0.5}>
              <img className="width-100" src={`${mathsNumbersUrls}/10/3.webp`} />
            </FadeIn>
          </FadeOut>
        </div>
      ),
      8: (
        <FadeOut delay={5} duration={0.5} className="learn-flex-center-center">
          <FadeIn className="flex" duration={0.5}>
            <Tada delay={2.5}>
              <img className="left-hand" alt="left-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
            </Tada>
            <Tada delay={2.5}>
              <img className="right-hand" alt="right-hand" src={`${mathsNumbersUrls}/0/5.webp`} />
            </Tada>
          </FadeIn>
        </FadeOut>
      )
    }}
  />
)

export default LearnBeginnerAdditionsPage
