import {
  createSameKeyValueObject,
  createInvertedAnswerQuestionMap
} from './../../../lib/methodsUtil'
import { translatedQuestionsAndAnswers } from './../../../lib/languageUtil'
import { randomizeReadingItemsAction, resetReadingAction } from '../../../redux/actions/actions'

// usually answers order need to be mapped to the English ones in the same order
// so since this is used as an example for a PR we do that but when I tackle that
// game for real we will have different lists (and easyFrWordsList) as short words
// are not the same for different langaueg, In French we'll have in easy âne (donkey),

const easyWordsReadingList = [
  //   'ant',
  //   'bat',
  'bear',
  //   'bee',
  //   'bird',
  //   'bull',
  'cat',
  'cow',
  'crab',
  //   'deer',
  'dog',
  //   'duck',
  //   'fish',
  //   'fox',
  //   'frog',
  //   'goat',
  //   'horse',
  'lion',
  //   'mouse',
  //   'pig',
  //   'seal',
  //   'shark',
  //   'sheep',
  //   'snake',
  'wolf'
]

const mediumWordsReadingList = ['rabbit', 'piano', 'guitar', 'mountain', 'police']

const hardWordsReadingList = ['elephant', 'president', 'tomato', 'umbrela', 'tornado', 'labrador']

const allWordsList = [...easyWordsReadingList, ...mediumWordsReadingList, ...hardWordsReadingList]

const questionAnswerMap = {
  en: createSameKeyValueObject(allWordsList),
  fr: {
    // easy
    bear: 'ours',
    cat: 'chat',
    cow: 'vache',
    crab: 'crabe',
    dog: 'chien',
    lion: 'lion',
    wolf: 'loup',
    // medium
    rabbit: 'lapin',
    piano: 'piano',
    guitar: 'guitare',
    mountain: 'montagne',
    police: 'police',
    // hard
    elephant: 'elephant',
    president: 'president',
    tomato: 'tomate',
    umbrela: 'parapluie',
    tornado: 'tornade',
    labrador: 'labrador'
  }
}

const answerQuestionMap = createInvertedAnswerQuestionMap(questionAnswerMap)

const { questionText, questionAnswer } = translatedQuestionsAndAnswers.reading.words

const makeQuestionText = lngCode => questionText[lngCode]

const makeQuestionAnswer = (answer, lngCode = 'en') => questionAnswer[lngCode](answer)

const commonProps = {
  navigationCommands: ['menu', 'reading'],
  randomizeQuestionFunction: randomizeReadingItemsAction,
  getPreviousQuestionAnswer: makeQuestionAnswer,
  resetCategoryAction: resetReadingAction,
  displayType: 'text'
}

const gameTypeProps = ({ lngCode, questionType, word, actualColor }) => {
  return {
    pageName: questionType,
    questionText: makeQuestionText(lngCode),
    questionAnswer: makeQuestionAnswer(word, lngCode),
    answerQuestionMap,
    textDisplayColor: actualColor
  }
}

export {
  easyWordsReadingList,
  mediumWordsReadingList,
  hardWordsReadingList,
  questionAnswerMap,
  answerQuestionMap,
  commonProps,
  gameTypeProps
}
