import {
  letters,
  createInvertedKeysValuesObject,
  lightSvgFillColors,
  darkSvgFillColors,
  allSvgFillColors,
  pageTitleColorsMap
} from './dataUtil'
import { or, common, translateFrom, mathematicsAlsoAcceptedAsAnswersMap } from './languageUtil'
import { getLngCommandKeys } from './commandPathMaps'

const isLetter = letterAndNumber => letters.includes(letterAndNumber)

const isColorPage = pageName => pageName.indexOf('colors') > -1

const isAnimalsPage = pageName => pageName.indexOf('animals') > -1

const isFoodPage = pageName => pageName.indexOf('food') > -1

const isLettersAndNumbersPage = pageName => pageName.indexOf('letters') > -1

const isImageDrivenWordPage = pageName =>
  isAnimalsPage(pageName) || isFoodPage(pageName) || isLettersAndNumbersPage(pageName)

const isWordsQuestion = pageName => pageName.indexOf('play_') > -1

const isCapitalsPage = pageName => pageName.indexOf('capitals') > -1

const isFlagsPage = pageName => pageName.indexOf('flags') > -1

const isLocationsPage = pageName => pageName.indexOf('locations') > -1

const isGeographyPage = pageName =>
  isCapitalsPage(pageName) || isFlagsPage(pageName) || isLocationsPage(pageName)

const isLearnPage = pageName => pageName.indexOf('learn') > -1

const isLearnGeographyPage = pageName => isGeographyPage(pageName) && isLearnPage(pageName)

const isAdditionsPage = pageName => pageName.indexOf('additions') > -1

const isSubtractionsPage = pageName => pageName.indexOf('subtractions') > -1

const isMultiplicationsPage = pageName => pageName.indexOf('multiplications') > -1

const isDivisionsPage = pageName => pageName.indexOf('divisions') > -1

const isMathematicsPage = pageName =>
  isAdditionsPage(pageName) ||
  isSubtractionsPage(pageName) ||
  isMultiplicationsPage(pageName) ||
  isDivisionsPage(pageName)

const isLearnMathematicsPage = pageName => isMathematicsPage(pageName) && isLearnPage(pageName)

const isMathematicsQuestion = displayType =>
  ['additions', 'subtractions', 'multiplications', 'divisions'].includes(displayType)

const isMathsLearnClick = command => ['beginner', 'easy', 'medium'].includes(command)

const isEasyPage = pageName => pageName.indexOf('easy') > -1

const getRandomNumberBetween0AndIncluded = max => Math.floor(Math.random() * (max + 1))

const getRandomItemFromList = list => list[Math.floor(Math.random() * list.length)]

const removeFromList = (fullList, removeList) => fullList.filter(item => !removeList.includes(item))

const getRandomNotAlreadySeenItemFromList = (itemsList, alreadySeenList) => {
  const notAlreadySeenItemsList = removeFromList(itemsList, alreadySeenList)
  return getRandomItemFromList(notAlreadySeenItemsList)
}

const getTranslatedAnswer = (lngCode, languagesAnswerQuestionMap, answer) => {
  const makeLanguageAnswersList = lngCode => Object.keys(languagesAnswerQuestionMap[lngCode])

  const englishAnswersList = languagesAnswerQuestionMap ? makeLanguageAnswersList('en') : []
  const answerIndex = englishAnswersList.indexOf(answer)
  return languagesAnswerQuestionMap ? makeLanguageAnswersList(lngCode)[answerIndex] : ''
}

const isFoundIn = (word, allowedWordsList = []) =>
  word !== '' && allowedWordsList.join().toLowerCase().includes(word.toLowerCase())

const isTranscriptAMatch = (transcript, allowedWordsList = []) =>
  transcript.some(ts => isFoundIn(ts, allowedWordsList))

const isSpeakRequest = transcript =>
  isTranscriptAMatch(transcript, [common('speak'), common('talk')])

const isStartRequest = transcript => isTranscriptAMatch(transcript, [common('start')])

const isStopRequest = transcript => isTranscriptAMatch(transcript, [common('stop')])

const isNextQuestion = transcript =>
  isTranscriptAMatch(transcript, [common('next'), common('question')])

// set-up multiple acceptable answers if multiple are possible
const getPossibleAnswers = (
  lngCode,
  answerQuestionMap,
  actual,
  alsoAcceptedAsAnswers,
  isPlaying
) => {
  let translatedAnswer = actual ? getTranslatedAnswer(lngCode, answerQuestionMap, actual) : ''

  return isPlaying &&
    actual &&
    alsoAcceptedAsAnswers[actual] &&
    alsoAcceptedAsAnswers[actual] !== translatedAnswer
    ? [...new Set([translatedAnswer, ...alsoAcceptedAsAnswers[actual]])]
    : [translatedAnswer]
}

const getPossibleMathAnswers = (questionAnswer, alsoAcceptedAsAnswers) =>
  alsoAcceptedAsAnswers[questionAnswer] ? alsoAcceptedAsAnswers[questionAnswer] : [questionAnswer]

const isTranscriptCorrect = (possibleAnswers, transcript, isMathematics = false) => {
  if (isMathematics) {
    // In maths, we are checking for full equality === because else "Seventeen"(17) is accepted when we want "Seven"(7).
    return transcript.some(ts => possibleAnswers.some(answer => ts === answer))
  }

  return possibleAnswers.some(
    possibleAnswer =>
      possibleAnswer && possibleAnswer.length > 1 && isFoundIn(possibleAnswer, transcript)
  )
}

const getMultipleChoices = (
  answer,
  actualMultipleChoices,
  number,
  lngCode = 'en',
  answerQuestionMap,
  choiceModifier
) => {
  if (actualMultipleChoices) {
    const selectedChoices = actualMultipleChoices.slice(0, number)
    selectedChoices.push(answer)
    const translatedSelectedChoices = selectedChoices.map(choice =>
      getTranslatedAnswer(lngCode, answerQuestionMap, choice)
    )
    let multipleChoices = translatedSelectedChoices.sort(() => 0.5 - Math.random())
    multipleChoices = (
      choiceModifier
        ? multipleChoices.map(choice => choiceModifier[lngCode](choice))
        : multipleChoices
    ).join('... ')
    const orIndex = multipleChoices.lastIndexOf('...')
    const firstChoices = multipleChoices.substring(0, orIndex)
    const lastChoice = multipleChoices.substring(orIndex + 3, multipleChoices.length)
    return `${firstChoices}...${or(lngCode)} ${lastChoice}`
  }
}

const createInvertedAnswerQuestionMap = answerQuestionMap => {
  const inverted = {}
  for (const languageKey in answerQuestionMap) {
    inverted[languageKey] = createInvertedKeysValuesObject(answerQuestionMap[languageKey])
  }
  return inverted
}

const capitalizeWord = word => (word ? word[0].toUpperCase() + word.slice(1) : '')

const convertCamelCaseWordToTitleCase = word => {
  const wordWithBlankSpaces = word ? word.replace(/([A-Z])/g, ' $1').trim() : ''
  return capitalizeWord(wordWithBlankSpaces)
}

const convertKebabCaseWordToTitleCase = word => {
  const wordWithBlankSpaces = word ? word.replace(/-/g, ' ').trim() : ''
  return capitalizeWord(wordWithBlankSpaces)
}

const convertCamelCaseArrayToTitleCases = arr =>
  arr.map(word => convertCamelCaseWordToTitleCase(word))

const capitalizePagename = string =>
  string
    .split('_')
    .map(w => capitalizeWord(w))
    .join(' ')

const getQuestionAnswersBooleanValues = (
  isMathematics,
  transcript,
  isPlaying,
  actual,
  answerQuestionMap,
  lngCode = 'en',
  alsoAcceptedAsAnswers = {},
  questionAnswer = ''
) => {
  const possibleAnswers = isMathematics
    ? getPossibleMathAnswers(questionAnswer, alsoAcceptedAsAnswers)
    : getPossibleAnswers(lngCode, answerQuestionMap, actual, alsoAcceptedAsAnswers, isPlaying)

  return {
    isRepeat: isFoundIn(common('repeat'), transcript),
    isCorrect: isTranscriptCorrect(possibleAnswers, transcript, isMathematics),
    isIDontKnow: isFoundIn(common('i_dont_know'), transcript),
    nextQuestion: isNextQuestion(transcript),
    isPass: isPlaying && isFoundIn(common('pass'), transcript),
    isWrong: transcript.length
  }
}

const isSpeakSoundDisabled = soundLevel => ['partial', 'off'].includes(soundLevel)

const makeLanguageAnswers = (lngCode, answerQuestionMap) => {
  const englishAnswersList = Object.keys(answerQuestionMap.en)
  const languageAnswersList = Object.keys(answerQuestionMap[lngCode])
  const acceptedAnswers = {}
  // indexes are the same for each word/name for any language. A game Answer is chosen in English.
  // Below we map the English name to the foreign name, which is the expected foreign answer in that language (else the default English answer will apply).
  englishAnswersList.forEach((name, index) => {
    if (englishAnswersList[index] !== languageAnswersList[index]) {
      acceptedAnswers[name] = languageAnswersList[index]
    }
  })
  return acceptedAnswers
}

const createBorderRadius = (number, inverted = false) => {
  const line = () => Math.floor((number || Math.random()) * 130 + 125)
  const radius = () => Math.floor((number || Math.random()) * 40 + 15)
  const randomLine = inverted ? radius : line
  const randomRadius = inverted ? line : radius
  return {
    borderRadius: `${randomLine()}px ${randomRadius()}px ${randomLine()}px ${randomRadius()}px/${randomRadius()}px ${randomLine()}px ${randomRadius()}px ${randomLine()}px`
  }
}

const createBorderRadiusFrom = answer =>
  createBorderRadius(1 - answer.length / 20, answer % 2 === 1)

const createGlobeBorderRadiusFrom = answer => {
  const answerLength = answer ? answer.length : 1
  return {
    borderRadius: `50% / 50% ${43 + answerLength / 2}% ${55 - answerLength / 2}%`
  }
}

const createAllNumbersList = limit => {
  const list = []
  for (let i = 1; i < limit + 1; i++) {
    list.push(i)
  }
  return list
}

// because QuestionAnswerGames renders twice; in order to get the correct last answer, we need to fiddle with the index depending on the size of the new list (odd or even changes which index we need)
const getSavedAnswerBeforeSeenListReset = (fullSeenList, resetList) => {
  const lastAnswerIndex = fullSeenList.length - (resetList.length === 2 ? 1 : 2)
  return fullSeenList[lastAnswerIndex]
}

const isDivisionPath = topicNavigationPath =>
  topicNavigationPath.indexOf('mathematics/divisions/') > -1

const getDivisionPageTitle = topicNavigationPath =>
  `${topicNavigationPath.replace('mathematics/divisions/', '')}_divisions_page`

const getMathematicsAlsoAcceptedAsAnswers = (result, lngCode) => {
  let alsoAcceptedAsAnswers
  const stringResult = result.toString()

  // because some numbers are not understood in French. 99% of the time `1` is understood as `un`
  // and sometimes it register after saying twice, so "un un". See mathematicsAlsoAcceptedAsAnswersMap
  const hasLanguageAcceptedAsAnswer =
    lngCode !== 'en' &&
    Object.keys(mathematicsAlsoAcceptedAsAnswersMap[lngCode]).includes(stringResult)

  if (hasLanguageAcceptedAsAnswer) {
    alsoAcceptedAsAnswers = {
      [result]: [stringResult, ...mathematicsAlsoAcceptedAsAnswersMap[lngCode][result]]
    }
  }

  // because sometimes Annyang will understand "-123" as "- 123" so we allow it with a blank space
  const isNegativeResult = stringResult.indexOf('-') === 0
  if (isNegativeResult) {
    const blankSpaceNegativeResult = `- ${stringResult.slice(1)}`
    alsoAcceptedAsAnswers = {
      [result]: hasLanguageAcceptedAsAnswer
        ? [...alsoAcceptedAsAnswers[result], blankSpaceNegativeResult]
        : [stringResult, blankSpaceNegativeResult]
    }
  }

  return alsoAcceptedAsAnswers
}

const createAndList = (list, commonKey) => {
  const languagesList = list.map(lng => common(`${commonKey}.${lng}`)).join(', ')
  const comaLastIndex = languagesList.lastIndexOf(',')
  return `${languagesList.slice(0, comaLastIndex)} &${languagesList.slice(comaLastIndex + 1)}.`
}

const isRedirectButtonClick = (nextPath, navigationCommands) => {
  if (nextPath) {
    const pathlastSlashIndex = nextPath.lastIndexOf('/')
    return isFoundIn(
      nextPath.slice(pathlastSlashIndex + 1),
      navigationCommands.map(nav => nav.replace(/_/g, '-'))
    )
  }
  return false
}

const createSameKeyValueObject = (keysList, preValue) => {
  const obj = {}
  keysList.forEach(key => {
    obj[key] = preValue ? `${preValue} ${key}` : key
  })
  return obj
}

const getStrippedLevelPageName = pageName =>
  ['easy', 'medium', 'hard'].some(level => pageName.indexOf(level) > -1)
    ? pageName.replace(/_|easy|medium|hard/gi, '')
    : pageName.replace(/_/gi, '-')

const getTopic = pageName =>
  capitalizeWord(
    getStrippedLevelPageName(pageName)
      .replace(/_|-|learn|play|beginner|famous|other|page/gi, '')
      .replace(/Lettersandnumbers/gi, 'Letters & Numbers')
      .replace(/Reversecapitals/gi, 'Reverse Capitals')
  )

const generateMultipleImagesRandomIndex = (pageName, actual, getRandomIndexMap) => {
  let randomNumber = 0
  if (isLettersAndNumbersPage(pageName)) {
    randomNumber = isLetter(actual)
      ? getRandomNumberBetween0AndIncluded(20)
      : getRandomNumberBetween0AndIncluded(16) + 1
  }
  if (isAnimalsPage(pageName) || isFoodPage(pageName)) {
    const random = getRandomIndexMap[pageName][actual]
    randomNumber = random > 0 ? getRandomNumberBetween0AndIncluded(random) : 0
  }
  return randomNumber
}

const getRandomFillColor = lightOrDarkSvgFillColors =>
  getRandomItemFromList(lightOrDarkSvgFillColors || allSvgFillColors)

const getMapFillColors = pageName => {
  let mapFillColorOne
  let mapFillColorTwo
  if (isCapitalsPage(pageName) || isLocationsPage(pageName)) {
    mapFillColorOne = getRandomFillColor()
    const secondFillColorList = darkSvgFillColors.includes(mapFillColorOne)
      ? darkSvgFillColors
      : lightSvgFillColors
    mapFillColorTwo = getRandomFillColor(
      secondFillColorList.filter(color => color !== mapFillColorOne)
    )
  }
  return { mapFillColorOne, mapFillColorTwo }
}

const getEnCommandName = (lngCode, command) => {
  const lngKeyIndex = getLngCommandKeys(lngCode).indexOf(command.toLowerCase())
  return getLngCommandKeys('en')[lngKeyIndex]
}

const getPageTitleColor = path => {
  let subPath = path.replace('famous-ones', 'easy').replace('others', 'hard').substring(1)
  subPath = subPath.replace(/\/learn|\/play|\/quotient|\/remainder/, '')
  return pageTitleColorsMap[subPath]
}

const isCustomTopic = (customTopics, pageName) => topic => {
  if (!customTopics) {
    return false
  }

  const translate = pageName === 'menu_page' ? common : translateFrom(pageName)

  const translatedCustomTopics = pageName
    ? customTopics.map(topc => translate(topc).toLowerCase())
    : []
  return translatedCustomTopics ? translatedCustomTopics.includes(topic) : false
}

const isLockedTopic = (user, command) => {
  if (user) {
    return user.premium
      ? false
      : !user.unlockedPremiumTopics.map(topic => topic.replace(/-/gi, ' ')).includes(command)
  }
  return true
}

const getTextSize = pageName => {
  const sizer = pageName.split('_')[0]
  if (sizer === 'easy') {
    return 'big'
  }
  if (sizer === 'medium') {
    return 'medium'
  }
  return 'small'
}

export {
  isLetter,
  isColorPage,
  isLettersAndNumbersPage,
  isImageDrivenWordPage,
  isWordsQuestion,
  isCapitalsPage,
  isFlagsPage,
  isLocationsPage,
  isGeographyPage,
  isLearnPage,
  isLearnGeographyPage,
  isAdditionsPage,
  isSubtractionsPage,
  isMultiplicationsPage,
  isLearnMathematicsPage,
  isMathematicsQuestion,
  isMathsLearnClick,
  isEasyPage,
  removeFromList,
  getRandomItemFromList,
  getRandomNotAlreadySeenItemFromList,
  getTranslatedAnswer,
  isFoundIn,
  isTranscriptAMatch,
  isSpeakRequest,
  isStartRequest,
  isStopRequest,
  isTranscriptCorrect,
  getMultipleChoices,
  createInvertedAnswerQuestionMap,
  capitalizeWord,
  convertCamelCaseArrayToTitleCases,
  convertCamelCaseWordToTitleCase,
  convertKebabCaseWordToTitleCase,
  getQuestionAnswersBooleanValues,
  isSpeakSoundDisabled,
  makeLanguageAnswers,
  createBorderRadius,
  createBorderRadiusFrom,
  createGlobeBorderRadiusFrom,
  createAllNumbersList,
  getSavedAnswerBeforeSeenListReset,
  isDivisionPath,
  getDivisionPageTitle,
  getMathematicsAlsoAcceptedAsAnswers,
  createAndList,
  isRedirectButtonClick,
  createSameKeyValueObject,
  getStrippedLevelPageName,
  getTopic,
  capitalizePagename,
  generateMultipleImagesRandomIndex,
  getMapFillColors,
  getEnCommandName,
  getPageTitleColor,
  isLockedTopic,
  isCustomTopic,
  getTextSize,
  getRandomNumberBetween0AndIncluded
}
