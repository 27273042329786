import React from 'react'
import { connect } from 'react-redux'
import { baseUrl } from '../lib/_processUtil'
import TopicNavigationPage from '../components/TopicNavigationPage'
import { mapMinimalStateToProps } from '../redux/mapToPropsUtil'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { FadeIn, Shake, BounceIn } from '../lib/animationsUtil'
import { getRandomNumberBetween0AndIncluded } from '../lib/methodsUtil'

const navigationCommands = ['menu']
const annyangNotSupportedNavigationCommands = ['menu']

const PageNotFoundPage = ({ isAnnyangSupported }) => (
  <TopicNavigationPage
    navigationCommands={
      isAnnyangSupported ? navigationCommands : annyangNotSupportedNavigationCommands
    }
    pageName="page_not_found_page"
    topics={[]}
    subTopics={isAnnyangSupported ? navigationCommands : annyangNotSupportedNavigationCommands}
    borderRadius={0.59}
    SEODescription="E=MC² - Elusive, missing or concealed - Page not found in Mini Einstein; Speech recognition, educational games on Words, Maths, Geography, Reading, ...">
    {() => (
      <BounceIn className="page-not-found-image-wrapper">
        <FadeIn className="page-not-found-image-wrapper-fade-in">
          <Shake delay={1} duration={10}>
            <ImageWithDimensions
              alt="Page Not Found Image"
              className="mini-einstein-menu-picture"
              src={`${baseUrl}/images/page-not-found/${getRandomNumberBetween0AndIncluded(22)}.webp`}
            />
          </Shake>
        </FadeIn>
      </BounceIn>
    )}
  </TopicNavigationPage>
)

export default connect(mapMinimalStateToProps)(PageNotFoundPage)
