import React, { useState, useEffect } from 'react'
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from '@paypal/react-paypal-js'
import TopicNavigationPage from '../components/TopicNavigationPage'
import InputRadio from '../components/InputRadio'
import StyledI18n from '../components/StyledI18n'
import ImageWithDimensions from '../components/ImageWithDimensions'
import { capitalizeWord, createBorderRadius } from '../lib/methodsUtil'
import { baseUrl } from '../lib/_processUtil'
import { common } from '../lib/languageUtil'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import LiveDonationProgressBar from './LiveDonationProgressBar'

const navigationCommands = ['menu', 'about', 'questions']

const targets = [
  { topic: 'play_letters_and_numbers_page', amount: '500€' },
  { topic: 'multiplications', amount: '2000€' },
  { topic: 'locations', amount: '5000€' }
]
// 'divisions', additional words? // from needDonationsTopics of dataUtil

const initialOptions = {
  // eslint-disable-next-line
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  // clientId: 'test', // for dev purposes
  currency: 'EUR',
  intent: 'capture'
}

const currencyOptions = [
  {
    label: 'Euro',
    value: 'EUR'
  },
  {
    label: 'US Dollar',
    value: 'USD'
  },
  {
    label: 'Pound sterling',
    value: 'GBP'
  }
  // {
  //   label: 'Canadian Dollar',
  //   value: 'CAD'
  // },
  // {
  //   label: 'Australian dollar',
  //   value: 'AUD'
  // }
]

const Spinner = () => (
  <div className="loading-spinner-wrapper">
    <p>LOADING...</p>
    <div className="support-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

const Checkout = ({ translate }) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer()
  const [currency, setCurrency] = useState('EUR')
  const [paypalAmount, setPaypalAmount] = useState(50)
  const [callbackMessage, setCallbackMessage] = useState(null)
  const [payerName, setPayerName] = useState(null)
  const [payerAmount, setPayerAmount] = useState(null)
  const [payerCurrency, setPayerCurrency] = useState(null)

  useEffect(() => {
    sendAmplitudeEvent('Support Page', { action: 'visit' })
  }, [])

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value)
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        // 'client-id': clientId,
        currency: value
      }
    })
  }

  const onPaypalAmountChange = ({ target: { value } }) => {
    setPaypalAmount(value)
  }

  const onAmountFieldBlur = () => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        // 'client-id': clientId,
        currency
      }
    })
  }

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{ amount: { value: paypalAmount, currency_code: currency } }]
    })
  }

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then(details => {
      const { payer, purchase_units } = details
      const { value, currency_code } = purchase_units[0].amount
      setCallbackMessage('success')
      setPayerName(payer.name.given_name)
      setPayerAmount(value.split('.')[1] === '00' ? value.split('.')[0] : value)
      setPayerCurrency(currency_code)
      sendAmplitudeEvent('Support Page', { action: 'donation' })
      setTimeout(() => {
        setCallbackMessage(null)
        setPayerName(null)
        setPayerAmount(null)
        setPayerCurrency(null)
      }, 5000)
    })
  }

  const onErrorOrder = () => {
    setCallbackMessage('error')
    setTimeout(() => setCallbackMessage(null), 5000)
  }

  return (
    <div className="paypal-checkout">
      {isPending ? (
        <Spinner />
      ) : callbackMessage ? (
        <div className="paypal-checkout-order-message">
          <div>
            <ImageWithDimensions src={`${baseUrl}/images/support/${callbackMessage}.webp`} />
          </div>
          <StyledI18n
            defaultString={translate(`checkout_${callbackMessage}_message`)}
            values={{ name: payerName, amount: payerAmount, currency: payerCurrency }}
          />
        </div>
      ) : (
        <>
          <div className="support-quote">{translate('working_on_it')}</div>
          <StyledI18n defaultString={translate('support_message')} />
          <div className="support-page-targets">
            {targets.map(({ topic, amount }) => (
              <div className="support-page-targets-target" key={topic}>
                <div className="support-page-targets-target-topic">
                  {capitalizeWord(common(topic))}
                </div>
                <div className="support-page-targets-target-amount">{amount}</div>
              </div>
            ))}
          </div>
          <LiveDonationProgressBar />
          <div className="paypal-checkout-amount-currency">
            <input
              type="text"
              onChange={onPaypalAmountChange}
              value={paypalAmount}
              style={createBorderRadius(0.7)}
              onBlur={onAmountFieldBlur}
            />
            <InputRadio
              name="currency"
              options={currencyOptions}
              value={currency}
              onChange={onCurrencyChange}
            />
          </div>
          <PayPalButtons
            style={{ layout: 'vertical' }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
            onError={(data, actions) => onErrorOrder(data, actions)}
          />
        </>
      )}
    </div>
  )
}

const content = translate => (
  <PayPalScriptProvider options={initialOptions}>
    <Checkout translate={translate} />
  </PayPalScriptProvider>
)

const SupportPage = () => (
  <TopicNavigationPage
    navigationCommands={navigationCommands}
    pageName="support_page"
    topics={['topic_about', 'topic_questions']}
    subTopics={navigationCommands}
    borderRadius={0.45}
    SEODescription="E=MC² - Every Move counts² - Help to create the best children & kids learning experience possible via maths, words, reading, geography, speech recognition"
    pageNeedsKeyboardTyping={true}
    titleOnly={true}>
    {content}
  </TopicNavigationPage>
)

export default SupportPage
