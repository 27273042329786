import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { common } from '../lib/languageUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { changePathAction } from '../redux/actions/actions'

const createAnchor = href => (
  <a style={{ color: 'black' }} target="_blank" rel="noreferrer" href={href} />
)

const NavigateComponent = ({ dispatch }) => (
  <span
    className="questions-page-navigate-to-support"
    onClick={() => {
      dispatch(changePathAction('/support'))
    }}>
    {common('here')}
  </span>
)

const NavigateToSupportLink = connect(
  mapMinimalStateToProps,
  passDispatchToProps
)(NavigateComponent)

const i18nextTransStyling = {
  italic: <i />,
  bold: <strong />,
  red: <span style={{ color: 'red' }} />,
  blue: <span style={{ color: 'blue' }} />,
  green: <span style={{ color: 'green' }} />,
  orange: <span style={{ color: 'orange' }} />,
  chocolate: <span style={{ color: 'chocolate' }} />,
  grey: <span style={{ color: 'slategrey' }} />,
  crimson: <span style={{ color: 'crimson' }} />,
  br: <br />,
  freepik: createAnchor('http://www.freepik.com'),
  amcharts: createAnchor('https://www.amcharts.com/svg-maps'),
  wiki: createAnchor('https://www.wikipedia.org/'),
  support: <NavigateToSupportLink />
}

const StyledI18n = ({ defaultString, values }) => (
  <div style={{ whiteSpace: 'pre-wrap' }}>
    <Trans defaults={defaultString} components={i18nextTransStyling} values={values} />
  </div>
)

export default StyledI18n
