import React from 'react'
import WorldMap from './WorldMap'
import { getMathDisplay } from '../lib/mathematicsUtil'
import { getCountryMapForCityArgument } from '../lib/geographyUtil'
import { questionAnswerMap } from '../../src/pages/geography/capitals/util'
import { isGlobeImage } from '../../src/pages/geography/locations/util'
import ImageWithDimensions from './ImageWithDimensions'
import {
  isMathematicsQuestion,
  isWordsQuestion,
  isGeographyPage,
  isEasyPage,
  createBorderRadiusFrom,
  createGlobeBorderRadiusFrom,
  getStrippedLevelPageName,
  getTextSize
} from '../lib/methodsUtil'

const QuestionDisplay = ({
  displayType,
  actual,
  getImageUrl,
  imageAlt,
  pageName,
  randomList,
  randomNumber,
  mapFillColorOne,
  mapFillColorTwo,
  textDisplayColor,
  translatedActual
}) => {
  let displayKey = actual
  if (['reverseImage', 'reverseCountryMap'].includes(displayType)) {
    displayKey = questionAnswerMap('en')[actual]
  }

  const isColor = displayType === 'color'
  const isText = displayType === 'text'
  const isImage = ['image', 'reverseImage'].includes(displayType)
  const isGlobeImg = isGlobeImage(actual, pageName)
  const isWorldmap = displayType === 'worldMap'
  const isCountryMap = ['countryMap', 'reverseCountryMap'].includes(displayType)
  const topicPageName = isGeographyPage(pageName)
    ? 'geography'
    : isWordsQuestion(pageName)
      ? 'words'
      : pageName

  return (
    <>
      {isColor && <div className="question-display-color" style={{ background: actual }}></div>}

      {isText && (
        <div className={`question-display-text-${getTextSize(pageName)}-${textDisplayColor}`}>
          {translatedActual}
        </div>
      )}

      {isImage && (
        <div
          className={`displayed-image-wrapper-${topicPageName}`}
          style={isWordsQuestion(pageName) ? createBorderRadiusFrom(actual) : {}}>
          <ImageWithDimensions
            alt={imageAlt}
            style={
              isGlobeImg
                ? createGlobeBorderRadiusFrom(actual)
                : isGeographyPage(pageName)
                  ? createBorderRadiusFrom(actual)
                  : {}
            }
            className={`displayed-image-${topicPageName} ${
              isGlobeImg ? 'displayed-image-border-radius' : ''
            }`}
            src={getImageUrl(displayKey, randomNumber)}
          />
        </div>
      )}

      {isWorldmap && (
        <WorldMap countryName={displayKey} fillOne={mapFillColorOne} fillTwo={mapFillColorTwo} />
      )}

      {isCountryMap && getCountryMapForCityArgument(displayKey, mapFillColorOne, mapFillColorTwo)}

      {isMathematicsQuestion(displayType) && (
        <div
          className={`mathematics-question-display
          mathematics-question-display-${pageName.split('_')[0]}
          mathematics-question-display-${getStrippedLevelPageName(pageName)}
          ${
            isEasyPage(pageName) && actual[0].toString().length + actual[1].toString().length > 2
              ? 'mathematics-question-display-hard'
              : ''
          }`}>
          {getMathDisplay(actual, displayType, pageName, randomList, getImageUrl)}
        </div>
      )}
    </>
  )
}

export default QuestionDisplay
