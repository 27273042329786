import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 850
const height = 1525

const cities = {
  Roseau: { x: 346, y: 1150 }
}

const Dominica = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map dominica">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-20 -20 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="DM-11"
          title="Saint Peter"
          className="land"
          d="M271.05,453.18L85.88,353.52L16.55,361.16L56.34,528.02L112.21,580.16L277.86,482.79L271.05,453.18z"
        />
        <path
          id="DM-10"
          title="Saint Paul"
          className="land"
          d="M538.46,998.23L515.1,792.6L403.46,845.26L243.4,843.85L318.16,1108.79L538.46,998.23z"
        />
        <path
          id="DM-09"
          title="Saint Patrick"
          className="land"
          d="M475.69,1461.07L569.76,1351.47L706.47,1327.84L775.02,1206.39L783.71,1042.75L603.02,1098.07L441.56,1315.5L444.75,1350.08L475.69,1461.07z"
        />
        <path
          id="DM-08"
          title="Saint Mark"
          className="land"
          d="M383.23,1484.85L475.69,1461.07L444.75,1350.08L386.97,1377.45L383.23,1484.85z"
        />
        <path
          id="DM-07"
          title="Saint Luke"
          className="land"
          d="M441.56,1315.5L386.29,1292.85L386.97,1377.45L444.75,1350.08L441.56,1315.5z"
        />
        <path
          id="DM-06"
          title="Saint Joseph"
          className="land"
          d="M572.51,654.44L503.29,566.61L277.86,482.79L112.21,580.16L243.4,843.85L403.46,845.26L515.1,792.6L572.51,654.44z"
        />
        <path
          id="DM-05"
          title="Saint John"
          className="land"
          d="M66.65,0L0.27,189.16L89.46,210.86L0.27,307.53L16.55,361.16L85.88,353.52L271.05,453.18L232.69,191.82L162.35,134.19L132.62,2.68L66.65,0z"
        />
        <path
          id="DM-04"
          title="Saint George"
          className="land"
          d="M603.02,1098.07L538.46,998.23L318.16,1108.79L386.29,1292.85L441.56,1315.5L603.02,1098.07z"
        />
        <path
          id="DM-03"
          title="Saint David"
          className="land"
          d="M783.71,1042.75L799.73,806.04L754.12,782.38L735.11,429.27L714.14,547.46L572.51,654.44L515.1,792.6L538.46,998.23L603.02,1098.07L783.71,1042.75z"
        />
        <path
          id="DM-02"
          title="Saint Andrew"
          className="land"
          d="M735.11,429.27L604.79,189.16L439.17,123.2L348.07,157.59L210.28,18.61L132.62,2.68L162.35,134.19L232.69,191.82L271.05,453.18L277.86,482.79L503.29,566.61L572.51,654.44L714.14,547.46L735.11,429.27z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Dominica))
