import actions from '../actions/action-types'
import {
  removeFromList,
  getTranslatedAnswer,
  isCapitalsPage,
  isGeographyPage,
  isLettersAndNumbersPage,
  generateMultipleImagesRandomIndex,
  getMapFillColors
} from '../../lib/methodsUtil'
import {
  colorsList,
  questionAnswerMap as colorsQuestionAnswerMap
} from '../../pages/words/colors/util'
import {
  animalsList,
  questionAnswerMap as animalsQuestionAnswerMap,
  answerQuestionImageIndexLimitMap as animalsRandom
} from '../../pages/words/animals/util'
import {
  foodList,
  questionAnswerMap as foodQuestionAnswerMap,
  answerQuestionImageIndexLimitMap as foodRandom
} from '../../pages/words/food/util'
import { lettersAndNumbersList } from '../../pages/words/letters-and-numbers/util'
import { easyCapitalsList, allCapitalsList } from '../../pages/geography/capitals/util'
import { answerQuestionMap as capitalsAnswerQuestionMap } from '../../pages/geography/capitals/answerQuestionMap'
import { easyFlagsList, allFlagsList } from '../../pages/geography/flags/util'
import { answerQuestionMap as flagsAnswerQuestionMap } from '../../pages/geography/flags/answerQuestionMap'
import { easyLocationsList, allLocationsList } from '../../pages/geography/locations/util'
import { answerQuestionMap as locationsAnswerQuestionMap } from '../../pages/geography/locations/answerQuestionMap'

const initialState = {
  isLearning: false,
  learnList: [],
  actual: null,
  translatedActual: null,
  randomNumber: 0,
  extra: null,
  mapFillColorOne: null,
  mapFillColorTwo: null
}

const pageList = {
  // WORDS
  learn_colors: colorsList,
  learn_animals: animalsList,
  learn_food: foodList,
  learn_letters_and_numbers: lettersAndNumbersList,
  // GEOGRAPHY
  learn_famous_capitals: easyCapitalsList,
  learn_other_capitals: removeFromList(allCapitalsList, easyCapitalsList),
  learn_famous_flags: easyFlagsList,
  learn_other_flags: removeFromList(allFlagsList, easyFlagsList),
  learn_famous_locations: easyLocationsList,
  learn_other_locations: removeFromList(allLocationsList, easyLocationsList)
}

const translateMap = {
  // WORDS
  learn_colors: colorsQuestionAnswerMap,
  learn_animals: animalsQuestionAnswerMap,
  learn_food: foodQuestionAnswerMap,
  // GEOGRAPHY
  learn_famous_capitals: capitalsAnswerQuestionMap,
  learn_other_capitals: capitalsAnswerQuestionMap,
  learn_famous_flags: flagsAnswerQuestionMap,
  learn_other_flags: flagsAnswerQuestionMap,
  learn_famous_locations: locationsAnswerQuestionMap,
  learn_other_locations: locationsAnswerQuestionMap
}

const getRandomImageIndexLimitMap = {
  learn_animals: animalsRandom,
  learn_food: foodRandom
}

const randomizeArrayOrder = arr =>
  arr
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

const learnReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.prepareLearning: {
      const { pageName } = action.payload
      return { ...initialState, learnList: randomizeArrayOrder(pageList[pageName]) }
    }

    case actions.startLearning: {
      return { ...state, isLearning: true }
    }

    case actions.updateLearningItem: {
      const { pageName, lngCode } = action.payload
      let learnList = state.learnList
      const actual = learnList.shift()
      const translatedActual = isGeographyPage(pageName)
        ? getTranslatedAnswer(lngCode, translateMap[pageName], actual)
        : isLettersAndNumbersPage(pageName)
        ? actual
        : translateMap[pageName][lngCode][actual]

      if (learnList.length === 0) {
        learnList = randomizeArrayOrder(pageList[pageName])
      }

      const randomNumber = generateMultipleImagesRandomIndex(
        pageName,
        actual,
        getRandomImageIndexLimitMap
      )

      let extra
      if (isCapitalsPage(pageName)) {
        extra = capitalsAnswerQuestionMap[lngCode][translatedActual]
      }

      const { mapFillColorOne, mapFillColorTwo } = getMapFillColors(pageName)

      return {
        ...state,
        learnList,
        actual,
        translatedActual,
        randomNumber,
        extra,
        mapFillColorOne,
        mapFillColorTwo
      }
    }

    case actions.resetLearning: {
      return { ...initialState, learnList: [] }
    }

    default:
      return state
  }
}

export default learnReducer
