import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 825

const cities = {
  Baghdad: { x: 460, y: 409 }
}

const Iraq = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map iraq">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="IQ-BB"
          title="Babil"
          className="land"
          d="M450.66,418.9L445.01,424.3L412.53,417.68L428.49,428.63L437.79,458.62L448.77,462.46L453.29,501.01L457.71,520.19L468.01,513.31L478.64,518.8L488.42,522.46L525.06,496.1L503.63,464.08L471.87,454.23L471.3,436.76L468.43,420.19L450.66,418.9z"
        />
        <path
          id="IQ-AN"
          title="Al-Anbar"
          className="land"
          d="M304,243.04L262.6,243.88L197.93,224.47L198.9,260.92L179.39,299.73L0.49,401.07L23.57,486.22L17.13,488.31L21.25,502.49L39.84,501.11L42.75,510.59L30.86,522.78L135.2,541.81L270.04,622.33L327.86,672.61L366.24,567.47L381.31,546L410.01,530.91L401.94,517.93L362.75,479.01L407.52,455.16L437.79,458.62L428.49,428.63L412.53,417.68L445.01,424.3L450.66,418.9L449.89,400.4L447.19,395.04L435.37,391.85L427.32,372.49L380.63,362.49L356.31,339.46L339.73,290.42L327.35,295.66L307.96,280.57L304,243.04z"
        />
        <path
          id="IQ-DI"
          title="Diyala"
          className="land"
          d="M573.88,249.98L576.08,238.72L567.09,229.36L545.56,272.95L526.61,288.75L524.03,261.86L506.08,253.52L478.68,287.6L468.53,283.78L468.75,313.5L450.84,338.09L463.77,345.77L459.98,379.03L447.19,395.04L449.89,400.4L470.42,397.51L468.43,420.19L471.3,436.76L477.19,429.82L484.29,435.77L495.36,420.35L549.02,430.64L587.62,388.91L578.48,389.07L581.21,375.69L568.84,380.37L539.92,342.02L554.84,310.87L542.92,295.53L549.75,280.93L566.15,284.93L560.09,268.11L573.88,249.98z"
        />
        <path
          id="IQ-SU"
          title="As-Sulaymaniyah"
          className="land"
          d="M573.88,249.98L583.43,231.61L602.86,229.19L587.58,190.91L590.83,172.83L617.23,159.83L570.03,159.07L552.96,140.62L536.47,142.1L528.37,99.23L515.7,98.24L509.64,87.74L471.29,102.69L483.97,118.36L478.43,127.21L507.04,150.23L478.43,151.55L491.34,171.69L492.81,194.42L483.95,221.53L473.1,227.45L504.93,242.64L506.08,253.52L524.03,261.86L526.61,288.75L545.56,272.95L567.09,229.36L576.08,238.72L573.88,249.98z"
        />
        <path
          id="IQ-WA"
          title="Wasit"
          className="land"
          d="M639.57,454.6L598.44,441.79L600.35,431.33L592.99,427.04L603.93,413.61L587.62,388.91L549.02,430.64L495.36,420.35L484.29,435.77L477.19,429.82L471.3,436.76L471.87,454.23L503.63,464.08L525.06,496.1L540.86,501.84L568.12,539.19L616.44,533.64L637.62,535.75L639.82,521.28L625.52,510.11L639.57,454.6z"
        />
        <path
          id="IQ-MA"
          title="Maysan"
          className="land"
          d="M727.42,607.38L740.32,552.85L712.3,519.84L700.22,489.89L679.58,488.35L639.57,454.6L625.52,510.11L639.82,521.28L637.62,535.75L616.44,533.64L651.73,611.05L682.48,617.57L700.74,603.61L727.42,607.38z"
        />
        <path
          id="IQ-DQ"
          title="Dhi-Qar"
          className="land"
          d="M682.48,617.57L651.73,611.05L616.44,533.64L568.12,539.19L573.03,567.66L567.64,645.4L620.85,655.29L637.45,676.11L679.4,670.45L682.48,617.57z"
        />
        <path
          id="IQ-QA"
          title="Al-Qadisiyah"
          className="land"
          d="M525.06,496.1L488.42,522.46L478.64,518.8L467.99,538.72L471.44,568.69L462.26,574.01L486.56,591.04L507.09,604.88L517.73,572.46L510.89,565L526.84,560.16L573.03,567.66L568.12,539.19L540.86,501.84L525.06,496.1z"
        />
        <path
          id="IQ-MU"
          title="Al-Muthannia"
          className="land"
          d="M619.71,812.64L636.37,806.94L651.32,716.29L637.45,676.11L620.85,655.29L567.64,645.4L573.03,567.66L526.84,560.16L510.89,565L517.73,572.46L507.09,604.88L486.56,591.04L478.41,629.28L449.41,660.73L412.83,742.24L485.79,800.49L619.71,812.64z"
        />
        <path
          id="IQ-BA"
          title="Al-Basrah"
          className="land"
          d="M727.42,607.38L700.74,603.61L682.48,617.57L679.4,670.45L637.45,676.11L651.32,716.29L636.37,806.94L688.28,721.49L727.23,715.45L749.59,725.28L748.52,714.61L751.31,724.33L763.57,720.47L799.51,729.75L778.8,695.17L754.95,680.87L754.84,630.98L727.13,630.44L727.42,607.38z"
        />
        <path
          id="IQ-BG"
          title="Baghdad"
          className="land"
          d="M468.43,420.19L470.42,397.51L449.89,400.4L450.66,418.9L468.43,420.19z"
        />
        <path
          id="IQ-"
          title="Karbala'"
          className="land"
          d="M437.79,458.62L407.52,455.16L362.75,479.01L401.94,517.93L453.29,501.01L448.77,462.46L437.79,458.62z"
        />
        <path
          id="IQ-NA"
          title="An-Najaf"
          className="land"
          d="M478.64,518.8L468.01,513.31L457.71,520.19L453.29,501.01L401.94,517.93L410.01,530.91L381.31,546L366.24,567.47L327.86,672.61L412.83,742.24L449.41,660.73L478.41,629.28L486.56,591.04L462.26,574.01L471.44,568.69L467.99,538.72L478.64,518.8z"
        />
        <path
          id="IQ-SD"
          title="Sala ad-Din"
          className="land"
          d="M374.79,194.51L372.84,167.88L335.76,192.27L348.45,230.09L306.05,233.33L304,243.04L307.96,280.57L327.35,295.66L339.73,290.42L356.31,339.46L380.63,362.49L427.32,372.49L435.37,391.85L447.19,395.04L459.98,379.03L463.77,345.77L450.84,338.09L468.75,313.5L468.53,283.78L478.68,287.6L506.08,253.52L504.93,242.64L473.1,227.45L450.75,269.73L385.88,229.28L367.55,199.2L374.79,194.51z"
        />
        <path
          id="IQ-NI"
          title="Ninawa"
          className="land"
          d="M413.17,37.22L398.57,34.13L405.01,44.89L398.53,56.15L371.73,45.12L360.72,61.1L332.76,69.81L305.04,53.62L305.34,41.18L294.65,32.09L248.97,79.25L213.7,87.72L204.84,104.12L201.55,133.92L211.91,174.74L197.93,224.47L262.6,243.88L304,243.04L306.05,233.33L348.45,230.09L335.76,192.27L372.84,167.88L374.36,140.1L385.83,134.81L415.3,88.48L451.87,65.51L413.17,37.22z"
        />
        <path
          id="IQ-AR"
          title="Arbil"
          className="land"
          d="M509.64,87.74L512.61,72.3L495.08,59.68L499.66,36.8L488.76,22.14L477.04,20.17L447.07,40.7L446.39,14.18L432.84,6.28L435.25,17.16L413.17,37.22L451.87,65.51L415.3,88.48L385.83,134.81L374.36,140.1L372.84,167.88L374.79,194.51L405.31,186.58L420.01,155.57L434.78,162.09L478.43,151.55L507.04,150.23L478.43,127.21L483.97,118.36L471.29,102.69L509.64,87.74z"
        />
        <path
          id="IQ-KI"
          title="Kirkuk"
          className="land"
          d="M478.43,151.55L434.78,162.09L420.01,155.57L405.31,186.58L374.79,194.51L367.55,199.2L385.88,229.28L450.75,269.73L473.1,227.45L483.95,221.53L492.81,194.42L491.34,171.69L478.43,151.55z"
        />
        <path
          id="IQ-DA"
          title="Dihok"
          className="land"
          d="M326.89,0L294.65,32.09L305.34,41.18L305.04,53.62L332.76,69.81L360.72,61.1L371.73,45.12L398.53,56.15L405.01,44.89L398.57,34.13L413.17,37.22L435.25,17.16L432.84,6.28L411.64,17.98L326.89,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Iraq))
