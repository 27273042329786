import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 833

const cities = {
  Freetown: { x: 22, y: 421 }
}

const SierraLeone = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map sierra-leone">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="SL-E"
          title="Eastern"
          className="land"
          d="M717.48,255.19L662.14,281.25L586.61,249.52L519.64,324.98L515.85,392.34L455.97,398.09L520.65,513.2L491.96,568.24L498.93,610.21L471.78,619.29L480.44,651.39L515.03,637.03L515.31,688.94L571.37,708.85L711.39,595.69L711.12,527.96L734.14,500.75L789.27,485.02L799.51,404.36L771.05,403.17L727.49,450.59L679.6,457.16L746.68,354.08L715.49,315.81L717.48,255.19z"
        />
        <path
          id="SL-N"
          title="Northern"
          className="land"
          d="M368.65,0L365.56,18.89L307.45,31.61L231.84,25.5L142.6,173.14L91.32,193.32L90.99,218.68L49.5,254.57L0.49,255.23L9.52,279.19L60.3,282.67L45.68,302.77L83.96,304.63L16.6,313.01L37.19,395.53L51.73,378.57L38.44,354.49L73.11,359.81L63.81,377.98L113.03,350.67L98.4,378.08L114.67,381.74L49.45,407.48L68.94,433.59L106.52,460.57L131.33,434L225.01,463.23L289.34,441.39L334.58,468.34L334.58,438.14L390.96,440L419.22,405.85L455.97,398.09L515.85,392.34L519.64,324.98L586.61,249.52L662.14,281.25L717.48,255.19L676.68,243.89L695.52,184.19L654.07,161.21L556.71,2.78L368.65,0z"
        />
        <path
          id="SL-W"
          title="Western"
          className="land"
          d="M68.94,433.59L4.28,400.19L36.79,488.18L86.47,468.08L106.52,460.57L68.94,433.59z"
        />
        <path
          id="SL-S"
          title="Southern"
          className="land"
          d="M187.15,637.43L92.98,649.37L184.58,673.23L203.96,696.23L214.37,649.37L187.15,637.43zM483.5,822.43L525.58,743.98L571.37,708.85L515.31,688.94L515.03,637.03L480.44,651.39L471.78,619.29L498.93,610.21L491.96,568.24L520.65,513.2L455.97,398.09L419.22,405.85L390.96,440L334.58,438.14L334.58,468.34L289.34,441.39L225.01,463.23L131.33,434L106.52,460.57L86.47,468.08L127.48,556.04L91.01,557.71L113.03,579.75L185.55,618.46L219.89,590.71L200.89,626.53L228.12,653.2L296.2,640.11L231.49,658.56L215.37,682.2L247.07,696.94L210.78,696.94L387.32,761.2L483.5,822.43z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(SierraLeone))
