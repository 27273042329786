import React from 'react'
import EasyMediumHardNavigationPage from '../../../src/components/EasyMediumHardNavigationPage'

const WordsReadingPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'reading', 'easy', 'medium', 'hard']}
    pageName="words_reading_page"
    customNavigationPath="/reading/read-words"
  />
)

export default WordsReadingPage
