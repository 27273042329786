import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 909

const cities = {
  Amman: { x: 174, y: 314 }
}

const Jordan = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map jordan">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="JO-AZ"
          title="Zarqa"
          className="land"
          d="M517.9,365.75L273.53,275.14L199.29,270.86L192.06,257.2L174.61,279.15L182.95,289.97L203.8,310.55L272.62,299.16L292.15,320.78L280.06,335.42L285.92,345.51L306.45,360.33L325.21,354.76L377.41,407.94L517.9,365.75z"
        />
        <path
          id="JO-KA"
          title="Karak"
          className="land"
          d="M160.28,428.41L93.88,422.46L82.57,460.35L90.45,493.46L73.63,532.33L90.1,540.72L100.06,516.45L133.04,522.2L198.15,565.69L229.09,495.38L221.7,462.29L166.02,460.94L160.28,428.41z"
        />
        <path
          id="JO-MD"
          title="Madaba"
          className="land"
          d="M160.28,428.41L173.07,405.58L158.89,375.82L163.99,346.69L140.1,348.75L102.22,368.07L93.88,422.46L160.28,428.41z"
        />
        <path
          id="JO-BA"
          title="Balqa"
          className="land"
          d="M126.71,259.99L112.73,258.57L112.7,350.77L102.22,368.07L140.1,348.75L132.81,324.65L182.95,289.97L174.61,279.15L137.61,266L126.71,259.99z"
        />
        <path
          id="JO-JA"
          title="Jarash"
          className="land"
          d="M196.94,224.71L176.35,226.25L137.61,266L174.61,279.15L192.06,257.2L184.21,242.88L196.94,224.71z"
        />
        <path
          id="JO-AJ"
          title="Ajlun"
          className="land"
          d="M176.35,226.25L165.82,212.49L129.16,222.66L126.71,259.99L137.61,266L176.35,226.25z"
        />
        <path
          id="JO-IR"
          title="Irbid"
          className="land"
          d="M208.85,187.58L174.59,144.54L151.4,136.93L119.03,153.92L112.73,258.57L126.71,259.99L129.16,222.66L165.82,212.49L176.35,226.25L196.94,224.71L196.54,210.44L209.83,211.13L208.85,187.58z"
        />
        <path
          id="JO-MN"
          title="Ma`an"
          className="land"
          d="M206.56,898.62L281.57,836.43L327.88,760.41L464.31,730.47L496.95,658.89L558.38,623.05L418.59,462.58L221.7,462.29L229.09,495.38L198.15,565.69L177.44,591.87L92.21,599.62L64.8,723.56L114.82,752.48L145.98,891.93L206.56,898.62z"
        />
        <path
          id="JO-AT"
          title="Tafilah"
          className="land"
          d="M198.15,565.69L133.04,522.2L100.06,516.45L90.1,540.72L73.63,532.33L58.32,575.77L92.21,599.62L177.44,591.87L198.15,565.69z"
        />
        <path
          id="JO-AM"
          title="Amman"
          className="land"
          d="M377.41,407.94L325.21,354.76L306.45,360.33L285.92,345.51L280.06,335.42L292.15,320.78L272.62,299.16L203.8,310.55L182.95,289.97L132.81,324.65L140.1,348.75L163.99,346.69L158.89,375.82L173.07,405.58L160.28,428.41L166.02,460.94L221.7,462.29L418.59,462.58L377.41,407.94z"
        />
        <path
          id="JO-MA"
          title="Mafraq"
          className="land"
          d="M704.33,0L344.55,231.06L265.15,217.44L226.41,185.16L208.85,187.58L209.83,211.13L196.54,210.44L196.94,224.71L184.21,242.88L192.06,257.2L199.29,270.86L273.53,275.14L517.9,365.75L739.1,301.15L799.56,246.79L789.21,223.2L751.12,228.54L741.81,196.58L756.35,191.87L704.33,0z"
        />
        <path
          id="JO-AQ"
          title="Aqaba"
          className="land"
          d="M92.21,599.62L58.32,575.77L35.52,637.71L36.49,703.08L2.37,817.06L0.44,866.62L145.98,891.93L114.82,752.48L64.8,723.56L92.21,599.62z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Jordan))
