import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 808
const height = 356

const cities = {
  Honiara: { x: 272, y: 173 }
}

const SolomonIslands = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map solomon-islands">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="SB-CT"
          title="Capital Territory (Honiara)"
          className="land"
          d="M266.66,171.23L271.59,173.33L271.95,170.58L266.66,171.23z"
        />
        <path
          id="SB-MK"
          title="Makira"
          className="land"
          d="M376.34,222.65L373.95,218.28L374.33,223.02L376.34,222.65zM350.29,218.4L347.66,219.44L347.25,225.42L360.79,227.91L359.56,234.19L361.83,233.98L362.84,236.27L361.61,240.05L364.51,239.6L367.67,243.73L375.03,247.28L377.64,250.46L380.53,251.33L380.91,250.05L383.6,252.58L390.76,252.58L396.73,255.87L402.49,254.92L413.55,256.8L413.18,255.57L406.37,255.31L407.33,247.84L403.8,244.96L396.89,233.49L390.64,234.83L387.9,232.52L380.24,232.91L360.27,220.96L353.9,218.12L350.29,218.4z"
        />
        <path
          id="SB-CH"
          title="Choiseul"
          className="land"
          d="M121.04,47.54L119.03,48.82L124.69,50.06L123.46,51.26L125.99,50.06L127.62,51.26L127.18,48.36L121.04,47.54zM137.86,51.71L137.04,48.59L133.78,47.14L131.19,51.02L133.57,52.53L137.86,51.71zM59.86,0L57.41,2.52L58.26,7.86L71.79,17.75L86.21,36.56L91.35,39.16L91.42,41.02L93.95,40.16L97.63,44.63L102.58,44.28L107.11,46.34L109.53,44.28L110.04,47.61L117.38,50.06L118.91,47.18L122.08,46.35L121.18,42.08L113.69,43.45L108.68,40.04L107.29,36.78L102.98,33.9L101.76,34.75L98.7,29.15L97.76,22.79L93.1,17.75L77.38,10.43L69.16,3.14L65.47,2.82L63.46,0.46L59.86,0z"
        />
        <path
          id="SB-WE"
          title="Western"
          className="land"
          d="M125.92,127.43L123.47,126.29L117.64,128.09L129.42,131.99L125.92,127.43zM162.62,134.71L165.83,131.96L164.16,131.29L163.34,125.5L159.66,131.87L162.62,134.71zM151.25,117.41L151.03,114.72L148.13,115.96L147.76,119.3L144.44,118.43L143.22,122.61L143.51,126.06L150.39,130.85L156.78,126.23L155.94,123.44L157.57,121.79L154.34,121.37L158.54,118.7L156.61,116.08L151.25,117.41zM113.2,118.85L115.33,114.53L112.47,109.37L109.55,110.52L102.58,118.64L107.19,121.83L109.25,126.54L114.4,128.62L115.33,128.03L110.27,123.68L110.79,119.3L113.2,118.85zM99.68,104.34L96.58,96.7L91.9,96.05L99.68,104.34zM96.4,94L102.98,101.86L101.76,95.62L96.4,94zM79.19,87.73L77.7,89.6L81.03,91.22L79.19,87.73zM128.64,95.84L127.08,93.84L126.82,84.84L120.65,81.95L117.93,82.78L112.9,87.08L109.53,95.62L104.01,97.56L104.21,103.55L108.34,104.79L111.2,101.03L123.47,100.2L128.42,107.89L127.39,109.34L125.47,107.78L124.37,103.96L125.15,110.79L126.82,112.68L135.81,116.36L139.13,121.79L138.31,118.06L143.63,119.67L144.89,113.51L140.77,104.79L135.22,98.22L128.86,98.96L128.64,95.84zM66.86,95.94L66.96,86.84L63.57,80.71L62.89,89.92L65.28,95.7L66.86,95.94zM101.58,90.84L102.58,84.43L100.75,78.93L96,75.34L90.66,76.83L87.37,83.15L90.68,89.64L93.79,92.19L98.04,91.94L99.3,93.37L101.58,90.84zM63.91,73.33L62.77,71.75L61.6,73.38L63.91,73.33zM67.25,58.35L62.43,59.74L60.41,64.73L67.46,72.59L67.29,76.59L70.11,77.36L70.15,80.71L72.61,81.08L73.81,80.26L72.81,77.43L79.44,68.33L73.83,66.26L67.25,58.35zM4.89,43.45L3.21,43.45L1.16,46.64L6.51,47.08L7.76,45.93L4.89,43.45zM19.65,25.2L14.75,21.91L11.03,29.36L14.75,31.46L21.7,29.95L22.72,26.88L19.65,25.2zM36.78,16.6L34.45,15.48L36.5,12.8L33.61,13.8L34.05,16.23L35.85,18.02L33.19,22.77L35.23,21.91L35.64,23.14L37.32,19.81L39.77,19.81L36.78,16.6z"
        />
        <path
          id="SB-TE"
          title="Temotu"
          className="land"
          d="M798.59,345.23L798.84,344.15L797.34,345.04L798.59,345.23zM687.43,306.37L688.73,304.17L686.3,304.84L687.43,306.37zM681.84,302.61L677.98,302.77L675.69,305.34L680.23,310.22L688.68,310.01L688.34,307.6L685.08,307.83L685.77,303.83L681.84,302.61zM663.56,282.5L661.18,279.39L658.34,283.12L660.23,282.56L660.67,285.64L663.56,282.5zM633.39,245.53L622.42,245.7L619.06,252.12L617.3,248.89L614.93,251.5L615.96,256.61L620.16,255.32L621.46,258.61L625.78,252.95L632.99,252.4L637.69,249.84L638.28,246.46L633.39,245.53zM644.25,219.14L643.94,224.64L646.5,222.32L644.25,222.07L644.25,219.14z"
        />
        <path
          id="SB-IS"
          title="Isabel"
          className="land"
          d="M249.81,113.59L248.46,107.64L243.81,107.95L243.3,112.88L251.07,117.08L252.32,115.77L249.81,113.59zM175.77,58.97L169.44,58.95L172.15,62.47L175.68,62.84L174.83,60.82L181.82,63.71L180.44,61.19L175.77,58.97zM186.15,59.34L177.11,56.78L185.5,64.34L185.5,69.52L192.6,73.67L196.57,78.69L200.3,79.42L201.12,82.77L206.61,85.22L207.25,87.35L208.93,86.5L210.96,89.42L215.51,89.8L215.88,91.49L223.16,95.6L226.96,96.07L231.05,100.2L247.94,106.03L259.79,117.2L264.3,117.72L259.36,109.81L257.18,110.13L255.75,108.45L258.54,107.76L260.81,104.09L236.22,86.29L234.48,83.31L228.41,83.6L222.89,80.48L222.84,79.05L215.49,78.55L213.77,75.91L209.59,74.68L209.75,72.42L202.71,68.28L195.49,59.8L193.28,60.82L189.63,57.86L186.15,59.34zM168.76,53.09L166.29,52.75L175.68,57.86L175.42,55.05L168.76,53.09zM166.59,47.54L162.09,50.43L165.37,52.53L165.81,50.43L167,51.26L166.59,47.54z"
        />
        <path
          id="SB-GU"
          title="Guadalcanal"
          className="land"
          d="M266.66,171.23L252.84,159.99L247.26,162.88L245.83,166.9L247.94,174.58L246.41,176.64L253.7,187.88L260.42,193.17L263.51,192.49L272.14,194.99L289.46,194.46L296.2,198.96L308.9,201.88L319.68,197.63L317.32,189.13L310.59,187.34L307.92,180.31L300.44,177.51L292.22,170.01L271.95,170.58L271.59,173.33L266.66,171.23z"
        />
        <path
          id="SB-CE"
          title="Central"
          className="land"
          d="M260.25,151.25L258.55,152.85L260.02,155.43L260.25,151.25zM294.35,153.7L289.14,148.7L287.4,153.39L283.22,155.43L285.33,156.57L288.54,154.66L292.22,156.26L294.35,153.7zM223.37,149.52L224.5,146.36L222.05,148.39L220.79,153.36L224.33,151.09L223.37,149.52zM279.53,144.62L278.72,146.36L275.01,147.35L276.64,150.04L281.5,149.92L286.11,152.94L287.95,147.35L285.72,145.51L283.63,146.36L279.53,144.62zM218.37,144.25L215.88,144.62L217.1,146.73L212.82,148.43L214.24,151.25L216.33,152.11L220.04,150.42L222.05,146.36L219.6,146.73L218.37,144.25zM277.09,143.4L274.92,141.51L274.26,145.78L277.09,143.4z"
        />
        <path
          id="SB-RB"
          title="Rennell and Bellona"
          className="land"
          d="M273.41,296.88L270.46,294.8L268.08,298.43L271.69,303.33L275.21,302.81L280.36,306.56L286.49,305.31L287.39,308.61L291.84,310.44L293.35,314.53L299.22,318.28L305.15,315.21L293.87,306.82L273.41,296.88z"
        />
        <path
          id="SB-ML"
          title="Malaita"
          className="land"
          d="M388.76,186.65L385.8,188.68L388.76,196.64L388.76,186.65zM355.72,169.95L350.97,165.83L354.69,173.24L354.19,185.64L357.07,189.58L359.43,186.98L363.08,193.09L364.51,190.42L363.24,182.78L355.72,169.95zM318.22,106.1L313.08,102.66L311.96,103.75L313.81,104.98L312.99,106.32L308.52,104.34L305.08,104.99L313.4,117.89L309.48,120.96L317.91,146.13L323.58,155.57L328.37,158.33L332.58,163.97L340.91,168.13L348.11,180.21L353.33,182.7L353.01,175.86L344.41,162.67L346.88,162.51L344.81,157.41L347.25,156.26L342.31,153.36L343.88,151.25L341.64,150.02L340.67,145.66L338.74,144.27L340.3,143.41L339.23,141.9L333.72,138.82L331.55,135.3L328.74,135.88L326.98,133.66L326.73,126.29L330.34,124.49L330.57,122.09L318.22,106.1z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(SolomonIslands))
