import { combineReducers } from 'redux'
import appReducer from './appReducer'
import learnReducer from './learnReducer'
import wordsReducer from './wordsReducer'
import geographyReducer from './geographyReducer'
import mathematicsReducer from './mathematicsReducer'
import readingReducer from './readingReducer'

const reducer = combineReducers({
  app: appReducer,
  learn: learnReducer,
  words: wordsReducer,
  geography: geographyReducer,
  mathematics: mathematicsReducer,
  reading: readingReducer
})

export default reducer
