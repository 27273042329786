// import React, { lazy } from 'react'
import React from 'react'

const sharedProps = {
  r: '25%',
  cx: '50%',
  cy: '50%'
}

const sharedBlackCircleProps = {
  r: '0.4%',
  cx: '50%',
  cy: '50%'
}

const sharedTextProps = {
  fontFamily: 'sans-serif',
  fontWeight: 'bold',
  fontSize: '6',
  style: { stroke: 'black', fill: 'white', strokeWidth: '0.2' }
}

const getStyle = (num, type, color, time, halfOpacity) => {
  const styleObj = time
    ? { animation: `${type === 'result' ? 'fadeInHalf' : 'fadeInFull'} .5s forwards ${time}s` }
    : { opacity: halfOpacity ? 0.5 : 1 } //

  return {
    className: `${num} ${type}-area ${num === 'two' ? 'eleventh' : 'tenth'}`,
    style: {
      stroke: color,
      ...styleObj
    }
  }
}

const showResultAt = time => {
  const styleObj = time ? { animation: `fadeInFull .5s forwards ${time}s` } : { opacity: 1 }

  return {
    className: 'result-area',
    style: {
      stroke: 'black',
      fill: 'white',
      strokeWidth: '0.2',
      ...styleObj
    }
  }
}

const LearnCircle = ({
  number,
  timeColorOne,
  timeColorTwo,
  timeColorThree,
  timeColorFour,
  timeColorFive,
  timeColorSix,
  timeColorSeven,
  timeColorEight,
  timeColorNine,
  timeColorTen,
  timeOne,
  timeTwo,
  resultTwo,
  timeThree,
  resultThree,
  timeFour,
  resultFour,
  timeFive,
  resultFive,
  timeSix,
  resultSix,
  timeSeven,
  resultSeven,
  timeEight,
  resultEight,
  timeNine,
  resultNine,
  timeTen,
  resultTen
}) => (
  <svg viewBox="0 0 64 64" className="view-box-circle-wrapper">
    <circle className="outter-color-circle" {...sharedProps} />
    <circle className="inner-color-circle" {...sharedProps} />

    <circle {...sharedProps} {...getStyle('one', 'result', 'orange', timeColorOne, resultTen)} />
    <circle {...sharedProps} {...getStyle('one', 'timer', 'orange', timeColorOne)} />
    <circle {...sharedProps} {...getStyle('two', 'result', 'gold', timeColorTwo, resultTen)} />
    <circle {...sharedProps} {...getStyle('two', 'timer', 'gold', timeColorTwo)} />
    <circle
      {...sharedProps}
      {...getStyle('three', 'result', 'yellow', timeColorThree, resultTen)}
    />
    <circle {...sharedProps} {...getStyle('three', 'timer', 'yellow', timeColorThree)} />
    <circle
      {...sharedProps}
      {...getStyle('four', 'result', 'greenyellow', timeColorFour, resultTen)}
    />
    <circle {...sharedProps} {...getStyle('four', 'timer', 'greenyellow', timeColorFour)} />
    <circle {...sharedProps} {...getStyle('five', 'result', 'green', timeColorFive, resultTen)} />
    <circle {...sharedProps} {...getStyle('five', 'timer', 'green', timeColorFive)} />
    {timeColorSix && (
      <circle {...sharedProps} {...getStyle('six', 'result', 'turquoise', timeColorSix)} />
    )}
    {timeColorSix && (
      <circle {...sharedProps} {...getStyle('six', 'timer', 'turquoise', timeColorSix)} />
    )}
    {timeColorSeven && (
      <circle {...sharedProps} {...getStyle('seven', 'result', 'blue', timeColorSeven)} />
    )}
    {timeColorSeven && (
      <circle {...sharedProps} {...getStyle('seven', 'timer', 'blue', timeColorSeven)} />
    )}
    {timeColorEight && (
      <circle {...sharedProps} {...getStyle('eight', 'result', 'purple', timeColorEight)} />
    )}
    {timeColorEight && (
      <circle {...sharedProps} {...getStyle('eight', 'timer', 'purple', timeColorEight)} />
    )}
    {timeColorNine && (
      <circle {...sharedProps} {...getStyle('nine', 'result', 'fuchsia', timeColorNine)} />
    )}
    {timeColorNine && (
      <circle {...sharedProps} {...getStyle('nine', 'timer', 'fuchsia', timeColorNine)} />
    )}
    {timeColorTen && (
      <circle {...sharedProps} {...getStyle('ten', 'result', 'red', timeColorTen)} />
    )}
    {timeColorTen && <circle {...sharedProps} {...getStyle('ten', 'timer', 'red', timeColorTen)} />}

    <circle {...sharedProps} r="9%" className="number-area" style={{ stroke: 'white' }} />
    <circle className="black-circle large-circle" {...sharedBlackCircleProps} />
    <circle className="black-circle middle-circle" {...sharedBlackCircleProps} />
    <circle className="black-circle small-circle" {...sharedBlackCircleProps} />

    <text
      x={number === '10' ? '25' : '28.75'}
      y="36"
      {...sharedTextProps}
      fontSize="12"
      style={{ fill: 'black' }}>
      {number}
    </text>

    <text x="39.5" y="21" {...sharedTextProps}>
      1
    </text>
    <text x="45.25" y="29" {...sharedTextProps}>
      2
    </text>
    <text x="45.25" y="39" {...sharedTextProps}>
      3
    </text>
    <text x="39.5" y="46.5" {...sharedTextProps}>
      4
    </text>
    <text x="30.5" y="49.5" {...sharedTextProps}>
      5
    </text>
    <text x="21.25" y="46.5" {...sharedTextProps}>
      6
    </text>
    <text x="15.5" y="39" {...sharedTextProps}>
      7
    </text>
    <text x="15.5" y="29" {...sharedTextProps}>
      8
    </text>
    <text x="21" y="21" {...sharedTextProps}>
      9
    </text>
    <text x="28.25" y="18" {...sharedTextProps}>
      10
    </text>

    <text
      x={number.length === 2 ? '43' : '44.5'}
      y="13.5"
      {...sharedTextProps}
      {...showResultAt(timeOne)}
      fontSize="7.5">
      {number}
    </text>
    <text
      x={resultTwo.length === 2 ? '53' : '54.5'}
      y="26.5"
      {...sharedTextProps}
      {...showResultAt(timeTwo)}
      fontSize="7.5">
      {resultTwo}
    </text>
    <text
      x={resultThree.length === 2 ? '53' : '54.5'}
      y="42.5"
      {...sharedTextProps}
      {...showResultAt(timeThree)}
      fontSize="7.5">
      {resultThree}
    </text>
    <text
      x={resultFour.length === 2 ? '44' : '45.5'}
      y="55"
      {...sharedTextProps}
      {...showResultAt(timeFour)}
      fontSize="7.5">
      {resultFour}
    </text>
    <text
      x={resultFive.length === 2 ? '28.5' : '30'}
      y="60.5"
      {...sharedTextProps}
      {...showResultAt(timeFive)}
      fontSize="7.5">
      {resultFive}
    </text>
    {resultSix && (
      <text
        x={resultSix.length === 2 ? '12.75' : '14.5'}
        y="56"
        {...sharedTextProps}
        {...showResultAt(timeSix)}
        fontSize="7.5">
        {resultSix}
      </text>
    )}
    {resultSeven && (
      <text
        x={resultSeven.length === 2 ? '3.25' : '5.25'}
        y="42.5"
        {...sharedTextProps}
        {...showResultAt(timeSeven)}
        fontSize="7.5">
        {resultSeven}
      </text>
    )}
    {resultEight && (
      <text
        x={resultEight.length === 2 ? '2.75' : '4.75'}
        y="27.25"
        {...sharedTextProps}
        {...showResultAt(timeEight)}
        fontSize="7.5">
        {resultEight}
      </text>
    )}
    {resultNine && (
      <text
        x={resultNine.length === 2 ? '12' : '14'}
        y="13.5"
        {...sharedTextProps}
        {...showResultAt(timeNine)}
        fontSize="7.5">
        {resultNine}
      </text>
    )}
    {resultTen && (
      <text
        x={resultTen.length === 2 ? '27.25' : '25'}
        y="8"
        {...sharedTextProps}
        {...showResultAt(timeTen)}
        fontSize="7.5">
        {resultTen}
      </text>
    )}
  </svg>
)

export default LearnCircle
