import actions from '../actions/action-types'
import {
  generateMultipleImagesRandomIndex,
  getRandomNotAlreadySeenItemFromList
} from '../../lib/methodsUtil'
import { colorsList } from '../../pages/words/colors/util'
import {
  animalsList,
  answerQuestionImageIndexLimitMap as animalsRandom
} from '../../pages/words/animals/util'
import {
  foodList,
  answerQuestionImageIndexLimitMap as foodRandom
} from '../../pages/words/food/util'
import { lettersAndNumbersList } from '../../pages/words/letters-and-numbers/util'

const initialState = {
  actual: '',
  seenList: [],
  randomNumber: 0
}

const pageList = {
  play_colors_page: colorsList,
  play_animals_page: animalsList,
  play_food_page: foodList,
  play_letters_and_numbers_page: lettersAndNumbersList
}

const getRandomImageIndexLimitMap = {
  play_animals_page: animalsRandom,
  play_food_page: foodRandom
}

const wordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.randomizeAWord: {
      let seenList = state.seenList
      const pageName = action.payload
      const answersList = pageList[pageName]

      const haveAllItemsBeenSeen = seenList.length === answersList.length
      if (haveAllItemsBeenSeen) {
        seenList = []
      }

      const actual = getRandomNotAlreadySeenItemFromList(answersList, seenList)
      seenList.push(actual)

      const randomNumber = generateMultipleImagesRandomIndex(
        pageName,
        actual,
        getRandomImageIndexLimitMap
      )

      return {
        ...state,
        actual,
        seenList,
        randomNumber
      }
    }

    case actions.resetWords: {
      // seenList has to be reset fully because seenList.push that is used earlier modify the original array.
      return { ...initialState, seenList: [] }
    }

    default:
      return state
  }
}

export default wordsReducer
