import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 622
const height = 647

const cities = {
  Basseterre: { x: 266, y: 396 }
}

const SaintKittsNevis = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map saint-kitts-nevis">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 150 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="KN-01"
          title="Christ Church Nichola Town"
          className="land"
          d="M232.202,236.789L228.617,245.773L209.934,263.428L209.359,273.421L195.61,287.611L197.858,318.385L197.858,318.385L183.405,316.269L183.405,316.269L183.405,316.269L183.405,316.269L146.928,302.129L143.603,293.067L132.209,279.746L132.209,279.746L164.032,240.574L167.113,225.478L186.738,209.553L186.738,209.553L202.958,224.258L208.188,222.631L209.754,227.531L216.032,230.793L222.311,229.708L227.54,237.881z"
        />
        <path
          id="KN-02"
          title="Saint Anne Sandy Point"
          className="land"
          d="M102.061,246.899L81.079,264.542L70.928,264.542L54.78,283.216L44.329,290.902L41.11,298.887L41.11,298.887L30.867,292.895L30.867,280.916L20.925,265.114L0.524,249.869L0.524,216.088L4.188,209.415L4.188,209.415L25.113,219.59L28.108,210.47L34.768,211.487L35.292,224.576L46.972,225.593L58.652,234.092L101.472,244.928L101.472,244.928z"
        />
        <path
          id="KN-03"
          title="Saint George Basseterre"
          className="land"
          d="M392.312,559.677L383.943,552.057L387.082,546.07L382.895,537.908L383.943,531.921L377.665,530.833L375.05,527.021L375.05,522.121L386.033,513.962L389.697,506.338L384.468,492.183L390.221,484.562L384.468,473.131L383.943,464.417L372.436,449.177L350.461,436.111L341.051,436.111L334.772,431.21L331.634,424.68L306.003,423.046L299.726,418.69L295.544,411.609L289.791,415.965L293.447,409.434L289.267,407.254L291.881,401.265L288.742,399.631L271.48,398.542L272.005,403.986L267.817,405.078L261.539,403.986L258.399,400.177L253.17,400.722L244.277,411.609L241.138,406.162L230.679,408.888L225.974,407.254L225.974,402.898L222.131,402.356L222.131,402.356L221.894,391.472L209.502,363.108L215.493,353.405L211.42,350.215L206.586,337.165L206.586,337.165L216.011,341.316L217.914,350.575L222.899,355.106L231.728,354.048L235.83,360.162L242.05,357.122L261.905,359.107L263.27,367.936L282.837,369.573L282.995,374.075L289.748,380.237L293.526,389.536L303.504,385.457L314.523,393.824L319.041,401.369L332.094,403.437L335.714,398.838L341.051,397.69L341.051,397.69L341.568,415.965L346.805,419.782L348.896,416.511L351.51,417.057L359.879,431.21L371.387,438.833L378.714,439.925L381.328,444.821L388.124,446.455L392.312,452.99L391.787,458.979L395.975,462.242L401.204,459.517L408,469.325L413.236,469.325L417.941,478.031L418.466,485.652L424.744,486.736L431.016,474.219L437.818,481.298L451.416,485.107L449.326,489.462L452.465,490.553L458.743,503.616L454.031,510.691L443.572,517.225L444.621,522.667L446.711,521.579L449.851,525.388L447.235,532.463L439.384,532.463L437.294,525.934L433.63,524.846L428.4,527.566L427.359,531.375L422.646,532.463L419.508,541.716L412.188,531.375L406.958,531.375L403.295,537.362L399.632,537.362L400.68,555.323z"
        />
        <path
          id="KN-04"
          title="Saint George Gingerland"
          className="land"
          d="M611.476,697.896L610.958,703.877L607.295,705.51L610.958,721.826L606.771,720.739L606.246,725.091L601.541,728.904L601.541,737.059L596.312,746.853L597.354,751.753L586.37,769.154L579.05,770.241L573.296,765.345L565.969,765.89L558.125,771.328L540.568,774.051L540.568,774.051L528.903,674.638L528.903,674.638L528.903,674.638L528.903,674.638L528.903,674.638L528.903,674.638L564.727,677.716L573.806,683.466L589.337,685.03L596.979,692.192L604.005,692.383z"
        />
        <path
          id="KN-05"
          title="Saint James Windward"
          className="land"
          d="M471.293,589.606L477.047,582.536L473.907,576.554L478.619,576.004L482.275,571.646L497.972,574.375L515.233,567.847L518.372,571.108L536.158,568.385L545.051,572.741L552.371,583.623L557.076,586.344L558.649,591.785L582.706,593.959L582.189,601.04L597.878,615.732L602.59,624.984L602.59,647.832L607.819,663.071L606.246,674.492L607.295,683.204L612,689.73L611.476,697.896L611.476,697.896L604.005,692.383L596.979,692.192L589.337,685.03L573.806,683.466L564.727,677.716L528.903,674.638L528.903,674.638L528.91,674.357L528.91,674.357L497.023,618.871z"
        />
        <path
          id="KN-06"
          title="Saint John Capisterre"
          className="land"
          d="M186.738,209.553L167.113,225.478L164.032,240.574L132.209,279.746L132.209,279.746L102.061,246.899L102.061,246.899L101.472,244.928L101.472,244.928L97.5,237.211L89.569,236.276L75.418,222.163L87.824,186.588L112.462,167.602L112.462,167.602L115.602,175.776L119.265,177.958L132.339,171.96L137.576,177.958L140.19,175.776L141.232,179.042L154.313,183.947L158.494,182.316L177.328,204.099z"
        />
        <path
          id="KN-07"
          title="Saint John Figtree"
          className="land"
          d="M540.568,774.051L535.109,777.853L528.307,777.312L525.692,781.121L522.036,780.579L517.323,787.649L504.774,788.19L500.586,791.458L492.218,792L479.144,786.559L473.907,777.853L463.448,767.522L460.31,767.522L459.785,764.258L449.851,763.716L454.556,759.906L455.08,752.832L446.187,740.869L442.006,727.814L435.728,722.371L434.679,714.213L439.908,713.668L439.908,713.668L459.605,713.784L483.411,705.969L496.448,704.837L528.903,674.638L528.903,674.638z"
        />
        <path
          id="KN-08"
          title="Saint Mary Cayon"
          className="land"
          d="M292.923,308.583L270.482,317.219L257.372,314.478L235.427,314.747L222.727,311.085L206.988,322.271L206.988,322.271L197.858,318.385L197.858,318.385L195.61,287.611L209.359,273.421L209.934,263.428L228.617,245.773L232.202,236.789L232.202,236.789L234.867,235.7L235.384,244.416L238.006,247.139L243.235,246.596L248.465,255.857L259.973,264.572L265.202,276.005L268.866,274.921L268.866,281.455L272.529,287.992z"
        />
        <path
          id="KN-09"
          title="Saint Paul Capisterre"
          className="land"
          d="M101.472,244.928L58.652,234.092L46.972,225.593L35.292,224.576L34.768,211.487L28.108,210.47L25.113,219.59L4.188,209.415L4.188,209.415L5.754,205.738L9.417,205.195L9.417,198.11L17.262,195.937L22.498,191.025L27.728,179.042L36.096,176.857L38.711,169.241L43.416,170.868L52.309,164.875L64.341,168.145L74.8,164.332L77.422,158.339L88.93,155.615L92.586,158.339L102.528,156.157L112.462,167.602L112.462,167.602L87.824,186.588L75.418,222.163L89.569,236.276L97.5,237.211z"
        />
        <path
          id="KN-10"
          title="Saint Paul Charlestown"
          className="land"
          d="M439.908,713.668L441.481,692.711L441.481,692.711L481.694,696.365L494.186,693.257L528.91,674.357L528.91,674.357L528.903,674.638L528.903,674.638L528.903,674.638L528.903,674.638L496.448,704.837L483.411,705.969L459.605,713.784z"
        />
        <path
          id="KN-11"
          title="Saint Peter Basseterre"
          className="land"
          d="M292.923,308.583L296.062,314.679L303.913,318.494L303.389,323.938L311.233,345.179L327.446,360.966L324.831,376.218L330.067,381.662L340.002,382.753L337.388,390.919L340.526,392.553L341.051,397.69L341.051,397.69L335.714,398.838L332.094,403.437L319.041,401.369L314.523,393.824L303.504,385.457L293.526,389.536L289.748,380.237L282.995,374.075L282.837,369.573L263.27,367.936L261.905,359.107L242.05,357.122L235.83,360.162L231.728,354.048L222.899,355.106L217.914,350.575L216.011,341.316L206.586,337.165L206.586,337.165L206.988,322.271L206.988,322.271L222.727,311.085L235.427,314.747L257.372,314.478L270.482,317.219z"
        />
        <path
          id="KN-12"
          title="Saint Thomas Lowland"
          className="land"
          d="M528.91,674.357L494.186,693.257L481.694,696.365L441.481,692.711L441.481,692.711L445.138,684.833L442.006,666.336L444.621,639.13L452.465,622.261L461.358,612.467L460.834,608.655L463.973,604.848L462.399,599.949L471.293,589.606L471.293,589.606L497.023,618.871z"
        />
        <path
          id="KN-13"
          title="Saint Thomas Middle Island"
          className="land"
          d="M183.405,316.269L166.675,334.342L149.471,342.396L141.656,357.537L131.822,366.26L131.822,366.26L129.724,356.61L122.929,350.077L72.71,328.295L50.743,310.323L46.031,301.063L41.11,298.887L41.11,298.887L44.329,290.902L54.78,283.216L70.928,264.542L81.079,264.542L102.061,246.899L102.061,246.899L132.209,279.746L132.209,279.746L143.603,293.067L146.928,302.129z"
        />
        <path
          id="KN-15"
          title="Trinity Palmetto Point"
          className="land"
          d="M183.405,316.269L197.858,318.385L197.858,318.385L206.988,322.271L206.988,322.271L206.586,337.165L206.586,337.165L211.42,350.215L215.493,353.405L209.502,363.108L221.894,391.472L222.131,402.356L222.131,402.356L196.156,402.898L190.402,398.542L181.509,400.177L174.189,395.275L168.435,396.909L166.862,389.831L161.108,386.563L152.74,384.929L148.035,381.116L141.756,382.753L138.617,373.496L131.822,366.26L131.822,366.26L141.656,357.537L149.471,342.396L166.675,334.342z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(SaintKittsNevis))
