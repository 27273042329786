import React from 'react'
// TODO - uncomment when going back to it
import {
  // FadeIn,
  // FadeOut,
  // Shake,
  // Swing,
  // Jello,
  // Flip,
  // FlipInX,
  // FlipInY,
  // FlipOutX,
  // FlipOutY,
  // BounceOut,
  // Bounce,
  BounceIn,
  Pulse,
  // RollIn,
  // RollOut,
  // RotateIn,
  // RotateOut,
  // RubberBand,
  // Tada,
  // Hinge,
  // Wobble,
  // BounceInDown,
  // BounceInUp,
  // BounceOutDown,
  // BounceOutUp,
  FadeInUp,
  FadeInDown,
  FadeOutUp,
  FadeOutDown
  // RotateInUpRight,
  // RotateOutUpRight,
  // RotateInDownRight,
  // RotateOutDownRight,
  // RotateInDownLeft,
  // RotateOutDownLeft,
  // SlideInLeft,
  // SlideOutRight,
  // SlideOutLeft,
  // SlideInRight
} from '../../../../lib/animationsUtil'
// import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

// TODO - Add Animations - HERE <-- uncomment and all imports too
// not much used 8 & 9

// 457 divided by 19
// const LearnMediumDivisionsPage = () => (
//   <div className="learn-mathematics-animation-container">
//     <div className="learn-math-equation-from-the-right-container">
//       <div className="math-line-small-ten">
//         <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/16/4.webp`} />
//       </div>
//       <div className="math-line-small-ten flex">
//         <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
//       </div>
//       <div className="math-line-small-ten">
//         <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
//       </div>
//       <div className="math-line-small-ten mathematics-question-display">
//         <img
//           className="small-number-with-margins small-number-with-margins-3"
//           src={`${mathsNumbersUrls}/21/by.webp`}
//         />
//         <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
//       </div>
//       <div className="math-line-small-ten flex">
//         <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
//       </div>
//       <div className="math-line-small-ten">
//         <img
//           className="small-number-with-margins small-number-with-margins-4"
//           src={`${mathsNumbersUrls}/20/minus.webp`}
//         />
//         <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
//         <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
//       </div>
//       <div className="math-line-small-ten">
//         <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
//       </div>
//       <div className="math-line-small-ten">
//         <img
//           className="small-number-with-margins small-number-with-margins-4"
//           src={`${mathsNumbersUrls}/20/minus.webp`}
//         />
//         <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
//         <img className="small-number" src={`${mathsNumbersUrls}/20/6.webp`} />
//       </div>
//       <div className="math-line-small-ten flex">
//         <img className="math-line-line-fourteen" src={`${baseUrl}/images/lines/14.webp`} />
//         <img className="arrow arrow-fifty-eight" src={`${baseUrl}/images/arrows/58.webp`} />
//       </div>
//       <div className="math-line-small-ten">
//         <img className="small-number" src={`${mathsNumbersUrls}/26/1.webp`} />
//       </div>
//     </div>
//   </div>
// )

const LearnMediumDivisionsPage = () => (
  // <LearnMathematicsPage
  //   pageName="learn_medium_divisions"
  //   sentencesNumberPlusRedirect={32}
  //   startTimesOf={{
  //     2: 7000,
  //     3: 15500,
  //     4: 20500,
  //     5: 28500,
  //     6: 39500,
  //     7: 47000,
  //     8: 58500,
  //     9: 66500,
  //     10: 75500,
  //     11: 86500,
  //     12: 94500,
  //     13: 99000,
  //     14: 108000,
  //     15: 115000,
  //     16: 125500,
  //     17: 136500,
  //     18: 144000,
  //     19: 149000,
  //     20: 156500,
  //     21: 166500,
  //     22: 175500,
  //     23: 186500,
  //     24: 192000,
  //     25: 201000,
  //     26: 211500,
  //     27: 221000,
  //     28: 229000,
  //     29: 237000,
  //     30: 246000,
  //     31: 254000,
  //     32: 262000
  //   }}
  //   animations={{
  //     1: (
  //       <div className="learn-flex-center-center">
  //         <FlipInX className="position-absolute">
  //           <FlipOutX delay={3}>
  //             <img className="max-100" src={`${baseUrl}/images/learn/md-1-1.webp`} />
  //           </FlipOutX>
  //         </FlipInX>
  //         <BounceInUp delay={3.5} className="position-absolute">
  //           <BounceOutUp delay={6}>
  //             <img className="max-100" src={`${baseUrl}/images/learn/md-1-2.webp`} />
  //           </BounceOutUp>
  //         </BounceInUp>
  //       </div>
  //     ),
  //     2: (
  //       <div className="learn-flex-center-center">
  //         <FlipInY className="position-absolute">
  //           <FlipOutY delay={3.5}>
  //             <img className="max-100" src={`${baseUrl}/images/learn/md-2-1.webp`} />
  //           </FlipOutY>
  //         </FlipInY>
  //         <FadeIn delay={4.5} className="position-absolute">
  //           <SlideInLeft delay={4.5}>
  //             <FadeOut delay={7.5}>
  //               <SlideOutRight delay={7.5}>
  //                 <img className="width-100" src={`${baseUrl}/images/learn/md-2-2.webp`} />
  //               </SlideOutRight>
  //             </FadeOut>
  //           </SlideInLeft>
  //         </FadeIn>
  //       </div>
  //     ),
  //     3: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-six" />
  //         <FlipInX delay={2.5} duration={0.5} className="math-line-small-six">
  //           <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //         </FlipInX>
  //         <FlipInX
  //           delay={3.5}
  //           duration={0.5}
  //           className="math-line-small-six mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/11/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //         </FlipInX>
  //         <div className="math-line-small-six" />
  //         <div className="math-line-small-six" />
  //         <div className="math-line-small-six" />
  //       </div>
  //     ),
  //     4: (
  // <div className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-six" />
  //   <div className="math-line-small-six">
  //     <RubberBand className="small-number" delay={3.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //     </RubberBand>
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <Flip className="height-100" delay={5.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </Flip>
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <FadeIn>
  //       <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     </FadeIn>
  //     <SlideInLeft className="arrow arrow-forty-five" duration={0.5}>
  //       <FadeIn duration={0.5}>
  //         <Shake delay={0.5} duartion={3}>
  //           <SlideOutRight delay={3.5}>
  //             <FadeOut delay={3.5}>
  //               <img src={`${baseUrl}/images/arrows/45.webp`} />
  //             </FadeOut>
  //           </SlideOutRight>
  //         </Shake>
  //       </FadeIn>
  //     </SlideInLeft>
  //   </div>
  //   <div className="math-line-small-six" />
  //   <div className="math-line-small-six" />
  // </div>
  //     ),
  //     5: (
  // <div className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-six" />
  //   <div className="math-line-small-six">
  //     <Jello className="height-100" delay={9}>
  //       <RubberBand className="height-100" delay={8}>
  //         <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //       </RubberBand>
  //     </Jello>
  //     <Jello className="height-100" delay={9}>
  //       <RubberBand className="height-100" delay={7}>
  //         <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //       </RubberBand>
  //     </Jello>
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <Flip className="height-100" delay={10}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </Flip>
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     <SlideInLeft className="arrow arrow-forty-five" delay={4.5} duration={0.5}>
  //       <FadeIn delay={4.5} duration={0.5}>
  //         <Shake delay={5} duartion={2}>
  //           <SlideOutRight delay={7}>
  //             <FadeOut delay={7}>
  //               <img src={`${baseUrl}/images/arrows/45.webp`} />
  //             </FadeOut>
  //           </SlideOutRight>
  //         </Shake>
  //       </FadeIn>
  //     </SlideInLeft>
  //   </div>
  //   <div className="math-line-small-six" />
  //   <div className="math-line-small-six" />
  // </div>
  //     ),
  //     6: (
  // <div className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-six" />
  //   <div className="math-line-small-six">
  //     <Wobble className="height-100" delay={5.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //     </Wobble>
  //     <Wobble className="height-100" delay={5.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //     </Wobble>
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <RubberBand className="height-100" delay={4.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </RubberBand>
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //   </div>
  //   <div className="math-line-small-six" />
  //   <div className="math-line-small-six" />
  // </div>
  //     ),
  //     7: (
  // <>
  //   <div className="learn-math-equation-from-the-right-container learn-math-opacity-on">
  //     <div className="math-line-small-six" />
  //     <div className="math-line-small-six">
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //     </div>
  //     <div className="math-line-small-six mathematics-question-display">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-3"
  //         src={`${mathsNumbersUrls}/11/by.webp`}
  //       />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </div>
  //     <div className="math-line-small-six flex">
  //       <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     </div>
  //     <div className="math-line-small-six" />
  //     <div className="math-line-small-six" />
  //   </div>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={1.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <img className="smaller-division-number" src={`${mathsNumbersUrls}/7/1.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={3.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={2} duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/2.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/8.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={5.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={4} duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/3.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="smaller-division-number" src={`${mathsNumbersUrls}/7/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/2.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={7.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={6} duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="smaller-division-number" src={`${mathsNumbersUrls}/7/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/6.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={11}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in-between" delay={8} duration={0.5}>
  //       <img className="division-comparison" src={`${mathsNumbersUrls}/19/less-than.webp`} />
  //       <div className="flex">
  //         <img className="division-comparison" src={`${mathsNumbersUrls}/19/1.webp`} />
  //         <img className="division-comparison" src={`${mathsNumbersUrls}/19/7.webp`} />
  //       </div>
  //     </FadeIn>
  //   </FadeOut>
  // </>
  //     ),
  //     8: (
  // <>
  //   <div className="learn-math-equation-from-the-right-container learn-math-opacity-still-on">
  //     <div className="math-line-small-six" />
  //     <div className="math-line-small-six">
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //     </div>
  //     <div className="math-line-small-six mathematics-question-display">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-3"
  //         src={`${mathsNumbersUrls}/11/by.webp`}
  //       />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </div>
  //     <div className="math-line-small-six flex">
  //       <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     </div>
  //     <div className="math-line-small-six" />
  //     <div className="math-line-small-six" />
  //   </div>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={3}>
  //     <FadeIn className="division-medium-division-container-in" duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/5.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/2.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/0.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={700.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={4} duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <Jello delay={6} className="small-division-number">
  //         <img className="width-100" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       </Jello>
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="smaller-division-number" src={`${mathsNumbersUrls}/7/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/6.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  // </>
  //     ),
  //     9: (
  // <div className="learn-math-equation-from-the-right-container learn-math-opacity-still-on learn-math-opacity-off">
  //   <div className="math-line-small-six flex">
  //     <BounceIn delay={1} duration={0.5}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/18/4.webp`} />
  //     </BounceIn>
  //     <FadeInUp className="arrow arrow-forty-six">
  //       <Pulse delay={1}>
  //         <FadeOutUp delay={3}>
  //           <img className="width-100" src={`${baseUrl}/images/arrows/46.webp`} />
  //         </FadeOutUp>
  //       </Pulse>
  //     </FadeInUp>
  //   </div>
  //   <div className="math-line-small-six">
  //     <Jello delay={7.5} duration={0.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //     </Jello>
  //     <Jello delay={7.5} duration={0.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //     </Jello>
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //   </div>
  //   <FadeIn className="math-line-small-six" delay={5.5} duration={0.5}>
  //     <img
  //       className="small-number-with-margins small-number-with-margins-4"
  //       src={`${mathsNumbersUrls}/10/minus.webp`}
  //     />
  //     <Jello className="small-number-with-small-margins" delay={6.5} duration={0.5}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/10/1.webp`} />
  //     </Jello>
  //     <Jello className="small-number-with-small-margins" delay={6.5} duration={0.5}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/10/6.webp`} />
  //     </Jello>
  //     <img
  //       className="small-number-with-small-margins visibility-hidden"
  //       src={`${mathsNumbersUrls}/16/0.webp`}
  //     />
  //   </FadeIn>
  //   <div className="math-line-small-six" />
  // </div>
  //     ),
  //     10: (
  // <div className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-six flex">
  //     <img src={`${mathsNumbersUrls}/18/4.webp`} />
  //     <RotateInDownLeft className="arrow arrow-forty-seven" delay={7} duration={0.5}>
  //       <RotateOutDownLeft className="height-100" delay={10} duration={0.5}>
  //         <img className="height-100" src={`${baseUrl}/images/arrows/47.webp`} />
  //       </RotateOutDownLeft>
  //     </RotateInDownLeft>
  //   </div>
  //   <div className="math-line-small-six">
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     <BounceInDown className="arrow arrow-forty-eight">
  //       <FadeIn className="height-100">
  //         <Pulse className="height-100" delay={1}>
  //           <FadeOut className="height-100" delay={2.5}>
  //             <BounceOutDown className="height-100" delay={2.5}>
  //               <img className="height-100" src={`${baseUrl}/images/arrows/48.webp`} />
  //             </BounceOutDown>
  //           </FadeOut>
  //         </Pulse>
  //       </FadeIn>
  //     </BounceInDown>
  //   </div>
  //   <div className="math-line-small-six">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-4"
  //       src={`${mathsNumbersUrls}/10/minus.webp`}
  //     />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/6.webp`} />
  //     <img
  //       className="small-number-with-small-margins visibility-hidden"
  //       src={`${mathsNumbersUrls}/16/0.webp`}
  //     />
  //   </div>
  //   <div className="math-line-small-six">
  //     <BounceInDown className="small-number-with-small-margins" delay={2.5}>
  //       <FadeIn className="height-100" delay={2.5}>
  //         <Bounce className="height-100" delay={10}>
  //           <img className="height-100" src={`${mathsNumbersUrls}/10/1.webp`} />
  //         </Bounce>
  //       </FadeIn>
  //     </BounceInDown>
  //     <BounceIn className="small-number-with-small-margins" delay={7.5} duration={0.5}>
  //       <Bounce className="height-100" delay={10}>
  //         <img className="height-100" src={`${mathsNumbersUrls}/10/2.webp`} />
  //       </Bounce>
  //     </BounceIn>
  //   </div>
  // </div>
  //     ),
  //     11: (
  // <>
  //   <div className="learn-math-equation-from-the-right-container learn-math-opacity-on">
  //     <div className="math-line-small-six flex">
  //       <img src={`${mathsNumbersUrls}/18/4.webp`} />
  //     </div>
  //     <div className="math-line-small-six">
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //     </div>
  //     <div className="math-line-small-six mathematics-question-display">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-3"
  //         src={`${mathsNumbersUrls}/11/by.webp`}
  //       />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </div>
  //     <div className="math-line-small-six flex">
  //       <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     </div>
  //     <div className="math-line-small-six">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-4"
  //         src={`${mathsNumbersUrls}/10/minus.webp`}
  //       />
  //       <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //       <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/6.webp`} />
  //       <img
  //         className="small-number-with-small-margins visibility-hidden"
  //         src={`${mathsNumbersUrls}/16/0.webp`}
  //       />
  //     </div>
  //     <div className="math-line-small-six">
  //       <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //       <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/2.webp`} />
  //     </div>
  //   </div>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={7.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" duration={0.5}>
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/4.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/times.webp`} />
  //       <div className="small-division-number position-relative">
  //         <FlipInY delay={0.5} duration={0.5} className="width-100">
  //           <Wobble className="width-100" delay={1.5} duration={2.5}>
  //             <FlipOutY className="width-100" delay={4.5} duration={0.5}>
  //               <img className="width-100" src={`${baseUrl}/images/learn/question-mark.webp`} />
  //             </FlipOutY>
  //           </Wobble>
  //         </FlipInY>
  //         <FlipInY delay={5} duration={0.5} className="learn-medium-division-eleven">
  //           <FlipOutY className="width-100" delay={7} duration={0.5}>
  //             <img className="width-100" src={`${mathsNumbersUrls}/7/3.webp`} />
  //           </FlipOutY>
  //         </FlipInY>
  //       </div>
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/7/equal.webp`} />
  //       <img className="smaller-division-number" src={`${mathsNumbersUrls}/7/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/7/2.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  // </>
  //     ),
  //     12: (
  // <div className="learn-math-equation-from-the-right-container learn-math-opacity-off">
  //   <div className="math-line-small-six flex">
  //     <img src={`${mathsNumbersUrls}/18/4.webp`} />
  //     <BounceIn delay={1} duration={0.5}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/18/3.webp`} />
  //     </BounceIn>
  //   </div>
  //   <div className="math-line-small-six">
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //     <FadeInUp className="arrow arrow-fifty" duration={0.5}>
  //       <Pulse delay={1}>
  //         <FadeOutUp delay={2} duration={0.5}>
  //           <img className="width-100" src={`${baseUrl}/images/arrows/50.webp`} />
  //         </FadeOutUp>
  //       </Pulse>
  //     </FadeInUp>
  //   </div>
  //   <div className="math-line-small-six">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-4"
  //       src={`${mathsNumbersUrls}/10/minus.webp`}
  //     />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/6.webp`} />
  //     <img
  //       className="small-number-with-small-margins visibility-hidden"
  //       src={`${mathsNumbersUrls}/16/0.webp`}
  //     />
  //   </div>
  //   <div className="math-line-small-six">
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/2.webp`} />
  //   </div>
  // </div>
  //     ),
  //     13: (
  // <>
  //   <div className="learn-math-equation-from-the-right-container learn-math-opacity-on learn-math-opacity-off-delayed">
  //     <div className="math-line-small-six flex">
  //       <img src={`${mathsNumbersUrls}/18/4.webp`} />
  //       <RubberBand delay={6}>
  //         <img className="height-100" src={`${mathsNumbersUrls}/18/3.webp`} />
  //       </RubberBand>
  //     </div>
  //     <div className="math-line-small-six">
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/1.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/7.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/11/2.webp`} />
  //     </div>
  //     <div className="math-line-small-six mathematics-question-display">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-3"
  //         src={`${mathsNumbersUrls}/11/by.webp`}
  //       />
  //       <RubberBand className="height-100" delay={5.5}>
  //         <img className="small-number" src={`${mathsNumbersUrls}/11/4.webp`} />
  //       </RubberBand>
  //     </div>
  //     <div className="math-line-small-six flex">
  //       <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //       <RotateInDownLeft delay={5} duration={0.5} className="arrow arrow-forty-nine">
  //         <FadeIn delay={5} duration={0.5} className="height-100">
  //           <FadeOut className="height-100" delay={8.5} duration={0.5}>
  //             <RotateOutDownLeft className="height-100" delay={8.5} duration={0.5}>
  //               <img className="height-100" src={`${baseUrl}/images/arrows/49.webp`} />
  //             </RotateOutDownLeft>
  //           </FadeOut>
  //         </FadeIn>
  //       </RotateInDownLeft>
  //     </div>
  //     <div className="math-line-small-six">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-4"
  //         src={`${mathsNumbersUrls}/10/minus.webp`}
  //       />
  //       <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //       <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/6.webp`} />
  //       <img
  //         className="small-number-with-small-margins visibility-hidden"
  //         src={`${mathsNumbersUrls}/16/0.webp`}
  //       />
  //     </div>
  //     <div className="math-line-small-six">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-4"
  //         src={`${mathsNumbersUrls}/10/minus.webp`}
  //       />
  //       <RubberBand className="small-number-with-small-margins" delay={7}>
  //         <img className="height-100" src={`${mathsNumbersUrls}/10/1.webp`} />
  //       </RubberBand>
  //       <RubberBand className="small-number-with-small-margins" delay={7}>
  //         <img className="height-100" src={`${mathsNumbersUrls}/10/2.webp`} />
  //       </RubberBand>
  //     </div>
  //   </div>
  //   <RollIn className="learn-flex-center-center position-absolute" duration={0.5}>
  //     <RollOut className="position-relative" delay={4.5} duration={0.5}>
  //       <img className="width-100 height-100" src={`${baseUrl}/images/learn/md-13.webp`} />
  //     </RollOut>
  //   </RollIn>
  // <>
  //     ),
  //     // Easy peasy! We solved it! 172 divided by 4 equal 43
  //     14: (
  // <FadeOut delay={6.5} duration={0.5} className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-six flex">
  //     <Tada delay={6}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/18/4.webp`} />
  //     </Tada>
  //     <Tada delay={6}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/18/3.webp`} />
  //     </Tada>
  //   </div>
  //   <div className="math-line-small-six">
  //     <Pulse className="small-number height-100" delay={3}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/11/1.webp`} />
  //     </Pulse>
  //     <Pulse className="small-number height-100" delay={3}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/11/7.webp`} />
  //     </Pulse>
  //     <Pulse className="small-number height-100" delay={3}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/11/2.webp`} />
  //     </Pulse>
  //   </div>
  //   <div className="math-line-small-six mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/11/by.webp`}
  //     />
  //     <Pulse className="small-number height-100" delay={4}>
  //       <img className="height-100" src={`${mathsNumbersUrls}/11/4.webp`} />
  //     </Pulse>
  //   </div>
  //   <div className="math-line-small-six flex">
  //     <img className="math-line-line-eleven" src={`${baseUrl}/images/lines/11.webp`} />
  //   </div>
  //   <div className="math-line-small-six">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-4"
  //       src={`${mathsNumbersUrls}/10/minus.webp`}
  //     />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/1.webp`} />
  //     <img className="small-number-with-small-margins" src={`${mathsNumbersUrls}/10/6.webp`} />
  //     <img
  //       className="small-number-with-small-margins visibility-hidden"
  //       src={`${mathsNumbersUrls}/16/0.webp`}
  //     />
  //   </div>
  //   <div className="math-line-small-six">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-4"
  //       src={`${mathsNumbersUrls}/10/minus.webp`}
  //     />
  //     <img className="height-100" src={`${mathsNumbersUrls}/10/1.webp`} />
  //     <img className="height-100" src={`${mathsNumbersUrls}/10/2.webp`} />
  //   </div>
  // </FadeOut>
  //),
  //     // reverse operation 1/2
  //     15:
  // <div className="learn-mathematics-animation-container">
  //   <div className="learn-math-equation-from-the-right-container">
  //     <BounceInUp className="math-line-small-fifth" duration={0.5} delay={8}>
  //       <FadeIn duration={0.5} delay={8.5}>
  //         <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/1.webp`} />
  //         <img
  //           className="small-number-with-margins visibility-hidden"
  //           src={`${mathsNumbersUrls}/17/0.webp`}
  //         />
  //       </FadeIn>
  //     </BounceInUp>
  //     <FadeIn className="math-line-small-fifth" delay={5} duration={0.5}>
  //       <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/4.webp`} />
  //       <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/3.webp`} />
  //     </FadeIn>
  //     <FadeIn className="math-line-small-fifth" delay={5.25} duration={0.5}>
  //       <img
  //         className="small-number-with-margins small-number-with-margins-2"
  //         src={`${mathsNumbersUrls}/17/times.webp`}
  //       />
  //       <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/4.webp`} />
  //     </FadeIn>
  //     <SlideInRight className="math-line-small-fifth" delay={5} duration={0.5}>
  //       <FadeIn delay={5} duration={0.5}>
  //         <img className="math-line-line-six" src={`${baseUrl}/images/lines/15.webp`} />
  //       </FadeIn>
  //     </SlideInRight>
  //     <BounceIn className="math-line-small-fifth" delay={7} duration={0.5}>
  //       <img className="small-number-with-margins" src={`${mathsNumbersUrls}/6/2.webp`} />
  //     </BounceIn>
  //   </div>
  //   <FlipInY className="learn-flex-center-center position-absolute" duration={0.5}>
  //     <FlipOutY className="position-relative" delay={4.5} duration={0.5}>
  //       <img className="width-100 height-100" src={`${baseUrl}/images/learn/md-15.webp`} />
  //     </FlipOutY>
  //   </FlipInY>
  // </div>
  //     // reverse operation 2/2
  //     16: (
  // <FadeOut duration={0.5} delay={8.5} className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-fifth">
  //     <BounceOutDown duration={0.5} delay={3.5}>
  //       <FadeOut className="height-100" duration={5.5} delay={8.5}>
  //         <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/1.webp`} />
  //       </FadeOut>
  //     </BounceOutDown>
  //     <img
  //       className="small-number-with-margins visibility-hidden"
  //       src={`${mathsNumbersUrls}/17/0.webp`}
  //     />
  //   </div>
  //   <div className="math-line-small-fifth">
  //     <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/4.webp`} />
  //     <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/3.webp`} />
  //   </div>
  //   <div className="math-line-small-fifth">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-2"
  //       src={`${mathsNumbersUrls}/17/times.webp`}
  //     />
  //     <img className="small-number-with-margins" src={`${mathsNumbersUrls}/17/4.webp`} />
  //   </div>
  //   <div className="math-line-small-fifth display-block">
  //     <img src={`${baseUrl}/images/lines/15.webp`} />
  //   </div>
  //   <div className="math-line-small-fifth">
  //     <BounceIn className="small-number-with-margins" delay={1.5} duration={0.5}>
  //       <Pulse delay={7.5} duration={0.5} className="height-100">
  //         <img className="height-100" src={`${mathsNumbersUrls}/6/1.webp`} />
  //       </Pulse>
  //     </BounceIn>
  //     <div className="small-number-with-margins">
  //       <BounceIn className="height-100" delay={1.5} duration={0.5}>
  //         <BounceOut className="height-100" delay={3.5} duration={0.5}>
  //           <img className="height-100" src={`${mathsNumbersUrls}/6/6.webp`} />
  //         </BounceOut>
  //       </BounceIn>
  //       <BounceIn className="position-absolute height-100 top-0" delay={4} duration={0.5}>
  //         <Pulse delay={7.5} duration={0.5} className="height-100">
  //           <img className="height-100" src={`${mathsNumbersUrls}/6/7.webp`} />
  //         </Pulse>
  //       </BounceIn>
  //     </div>
  //     <Pulse delay={7.5} duration={0.5} className="small-number-with-margins">
  //       <img className="height-100" src={`${mathsNumbersUrls}/6/2.webp`} />
  //     </Pulse>
  //   </div>
  // </FadeOut>
  // ),
  //     // "Let's now talk about remainders. We can also have divisions with remainders. Let's do another advanced example with one.",
  //     17: (
  // <div className="learn-flex-center-center">
  //   <SlideInLeft delay={0.5} className="position-absolute max-100 flex">
  //     <FadeIn className="width-100" delay={0.5} duration={0.5}>
  //       <Wobble className="width-100" delay={3} duration={3}>
  //         <SlideOutRight className="width-100" delay={7} duration={0.5}>
  //           <FadeOut className="width-100" delay={7} duration={0.5}>
  //             <img className="width-100" src={`${baseUrl}/images/learn/md-17.webp`} />
  //           </FadeOut>
  //         </SlideOutRight>
  //       </Wobble>
  //     </FadeIn>
  //   </SlideInLeft>
  // </div>
  //     18: (
  // <div className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <FlipInX delay={2} duration={0.5} className="math-line-small-ten">
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //   </FlipInX>
  //   <FlipInX
  //     delay={3.5}
  //     duration={0.5}
  //     className="math-line-small-ten mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/21/by.webp`}
  //     />
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //   </FlipInX>
  //   <FlipInX delay={4.5} duration={0.5} className="math-line-small-ten flex">
  //     <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //   </FlipInX>
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  // </div>
  //     ),
  //     19: (
  // <div className="learn-math-equation-from-the-right-container">
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten">
  //     <RubberBand className="small-number" delay={2.5}>
  //       <Wobble className="height-100" delay={3.5}>
  //         <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //       </Wobble>
  //     </RubberBand>
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //     <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //   </div>
  //   <div className="math-line-small-ten mathematics-question-display">
  //     <img
  //       className="small-number-with-margins small-number-with-margins-3"
  //       src={`${mathsNumbersUrls}/21/by.webp`}
  //     />
  //     <Flip className="height-100" delay={5.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //     </Flip>
  //     <Flip className="height-100" delay={5.5}>
  //       <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //     </Flip>
  //   </div>
  //   <div className="math-line-small-ten flex">
  //     <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //     <SlideInLeft className="arrow arrow-fifty-one" duration={0.5}>
  //       <FadeIn duration={0.5}>
  //         <Shake delay={0.5} duartion={3}>
  //           <SlideOutRight delay={3.5}>
  //             <FadeOut delay={3.5}>
  //               <img src={`${baseUrl}/images/arrows/51.webp`} />
  //             </FadeOut>
  //           </SlideOutRight>
  //         </Shake>
  //       </FadeIn>
  //     </SlideInLeft>
  //   </div>
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  //   <div className="math-line-small-ten" />
  // </div>
  //     ),
  //     20: (
  // <>
  //   <div className="learn-math-equation-from-the-right-container learn-math-opacity-on-delayed">
  //     <div className="math-line-small-ten" />
  //     <div className="math-line-small-ten" />
  //     <div className="math-line-small-ten">
  //       <Pulse className="height-100" duration={0.5}>
  //         <Jello className="height-100" delay={1.5} duration={0.5}>
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //         </Jello>
  //       </Pulse>
  //       <Pulse className="height-100" delay={0.5} duration={0.5}>
  //         <Jello className="height-100" delay={1.5} duration={0.5}>
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //         </Jello>
  //       </Pulse>
  //       <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //     </div>
  //     <div className="math-line-small-ten mathematics-question-display">
  //       <img
  //         className="small-number-with-margins small-number-with-margins-3"
  //         src={`${mathsNumbersUrls}/21/by.webp`}
  //       />
  //       <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //       <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //     </div>
  //     <div className="math-line-small-ten flex">
  //       <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //       <img className="arrow arrow-fifty-one" src={`${baseUrl}/images/arrows/51.webp`} />
  //     </div>
  //     <div className="math-line-small-ten" />
  //     <div className="math-line-small-ten" />
  //     <div className="math-line-small-ten" />
  //     <div className="math-line-small-ten" />
  //     <div className="math-line-small-ten" />
  //   </div>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={4.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={2.5} duration={0.5}>
  //       <img className="super-small-division-number" src={`${mathsNumbersUrls}/9/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/9.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/9/times.webp`} />
  //       <img className="super-small-division-number" src={`${mathsNumbersUrls}/9/1.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/9/equal.webp`} />
  //       <img className="super-small-division-number" src={`${mathsNumbersUrls}/9/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/9.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={7}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={5} duration={0.5}>
  //       <img className="super-small-division-number" src={`${mathsNumbersUrls}/9/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/9.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/9/times.webp`} />
  //       <img className="smaller-division-number" src={`${mathsNumbersUrls}/9/2.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/9/equal.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/3.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/8.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  //   <FadeOut
  //     className="learn-horizontal-numbers division-medium-division-container-out"
  //     delay={9.5}
  //     duration={0.5}>
  //     <FadeIn className="division-medium-division-container-in" delay={7.5} duration={0.5}>
  //       <img className="super-small-division-number" src={`${mathsNumbersUrls}/9/1.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/9.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/9/times.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/3.webp`} />
  //       <img className="division-small-number" src={`${mathsNumbersUrls}/9/equal.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/5.webp`} />
  //       <img className="small-division-number" src={`${mathsNumbersUrls}/9/7.webp`} />
  //     </FadeIn>
  //   </FadeOut>
  // </>
  //     ),
  //     21: (
  // TODO - 38 is smaller than 45 but 57 is not
  <div className="learn-mathematics-animation-container learn-math-opacity-off-delayed">
    <div className="learn-math-equation-from-the-right-container">
      <div className="math-line-small-ten">
        <BounceIn className="small-number" delay={6} duration={0.5}>
          <img className="height-100" src={`${mathsNumbersUrls}/16/2.webp`} />
        </BounceIn>
        <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/16/4.webp`} />
        <FadeInUp delay={5.5} duration={0.5} className="arrow arrow-fifty-two">
          <Pulse delay={6} duration={0.5}>
            <FadeOutUp delay={6.5} duration={0.5}>
              <img className="width-100" src={`${baseUrl}/images/arrows/52.webp`} />
            </FadeOutUp>
          </Pulse>
        </FadeInUp>
      </div>
      <div className="math-line-small-ten flex">
        <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
      </div>
      <div className="math-line-small-ten">
        <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
        <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
        <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
      </div>
      <div className="math-line-small-ten mathematics-question-display">
        <img
          className="small-number-with-margins small-number-with-margins-3"
          src={`${mathsNumbersUrls}/21/by.webp`}
        />
        <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
        <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
      </div>
      <div className="math-line-small-ten">
        <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
        <FadeInDown delay={7} duration={0.5} className="arrow arrow-fifty-three">
          <Pulse className="height-100" delay={7.5} duration={0.5}>
            <FadeOutDown className="height-100" delay={8} duration={0.5}>
              <img className="height-100" src={`${baseUrl}/images/arrows/53.webp`} />
            </FadeOutDown>
          </Pulse>
        </FadeInDown>
      </div>
      <div className="math-line-small-ten">
        <BounceIn className="small-number" delay={7.5} duration={0.5}>
          <img className="height-100" src={`${mathsNumbersUrls}/20/3.webp`} />
        </BounceIn>
        <BounceIn className="small-number" delay={7.5} duration={0.5}>
          <img className="height-100" src={`${mathsNumbersUrls}/20/8.webp`} />
        </BounceIn>
        <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
      </div>
      <div className="math-line-small-ten" />
      <div className="math-line-small-ten" />
      <div className="math-line-small-ten" />
      <div className="math-line-small-ten" />
    </div>
  </div>
  //     ),
  //     22: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //           <img className="arrow arrow-fifty-four" src={`${baseUrl}/images/arrows/54.webp`} />
  //           <img className="arrow arrow-fifty-five" src={`${baseUrl}/images/arrows/55.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //       </div>
  //     ),
  //     23: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //       </div>
  //     ),
  //     24: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //       </div>
  //     ),
  //     25: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //       </div>
  //     ),
  //     26: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //       </div>
  //     ),
  //     27: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //           <img className="arrow arrow-fifty-six" src={`${baseUrl}/images/arrows/56.webp`} />
  //           <img className="arrow arrow-fifty-seven" src={`${baseUrl}/images/arrows/57.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/6.webp`} />
  //         </div>
  //         <div className="math-line-small-ten" />
  //         <div className="math-line-small-ten" />
  //       </div>
  //     ),
  //     28: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/6.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-fourteen" src={`${baseUrl}/images/lines/14.webp`} />
  //           <img className="arrow arrow-fifty-eight" src={`${baseUrl}/images/arrows/58.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/26/1.webp`} />
  //         </div>
  //       </div>
  //     ),
  //     29: (
  //       <div className="learn-math-equation-from-the-right-container">
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/2.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/16/4.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-twelve" src={`${baseUrl}/images/lines/12.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten mathematics-question-display">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-3"
  //             src={`${mathsNumbersUrls}/21/by.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-thirteen" src={`${baseUrl}/images/lines/13.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/3.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/8.webp`} />
  //           <img className="small-number visibility-hidden" src={`${mathsNumbersUrls}/20/0.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img
  //             className="small-number-with-margins small-number-with-margins-4"
  //             src={`${mathsNumbersUrls}/20/minus.webp`}
  //           />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/7.webp`} />
  //           <img className="small-number" src={`${mathsNumbersUrls}/20/6.webp`} />
  //         </div>
  //         <div className="math-line-small-ten flex">
  //           <img className="math-line-line-fourteen" src={`${baseUrl}/images/lines/14.webp`} />
  //         </div>
  //         <div className="math-line-small-ten">
  //           <img className="small-number" src={`${mathsNumbersUrls}/26/1.webp`} />
  //         </div>
  //       </div>
  //     ),
  //     // "Our final results are that 457 divided by 19 gives a quotient of 24 and a remainder of 1."
  //     30: (
  //       <div className="learn-flex-center-center">
  //         <FlipOutX
  //           delay={1}
  //           className="learn-flex-column-space-between learn-horizontal-numbers"
  //           duration={0.5}>
  //           <FlipInX className="learn-flex-column-space-around" duration={0.5}>
  //             <img className="small-number" src={`${mathsNumbersUrls}/21/4.webp`} />
  //             <img className="small-number" src={`${mathsNumbersUrls}/21/5.webp`} />
  //             <img className="small-number" src={`${mathsNumbersUrls}/21/7.webp`} />
  //             <img className="plus-sign minus-sign" src={`${mathsNumbersUrls}/21/by.webp`} />
  //             <img className="small-number" src={`${mathsNumbersUrls}/21/1.webp`} />
  //             <img className="small-number" src={`${mathsNumbersUrls}/21/9.webp`} />
  //           </FlipInX>
  //         </FlipOutX>
  //         <FlipOutX
  //           delay={4}
  //           className="flex justify-content-space-between width-100 position-absolute">
  //           <FlipInY className="position-relative" delay={1.5}>
  //             <img className="max-100" src={`${baseUrl}/images/learn/ed-9-1.webp`} />
  //             <Pulse className="learn-easy-division-slide-nine-number" delay={2}>
  //               <img className="width-100" src={`${mathsNumbersUrls}/16/2.webp`} />
  //               <img className="width-100" src={`${mathsNumbersUrls}/16/4.webp`} />
  //             </Pulse>
  //           </FlipInY>
  //           <FlipInY className="position-relative" delay={2.5}>
  //             <img className="max-100" src={`${baseUrl}/images/learn/ed-9-2.webp`} />
  //             <Pulse className="learn-easy-division-slide-nine-number" delay={3}>
  //               <img className="width-100" src={`${mathsNumbersUrls}/26/1.webp`} />
  //             </Pulse>
  //           </FlipInY>
  //         </FlipOutX>
  //       </div>
  //     ),
  //     // "That's it. You have now all the tools and knowledge to do divisions by yourself. Take your time and you will become a mini Einstein!"
  //     31: (
  //       <RotateIn className="learn-flex-center-center" duration={0.5}>
  //         <RotateOut className="position-relative" delay={3.5} duration={0.5}>
  //           <Shake delay={1.5} duration={2}>
  //             <img className="width-100 height-100" src={`${baseUrl}/images/learn/ed-10.webp`} />
  //           </Shake>
  //         </RotateOut>
  //       </RotateIn>
  //     )
  //   }}
  // />
)

export default LearnMediumDivisionsPage
