import React from 'react'
import {
  FadeIn,
  FadeOut,
  RollIn,
  RollOut,
  FlipInX,
  FlipOutX,
  RubberBand,
  BounceIn,
  BounceOut,
  Bounce,
  DrawBarAnim
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnEasyAdditionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_easy_additions"
    sentencesNumberPlusRedirect={8}
    startTimesOf={{
      2: 9000,
      3: 19750,
      4: 25000,
      5: 32500,
      6: 41500,
      7: 44500,
      8: 48500
    }}
    animations={{
      1: (
        <div className="learn-flex-center-center">
          <FadeIn className="max-100" duration={0.5}>
            <FadeOut className="max-100" delay={5.5} duration={0.5}>
              <img className="max-100" src={`${mathsNumbersUrls}/0/5.webp`} />
            </FadeOut>
          </FadeIn>
          <FadeIn delay={3} className="position-absolute max-100">
            <FadeOut className="max-100" delay={5.5} duration={0.5}>
              <img className="max-100" src={`${baseUrl}/images/learn/red-cross.webp`} />
            </FadeOut>
          </FadeIn>
          <RollIn delay={5.5} className="position-absolute">
            <RollOut delay={8.5} duration={0.5}>
              <img className="max-100" src={`${baseUrl}/images/learn/pen-and-paper.webp`} />
            </RollOut>
          </RollIn>
        </div>
      ),
      2: (
        <div className="learn-flex-column-space-between learn-horizontal-numbers">
          <FlipOutX delay={9} duration={0.5}>
            <FlipInX delay={1.75} duration={0.5}>
              <RubberBand delay={5.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/8/6.webp`} />
              </RubberBand>
            </FlipInX>
          </FlipOutX>
          <FlipOutX delay={9} duration={0.5}>
            <FlipInX delay={2} duration={0.5}>
              <img className="width-100" src={`${mathsNumbersUrls}/8/plus.webp`} />
            </FlipInX>
          </FlipOutX>
          <FlipOutX delay={9} duration={0.5}>
            <FlipInX delay={2.25} duration={0.5}>
              <RubberBand delay={6.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/8/7.webp`} />
              </RubberBand>
            </FlipInX>
          </FlipOutX>
        </div>
      ),
      3: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container">
            <DrawBarAnim number="one" />
            <DrawBarAnim number="two" />
            <DrawBarAnim number="three" />
            <DrawBarAnim number="four" />
            <DrawBarAnim number="five" />
            <DrawBarAnim number="six" />
          </div>
          <div className="learn-maths-math-sign position-relative">
            <DrawBarAnim number="plus-one" classname="learn-medium-additions-math-sign" />
            <DrawBarAnim number="plus-two" />
          </div>
          <div className="math-bars-container visibility-hidden" />
        </div>
      ),
      4: (
        <div className="learn-flex-column-space-between">
          <div className="math-bars-container">
            <div className="math-bar math-bar-one" />
            <div className="math-bar math-bar-two" />
            <div className="math-bar math-bar-three" />
            <div className="math-bar math-bar-four" />
            <div className="math-bar math-bar-five" />
            <div className="math-bar math-bar-six" />
          </div>
          <div className="learn-maths-math-sign position-relative">
            <div className="math-bar math-bar-one" />
            <div className="math-bar math-bar-four learn-medium-additions-math-sign" />
          </div>
          <div className="math-bars-container">
            <DrawBarAnim number="seven" />
            <DrawBarAnim number="eight" />
            <DrawBarAnim number="nine" />
            <DrawBarAnim number="ten" />
            <DrawBarAnim number="eleven" />
            <DrawBarAnim number="twelve" />
            <DrawBarAnim number="thirteen" />
          </div>
        </div>
      ),
      5: (
        <FadeOut className="flex width-100 height-100" delay={7.5} duration={0.5}>
          <div className="learn-flex-column-space-between">
            <div className="math-bars-container position-relative">
              <div className="math-bar math-bar-one" />
              <DrawBarAnim number="one-cross" />
              <div className="math-bar math-bar-two" />
              <DrawBarAnim number="two-cross" />
              <div className="math-bar math-bar-three" />
              <DrawBarAnim number="three-cross" />
              <div className="math-bar math-bar-four" />
              <DrawBarAnim number="four-cross" />
              <div className="math-bar math-bar-five" />
              <DrawBarAnim number="five-cross" />
              <div className="math-bar math-bar-six" />
              <DrawBarAnim number="six-cross" />
            </div>
            <div className="learn-maths-math-sign position-relative">
              <div className="math-bar math-bar-one" />
              <div className="math-bar math-bar-four learn-medium-additions-math-sign" />
            </div>
            <div className="math-bars-container position-relative">
              <div className="math-bar math-bar-seven" />
              <DrawBarAnim number="seven-cross" />
              <div className="math-bar math-bar-eight" />
              <DrawBarAnim number="eight-cross" />
              <div className="math-bar math-bar-nine" />
              <DrawBarAnim number="nine-cross" />
              <div className="math-bar math-bar-ten" />
              <DrawBarAnim number="ten-cross" />
              <div className="math-bar math-bar-eleven" />
              <DrawBarAnim number="eleven-cross" />
              <div className="math-bar math-bar-twelve" />
              <DrawBarAnim number="twelve-cross" />
              <div className="math-bar math-bar-thirteen" />
              <DrawBarAnim number="thirteen-cross" />
            </div>
          </div>
        </FadeOut>
      ),
      6: (
        <div className="learn-flex-column-space-between learn-horizontal-numbers justify-content-center">
          <FadeIn className="width-100 flex" delay={0.25} duration={0.5}>
            <img className="width-100 flex" src={`${mathsNumbersUrls}/8/6.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.5} duration={0.5}>
            <img className="plus-sign width-100 flex" src={`${mathsNumbersUrls}/8/plus.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={0.75} duration={0.5}>
            <img className="width-100 flex" src={`${mathsNumbersUrls}/8/7.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={1} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/8/equal.webp`} />
          </FadeIn>
          <FadeIn className="learn-easy-six-one flex" delay={1.25} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/8/1.webp`} />
          </FadeIn>
          <FadeIn className="width-100 flex" delay={1.25} duration={0.5}>
            <img className="equal-sign width-100 flex" src={`${mathsNumbersUrls}/8/3.webp`} />
          </FadeIn>
        </div>
      ),
      7: (
        <div className="learn-flex-center-center">
          <BounceIn>
            <Bounce delay={1} duration={2}>
              <BounceOut delay={3}>
                <img
                  className="width-100"
                  src={`${baseUrl}/images/learn/pen-and-paper-crosses.webp`}
                />
              </BounceOut>
            </Bounce>
          </BounceIn>
        </div>
      )
    }}
  />
)

export default LearnEasyAdditionsPage
