import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { passDispatchToProps, mapIsAnnyangSupportedStateToProps } from '../redux/mapToPropsUtil'
import { changePathAction } from '../redux/actions/actions'
import { common } from '../lib/languageUtil'

const AnnyangNotSupportedMenuButton = ({ isAnnyangSupported, dispatch }) =>
  !isAnnyangSupported && (
    <button
      className="annyang-not-supported-menu-button"
      onClick={() => dispatch(changePathAction('/menu'))}>
      {common('menu')}
    </button>
  )

export default withTranslation()(
  connect(mapIsAnnyangSupportedStateToProps, passDispatchToProps)(AnnyangNotSupportedMenuButton)
)
