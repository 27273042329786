import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 765

const cities = {
  'Kuwait City': { x: 608, y: 359 }
}

const Kuwait = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map kuwait">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="KW-HW"
          title="Hawalli"
          className="land"
          d="M656.97,381.32L621.04,352.99L614.06,371.7L621.19,388.57L656.97,381.32z"
        />
        <path
          id="KW-JA"
          title="Al Jahrah"
          className="land"
          d="M696.87,55.69L668.33,56.74L700.53,83.92L657.49,98.78L665.15,125.75L636.89,144.46L713.8,241.54L768.68,166.75L696.87,55.69zM480.54,0L347.71,2.96L257.94,46.08L135.49,316.6L0.53,484.71L384.74,542.17L520.8,503.93L550.95,402.61L560.71,381.53L558.03,375.15L499.13,340.91L603.03,252.96L693.58,259.76L615.68,131.27L595.66,50.59L480.54,0z"
        />
        <path
          id="KW-MU"
          title="Mubarak Al-Kabeer"
          className="land"
          d="M662.14,428.01L656.97,381.32L621.19,388.57L632.05,430.32L662.14,428.01z"
        />
        <path
          id="KW-AH"
          title="Al Ahmadi"
          className="land"
          d="M478,754.49L799.47,751.15L662.14,428.01L632.05,430.32L565.9,441.73L550.95,402.61L520.8,503.93L384.74,542.17L478,754.49z"
        />
        <path
          id="KW-FA"
          title="Al Farwaniyah"
          className="land"
          d="M621.19,388.57L614.06,371.7L560.71,381.53L550.95,402.61L565.9,441.73L632.05,430.32L621.19,388.57z"
        />
        <path
          id="KW-KU"
          title="Al Asimah"
          className="land"
          d="M621.04,352.99L558.03,375.15L560.71,381.53L614.06,371.7L621.04,352.99zM777.75,321.7L731.05,311.13L784.58,344.14L777.75,321.7z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Kuwait))
