import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 115
const height = 75

const cities = {
  "Nuku'alofa": { x: 314, y: 1876 }
}

const Tonga = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map tonga">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`265 1850 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="TO-01"
          title="'Eua"
          className="land"
          d="M416.71,1931.87L415.54,1929.22L404.86,1948.2L396.86,1954.98L399.07,1963.36L394.7,1968.08L407.94,1979.25L414.95,1987.47L416.97,1984L416.71,1962.5L418.7,1944.63L416.71,1931.87z"
        />
        <path
          id="TO-02"
          title="Ha'apai"
          className="land"
          d="M458.47,1578.9L459,1572L451.69,1573.72L449.27,1577.52L456.87,1581.51L458.47,1578.9zM606.71,1429.18L611.29,1411.49L604.95,1408.94L601.34,1418.48L593.64,1423.09L600.59,1430.06L606.71,1429.18zM374.18,1416.24L374.77,1406.04L372.43,1396.76L367.33,1392.75L360.5,1392.98L357.68,1405.57L361.58,1418.45L370.52,1423.09L374.18,1416.24zM622.21,1405.35L628.44,1388.45L626.76,1383.47L616.93,1396.73L611.3,1399.88L612.46,1403.64L622.21,1405.35zM383.12,1383.53L385,1376.27L378.31,1375.44L378.36,1384.72L383.12,1383.53zM631.05,1370.44L623.46,1363.62L620.22,1353.35L615.38,1352.27L616.85,1358.51L624.58,1371.87L631.05,1370.44z"
        />
        <path
          id="TO-03"
          title="Niuas"
          className="land"
          d="M796.39,133.97L799.83,128.24L798.42,123.61L784.21,132.51L786.72,138.49L791.71,138.54L796.39,133.97zM187.72,0L177.36,2.16L171.11,7.5L170.17,14.2L175.72,20.57L185.23,21.49L192.05,13.93L193.71,4.59L187.72,0z"
        />
        <path
          id="TO-05"
          title="Vava'u"
          className="land"
          d="M504.37,1089.64L508.72,1083.21L501.44,1077.14L496.8,1084.5L500.1,1089.94L504.37,1089.64zM736.42,1018.64L736.06,1014.99L727.79,1007.42L722.97,1005.55L707.7,1006.79L700.89,1018.23L685.86,1028.02L690.03,1037.99L699.39,1037.93L701.89,1029.67L705.52,1026.52L708.65,1035.93L703.53,1037.99L708.05,1049.79L712.04,1052.04L713.51,1040.51L720.98,1037.99L725.33,1031.13L722.25,1025.58L727.53,1021.9L733.64,1029.04L736.42,1018.64z"
        />
        <path
          id="TO-04"
          title="Tongatapu"
          className="land"
          d="M3.76,2292.13L6.61,2289.77L0.17,2287.32L3.76,2292.13zM288.68,1873.32L280.56,1866.91L291.76,1853.19L283.33,1856.15L275.94,1862.45L272.89,1870.78L277.48,1880.07L285.37,1891.22L304.84,1900.14L307.25,1897.58L322.47,1905.22L331.79,1919.14L341.94,1923.58L349.04,1921.79L353.05,1915.38L351.96,1901.32L370.14,1887.84L373.21,1879.48L368.53,1874.21L360.45,1873.79L348.44,1881.12L344.69,1889.43L339.95,1890.61L327.76,1885.92L318.02,1890.86L313.34,1886L320.33,1880.07L319.53,1876.3L309.64,1869.86L288.68,1873.32z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Tonga))
