import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 631

const cities = {
  'Addis Ababa': { x: 309, y: 323 }
}

const Ethiopia = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map ethiopia">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="ET-HA"
          title="Harari People"
          className="land"
          d="M494.01,310.19L494.43,299.6L484.01,306.79L494.01,310.19z"
        />
        <path
          id="ET-DD"
          title="Dire Dawa"
          className="land"
          d="M498.93,281.47L468.12,285.81L466.74,294.8L484.84,294.74L498.93,281.47z"
        />
        <path
          id="ET-SO"
          title="Somali"
          className="land"
          d="M349.48,620.38L366.11,595.7L414.88,573.39L436.15,591.67L472.01,591.31L486.24,578.36L525.07,572.46L562.2,543.63L637.49,539.89L799.36,374.44L746.07,374.44L586.51,319.92L556.37,298.08L525.32,255.08L515.24,232.1L527.29,213.33L513.56,207.29L468.31,212.72L440.81,215.42L431.91,229.55L428.63,268.99L411.22,296.38L422.92,297.2L419.29,303.8L441.85,293.06L466.74,294.8L468.12,285.81L498.93,281.47L529.42,346.74L516.37,366.68L495.36,340.71L482.48,350.9L474.1,400.56L492.86,416.04L482.05,446.13L460.61,462.12L428.98,446.44L419.93,459.12L423.34,497.72L402.36,512.93L374.96,514.66L345.93,593.02L325.72,614.33L349.48,620.38z"
        />
        <path
          id="ET-AA"
          title="Addis Ababa"
          className="land"
          d="M311.84,316.12L309.81,316.35L302.52,320.56L311.13,329.4L311.84,316.12z"
        />
        <path
          id="ET-BE"
          title="Benshangul-Gumaz"
          className="land"
          d="M176.79,323.88L176.49,320.53L175.83,324.02L176.79,323.88zM166.72,319.06L162.55,328.41L174.13,331.86L173.25,322.2L166.72,319.06zM184.62,298.4L184.59,292.18L182.98,302.06L184.62,298.4zM148.55,155.71L129.67,169.54L120.99,160.07L109.95,171.52L105.13,219.25L95.23,229.5L84.2,218.79L69.37,235.72L58.9,309.3L73.85,309.38L79.2,285.86L91.39,288.44L101.1,264.96L122.64,270.55L165.52,312.89L175.43,294.46L167.87,263.56L187.81,248.19L192.5,255.22L216.79,249.96L180.76,239.59L177.67,221.77L188.07,193.37L179.22,168.8L169.12,175.14L148.55,155.71z"
        />
        <path
          id="ET-AA"
          title="Oromiya"
          className="land"
          d="M305.11,263.48L313.7,247.49L294.15,246.26L289.93,258.66L251.78,274.85L216.79,249.96L192.5,255.22L187.81,248.19L167.87,263.56L175.43,294.46L165.52,312.89L122.64,270.55L101.1,264.96L91.39,288.44L79.2,285.86L73.85,309.38L58.9,309.3L60.19,341.86L101.16,364.52L112.57,365.34L102.03,371.15L102.01,385.69L113.3,402.04L121.8,399.01L126.77,382.76L147.31,381.27L152.23,369.78L163.55,397.33L220.04,415.37L235.37,406.29L235.35,374.43L247.19,376.16L241.89,351.71L280.39,351.94L286.87,360.06L297.83,349.74L303.64,369.23L296.66,361.66L300.57,373.16L290.52,399.68L271.32,422.84L300.24,423.79L302.14,440L325.85,453.57L328.09,472.97L293.62,458.46L283.26,481L288.51,486.01L280.1,488.87L272.34,473.39L283.3,458.64L261.46,449.99L256.29,480.42L272.5,496.05L264.63,501.09L262.31,521.82L212.81,525.2L195.62,565.57L215.16,568.81L273.04,609.3L325.72,614.33L345.93,593.02L374.96,514.66L402.36,512.93L423.34,497.72L419.93,459.12L428.98,446.44L460.61,462.12L482.05,446.13L492.86,416.04L474.1,400.56L482.48,350.9L495.36,340.71L516.37,366.68L529.42,346.74L498.93,281.47L484.84,294.74L466.74,294.8L441.85,293.06L419.29,303.8L422.92,297.2L411.22,296.38L376.26,328.85L364.1,312.87L361.33,327.31L337.48,331.67L340.7,312.29L334.3,304.69L347.99,302.08L313.33,279.92L305.11,263.48zM182.98,302.06L184.59,292.18L184.62,298.4L182.98,302.06zM309.81,316.35L311.84,316.12L311.13,329.4L302.52,320.56L309.81,316.35zM173.25,322.2L174.13,331.86L162.55,328.41L166.72,319.06L173.25,322.2zM175.83,324.02L176.49,320.53L176.79,323.88L175.83,324.02zM494.43,299.6L494.01,310.19L484.01,306.79L494.43,299.6z"
        />
        <path
          id="ET-GA"
          title="Gambela Peoples"
          className="land"
          d="M101.16,364.52L60.19,341.86L41.25,354.4L13.72,349.72L0.64,378.7L39.34,392.69L64.9,422.75L125.33,420.88L121.8,399.01L113.3,402.04L102.01,385.69L102.03,371.15L112.57,365.34L101.16,364.52z"
        />
        <path
          id="ET-SN"
          title="Southern Nations, Nationalities and Peoples"
          className="land"
          d="M303.64,369.23L297.83,349.74L286.87,360.06L280.39,351.94L241.89,351.71L247.19,376.16L235.35,374.43L235.37,406.29L220.04,415.37L163.55,397.33L152.23,369.78L147.31,381.27L126.77,382.76L121.8,399.01L125.33,420.88L64.9,422.75L91.96,444.91L123.87,516.04L150.61,518.15L147.81,542.95L157.87,561.46L195.62,565.57L212.81,525.2L262.31,521.82L264.63,501.09L272.5,496.05L256.29,480.42L261.46,449.99L283.3,458.64L272.34,473.39L280.1,488.87L288.51,486.01L283.26,481L293.62,458.46L328.09,472.97L325.85,453.57L302.14,440L300.24,423.79L271.32,422.84L290.52,399.68L300.57,373.16L296.66,361.66L303.64,369.23z"
        />
        <path
          id="ET-AF"
          title="Afar"
          className="land"
          d="M393.03,29.97L374.18,23.37L356.5,30.56L364.57,52.23L361.1,108.98L368.42,116.26L362.45,140.06L384.66,208.04L381.2,260.72L373.66,262.79L375,281.05L366.32,280.35L364.1,312.87L376.26,328.85L411.22,296.38L428.63,268.99L431.91,229.55L440.81,215.42L468.31,212.72L469.69,173.87L500.97,132.37L418.58,42.58L393.03,29.97z"
        />
        <path
          id="ET-TI"
          title="Tigray"
          className="land"
          d="M261.83,0L243.83,42.6L229.98,23.25L214.63,33.94L191.18,32.3L184.49,60.68L217.16,82.79L292.33,71.05L321.67,102.38L332.85,102.33L335.43,140.74L362.45,140.06L368.42,116.26L361.1,108.98L364.57,52.23L356.5,30.56L374.18,23.37L348.34,17.17L331.99,24.2L321.42,12.58L290.36,25.46L274.72,11.12L261.83,0z"
        />
        <path
          id="ET-AM"
          title="Amhara"
          className="land"
          d="M217.16,82.79L184.49,60.68L167.17,119.49L144.4,121.82L120.99,160.07L129.67,169.54L148.55,155.71L169.12,175.14L179.22,168.8L188.07,193.37L177.67,221.77L180.76,239.59L216.79,249.96L251.78,274.85L289.93,258.66L294.15,246.26L313.7,247.49L305.11,263.48L313.33,279.92L347.99,302.08L334.3,304.69L340.7,312.29L337.48,331.67L361.33,327.31L364.1,312.87L366.32,280.35L375,281.05L373.66,262.79L381.2,260.72L384.66,208.04L362.45,140.06L335.43,140.74L332.85,102.33L321.67,102.38L292.33,71.05L217.16,82.79z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Ethiopia))
