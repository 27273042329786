import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1176

const cities = {
  Accra: { x: 561, y: 1005 }
}

const Ghana = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map ghana">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="GH-TV"
          title="Volta"
          className="land"
          d="M652.4,437.94L620.25,437.66L603.39,481.1L603.72,505.28L630.37,510.45L621.78,530.09L573.44,515.36L515.37,550.92L554.17,575.53L588.08,625.69L609.18,682.85L628.83,695.33L626.46,727.06L611.97,787.48L625.01,809.95L606.2,841.6L625.18,864.36L602.66,911.28L643.24,928.89L643.77,948.17L689.53,943.12L715.42,974.98L707.27,981.61L757.79,975.83L799.66,913.6L720.23,858.45L678.98,785.96L675.12,762.2L693.7,751.69L702.26,698.76L675.31,666.03L696.26,630.68L689.36,539.19L714.31,522.12L652.4,437.94z"
        />
        <path
          id="GH-AA"
          title="Greater Accra"
          className="land"
          d="M707.27,981.61L715.42,974.98L689.53,943.12L643.77,948.17L628.44,961.53L605.23,939.47L579.51,942.51L553.24,979.98L500.22,976.37L495.32,987.59L521.76,1028.6L639.84,978.97L707.27,981.61z"
        />
        <path
          id="GH-WP"
          title="Western"
          className="land"
          d="M210.83,1165.92L304.54,1115.07L308.66,1086.6L331.05,1072.13L297.64,1037.42L297.62,1013.82L260.27,1006.62L256.49,958.26L198.61,911.48L214.48,884.34L186.15,869.08L187.28,849.38L167.87,846.39L150.62,868.24L78.49,820.64L67.87,774.47L37.97,767.45L30.02,747.56L0.34,826.59L42.15,991.5L57.21,1008.82L84.59,1008.85L95.96,1051.24L89.41,1089.5L26.01,1102.07L163.05,1133.03L210.83,1165.92z"
        />
        <path
          id="GH-EP"
          title="Eastern"
          className="land"
          d="M626.46,727.06L547.9,722.44L478.18,747.08L443.88,794.07L416.09,804.17L426.31,827.21L372.56,900.88L363.68,953.4L385.79,978.12L495.32,987.59L500.22,976.37L553.24,979.98L579.51,942.51L605.23,939.47L628.44,961.53L643.77,948.17L643.24,928.89L602.66,911.28L625.18,864.36L606.2,841.6L625.01,809.95L611.97,787.48L626.46,727.06z"
        />
        <path
          id="GH-CP"
          title="Central"
          className="land"
          d="M363.68,953.4L320.9,964.44L242.93,935.6L214.48,884.34L198.61,911.48L256.49,958.26L260.27,1006.62L297.62,1013.82L297.64,1037.42L331.05,1072.13L308.66,1086.6L304.54,1115.07L442.7,1079.8L521.76,1028.6L495.32,987.59L385.79,978.12L363.68,953.4z"
        />
        <path
          id="GH-BA"
          title="Brong Ahafo"
          className="land"
          d="M515.37,550.92L439.64,518.39L390.39,579.54L358.21,511.45L380.98,474.23L375.87,455.6L336.59,437.8L252.73,462.96L244.61,507.99L270.84,530.4L203.57,547.58L118.77,433.64L136.15,538.22L85.09,586.47L57.99,652.83L52.37,718.43L30.02,747.56L37.97,767.45L67.87,774.47L78.49,820.64L150.62,868.24L152.94,814.58L172.02,811.58L183.85,786.92L156.71,784.03L170.96,759.3L251.65,724.91L201.68,693.19L208.23,673.74L254.66,672.54L289.63,695.59L338.89,644.6L362.37,673.93L460.85,659.43L547.9,722.44L626.46,727.06L628.83,695.33L609.18,682.85L588.08,625.69L554.17,575.53L515.37,550.92z"
        />
        <path
          id="GH-AH"
          title="Ashanti"
          className="land"
          d="M547.9,722.44L460.85,659.43L362.37,673.93L338.89,644.6L289.63,695.59L254.66,672.54L208.23,673.74L201.68,693.19L251.65,724.91L170.96,759.3L156.71,784.03L183.85,786.92L172.02,811.58L152.94,814.58L150.62,868.24L167.87,846.39L187.28,849.38L186.15,869.08L214.48,884.34L242.93,935.6L320.9,964.44L363.68,953.4L372.56,900.88L426.31,827.21L416.09,804.17L443.88,794.07L478.18,747.08L547.9,722.44z"
        />
        <path
          id="GH-UW"
          title="Upper West"
          className="land"
          d="M330.62,26.54L76.7,30.18L59.61,96.69L89.24,135.62L75.99,153.68L91.5,164.9L93.58,275.67L167.88,246.76L269.55,242.79L290.05,202.34L306.99,198.24L290.07,181.9L296.41,159.67L318.67,134.7L341,141.2L302.85,90.28L330.62,26.54z"
        />
        <path
          id="GH-UE"
          title="Upper East"
          className="land"
          d="M532.11,0L472.29,46.66L461.29,30.91L330.62,26.54L302.85,90.28L341,141.2L364.96,158L382.89,124.06L420.71,125.02L445.21,95.68L460.42,96.7L466.42,117.78L569.85,84.63L589.21,18.37L556.46,5.12L532.11,0z"
        />
        <path
          id="GH-NP"
          title="Northern"
          className="land"
          d="M652.4,437.94L677.11,418.89L663.75,389.57L682.84,325.88L660.23,305.01L627.85,318.36L640.52,302.24L626.36,296.1L654.14,287.32L633.17,274.97L648.63,273.88L657.53,160.81L618.6,140.55L569.85,84.63L466.42,117.78L460.42,96.7L445.21,95.68L420.71,125.02L382.89,124.06L364.96,158L341,141.2L318.67,134.7L296.41,159.67L290.07,181.9L306.99,198.24L290.05,202.34L269.55,242.79L167.88,246.76L93.58,275.67L108.59,344.94L87.93,384.59L108.41,391.87L118.77,433.64L203.57,547.58L270.84,530.4L244.61,507.99L252.73,462.96L336.59,437.8L375.87,455.6L380.98,474.23L358.21,511.45L390.39,579.54L439.64,518.39L515.37,550.92L573.44,515.36L621.78,530.09L630.37,510.45L603.72,505.28L603.39,481.1L620.25,437.66L652.4,437.94z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Ghana))
