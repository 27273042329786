import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 833

const cities = {
  Paramaribo: { x: 569, y: 30 }
}

const Suriname = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map suriname">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="SR-WA"
          title="Wanica"
          className="land"
          d="M554.28,16.46L549.65,13.4L530.09,62.09L591.94,63.25L571.27,43.53L554.28,16.46z"
        />
        <path
          id="SR-SA"
          title="Saramacca"
          className="land"
          d="M549.65,13.4L428.16,3.07L422.38,61.29L418.5,88.46L421.83,114.81L511.62,110.99L530.09,62.09L549.65,13.4z"
        />
        <path
          id="SR-PM"
          title="Paramaribo"
          className="land"
          d="M574.27,32.56L554.28,16.46L571.27,43.53L574.27,32.56z"
        />
        <path
          id="SR-CR"
          title="Coronie"
          className="land"
          d="M422.38,61.29L414.72,35.88L282.76,6.94L303.67,87.84L306.45,132.75L418.5,88.46L422.38,61.29z"
        />
        <path
          id="SR-CM"
          title="Commewijne"
          className="land"
          d="M576.6,0L626.76,25.77L574.27,32.56L571.27,43.53L591.94,63.25L593.66,90.01L673.2,84.61L658,2.07L576.6,0z"
        />
        <path
          id="SR-SI"
          title="Sipaliwini"
          className="land"
          d="M398.19,822.55L418.22,779.67L373.07,738.97L405.43,685.51L456.55,711.14L607.77,669.19L668.19,727.04L723.08,693.96L763.67,620.9L755.57,566.61L799.51,470.88L716.17,352.51L701.12,214.85L733.94,153.45L667.88,121.27L629.07,199.39L651.05,217.35L619.98,348.39L546.31,391.49L500.29,179.64L465.98,199.95L413.68,177.79L418.5,88.46L306.45,132.75L158.5,143.9L149.03,190.43L68.7,194.36L22.94,229.37L38.97,264.84L0.49,396.27L76.01,515.44L143.51,513.66L164.48,622.25L262.71,783.77L398.19,822.55z"
        />
        <path
          id="SR-PR"
          title="Para"
          className="land"
          d="M591.94,63.25L530.09,62.09L511.62,110.99L421.83,114.81L418.5,88.46L413.68,177.79L465.98,199.95L500.29,179.64L530.46,133.14L598.61,132.22L626.19,200.53L629.07,199.39L667.88,121.27L673.2,84.61L593.66,90.01L591.94,63.25z"
        />
        <path
          id="SR-MA"
          title="Marowijne"
          className="land"
          d="M763.52,126.48L793.26,30.8L658,2.07L673.2,84.61L667.88,121.27L733.94,153.45L763.52,126.48z"
        />
        <path
          id="SR-BR"
          title="Brokopondo"
          className="land"
          d="M626.19,200.53L598.61,132.22L530.46,133.14L500.29,179.64L546.31,391.49L619.98,348.39L651.05,217.35L629.07,199.39L626.19,200.53z"
        />
        <path
          id="SR-NI"
          title="Nickerie"
          className="land"
          d="M175.39,56.96L175.56,34.95L170.12,48.07L175.39,56.96zM303.67,87.84L282.76,6.94L191.41,6.99L172.11,85.05L138.11,132.73L158.5,143.9L306.45,132.75L303.67,87.84z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Suriname))
