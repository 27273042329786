import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1315

const cities = {
  Maputo: { x: 176, y: 1224 }
}

const Mozambique = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map mozambique">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="MZ-MPM"
          title="Maputo City"
          className="land"
          d="M184.15,1225.56L176.18,1221.82L173.46,1228.86L184.15,1225.56z"
        />
        <path
          id="MZ-L"
          title="Maputo"
          className="land"
          d="M201.78,1304.11L206.39,1239.65L197.97,1257.23L173.46,1228.86L176.18,1221.82L184.15,1225.56L198.14,1197.35L219.75,1181.23L187.67,1100.45L141.48,1084.62L129.06,1089.86L134.29,1202.7L127.52,1217.41L139.91,1233.86L143.17,1303.6L201.78,1304.11z"
        />
        <path
          id="MZ-I"
          title="Inhambane"
          className="land"
          d="M321.15,1137.45L373.84,1110.84L397.37,1076.16L401.11,1055.8L396.04,1049.15L385.25,1064.13L405.84,978.07L400.7,980.7L397.06,959.74L401.73,918.16L399.16,928.81L393.45,913.32L389.89,943.25L383.24,935.19L380.63,874.79L369.8,837.18L365.69,848.75L361.49,831.73L368.71,818.96L319.86,836.57L309.96,847.74L286.21,847.91L275.17,856.55L249.49,855.06L230.65,863.5L220.81,879.6L237.81,912.45L232.32,948.98L273.24,1000.97L285.29,1094.48L295.62,1111.49L316.99,1104.98L320.12,1118.35L307.54,1126.67L321.15,1137.45z"
        />
        <path
          id="MZ-Q"
          title="Zambezia"
          className="land"
          d="M670.82,494.43L659.62,474.55L664.74,465.93L656.26,434.2L637.18,401.39L573.96,362.62L562.34,366.88L549.2,358.27L539.63,365.06L526.84,348.9L489.22,348.32L457.02,386.36L423.25,383.73L419.3,429.37L388.43,437.09L370.17,468.18L381.17,480.99L382.19,514.68L384.32,561.53L442.07,612.54L445.47,647.47L446.5,654.77L455.54,654.01L453.37,638.37L466.11,645.58L471.03,627.44L502.21,598.33L508.97,587.05L498.1,573.7L509.48,584.35L524.93,562.72L595.52,527.04L591.97,519.51L598.89,527.14L635.76,506.52L666.72,503.83L670.82,494.43z"
        />
        <path
          id="MZ-S"
          title="Sofala"
          className="land"
          d="M384.32,561.53L368.19,549.21L350.42,503.31L329.87,491.16L301.77,519.99L294.65,552.45L296.21,572.64L306.39,579.8L303.35,599.36L293.39,604.5L280.56,594.2L271.09,606.5L275.37,622L263.64,637.43L287.52,656.5L273.53,711.09L275.64,724.69L285.25,729.53L240.59,749.2L246.74,776.12L276.4,800.44L277.43,819.82L294.22,828.09L286.21,847.91L309.96,847.74L319.86,836.57L368.71,818.96L359.75,801.06L335.3,785.86L340.94,760.62L334.32,754.61L343.32,754.66L343.52,727.68L324.93,711.8L332.17,710.17L351.23,731.55L424.58,658.82L445.47,647.47L442.07,612.54L384.32,561.53z"
        />
        <path
          id="MZ-G"
          title="Gaza"
          className="land"
          d="M168.14,847.7L80.38,937.36L99.73,997.87L99.07,1022.42L123.36,1061.34L129.06,1089.86L141.48,1084.62L187.67,1100.45L219.75,1181.23L321.15,1137.45L307.54,1126.67L320.12,1118.35L316.99,1104.98L295.62,1111.49L285.29,1094.48L273.24,1000.97L232.32,948.98L237.81,912.45L220.81,879.6L230.65,863.5L215.09,870.92L168.14,847.7z"
        />
        <path
          id="MZ-N"
          title="Nampula"
          className="land"
          d="M729.05,458.16L732.08,447.93L722.88,450.91L729.05,458.16zM775.96,232.42L745.98,227.58L724.95,245.14L649.18,278.57L617.51,280.15L581.11,293.98L575.07,288.18L558.42,292.09L502.42,330.49L489.22,348.32L526.84,348.9L539.63,365.06L549.2,358.27L562.34,366.88L573.96,362.62L637.18,401.39L656.26,434.2L664.74,465.93L659.62,474.55L670.82,494.43L725.49,459.72L720.03,449.7L735.75,443.47L746.46,425L741.21,425.21L786.48,371.32L785.99,361.95L774.89,362.19L789.87,353.68L786.78,346.19L794.53,347.83L784.69,336.62L799.51,332.3L793.49,311.15L799.69,306.35L789.01,303.84L783.66,314.27L784.18,296.68L792.44,291.33L788.69,284.47L778.01,288.16L785.25,272.79L775.96,232.42z"
        />
        <path
          id="MZ-A"
          title="Niassa"
          className="land"
          d="M622.92,70.87L605.19,60.41L581.24,60.93L572.12,80.08L544.57,94.6L513.11,82.49L497.15,83.15L482.53,94.5L450.48,92.98L448.1,83.55L422.4,70.87L400.23,86.16L330.7,84.5L311.66,131.26L326.05,218.12L333.22,231.01L349.95,232.82L366.57,247.4L423.97,320.81L423.25,383.73L457.02,386.36L489.22,348.32L502.42,330.49L558.42,292.09L575.07,288.18L581.11,293.98L617.51,280.15L589.76,228.7L601.72,201.91L583.94,198.51L588.13,154.19L617.87,111.44L622.92,70.87z"
        />
        <path
          id="MZ-P"
          title="Cabo Delgado"
          className="land"
          d="M775.96,0L720.21,34L679.02,52.51L653.3,52.01L622.92,70.87L617.87,111.44L588.13,154.19L583.94,198.51L601.72,201.91L589.76,228.7L617.51,280.15L649.18,278.57L724.95,245.14L745.98,227.58L775.96,232.42L781.09,191.08L772.68,194.65L767.23,188.49L784.97,174.08L772.29,154.49L778.52,145.71L770.26,136.2L775.45,103.55L768.48,89.03L771.36,68.7L762.56,63.47L774.42,53.53L778.9,40.23L773.64,35.77L782.98,26.98L772.83,20.94L785.58,15.19L775.96,0z"
        />
        <path
          id="MZ-B"
          title="Manica"
          className="land"
          d="M329.87,491.16L286.34,475.35L270.33,457.57L251.69,459.07L210.7,534.74L204.36,581.23L213.04,611.24L200.1,625.81L202.49,644.76L186.61,649.51L185.83,659.1L198.98,669.35L192.02,699.65L196.25,716.44L205.55,714.44L212.29,725.21L210.37,745.04L185.13,785.12L170.83,790.84L171.54,817.65L160.16,833.58L168.14,847.7L215.09,870.92L230.65,863.5L249.49,855.06L275.17,856.55L286.21,847.91L294.22,828.09L277.43,819.82L276.4,800.44L246.74,776.12L240.59,749.2L285.25,729.53L275.64,724.69L273.53,711.09L287.52,656.5L263.64,637.43L275.37,622L271.09,606.5L280.56,594.2L293.39,604.5L303.35,599.36L306.39,579.8L296.21,572.64L294.65,552.45L301.77,519.99L329.87,491.16z"
        />
        <path
          id="MZ-T"
          title="Tete"
          className="land"
          d="M382.19,514.68L365.21,513.14L369.15,491.41L317.11,447.5L315.46,430.73L302.57,417.44L327.84,369.13L324.01,317.46L310.91,300.37L264,308.32L258.32,317.02L225.07,271.39L0.31,346.56L13.72,385.48L14.45,426.16L78.94,427.89L89.82,438.76L112.85,443.12L127.86,459.66L156.45,461.45L189.61,481.62L207.45,479.48L196.94,499.4L210.7,534.74L251.69,459.07L270.33,457.57L286.34,475.35L329.87,491.16L350.42,503.31L368.19,549.21L384.32,561.53L382.19,514.68z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Mozambique))
