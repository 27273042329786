import React from 'react'

const InputRadio = ({ name, options, value, onChange }) => (
  <div className={`input-radio-${name}`}>
    {options.map(option => (
      <div className="form_field" key={option.value}>
        <input
          onChange={onChange}
          type="radio"
          value={option.value}
          name={`radio_input_${option.value}`}
          checked={option.value === value}
        />
        <label htmlFor={`radio_input_${option.value}`}>{option.label}</label>
      </div>
    ))}
  </div>
)

export default InputRadio
