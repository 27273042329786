import React from 'react'
import EasyMediumHardNavigationPage from '../../../src/components/EasyMediumHardNavigationPage'

const SubtractionsPage = () => (
  <EasyMediumHardNavigationPage
    navigationCommands={['menu', 'mathematics', 'learn', 'beginner', 'easy', 'medium', 'hard']}
    pageName="subtractions_page"
    customNavigationPath="/mathematics/subtractions"
  />
)

export default SubtractionsPage
