import React from 'react'
import { connect } from 'react-redux'
import QuestionsAnswersGame from '../../../components/QuestionsAnswersGame'
import { passDispatchToProps, mathematicsMapStateToProps } from '../../../redux/mapToPropsUtil'
import { commonProps, gameTypeProps, subtractNumbers } from '../util'

const EasySubtractionsPage = props => {
  const { lngCode = 'en', actual: numbersArray, resultsList, randomList } = props

  return (
    <QuestionsAnswersGame
      {...props}
      {...commonProps}
      {...gameTypeProps({
        lngCode,
        questionType: 'subtractions',
        questionLevel: 'easy',
        numbersArray,
        result: subtractNumbers(numbersArray),
        resultsList,
        randomList
      })}
    />
  )
}

export default connect(mathematicsMapStateToProps, passDispatchToProps)(EasySubtractionsPage)
