import React from 'react'
import LearnPage from '../../../../src/components/LearnPage'
import { getAnimalImageUrl } from '../../../../src/pages/words/animals/util'

const LearnAnimalsPage = () => (
  <LearnPage
    parentPage="animals_words"
    grandParentPage="words"
    pageName="learn_animals"
    parentPagePath="/words/animals"
    getImageUrl={getAnimalImageUrl}
  />
)

export default LearnAnimalsPage
