import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ProgressBar from '../components/ProgressBar'

const targetInEuros = 10000

const paypalSandboxApiUrl = 'https://api-m.sandbox.paypal.com'
// const paypalLiveApiUrl = "https://api-m.paypal.com";
const paypalApiUrl = paypalSandboxApiUrl

// eslint-disable-next-line
const PAYPAL_API_CLIENT = process.env.REACT_APP_PAYPAL_CLIENT_ID
// eslint-disable-next-line
const PAYPAL_API_SECRET = process.env.REACT_APP_PAYPAL_KEY

// IMPORTANT! IN PAYPAL DEVELOPER DASHBOARD CLICK THE APP ACCOUNT AND SCROLL DOWN TO FEATURES - CHECK THE TRANSACTION SEARCH CHECKBOX ELSE IT WILL LOG A 403 - NOT AUTHORISED

const getPaypalAccessToken = async () => {
  return await axios.post(`${paypalApiUrl}/v1/oauth2/token`, null, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    auth: {
      username: PAYPAL_API_CLIENT,
      password: PAYPAL_API_SECRET
    },
    params: {
      grant_type: 'client_credentials'
    }
  })
}

const getDonationBalance = async (setBalanceInfo, setError, setIsLoading) => {
  try {
    const {
      data: { access_token }
    } = await getPaypalAccessToken()

    // a from strat date can also be given but not using it; only currency. as_of_time: '2024-04-09T00:00:00-0700',
    const query = new URLSearchParams({ currency_code: 'EUR' }).toString()

    const response = await axios.get(`${paypalApiUrl}/v1/reporting/balances?${query}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      }
    })

    const balanceInfo = parseInt(response.data.balances[0].total_balance.value, 10)
    setBalanceInfo(balanceInfo)
    setIsLoading(false)
  } catch (error) {
    setError(error)
    setIsLoading(false)
  }
}

const LiveDonationProgressBar = () => {
  const [balanceInfo, setBalanceInfo] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getDonationBalance(setBalanceInfo, setError, setIsLoading)
  }, [])

  return (
    <div className="live-donation-progress-bar">
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : balanceInfo ? (
        <ProgressBar
          percentage={(balanceInfo * 100) / 10000}
          actualAmount={balanceInfo}
          target={targetInEuros}
        />
      ) : null}
    </div>
  )
}

export default LiveDonationProgressBar
