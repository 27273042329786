import React from 'react'
import { baseUrl } from '../lib/_processUtil'
import { withTranslation } from 'react-i18next'
import {
  capitalizeWord,
  getEnCommandName,
  isCustomTopic,
  isLockedTopic,
  getTopic,
  isLearnMathematicsPage,
  isMathsLearnClick
} from '../lib/methodsUtil'
import { common } from '../lib/languageUtil'
import { changePathAction } from '../redux/actions/actions'
import { createBorderRadius } from '../lib/methodsUtil'
import { premiumTopics } from './LockedPremiumTopicsRedirect'
import ImageWithDimensions from './ImageWithDimensions'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'

const updatedPremiumTopics = premiumTopics.map(t => t.replace(/-/gi, '_'))

const NavigationButtons = ({
  user,
  pageName,
  lngCode,
  customNavigationCommands,
  needDonationsTopics,
  comingSoonTopics,
  newTopics,
  isOverOneDayShare,
  dispatch,
  otherButton
}) => {
  const isNeedDonationTopic = isCustomTopic(needDonationsTopics, pageName)
  const isComingSoonTopic = isCustomTopic(comingSoonTopics, pageName)
  const isNewTopic = isCustomTopic(newTopics, pageName)
  const isPremiumTopic = isCustomTopic(updatedPremiumTopics, pageName)

  const comingSoonBadge = (
    <div style={createBorderRadius(0.2)} className="navigation-button-coming-soon-topic-badge">
      {common('coming_soon')}
    </div>
  )

  const newBadge = (
    <div style={createBorderRadius(0.2)} className="navigation-button-new-topic-badge">
      {common('new')}
    </div>
  )

  return (
    <div className="navigation-commands">
      {otherButton}
      {Object.keys(customNavigationCommands).map(command => {
        const isNeedDonation = isNeedDonationTopic(command)
        const isComingSoon = isComingSoonTopic(command)
        const enCommand = getEnCommandName(lngCode, command)
        const isLockedCapitals = isOverOneDayShare && enCommand === 'capitals'
        const isLockedPremiumTopic = isPremiumTopic(command) && isLockedTopic(user, enCommand)

        return (
          <button
            style={createBorderRadius(0.7)}
            key={command}
            className={
              isLockedCapitals
                ? 'navigation-button-need-sharing-style'
                : isNeedDonation
                  ? 'navigation-button-need-donation-style'
                  : isComingSoon
                    ? 'navigation-button-coming-soon-style'
                    : ''
            }
            disabled={isComingSoon}
            onClick={() => {
              dispatch(
                changePathAction(
                  isNeedDonation
                    ? '/support'
                    : isLockedPremiumTopic
                      ? '/shop'
                      : customNavigationCommands[command]
                )
              )
              if (pageName && isLearnMathematicsPage(pageName) && isMathsLearnClick(command)) {
                sendAmplitudeEvent('Learning Page Visits', {
                  topic: getTopic(pageName),
                  action: 'click'
                })
              }
            }}>
            <span className="menu-button-icon-wrapper">
              <ImageWithDimensions
                alt={`${command} icon`}
                className="menu-button-icon"
                src={`${baseUrl}/images/icons/${isLockedPremiumTopic ? 'lock' : enCommand}.webp`}
              />
              {isNeedDonation && (
                <ImageWithDimensions
                  alt="support icon"
                  className="menu-button-support-icon"
                  src={`${baseUrl}/images/icons/support.webp`}
                />
              )}
              {isLockedCapitals && (
                <ImageWithDimensions
                  alt="social lock icon"
                  className="menu-button-social-locked-icon"
                  src={`${baseUrl}/images/icons/key.webp`}
                />
              )}
            </span>
            <span className="menu-button-command-wrapper">{capitalizeWord(command)}</span>
            {isComingSoon ? comingSoonBadge : isNewTopic(command) ? newBadge : null}
          </button>
        )
      })}
    </div>
  )
}

export default withTranslation()(NavigationButtons)
