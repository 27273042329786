import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 982
const height = 802

const cities = {
  'Bandar Seri Begawan': { x: 777, y: 94 }
}

const BruneiDarussalam = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map brunei">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="BN-BE"
          title="Belait"
          className="land"
          d="M698.13,566.04L670.97,602.07L674.68,607.6L682.11,600.23L690.46,598.08L697.88,586.41L705.3,584.87L705.92,591.32L685.82,631.26L667.88,650.98L666.64,657.44L662,660.51L660.46,668.5L656.43,671.57L657.98,676.48L668.19,673.1L669.73,678.02L663.86,691.23L658.91,694.92L657.67,700.75L644.99,706.9L643.14,712.43L622.41,733.01L603.55,760.36L571.07,780.63L550.66,787.7L528.08,792L515.71,791.08L520.66,783.4L527.46,780.94L528.08,774.18L524.99,767.73L516.33,760.97L491.9,758.21L487.26,747.15L475.75,737.01L464.93,733.01L466.16,724.1L462.14,718.57L456.27,717.04L459.36,707.51L454.1,700.14L445.44,696.15L434,686.93L419.46,688.77L404.62,670.34L401.83,659.28L394.1,643.92L389.15,638.7L386.68,623.64L379.56,613.81L382.04,595.68L378.02,590.46L378.02,587.08L371.21,585.55L368.74,587.08L368.43,590.77L362.55,590.77L354.82,605.52L338.74,596.61L330.39,597.53L322.66,595.38L315.54,597.53L310.9,603.36L300.08,598.76L282.76,600.91L275.95,596.3L275.03,590.77L280.28,556.36L287.4,539.15L288.02,531.47L269.46,519.37L276.26,497.56L272.55,482.2L257.09,453.62L257.09,445.02L241.31,412.46L212.24,390.95L201.73,393.71L198.63,383.88L180.06,362.27L131.87,354.67L126.31,350.52L151.88,352.25L206.37,349.6L230.67,345.19L273.01,332.92L275.82,334.21L277.33,331.41L331.76,310.96L369.78,298.91L374.52,294.6L385.97,290.3L418.37,272L445.37,253.27L445.37,253.27L450.5,261.79L454.63,275.44L457.37,287.72L456.46,295.91L486.22,335.49L508.21,328.67L523.78,354.6L522.86,368.7L518.74,378.25L519.2,386.9L536.6,396.63L625.45,488.98L641.48,544.48z"
        />
        <path
          id="BN-BM"
          title="Brunei-Muara"
          className="land"
          d="M850.79,116.96l-12.03,5.13l2.43,-5.53l7.98,-4.75l4.1,1.1L850.79,116.96zM842.23,110.7l-1.6,-3.67l5.45,-6.49l1.52,6.12L842.23,110.7zM847.44,100.69l-1.23,-1.93l3.33,-6.55l0.88,2.79L847.44,100.69zM856.7,53.77l-1.3,-1.69l2.98,-4.64l1.42,2.23L856.7,53.77zM881.71,27.24l14.24,0.23l4.67,-2.2l9.57,14.85l-2.69,1.39l-27.55,-2.78l-10.39,-8.7l-2.1,-8.35l2.34,-1.97L881.71,27.24zM902.14,4.4l13.31,0.81l-32.58,2.78l13.19,-4.52L902.14,4.4zM850.53,1.38l0.93,4.75l4.67,1.39l22.18,-0.58l3.39,-3.36v1.51l-2.45,4.18l-17.51,9.05l-1.87,3.48l0.23,9.97l-2.57,3.02L839.43,34l-2.28,6.39l7.26,7.01l-4.77,2.06l-0.42,3.3l1.87,1.86l4.57,-1.03l2.21,2.73l-2.3,4.22L832.35,70.9l-16.77,32.22l-16.54,10.6l11.84,-1.85l7.24,-6.12l8.32,-12.73l3.62,2.43l2.25,11.53l-27.3,19.93l-1.17,7.1l3.84,8.23l-6.78,3.34l-0.56,2.75l4.55,6.81l-9.28,13.83l-2.17,7.99l-24.74,-3.99l-38.91,0.81l-12.74,8.1l-15.08,5.01l-6.57,6.36l-13.92,31.34l-36.8,10.14l-10.21,6.45l0,0l2.75,-44.33l17.24,-40.4l8.13,-39.75l-2.1,-33.28l0,0l4.32,-1.94l-5.19,-8.18l0.87,-5.17l3.05,9.37l4.08,-2.48l1.73,3.02l3.24,-2.8l-3.24,-5.38l-7.34,-1.94l0.43,-1.51l6.69,2.15l4.54,5.82l2.59,-0.43l3.02,-2.8l-1.08,-2.37l2.16,-0.86l1.73,1.94l-0.43,-1.94l7.34,-4.09l5.61,-7.97l4.33,3.45l3.02,-4.74l-0.86,4.74l4.11,-1.07l1.29,-5.6l2.59,2.8l3.67,-1.08l-0.87,-4.52l3.03,0.43l-0.65,3.45l2.48,-0.75l40.93,-19.28l44.23,-17.17L847.62,0L850.53,1.38z"
        />
        <path
          id="BN-TE"
          title="Temburong"
          className="land"
          d="M933.75,102.13L933.75,108.12L937.91,118.78L937.46,121L932.1,120.78L930.98,129.66L936.79,132.32L947.52,131.43L947.96,134.76L958.02,135.43L955.79,141.2L959.14,149.19L966.51,157.85L999.59,183.6L999.36,215.17L1008.08,229.6L1018.14,240.25L1013,265.56L1015.01,268.44L1024.62,270.22L1026.85,277.77L1021.71,295.75L1036.68,307.29L1042.27,314.84L1045.18,334.37L1044.28,341.7L1030.43,380.9L1032.66,386.23L1029.76,393.56L1035.79,415.09L1034.23,426.19L1038.7,436.4L1032.88,453.27L1035.57,463.26L1051.66,479.73L1053.89,490.61L1069.76,497.05L1072.22,501.04L1076.69,502.15L1087.86,526.79L1097.69,533.01L1091.66,550.32L1085.4,553.87L1082.05,559.87L1072.22,567.19L1067.3,567.42L1056.57,554.54L1050.07,549.97L1048.06,546.42L1048.28,536.87L1036.44,537.09L1031.97,532.87L1022.36,538.65L1013.64,535.32L1011.63,533.1L1009.17,516L985.26,518.67L967.61,511.79L956.21,512.45L938.55,504.46L911.29,511.34L891.63,499.13L898.33,486.7L900.01,474.94L892.19,461.39L896.43,439.86L892.86,431.65L892.63,421.21L885.71,407.01L887.27,390.58L876.99,372.6L873.86,354.89L862.69,340.23L850.84,308.93L858.89,298.06L848.16,290.95L849.5,284.96L854.2,284.74L854.2,282.74L851.07,280.52L850.4,272.75L844.59,266.31L851.29,262.32L848.16,259.21L845.7,261.43L842.8,259.21L847.04,245.44L843.47,242.2L849.5,235.99L841.9,231.1L837.21,233.32L834.08,225.33L836.09,221.12L842.57,218.01L840.56,208.68L842.57,204.24L858.89,192.7L857.55,188.26L847.49,188.26L842.8,184.27L841.68,180.27L844.36,169.39L850.4,169.17L872.74,152.08L883.92,149.19L889.06,150.52L894.87,145.64L900.45,148.52L905.59,147.86L906.04,143.2L903.36,138.54L904.48,137.2L908.95,139.2L908.72,136.54L903.58,132.32L898.44,131.43L891.52,122.77L889.95,117L891.96,111.67L897.33,110.56L903.36,105.9z"
        />
        <path
          id="BN-TU"
          title="Tutong"
          className="land"
          d="M660.5,83.38L662.6,116.66L654.47,156.41L637.22,196.81L634.48,241.15L634.48,241.15L632.62,242.99L634.79,244.83L642.83,247.6L642.83,253.43L637.88,260.19L638.19,266.95L645.92,269.1L648.22,277.3L657.61,279.55L654.58,287.23L655.2,292.15L657.67,294.3L656.74,311.19L663.6,319.74L665.4,334.6L662.93,338.29L663.86,340.75L669.42,343.51L667.57,347.2L670.04,353.04L668.81,365.94L673.14,387.75L671.28,429.84L674.06,445.82L678.7,454.73L679.94,466.71L692.93,474.7L699.73,473.47L702.83,475.62L712.72,475.31L720.46,471.63L724.17,472.55L726.02,477.77L724.79,489.32L721.38,496.08L723.24,503.15L717.98,535.1L709.32,549.85L699.73,560.29L698.13,566.04L698.13,566.04L641.48,544.48L625.45,488.98L536.6,396.63L519.2,386.9L518.74,378.25L522.86,368.7L523.78,354.6L508.21,328.67L486.22,335.49L456.46,295.91L457.37,287.72L454.63,275.44L450.5,261.79L445.37,253.27L445.37,253.27L489.86,215.38L503.9,212.36L508.01,207.84L508.22,203.97L499.58,210.21L497.21,209.78L556.6,156.38L625.29,112.24L645.38,98.02L648.83,93.07L651.43,93.29L652.07,90.7L654.45,90.92L655.1,87.9L657.26,88.77z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(BruneiDarussalam))
