import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 645

const cities = {
  Moroni: { x: 9, y: 227 }
}

const Comoros = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map comoros">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="KM-AN"
          title="Anjouan"
          className="land"
          d="M788.66,634.3L799.37,542.37L761.6,437.41L727.94,450.59L704.64,509.66L602.82,497.33L711.78,554.14L788.66,634.3z"
        />
        <path
          id="KM-GC"
          title="Grande Comore"
          className="land"
          d="M84.65,0L36.42,11.38L0.63,241.85L33.05,290.37L134.57,354.7L171.5,323.85L152.17,286.31L97.69,151.55L108.5,24.17L84.65,0z"
        />
        <path
          id="KM-MO"
          title="Moheli"
          className="land"
          d="M348.42,589.24L255.23,544.91L273.17,616.95L390.71,628.72L348.42,589.24z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Comoros))
