import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 439
const height = 424

const cities = {
  Malabo: { x: 444, y: 6 }
}

const EquatorialGuinea = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map equatorial-guinea">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`380 -10 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="GQ-BS"
          title="Bioko Sur"
          className="land"
          d="M437.75,25.51L420.36,17.92L394.52,47.61L398.34,70.5L428.67,79L453.48,34.46L437.75,25.51z"
        />
        <path
          id="GQ-BN"
          title="Bioko Norte"
          className="land"
          d="M430.94,0L420.36,17.92L437.75,25.51L453.48,34.46L461.76,1.43L430.94,0z"
        />
        <path
          id="GQ-WN"
          title="Wele-Nzás"
          className="land"
          d="M732.82,256.47L684.87,258.6L692.92,304.47L749.73,306.46L720.99,343.1L747.34,386.6L799.46,386.77L798.85,284.13L755.01,284.08L746.06,251.88L732.82,256.47z"
        />
        <path
          id="GQ-LI"
          title="Litoral"
          className="land"
          d="M519.35,402.66L515.5,395.56L514.77,400.23L519.35,402.66zM608.39,223.05L581.68,200.79L585.87,255.8L557.54,296.47L576.73,302.68L555.72,306.58L521.08,361.23L554.33,380.65L573,368.01L602.17,396.8L612.54,386.69L651.14,367.12L628.07,317.87L601,305.9L639.07,223.51L608.39,223.05z"
        />
        <path
          id="GQ-KN"
          title="Kié-Ntem"
          className="land"
          d="M798.85,284.13L797.46,223.49L669.81,223.5L675.65,257.6L684.87,258.6L732.82,256.47L746.06,251.88L755.01,284.08L798.85,284.13z"
        />
        <path
          id="GQ-CS"
          title="Centro Sur"
          className="land"
          d="M675.65,257.6L669.81,223.5L639.07,223.51L601,305.9L628.07,317.87L651.14,367.12L612.54,386.69L747.34,386.6L720.99,343.1L749.73,306.46L692.92,304.47L684.87,258.6L675.65,257.6z"
        />
        <path
          id="GQ-AN"
          title="Annobón"
          className="land"
          d="M0.92,733.06L1.5,724.68L0.54,730.34L0.92,733.06z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(EquatorialGuinea))
