import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 622
const height = 370

const cities = {
  Prague: { x: 215, y: 582 }
}

const CzechRepublic = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map czech-republic">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 430 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="CZ-JC"
          title="Jihočeský"
          className="land"
          d="M151.5,659.74L161.16,658.24L166.59,661.51L172.49,659.07L176.22,653.32L179.3,653.76L179.23,657.65L183.99,657.83L191.63,657.24L193.19,652.96L197.5,652.47L202.69,657.97L210.13,656.53L209.46,653.54L214.72,657.31L215.87,655.32L223.37,657.31L225.99,655.54L225.34,659.1L230.03,658.93L231.17,661.15L234.41,656.31L239.21,654.88L238.64,648.12L242.07,644.72L249.12,651.12L251.98,649.57L253.26,654.22L256.98,654.65L256.98,654.65L257.9,658.24L254.1,659.54L257.06,663.9L252.99,678.74L257.07,681.63L254.43,684.78L261.2,692.8L265.81,689.85L272.54,693.59L272.2,696.42L269.9,695.86L274.26,696.63L275.72,700.22L280.34,698.15L285.9,702.42L293.99,700.87L293.54,707.79L301.44,714.49L302.99,712.43L305.38,714.3L314.36,713L317.04,720.4L313.11,720.15L311.38,727.19L307.2,731.04L316.09,737.75L316.09,737.75L312,743.32L312,743.32L305.47,737.32L288.33,731.4L286.77,737.07L277.64,738.6L277.23,731.63L262.74,728.61L261.68,752.31L258.87,758.42L260.93,762.41L245.86,761L245.74,767.18L238.4,773.56L236.08,787.87L232.76,787.94L227.94,781.55L223.42,784.87L218.11,783.14L215.15,778.9L212.8,779.61L211.57,787.03L207.67,786.39L207.85,789.25L205.01,788.92L202.86,792L197.24,788.01L178.73,786.11L173.62,780.1L178.07,775.32L161.28,762.59L155.72,761.66L148.99,746.37L143,747.2L139.22,737.77L137.3,739.28L134.76,734.81L132.08,735.16L132.08,735.16L131.48,728.07L136.91,723.04L135.49,710.57L139.26,711.97L145.03,708.21L144.83,704.91L147.3,703.77L144.85,699.79L148.16,695.18L145.55,692.18L147.99,692.38L147.58,687.85L152.09,685.9L153.73,680.4L150.87,675.63L153.45,670.75L149.98,663.51z"
        />
        <path
          id="CZ-JM"
          title="Jihomoravský"
          className="land"
          d="M502.56,751.01l-9.15,5.22l-8.51,-4.03l-8.01,4.4l-3.91,-4.23l-11.38,-4.32l-13.65,15.01l-9.35,20.16l-2.03,-0.85l-1.49,-12.98l-9.2,1.63l-6.81,-4.37l-3.88,1.34l-1.65,-7.23l-6.32,-0.15l-4.82,-4.35l-8.25,1.92l-6.31,9.67l-3.43,-2.42l-0.9,2.08l-20.69,-1.87l-9.9,-4.58l-3.89,-3.63l0.61,-2.46l-5.78,-1.57l-4.89,-5.84l-5.47,0.37l-2.42,3.18l-5.42,-0.53l-6.74,-5.59l-6.93,-1.63l0,0l4.1,-5.57l0,0l2.38,-1.37l3.28,2.83l1.15,-3.53l4.05,-0.55l2.09,2.88l7.99,-8.79l5.14,0.23l0.89,-4.1l2.93,1.32l3.9,-3.71l3.21,4.06l3.32,-2.9l4.82,2.31l14.61,-9.49l2.01,-3.99l-0.91,-2.12l-2.21,1.05l-0.87,-3.78l4.36,-4.95l-3.28,-1.63l1.37,-5.55l3.18,-0.69l-2.58,-4.67l2.73,-4.63l8.8,-4.77l-1.96,-11.64l2.23,-4.54l-3.23,-3.25l2.89,0.89l2.96,-4.25l-4.13,-3.9l1.64,-4.2l0,0l1.35,1.2l3.25,-3.69l8.94,1.31l1.36,-5.65l22.51,4.37l0,0l-0.82,3.52l-4.5,-1.1l-0.81,5.42l8.72,1.6l-4.1,3.06l0.83,7.3l2.82,2.88l-2.88,3.31l5.7,4.27l4.7,-7.27l-2.78,-6.38l5.32,-1.28l5.41,13.24l3.73,1.05l0.94,-2.52l-1.77,5.73l9.72,5.26l1.76,6.94l0,0l-2.66,4.08l3.33,7.54l-1.83,2.1l5.1,0.14l-8.25,13.35l9.4,-2.05l0.69,8.23l12.48,3.66l-0.58,3.82l3.68,-0.58l3.49,4.38l5.86,-2.42l4.64,2.94l-0.9,3.4l5.65,1.67L502.56,751.01z"
        />
        <path
          id="CZ-KA"
          title="Karlovarský"
          className="land"
          d="M72.5,531.11L77.58,534.84L77.58,534.84L91.17,537.98L92.73,542.96L101.21,541.01L103.69,544.6L102.27,557.71L105.32,559.92L103.35,562.63L105.88,562.97L108.22,568.27L106.27,569.78L107.81,572.59L103,573.1L107,573.95L109.54,577.94L109.54,577.94L107.75,577.98L107.72,584.57L103.32,584.99L103.38,592.3L91.08,586.98L78.64,595.56L79.19,599.84L75.72,602.97L63.1,600.25L55.43,602.23L53.4,607.06L50.29,603.57L45.63,606.76L41.7,605.1L41.7,605.1L34.49,599.76L36.16,594.5L33.49,592.55L30.93,594.03L30.84,591.53L21.5,587.31L20.55,584.46L15.45,583.68L16.71,581.14L9.89,576.52L11.35,568.18L5.42,558.71L0.13,556.78L4.51,553.07L0.97,547.1L8.51,546.52L9.99,553.55L14.88,553.8L15.89,556.67L13.49,557.31L18.38,560.85L18.04,567.06L22.29,567.63L21.74,557.91L24.38,557.86L27.95,546.58L36.11,542.68L38.16,535.97L52.66,533.15L55.71,535.92L58.31,530.94L63.72,528.54L65.08,531.09L65.9,527.06z"
        />
        <path
          id="CZ-KR"
          title="Královéhradecký"
          className="land"
          d="M313.77,482.02L326.89,487.71L336.79,485.11L340.94,496.61L352.61,495.21L355.46,502.91L352.27,505.28L355.57,507.34L362.75,498.21L370.38,503.26L373.21,502.3L374.66,497.03L384.44,498.34L393.65,510.18L390.06,511.63L389.72,517.38L386.1,521.16L381.78,520.44L371.22,530.91L378.81,540.2L381.09,537.97L386.18,538.46L388.17,545.59L392.94,546.7L404.04,560.75L406.19,571.74L406.19,571.74L397.73,568.57L396.16,572.62L384.49,578.7L385.58,580.96L381.6,583.24L383.55,586.18L375.93,585.88L375.84,584.08L371.25,586.39L360.3,579.63L359.3,576.08L354.49,576.13L352.75,569.72L346.09,567.82L338.67,573.48L333.2,567.29L325.39,574.24L321.71,572.12L316.09,573.12L314.45,570.75L310.33,572.81L310.28,575.39L303.1,577.04L303.1,577.04L300.67,572.24L294.59,571.43L298.93,564.42L297.25,551.85L294.82,549.61L290.81,552.24L281.8,550.27L282.65,546.96L274.93,543.24L272.42,538.72L277.3,534.32L273.36,520.95L276.16,518.13L276.16,518.13L283.23,522.14L287.14,517.63L296.47,522.03L297.45,520.2L298.58,524.21L303.08,522.96L302.58,518.31L306.25,514.79L311.55,519.43L320.26,518.14L314.72,509.85L317.51,505.11L314.18,501.86L314.96,487.85L311.35,481.61L311.35,481.61z"
        />
        <path
          id="CZ-LI"
          title="Liberecký"
          className="land"
          d="M264.93,446.7L272.58,448.58L272.57,451.66L275.92,451.35L278.38,447.31L280.22,453.02L284.85,450.4L289.48,456.8L287.32,459.92L288.15,465.92L296.32,473.74L296.8,481.88L302.62,477.6L311.35,481.61L311.35,481.61L314.96,487.85L314.18,501.86L317.51,505.11L314.72,509.85L320.26,518.14L311.55,519.43L306.25,514.79L302.58,518.31L303.08,522.96L298.58,524.21L297.45,520.2L296.47,522.03L287.14,517.63L283.23,522.14L276.16,518.13L276.16,518.13L272.17,516.77L268.92,511.3L266.61,512.16L263.54,506.54L260.14,508.35L256.21,504.5L243.72,519.87L238.24,518.63L235.06,524.03L225.97,525.02L224.85,519.79L222.11,521.9L218.81,519.99L217.93,522.42L216.38,520.58L216.38,520.58L207.01,507.16L204.21,496.45L206.87,494.26L206.82,490.12L211.54,487.89L211.33,483.6L214.02,481.55L212.79,477.13L222.58,479.07L224.06,473.34L228.69,470.42L228.69,470.42L242.2,475.99L244.87,475.59L247.9,468.27L263.26,469.2L262.14,461.18L265.07,454.87L260.24,451.53z"
        />
        <path
          id="CZ-MO"
          title="Moravskoslezský"
          className="land"
          d="M510.63,549.5L509.45,555.69L513.04,558.65L512.46,562.82L497.49,569.41L502.67,576.25L506.94,575.48L509.92,578.15L514.19,589.13L518.77,590.37L522.52,595.72L527.09,594.97L534.74,589.31L538.41,591.19L538.35,586.8L534.72,586.49L537.37,582.66L542.4,585.76L544.81,592.65L548.26,594.36L553.01,592.16L553.75,595.82L559.41,596.86L563.18,603.79L566.19,601.08L564.5,599.09L573.21,600.57L578.55,605.33L586.11,602.91L585.48,608.67L589.04,611.29L585.92,615.99L590.88,630.68L598.44,633.19L601.41,637.16L607.6,637.5L612,654.7L610.41,658.77L602.75,663.06L585.55,660.56L577.41,674.28L571,676.08L571,676.08L567.42,674.8L567.53,671.1L561.33,662.97L552.57,663.1L545.81,658.83L534.11,661.17L531.9,656.43L526.67,656.33L526.67,656.33L525.69,652.72L521.13,654.54L521.95,650.41L511.27,637.11L506.46,637.06L506.86,628.92L501.16,626.58L497.73,629.15L492.25,625.34L490.9,628.23L487.56,625.66L482.67,626.05L476.22,618.17L473.17,620.18L466.57,616.27L463.41,617.36L464.19,611.62L460.7,613.62L457.23,608.95L461.99,597.76L457.91,594.27L465.19,583.09L465.83,571.72L472.04,565.49L474.74,565.92L478.21,560.58L481.76,562.43L482.96,556.09L482.96,556.09L488.71,553.15L499.54,554.38L506.14,549.53L506.12,545.69z"
        />
        <path
          id="CZ-OL"
          title="Olomoucký"
          className="land"
          d="M436.42,529.21L441.61,532.89L453.85,534.77L456.87,538.17L462.33,537.49L462.07,540.5L466.39,545.02L470.36,547.08L475.58,545.64L475.63,554.55L479.31,552.05L482.96,556.09L482.96,556.09L481.76,562.43L478.21,560.58L474.74,565.92L472.04,565.49L465.83,571.72L465.19,583.09L457.91,594.27L461.99,597.76L457.23,608.95L460.7,613.62L464.19,611.62L463.41,617.36L466.57,616.27L473.17,620.18L476.22,618.17L482.67,626.05L487.56,625.66L490.9,628.23L492.25,625.34L497.73,629.15L501.16,626.58L506.86,628.92L506.46,637.06L511.27,637.11L521.95,650.41L521.13,654.54L525.69,652.72L526.67,656.33L526.67,656.33L525.32,660.11L520.08,658.5L514.2,667.4L514.2,667.4L513.73,670.34L513.73,670.34L509.03,670.3L506.52,666.82L503.95,670.1L502.54,668.42L502.54,668.42L500.25,668.73L502.73,673.39L502.73,673.39L500.81,675.62L489.47,675.31L490.53,677.82L486.74,681.77L484.43,678.47L477.75,678.28L477.31,673.57L474.06,677.83L476.08,683.36L470.49,689.13L461.46,689.85L459.97,693.81L458.26,692.87L458.26,692.87L456.49,685.93L446.77,680.67L448.55,674.94L447.61,677.46L443.88,676.41L438.47,663.17L433.16,664.44L435.93,670.82L431.23,678.1L425.54,673.83L428.41,670.52L425.59,667.63L424.76,660.33L428.85,657.28L420.13,655.68L420.94,650.26L425.44,651.36L426.27,647.84L426.27,647.84L426.17,640.02L430.05,636.49L430.16,631.59L424.9,630.08L426.25,624.39L420.08,615.78L422.94,609.32L417.92,592.02L421.24,592.61L426.96,587.43L426.35,576.67L431.79,563.94L431.79,563.94L441.82,557.44L443.92,561.44L446.55,559.53L444.3,549.34L439.24,547.48L435.83,537.04L431.25,534.45L433.27,529.46z"
        />
        <path
          id="CZ-PA"
          title="Pardubický"
          className="land"
          d="M431.65,563.92L431.79,563.94L431.79,563.94L426.35,576.67L426.96,587.43L421.24,592.61L417.92,592.02L422.94,609.32L420.08,615.78L426.25,624.39L424.9,630.08L430.16,631.59L430.05,636.49L426.17,640.02L426.27,647.84L426.27,647.84L403.75,643.47L402.4,649.12L393.46,647.81L390.2,651.5L388.85,650.3L388.85,650.3L371.1,636.69L362.92,636.13L353.65,628.42L350.05,635.17L346.27,635.95L339.8,631.34L341.23,627.67L333.21,626.04L333.85,622.67L329.82,622.87L323.32,617.77L316.72,618.82L308.05,611.31L308.05,611.31L311.15,597.17L296.47,586.48L299.51,585.27L297.55,583.43L300.53,583.05L299.61,579.61L301.81,579.46L299.94,577.14L303.1,577.04L303.1,577.04L310.28,575.39L310.33,572.81L314.45,570.75L316.09,573.12L321.71,572.12L325.39,574.24L333.2,567.29L338.67,573.48L346.09,567.82L352.75,569.72L354.49,576.13L359.3,576.08L360.3,579.63L371.25,586.39L375.84,584.08L375.93,585.88L383.55,586.18L381.6,583.24L385.58,580.96L384.49,578.7L396.16,572.62L397.73,568.57L406.19,571.74L406.19,571.74L417.41,578.23L424.47,571.43L427.13,564.87z"
        />
        <path
          id="CZ-ZL"
          title="Zlínský"
          className="land"
          d="M567.42,674.8L567.53,671.1L561.33,662.97L552.57,663.1L545.81,658.83L534.11,661.17L531.9,656.43L526.67,656.33L526.67,656.33L525.32,660.11L520.08,658.5L514.2,667.4L514.2,667.4L513.73,670.34L513.73,670.34L509.03,670.3L506.52,666.82L503.95,670.1L502.54,668.42L502.54,668.42L500.38,668.67L502.73,673.39L502.73,673.39L500.81,675.62L489.47,675.31L490.53,677.82L486.74,681.77L484.43,678.47L477.75,678.28L477.31,673.57L474.06,677.83L476.08,683.36L470.49,689.13L461.46,689.85L459.97,693.81L458.26,692.87L458.26,692.87L455.6,696.95L458.93,704.48L457.1,706.58L462.2,706.72L453.94,720.07L463.35,718.02L464.03,726.25L476.51,729.9L475.93,733.72L479.61,733.14L483.1,737.53L488.96,735.11L493.6,738.05L492.7,741.45L498.35,743.12L502.56,751.01L502.56,751.01L507.56,750.2L514.7,741.41L523.78,740.93L528.07,728L540.7,725.83L544.89,718.02L547.65,696.82L551.12,691.3L567.47,686.1L570.91,682.38L571,676.08z"
        />
        <path
          id="CZ-PL"
          title="Plzeňský"
          className="land"
          d="M109.54,577.94L112.5,579.63L114.42,577.53L115.91,580.89L119.3,579.29L119.3,579.29L121.18,583.12L118.35,587.35L128.79,586.03L130.71,587.46L127.66,588.09L133.74,592.64L142.61,592.33L145.6,596.9L149.84,596.78L149.48,600.29L157.44,602.07L157.67,610.37L155.3,610.4L153.03,625.32L156.3,626.31L143.04,634.33L143.81,637.18L148.05,636.76L144.39,639.1L147.11,641.44L146.26,643.74L150.41,644.89L148.91,655.36L151.34,655.31L151.5,659.74L151.5,659.74L149.98,663.51L153.45,670.75L150.87,675.63L153.73,680.4L152.09,685.9L147.58,687.85L147.99,692.38L145.55,692.18L148.16,695.18L144.85,699.79L147.3,703.77L144.83,704.91L145.03,708.21L139.26,711.97L135.49,710.57L136.91,723.04L131.48,728.07L132.08,735.16L132.08,735.16L128.19,734.96L127.1,738.79L118.73,732.54L117.82,723.33L108.41,714.4L103.55,715.04L98.41,711.85L97.79,706.95L85.29,694.24L84.86,688.77L79.71,684.63L62.47,682.86L60.31,676.2L51.27,670.7L50.07,658.32L45.16,656.32L42.5,644.97L39.58,645.18L39.01,635.69L31.8,633.3L28.34,627.86L28.18,625.32L34.58,621.72L34.79,614.85L41.7,605.1L41.7,605.1L45.63,606.76L50.29,603.57L53.4,607.06L55.43,602.23L63.1,600.25L75.72,602.97L79.19,599.84L78.64,595.56L91.08,586.98L103.38,592.3L103.32,584.99L107.72,584.57L107.75,577.98z"
        />
        <path
          id="CZ-PR"
          title="Prague"
          className="land"
          d="M220.72,567.95L220.89,568.27L220.89,568.27L223.55,569.91L222.09,571.37L226.28,570.27L226.85,573.65L236.25,578.95L235.81,581.8L230.43,583.95L232.23,585.5L232.23,585.5L232.93,589.33L232.93,589.33L230.44,592.85L225.22,589.71L220.38,590.48L214.3,595.84L208.83,596.26L208.4,600.14L201.92,597.7L203.6,593.16L200.43,592.48L200.05,587.71L195,583.5L199.16,581L192.73,577.53L200.15,573.69L200.77,575.76L205.33,575.52L205.76,571.03L220.61,567.73L220.61,567.73z"
        />
        <path
          id="CZ-ST"
          title="Středočeský"
          className="land"
          d="M216.38,520.58l1.55,1.84l0.88,-2.44l3.3,1.91l2.74,-2.11l1.12,5.23l9.09,-0.99l3.18,-5.4l5.48,1.25l12.5,-15.37l3.92,3.85l3.4,-1.8l3.07,5.61l2.31,-0.85l3.25,5.46l3.99,1.36l0,0l-2.8,2.82l3.94,13.37l-4.88,4.4l2.51,4.52l7.72,3.71l-0.85,3.32l9.01,1.96l4.01,-2.63l2.43,2.24l1.68,12.56l-4.34,7.01l6.08,0.82l2.43,4.79l0,0l-3.16,0.1l1.87,2.32l-2.2,0.15l0.91,3.44l-2.97,0.38l1.96,1.84l-3.05,1.21l14.68,10.69l-3.1,14.14l0,0l-6.93,4.06l0.1,5.57l-3.55,-1.03l-3.15,4.27l-11.39,2.32l-3.92,9.01l0.7,2.76l4.4,0.54l0.68,5.78l-6.46,1.27l-0.8,3.39l-4.05,-2.99l-5.58,2.32l-6.88,-1.66l0.61,3.6l-4.18,0.11l-0.65,4.02l0,0l-3.72,-0.43l-1.28,-4.66l-2.86,1.56l-7.05,-6.4l-3.44,3.4l0.58,6.76l-4.8,1.43l-3.25,4.85l-1.14,-2.22l-4.68,0.17l0.64,-3.56l-2.61,1.76l-7.5,-1.99l-1.15,1.99l-5.26,-3.77l0.67,2.99l-7.43,1.44l-5.2,-5.5l-4.3,0.49l-1.56,4.28l-7.64,0.6l-4.76,-0.18l0.06,-3.89l-3.07,-0.44l-3.73,5.75l-5.9,2.44l-5.43,-3.28l-9.66,1.5l0,0l-0.16,-4.43l-2.43,0.06l1.5,-10.47l-4.15,-1.16l0.85,-2.3l-2.72,-2.34l3.66,-2.34l-4.24,0.42l-0.77,-2.84l13.26,-8.02l-3.27,-0.99l2.27,-14.92l2.37,-0.03l-0.23,-8.3l-7.97,-1.78l0.36,-3.52l-4.24,0.13l-2.98,-4.57l-8.87,0.31l-6.08,-4.55l3.06,-0.63l-1.93,-1.43l-10.43,1.32l2.83,-4.23l-1.88,-3.84l0,0l1.41,-2.63l3.36,0.35l0.71,-3.66l1.8,0.69l-0.22,-5.49l5.29,-3.58l5.55,-0.08l5.65,-5.83l7.19,1.35l5.7,-5.54l4.73,2.85l1.13,-3.79l7.08,-3.03l-0.33,-2.19l3.56,0.51l-0.86,-4.26l2.31,-1.81l21.7,2.49l2.41,-3.62l8.23,0.42l-0.59,-11l5.6,0.99l0.15,-4.4l4.48,0.54l-1.49,-6.73L216.38,520.58zM232.23,585.5L232.23,585.5l-1.79,-1.54l5.37,-2.15l0.44,-2.85l-9.39,-5.3l-0.58,-3.38l-4.19,1.1l1.46,-1.46l-2.67,-1.63l0,0l-0.28,-0.55l0,0l-14.85,3.31l-0.43,4.49l-4.56,0.24l-0.61,-2.07l-7.42,3.84l6.43,3.47l-4.16,2.5l5.05,4.21l0.38,4.77l3.17,0.67l-1.68,4.54l6.48,2.44l0.42,-3.88l5.47,-0.42l6.08,-5.36l4.84,-0.77l5.23,3.14l2.49,-3.52L232.23,585.5z"
        />
        <path
          id="CZ-US"
          title="Ústecký"
          className="land"
          d="M203,444.44L205.48,443.68L210.49,447.44L213.75,444.96L217.2,446.76L217.91,443.42L217.73,446.95L223.69,448.73L226.97,452.97L223.82,461.78L231.44,459.92L228.69,470.42L228.69,470.42L224.06,473.34L222.58,479.07L212.79,477.13L214.02,481.55L211.33,483.6L211.54,487.89L206.82,490.12L206.87,494.26L204.21,496.45L207.01,507.16L216.38,520.58L216.38,520.58L213.85,521.83L215.34,528.56L210.87,528.02L210.71,532.42L205.12,531.43L205.71,542.44L197.48,542.01L195.07,545.63L173.37,543.14L171.07,544.95L171.93,549.22L168.36,548.71L168.69,550.9L161.61,553.93L160.48,557.72L155.75,554.87L150.05,560.42L142.87,559.06L137.22,564.9L131.66,564.98L126.37,568.56L126.59,574.04L124.79,573.35L124.08,577.01L120.71,576.66L119.3,579.29L119.3,579.29L115.91,580.89L114.42,577.53L112.5,579.63L109.54,577.94L109.54,577.94L107,573.95L103,573.1L107.81,572.59L106.27,569.78L108.22,568.27L105.88,562.97L103.35,562.63L105.32,559.92L102.27,557.71L103.69,544.6L101.21,541.01L92.73,542.96L91.17,537.98L77.58,534.84L77.58,534.84L84.14,528.63L85.15,519.94L90,521.38L94.17,518.71L99.91,520.85L104.61,508.28L111.44,510.04L111.67,506.13L116.32,503.28L115.9,500.06L124.47,506.69L129.71,501.58L129.87,492.17L142.37,488.45L146.23,490.5L148,488.15L159.69,489.03L163.47,486.61L163.65,479.67L167.01,480.08L171.74,475.9L175.38,478.14L179.64,477L192.93,470.15L193.78,466.26L196.79,465.16L200.14,466.74L207.68,464.71L208.6,459.28L200.8,456.62L202.1,452.31L196.16,451.86L197.43,444.51L201.15,442.19z"
        />
        <path
          id="CZ-VY"
          title="Vysočina"
          className="land"
          d="M308.05,611.31L316.72,618.82L323.32,617.77L329.82,622.87L333.85,622.67L333.21,626.04L341.23,627.67L339.8,631.34L346.27,635.95L350.05,635.17L353.65,628.42L362.92,636.13L371.1,636.69L388.85,650.3L388.85,650.3L387.22,654.5L391.35,658.4L388.39,662.65L385.5,661.76L388.73,665.01L386.49,669.55L388.46,681.19L379.66,685.96L376.93,690.59L379.5,695.26L376.32,695.95L374.95,701.5L378.23,703.13L373.87,708.08L374.74,711.86L376.95,710.81L377.86,712.94L375.85,716.93L361.24,726.42L356.42,724.11L353.1,727.02L349.89,722.96L346,726.67L343.07,725.35L342.18,729.45L337.04,729.21L329.04,738.01L326.95,735.12L322.9,735.67L321.75,739.2L318.47,736.37L316.09,737.75L316.09,737.75L307.2,731.04L311.38,727.19L313.11,720.15L317.04,720.4L314.36,713L305.38,714.3L302.99,712.43L301.44,714.49L293.54,707.79L293.99,700.87L285.9,702.42L280.34,698.15L275.72,700.22L274.26,696.63L269.9,695.86L272.2,696.42L272.54,693.59L265.81,689.85L261.2,692.8L254.43,684.78L257.07,681.63L252.99,678.74L257.06,663.9L254.1,659.54L257.9,658.24L256.98,654.65L256.98,654.65L257.63,650.64L261.81,650.52L261.2,646.92L268.08,648.58L273.66,646.25L277.71,649.25L278.52,645.85L284.98,644.58L284.3,638.81L279.9,638.26L279.21,635.5L283.13,626.5L294.52,624.18L297.67,619.91L301.22,620.94L301.12,615.36z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(CzechRepublic))
