import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 872

const cities = {
  'Port Louis': { x: 295, y: 246 }
}

const Mauritius = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map mauritius">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="MU-PW"
          title="Plaines Wilhems"
          className="land"
          d="M272.14,326.44L239.02,670.25L298.29,731.63L394.16,696.29L481.32,594.02L296.54,330.15L272.14,326.44z"
        />
        <path
          id="MU-PL"
          title="Port Louis"
          className="land"
          d="M394.56,349.21L383.7,211.31L309.45,211.51L260.83,279.33L272.14,326.44L296.54,330.15L394.56,349.21z"
        />
        <path
          id="MU-SA"
          title="Savanne"
          className="land"
          d="M155.95,861.71L532.07,851.42L472.6,701.87L394.16,696.29L298.29,731.63L143.15,783.71L155.95,861.71z"
        />
        <path
          id="MU-RN"
          title="Rivière Noire"
          className="land"
          d="M155.95,861.71L143.15,783.71L298.29,731.63L239.02,670.25L272.14,326.44L260.83,279.33L120.54,420.37L88.57,739.47L0.46,745.53L155.95,861.71z"
        />
        <path
          id="MU-RR"
          title="Rivière du Rempart"
          className="land"
          d="M449.87,0L531.87,268.87L698.74,166.21L611.16,0L449.87,0z"
        />
        <path
          id="MU-PA"
          title="Pamplemousses"
          className="land"
          d="M449.87,0L309.45,211.51L383.7,211.31L394.56,349.21L494.58,319.64L531.87,268.87L449.87,0z"
        />
        <path
          id="MU-MO"
          title="Moka"
          className="land"
          d="M394.56,349.21L296.54,330.15L481.32,594.02L592.88,567.99L530.12,436.04L582.42,384.02L494.58,319.64L394.56,349.21z"
        />
        <path
          id="MU-GP"
          title="Grand Port"
          className="land"
          d="M771,527.12L592.88,567.99L481.32,594.02L394.16,696.29L472.6,701.87L532.07,851.42L681.97,751.31L664,670.26L771,527.12z"
        />
        <path
          id="MU-BL"
          title="Flacq"
          className="land"
          d="M771,527.12L799.54,385.18L698.74,166.21L531.87,268.87L494.58,319.64L582.42,384.02L530.12,436.04L592.88,567.99L771,527.12z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Mauritius))
