import actions from '../actions/action-types'
import {
  getPageLimitNumber,
  getDisplayDirectoryLimits,
  calculateResult
} from '../../pages/mathematics/util'
import { getRandomItemFromList, createAllNumbersList } from '../../lib/methodsUtil'

const initialState = {
  actual: [1, 1],
  resultsList: [],
  randomList: [],
  numbersToChooseFrom: {
    first: [],
    second: []
  }
}

const mathematicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.randomizeNumbers: {
      const pagename = action.payload

      let numbersToChooseFrom = state.numbersToChooseFrom
      let { first, second } = numbersToChooseFrom

      const mathPageName = pagename.replace(/_quotient|_remainder/, '')

      // if the number have all been seen, we reset the first and second list with all the numbers to choose from for another round.
      if (first.length === 0 || second.length === 0) {
        const limit = getPageLimitNumber[mathPageName]
        const allNumbersList = createAllNumbersList(limit)
        first = allNumbersList
        second = allNumbersList
        numbersToChooseFrom = { first, second }
      }

      // pick random numbers for first and second
      let firstNumber = getRandomItemFromList(first)
      let secondNumber = getRandomItemFromList(second)

      const shouldHaveHigherNumberFirst = [
        'beginner_subtractions',
        'easy_subtractions',
        'medium_subtractions',
        'beginner_divisions',
        'easy_divisions',
        'medium_divisions',
        'hard_divisions'
      ].includes(mathPageName)

      if (shouldHaveHigherNumberFirst && secondNumber > firstNumber) {
        const tempNumber = secondNumber
        secondNumber = firstNumber
        firstNumber = tempNumber
      }

      const updatedResultsList = state.resultsList.slice()
      const result = calculateResult(pagename, firstNumber, secondNumber)
      updatedResultsList.push(result)

      const directoryLimits = getDisplayDirectoryLimits[mathPageName]
      const updatedRandomList = state.randomList.slice()
      updatedRandomList.push(Math.floor(Math.random() * directoryLimits[0] + directoryLimits[1]))

      return {
        ...state,
        actual: [firstNumber, secondNumber],
        resultsList: updatedResultsList,
        randomList: updatedRandomList,
        numbersToChooseFrom: {
          first: first.filter(number => number !== firstNumber),
          second: second.filter(number => number !== secondNumber)
        }
      }
    }

    case actions.resetMathematics: {
      return initialState
    }

    default:
      return state
  }
}

export default mathematicsReducer
