import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 883

const cities = {
  Montevideo: { x: 344, y: 856 }
}

const Uruguay = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map uruguay">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="UY-RN"
          title="Río Negro"
          className="land"
          d="M280.55,422.17L208.07,399.09L143.1,413.7L93.41,443.84L34.28,422.06L54.75,504.56L42.38,530.35L10.15,535.13L4.09,589.77L57.25,557.65L54.14,537.92L85.77,520.41L115.91,528.52L134.78,517.07L171.53,539.67L194.88,535.38L187.88,522.18L233.94,510.94L264.34,485.05L280.55,422.17z"
        />
        <path
          id="UY-PA"
          title="Paysandú"
          className="land"
          d="M316.42,290.12L290.7,311.39L189.08,298.24L83.98,242.15L57.29,247.11L66.25,263.08L55.79,298.25L32.6,315.31L48.61,384.38L34.28,422.06L93.41,443.84L143.1,413.7L208.07,399.09L280.55,422.17L327.89,319.05L316.42,290.12z"
        />
        <path
          id="UY-FS"
          title="Flores"
          className="land"
          d="M284.53,602.62L263.79,568.14L194.88,535.38L161.55,581.75L189.85,623.37L185.93,649.15L202.33,687.44L234.39,691.37L273.97,666.84L295.13,676.42L301.31,651.09L284.53,602.62z"
        />
        <path
          id="UY-AR"
          title="Artigas"
          className="land"
          d="M239.52,0L202.42,0.7L177.08,33.4L133.18,30.09L118.4,14.23L115.57,39.65L79.8,78.45L93.44,120.67L106.17,114.79L203.29,171.06L259.53,166.13L302.25,137.47L338.29,146.72L362.38,170.42L363.23,121.86L239.52,0z"
        />
        <path
          id="UY-SJ"
          title="San José"
          className="land"
          d="M295.13,676.42L273.97,666.84L234.39,691.37L202.33,687.44L222.82,721.06L199.64,736.51L188.79,775.97L233.09,816.63L309.94,839.28L310,828.37L293.74,803.16L304.39,773.18L293.85,759.82L295.13,676.42z"
        />
        <path
          id="UY-CO"
          title="Colonia"
          className="land"
          d="M202.33,687.44L154.73,675.27L115.35,682.91L37.58,653.82L0.46,671.55L85,780.13L188.79,775.97L199.64,736.51L222.82,721.06L202.33,687.44z"
        />
        <path
          id="UY-RO"
          title="Rocha"
          className="land"
          d="M586.64,841.74L623.26,821.19L617.14,796.76L630.72,801.89L627.54,819.53L644.71,816.16L702.2,765.39L710.33,730.4L737.65,702.93L760.41,647.04L736.19,630.47L739.05,535.72L701.65,547.32L647.76,597.16L611.02,628.13L593.98,680.72L586.64,841.74z"
        />
        <path
          id="UY-MO"
          title="Montevideo"
          className="land"
          d="M359.16,856.24L359.53,836.72L344.97,826.91L310,828.37L309.94,839.28L301.81,848.38L317.98,859.99L332.57,855.1L341.4,866.44L359.16,856.24z"
        />
        <path
          id="UY-MA"
          title="Maldonado"
          className="land"
          d="M522.08,872.37L586.64,841.74L593.98,680.72L560.33,709.76L539.32,765.84L444.42,807.78L459.44,841L522.08,872.37z"
        />
        <path
          id="UY-LA"
          title="Lavalleja"
          className="land"
          d="M647.76,597.16L620.52,575.94L592.4,594.24L542.13,573.96L497.03,582.07L498.04,595.57L466.73,624.79L464.26,671.08L422.42,732.02L444.42,807.78L539.32,765.84L560.33,709.76L593.98,680.72L611.02,628.13L647.76,597.16z"
        />
        <path
          id="UY-FD"
          title="Florida"
          className="land"
          d="M497.03,582.07L499.08,534.51L385.57,591.72L350.52,602.1L302.89,585.62L301.03,600.38L284.53,602.62L301.31,651.09L295.13,676.42L293.85,759.82L304.39,773.18L312.04,759.34L344.49,760.67L365.15,738.71L422.42,732.02L464.26,671.08L466.73,624.79L498.04,595.57L497.03,582.07z"
        />
        <path
          id="UY-CA"
          title="Canelones"
          className="land"
          d="M444.42,807.78L422.42,732.02L365.15,738.71L344.49,760.67L312.04,759.34L304.39,773.18L293.74,803.16L310,828.37L344.97,826.91L359.53,836.72L359.16,856.24L396.33,835.72L459.44,841L444.42,807.78z"
        />
        <path
          id="UY-TT"
          title="Treinta y Tres"
          className="land"
          d="M790.03,477.26L690.26,471.02L674.58,460.25L659.88,477.08L617.52,462.12L534.18,488.67L513.02,504.18L514.33,517.71L499.08,534.51L497.03,582.07L542.13,573.96L592.4,594.24L620.52,575.94L647.76,597.16L701.65,547.32L739.05,535.72L790.03,477.26z"
        />
        <path
          id="UY-TA"
          title="Tacuarembó"
          className="land"
          d="M567.97,356.18L529.46,310.33L422.67,299.09L415.41,260.42L389.87,224.84L355.27,203.06L341.11,211.05L311.97,261.42L316.42,290.12L327.89,319.05L280.55,422.17L264.34,485.05L331.02,481.41L339.38,463.99L379.53,442.06L390.54,447.47L399.95,427.18L437.22,424.08L444.72,410.52L466.28,422.42L509.81,417.91L540.04,389.64L552.7,352.45L567.97,356.18z"
        />
        <path
          id="UY-RV"
          title="Rivera"
          className="land"
          d="M596.23,274.68L575.51,238.47L478.97,200.45L426.53,130.66L386.89,170.47L363.46,171.77L356.54,191.85L337.37,201.41L341.11,211.05L355.27,203.06L389.87,224.84L415.41,260.42L422.67,299.09L529.46,310.33L567.97,356.18L585.23,337.78L577.92,331.71L596.23,274.68z"
        />
        <path
          id="UY-DU"
          title="Durazno"
          className="land"
          d="M466.28,422.42L444.72,410.52L437.22,424.08L399.95,427.18L390.54,447.47L379.53,442.06L339.38,463.99L331.02,481.41L264.34,485.05L233.94,510.94L187.88,522.18L194.88,535.38L263.79,568.14L284.53,602.62L301.03,600.38L302.89,585.62L350.52,602.1L385.57,591.72L499.08,534.51L514.33,517.71L491.77,516.4L468.18,495.54L466.28,422.42z"
        />
        <path
          id="UY-CL"
          title="Cerro Largo"
          className="land"
          d="M790.03,477.26L799.54,460.66L732.92,414.49L703.35,344.1L656.34,312.96L644.65,318.28L596.23,274.68L577.92,331.71L585.23,337.78L567.97,356.18L552.7,352.45L540.04,389.64L509.81,417.91L466.28,422.42L468.18,495.54L491.77,516.4L514.33,517.71L513.02,504.18L534.18,488.67L617.52,462.12L659.88,477.08L674.58,460.25L690.26,471.02L790.03,477.26z"
        />
        <path
          id="UY-SO"
          title="Soriano"
          className="land"
          d="M194.88,535.38L171.53,539.67L134.78,517.07L115.91,528.52L85.77,520.41L54.14,537.92L57.25,557.65L4.09,589.77L0.46,671.55L37.58,653.82L115.35,682.91L154.73,675.27L202.33,687.44L185.93,649.15L189.85,623.37L161.55,581.75L194.88,535.38z"
        />
        <path
          id="UY-SA"
          title="Salto"
          className="land"
          d="M362.38,170.42L338.29,146.72L302.25,137.47L259.53,166.13L203.29,171.06L106.17,114.79L93.44,120.67L92.26,141.07L76.49,148.07L85.01,167.72L57.29,247.11L83.98,242.15L189.08,298.24L290.7,311.39L316.42,290.12L311.97,261.42L341.11,211.05L337.37,201.41L356.54,191.85L363.46,171.77L362.38,170.42z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Uruguay))
