import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const MathematicsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'additions', 'subtractions', 'multiplications', 'divisions']}
    pageName="mathematics_page"
    topics={['topic_additions', 'topic_subtractions', 'topic_multiplications', 'topic_divisions']}
    topicNavigationPath="mathematics"
    subTopics={['additions', 'subtractions', 'multiplications', 'divisions']}
    borderRadius={0.35}
    SEODescription="E=MC² - Empower Maths & Calculus² - Via speech commands & different levels, play & have fun with Additions, Subtractions, Multiplications and Divisions."
  />
)

export default MathematicsPage
