import React from 'react'
import TopicNavigationPage from '../components/TopicNavigationPage'

const WordsPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu', 'colors', 'animals', 'food', 'letters_and_numbers', 'other_words']}
    pageName="words_page"
    topics={[
      'topic_colors',
      'topic_animals',
      'topic_food',
      'topic_letters_and_numbers',
      'topic_other_words'
    ]}
    topicNavigationPath="words"
    subTopics={['colors', 'animals', 'food', 'letters_and_numbers', 'other_words']}
    comingSoonTopics={['other_words']}
    borderRadius={0.15}
    SEODescription="E=MC² - Elevate My Conversation² - By Themselves, children As Young As 2 can learn some Vocabulary, New Words, Colours, ABC, Animals, Food in English or French"
  />
)

export default WordsPage
