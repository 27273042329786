import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 809
const height = 786

const cities = {
  Dodoma: { x: 457, y: 377 }
}

const Tanzania = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map tanzania">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="TZ-29"
          title="Njombe"
          className="land"
          d="M434.39,577.62L422.33,578.96L416.32,561.13L388.93,548.3L370.45,564.31L325.79,570.71L325.44,592.37L341.97,614.12L374.02,650.45L377.99,688.62L416.71,663.76L423.65,652.87L417.03,632.31L458.2,601.72L434.39,577.62z"
        />
        <path
          id="TZ-27"
          title="Geita"
          className="land"
          d="M229.36,157.76L235.56,140.68L218.58,122.67L222.42,115.39L189.28,99.61L183.88,82.29L166.4,97.26L132.87,91.75L111.91,120.83L119.61,131.94L150.81,241.14L169.85,230.77L170.49,218.22L180.87,196.98L202.34,191.92L205.7,163.11L229.36,157.76z"
        />
        <path
          id="TZ-30"
          title="Simiyu"
          className="land"
          d="M423.05,106.66L383.57,106.96L338.89,81.17L290.23,83.72L321.06,122.5L301.35,131.7L294.89,163.24L347.7,183.11L365.98,212.67L390.95,201.22L400.42,186.47L401.05,135.13L416.96,137.75L423.05,106.66z"
        />
        <path
          id="TZ-28"
          title="Katavi"
          className="land"
          d="M125.36,323.48L117.83,306.3L101.5,301.32L49.61,311.66L77.32,388.1L46.86,417.7L70.22,442.76L114.04,424.87L126.56,447.73L152.37,454.03L156.28,483.23L193.43,489.09L212.37,447.15L232.4,439.45L244.11,388.47L207.34,367.26L196.08,342.84L140.66,345.33L125.36,323.48z"
        />
        <path
          id="TZ-25"
          title="Tanga"
          className="land"
          d="M681.06,357L711.31,267.11L653.47,224.12L625.73,261.48L627.11,287.44L594.27,285.95L556.13,350.13L563.87,367.68L579.84,344.46L596.86,358.24L609.33,352.06L681.06,357z"
        />
        <path
          id="TZ-13"
          title="Mara"
          className="land"
          d="M336.33,0L244.57,0L243.25,33.49L297.27,37.26L284.83,66.08L305.69,70.73L280.24,74.73L290.23,83.72L338.89,81.17L383.57,106.96L423.05,106.66L432.67,88.68L425.84,50.59L336.33,0z"
        />
        <path
          id="TZ-09"
          title="Kilimanjaro"
          className="land"
          d="M582.24,137.98L561.87,133.28L545.25,143.34L556.25,178.85L572.45,179.11L584.07,199.11L589.83,247.18L625.73,261.48L653.47,224.12L594.79,180.47L599.18,148.61L582.24,137.98z"
        />
        <path
          id="TZ-26"
          title="Manyara"
          className="land"
          d="M625.73,261.48L589.83,247.18L584.07,199.11L572.45,179.11L556.25,178.85L521.4,191.4L518.07,221.4L504.57,224.38L475.66,189.92L461.89,203.16L461.66,184.38L448.53,198.65L435.39,188.44L400.4,208.97L409.37,223.23L398.75,242.93L432.63,277.03L444.79,277.54L454.17,250.24L501.45,228.79L497.5,320.53L541.84,359.82L556.13,350.13L594.27,285.95L627.11,287.44L625.73,261.48z"
        />
        <path
          id="TZ-01"
          title="Arusha"
          className="land"
          d="M582.24,137.98L425.84,50.59L432.67,88.68L423.05,106.66L416.96,137.75L401.05,135.13L400.42,186.47L390.95,201.22L400.4,208.97L435.39,188.44L448.53,198.65L461.66,184.38L461.89,203.16L475.66,189.92L504.57,224.38L518.07,221.4L521.4,191.4L556.25,178.85L545.25,143.34L561.87,133.28L582.24,137.98z"
        />
        <path
          id="TZ-23"
          title="Singida"
          className="land"
          d="M400.4,208.97L390.95,201.22L365.98,212.67L357.07,218.11L331.92,257.53L331.26,298.37L352.94,315.64L340.42,330.32L337.43,375.48L301.76,404.51L299.34,427.81L331.92,468.07L388.09,443.32L402.36,423.97L413.95,426.19L432.46,338.03L413.19,298.27L432.63,277.03L398.75,242.93L409.37,223.23L400.4,208.97z"
        />
        <path
          id="TZ-21"
          title="Ruvuma"
          className="land"
          d="M584.14,775.77L630.29,741.6L623.08,709.46L603.3,680.95L542.52,661.44L562.05,617.83L516.13,646.96L514.49,617.21L506.13,629.93L459.73,633.59L472.76,590.72L458.2,601.72L417.03,632.31L423.65,652.87L416.71,663.76L377.99,688.62L380.39,730.32L405.6,764.85L449.53,767.16L467.49,753.11L494.3,774.23L554.1,764.21L584.14,775.77z"
        />
        <path
          id="TZ-17"
          title="Mtwara"
          className="land"
          d="M630.29,741.6L658.94,753.11L767.8,709.03L799.49,675.01L765.52,662.81L746.1,673.89L740.77,693.23L723.92,674.3L623.08,709.46L630.29,741.6z"
        />
        <path
          id="TZ-12"
          title="Lindi"
          className="land"
          d="M715.84,527.5L662.11,540.3L644.35,517.14L609.31,500.28L578.24,540.62L577.59,587.35L562.05,617.83L542.52,661.44L603.3,680.95L623.08,709.46L723.92,674.3L740.77,693.23L746.1,673.89L765.52,662.81L759.27,650.89L745.01,652.97L752.9,636.96L736.15,609.55L741.63,590.74L723.31,569.64L735.16,571.34L720.85,556.2L715.84,527.5z"
        />
        <path
          id="TZ-04"
          title="Iringa"
          className="land"
          d="M413.95,426.19L402.36,423.97L388.09,443.32L331.92,468.07L326.01,492.19L365.87,491.17L412.71,506.97L387.43,532.86L388.93,548.3L416.32,561.13L422.33,578.96L434.39,577.62L432.65,554.33L453.19,557.43L456.24,566.6L459.67,550.61L504.87,506.6L534.96,501.21L549.5,479.29L517.11,468.2L514.27,458.76L487.85,441.04L413.95,426.19z"
        />
        <path
          id="TZ-03"
          title="Dodoma"
          className="land"
          d="M541.84,359.82L497.5,320.53L501.45,228.79L454.17,250.24L444.79,277.54L432.63,277.03L413.19,298.27L432.46,338.03L413.95,426.19L487.85,441.04L514.27,458.76L541.84,359.82z"
        />
        <path
          id="TZ-15"
          title="Zanzibar West"
          className="land"
          d="M715.08,362.12L709.61,362.09L717.53,377.41L715.08,362.12z"
        />
        <path
          id="TZ-07"
          title="Kaskazini-Unguja (Zanzibar North)"
          className="land"
          d="M723.45,359.7L717.91,339.34L709.61,362.09L715.08,362.12L723.45,359.7z"
        />
        <path
          id="TZ-19"
          title="Pwani"
          className="land"
          d="M759.56,486.55L759.72,478.11L737.57,500.43L754.52,498.15L759.56,486.55zM681.06,357L609.33,352.06L627.68,377.86L625.25,405.96L645.4,409.66L644.12,423.65L660.61,435.06L645.32,455.04L649.47,469.73L616.51,474.88L609.31,500.28L644.35,517.14L662.11,540.3L715.84,527.5L728.03,505.14L725.07,489.31L713.54,491.57L726.62,445.1L721.1,429.07L697.35,434.31L704.51,400.07L685.69,386.66L681.06,357z"
        />
        <path
          id="TZ-10"
          title="Kusini-Pemba"
          className="land"
          d="M756.86,300.67L748.72,299.19L742.07,301.28L751.48,304.78L742.12,311.93L747.75,320.28L756.86,300.67z"
        />
        <path
          id="TZ-06"
          title="Kaskazini-Pemba"
          className="land"
          d="M756.86,300.67L756.87,280.11L754.4,287.08L744.53,279.62L748.72,299.19L756.86,300.67z"
        />
        <path
          id="TZ-16"
          title="Morogoro"
          className="land"
          d="M609.33,352.06L596.86,358.24L579.84,344.46L563.87,367.68L556.13,350.13L541.84,359.82L514.27,458.76L517.11,468.2L549.5,479.29L534.96,501.21L504.87,506.6L459.67,550.61L456.24,566.6L453.19,557.43L432.65,554.33L434.39,577.62L458.2,601.72L472.76,590.72L459.73,633.59L506.13,629.93L514.49,617.21L516.13,646.96L562.05,617.83L577.59,587.35L578.24,540.62L609.31,500.28L616.51,474.88L649.47,469.73L645.32,455.04L660.61,435.06L644.12,423.65L645.4,409.66L625.25,405.96L627.68,377.86L609.33,352.06z"
        />
        <path
          id="TZ-02"
          title="Dar-Es-Salaam"
          className="land"
          d="M726.62,445.1L734.94,431.5L704.51,400.07L697.35,434.31L721.1,429.07L726.62,445.1z"
        />
        <path
          id="TZ-08"
          title="Kigoma"
          className="land"
          d="M150.81,241.14L119.61,131.94L107.88,142.85L106.51,163.29L80.12,183.23L49.29,235.07L29.89,248.85L6.29,247.88L0.51,281.77L21.24,338.33L15,379.39L46.86,417.7L77.32,388.1L49.61,311.66L101.5,301.32L117.83,306.3L125.36,323.48L137.43,318.32L137.23,284.58L156.15,260.18L150.81,241.14z"
        />
        <path
          id="TZ-05"
          title="Kagera"
          className="land"
          d="M244.57,0L83.04,4.53L108.76,42.5L112.83,77.58L109,96.47L85.57,104.96L78.94,132.87L107.88,142.85L119.61,131.94L111.91,120.83L132.87,91.75L166.4,97.26L183.88,82.29L243.25,33.49L244.57,0z"
        />
        <path
          id="TZ-24"
          title="Tabora"
          className="land"
          d="M357.07,218.11L307.28,206.82L254.73,209.05L243.91,214.02L243.94,233.68L230.7,228.66L221.94,242.66L190.67,246.46L189.78,225.86L170.49,218.22L169.85,230.77L150.81,241.14L156.15,260.18L137.23,284.58L137.43,318.32L125.36,323.48L140.66,345.33L196.08,342.84L207.34,367.26L244.11,388.47L232.4,439.45L267.11,422.32L299.34,427.81L301.76,404.51L337.43,375.48L340.42,330.32L352.94,315.64L331.26,298.37L331.92,257.53L357.07,218.11z"
        />
        <path
          id="TZ-22"
          title="Shinyanga"
          className="land"
          d="M294.89,163.24L272.41,174.65L248.31,162.53L239.33,175.66L229.36,157.76L205.7,163.11L202.34,191.92L180.87,196.98L170.49,218.22L189.78,225.86L190.67,246.46L221.94,242.66L230.7,228.66L243.94,233.68L243.91,214.02L254.73,209.05L307.28,206.82L357.07,218.11L365.98,212.67L347.7,183.11L294.89,163.24z"
        />
        <path
          id="TZ-18"
          title="Mwanza"
          className="land"
          d="M290.23,83.72L280.24,74.73L305.69,70.73L284.83,66.08L297.27,37.26L243.25,33.49L183.88,82.29L189.28,99.61L222.42,115.39L218.58,122.67L235.56,140.68L229.36,157.76L239.33,175.66L248.31,162.53L272.41,174.65L294.89,163.24L301.35,131.7L321.06,122.5L290.23,83.72z"
        />
        <path
          id="TZ-11"
          title="Zanzibar South"
          className="land"
          d="M717.53,377.41L736.3,390.07L723.45,359.7L715.08,362.12L717.53,377.41z"
        />
        <path
          id="TZ-20"
          title="Rukwa"
          className="land"
          d="M193.43,489.09L156.28,483.23L152.37,454.03L126.56,447.73L114.04,424.87L70.22,442.76L117.99,544.33L146.26,547L169.2,570.67L203.16,582.1L193.35,561.19L213.34,545.68L215.32,526.23L228.74,519.95L246.57,538.51L226.06,508.14L193.43,489.09z"
        />
        <path
          id="TZ-14"
          title="Mbeya"
          className="land"
          d="M299.34,427.81L267.11,422.32L232.4,439.45L212.37,447.15L193.43,489.09L226.06,508.14L246.57,538.51L228.74,519.95L215.32,526.23L213.34,545.68L193.35,561.19L203.16,582.1L296.04,622.22L317.27,619.5L330,629.25L341.97,614.12L325.44,592.37L325.79,570.71L370.45,564.31L388.93,548.3L387.43,532.86L412.71,506.97L365.87,491.17L326.01,492.19L331.92,468.07L299.34,427.81z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Tanzania))
