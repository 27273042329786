import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 820
const height = 1086

const cities = {
  Bridgetown: { x: 96, y: 855 }
}

const Barbados = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map barbados">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-10 -10 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="BB-11"
          title="Saint Thomas"
          className="land"
          d="M254.77,489.46L183.87,465.81L126.94,509.55L98.07,585.38L148.32,705.87L233.12,665.71L349.16,597.58L309.6,525.31L254.77,489.46z"
        />
        <path
          id="BB-10"
          title="Saint Philip"
          className="land"
          d="M626.81,937.4L788.55,794.24L799.62,669.08L758.18,616.47L655.44,574.12L510.34,722.19L494.81,781.49L533.09,797.07L571.01,896.52L626.81,937.4z"
        />
        <path
          id="BB-09"
          title="Saint Peter"
          className="land"
          d="M297.27,199.43L272.68,145.94L0.38,256.88L7.86,384.12L166.14,372.32L176.28,224.56L297.27,199.43z"
        />
        <path
          id="BB-08"
          title="Saint Michael"
          className="land"
          d="M291.96,858.61L233.12,665.71L148.32,705.87L76.41,756.16L43.92,737.23L111.24,910.22L186.64,945.97L297.72,887.51L291.96,858.61z"
        />
        <path
          id="BB-07"
          title="Saint Lucy"
          className="land"
          d="M142.03,0L78.14,23.96L2.89,123.33L0.38,256.88L272.68,145.94L207.07,35.49L142.03,0z"
        />
        <path
          id="BB-06"
          title="Saint Andrew"
          className="land"
          d="M477.3,461.58L379.16,366.1L302.29,386.4L254.77,489.46L309.6,525.31L349.16,597.58L398.96,588.76L404.81,520.06L477.3,461.58z"
        />
        <path
          id="BB-05"
          title="Saint John"
          className="land"
          d="M655.44,574.12L477.3,461.58L404.81,520.06L398.96,588.76L510.34,722.19L655.44,574.12z"
        />
        <path
          id="BB-04"
          title="Saint James"
          className="land"
          d="M166.14,372.32L7.86,384.12L43.92,737.23L76.41,756.16L148.32,705.87L98.07,585.38L126.94,509.55L183.87,465.81L166.14,372.32z"
        />
        <path
          id="BB-03"
          title="Saint George"
          className="land"
          d="M510.34,722.19L398.96,588.76L349.16,597.58L233.12,665.71L291.96,858.61L494.81,781.49L510.34,722.19z"
        />
        <path
          id="BB-02"
          title="Saint Andrew"
          className="land"
          d="M379.16,366.1L297.27,199.43L176.28,224.56L166.14,372.32L183.87,465.81L254.77,489.46L302.29,386.4L379.16,366.1z"
        />
        <path
          id="BB-01"
          title="Christ Church"
          className="land"
          d="M413.41,1065.62L509.82,1043.32L626.81,937.4L571.01,896.52L533.09,797.07L494.81,781.49L291.96,858.61L297.72,887.51L186.64,945.97L313.26,968.13L378.59,1005.65L413.41,1065.62z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Barbados))
