import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
// import '../styles/scss/App.scss'
import annyang from '../lib/annyangSetUp'
import { translateFrom, common, commonTranslateArray } from '../lib/languageUtil'
import readOutLoud from '../lib/readOutLoud'
import {
  isRedirectButtonClick,
  isSpeakRequest,
  isSpeakSoundDisabled,
  createBorderRadius,
  isTranscriptAMatch
} from '../lib/methodsUtil'
import PageHeader from './PageHeader'
import SpeechButton from './SpeechButton'
import NavigationButtons from './NavigationButtons'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import AppListeningSpeakingVisual from './AppListeningSpeakingVisual'
import AnnyangNotSupportedMenuButton from './AnnyangNotSupportedMenuButton'
import { wasPathChanged } from '../../src/router/routerUtil'

const createLevels = navigationCommands =>
  navigationCommands.filter(
    command => !['menu', 'mathematics', 'geography', 'reading'].includes(command)
  )

const createLevelsNavigationCommands = (levels, customNavigationPath) => {
  const commands = {}
  levels.forEach(level => {
    commands[common(level).toLowerCase()] = `${customNavigationPath}/${level}`
  })
  return commands
}

class EasyMediumHardNavigationPage extends Component {
  componentDidMount() {
    const { dispatch, lngCode, navigationCommands } = this.props
    annyang.bindNavigationCommands(dispatch, commonTranslateArray(navigationCommands), lngCode)
    annyang.start()
    annyang.addTranscriptListener(dispatch)
  }

  shouldComponentUpdate(nextProps) {
    const { transcript, path, navigationCommands, customNavigationPath, extraNavigationCommands } =
      nextProps

    const levels = createLevels(navigationCommands)
    const translatedNavigationCommands = Object.keys({
      ...createLevelsNavigationCommands(levels, customNavigationPath),
      ...extraNavigationCommands
    })

    return (
      isTranscriptAMatch(transcript, translatedNavigationCommands) ||
      isRedirectButtonClick(path, navigationCommands) ||
      isSpeakRequest(transcript)
    )
  }

  componentWillUnmount() {
    annyang.abort()
  }

  render() {
    const {
      path,
      dispatch,
      pageName,
      navigationCommands,
      customNavigationPath,
      extraNavigationCommands,
      transcript,
      language,
      lngCode,
      gender,
      soundLevel
    } = this.props

    const translate = translateFrom(pageName)
    const intro = translate('intro')
    const levels = createLevels(navigationCommands)
    const levelsMap = levels.map(level => common(`topic_${level}`))

    const readOutLoudNavigationText = `${intro} ${levelsMap.join('. ')}`
    const isSpeakDisabled = isSpeakSoundDisabled(soundLevel)
    if (isSpeakRequest(transcript) && !isSpeakDisabled) {
      readOutLoud(readOutLoudNavigationText, dispatch, language, gender)
    }

    return (
      <div className={`${pageName.replace(/_/g, '-')} top-navigation`}>
        <div className="navigation-box" style={createBorderRadius(0.65)}>
          <PageHeader title={translate('title')} intro={intro} topics={levelsMap} />

          <AppListeningSpeakingVisual />

          <AnnyangNotSupportedMenuButton />

          <NavigationButtons
            customNavigationCommands={{
              ...createLevelsNavigationCommands(levels, customNavigationPath),
              ...extraNavigationCommands
            }}
            lngCode={lngCode}
            dispatch={dispatch}
          />
        </div>

        {!isSpeakDisabled && (
          <SpeechButton
            className={`${pageName.replace(/_/g, '-')}-speech-button`}
            text={readOutLoudNavigationText}
          />
        )}

        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default connect(mapMinimalStateToProps, passDispatchToProps)(EasyMediumHardNavigationPage)
