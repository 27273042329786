import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { translateFrom, common } from '../lib/languageUtil'
import { mapMinimalStateToProps, passDispatchToProps } from '../redux/mapToPropsUtil'
import { getPageTitleColor, getTopic } from '../lib/methodsUtil'
import { sendAmplitudeEvent } from '../lib/amplitudeUtil'
import readOutLoud from '../lib/readOutLoud'
import { isDevDisableReadOutLoud } from '../lib/_processUtil'
import { Navigate } from 'react-router-dom'
import { changePathAction } from '../redux/actions/actions'
import { wasPathChanged } from '../../src/router/routerUtil'

const disableReadOutLoud = isDevDisableReadOutLoud

const updateCallBack = (path, dispatch, callBack) => {
  if (callBack === 'END') {
    const parentPath = path.substring(0, path.lastIndexOf('/'))
    dispatch(changePathAction(parentPath))
  }
}

class LearnMathematicsPage extends Component {
  constructor(props) {
    super(props)
    this.state = { learnAnimation: null }
    this.cb = this.cb.bind(this)
  }

  cb(i, sentencesNumberPlusRedirect) {
    const { path, dispatch, animations } = this.props
    const anim = i === sentencesNumberPlusRedirect ? 'END' : animations[i]
    updateCallBack(path, dispatch, anim)
    this.setState({ learnAnimation: anim })
  }

  componentDidMount() {
    const {
      dispatch,
      pageName,
      language,
      gender,
      lngCode,
      sentencesNumberPlusRedirect = 1,
      // CAREFUL, if/when passed, startTimesOf must be passed FULLY, else it will set to 0 any value not passed
      startTimesOf,
      frReadSpeed
    } = this.props

    // the below sends an event everytime the page mounts. When accessed via click, the amplitude event with { action: 'click' }
    // is sent first by the NavigationButtons component. sendAmplitudeEvent only allows an event to be sent every 2 seconds
    // preventing the below to be sent. If the page is accessed vocally, the below should be sent.
    sendAmplitudeEvent('Learning Page Visits', {
      topic: getTopic(pageName),
      action: 'vocal'
    })

    const translate = translateFrom(pageName)
    const read = (sentence, cb) => {
      !disableReadOutLoud &&
        readOutLoud(
          sentence === '' ? '' : translate(sentence),
          dispatch,
          language,
          gender,
          cb,
          lngCode === 'fr' && frReadSpeed ? frReadSpeed : '1.15'
        )
    }

    for (let i = 1; i <= sentencesNumberPlusRedirect; i++) {
      const defaultTimeOut = (i - 1) * 10000
      setTimeout(
        () =>
          read(
            i === sentencesNumberPlusRedirect ? '' : `sentence_${i}`,
            this.cb(i, sentencesNumberPlusRedirect)
          ),
        (startTimesOf && startTimesOf[i]) || defaultTimeOut
      )
    }
  }

  render() {
    const { pageName, path } = this.props
    const { learnAnimation } = this.state

    return (
      <div className={`${pageName.replace(/_/g, '-')} learn-mathematics-page`}>
        <div className="learn-page-title" style={{ color: getPageTitleColor(path) }}>
          {common(pageName)}
        </div>
        <div className="learn-mathematics-animation-container">{learnAnimation}</div>
        {wasPathChanged(path, pageName) && <Navigate to={path} replace />}
      </div>
    )
  }
}

export default withTranslation()(
  connect(mapMinimalStateToProps, passDispatchToProps)(LearnMathematicsPage)
)
