import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 710
const height = 847

const cities = {
  Vientiane: { x: 239, y: 454 }
}

const Laos = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map laos">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="LA-XE"
          title="Xékong"
          className="land"
          d="M685.23,728.47L699.58,705.63L652.53,658.5L679.06,630.1L651.35,618.93L637.32,632.99L621.9,631.98L606.59,658.25L610.16,679.1L576.61,684.37L580.83,694.36L609.54,719.45L619.42,708.29L654.36,706.22L681.37,715.3L685.23,728.47z"
        />
        <path
          id="LA-AT"
          title="Attapu"
          className="land"
          d="M685.23,728.47L681.37,715.3L654.36,706.22L619.42,708.29L609.54,719.45L622.32,731.15L610.22,753.15L593.03,751.6L592.73,736.3L580.64,732.15L562.32,749.25L577.74,785.37L593.69,771.87L623.59,798.92L665.34,771.09L677.14,776.35L690.39,743.53L685.23,728.47z"
        />
        <path
          id="LA-KH"
          title="Khammouan"
          className="land"
          d="M583.66,538.85L572.61,512.71L563.44,515.36L520.69,475.02L496.98,425.7L477.58,419.23L457.49,449.06L412.18,420.97L401.38,425.32L387.42,456.77L434.65,499.27L430.36,545.73L438.3,549.24L439.05,532.86L469.06,529.5L583.66,538.85z"
        />
        <path
          id="LA-BL"
          title="Bolikhamxai"
          className="land"
          d="M477.58,419.23L461.21,399.07L464.24,375.27L427.03,365.72L385.35,335.6L372.59,362.81L350.75,357.8L313.64,352.6L309.39,385.84L252.38,393.58L259.98,414.74L276.96,429.83L296.18,415.3L297.24,401.86L359.07,411.12L387.42,456.77L401.38,425.32L412.18,420.97L457.49,449.06L477.58,419.23z"
        />
        <path
          id="LA-PH"
          title="Phôngsali"
          className="land"
          d="M152.51,0L131.61,25.07L153.37,67.89L150.05,117.11L159.88,127.04L205.01,152.67L223.45,161.92L262.01,135.02L250.49,123.69L264.04,75.76L254.74,79.01L250.99,65.9L236.56,83.65L231.05,59.4L185.21,7.3L161.84,11.28L152.51,0z"
        />
        <path
          id="LA-OU"
          title="Oudômxai"
          className="land"
          d="M205.01,152.67L159.88,127.04L154.98,133.05L141.57,161.81L121.69,175.5L135.76,185.24L133.88,200.8L105.05,217.32L103.02,238.89L87.92,236.71L60.03,251.91L62.02,260.41L135.65,265.05L149.35,254.49L194.43,233.38L189.63,207.34L209.1,176.52L205.01,152.67z"
        />
        <path
          id="LA-LP"
          title="Louangphrabang"
          className="land"
          d="M304.85,169.32L280.89,163.32L262.01,135.02L223.45,161.92L205.01,152.67L209.1,176.52L189.63,207.34L194.43,233.38L149.35,254.49L167.72,306.31L158.85,340.91L188.16,306.45L218.9,309.75L229.64,323.84L245.37,315.01L238.45,294.23L281.65,256.04L288.66,181.97L304.85,169.32z"
        />
        <path
          id="LA-HO"
          title="Houaphan"
          className="land"
          d="M379.86,277.32L418.15,286.27L449.15,248.13L445.31,230.42L422.11,226.74L424.31,214.09L394.62,204.35L418.23,183.93L369.88,152.8L341.49,164.21L332.25,182.83L304.85,169.32L288.66,181.97L281.65,256.04L312.38,260.63L342.4,244.9L356.92,269.07L380.3,257.3L379.86,277.32z"
        />
        <path
          id="LA-XI"
          title="Xiangkhoang"
          className="land"
          d="M379.86,277.32L380.3,257.3L356.92,269.07L342.4,244.9L312.38,260.63L281.65,256.04L238.45,294.23L245.37,315.01L277.4,331.28L348.5,342.74L350.75,357.8L372.59,362.81L385.35,335.6L347.33,316.43L369.66,298.41L361.43,279.07L379.86,277.32z"
        />
        <path
          id="LA-VI"
          title="Vientiane"
          className="land"
          d="M245.37,315.01L229.64,323.84L218.9,309.75L188.16,306.45L158.85,340.91L158.88,382.74L121.21,425.58L134.36,461.41L181.53,424.59L181.08,402.67L192.61,401L226.25,420.21L259.98,414.74L252.38,393.58L309.39,385.84L313.64,352.6L350.75,357.8L348.5,342.74L277.4,331.28L245.37,315.01z"
        />
        <path
          id="LA-VT"
          title="Vientiane [prefecture]"
          className="land"
          d="M259.98,414.74L226.25,420.21L192.61,401L181.08,402.67L181.53,424.59L231.84,447.66L239.54,461.76L272.38,444.21L276.96,429.83L259.98,414.74z"
        />
        <path
          id="LA-SV"
          title="Savannakhét"
          className="land"
          d="M583.66,538.85L469.06,529.5L439.05,532.86L438.3,549.24L430.36,545.73L432.1,587.46L459.19,625.06L492.96,636.23L536.42,648.25L559.26,637.59L561.45,619.73L574.41,627.11L604.58,618.13L615.79,595.47L596.29,573.2L594.8,541.31L583.66,538.85z"
        />
        <path
          id="LA-SL"
          title="Saravan"
          className="land"
          d="M651.35,618.93L624.59,585.66L615.79,595.47L604.58,618.13L574.41,627.11L561.45,619.73L559.26,637.59L536.42,648.25L492.96,636.23L491.92,655.97L513.09,667.29L508.59,691.71L553.02,688.61L561.15,705.05L580.83,694.36L576.61,684.37L610.16,679.1L606.59,658.25L621.9,631.98L637.32,632.99L651.35,618.93z"
        />
        <path
          id="LA-CH"
          title="Champasak"
          className="land"
          d="M552.69,836.16L560.12,823.1L543.89,793.31L577.74,785.37L562.32,749.25L580.64,732.15L592.73,736.3L593.03,751.6L610.22,753.15L622.32,731.15L609.54,719.45L580.83,694.36L561.15,705.05L553.02,688.61L508.59,691.71L494.86,719.87L508.66,733.57L500.75,774.38L470.86,795.23L477.58,810.81L552.69,836.16z"
        />
        <path
          id="LA-XA"
          title="Xaignabouri"
          className="land"
          d="M158.85,340.91L167.72,306.31L149.35,254.49L135.65,265.05L62.02,260.41L29.14,267.92L39.59,296.56L61.08,298.54L72.8,285.67L107.49,292.19L99.86,308.93L114.1,343.78L105.62,379.16L86.72,401.66L98.29,411.88L99.19,438.11L73.38,484.7L96.15,495.57L134.36,461.41L121.21,425.58L158.88,382.74L158.85,340.91z"
        />
        <path
          id="LA-LM"
          title="Louang Namtha"
          className="land"
          d="M154.98,133.05L131.78,124.9L107.94,131.17L98.62,94.11L58.52,118.05L41.7,146.24L47.65,164.81L68.38,198.42L105.05,217.32L133.88,200.8L135.76,185.24L121.69,175.5L141.57,161.81L154.98,133.05z"
        />
        <path
          id="LA-BK"
          title="Bokeo"
          className="land"
          d="M105.05,217.32L68.38,198.42L47.65,164.81L16.83,172.51L0.42,213.36L5.55,224.13L23.28,209.26L41.98,231.15L29.14,267.92L62.02,260.41L60.03,251.91L87.92,236.71L103.02,238.89L105.05,217.32z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Laos))
