import React, { forwardRef } from 'react'
import windowSize from 'react-window-size'
import { getSvgScale, getCircleFill } from './_util'

const width = 810
const height = 1070

const cities = {
  "Saint George's": { x: 90, y: 951 }
}

const Grenada = (
  { cityName, windowWidth, windowHeight, fillOne = 'gold', fillTwo = 'goldenrod' },
  // eslint-disable-next-line
  ref
) => {
  const svgScale = getSvgScale(width, height, windowWidth, windowHeight)

  return (
    <div className="country-map grenada">
      <svg
        fill="url(#linear-gradient)"
        width={`${width}`}
        height={`${height}`}
        viewBox={`-5 -5 ${width} ${height}`}
        stroke="#080808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".2"
        version="1">
        <defs>
          <linearGradient
            id="linear-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0" stopColor={fillOne} />
            <stop offset="100%" stopColor={fillTwo} />
          </linearGradient>
        </defs>
        <path
          id="GD-06"
          title="Saint Patrick"
          className="land"
          d="M368.49,710.31L362.97,616.44L320.43,589.6L234.38,590.91L213.39,616.52L264.86,705.34L368.49,710.31zM408.4,460.62L399.68,431.4L373.61,467.17L397.28,482.96L408.4,460.62z"
        />
        <path
          id="GD-05"
          title="Saint Mark"
          className="land"
          d="M264.86,705.34L213.39,616.52L148.51,695.72L251.92,746.68L264.86,705.34z"
        />
        <path
          id="GD-04"
          title="Saint John"
          className="land"
          d="M251.92,746.68L148.51,695.72L77.95,844.2L205.04,842.59L251.92,746.68z"
        />
        <path
          id="GD-03"
          title="Saint George"
          className="land"
          d="M188.23,1059.76L211.74,1014.47L185.64,948.39L224.44,895.49L205.04,842.59L77.95,844.2L80.99,992.47L0.38,1048.64L66.35,1058.62L120.02,1033.68L188.23,1059.76z"
        />
        <path
          id="GD-02"
          title="Saint David"
          className="land"
          d="M326.67,926.72L260.01,885.57L224.44,895.49L185.64,948.39L211.74,1014.47L312.75,976.85L326.67,926.72z"
        />
        <path
          id="GD-01"
          title="Saint Andrew"
          className="land"
          d="M368.49,710.31L264.86,705.34L251.92,746.68L205.04,842.59L224.44,895.49L260.01,885.57L326.67,926.72L368.49,710.31z"
        />
        <path
          id="GD-10"
          title="Carriacou and Petite Martinique"
          className="land"
          d="M799.62,19.17L773.71,34.16L791.47,43.01L799.62,19.17zM712.77,0L635.68,109.03L593.21,96.09L578.74,123.61L605.45,123.61L578.74,179.87L678.14,140.81L712.77,151.13L722.05,69.55L712.77,0z"
        />
        {cityName && (
          <circle
            cx={cities[cityName].x}
            cy={cities[cityName].y}
            fill={getCircleFill(fillOne)}
            filter="drop-shadow(2px 4px 6px black)"
            stroke="black"
            r={7 * svgScale}
            strokeWidth={2 * svgScale}
          />
        )}
      </svg>
    </div>
  )
}

export default windowSize(forwardRef(Grenada))
