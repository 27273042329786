import React from 'react'

const WrongAnswerCross = ({ wrongAnswersLength }) => (
  <svg
    className={
      wrongAnswersLength > 20
        ? 'smaller-wrong-answer-cross'
        : wrongAnswersLength > 5
        ? 'small-wrong-answer-cross'
        : 'wrong-answer-cross'
    }
    viewBox="2.54312999459216E-06 -0.194655999541283 113.526997884116 113.527656182647">
    <defs>
      <linearGradient id="def0" x1="0.499995" x2="0.499995" y1="1.73779E-06" y2="1.00001">
        <stop offset="0" stopColor="#F27E5E" />
        <stop offset="0.5" stopColor="#EB1C24" />
        <stop offset="1" stopColor="#CE2229" />
      </linearGradient>
    </defs>
    <g>
      <path
        d="M2.54313E-06,87.5347L30.964,56.564 2.54313E-06,25.6013 25.7973,-0.194656 56.7627,30.7707 87.7267,-0.194656 113.527,25.6013 82.5627,56.5693 113.527,87.5347 87.7267,113.329 56.7667,82.364 25.7973,113.333 2.54313E-06,87.5347z"
        fill="#990000"
      />
      <path
        d="M111.641,87.5341L80.6768,56.5701 111.641,25.6021 87.7261,1.69014 56.7635,32.6555 25.7968,1.69014 1.8848,25.6021 32.8501,56.5648 1.8848,87.5341 25.7968,111.447 56.7675,80.4781 87.7261,111.443 111.641,87.5341z"
        fill="url(#def0)"
      />
      <path
        d="M53.5507,42.1597C69.9773,36.9184,86.2987,35.0784,101.036,36.2077L111.64,25.6024 87.7267,1.6904 56.7627,32.6557 25.7973,1.6904 1.88534,25.6024 29.0347,52.7491C36.5187,48.5651,44.7387,44.9717,53.5507,42.1597z"
        fill="#FFFFFF"
        style={{ fillOpacity: '0.101960784313725' }}
      />
    </g>
  </svg>
)

export default WrongAnswerCross
