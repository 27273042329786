import React from 'react'
import {
  FadeIn,
  FadeOut,
  Shake,
  Swing,
  FlipInX,
  FlipInY,
  FlipOutX,
  FlipOutY,
  BounceIn,
  Pulse,
  RotateIn,
  RotateOut,
  RubberBand,
  Wobble
} from '../../../../lib/animationsUtil'
import LearnMathematicsPage from '../../../../components/LearnMathematicsPage'
import { baseUrl, mathsNumbersUrls } from '../../../../lib/_processUtil'

const LearnEasyDivision = ({ number, numberResult, flipOutDelay, delay, comp }) => (
  <FlipOutX
    delay={flipOutDelay}
    className="learn-flex-center-center justify-content-space-between"
    duration={0.5}>
    <FlipInX className="flex align-items-center height-100" delay={delay} duration={0.5}>
      <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/3.webp`} />
    </FlipInX>
    <FlipInX className="flex align-items-center height-100" delay={delay + 0.25} duration={0.5}>
      <img className="very-small-maths-sign" src={`${mathsNumbersUrls}/28/times.webp`} />
    </FlipInX>
    <FlipInX className="flex align-items-center height-100" delay={delay + 0.5} duration={0.5}>
      <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/${number}.webp`} />
    </FlipInX>
    <FlipInX className="flex align-items-center height-100" delay={delay + 0.75} duration={0.5}>
      <img className="very-very-small-maths-sign" src={`${mathsNumbersUrls}/28/equal.webp`} />
    </FlipInX>
    {numberResult === 12 ? (
      <>
        <FlipInX className="flex align-items-center height-100" delay={1} duration={0.5}>
          <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/1.webp`} />
        </FlipInX>
        <FlipInX className="flex align-items-center height-100" delay={1} duration={0.5}>
          <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/2.webp`} />
        </FlipInX>
      </>
    ) : (
      <FlipInX className="flex align-items-center height-100" delay={delay + 1} duration={0.5}>
        <img
          className="very-very-small-number"
          src={`${mathsNumbersUrls}/28/${numberResult}.webp`}
        />
      </FlipInX>
    )}
    {comp || (
      <FlipInX className="flex align-items-center height-100" delay={delay + 2} duration={0.5}>
        <RubberBand
          className="flex align-items-center height-100"
          delay={delay + 2.5}
          duration={0.5}>
          <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/less-than.webp`} />
        </RubberBand>
      </FlipInX>
    )}
    <FlipInX className="flex align-items-center height-100" delay={delay + 2} duration={0.5}>
      <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/1.webp`} />
    </FlipInX>
    <FlipInX className="flex align-items-center height-100" delay={delay + 2} duration={0.5}>
      <img className="very-very-small-number" src={`${mathsNumbersUrls}/28/1.webp`} />
    </FlipInX>
  </FlipOutX>
)

const LearnEasyDivisionsPage = () => (
  <LearnMathematicsPage
    pageName="learn_easy_divisions"
    sentencesNumberPlusRedirect={11}
    startTimesOf={{
      2: 5000,
      3: 14000,
      4: 19500,
      5: 26000,
      6: 32000,
      7: 41000,
      8: 48000,
      9: 53000,
      10: 58000,
      11: 62000
    }}
    animations={{
      1: (
        <FlipInY className="learn-flex-center-center" duration={0.5}>
          <Wobble className="position-relative" delay={1.5} duration={3}>
            <FlipOutY className="position-relative" delay={4.5} duration={0.5}>
              <img className="width-100 height-100" src={`${baseUrl}/images/learn/ed-1.webp`} />
            </FlipOutY>
          </Wobble>
        </FlipInY>
      ),
      2: (
        <div className="learn-flex-center-center">
          <FlipOutX
            delay={8.5}
            className="learn-flex-column-space-between learn-horizontal-numbers"
            duration={0.5}>
            <FlipInX delay={1} duration={0.5}>
              <RubberBand delay={5.75} duration={0.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/25/1.webp`} />
              </RubberBand>
            </FlipInX>
            <FlipInX delay={1} duration={0.5}>
              <RubberBand delay={5.75} duration={0.5}>
                <img className="width-100" src={`${mathsNumbersUrls}/25/1.webp`} />
              </RubberBand>
            </FlipInX>
            <FlipInX delay={1.25} duration={0.5}>
              <img
                className="width-100 plus-sign minus-sign"
                src={`${mathsNumbersUrls}/25/by.webp`}
              />
            </FlipInX>
            <FlipInX delay={1.5} duration={0.5}>
              <RubberBand delay={1.9} duration={0.5}>
                <RubberBand delay={3.5} duration={0.5}>
                  <img className="width-100" src={`${mathsNumbersUrls}/25/3.webp`} />
                </RubberBand>
              </RubberBand>
            </FlipInX>
          </FlipOutX>
        </div>
      ),
      3: <LearnEasyDivision number={1} numberResult={3} flipOutDelay={5} delay={0.5} />,
      4: <LearnEasyDivision number={2} numberResult={6} flipOutDelay={11} delay={5.5} />,
      5: <LearnEasyDivision number={3} numberResult={9} flipOutDelay={17} delay={12} />,
      6: (
        <LearnEasyDivision
          number={4}
          numberResult={12}
          flipOutDelay={26}
          delay={18}
          comp={
            <FlipInY className="flex align-items-center height-100" delay={1.6} duration={0.5}>
              <RubberBand className="flex align-items-center height-100" delay={2.5} duration={6}>
                <img
                  className="very-very-small-number"
                  src={`${mathsNumbersUrls}/28/more-than.webp`}
                />
              </RubberBand>
            </FlipInY>
          }
        />
      ),
      7: (
        <>
          <FlipOutX className="learn-flex-center-center" delay={3.5} duration={0.5}>
            <BounceIn className="flex align-items-center height-100" delay={1.25} duration={0.5}>
              <RubberBand className="flex align-items-center height-100" delay={1.5}>
                <img className="very-small-number" src={`${mathsNumbersUrls}/28/9.webp`} />
              </RubberBand>
            </BounceIn>
            <FadeIn className="flex align-items-end height-100" duration={2}>
              <img
                className="width-100 plus-sign minus-sign"
                src={`${baseUrl}/images/learn/ed-7a.webp`}
              />
            </FadeIn>
            <BounceIn className="flex align-items-center height-100" delay={1.25} duration={0.5}>
              <RubberBand className="flex align-items-center height-100" delay={2.75}>
                <img className="very-small-number" src={`${mathsNumbersUrls}/28/1.webp`} />
              </RubberBand>
            </BounceIn>
            <BounceIn className="flex align-items-center height-100" delay={1.25} duration={0.5}>
              <RubberBand className="flex align-items-center height-100" delay={2.75}>
                <img className="very-small-number" src={`${mathsNumbersUrls}/28/1.webp`} />
              </RubberBand>
            </BounceIn>
          </FlipOutX>
          <FlipInX className="learn-flex-center-center position-absolute" delay={4} duration={0.5}>
            <Wobble className="position-relative" delay={4.5} duration={2}>
              <FlipOutX className="position-relative" delay={6.5} duration={0.5}>
                <img className="width-100 height-100" src={`${baseUrl}/images/learn/ed-7b.webp`} />
              </FlipOutX>
            </Wobble>
          </FlipInX>
        </>
      ),
      8: (
        <FadeOut className="learn-flex-center-center" delay={4} duration={0.5}>
          <FadeIn className="flex" delay={1.25} duration={0.5}>
            <img className="width-75 flex" src={`${mathsNumbersUrls}/23/1.webp`} />
          </FadeIn>
          <FadeIn className="flex" delay={1.25} duration={0.5}>
            <img className="width-75 flex" src={`${mathsNumbersUrls}/23/1.webp`} />
          </FadeIn>
          <FadeIn className="flex" delay={1.5} duration={0.5}>
            <img className="width-75 flex" src={`${mathsNumbersUrls}/23/minus.webp`} />
          </FadeIn>
          <FadeIn className="flex" delay={1.75} duration={0.5}>
            <img className="width-75 flex" src={`${mathsNumbersUrls}/23/9.webp`} />
          </FadeIn>
          <FadeIn className="flex" delay={2} duration={0.5}>
            <img className="width-75 flex" src={`${mathsNumbersUrls}/23/equal.webp`} />
          </FadeIn>
          <FadeIn className="flex" delay={2.25} duration={0.5}>
            <Swing delay={2.5}>
              <img className="width-75 flex" src={`${mathsNumbersUrls}/23/2.webp`} />
            </Swing>
          </FadeIn>
        </FadeOut>
      ),
      9: (
        <div className="learn-flex-center-center">
          <FlipOutX
            delay={1}
            className="learn-flex-column-space-between learn-horizontal-numbers"
            duration={0.5}>
            <FlipInX className="learn-flex-column-space-around" duration={0.5}>
              <img src={`${mathsNumbersUrls}/26/1.webp`} />
              <img src={`${mathsNumbersUrls}/26/1.webp`} />
              <img className="plus-sign minus-sign" src={`${mathsNumbersUrls}/26/by.webp`} />
              <img src={`${mathsNumbersUrls}/26/3.webp`} />
            </FlipInX>
          </FlipOutX>
          <FlipOutX
            delay={4}
            className="flex justify-content-space-between width-100 position-absolute">
            <FlipInY className="position-relative" delay={1.5}>
              <img className="max-100" src={`${baseUrl}/images/learn/ed-9-1.webp`} />
              <Pulse className="learn-easy-division-slide-nine-number" delay={2}>
                <img className="width-100" src={`${mathsNumbersUrls}/26/3.webp`} />
              </Pulse>
            </FlipInY>
            <FlipInY className="position-relative" delay={2.5}>
              <img className="max-100" src={`${baseUrl}/images/learn/ed-9-2.webp`} />
              <Pulse className="learn-easy-division-slide-nine-number" delay={3}>
                <img className="width-100" src={`${mathsNumbersUrls}/26/2.webp`} />
              </Pulse>
            </FlipInY>
          </FlipOutX>
        </div>
      ),
      10: (
        <RotateIn className="learn-flex-center-center" duration={0.5}>
          <RotateOut className="position-relative" delay={3.5} duration={0.5}>
            <Shake delay={1.5} duration={2}>
              <img className="width-100 height-100" src={`${baseUrl}/images/learn/ed-10.webp`} />
            </Shake>
          </RotateOut>
        </RotateIn>
      )
    }}
  />
)

export default LearnEasyDivisionsPage
