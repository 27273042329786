import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import TopicNavigationPage from '../components/TopicNavigationPage'
import { mapMinimalStateToProps } from '../redux/mapToPropsUtil'

// Shop - 1 euro per category top unlock or cheaper to unlock all
// Buying all now set to unlock all future topics that will be added, else casde by case.
// Nikki said that when price is low people tend to not go for it.
// or share about ME on Facebook, to unlock a free topic
// Premium content

// add 2 / 3 printscreen of what they can expect when they buy

const i18nextTransStyling = {
  italic: <i />,
  bold: <strong />,
  red: <span style={{ color: 'red' }} />,
  blue: <span style={{ color: 'blue' }} />,
  green: <span style={{ color: 'green' }} />
}

const content = translate => (
  <>
    <hr />
    <div style={{ whiteSpace: 'pre-wrap' }}>
      <Trans defaults={translate('unlock_all')} components={i18nextTransStyling} />

      <p>
        Here will be a primary button (bigger size) to unlock all. The text above should also be
        bigger
      </p>

      <button>{translate('genius_package')}</button>

      <div>{translate('locked_topics')}</div>

      <p>Here will be the list of buying options</p>
    </div>
  </>
)

const ShopPage = () => (
  <TopicNavigationPage
    navigationCommands={['menu']}
    pageName="shop_page"
    topics={['topic_menu']}
    subTopics={['menu']}
    borderRadius={0.75}
    SEODescription="E=MC² - Educational Media Center² - Get online access to lectures, lessons, reading, maths, geography and additional content for children to learn by themselves">
    {content}
  </TopicNavigationPage>
)

export default connect(mapMinimalStateToProps)(ShopPage)
